import React, { Component } from 'react';
import { CircleIcon, CheckCircleFillIcon } from '@primer/octicons-react'

import 'rc-slider/assets/index.css';

import '../full.css';

import $ from "jquery"

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

// Autres components
import ComponentReference from './ComponentReference'
import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'


class ComponentSources extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.player = React.createRef();
  }

  state = {

    show: false,

    commentateur: {
      quote_link_typeID: 0,
    },

    type_person: "Une personne",

    showConsoleLog: true,

    count: 0,

    CarouselPrimaireIndex: 0,
    CarouselSecondaireIndex: 0,

  }

  componentDidMount() {
    this.initData()
  }


  componentDidUpdate() {

  }


  initData = (exceptions) => {

    this.props.showConsoleLog && console.log("initData -> referenceList 0 :", this.props.referenceList)

    this.setState({

      type_person: this.state.type_person, // (exceptions !== undefined && exceptions === "type_person") ? 
      currentStep: (this.props.type === "modification") ? 2 : 0,
      activeStep: 0,

      selectedFilterPersonName: '',
      selectedFilterMediaName: '',

      personToDeleteSelected: -1,

      CarouselPrimaireIndex: 0,
      CarouselSecondaireIndex: 0,

    })

  }



  afterFilter = (newResult, newFilters) => {
    this.props.showConsoleLog && console.log("Filter Value 1 : ", newResult)
    this.props.showConsoleLog && console.log("Filter Value 2 : ", newFilters)

    this.props.showConsoleLog && console.log("Filter Value 33 : ", newFilters.nom)
    this.props.showConsoleLog && console.log("Filter Value 34 : ", this.state.selectedFilterPersonName)

    if (newFilters.nom === undefined) {
      if (this.state.selectedFilterPersonName !== "") {
        this.setState({ selectedFilterPersonName: "" })
      }
    } else {
      if (newFilters.nom.filterVal !== this.state.selectedFilterPersonName) {
        this.setState({ selectedFilterPersonName: newFilters.nom.filterVal })
      }
    }
  }


  secondsToHHMMSS = (time) => {
    let returnTime = ''

    let hoursStr = ''
    let minutesStr = ''
    let secondsStr = ''

    let hoursNum = Math.floor(time / 3600)
    let minutesNum = Math.floor((time - hoursNum * 3600) / 60)
    let secondsNum = Math.floor((time - hoursNum * 3600 - minutesNum * 60))

    if (hoursNum < 10) {
      hoursStr = '0'.concat(hoursNum.toString())
    } else {
      hoursStr = hoursNum.toString()
    }
    if (minutesNum < 10) {
      minutesStr = '0'.concat(minutesNum.toString())
    } else {
      minutesStr = minutesNum.toString()
    }
    if (secondsNum < 10) {
      secondsStr = '0'.concat(secondsNum.toString())
    } else {
      secondsStr = secondsNum.toString()
    }

    this.props.showConsoleLog && console.log("hours / time : ", time)
    this.props.showConsoleLog && console.log("hours : ", hoursStr, " / minutes : ", minutesStr, " / seconds : ", secondsStr)

    if (hoursNum === 0) {
      returnTime = minutesStr.concat(':', secondsStr)
    } else {
      returnTime = hoursStr.concat(':', minutesStr, ':', secondsStr)
    }

    return (returnTime)
  }

  hour_min_sec_toSeconds(hour, min, sec) {
    return (sec + min * 60 + hour * 3600)
  }

  hour_min_sec_to_min_and_seconds(hour, min, sec) {
    let new_min = hour * 60 + min;
    return ({ new_min, sec })
  }



  alreadySelectedCheck = (idperson) => {

    let stateReturn = "not selected"

    if (this.props.listcommentateursPourId.includes(idperson)) {
      stateReturn = "pour"
    }
    if (this.props.listcommentateursPeutetreId.includes(idperson)) {
      stateReturn = "peut etre"
    }
    if (this.props.listcommentateursContreId.includes(idperson)) {
      stateReturn = "contre"
    }


    // if (idperson === 61) {
    this.props.showConsoleLog && console.log("alreadySelectedCheck -> idperson", idperson)
    this.props.showConsoleLog && console.log("alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("alreadySelectedCheck -> includes", this.props.listcommentateursContreId.includes(idperson))
    this.props.showConsoleLog && console.log("alreadySelectedCheck -> stateReturn", stateReturn)
    // }

    return stateReturn

  }


  getPersonNameByID = IDperson => {
    var listperson = this.props.listperson
    var personName = 'person not found'
    IDperson = Number(IDperson)

    this.props.showConsoleLog && console.log('getPersonNameByID -> IDperson : ', IDperson)
    this.props.showConsoleLog && console.log('getPersonNameByID -> listperson : ', listperson)

    listperson !== undefined && listperson !== null &&
      listperson.forEach((element) => {

        if (element.idperson === IDperson) {
          personName = element.pers_name;
          this.props.showConsoleLog && console.log('getPersonNameByID -> personne trouvée : ', element.pers_name)
        }
      })

    return personName
  }


  getAttributFromArrayOfObject(arrayOfObject, searchAttribute, searchValue, returnAttribute) {
    var attributeValue = ''
    var result = ''

    arrayOfObject.forEach((element, index) => {
      // this.props.showConsoleLog && console.log('getAttributFromArrayOfObject -> element : ', element)   
      attributeValue = $(element).attr(searchAttribute)
      // this.props.showConsoleLog && console.log('getAttributFromArrayOfObject -> attributeValue : ', attributeValue) 

      if (attributeValue === searchValue) {
        // this.props.showConsoleLog && console.log('getAttributFromArrayOfObject -> return : ', $(element).attr(returnAttribute)) 
        result = $(element).attr(returnAttribute)
      }
    })

    return result
  }



  transformArrayToSelectObject = (arrayOfObjects, attributeOfValue, attributeOfLabel) => {
    let selectObject = []

    this.props.showConsoleLog && console.log('transformArrayToSelectObject -> arrayOfObjects : ', arrayOfObjects)
    // this.props.showConsoleLog && console.log('transformArrayToSelectObject -> arrayIndexes : ', arrayIndexes)
    // this.props.showConsoleLog && console.log('transformArrayToSelectObject -> element : ', element, ' // index : ', index, ' // array.length : ', array.length)

    if (attributeOfValue !== undefined && attributeOfLabel !== undefined) {
      arrayOfObjects.forEach((element, index) => {
        selectObject.push({ value: $(element).attr(attributeOfValue), label: $(element).attr(attributeOfLabel) })
      })
    }
    else {
      selectObject.push({ value: "error", label: "error" })
    }


    return selectObject
  }


  transformArrayToSelectObjectWithAttribute = (arrayOfObjects, attribute, subAttributeOfValue, subAttributeOfLabel) => {
    let selectObject = []

    // this.props.showConsoleLog && console.log('transformArrayToSelectObjectWithAttribute -> arrayOfObjects : ', arrayOfObjects)

    // this.props.showConsoleLog && console.log('transformArrayToSelectObject -> arrayIndexes : ', arrayIndexes)
    // this.props.showConsoleLog && console.log('transformArrayToSelectObject -> element : ', element, ' // index : ', index, ' // array.length : ', array.length)

    arrayOfObjects.forEach((element, index) => {
      let { person } = element;
      // this.props.showConsoleLog && console.log('transformArrayToSelectObjectWithAttribute -> person : ', person)
      selectObject.push({ value: $(person).attr(subAttributeOfValue), label: $(person).attr(subAttributeOfLabel) })
    })

    return selectObject
  }


  replaceAll(string, search, replace) {
    let returnString = ""
    if (string !== "" && string !== null && string !== undefined) {
      returnString = string.split(search).join(replace)
    }
    return returnString
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  selectFieldContent = (row) => {
    // const returnContent = <div></div>

    this.props.showConsoleLog && console.log("row.alreadySelected :", row.alreadySelected)
    this.props.showConsoleLog && console.log("row.alreadySelected test :", row.alreadySelected === "not selected")

    if (row.alreadySelected === "not selected") {
      return <button
        className="noBorder "
        style={{ backgroundColor: 'transparent' }}
        onClick={() => {
          this.changeSelectedPerson(row.id)
        }}
      >
        {row.selected ? <CheckCircleFillIcon size={24} /> : <CircleIcon size={24} />}
      </button>
    } else if (row.alreadySelected === "pour") {
      return <div className="commentateurTextPour ">
        <CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 && <div className="w-100 text-center " >Déjà pour</div>}
      </div>
    } else if (row.alreadySelected === "contre") {
      return <div className="commentateurTextContre"><CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 && <div className="w-100 text-center " >Déjà contre</div>}
      </div>
    } else if (row.alreadySelected === "peut etre") {
      return <div className="commentateurTextPeutEtre"><CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 && <div className="w-100 text-center " >Déjà sous condition</div>}
      </div>
    } else {
      return <div></div>
    }


  }

  getMediaFromArrayOfMediaList = (idperson) => {
    const listmedia = this.props.listmedia
    const mediaObject = {}

    this.props.showConsoleLog && console.log('getMediaFromArrayOfMediaList -> listmedia :', listmedia)

    if (listmedia !== undefined && idperson !== 0) {
      listmedia.forEach((element) => {
        if (element.person.idperson === idperson) {
          Object.assign(mediaObject, element.person)
        }
      })
    }

    return mediaObject
  }


  getPersonFromArrayOfPersonList(idperson) {
    const listperson = this.props.listperson
    const personObject = {}

    if (listperson !== null && idperson !== 0) {
      listperson.forEach((element) => {
        if (element.idperson === idperson) {
          Object.assign(personObject, element)
        }
      })
    }

    return personObject
  }


  setReferenceNewAttributeValue = (attributeName, attributeValue, attributeValue2) => {

    this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeName : ", attributeName)
    this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeValue : ", attributeValue)

    if (attributeName === "re_sourcePrimaire") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_sourcePrimaire: attributeValue } })
    }
    if (attributeName === "mediaSelection") {
      let tempSelectedMedia = this.getMediaFromArrayOfMediaList(attributeValue)
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> tempSelectedMedia : ", tempSelectedMedia)
      this.setState({
        referenceNew: {
          ...this.state.referenceNew,
          media_idperson: tempSelectedMedia.idperson,
          media_orga_typeID: tempSelectedMedia.orga_typeID,
          media_ot_title: tempSelectedMedia.ot_title,
          media_pers_picture: tempSelectedMedia.pers_picture,
          media_pers_title: tempSelectedMedia.pers_name,
          media_pers_type: tempSelectedMedia.pers_type,
        }
      })
    }
    if (attributeName === "ref_type") {
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeValue : ", attributeValue)
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> listreferencetype : ", this.props.listreferencetype)
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> listreferencetype[] : ", this.props.listreferencetype[attributeValue - 1].type_title)

      this.setState({ referenceNew: { ...this.state.referenceNew, ref_type_id: attributeValue, ref_type_title: this.props.listreferencetype[attributeValue - 1].type_title } })
    }
    if (attributeName === "ref_link") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_link: attributeValue, re_videoStart: 0.0001, re_videoEnd: 1 } })
    }
    if (attributeName === "ref_linkANDref_title") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_link: attributeValue, ref_linkTitle: attributeValue2, re_videoStart: 0.0001, re_videoEnd: 1 } })
    }
    if (attributeName === "ref_linkTitle") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_linkTitle: attributeValue } })
    }
    if (attributeName === "ref_title") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_title: attributeValue } })
    }
    if (attributeName === "ref_dateParution") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_dateParution: attributeValue } })
    }
    if (attributeName === "initVideoExtract") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoStart: 0.0001, re_videoEnd: 1 } })
    }
    if (attributeName === "re_proposDirect") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_proposDirect: attributeValue } })
    }
    if (attributeName === "re_proposRapporte") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_proposRapporte: attributeValue } })
    }
    if (attributeName === "re_videoStartEnd") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoStart: attributeValue, re_videoEnd: attributeValue2 } })
    }
    if (attributeName === "ref_BroadcastTitle") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_BroadcastTitle: attributeValue } })
    }

  }


  changeLabelValue = (labelName, labelValue) => {

    if (labelName === "CarouselPrimaireIndex") {
      this.setState({ CarouselPrimaireIndex: labelValue })
    }
    if (labelName === "CarouselSecondaireIndex") {
      this.setState({ CarouselSecondaireIndex: labelValue })
    }
    if (labelName === "both") {
      this.setState({ CarouselPrimaireIndex: labelValue, CarouselSecondaireIndex: labelValue })
    }

  }


  render() {
    const url_image = this.props.url_image

    const listperson = this.props.listperson

    this.props.showConsoleLog && console.log('ComponentSources -> listperson : ', listperson)
    this.props.showConsoleLog && console.log('ComponentSources -> referenceList : ', this.props.referenceList)

    const selectedDeletePerson = this.getPersonFromArrayOfPersonList(this.state.personToDeleteSelected)

    const selectOptionsType = {
      'Personne': 'Personne',
      'Organisation': 'Organisation',
      // 'Non renseigné': 'Non renseigné'
    }

    const afterFilter = this.afterFilter


    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      if (active) {
        activeStyle.backgroundColor = 'black';
        activeStyle.color = 'white';
      } else {
        activeStyle.backgroundColor = 'gray';
        activeStyle.color = 'black';
      }
      if (typeof page === 'string') {
        activeStyle.backgroundColor = 'white';
        activeStyle.color = 'black';
      }
      return (
        <li key={`pageButtonRender_${page}`} className="page-item">
          <a href="#" className="p-2 pr-3 pl-3" onClick={handleClick} style={activeStyle}>{page}</a>
        </li>
      );
    };

    const options = {
      pageButtonRenderer
    };


    return (

      <div className="">

        {
          <div className='col-lg-12 p-0'>

            {this.props.showConsoleLog && console.log("this.props.referenceList", this.props.referenceList)}
            {this.props.showConsoleLog && console.log("this.props.type", this.props.type)}

            {/* Visualiser les sources */}
            {
              <div className="w-100 m-0 p-0 ">

                <div className="d-flex flex-row m-0 p-0 justify-content-around">

                  {this.props.showConsoleLog && console.log("nbSP referenceList :", this.props.referenceList)}
                  {this.props.showConsoleLog && console.log("nbSP nbSourcePrimaire :", this.props.nbSourcePrimaire)}
                  {this.props.showConsoleLog && console.log("nbSP CarouselPrimaireIndex :", this.state.CarouselPrimaireIndex)}

                  {this.props.referenceList.length > 0 && this.props.nbSourcePrimaire > 0 &&
                    <div className={"col-12 m-0 p-1 pb-4 ".concat(this.props.nbSourceSecondaire === 0 ? "col-lg-10 " : "col-lg-6 ")}>
                      <Carousel
                        controls={false}
                        activeIndex={this.state.CarouselPrimaireIndex}
                      /* onSlide={() => (
                        this.props.showConsoleLog && console.log("Carousel change : ", relanconsDebatsCurrentIndexes[(this.state.relanconsDebatsCurrentIndex + 1) % relanconsDebatsCurrentIndexes.length]),
                        this.setState({
                          relanconsDebatsIndexAccumulator: this.state.relanconsDebatsIndexAccumulator + 1,
                          relanconsDebatsCurrentIndex: relanconsDebatsCurrentIndexes[(this.state.relanconsDebatsIndexAccumulator + 1) % relanconsDebatsCurrentIndexes.length]
                        })
                      )} */
                      >

                        {this.props.referenceList
                          .filter((element) => element.re_sourcePrimaire)
                          .map((element, index) => (
                            <Carousel.Item key={`carouselSourcePrimaire_${index}`} >

                              <ComponentReference
                                quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                                addOrModifyOrVisualize={this.props.addOrModifyOrVisualize}
                                addPosition={this.props.addPosition}
                                quoteID={this.props.quoteID}
                                quoteLinkID={this.props.quoteLinkID}
                                referenceType="visualisation"
                                sourceType="primaire"
                                uniqueID={"visualisationPrimaire_".concat(index)}
                                qt_title={this.props.qt_title}

                                index={index}
                                indexMax={this.props.nbSourcePrimaire}
                                listmedia={this.props.listmedia}
                                listperson={this.props.listperson}
                                listreferencetype={this.props.listreferencetype}
                                listmediatype={this.props.listmediatype}
                                commentateurData={this.props.commentateurData}

                                reference={element}

                                capitalizeFirstLetter={this.capitalizeFirstLetter}
                                sourceSelectedChange={this.sourceSelectedChange}
                                setReferenceNewAttributeValue={this.setReferenceNewAttributeValue}
                                commentateursAPIs={this.props.commentateursAPIs}
                                changeLabelValue={this.changeLabelValue}

                                selectReferenceIndexToChange={this.props.selectReferenceIndexToChange}
                                deleteReference={this.props.deleteReference}

                                showConsoleLog={this.props.showConsoleLog}

                                miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                                ReferenceAPIs={this.props.ReferenceAPIs}
                                CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                                url_image={this.props.url_image}


                                visitorLocation={this.props.visitorLocation}
                                queryTracker={this.props.queryTracker}
                                spot_api={this.props.spot_api}
                                API_key={this.props.API_key}
                              />

                            </Carousel.Item>
                          ))}

                      </Carousel>

                    </div>
                  }

                  {this.props.referenceList.length > 0 && this.props.nbSourceSecondaire > 0 &&
                    <div className={"col-12 m-0 p-1 pb-4 ".concat(this.props.nbSourcePrimaire === 0 ? "col-lg-10 " : "col-lg-6 ")}>
                      <Carousel
                        controls={false}
                        activeIndex={this.state.CarouselSecondaireIndex}
                      >

                        {this.props.referenceList
                          .filter((element) => element.re_sourcePrimaire === null || element.re_sourcePrimaire !== true)
                          .map((element, index) => (
                            <Carousel.Item key={`carouselSourceSecondaire_${index}`}>

                              <ComponentReference
                                quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                                addOrModifyOrVisualize={this.props.addOrModifyOrVisualize}
                                addPosition={this.props.addPosition}
                                quoteID={this.props.quoteID}
                                quoteLinkID={this.props.quoteLinkID}
                                referenceType="visualisation"
                                sourceType="secondaire"
                                uniqueID={"visualisationSecondaire_".concat(index)}
                                qt_title={this.props.qt_title}

                                index={index}
                                indexMax={this.props.nbSourceSecondaire}
                                listmedia={this.props.listmedia}
                                listperson={this.props.listperson}
                                listreferencetype={this.props.listreferencetype}
                                listmediatype={this.props.listmediatype}
                                commentateurData={this.props.commentateurData}

                                reference={element}

                                listcommentateursPourId={this.props.listcommentateursPourId}
                                listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}
                                listcommentateursContreId={this.props.listcommentateursContreId}

                                capitalizeFirstLetter={this.capitalizeFirstLetter}
                                sourceSelectedChange={this.sourceSelectedChange}
                                setReferenceNewAttributeValue={this.setReferenceNewAttributeValue}
                                commentateursAPIs={this.props.commentateursAPIs}
                                changeLabelValue={this.changeLabelValue}

                                selectReferenceIndexToChange={this.props.selectReferenceIndexToChange}
                                deleteReference={this.props.deleteReference}

                                showConsoleLog={this.props.showConsoleLog}

                                miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                                ReferenceAPIs={this.props.ReferenceAPIs}
                                CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                                url_image={this.props.url_image}


                                visitorLocation={this.props.visitorLocation}
                                queryTracker={this.props.queryTracker}
                                spot_api={this.props.spot_api}
                                API_key={this.props.API_key}
                              />

                            </Carousel.Item>
                          ))}

                      </Carousel>

                    </div>
                  }

                </div>

              </div>
            }

          </div>
        }

      </div >

    )
  }

}
export default ComponentSources