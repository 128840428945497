import React, { Component } from 'react';
import { RocketIcon } from '@primer/octicons-react'

import ComponentBandeauHautDePage from './ComponentBandeauHautDePage'
import ComponentFooter from './ComponentFooter'
import ComponentFormulaireQuestionnaireContact from './ComponentFormulaireQuestionnaireContact'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';


//Elements API
// import { ApiClient, ReferenceApi } from 'spot_api_old'


class PageAPropos extends Component {

  state = {
    showConsoleLog: true,

    displayGoldPersons: false,
  }

  componentDidMount() {
    // this.initData()
  }

  initData = () => {

  }

  componentDidUpdate() {

  }



  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {

    this.props.showConsoleLog && console.log('SourcePage -> referenceDetails : ', this.state.referenceDetails)

    return (
      <div className="">

        <ComponentBandeauHautDePage
          url_image={this.props.url_image}
          pageName="intervenant"
          pageRechercheDisplay={this.props.pageRechercheDisplay}
        />

        <div className="container " id="SourcePage">

          <div className={"d-flex col-12 col-lg-12 m-0 p-0 justify-content-center  "} style={{ height: '366px', position: 'relative' }}>

            <div
              className="d-flex align-items-center col-12 col-lg-9 m-0 p-5  "
              style={{ position: window.innerWidth > 575.98 ? "relative" : "absolute", zIndex: 40 }}
            >
              <div className="d-flex flex-column " >
                <div className="d-flex bandeauAccueilBox1Format1 ">Spot en bref</div>
                <div
                  className="col-lg-11 mt-4 p-0 text-justify bandeauAccueilBox1Format2 "
                  style={{ fontSize: window.innerWidth > 575.98 ? "14px" : "12px" }}
                >
                  SPOT permet pour chaque débat de société <em style={{ fontWeight: 'bold' }}> de rassembler et d’organiser de manière collaborative </em>
                  l’ensemble des informations déjà publiées dans les médias et sur les réseaux sociaux.

                </div>
                <div
                  className="col-lg-11 mt-2 p-0 text-justify bandeauAccueilBox1Format2"
                  style={{ fontSize: window.innerWidth > 575.98 ? "14px" : "12px" }}
                >
                  Ce projet
                  met en pratique <em style={{ fontWeight: 'bold' }}>{' les méthodes de l’éducation aux médias et à l’information '}</em>
                  : vérification des sources, distinction fait/opinion etc.

                </div>
                <div
                  className="col-lg-11 mt-2 p-0 text-justify bandeauAccueilBox1Format2"
                  style={{ fontSize: window.innerWidth > 575.98 ? "14px" : "12px" }}
                >
                  Association Loi 1901, SPOT est aussi une plateforme digitale neutre.

                </div>
              </div>
            </div>

            <div className="col-12 col-lg-3 m-0 p-0 rounded " style={{ position: 'relative', zIndex: 30, backgroundColor: true ? "#FFF" : "#E5F1FD" }}>

            </div>

          </div>

          <div className='d-flex flex-row mt-5 '></div>

          <div className="d-flex flex-column w-100 mt-5 pt-3 pb-4 " style={{ backgroundColor: '#F3F3F3' }}>
            <div className="d-flex flex-row bandeauAccueilBox1Format1 col-lg-12 justify-content-center ">Nos valeurs</div>
            <div className={"d-flex ".concat(window.innerWidth > 575.98 ? "flex-row " : "flex-column ", " justify-content-around")}>
              {[{
                valeur: 'Transparence',
                texte: '… bientôt Open Source',
                image: 'valeurs_loupe.png'
              },
              {
                valeur: 'Neutralité',
                texte: '… réunit l’éventail des points du vue exprimés',
                image: 'valeurs_balance.png'
              },
              {
                valeur: 'Rigueur',
                texte: '… priorité à l’argumentation basée sur les faits',
                image: 'valeurs_bouton.png'
              }
              ].map((element) => (
                <div className="d-flex flex-column col-10 col-lg-3 mt-4 ml-auto mr-auto aproposValeursBox " >

                  <div className="d-flex flex-row pb-2 " style={{ height: '100%' }}>
                    <div className="d-flex col-3 col-lg-3 justify-content-center align-items-center m-0 " >
                      <img
                        className="" // img-fluid
                        src={this.props.url_image + element.image}
                        alt=""
                        style={{ height: '60px' }}

                      />
                    </div>
                    <div className="col-9 col-lg-9 m-0 p-0  " >
                      <div className="text-center aproposValeursTitre pt-2 pl-2 ">{element.valeur}</div>
                      <div className="text-center aproposValeursTexte pt-2 pl-2 ">{element.texte}</div>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>


          <div className="d-flex flex-column w-100 mt-5 pt-3 pb-4 " style={{ backgroundColor: '#FCF2D3' }}>
            <div className="d-flex flex-row bandeauAccueilBox1Format1 col-lg-12 justify-content-center ">3 façons de nous soutenir</div>
            <div className={"d-flex ".concat(window.innerWidth > 575.98 ? "flex-row " : "flex-column ", " justify-content-around")}>
              {[{
                id: 1,
                titre: 'Contribuez aux débats',
                texte: "Spot est un espace d'organisation des débats fait pour les citoyens et par les citoyens. A chaque fois que vous sourcez une information, vous aidez de nombreuses personnes à s'orienter dans le débat d'idées.",
                image: 'aidezNous_contribuez.png',
                boutonText: "Je crée un débat",
                boutonLink: "/",
              },
              {
                id: 2,
                titre: 'Faites un don',
                texte: 'Spot est un association de loi 1901, les dons servent au développement de nos activités : hébergement web, domiciliation, services web etc.',
                image: 'aidezNous_faitesUnDon.png',
                boutonText: "Je fais un don",
                boutonLink: "https://www.helloasso.com/associations/spot-l-encyclopedie-du-debat-public/formulaires/1/widget",
              },
              {
                id: 3,
                titre: 'Améliorons le débat public ensemble !',
                texte: "Développeur Web, data analyst, juriste, designer, community manager, citoyen désireux de s'impliquer dans le débat de sa ville, nous serions ravis de pouvoir avancer avec vous !",
                image: 'aidezNous_devenezMembre.png',
                boutonText: "Je contacte Spot",
                boutonLink: "",
              },
              ].map((element) => (
                <div className="d-flex flex-column col-11 col-lg-3 mt-4 ml-auto mr-auto aproposValeursBox " style={{ height: '100%' }}>

                  <div className="d-flex flex-row col-lg-12 justify-content-center align-items-center m-0 pt-4 ">
                    <img
                      className="" // img-fluid
                      src={this.props.url_image + element.image}
                      alt=""
                      style={{ height: '200px' }}

                    />
                  </div>

                  <div className="d-flex flex-row justify-content-center align-items-center text-center aproposValeursTitre p-2" style={{ height: '60px' }}>{element.titre}</div>
                  <div className="d-flex flex-column aproposValeursTexte text-justify p-2 mb-2 " style={{ height: '140px' }}>
                    <div className="">{element.texte}</div>
                    {element.titre === "Faites un don" &&
                      <div className="aproposValeursTexte text-justify mt-2" >Retrouvez le détail du budget de Spot au format Excel <a href='/Spot - Historique budget.xlsx' download>{"\xa0ici"}</a></div>
                    }
                  </div>

                  <div className="d-flex flex-row justify-content-center align-items-center text-center aproposValeursTitre p-2 mb-4 " >
                    {element.id !== 3 &&
                      <Button
                        // onClick={() => { }}
                        href={element.boutonLink}
                        target={element.id === 2 ? "_blank" : ""}
                        className="d-inline-flex justify-content-center align-items-center jeContribueBox col-lg-8 m-0 p-1 "
                        style={{ backgroundColor: "#E55C5C", borderColor: "#E55C5C" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                      >
                        <div className="ml-3 mr-3 mt-1 mb-1">
                          {element.boutonText.toUpperCase()}
                        </div>
                      </Button>
                    }

                    {element.id === 3 &&
                      <ComponentFormulaireQuestionnaireContact />
                    }
                  </div>

                  {/*
                  <iframe id="haWidget" allowtransparency="true" src="https://www.helloasso.com/associations/spot-l-encyclopedie-du-debat-public/formulaires/1/widget-bouton" style={{ width: "100%", height: "70px", border: "none" }}></iframe>
                  <div style={{ width: "100%", textAlign: "center" }}>Propulsé par <a href="https://www.helloasso.com" rel="nofollow">HelloAsso</a></div>
                  */}

                </div>
              ))}
            </div>
          </div>


          {true &&
            <div className="d-flex flex-column col-lg-12 mt-5 pt-3 pb-4 " style={{ backgroundColor: '#F3F3F3' }}>
              <div className="d-flex flex-row bandeauAccueilBox1Format1 col-lg-12 justify-content-center ">Nos partenaires</div>

              <div className={"d-flex ".concat(window.innerWidth > 575.98 ? "flex-row " : "flex-column ", " justify-content-around")}>
                {[
                  { nom: 'Latitudes', siteWeb: 'https://www.latitudes.cc/', logo: 'partenaire_Latitudes.png', height: '60px' },
                  // { nom: "ESILV - Ecole d'ingénieurs", siteWeb: 'https://www.esilv.fr/', logo: 'partenaire_Esilv.png', height: '110px' },
                  { nom: 'e-Artsup - Ecode de Design', siteWeb: 'https://www.e-artsup.net/', logo: 'partenaire_eArtsup.png', height: '110px' },
                ].map((element) => (
                  <Button
                    className="d-flex flex-row justify-content-center align-items-center col-lg-3 mt-4 aproposValeursBox "
                    variant={'outline-danger'}
                    href={element.siteWeb}
                    target="_blank"
                  >

                    <img
                      className="rounded" // img-fluid
                      src={this.props.url_image + element.logo}
                      alt=""
                      style={{ height: element.height }}

                    />

                  </Button>
                ))}
              </div>




            </div>
          }


          <div className="d-flex flex-column justify-content-center w-100 mt-5 pt-3 pb-4 " style={{ backgroundColor: '#FFF' }}>
            <div className="d-flex flex-row bandeauAccueilBox1Format1 col-lg-12 justify-content-center ">Ils ont contribué à Spot</div>

            <div className="d-flex flex-column justify-content-center mt-5 ">
              <div className="d-flex flex-row justify-content-start ml-5 pl-5 mt-3">Développement Web / Data</div>
              <div className="d-flex flex-row justify-content-center mt-3">
                <table className="table col-lg-10">
                  <thead>
                    <tr>
                      <th scope="col">Nom</th>
                      {window.innerWidth > 575.98 && <th scope="col">Période</th>}
                      {window.innerWidth > 575.98 && <th scope="col">Compétences</th>}
                      <th scope="col">Page perso</th>
                    </tr>
                  </thead>

                  {[
                    { nom: 'Pierre C.', goldPerson: true, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                    { nom: 'Tanisha GOMIS', goldPerson: true, periode: 'Sept. 19 - Août 20', competences: 'dév Web : BDD structurée, ReactJS, APIs', pagePersoType: "linkedin", pagePerso: 'https://www.linkedin.com/in/tanisha-gomis-465654173/' },
                    { nom: 'Ambre BOURDIER', goldPerson: false, periode: 'Sept. 19 - Mars 20', competences: 'dév Web : BDD structurée', pagePersoType: "linkedin", pagePerso: 'https://www.linkedin.com/in/ambre-bourdier-091791156/' },
                    { nom: 'Zayneb AMINE', goldPerson: false, periode: 'Sept. 19 - Mars 20', competences: 'dév Web : BDD structurée', pagePersoType: "linkedin", pagePerso: 'https://www.linkedin.com/in/zayneb-amine-4b621b170/' },

                    { nom: 'Nicolas MERY', goldPerson: true, periode: 'Sept. 20 - Mars 21', competences: 'data : python, BDD relationnelle, Open Data ; Docker', pagePersoType: "", pagePerso: '' },
                    { nom: 'Pierre CENTA', goldPerson: false, periode: 'Sept. 20 - Mars 21', competences: 'data : python, APIs', pagePersoType: "", pagePerso: '' },
                    { nom: 'Louis-Raphaël VINTELER', goldPerson: false, periode: 'Sept. 20 - Mars 21', competences: 'data : python, APIs', pagePersoType: "", pagePerso: '' },
                    { nom: 'Cédric HANSEN', goldPerson: false, periode: 'Sept. 20 - Mars 21', competences: 'data : python, APIs', pagePersoType: "", pagePerso: '' },
                    { nom: 'Nicolas VANACKER', goldPerson: false, periode: 'Sept. 20 - Mars 21', competences: 'data : python, APIs', pagePersoType: "", pagePerso: '' },

                    { nom: 'Théodore', goldPerson: false, periode: 'Nov 2021', competences: 'python, partitionnement data', pagePersoType: "", pagePerso: '' },
                    { nom: 'Nacim O.', goldPerson: false, periode: 'Nov 2021', competences: 'python, partitionnement data', pagePersoType: "pagePerso", pagePerso: 'https://liris.cnrs.fr/en/member-page/nacim-oijid' },
                    { nom: 'Arefe', goldPerson: false, periode: 'Nov 2021', competences: 'python, partitionnement data', pagePersoType: "", pagePerso: '' },

                    { nom: 'Jordan T.', goldPerson: false, periode: '', competences: '', pagePersoType: "", pagePerso: '' },

                    { nom: '', periode: '', competences: '', pagePersoType: "", pagePerso: '' },

                  ]
                    .map((element) => (
                      <tr>
                        <th scope="row">{element.nom}{element.goldPerson && <span className="pl-3" style={{ color: "#e55c5c" }} >{this.state.displayGoldPersons && <RocketIcon size={18} />}</span>}</th>
                        {window.innerWidth > 575.98 && <td>{element.periode}</td>}
                        {window.innerWidth > 575.98 && <td>{element.competences}</td>}
                        <td>
                          <a href={element.pagePerso} target="_blank">
                            {element.pagePersoType === 'linkedin' ? "LinkedIn" :
                              (element.pagePersoType === 'pagePerso' ? "Site" : "")}
                          </a>
                        </td>
                      </tr>
                    ))}
                  <tbody>
                  </tbody>

                </table>

              </div>

            </div>


            {this.state.displayGoldPersons &&
              <div>
                <span className="ml-5 pl-5 mb-5 " style={{ color: "#e55c5c" }} >{this.state.displayGoldPersons && <RocketIcon size={18} />}</span>
                <span> : ont permis à Spot de franchir des étapes décisives</span>
              </div>
            }


            <div className="d-flex flex-column justify-content-center mt-5 mb-5 ">
              <div className="d-flex flex-row justify-content-start ml-5 pl-5 mt-3">Design / graphisme</div>
              <div className="d-flex flex-row justify-content-center mt-3">
                <table className="table col-lg-10">
                  <thead>
                    <tr>
                      <th scope="col">Nom</th>
                      {window.innerWidth > 575.98 && <th scope="col">Période</th>}
                      {window.innerWidth > 575.98 && <th scope="col">Compétences</th>}
                      <th scope="col">Page perso</th>
                    </tr>
                  </thead>

                  {[
                    { nom: 'Julien VIEIRA', goldPerson: true, periode: 'Fév 20 - Juin 21', competences: 'Enseignant en Design', pagePersoType: "pagePerso", pagePerso: 'https://vieira-julien.fr' },
                    { nom: 'Rodolphe H.', goldPerson: true, periode: 'Fév 20 - Juin 21', competences: 'Enseignant en Design', pagePersoType: "", pagePerso: '' },
                    { nom: 'Marine PITON', goldPerson: true, periode: 'Fév 20 - Sept 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "pagePerso", pagePerso: 'https://marinepiton.com/' },
                    { nom: 'Chloé PROTAS', goldPerson: false, periode: 'Fév 20 - Juin 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "", pagePerso: '' }, // PROTAS
                    { nom: 'Charlotte BRUNET', goldPerson: false, periode: 'Fév 20 - Juin 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "", pagePerso: '' }, // 
                    { nom: 'Pierre D.', goldPerson: false, periode: 'Fév 20 - Juin 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "", pagePerso: '' }, // DEJONGHE
                    { nom: 'Thomas D.', goldPerson: false, periode: 'Fév 20 - Juin 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "", pagePerso: '' }, // 
                    { nom: 'Alexis Q.', goldPerson: false, periode: 'Fév 20 - Juin 20', competences: 'Charte graphique, Logo, UX / UI', pagePersoType: "", pagePerso: '' }, // 

                    { nom: 'Claire DARFEUIL', goldPerson: false, periode: 'Fév 21 - Juin 21', competences: 'Campagne réseaux sociaux', pagePersoType: "pagePerso", pagePerso: 'http://www.kuleru.fr/' },
                    { nom: 'Anthony D.S.', goldPerson: false, periode: 'Fév 21 - Juin 21', competences: 'Campagne réseaux sociaux', pagePersoType: "", pagePerso: '' }, // 
                    { nom: 'Lila DUQUET', goldPerson: false, periode: 'Fév 21 - Juin 21', competences: 'Campagne réseaux sociaux', pagePersoType: "pagePerso", pagePerso: 'http://liladuquet.fr/' },

                    { nom: 'Lea MONNOT', goldPerson: true, periode: 'Fév 21 - Juin 21', competences: 'Charte graphique, UX / UI', pagePersoType: "", pagePerso: '' }, // 
                    { nom: 'Tess MAILLOT', goldPerson: true, periode: 'Fév 21 - Juin 21', competences: 'Charte graphique, UX / UI', pagePersoType: "", pagePerso: '' }, // 
                    { nom: 'Agathe LEMOINE', goldPerson: true, periode: 'Fév 21 - Juin 21', competences: 'Charte graphique, UX / UI', pagePersoType: "", pagePerso: '' }, // 

                    { nom: '', periode: '', competences: '', pagePersoType: "", pagePerso: '' },

                  ]
                    .map((element) => (
                      <tr>
                        <th scope="row">{element.nom}{element.goldPerson && <span className="pl-3" style={{ color: "#e55c5c" }} >{this.state.displayGoldPersons && <RocketIcon size={18} />}</span>}</th>
                        {window.innerWidth > 575.98 && <td>{element.periode}</td>}
                        {window.innerWidth > 575.98 && <td>{element.competences}</td>}
                        <td>
                          <a href={element.pagePerso} target="_blank">
                            {element.pagePersoType === 'linkedin' ? "LinkedIn" :
                              (element.pagePersoType === 'pagePerso' ? "Site" : "")}
                          </a>
                        </td>
                      </tr>
                    ))}
                  <tbody>
                  </tbody>
                </table>
              </div>

            </div>

            <div className="d-flex flex-column justify-content-center mb-5 ">
              <div className="d-flex flex-row justify-content-start ml-5 pl-5 mt-3">Suivi de projet</div>
              <div className="d-flex flex-row justify-content-center mt-3">
                <table className="table col-lg-10">
                  <thead>
                    <tr>
                      <th scope="col">Nom</th>
                      {window.innerWidth > 575.98 && <th scope="col">Période</th>}
                      {window.innerWidth > 575.98 && <th scope="col">Compétences</th>}
                      {window.innerWidth > 575.98 && <th scope="col">Page perso</th>}
                    </tr>
                  </thead>

                  {[
                    { nom: 'Aude B.', goldPerson: false, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                    { nom: 'Ghislain B.', goldPerson: false, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                    { nom: 'Nathalie S.D.', goldPerson: false, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                    { nom: 'Aurélien D.', goldPerson: true, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                    { nom: 'Jean-Michel M.', goldPerson: false, periode: '', competences: '', pagePersoType: "", pagePerso: '' },
                  ]
                    .map((element) => (
                      <tr>
                        <th scope="row">{element.nom}{element.goldPerson && <span className="pl-3" style={{ color: "#e55c5c" }} >{this.state.displayGoldPersons && <RocketIcon size={18} />}</span>}</th>
                        {window.innerWidth > 575.98 && <td>{element.periode}</td>}
                        {window.innerWidth > 575.98 && <td>{element.competences}</td>}
                        {window.innerWidth > 575.98 &&
                          <td>
                            <a href={element.pagePerso} target="_blank">
                              {element.pagePersoType === 'linkedin' ? "LinkedIn" :
                                (element.pagePersoType === 'pagePerso' ? "Site" : "")}
                            </a>
                          </td>
                        }
                      </tr>
                    ))}
                  <tbody>
                  </tbody>
                </table>
              </div>

            </div>

            <div className="d-flex flex-row justify-content-around">
              <img
                className="" // img-fluid
                src={this.props.url_image + "apropos_IlsOntContribue.png"}
                alt=""
                style={{ height: '160px' }}

              />

            </div>
          </div>


        </div>


        {/* FOOTER */}
        <ComponentFooter
          url_image={this.props.url_image}
        />

      </div >
    )
  }
}


export default PageAPropos
