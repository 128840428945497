import React, { Component } from 'react';

import { SquareFillIcon } from '@primer/octicons-react'

import Card from 'react-bootstrap/Card'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';

class ComponentArgumentSmall extends Component {

  state = {
    showConsoleLog: true,
  }

  componentDidMount() {
  }


  render() {

    this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> argument : ", this.props.argument)

    const completude = 65  // Math.round(Math.random() * 100)

    return (
      <Card
        className="m-0 p-0 "
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <Card.Header className="m-0 p-0 " style={{ backgroundColor: 'transparent', border: 'none' }}>

          <Button
            className={"m-0 pl-3 pr-3 pt-1 pb-1 w-100 ".concat(this.props.argumentsSelected.length === 0 || (this.props.argumentsSelected.length > 0 && this.props.argumentsSelected.indexOf(this.props.argument.ql_supportID) > -1 ? '' : 'titre_neutre'))}
            style={{
              backgroundColor:
                this.props.argument.qlt_title === "pour" ? '#E5F1FD' :
                  (this.props.argument.qlt_title === "contre" ? 'rgba(186, 194, 228, 0.5)' :
                    (this.props.argument.qlt_title === "quoi" ? '' :
                      (this.props.argument.qlt_title === "chronologie" ? '#faf1d0' : ""))),
              borderRadius: '5px',
              border: this.props.argumentsSelected.length === 0 || (this.props.argumentsSelected.length > 0 && this.props.argumentsSelected.indexOf(this.props.argument.ql_supportID) > -1) ? 'solid' : 'solid',
              borderWidth: '1px',
              borderColor: this.props.argument.qlt_title === "pour" ? '#E5F1FD' :
                (this.props.argument.qlt_title === "contre" ? 'rgba(186, 194, 228, 0.5)' : '')
            }}
            // argumentSelection argumentsSelected
            onClick={() => this.props.argumentSelection(this.props.argument.ql_supportID)}
          >

            {/* Affichage du titre */}
            <div
              className="d-flex flex-row w-100 m-0 p-0 quoiTexte text-left robotoFont13px"
              style={{ fontSize: window.innerWidth > 576 ? "14px" : "12px" }}
            >
              {this.props.argument.quote.qt_title !== "évènement" &&
                <div>
                  {/* Intro */}
                  {false && <span style={{ color: 'grey' }}>{this.props.argument.quote.intro.concat(" ... ")}</span>}
                  {/* Titre */}
                  <span>{this.props.argument.quote.q_title}</span>
                </div>
              }

              {this.props.argument.quote.qt_title === "évènement" &&
                <div>
                  <div style={{ color: 'grey' }}>
                    {this.props.capitalizeFirstLetter(this.props.introDate2(this.props.argument).concat(' ...'))}
                  </div>
                  <div>{this.props.capitalizeFirstLetter(this.props.argument.quote.q_title)}</div>
                </div>
              }
            </div>

          </Button>

        </Card.Header>




        {/* Nature de l'information */}
        <div className="d-flex flex-row col-12 col-lg-12 mt-0 ">

          {
            (this.props.argument.quote.qt_title === "fait" || this.props.argument.quote.qt_title === "projection future") &&
            // this.props.argument.quote.completude !== undefined &&
            <div className="d-flex flex-row justify-content-start align-items-center col-lg-10 pt-0 ">
              <div className="footer mr-3">Niveau de preuve :</div>
              <ProgressBar
                variant={completude < 50 ? "danger" : (completude < 75 ? "warning" : "success")}
                className=''
                style={{ height: this.state.completudeHovered ? '14px' : '12px', width: "140px" }}
                now={Math.max(completude, 30)}
                label={`${completude}%`}
              />
            </div>
          }

          <div
            className="d-inline-flex flex-row-reverse w-100 m-0 p-0 pt-0 argumentNatureSous " // blockquote-footer 
          >
            <div
              className="m-0 p-0 "
              style={{
                color: this.props.argument.quote.qt_title === "fait" ? "#ffc305"
                  : (this.props.argument.quote.qt_title === "opinion" ? "#ff5733"
                    : (this.props.argument.quote.qt_title === "étude" ? "#c70039"
                      : (this.props.argument.quote.qt_title === "proposition d'action" ? "#581845"
                        : (this.props.argument.quote.qt_title === "projection future" ? "#ff5733"
                          : ""
                        ))))
              }}
            >
              <SquareFillIcon size={14} />
            </div>
            {/* Type d'information : */}
            <div className="m-0 p-0 " >{this.props.argument.quote.qt_title + "\xa0"}</div>
          </div>
        </div>
      </Card >

    )
  }
}


export default ComponentArgumentSmall