import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

{/*
require('dotenv').config()

const instance = createInstance({
  urlBase: 'https://spotdebats.matomo.cloud/',
  siteId: 1,
  userId: 'undefined', // optional, default value: `undefined`.
  trackerUrl: 'https://spotdebats.matomo.cloud/tracking.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://spotdebats.matomo.cloud/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: true, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})
*/}

ReactDOM.render(
  //<MatomoProvider value={instance}>
    <App />
  //</MatomoProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
