import React, { Component } from 'react';

import {
  PlusCircleIcon, FileIcon, CheckCircleIcon, ChevronUpIcon, ChevronDownIcon,
  PencilIcon, CircleIcon, CheckCircleFillIcon, CheckIcon, SearchIcon
} from '@primer/octicons-react'


import ComponentTweetQuote from './ComponentTweetQuote'
import ComponentFormulaireQuoteManager from './ComponentFormulaireQuoteManager'

import { TwitterTweetEmbed } from 'react-twitter-embed'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, EffectCards, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import 'rc-slider/assets/index.css';
import '../full.css';

class ComponentFormulaireCommentateur extends Component {

  constructor(props) {
    super(props);
  }

  state = {

    show: false,

    quote_link_typeID: 0,

    commentateurData: {
      person: {
        pers_originID: 0,
        pers_nom: "",
        pers_prenom: "",
        som_gender: "",
        picture: "",
        pers_type: "someone",
        so_typeID: 0,
        so_profession: "",
        orga_typeID: 0,
        partiPolitique: "",
        twitterUserID: "",
        twitterUsername: "",
      }
    },

    reference: {
      re_originID: 0,
      idTweet: "",
      tweetParutionDate: "",
      tweetURL: "",
      partiPolitique: "",
    },

    type_person: "someone",

    creerPersonne: false,

    menuAjouterSupprimer: 'Ajouter',

    showConsoleLog: true,
    simulationMode: false,

    reorganizePersonsListData: [],
    reorganizePersonsListExpandRow: {},

    selectedFilterPersonName: '',

    titresStep: ['Commentateur.trice', 'Source', 'Confirmation'], // 'Prise de position',

    errorMessage: false,
    errorIndex: 0,

    afficherOptions: false,

    personToDeleteSelectedID: -1,
    personToDeleteConfirmation: false,

    changeMainQuote: true,

    listperson: [],
    listpersonReorganized: [],
    firstRow: 0,
    numberOfPersons: 20,

    showAllParts: false,

    peopleSearchSpinner: false, // true,
    peopleAPIOnProgress: false,

    citiesList: [],
    tweetsRelatedToQuote: [],

    tweetsIdsToDisplay: [],
    tweetsIdsAlreadyAdded: [],

  }


  componentDidMount() {
    // this.MediaAPIs(2)
    // this.initData()
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {

  }


  initData = (exceptions) => {

    this.setState({
      quote_link_typeID: this.props.commentateurData !== undefined ? this.props.commentateurData.quote_link_typeID : 0,    // Nécessaire pour retrouver la personne dans ComponentFormulaireQuoteManager (dans ComponentCommentateur)

      commentateurData: {
        person: {
          pers_originID: this.props.commentateurData !== undefined ? this.props.commentateurData.person.pers_originID : 0,    // Nécessaire pour retrouver la personne dans ComponentFormulaireQuoteManager (dans ComponentCommentateur)
          pers_nom: "",
          pers_prenom: "",
          som_gender: "",
          picture: "",
          pers_type: "someone",
          so_typeID: 0,
          so_profession: "",
          orga_typeID: 0,
          partiPolitique: "",
          twitterUserID: "",
          twitterUsername: "",
        }
      },


      reference: {
        re_originID: this.props.reference !== undefined ? this.props.reference.reference_extract_ID : 0,
        idTweet: this.props.reference !== undefined ? this.getTweetId(this.props.reference.ref_link) : "",  // Nécessaire pour retrouver le Tweet dans ComponentFormulaireQuoteManager (dans ComponentCommentateur)
        tweetParutionDate: "",
        tweetURL: "",
        partiPolitique: this.props.commentateurData !== undefined &&
          this.props.commentateurData.person !== undefined &&
          this.props.commentateurData.person.organisationList !== undefined &&
          this.props.commentateurData.person.organisationList.length > 1 ?
          this.props.commentateurData.person.organisationList[1].organisationName : "",
      },

      type_person: "someone", // (exceptions !== undefined && exceptions === "type_person") ? 
      menuAjouterSupprimer: "Ajouter",

      afficherOptions: false,
      selectedFilterPersonName: '',
      creerPersonne: false,

      personToDeleteSelectedID: -1,
      changeMainQuote: true,

      listperson: [],
      firstRow: 0,
      numberOfPersons: 20,


      citiesList: [],
      tweetsRelatedToQuote: [],

      tweetsIdsToDisplay: [],
      tweetsIdsAlreadyAdded: [],


    }, () => {
      // this.PersonListAPIs(this.state.firstRow, this.state.numberOfPersons, this.state.type_person, '')

      this.getTweetsRelatedToQuote(this.props.quoteID)
      this.citiesAPIs("")

    })



  }



  insertCommentateurFromTweetAPI = (quoteLinkTypeID, tweetInfos, labelisationSelected) => { // Lier une quote à une personne (quote_author_position)

    if (this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key

      let apiInstance = new this.props.spot_api.CommentateurApi()

      let modalityOriginID = this.props.quote.qm_originID
      let commentateurOriginID = tweetInfos.pers_originID

      let reference = {}
      reference["ref_title"] = null
      reference["ref_link"] = tweetInfos.tweetURL
      reference["ref_dateParution"] = tweetInfos.tweetParutionDate.substring(0, 19).replace("T", " ")

      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> modalityOriginID : ', modalityOriginID)
      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> commentateurOriginID : ', commentateurOriginID)
      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> quoteLinkTypeID : ', quoteLinkTypeID)
      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> reference : ', reference)

      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> tweetInfos : ', tweetInfos)
      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> labelisationSelected : ', labelisationSelected)


      let referenceAndTweetLabel = {}
      referenceAndTweetLabel["reference"] = reference
      referenceAndTweetLabel["tweetOnlineLabelisation"] = Object.assign({}, labelisationSelected)
      referenceAndTweetLabel["tweetOnlineLabelisation"]["qt_id"] = tweetInfos.qt_id

      this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> referenceAndTweetLabel : ', referenceAndTweetLabel)

      if (quoteLinkTypeID == 1 || quoteLinkTypeID == 2) {

        this.props.queryTracker('ComponentFormulaireCommentateur.js', 'insertCommentateurFromTweetAPI')

        if (!this.state.simulationMode) {

          apiInstance.insertCommentateurFromTweetPost(modalityOriginID, commentateurOriginID, quoteLinkTypeID, referenceAndTweetLabel,
            (error, data, response) => {
              if (error) {
                console.error(error);
              }
              else {

                let res = JSON.parse(response.text)

                this.props.showConsoleLog && console.log('personPersonIDGet -> data : ', data)
                this.props.showConsoleLog && console.log('personPersonIDGet -> response : ', response)
                this.props.showConsoleLog && console.log('personPersonIDGet -> res : ', res)

                let tempList = this.state.tweetsIdsAlreadyAdded
                this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> tempList : ', tempList)
                this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> tweetInfos.idTweet : ', tweetInfos.idTweet)
                tempList.push(tweetInfos.idTweet)
                this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> tempList : ', tempList)

                const commentateurData_temp = {
                  ...this.state.commentateurData,
                  person: {
                    ...this.state.commentateurData.person,
                    pers_originID: tweetInfos.pers_originID,
                    pers_nom: tweetInfos.pers_nom,
                    pers_prenom: tweetInfos.pers_prenom,
                    som_gender: tweetInfos.som_gender,
                    partiPolitique: tweetInfos.partiPolitique,
                    twitterUserID: tweetInfos.twitterUserID,
                    twitterUsername: tweetInfos.twitterUsername,
                  }
                }
                this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> commentateurData_temp : ', commentateurData_temp)

                this.setState({
                  quote_link_typeID: quoteLinkTypeID,
                  commentateurData: commentateurData_temp
                })

                // Réinitialisation de la référence pour le rafraichissement de l'UI du Tweet
                this.setState({
                  reference: {
                    re_originID: 0,
                    idTweet: "",
                    tweetParutionDate: "",
                    tweetURL: "",
                    partiPolitique: "",
                  },
                }, () => setTimeout(() => {
                  this.setState({
                    reference: {
                      ...this.state.reference,
                      re_originID: res["resultInsertExtractReference"]["insertId"],
                      idTweet: tweetInfos.idTweet,
                      tweetParutionDate: tweetInfos.tweetParutionDate,
                      tweetURL: tweetInfos.tweetParutionDate,
                      partiPolitique: tweetInfos.partiPolitique,
                    }
                  })
                }, 50))

                setTimeout(() => { this.setState({ tweetsIdsAlreadyAdded: tempList }) }, 500)

                this.props.commentateursAPIs(parseInt(this.props.quoteID))

              }
            })

        } else {
          this.setState({
            quote_link_typeID: quoteLinkTypeID,
            commentateurData: {
              ...this.state.commentateurData,
              person: {
                ...this.state.commentateurData.person,
                pers_originID: tweetInfos.pers_originID,
                pers_nom: tweetInfos.pers_nom,
                pers_prenom: tweetInfos.pers_prenom,
                som_gender: tweetInfos.som_gender,
                partiPolitique: tweetInfos.partiPolitique,
                twitterUserID: tweetInfos.twitterUserID,
                twitterUsername: tweetInfos.twitterUsername,
              }
            }
          })

          this.setState({
            reference: {
              re_originID: 0,
              idTweet: "",
              tweetParutionDate: "",
              tweetURL: "",
              partiPolitique: "",
            },
          }, () => setTimeout(() => {
            this.setState({
              reference: {
                ...this.state.reference,
                re_originID: 0,
                idTweet: tweetInfos.idTweet,
                tweetParutionDate: tweetInfos.tweetParutionDate,
                tweetURL: tweetInfos.tweetParutionDate,
                partiPolitique: tweetInfos.partiPolitique,
              }
            })
          }, 50))
        }

      }

      else if (quoteLinkTypeID > 0) {

        let tempList = this.state.tweetsIdsAlreadyAdded
        this.props.showConsoleLog && console.log('insertCommentateurFromTweetAPI -> tempList : ', tempList)
        tempList.push(tweetInfos.idTweet)


        apiInstance.insertQuotetweetOnlineLabelOnlyPost(referenceAndTweetLabel,
          (error, data, response) => {
            if (error) {
              console.error(error);
            }
            else {

              setTimeout(() => { this.setState({ tweetsIdsAlreadyAdded: tempList }) }, 1000)

              this.props.commentateursAPIs(parseInt(this.props.quoteID))

            }
          })

      }





    }
  }


  getTweetsRelatedToQuote = (quoteID) => {

    this.props.showConsoleLog && console.log('getTweetsRelatedToQuote in')

    if (this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key
      let apiInstance = new this.props.spot_api.TweetApi()

      this.props.queryTracker('ComponentFormulaireCommentateur.js', 'getTweetsRelatedToQuote')
      apiInstance.getTweetsRelatedToQuoteGet(quoteID, (error, data, response) => {

        this.props.showConsoleLog && console.log('getTweetsRelatedToQuote -> data : ', data)
        this.setState({ tweetsRelatedToQuote: data })

      })
    }
  }


  PersonListAPIs = (firstRow, numberOfPersons, someoneOrOrganisation, filterText) => {

    this.props.showConsoleLog && console.log("PersonListAPIs -> firstRow : ", firstRow)
    this.props.showConsoleLog && console.log("PersonListAPIs -> numberOfPersons : ", numberOfPersons)
    this.props.showConsoleLog && console.log("PersonListAPIs -> someoneOrOrganisation : ", someoneOrOrganisation)
    this.props.showConsoleLog && console.log("PersonListAPIs -> filterText : ", filterText)


    this.setState({ peopleAPIOnProgress: true })

    if (this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key
      let apiInstance = new this.props.spot_api.PersonApi()

      this.props.queryTracker('Citation_2.js', 'PersonListAPIs')
      apiInstance.personListGet(firstRow, numberOfPersons, someoneOrOrganisation, filterText === "" ? " " : filterText, (error, data, response) => {

        this.props.showConsoleLog && console.log('PersonListAPIs -> data : ', data)
        this.setState({ listperson: data })

        this.props.showConsoleLog && console.log("PersonListAPIs -> selectedFilterPersonName : ", this.state.selectedFilterPersonName)
        this.props.showConsoleLog && console.log("PersonListAPIs -> COND : ", filterText === this.state.selectedFilterPersonName)

        // if (filterText === this.state.selectedFilterPersonName) {
        this.setState({ peopleAPIOnProgress: false })
        // }

        // this.reorganizePersonsList(data)
      })
    }

  }




  reorganizePersonsList = (listperson) => {

    const data = []

    this.setState({ listpersonReorganized: listperson })

    this.props.showConsoleLog && console.log("reorganizePersonsList -> listperson len : ", listperson.length)

    this.props.showConsoleLog && console.log("reorganizePersonsList -> listperson : ", listperson)
    this.props.showConsoleLog && console.log("reorganizePersonsList -> type_person : ", this.state.type_person)

    listperson !== undefined && listperson !== null && listperson.length > 0 &&
      listperson
        /*.filter((person) =>
          person.idperson > 57 &&
          (this.state.selectedFilterPersonName !== "" ?
            person.pers_name.toLowerCase().includes(this.state.selectedFilterPersonName.toLowerCase())
            :
            true
          ) &&
          (this.state.type_person === "Une personne" ?
            person.ot_title === null
            :
            (this.state.type_person === "Une organisation" ?
              person.ot_title !== null
              :
              true
            )
          )
        )*/
        .slice(0, 1000)
        .forEach((person) => {
          data.push({
            // exp: "+",
            id: person.idperson,
            pers_originID: person.pers_originID,
            type: person.orga_typeID === null ? "Personne" : "Organisation",
            picture: person.pers_picture,
            nom: person.pers_name,
            prenom: person.pers_firstName,
            profession: person.organisationList, // person.orga_typeID === null ? this.capitalizeFirstLetter(person.so_profession) : this.capitalizeFirstLetter(person.ot_title),
            // mandat: person.pers_picture.slice(0, 4) === 'http' ? "Député" : "",
            selected: false,
            alreadySelected: this.alreadySelectedCheck(person.pers_originID)
          })
        })

    this.setState({ reorganizePersonsListData: data })

    this.props.showConsoleLog && console.log("reorganizePersonsListData", data)

    const expandRow = {
      onlyOneExpanding: true,
      // showExpandColumn: true,
      renderer: row => (
        <div>
          <p>{`This Expand row is belong to rowKey ${row.nom}`}</p>
        </div>
      ),
      // expanded: this.state.expanded,
      // onExpand: this.handleOnExpand,
    }

    this.setState({ reorganizePersonsListExpandRow: expandRow })

  }


  finishUpdate = () => {

    this.props.showConsoleLog && console.log('finishUpdate -> OK ')
    this.props.showConsoleLog && console.log('finishUpdate -> CommentateurCausaliteAPIs ', this.props.CommentateurCausaliteAPIs)

    this.props.keepPopover !== undefined && this.props.keepPopover(false)
    this.setState({ afficherOptions: false, show: false }, () => {
      if (this.state.changeMainQuote) {
        this.props.commentateursAPIs(this.props.quoteID)
      } else { // Mise à jour des référence des arguments
        this.props.CommentateurCausaliteAPIs(this.props.quoteLinkID)
      }
    })

  }



  deleteCommentateur(commentateurID) {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'deleteCommentateur')
    apiInstance.commentateurCommentateurIDDelete(commentateurID, this.props.quoteID, (error, data, response) => { // Supprimer la position d'un commentateur (quote_author_position)
      if (error) {
        console.error(error);
      }
      else {
        this.props.showConsoleLog && console.log('commentateur supprimé')

        let apiInstance3 = new this.props.spot_api.PersonApi()

        this.props.queryTracker('ComponentFormulaireCommentateur.js', 'deleteCommentateur')
        apiInstance3.personPersonIDGet(commentateurID, (error, data, response) => {
          if (error) {
            console.error(error)
          }
          else {

            let res = JSON.parse(response.text)
            let resObj = res[0]

            this.props.showConsoleLog && console.log('personPersonIDGet -> data : ', data)
            this.props.showConsoleLog && console.log('personPersonIDGet -> response : ', response)
            this.props.showConsoleLog && console.log('personPersonIDGet -> res : ', res)

            this.setState({ show: false }, () => setTimeout(() => this.props.commentateursAPIs(this.props.quoteID), 800))

          }
        })
      }
    });
  }


  addNewModalities = (pers_originID, tempModalitiesNew, tempModalitiesReuse) => {

    this.state.simulationMode && console.log(' *** addNewModalities *** ')

    tempModalitiesNew.forEach((quoteModality, index) => {
      this.addNewModalitiesAPI(pers_originID, quoteModality, (index + 1) === tempModalitiesNew.length, tempModalitiesReuse)
    })

  }

  addReuseModalities = (pers_originID, tempModalitiesReuse) => {

    this.state.simulationMode && console.log(' *** addReuseModalities *** ')

    tempModalitiesReuse.forEach((quoteModality, index) => {
      this.linkQuoteToPersonNorefAPI(pers_originID, quoteModality, (index + 1) === tempModalitiesReuse.length)
    })

  }


  addNewModalitiesAPI = (pers_originID, quoteModality, lastIteration, tempModalitiesReuse) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    let apiInstance = new this.props.spot_api.QuoteApi()

    this.props.showConsoleLog && console.log('addNewModalitiesAPI -> pers_originID : ', pers_originID)
    this.props.showConsoleLog && console.log('addNewModalitiesAPI -> id_quoteModality : ', this.props.quote.id_quoteModality)
    this.props.showConsoleLog && console.log('addNewModalitiesAPI -> commentateur : ', this.state.commentateur)

    let quoteModalityClean = Object.assign({}, quoteModality)
    quoteModalityClean.qm_modalityContent = this.replaceAll(quoteModalityClean.qm_modalityContent, "'", "\\'")

    this.props.showConsoleLog && console.log('addNewModalitiesAPI -> quoteModalityClean : ', quoteModalityClean)

    this.props.showConsoleLog && console.log('addNewModalitiesAPI -> commentateur : ', this.state.commentateur)

    this.state.simulationMode && console.log('addNewModalitiesAPI -> Ajout de la modalité // pers_originID :', pers_originID, ' // quoteModality :', quoteModality, ' // quote_link_typeID :', this.state.quote_link_typeID, ' // lastIteration :', lastIteration)

    if (this.state.simulationMode && lastIteration) {
      this.addReuseModalities(pers_originID, tempModalitiesReuse)
    }

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'addNewModalitiesAPI')
    {
      !this.state.simulationMode &&
        apiInstance.quoteModalitiesNewPost(pers_originID, this.state.quote_link_typeID, quoteModalityClean,
          (error, data, response) => { // Lier une quote à une personne (quote_author_position)
            if (error) {
              console.error(error);
            }
            else {

              this.props.showConsoleLog && console.log('addNewModalitiesAPI -> Lien quote -> personne OK')
              this.props.showConsoleLog && console.log('addNewModalitiesAPI -> response : ', response)

              if (lastIteration) {
                this.addReuseModalities(pers_originID, tempModalitiesReuse)
              }

            }
          })
    }
  }


  linkQuoteToPersonNorefAPI = (pers_originID, quoteModality, lastIteration) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> pers_originID : ', pers_originID)
    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> id_quoteModality : ', this.props.quote.id_quoteModality)
    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> commentateur : ', this.state.commentateur)

    this.state.simulationMode && console.log('linkQuoteToPersonNorefAPI -> Ajout de la modalité // pers_originID :', pers_originID, ' // id_quoteModality :', quoteModality.id_quoteModality, ' // quote_link_typeID :', this.state.quote_link_typeID, ' // lastIteration :', lastIteration)

    if (this.state.simulationMode && lastIteration) {
      this.state.simulationMode && console.log(' *** FIN linkQuoteToPersonNorefAPI *** ')
    }

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'linkQuoteToPersonNorefAPI')
    {
      !this.state.simulationMode &&
        apiInstance.commentateurNoRefCommentateurIDPost(pers_originID, quoteModality.qm_originID, this.state.quote_link_typeID,
          (error, data, response) => { // Lier une quote à une personne (quote_author_position)
            if (error) {
              console.error(error);
            }
            else {

              this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> Lien quote -> personne OK')
              this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> response : ', response)

              if (lastIteration) {

                this.props.commentateursAPIs(parseInt(this.props.quoteID), pers_originID)  // Rechargement des commentateurs pour update des ref sur la page Citation
                this.props.keepPopover !== undefined && this.props.keepPopover(false)
                this.setState({ afficherOptions: false, show: false })
                this.props.quoteModalitesAPIs(parseInt(this.props.quoteID))
              }

            }
          })
    }
  }


  /* linkQuoteToPerson = (idperson, referenceNew) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    this.props.showConsoleLog && console.log('linkQuoteToPerson -> idperson : ', idperson)
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> quoteID : ', parseInt(this.props.quoteID))
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> commentateur : ', this.state.commentateur)
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> referenceNew : ', referenceNew)

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'linkQuoteToPerson')
    apiInstance.commentateurCommentateurIDPost(idperson, parseInt(this.props.quoteID), this.state.quote_link_typeID, referenceNew,
      (error, data, response) => { // Lier une quote à une personne (quote_author_position)
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('linkQuoteToPerson -> Lien quote -> personne OK')
          this.props.showConsoleLog && console.log('linkQuoteToPerson -> response : ', response)


          let apiInstance2 = new this.props.spot_api.PersonApi()

          this.props.queryTracker('ComponentFormulaireCommentateur.js', 'linkQuoteToPerson')
          apiInstance2.personPersonIDGet(idperson, (error, data, response) => { // Récupérer une personne pour avoir le nom dans l'historique
            if (error) {
              console.error(error)
            }
            else {

              let res = JSON.parse(response.text)
              let resObj = res[0]

              this.props.showConsoleLog && console.log('personPersonIDGet -> data : ', data)
              this.props.showConsoleLog && console.log('personPersonIDGet -> response : ', response)
              this.props.showConsoleLog && console.log('personPersonIDGet -> res : ', res)


              this.props.commentateursAPIs(parseInt(this.props.quoteID), idperson)  // Rechargement des commentateurs pour update des ref sur la page Citation

              
              this.props.keepPopover !== undefined && this.props.keepPopover(false)
              this.setState({ afficherOptions: false, show: false })


            }
          })
        }
      })
  }

  linkSourceToPerson = (idperson, referenceNew) => { // Lier un argument à une personne (quote_link_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    this.props.showConsoleLog && console.log('linkSourceToPerson -> idperson : ', idperson)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> quoteLinkID : ', this.props.quoteLinkID)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> commentateur : ', this.state.commentateur)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> quote_link_typeID : ', this.state.quote_link_typeID)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> referenceNew : ', referenceNew)

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'linkSourceToPerson')
    apiInstance.commentateurCausaliteCommentateurIDPost(idperson, this.props.quoteLinkID, this.state.quote_link_typeID, referenceNew,
      (error, data, response) => { // Lier un argument à une personne (quote_link_author_position)
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('linkSourceToPerson -> Lien quote -> personne OK')
          this.props.showConsoleLog && console.log('linkSourceToPerson -> response : ', response)


          let apiInstance2 = new this.props.spot_api.PersonApi()

          this.props.queryTracker('ComponentFormulaireCommentateur.js', 'linkSourceToPerson')
          apiInstance2.personPersonIDGet(idperson, (error, data, response) => { // Récupérer une personne pour avoir le nom dans l'historique
            if (error) {
              console.error(error)
            }
            else {

              let res = JSON.parse(response.text)
              let resObj = res[0]

              this.props.showConsoleLog && console.log('personPersonIDGet -> data : ', data)
              this.props.showConsoleLog && console.log('personPersonIDGet -> response : ', response)
              this.props.showConsoleLog && console.log('personPersonIDGet -> res : ', res)

              this.props.showConsoleLog && console.log("linkSourceToPerson -> CommentateurCausaliteAPIs : ", this.props.CommentateurCausaliteAPIs)

              this.setState({ show: false }, () => this.props.CommentateurCausaliteAPIs(this.props.quoteLinkID))

            }
          })
        }
      })
  } */



  createPerson = () => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    this.props.showConsoleLog && console.log('createPerson -> person : ', this.state.commentateurData)

    let apiInstance = new this.props.spot_api.PersonApi();

    this.props.queryTracker('ComponentFormulaireCommentateur.js', 'createPerson')
    apiInstance.personPost(this.state.commentateurData.person, (error, data, response_com) => { // Ajouter une personne (table person)
      if (error) {
        console.error(error);
      }
      else { // Si la personne existe déjà dans la BDD

        this.props.showConsoleLog && console.log('person créée')

        let res = JSON.parse(response_com.text)
        let idperson = res['result'].insertId

        this.props.showConsoleLog && console.log('createPerson -> idperson :', idperson)

      }
    });
  }


  changeSelectedPerson = (idToChange, nom, prenom, profession, picture) => {

    let reorganizePersonsListData = this.state.reorganizePersonsListData

    reorganizePersonsListData.forEach((element) => {
      if (element.id === idToChange) {
        element.selected = true
      } else {
        element.selected = false
      }
    })

    this.setState({
      reorganizePersonsListData: reorganizePersonsListData,
      commentateurData:
      {
        ...this.state.commentateurData,
        person: {
          ...this.state.commentateurData.person,
          idperson: idToChange,
          pers_name: nom,
          pers_firstName: prenom,
          picture: picture,
        }
      }
    })

  }




  alreadySelectedCheck = (pers_originID) => {
    let stateReturn = "not selected"

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> pers_originID", pers_originID)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> includes", this.props.listcommentateursContreId.includes(pers_originID))
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> stateReturn", stateReturn)

    if (this.props.listcommentateursPourId !== undefined && this.props.listcommentateursPourId.includes(pers_originID)) {
      stateReturn = "pour"
    }
    if (this.props.listcommentateursPeutetreId !== undefined && this.props.listcommentateursPeutetreId.includes(pers_originID)) {
      stateReturn = "peut etre"
    }
    if (this.props.listcommentateursContreId !== undefined && this.props.listcommentateursContreId.includes(pers_originID)) {
      stateReturn = "contre"
    }

    return stateReturn

  }



  getPersonNameByID = IDperson => {
    var listperson = this.state.listperson
    var personName = 'person not found'
    IDperson = Number(IDperson)

    this.props.showConsoleLog && console.log('getPersonNameByID -> IDperson : ', IDperson)
    this.props.showConsoleLog && console.log('getPersonNameByID -> listperson : ', listperson)

    listperson !== undefined && listperson !== null &&
      listperson.forEach((element) => {

        if (element.idperson === IDperson) {
          personName = element.pers_name;
          this.props.showConsoleLog && console.log('getPersonNameByID -> personne trouvée : ', element.pers_name)
        }
      })

    return personName
  }


  replaceAll(string, search, replace) {
    let returnString = ""
    if (string !== "" && string !== null && string !== undefined) {
      returnString = string.split(search).join(replace)
    }
    return returnString
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  selectFieldContent = (row) => {
    // const returnContent = <div></div>

    this.props.showConsoleLog && console.log("row.alreadySelected :", row.alreadySelected)
    this.props.showConsoleLog && console.log("row.alreadySelected test :", row.alreadySelected === "not selected")

    if (row.alreadySelected === "not selected") {
      return <button
        className="noBorder "
        style={{ backgroundColor: 'transparent' }}
        onClick={() => {
          this.changeSelectedPerson(row.id, row.nom, row.prenom, row.profession, row.picture)
        }}
      >
        {row.selected ? <CheckCircleFillIcon size={24} /> : <CircleIcon size={24} />}
      </button>
    } else if (row.alreadySelected === "pour") {
      return <div className="commentateurTextPour ">
        <CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 &&
          <div className="w-100 ">
            <div className="w-100 text-center " >Déjà pour</div>
            {!this.state.personToDeleteConfirmation &&
              <Button
                className="whiteButton modifierInformations mt-2"
                variant="danger"
                onClick={() => {
                  console.log("delete row : ", row)
                  this.setState({ personToDeleteSelectedID: row.id, personToDeleteConfirmation: true }, () => this.changeSelectedPerson(-1, "", "", "", ""))
                }}
              >
                Supprimer
              </Button>
            }
            {this.state.personToDeleteConfirmation &&
              <Button
                className="whiteButton modifierInformations mt-2"
                variant="danger"
                onClick={() => {
                  console.log("delete row : ", row)
                  this.deleteCommentateur(row.id)
                }}
              >
                Confirmer
              </Button>
            }
          </div>
        }
      </div >
    } else if (row.alreadySelected === "contre") {
      return <div className="commentateurTextContre"><CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 &&
          <div>
            <div className="w-100 text-center " >Déjà contre</div>
          </div>
        }
      </div>
    } else if (row.alreadySelected === "peut etre") {
      return <div className="commentateurTextPeutEtre"><CheckCircleFillIcon className="w-100 " size={24} />
        {window.innerWidth > 575.98 &&
          <div className="w-100 text-center " >Déjà sous condition</div>
        }
      </div>
    } else {
      return <div></div>
    }


  }


  getPersonFromArrayOfPersonList = (idperson) => {
    const listperson = this.state.listperson
    const personObject = {}

    this.props.showConsoleLog && console.log("getPersonFromArrayOfPersonList -> idperson : ", idperson)
    this.props.showConsoleLog && console.log("getPersonFromArrayOfPersonList -> listperson : ", listperson)

    if (idperson > 0) {
      listperson.forEach((element) => {
        if (element.idperson === idperson) {
          Object.assign(personObject, element)
        }
      })
    }

    return personObject
  }

  handleChangeModality = (modalityTypeName, modalityTypeToChange, modalityValue) => {

    let tempQuoteModalitiesSelected = this.state.quoteModalitiesSelected

    tempQuoteModalitiesSelected.forEach((element) => {
      if (element.mo_title === modalityTypeName) {

        if (modalityTypeToChange === "init") {
          element.qm_modalityContent = ""
          element.qm_modalityContentIntermediary = ""
          element.echelleDebatSelectionneValue = 0
          element.echellePlaceSelectionneeValue = 0

        }

        if (modalityTypeToChange === "id_quoteModality") {
          this.props.showConsoleLog && console.log("handleChangeModality -> modalityValue :", modalityValue)
          this.props.showConsoleLog && console.log("handleChangeModality ->  :",)

          element.id_quoteModality = modalityValue
          element.qm_modalityContent = modalityValue === 0 ? "" : this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.quoteModalities, 'id_quoteModality', modalityValue, 'qm_modalityContent')
        }

        if (modalityTypeToChange === "qm_modalityContent") {
          element.qm_modalityContent = modalityValue
          element.qm_modalityContentIntermediary = ""
          element.id_quoteModality = 0
        }


        if (modalityTypeToChange === "displayElement") {
          element.displayElement = modalityValue
        }


        if (modalityTypeToChange === "qm_modalityContentIntermediary") {
          element.qm_modalityContentIntermediary = modalityValue
          element.qm_modalityContent = ""
        }

        if (modalityTypeToChange === "echelleDebatSelectionneValue") {

          let echelle = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.echellesGeographiquesInformationTable, 'id', modalityValue, 'echelleInformation')
          let intro = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.echellesGeographiquesInformationTable, 'id', modalityValue, 'intro') + "\xa0"

          element.echelleDebatSelectionneValue = modalityValue
          element.echellePlaceSelectionneeValue = 0
          if (![1, 2, 3].includes(modalityValue)) {
            element.qm_modalityContent = intro.concat(echelle)
            element.qm_modalityContentIntermediary = ""
          } else {
            element.qm_modalityContent = ""
            element.qm_modalityContentIntermediary = intro.concat(echelle)
          }

        }

        if (modalityTypeToChange === "echellePlaceSelectionneeValue") {

          let intro = ""
          if (this.state.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 1) {
            intro = "à\xa0"
          } else if (this.state.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 2) {
            intro = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.departmentsList, 'de_id', modalityValue.value, 'de_intro') + "\xa0"
          } else if (this.state.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 3) {
            intro = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.regionsList, 're_id', modalityValue.value, 're_intro') + "\xa0"
          }

          element.echellePlaceSelectionneeValue = modalityValue.value
          element.qm_modalityContent = intro.concat(modalityValue.label)

        }

      }
    })
    this.setState({ quoteModalitiesSelected: tempQuoteModalitiesSelected })

    this.props.showConsoleLog && console.log('handleChangeModality -> quoteModalitiesSelected :', tempQuoteModalitiesSelected)

    /* 
    quoteModalitiesSelected: [
      {
        id_modalityType: 2,
        mo_title: "quand",
        qm_modalityContent: "",
      },
      {
        id_modalityType: 3,
        mo_title: "où",
        qm_modalityContent: "",
        qm_modalityContentIntermediary: "",
      },
      {
        id_modalityType: 4,
        mo_title: "combien",
        qm_modalityContent: "",
      },
      {
        id_modalityType: 5,
        mo_title: "pour qui",
        qm_modalityContent: "",
      },
    ],

    */

  }


  selectDebatEchelle1 = (selectedOption) => {

    this.handleChangeModality('où', "echelleDebatSelectionneValue", selectedOption)

  }

  selectDebatEchelle2 = (selectedOption) => {

    this.handleChangeModality('où', "echellePlaceSelectionneeValue", selectedOption)

  }


  transformDepartementArrayToSelectObject = (array, placeType) => {
    let selectObject = []

    if (placeType === 'departments') {
      if (array !== null) {
        array.forEach((element, index) => {
          selectObject.push({ value: element.de_id, label: `${element.de_name}`, codePostal: element.de_code })
        })
      }
    }

    if (placeType === 'regions') {
      if (array !== null) {
        array.forEach((element, index) => {
          selectObject.push({ value: element.re_id, label: `${element.re_name}` })
        })
      }
    }

    return selectObject
  }


  citiesAPIs = (reactSelectTextInput) => {
    this.props.showConsoleLog && console.log('citiesAPIs -> call')
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key
      let apiInstance = new this.props.spot_api.PlaceApi()
      let opts = { filterText: null };
      if (reactSelectTextInput.length > 0) {
        opts['filterText'] = reactSelectTextInput;
      }

      this.props.showConsoleLog && console.log('citiesAPIs -> in', reactSelectTextInput)
      this.props.showConsoleLog && console.log('citiesAPIs -> in', reactSelectTextInput.length)

      apiInstance.placeCitiesGet(0, 20, opts, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          this.props.showConsoleLog && console.log('ComponentFormulaireCommentateur -> citiesAPIs : ', data)
          this.setState({ citiesList: data })
        }
      })
    }
  }


  transformVilleArrayToSelectObject = (array) => {
    let selectObject = []

    this.props.showConsoleLog && console.log('Villes transformVilleArrayToSelectObject : ', array)

    if (array !== undefined && array !== null) {
      array.forEach((element, index) => {

        if (element !== undefined && element.ci_zip_code !== null && element.ci_zip_code !== undefined) {
          selectObject.push({ value: element.ci_id, label: `${element.ci_name}`, codePostal: element.ci_zip_code })
          // this.props.showConsoleLog && console.log('Ville zip_code ok : ', element.name)
        } else {
          this.props.showConsoleLog && console.log('Ville zip_code null : ', element.name)
        }

      })
    }
    else {
      // selectObject.push({ value: this.props.listeVillesValeur0.name, label: this.props.listeVillesValeur0.name, codePostal: "00000" })
    }

    return selectObject
  }



  handleInputChange = (inputValue) => {
    this.props.showConsoleLog && console.log('handleInputChange villes ', inputValue)

    this.setState({ reactSelectTextInput: inputValue }, () => this.citiesAPIs(inputValue))

  }


  shuffle = (arrayInput) => {

    let array = Object.assign({}, arrayInput)

    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }


  getTweetId(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      let splittedString = string.split("/")
      return splittedString[splittedString.length - 1]
    } else {
      return ""
    }
  }

  changeShowState = (value) => { this.setState({ show: value }) }


  render() {
    const url_image = this.props.url_image

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> this.state.reference : ", this.state.reference)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> this.props.reference : ", this.props.reference)
    this.props.showConsoleLog && this.props.reference !== undefined && console.log("ComponentFormulaireCommentateur -> this.props.reference getTweetId : ", this.getTweetId(this.props.reference.ref_link))

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> this.props.reference : ", this.props.reference)

    const person = this.state.commentateurData.person
    const listperson = this.state.listperson


    if (JSON.stringify(this.state.listperson) !== JSON.stringify(this.state.listpersonReorganized)) {
      this.reorganizePersonsList(this.state.listperson)
    }

    if (this.state.listperson.length > 0 && JSON.stringify(this.state.listperson) === JSON.stringify(this.state.listpersonReorganized) && this.state.peopleSearchSpinner) {
      this.setState({ peopleSearchSpinner: false }) // Arrêt du spinner après le premier chargement
    }

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> this.props.quote : ", this.props.quote)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> this.props.quote : ", this.props.quote)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> q_originID : ", this.props.quote.q_originID)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> citiesList 1 : ", this.state.citiesList)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> quoteModalitiesSelected : ", this.state.quoteModalitiesSelected)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> quoteLinkID : ", this.state.quote_link_typeID)

    this.props.showConsoleLog && console.log("this.state.commentateurData :", this.state.commentateurData)
    this.props.showConsoleLog && console.log("this.props.commentateurData :", this.props.commentateurData)


    let tweetsIdsToDisplayTemp = []
    if (this.state.tweetsRelatedToQuote !== undefined && this.state.tweetsRelatedToQuote !== null) {
      tweetsIdsToDisplayTemp = this.state.tweetsRelatedToQuote
        .filter((element) => !this.state.tweetsIdsAlreadyAdded.includes(element.idTweet))
        .map(({ idTweet }) => idTweet)
    }

    const tweetsIdsToDisplay = tweetsIdsToDisplayTemp.slice(0, 10).sort(() => Math.random() - 0.5)
    // const tweetsIdsToDisplay = tweetsIdsToDisplayTemp.slice(0, 3) // this.shuffle(tweetsIdsToDisplayTemp).slice(0, 3)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> tweetsIdsToDisplay : ", tweetsIdsToDisplay)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> tweetsIdsAlreadyAdded : ", this.state.tweetsIdsAlreadyAdded)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> tweetsRelatedToQuote : ", this.state.tweetsRelatedToQuote)

    let tweetsFiltered = []
    if (this.state.tweetsRelatedToQuote !== undefined && this.state.tweetsRelatedToQuote !== null) {
      tweetsFiltered = this.state.tweetsRelatedToQuote.slice(0, 5)
    }

    // .filter((element) => !this.state.tweetsIdsAlreadyAdded.includes(element.idTweet))

    const tweetsToDisplay = tweetsFiltered.sort(() => Math.random() - 0.5)  // this.shuffle(tweetsFiltered).slice(0, 10)

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> tweetsFiltered : ", tweetsFiltered)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> tweetsToDisplay : ", tweetsToDisplay)

    const columnsPersonList = [
      { // CircleIcon CheckCircleFillIcon
        dataField: 'selectionner',
        text: window.innerWidth > 575.98 ? 'Sélectionner ' : '',
        isDummyField: true,
        formatter: (cell, row, rowIndex) => (
          this.selectFieldContent(row)
        ),
        formatExtraData: this.state.commentateurData.person.idperson,
        headerStyle: { width: '13%', fontSize: '15px', textAlign: 'center', verticalAlign: 'middle' },
        style: { padding: '5px', textAlign: 'center', verticalAlign: 'middle' }
      },
      {
        dataField: 'picture', text: 'Photo',
        formatter: (cell, row) => {
          return (
            <img
              className="rounded "
              src={cell === null ? "" :
                (cell.slice(0, 4) === 'http' ? row.picture : (this.props.url_image + row.picture))}
              alt=""
              style={{ maxHeight: '80px', maxWidth: '95%' }}
            />
          )
        },
        headerStyle: {
          width: window.innerWidth > 575.98 ? '8%' : '14%',
          padding: window.innerWidth > 575.98 ? '' : '0px',
          fontSize: window.innerWidth > 575.98 ? '15px' : '13px',
          textAlign: 'center',
          verticalAlign: 'middle',
        },
        style: {
          fontSize: window.innerWidth > 575.98 ? '14px' : '13px',
          margin: '0px', padding: window.innerWidth > 575.98 ? '5px' : '2px',
          textAlign: 'center',
          verticalAlign: 'middle',
        }
      },
      {
        dataField: 'nom', text: 'Nom',
        formatter: (cell, row) => {
          return (
            row.prenom !== null ?
              <div>
                {row.prenom.concat("\xa0", row.nom.toUpperCase())}
              </div>
              :
              <div>
                {row.nom}
              </div>

          )
        },
        headerStyle: {
          width: window.innerWidth > 575.98 ? '20%' : '40%',
          fontSize: window.innerWidth > 575.98 ? '15px' : '13px',
          textAlign: 'center',
          verticalAlign: 'middle',
        },
        style: {
          fontSize: window.innerWidth > 575.98 ? '14px' : '13px',
          margin: '0px',
          padding: '5px',
          verticalAlign: 'middle',
        }
      },
      {
        dataField: 'profession',
        text: (this.state.type_person === 'someone' ? 'Informations' : 'Informations'), // 'Profession' : 'Type'
        formatter: (cell, row) => {
          return (
            <div>

              <ul className="" style={{ marginTop: '10px' }}>
                {row.profession.map((element, index) => (

                  <li key={`profession_${element.id}_${index}`}>

                    <span>
                      {this.capitalizeFirstLetter(element.so_profession) +
                        (element.organisationName !== null && element.organisationName !== "Assemblée nationale" ?
                          " de ".concat(element.organisationName)
                          :
                          "")
                      }
                    </span>
                    {
                      (element.organisationName === null ||
                        (element.organisationName !== null && !element.organisationName.includes("Assemblée nationale"))) &&
                      element.so_placeName_temp !== null &&
                      <span>
                        {
                          this.props.miseEnFormeIntroAvecCitation(" de ", this.capitalizeFirstLetter(element.so_placeName_temp))
                        }
                      </span>
                    }

                    {element.organisationName !== null &&
                      (element.organisationName.includes("Ministère") || element.organisationName.includes("Secrétariat d'Etat") || element.organisationName.includes("Gouvernement")) &&
                      <img
                        className="ml-3 rounded "
                        src={this.props.url_image + "Logo_de_la_République_française_(1999).png"}
                        alt=""
                        style={{ maxHeight: '15px', maxWidth: '95%' }}
                      />
                    }
                    {element.organisationName !== null &&
                      element.organisationName.includes("Assemblée nationale") &&
                      <img
                        className="ml-3 rounded "
                        src={this.props.url_image + "Logo_Assemblee_Nationale.png"}
                        alt=""
                        style={{ maxHeight: '15px', maxWidth: '95%' }}
                      />
                    }
                    {element.organisationName !== null &&
                      element.organisationName.includes("Parlement européen") &&
                      <img
                        className="ml-3 rounded "
                        src={this.props.url_image + "Logo_Union_Europeenne.png"}
                        alt=""
                        style={{ maxHeight: '15px', maxWidth: '95%' }}
                      />
                    }
                  </li>
                ))}
              </ul>

            </div>
          )
        },
        headerStyle: {
          fontSize: window.innerWidth > 575.98 ? '15px' : '13px',
        },
        style: {
          fontSize: window.innerWidth > 575.98 ? '14px' : '13px',
          padding: '5px',
          verticalAlign: 'middle',
        }
      },

    ]


    return (

      <div className="">
        <div className="d-flex align-items-center justify-content-center m-0 p-0 ">

          {this.props.listcommentateurs !== undefined
            // && this.props.listcommentateurs.length !== 0
            && this.props.niveauDeLectureValue !== "Facile"
            &&
            <Button
              variant="light"
              onClick={() => {
                this.props.keepPopover !== undefined && this.props.keepPopover(true)
                this.setState({ show: true }, () => this.initData())

              }}
              className='d-flex flex-row align-items-center m-0 p-2 octiconStyle'
              title={"Ajouter / supprimer un commentateur\xa0\u00ab\xa0" + this.props.addPosition + "\xa0\u00bb\xa0"}
            >
              <PencilIcon size={14} className='m-1 p-0' />
              {this.props.formulaireType === 'mainPage' &&
                <p className="robotoFont12px m-0 pr-2 " >Ajouter un commentateur</p>
              }
            </Button>
          }

          {false && (this.props.listcommentateurs === undefined || this.props.listcommentateurs.length === 0) &&
            <Button
              variant="light"
              onClick={() => {
                this.props.keepPopover !== undefined && this.props.keepPopover(true)
                this.setState({ show: true }, () => this.initData())

              }}
              className='d-flex flex-row align-items-center p-1 whiteButtonHover'
              title={"Ajouter un premier commentateur\xa0\u00ab\xa0" + this.props.addPosition + "\xa0\u00bb\xa0"}
            >
              <span className="d-flex align-items-center octiconStyle" style={{ color: '#C4C4C4' }}>
                <PlusCircleIcon size={70} className=" " />
              </span>
            </Button>
          }


        </div>


        <Modal
          show={this.state.show}
          onHide={() => {
            this.props.keepPopover !== undefined && this.props.keepPopover(false)
            this.setState({ afficherOptions: false, show: false })
          }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">


              {(this.state.showAllParts || this.state.menuAjouterSupprimer === 'Ajouter') &&

                <div className="d-flex justify-content-center ">

                  <div
                    className={"col-12 m-0 p-0 text-center  ".concat("col-lg-9 ")}
                    style={{ fontSize: window.innerWidth > 576 ? '25px' : '17px', fontWeight: 'bold' }}
                  >
                    {this.props.quoteOrArgumentForm === "mainQuote" ? 'Ajouter un commentateur au débat' :
                      (this.props.quoteOrArgumentForm === "argument" ? "Ajouter un commentateur à l'argument" : "")
                    }
                  </div>

                </div>
              }

            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="d-flex flex-column col-lg-12 justify-content-center m-0 p-1 ">

            {this.props.showConsoleLog && console.log("commentateurData :", this.state.commentateurData)}

            {this.state.reference.idTweet.length === 0 &&
              <div className="d-flex flex-column col-lg-12 m-0 p-0">

                <div className="d-flex flex-row justify-content-center col-lg-12 m-0 p-4 relanconsDebat">{this.props.quoteTitle + " ..."}</div>

                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  freeMode={true}
                  navigation={true}
                  scrollbar={true}
                  mousewheel={true}
                  pagination={{
                    clickable: true,
                    type: "progressbar",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper d-flex flex-row col-lg-12 m-0 p-0 "
                >

                  {tweetsToDisplay.map((element, index) => (
                    // 
                    !this.state.tweetsIdsAlreadyAdded.includes(element.idTweet) && tweetsIdsToDisplay.includes(element.idTweet) &&
                    <SwiperSlide
                      key={`tweetSuggest_${index}`}
                      id={`tweetSuggest_${element.idTweet}`}
                      className='d-flex col-lg-4 m-0 p-0 ' // border rounded
                    >

                      <ComponentTweetQuote
                        showConsoleLog={this.props.showConsoleLog}
                        tweetData={element}
                        quoteTitle={this.props.quoteTitle}
                        insertCommentateurFromTweetAPI={this.insertCommentateurFromTweetAPI}
                        showFormulaire={true}
                      />

                    </SwiperSlide>

                  ))
                  }

                </Swiper>

              </div>
            }

            {this.props.showConsoleLog && console.log("this.state.reference :", this.state.reference)}
            {this.props.showConsoleLog && console.log("this.state.commentateurData :", this.state.commentateurData)}
            {this.props.showConsoleLog && console.log("this.props.commentateurData :", this.props.commentateurData)}
            {this.props.showConsoleLog && console.log("this.props.commentateurData cond :", this.props.commentateurData !== undefined)}
            {this.props.showConsoleLog && console.log("this.props.natureInformationTable :", this.props.natureInformationTable)}

            {this.state.reference.idTweet.length > 0 &&
              <div className="d-flex flex-column col-lg-12 m-0 p-0">

                <div className="d-flex justify-content-center col-lg-12 m-0 p-4 relanconsDebat ">{this.props.quoteTitle + " ..."}</div>

                <div className="d-flex flex-row col-lg-12 m-0 p-0 ">

                  {<div className='d-flex flex-column col-lg-4 align-items-start border rounded m-0 p-0'>

                    <div className='d-flex flex-column w-100 pb-4' style={{ position: "sticky", top: "0" }}>
                      {this.state.reference.partiPolitique !== null &&
                        <div className="d-flex flex-column col-lg-12 mt-0 pt-4 robotoFont13px " style={{ height: "40px" }}>
                          <div className="w-100 text-center ">{"Député " + this.state.reference.partiPolitique}</div>
                        </div>
                      }
                      <div className="w-100 pl-1 pr-1" style={{ position: 'relative' }}>
                        <TwitterTweetEmbed
                          className="d-flex flex-row col-lg-12 mt-0 p-0 "
                          tweetId={this.state.reference.idTweet}
                        />
                        <div className='w-100 h-100' style={{ position: "absolute", right: "0", top: "0" }}></div>
                      </div>

                      <a
                        className='text-center robotoFont12px '
                        href={`https://twitter.com/spot/status/`.concat(this.state.reference.idTweet)}
                        target="_blank"
                      >
                        Lien vers le Tweet
                      </a>
                    </div>

                  </div>}

                  {false && <div className="d-flex col-lg-4 align-items-start border rounded m-0 p-0">
                    <ComponentTweetQuote
                      showConsoleLog={this.props.showConsoleLog}
                      tweetData={this.state.reference}
                      quoteTitle={this.props.quoteTitle}
                      insertCommentateurFromTweetAPI={this.insertCommentateurFromTweetAPI}
                      showFormulaire={false}
                    />
                  </div>
                  }

                  <div className="d-flex flex-column col-lg-8 p-4 border rounded">

                    <div className="col-lg-12 m-0 p-0 ">

                      {true &&
                        <ComponentFormulaireQuoteManager
                          formulaireType={this.props.formulaireType}
                          quote={this.props.quote}

                          listquoteSimilar={this.props.listquoteSimilar}

                          departmentsList={this.props.departmentsList}

                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                          miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}

                          natureInformationTable={this.props.natureInformationTable}
                          natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                          natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                          natureInformationTable_faits={this.props.natureInformationTable_faits}

                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                          addQuote={this.props.addQuote}

                          url_image={this.props.url_image}
                          showConsoleLog={this.props.showConsoleLog}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          show={this.state.show}
                          quoteModalities={this.props.quoteModalities}

                          quote_link_typeID={this.state.quote_link_typeID}
                          reference={this.state.reference}
                          commentateurData={this.props.commentateurData !== undefined ? this.props.commentateurData : this.state.commentateurData}
                          quoteModalitesAPIs={this.props.quoteModalitesAPIs}
                          ArgumentsAPIs={this.props.ArgumentsAPIs}
                          changeShowState={this.changeShowState}
                          argumentPourContre={this.props.argumentPourContre}

                          keepPopover={this.props.keepPopover}

                        />
                      }

                    </div>

                  </div>

                </div>

              </div>
            }


            {/* Ajouter un commentateur */}
            {(this.state.showAllParts || this.state.menuAjouterSupprimer === "Ajouter") &&
              this.state.commentateurData.person.idperson < 1 &&
              false &&
              <div className='col-lg-12 p-0 '>
                {
                  <div className="col-lg-12 whiteButtonShadow p-0 pt-4 pb-4 ">

                    <div className="ongletVerticalTitre mt-2 text-center">
                      {// this.state.type_person === 'Une personne' ?
                        "Sélectionner le commentateur à ajouter"  // : "Sélectionner l'organisation"
                      }
                    </div>


                    {(this.state.showAllParts || this.state.afficherOptions) &&
                      <div className="d-flex justify-content-center m-4 ">
                        <Button
                          className="p-3 mr-4"
                          active={!this.state.creerPersonne}
                          variant={!this.state.creerPersonne ? "dark" : "light"}
                          onClick={() => {
                            this.setState({ creerPersonne: false })
                          }}
                        >
                          {this.state.type_person === 'someone' ?
                            "Sélectionner une personne déjà sur SPOT\xa0"
                            :
                            "Sélectionner une organisation déjà sur SPOT\xa0"
                          }
                          {!this.state.creerPersonne &&
                            <CheckIcon size={18} />
                          }
                        </Button>
                        <Button
                          className="p-3 mr-4"
                          active={this.state.creerPersonne}
                          variant={this.state.creerPersonne ? "dark" : "light"}
                          onClick={() => {
                            this.setState({ creerPersonne: true })
                          }
                          }
                        >
                          {this.state.type_person === 'someone' ?
                            "Ajouter une nouvelle personne\xa0"
                            :
                            "Ajouter une nouvelle organisation\xa0"
                          }
                          {this.state.creerPersonne &&
                            <CheckIcon size={18} />
                          }
                        </Button>
                      </div>
                    }


                    { // Section de filtres : personne/organisation et texte de filtre
                      <div className="col-lg-12 mt-4 p-2 ">
                        <div className="col-lg-8 p-2 ml-auto mr-auto rounded " style={{ backgroundColor: "#F5F5F5" }}>

                          {this.props.showConsoleLog && console.log("filterType : ", this.state.filterType)}

                          {/* Choix 'personne' ou 'organisation' */}
                          <Form
                            inline="true"
                          //bsSwitchPrefix={"d-flex flex-row mt-2 p-0 w-100  ".concat(window.innerWidth > 575.98 ? " justify-content-center " : " justify-content-around ")}
                          >
                            <div className={"d-flex flex-row mt-2 p-0 w-100 ".concat(window.innerWidth > 575.98 ? " justify-content-center " : " justify-content-around ")}>
                              {["someone", "organisation"].map((element, index) => (
                                <Form.Check
                                  //custom
                                  inline
                                  key={`radioButtonPersonOrga_${index}`}
                                  label={element === 'someone' ? 'Une personne' : 'Une organisation'}
                                  type='radio'
                                  id={`radioButtonPersonOrga_${index}`}
                                  onChange={() => {
                                    // this.setState({ type_person: element, numberOfPersons: 20 },
                                    //   () => this.PersonListAPIs(this.state.firstRow, this.state.numberOfPersons, this.state.type_person, this.state.selectedFilterPersonName))

                                  }}
                                  checked={this.state.type_person === element}
                                />
                              ))}
                            </div>
                          </Form>


                          {(this.state.showAllParts || !this.state.creerPersonne) &&
                            <div className="d-flex flex-row col-lg-12 mt-3 mb-3 ">
                              <span className="col-lg-1  "></span>
                              <FormControl
                                id="barreRechercheArgument"
                                type="text"
                                className="col-lg-8 p-0 ml-auto text-center border "
                                placeholder={this.state.type_person === "someone" ? "\xa0Filtrer par nom et prénom" : "\xa0Filtrer par nom"}
                                onChange={(e) => {
                                  this.setState({ selectedFilterPersonName: e.target.value })
                                  // this.PersonListAPIs(this.state.firstRow, this.state.numberOfPersons, this.state.type_person, e.target.value)
                                }}
                                style={{
                                  border: 'solid',
                                  borderWidth: '3px',
                                  borderColor: (this.state.hoverRechercherUp || this.state.hoverRechercherDown) ? '#f1948a' : '#ffffff',
                                }}
                              // onMouseEnter={() => this.setState({ hoverRechercherDown: true })}
                              // onMouseLeave={() => this.setState({ hoverRechercherDown: false })}
                              />
                              <Button
                                className="d-flex justify-content-center p-0 mr-auto "
                                style={{ width: '50px', backgroundColor: '#E55C5C', color: 'white', border: 'none' }}
                                onClick={() => {
                                  // this.setState({ peopleSearchSpinner: true })
                                  // this.PersonListAPIs(this.state.firstRow, this.state.numberOfPersons, this.state.type_person, this.state.selectedFilterPersonName)
                                  // setTimeout(() => this.setState({ peopleSearchSpinner: false }), 2500)
                                }
                                }
                              >
                                <span className="align-self-center "><SearchIcon size={22} /></span>
                              </Button>
                              <span className="col-lg-1  ">
                                {(this.state.peopleSearchSpinner || this.state.peopleAPIOnProgress) &&
                                  <Spinner animation="border" variant="danger" />
                                }
                              </span>
                            </div>
                          }

                        </div>
                      </div>
                    }




                    {this.props.showConsoleLog && console.log('ComponentFormulaireCommentateur -> type_person test : ', this.state.type_person === 'someone')}


                    {(this.state.showAllParts ||
                      (this.state.type_person !== "" && !this.state.creerPersonne)) &&
                      this.state.reorganizePersonsListData.length > 0 &&
                      <div
                        className={'tab-pane fade show '.concat(window.innerWidth > 575.98 ? "mt-4 " : "mt-2")}
                        id="rechercheCommentateur"
                        role="tabpanel"
                        aria-labelledby="institutions-tab"
                        style={{
                          position: 'relative',
                          zIndex: '60',
                          // height: '550px',
                          // overflowY: 'scroll',
                        }}
                      >

                        {/*
                          <BootstrapTable
                            keyField='id'
                            columns={columnsPersonList}
                            data={this.state.reorganizePersonsListData} // 
                            // filter={filterFactory({ afterFilter })}
                            // expandRow={this.state.reorganizePersonsListExpandRow}
                            // pagination={paginationFactory(options)}
                            // striped
                            hover
                            condensed
                          />
                        */}

                      </div>
                    }

                    {this.state.reorganizePersonsListData.length === 0 &&
                      !this.state.peopleSearchSpinner && !this.state.peopleAPIOnProgress &&
                      <div className="w-100 text-center robotoFont16px mt-4 mb-3">Aucun résultat trouvé</div>
                    }



                    {(this.state.showAllParts || this.state.listperson.length === this.state.numberOfPersons) &&
                      <div className="d-flex flex-row ">
                        <Button
                          className="pt-2 pb-2 pl-4 pr-4 mt-4 mb-4 ml-auto mr-auto"
                          // active={this.state.creerPersonne}
                          variant={"dark"}
                          onClick={() => {
                            // this.setState({ numberOfPersons: this.state.numberOfPersons + 20 },
                            //   () => this.PersonListAPIs(this.state.firstRow, this.state.numberOfPersons, this.state.type_person, this.state.selectedFilterPersonName)
                            // )
                          }}
                          style={{ borderRadius: '50px' }}
                        >
                          {"Voir plus de commentateurs"}
                        </Button>
                      </div>
                    }

                    {false && (this.state.showAllParts || (this.state.listperson.length < this.state.numberOfPersons && !this.state.afficherOptions)) && // !this.state.creerPersonne &&
                      <div className="w-100 mt-4 p-0 ">
                        <div className="align-self-center textButtonUp ">
                          {this.state.type_person === 'someone' ?
                            "La personne que vous cherchez n'est pas dans la liste ?"
                            :
                            "L'organisation que vous cherchez n'est pas dans la liste ?"
                          }
                        </div>
                        <div className="row justify-content-center p-2 pl-4 pr-4">
                          <Button
                            className="col-lg-4 m-0 pl-2 pr-2 textButton "
                            // active={this.state.argumentNouveau}
                            variant={"dark"} // this.state.argumentNouveau ? "light"
                            onClick={() => {
                              this.setState({ creerPersonne: true, afficherOptions: true })
                            }}
                          >
                            {this.state.type_person === 'someone' ?
                              "Ajouter une nouvelle personne sur Spot"
                              :
                              "Ajouter une nouvelle organisation sur Spot"
                            }
                          </Button>
                        </div>
                      </div>
                    }


                    {(this.state.showAllParts || (this.state.creerPersonne && this.state.type_person === 'someone')) &&
                      <div className="ml-5 mt-4">
                        <div className="form-group col-lg-12 ">
                          <label htmlFor="addCommentateur_selectPersonName ">Quel est le nom de la personne ?</label>
                          <div className="d-flex justify-content-center input-group">
                            <input
                              type="text"
                              className="form-control text-left col-lg-6"
                              id="addCommentateur_selectPersonName"
                              placeholder=" -- "
                              value={person.pers_name}
                              onChange={e => this.setState({ person: { ...person, pers_name: e.target.value }, personID: 0 })} />
                          </div>
                        </div>

                        {this.props.showConsoleLog && console.log("so_typeID : ", person.so_typeID)}

                        <div className="form-group col-lg-12 ">
                          <label htmlFor="addCommentateur_selectPersonType">Quel est le profil de la personne ?</label>
                          <div className="d-flex justify-content-center">
                            <select
                              className="form-control col-lg-6"
                              id="addCommentateur_selectPersonType"
                              value={person.so_typeID}
                              onChange={e => {
                                this.setState({ person: { ...person, so_typeID: parseInt(e.target.value) } })
                              }
                              }
                            >
                              <option value={0}> -- </option>
                              {this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur  -> listsomeonetype : ", this.props.listsomeonetype)}
                              {this.props.listsomeonetype !== undefined && this.props.listsomeonetype.map(({ idtype, title }, index) => (   //importer la liste des catégories (API à ajouter)
                                <option key={`listsomeonetype10_${index}`} value={idtype}>{this.capitalizeFirstLetter(title)}</option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {(this.state.showAllParts || person.so_typeID === 4) &&
                          <div className="form-group col-lg-12 ">
                            <label htmlFor="infoTitre ">Quelle est la profession de ce citoyen ? </label>
                            <div className="d-flex justify-content-center input-group">
                              <input
                                type="text"
                                className="form-control text-left col-lg-6"
                                id="infoTitre"
                                placeholder=" -- "
                                value={person.so_profession}
                                onChange={e => this.setState({ person: { ...person, so_profession: e.target.value } })} />
                            </div>
                          </div>
                        }

                        {(this.state.showAllParts || person.so_typeID === 5) &&
                          <div className="form-group col-lg-12 ">
                            <label htmlFor="infoTitre ">Quelle est la profession de la personne ? </label>
                            <div className="d-flex justify-content-center input-group">
                              <input
                                type="text"
                                className="form-control text-left col-lg-6"
                                id="infoTitre"
                                placeholder=" -- "
                                value={person.so_profession}
                                onChange={e => this.setState({ person: { ...person, so_profession: e.target.value } })} />
                            </div>
                          </div>
                        }

                      </div>
                    }


                    {(this.state.showAllParts || (this.state.creerPersonne && this.state.type_person === 'organisation')) &&
                      <div className="ml-5">
                        <div className="form-group col-lg-12 ">
                          <label htmlFor="addCommentateur_selectOrgaName ">Quel est le nom de l'organisation ?</label>
                          <div className="d-flex justify-content-center input-group">
                            <input
                              type="text"
                              className="form-control text-left col-lg-6"
                              id="addCommentateur_selectOrgaName"
                              placeholder=" -- "
                              value={person.pers_name}
                              onChange={e => this.setState({ person: { ...person, pers_name: e.target.value } })} />
                          </div>
                        </div>
                        <div className="form-group col-lg-12 ">
                          <label htmlFor="addCommentateur_selectOrgaType">Quel est le type de cette organisation ?</label>
                          <div className="d-flex justify-content-center">
                            <select
                              className="form-control col-lg-6"
                              id="addCommentateur_selectOrgaType"
                              value={person.orga_typeID}
                              onChange={e => {
                                this.setState({ person: { ...person, orga_typeID: e.target.value } })
                              }
                              }
                            >
                              <option value={0}> -- </option>
                              {this.props.listorganisationtype !== undefined &&
                                this.props.listorganisationtype.map(({ idtype, title }, index) => (   //importer la liste des catégories (API à ajouter)
                                  <option key={`listorganisationtype3_${index}`} value={idtype}>{this.capitalizeFirstLetter(title)}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    }

                  </div>
                }


                {/* Renseigner l'avis du commentateur */}
                { /* this.props.addPosition === undefined && */}
                {(this.state.showAllParts) &&

                  <div className="form-group col-lg-12 pt-4 pb-4 whiteButtonShadow">

                    <label className="col-lg-12 mt-2 " htmlFor="addCommentateur_selectPosition">
                      <strong className="" style={{ color: this.props.addPosition === undefined ? '#000000' : '#909497' }}>
                        <div className="d-flex flex-wrap" >
                          <FileIcon size={24} />
                          {(this.state.listperson !== undefined && this.state.listperson !== null && this.state.listperson.length && Number(this.state.commentateurData.person.idperson) > 0) ?
                            <div className="d-flex flex-row" >
                              {"\xa0 Quel est l'avis de \xa0"}
                              <em style={{ color: '#ec7063' }}>
                                {this.getPersonNameByID(this.state.commentateurData.person.idperson)}
                              </em>
                              {"\xa0 au sujet du débat : "}
                            </div>
                            :
                            <div>
                              {"\xa0 Quel est l'avis du commentateur au sujet du débat : "}
                            </div>
                          }
                        </div>
                        <div className="ml-4">
                          {"\u00ab\xa0"}
                          <em >
                            {this.props.quoteTitle}
                          </em>
                          {"\xa0\u00bb *"}
                        </div>
                      </strong>
                    </label>


                    {/* daccordBox pasdaccord peutetre */}
                    <div className="d-flex flex-row justify-content-around col-lg-12 mt-5 ">

                      <div className="d-flex flex-row justify-content-center col-lg-3 ">
                        <button
                          type="button"
                          className={this.state.quote_link_typeID === 1 ? "col-lg-9 p-2 daccordBox" : "col-lg-9 p-2 whiteButtonShadow"}
                          onClick={() => this.setState({ quote_link_typeID: 1 })}
                        >
                          <strong style={{ color: this.state.quote_link_typeID === 1 ? "black" : "grey" }}>
                            D'accord
                          </strong>
                        </button>
                      </div>

                      <div className="d-flex flex-row justify-content-center col-lg-3 ">
                        <button
                          type="button"
                          className={this.state.quote_link_typeID === 3 ? "col-lg-9 p-2 peutetre" : "col-lg-9 p-2 whiteButtonShadow"}
                          onClick={() => this.setState({ quote_link_typeID: 3 })}
                        >
                          <strong style={{ color: this.state.quote_link_typeID === 3 ? "black" : "grey" }}>
                            Sous conditions
                          </strong>
                        </button>
                      </div>

                      <div className="d-flex flex-row justify-content-center col-lg-3 ">
                        <button
                          type="button"
                          className={this.state.quote_link_typeID === 2 ? "col-lg-9 p-2 pasdaccord" : "col-lg-9 p-2 whiteButtonShadow"}
                          onClick={() => this.setState({ quote_link_typeID: 2 })}
                        >
                          <strong style={{ color: this.state.quote_link_typeID === 2 ? "black" : "grey" }}>
                            Pas d'accord
                          </strong>
                        </button>
                      </div>

                    </div>

                  </div>
                }

              </div>
            }

          </Modal.Body>

        </Modal>

      </div >

    )
  }

}
export default ComponentFormulaireCommentateur