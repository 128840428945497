import React, { Component } from 'react';

import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import ThemeIconBar2 from './ThemeIconBar2';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';
import ModalWindowHelp from './ModalWindow_Help';


import '../full.css';
import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

registerLocale('fr', fr)


class ComponentFormulaireThematiques extends Component {
  constructor(props) {
    super(props);
  }

  state = {

    quoteInit: {},
    quote: {
      q_title: "",
      q_text: "",
      q_statut: 1,
      q_typeID: 0,
      themesID: [],
      sousthemeID: [],

      themesList: [], 
      sousThemesList: [],
    },

    showAllSousTitres: false,
    searchBarText: "",

    show: false,
    showConsoleLog: true,

  }


  componentDidMount() {
    // this.MainThemesAPIs()
  }

  componentDidUpdate = (prevProps) => {

  }

  initData = () => {

    this.props.showConsoleLog && console.log("in initData -> quote : ", this.props.quote)

    if (this.props.formulaireType === 'new quote' ||
      this.props.formulaireType === 'add argument' ||
      this.props.formulaireType === 'add context' ||
      this.props.formulaireType === 'add chronology') { // création d'une nouvelle citation


      this.searchSimilarQuote('')

      this.setState({
        infoTitreIntro: "",
        quote: {
          q_title: "",
          q_text: "",
          q_statut: 1,
          q_typeID: 0,
          themesID: [],
          sousthemeID: [],
          q_completude: 0,
          q_photo: null,
          q_placeScaleID: 0,
          q_placePositionID: 0,
          q_quoi: "",
          // q_quoiInd: 1,
          q_combien: "",
          // q_combienInd: 2,
          q_ou: "",
          // q_ouInd: 4,
          q_ouAfficherDansLeTitre: false,
          q_quandAfficherDansLeTitre: false,
          q_quand: "",
          // q_quandInd: 3,
          q_pourQui: "",
          // q_pourQuiInd: 5,
          q_vd_DateBeg: "",
          q_vd_DateEnd: null,
          q_vd_timeFormat: "jj/mm/aaaa",
          q_vd_timeIntro: "le",
          q_vd_timeScale: "jour", // timeScale === "jour" ou "mois"
          q_vd_timeType: "date", // timeType === "date" ou "durée"
          qm_modalityID: 1,
          qm_modalityContent: "",
          mo_title: "",
        },
        champsTitreDetaille: false,
        echelleDebatSelectionneValue: 0,
        echellePlaceSelectionneeValue: 0,
        reactSelectTextInput: "",
        citiesList: [],

        listquoteSimilar: [],
      }, () => { this.handleChangesNature(this.props.formulaireType === 'new quote' ? 1 : 0) })

      // this.props.citiesAPIs("")



      this.initFormulaireArgument()

      // this.SousThemesAPIs()
    } else { // quote en props => modification d'une citation

      this.setState({ quote: { ...this.props.quote } })

      this.setState({
        echelleDebatSelectionneValue: this.props.quote.q_placeScaleID,
        echellePlaceSelectionneeValue: this.props.quote.q_placePositionID,
        champsTitreDetaille: false,
        reactSelectTextInput: "",
        citiesList: [],
      })

      this.initIdQuotType()

      if ((this.props.quote.q_quoi !== undefined && this.props.quote.q_quoi !== null && this.props.quote.q_quoi.length !== 0) ||
        (this.props.quote.q_combien !== undefined && this.props.quote.q_combien !== null && this.props.quote.q_combien.length !== 0) ||
        (this.props.quote.q_ou !== undefined && this.props.quote.q_ou !== null && this.props.quote.q_ou.length !== 0) ||
        (this.props.quote.q_quand !== undefined && this.props.quote.q_quand !== null && this.props.quote.q_quand.length !== 0) ||
        (this.props.quote.q_pourQui !== undefined && this.props.quote.q_pourQui !== null && this.props.quote.q_pourQui.length !== 0)) {
        // this.setState({ champsTitreDetailleForced: true })
      } else {
        this.setState({ champsTitreDetailleForced: false })
      }

      this.props.showConsoleLog && console.log("out initData -> this.props.quote 2 : ", this.props.quote)

      // this.props.citiesAPIs("")


    }
  }


  initIdQuotType = () => {
    this.props.showConsoleLog && console.log('initIdQuotType -> quote : ', this.props.quote)
    this.props.showConsoleLog && console.log('initIdQuotType -> natureInformationTable : ', this.props.natureInformationTable)
    this.props.showConsoleLog && console.log('initIdQuotType -> q_typeID : ', this.props.quote.q_typeID)

    if (this.props.quote.idquote !== -1) {
      this.setState({ infoTitreIntro: this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.props.quote.q_typeID, 'introduction') })
    }

  }


  getThemesIDlist = (themesList) => {

    let returnListID = []

    if (themesList !== undefined) {
      themesList.forEach((element) => {
        returnListID.push(element.idtheme)
      })
    }

    return returnListID
  }

  compareTwoLists = (list1, list2) => {
    // S'il y a un élément commun entre les 2 listes : renvoie true. Sinon envoie false

    let returnBool = false

    this.props.showConsoleLog && console.log("compareTwoLists -> list1 :", list1)
    this.props.showConsoleLog && console.log("compareTwoLists -> list2 :", list2)

    const filteredArray = list1.filter(value => list2.includes(value));

    this.props.showConsoleLog && console.log("compareTwoLists -> filteredArray :", filteredArray)

    return (filteredArray.length > 0)

  }


  getArrayByAttributeName = (objectsArray, attributeName) => {

    let result = []

    if (objectsArray !== undefined) {
      objectsArray.forEach((element, index) => {
        if ($(element).attr(attributeName) !== undefined && $(element).attr(attributeName) !== null) {
          this.props.showConsoleLog && console.log('getArrayByAttributeName -> OK ')
          result.push($(element).attr(attributeName))
        }
      })
    }

    this.props.showConsoleLog && console.log("getArrayByAttributeName -> result : ", result)

    return result

  }


  getTitleParentList = (parentsIDlist) => {
    let returnText = parentsIDlist.length === 1 ? "Thème principal :\n" : "Thèmes principaux :\n"

    this.props.showConsoleLog && console.log("getTitleParentList -> parentsIDlist :", parentsIDlist)

    parentsIDlist.forEach((element) => {
      returnText += "  - ".concat(element.t_title, "\n")
    })

    this.props.showConsoleLog && console.log("getTitleParentList -> returnText :", returnText)

    return returnText
  }


  selectThemesFormulaire = (idThemeToChange, type) => {

    this.props.showConsoleLog && console.log("selectThemesFormulaire -> idThemeToChange : ", idThemeToChange)

    let tempQuote = Object.assign({}, this.state.quote)
    let tempThemes = []

    if (type === "mainTheme") {
      tempThemes = [...tempQuote.themesList]
    } else if (type === "sousTheme") {
      tempThemes = [...tempQuote.sousThemesList]
    }


    this.props.showConsoleLog && console.log("selectThemesFormulaire -> quote avant 1 : ", this.state.quote)
    this.props.showConsoleLog && console.log("selectThemesFormulaire -> quote avant 2 : ", tempQuote)
    this.props.showConsoleLog && console.log("selectThemesFormulaire his -> this.props.quote selectThemesFormulaire -> themesID avant : ", tempThemes)

    let arrayThemesID = this.getArrayByAttributeName(tempThemes, 'idtheme')

    this.props.showConsoleLog && console.log("selectThemesFormulaire -> arrayThemesID : ", arrayThemesID)

    if (arrayThemesID.includes(idThemeToChange)) { // Suppression du thème

      this.props.showConsoleLog && console.log("selectThemesFormulaire -> remove :", tempThemes.filter((element) => element.idtheme !== idThemeToChange))
      this.props.showConsoleLog && console.log("selectThemesFormulaire -> indice déjà sélectionné ")

      let tempThemes2 = tempThemes.filter((element) => element.idtheme !== idThemeToChange)
      if (type === "mainTheme") {
        tempQuote.themesList = tempThemes2
      } else if (type === "sousTheme") {
        tempQuote.sousThemesList = tempThemes2
      }


    } else if ((arrayThemesID.length < this.props.maxThemeSelected) || type === "sousTheme") { // Ajout d'un thème avec limitation du nombre de thèmes sélectionnables

      this.props.showConsoleLog && console.log("selectThemesFormulaire -> indice nouvellement sélectionné ")

      tempThemes.push({ idtheme: idThemeToChange })
      if (type === "mainTheme") {
        tempQuote.themesList = tempThemes
      } else if (type === "sousTheme") {
        tempQuote.sousThemesList = tempThemes
      }

    }

    this.props.showConsoleLog && console.log("selectThemesFormulaire -> quote après : ", tempQuote)
    this.props.showConsoleLog && console.log("selectThemesFormulaire -> themesID après : ", tempQuote.themesList)

    this.setState({ quote: tempQuote, searchBarText: "" })

  }


  closeFormulaire = (quoteID) => {

    this.props.showConsoleLog && console.log('closeFormulaire -> in quoteID :', quoteID)
    this.props.showConsoleLog && console.log('closeFormulaire -> in QuotePrincipaleAPIs :', this.props.QuotePrincipaleAPIs)

    this.props.QuotePrincipaleAPIs !== undefined && this.props.QuotePrincipaleAPIs(quoteID)

    this.setState({ show: false })
  }


  checkNewThemes(originalQuoteThemesID, newQuoteThemesID) {
    let returnListNewThemesID = []

    newQuoteThemesID.forEach(element => {
      if (!originalQuoteThemesID.includes(element)) {
        returnListNewThemesID.push(element)
      }
    })

    return returnListNewThemesID
  }

  checkDeleteQuoteTheme = (quote, q_originID) => {

    this.props.showConsoleLog && console.log('comparModif -> temes concat : ', this.props.quote.themesList.concat(this.props.quote.sousThemesList))

    // Vérification des thèmes
    let originalQuoteThemesID = this.getArrayByAttributeName(this.props.quote.themesList.concat(this.props.quote.sousThemesList), 'idtheme').sort(function (a, b) { return a - b; });
    let newQuoteThemesID = quote.themesList !== undefined ?
      this.getArrayByAttributeName(quote.themesList.concat(quote.sousThemesList), 'idtheme').sort(function (a, b) { return a - b; })
      : []

    this.props.showConsoleLog && console.log('comparModif -> originalQuoteThemesID : ', originalQuoteThemesID)
    this.props.showConsoleLog && console.log('comparModif -> newQuoteThemesID : ', newQuoteThemesID)

    let newThemesID = this.checkNewThemes(originalQuoteThemesID, newQuoteThemesID)
    let deletedThemesID = this.checkNewThemes(newQuoteThemesID, originalQuoteThemesID)
    this.props.showConsoleLog && console.log('comparModif -> newThemes : ', newThemesID)
    this.props.showConsoleLog && console.log('comparModif -> deletedThemes : ', deletedThemesID)
    // Fin vérification des thèmes

    if (deletedThemesID.length > 0) {
      this.props.showConsoleLog && console.log('checkDeleteQuoteTheme -> suppression de thèmes ', deletedThemesID)
      this.deleteQuoteTheme(deletedThemesID, newThemesID, quote, q_originID)
    } else {
      this.props.showConsoleLog && console.log('checkDeleteQuoteTheme -> pas de suppression de thèmes ')
      this.checkAddQuoteTheme(newThemesID, quote, q_originID)
    }

  }

  deleteQuoteTheme = (deletedThemesID, newThemesID, quote, q_originID) => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.QuoteApi()

    this.props.showConsoleLog && console.log('deleteQuoteTheme -> quote.q_originID ', quote.q_originID)
    this.props.showConsoleLog && console.log('deleteQuoteTheme -> deletedThemesID ', deletedThemesID)

    apiInstance.quoteQuoteIDThemesDelete(q_originID, deletedThemesID, (error, data, response) => { // Supprimer les themes d'une quote
      if (error) {
        console.error(error)
      }
      else {
        this.props.showConsoleLog && console.log('deleteQuoteTheme -> suppression de thèmes OK ')
        this.checkAddQuoteTheme(newThemesID, quote, q_originID)
      }
    })

  }

  checkAddQuoteTheme = (newThemesID, quote, q_originID) => {
    if (newThemesID.length > 0) {
      this.props.showConsoleLog && console.log('checkAddQuoteTheme -> ajout de thèmes ', newThemesID)
      this.addQuoteTheme(newThemesID, quote, q_originID)
    } else {
      this.props.showConsoleLog && console.log('checkAddQuoteTheme -> pas d ajout de thèmes ')
      this.closeFormulaire(this.props.quote.idquote)
    }
  }

  addQuoteTheme = (newThemesID, quote, q_originID) => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.QuoteApi()

    this.props.showConsoleLog && console.log('addQuoteTheme -> quote.q_originID ', quote.q_originID)
    this.props.showConsoleLog && console.log('addQuoteTheme -> newThemesID ', newThemesID)

    apiInstance.quoteQuoteIDThemesPost(q_originID, newThemesID, (error, data, response) => { // Ajouter les themes d'une quote
      if (error) {
        console.error(error)
      }
      else {
        this.props.showConsoleLog && console.log('addQuoteTheme -> ajout de thèmes OK ')
        this.closeFormulaire(this.props.quote.idquote)
      }
    })

  }


  render() {

    const quote = this.state.quote;

    const mainThemesList = this.props.listThemes.filter((element) => element.t_mainTheme === 1)
    const sousThemesList = this.props.listThemes.filter((element) => (element.t_mainTheme === null || element.t_mainTheme !== 1))

    const mainThemesSelectedID = this.getThemesIDlist(this.state.quote.themesList) 
    const sousThemesSelectedID = this.getThemesIDlist(this.state.quote.sousThemesList)
    const sousThemesToShow = sousThemesList
      .filter((element) => (
        (this.state.showAllSousTitres ||
          sousThemesSelectedID.includes(element.idtheme) ||
          this.compareTwoLists(mainThemesSelectedID, this.getThemesIDlist(element.parentsIDlist)))
        && (this.state.searchBarText === "" || element.t_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.state.searchBarText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
      ))
      .sort(function (a, b) { return a.parentsIDlist[0].idtheme - b.parentsIDlist[0].idtheme; })

    return (

      <div className="m-0 p-0 " >


        {// Modification des thématiques
          this.props.quote.idquote !== -1 &&
          this.props.quote.themesList !== undefined &&
          this.props.formulaireType === 'thématiques' &&
          <div className="d-flex justify-content-start m-0 p-0 ">
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ show: true })
                this.initData()
              }}
              className='d-flex flex-row align-items-center m-0 p-2 octiconBox borderRadius whiteButtonHover'
              title="Modifier les thématiques"
              style={{ width: '50px', height: '50px' }}
            >
              <img
                className="rounded w-100 "
                src={this.props.url_image + (this.props.quote.themesList.length === 0 ? '00_Icone_Vide.png' : '00_Icone_changer_themes2.png')}
                alt=""
                style={{ filter: '' }}
              />
            </Button>
          </div>
        }


        <Modal
          show={this.state.show}
          onHide={() => {
            // this.initData()
            // this.setState({ show: false })
            this.closeFormulaire(this.props.quote.idquote)
          }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center w-100 ">

              <div
                className="col-12 col-lg-9 m-0 p-0 titreFormulaire "
                style={{ fontSize: window.innerWidth > 576 ? '25px' : '19px' }}
              >
                Modifier les thématiques du débat
              </div>

            </Modal.Title>
          </Modal.Header>


          <Modal.Body>

            <div className="">

              { // Affichage des thématiques
                (this.props.formulaireType === 'thématiques') &&
                <div className="d-flex ">
                  <div className="d-flex flex-column col-lg-12 p-0 mt-4 ">
                    <div className="d-flex flex-row ongletVerticalTitre w-100 ">
                      <div>Thématiques</div>
                      <div className="d-flex align-items-center ml-4 pb-1">
                        <ModalWindowHelp
                          pageName='ComponentFormulaire'
                          helpType='Thématiques'
                          listThemes={this.props.listThemes}
                          mainThemesList={mainThemesList}
                          sousThemesList={sousThemesList}

                          url_image={this.props.url_image}
                        />
                      </div>
                    </div>

                    {this.props.showConsoleLog && console.log('props.quote.themesList : ', this.props.quote.themesList)}
                    {this.props.showConsoleLog && console.log('state.quote.themesList : ', this.state.quote.themesList)}

                    <div className="d-flex justify-content-center w-100 mt-4">

                      <div className="d-flex flex-column col-lg-11 mt-2 pb-4 border rounded">

                        {this.state.quote.themesList !== undefined &&
                          <div className="d-flex flex-row pt-3 ">
                            <label className="mr-auto" htmlFor="infoThematiques_t">Sélectionner les thématiques liées à cette information :</label>
                            <small
                              id="infoDescriptionFaciledHelpBlock_2"
                              className="form-text text-right"
                              style={{
                                color: this.state.quote.themesList.length < this.props.maxThemeSelected ? 'grey' : ' #ec7063 ',
                                fontWeight: this.state.quote.themesList.length < this.props.maxThemeSelected ? 'normal' : 'bold',
                              }}
                            >
                              {this.state.quote.themesList.length} / {this.props.maxThemeSelected} max.
                            </small>
                          </div>
                        }


                        {<div className="d-flex flex-wrap justify-content-center mt-2 ">
                          {this.props.showConsoleLog && console.log("ThemeIconBar -> quote : ", this.state.quote)}
                          {true &&
                            mainThemesList
                              .filter((element, index) => element.t_mainTheme)
                              .map((element, index) => (
                                <div key={`themeIconSelect_${index}`}>
                                  {this.props.showConsoleLog && console.log("ThemeIconBar -> getArrayByAttributeName : ", this.getArrayByAttributeName(this.state.quote.themesList, 'idtheme'))}
                                  {this.props.showConsoleLog && console.log("ThemeIconBar -> includes : ", this.getArrayByAttributeName(this.state.quote.themesList, 'idtheme').includes(element.idtheme))}
                                  <ThemeIconBar2
                                    element={element}
                                    onClickTheme={(e) => this.selectThemesFormulaire(e, 'mainTheme')}
                                    selected={this.getArrayByAttributeName(this.state.quote.themesList, 'idtheme').includes(element.idtheme)}

                                    selectedThemesNumber={this.state.selectedThemesNumber}
                                    maxThemeSelected={this.props.maxThemeSelected}

                                    showConsoleLog={this.props.showConsoleLog}
                                    url_image={this.props.url_image}

                                  />
                                </div>
                              ))
                          }


                          {this.props.showConsoleLog && console.log("sousThemesToShow :", sousThemesToShow)}
                          {this.props.showConsoleLog && console.log("searchBarText :", this.state.searchBarText)}

                          {this.state.quote.themesList !== undefined && this.state.quote.themesList.length > 0 &&
                            < div className="col-lg-12 mt-5">
                              <div className="d-flex flex-row">
                                <div className="infoThematiques_t mr-auto ">Indiquer si cette information se rattache à des sujets précis :</div>
                                <FormControl
                                  id="barreRechercheSousThemes_2"
                                  type="text"
                                  className="col-lg-5 m-0 p-0 barreRechercheArgument text-left border "
                                  placeholder={"\xa0Rechercher des sujets par mots clés"}
                                  onChange={(e) => {
                                    this.setState({ searchBarText: e.target.value })
                                  }}
                                  style={{
                                    border: 'solid',
                                    borderWidth: '3px',
                                    borderColor: '#f1948a',
                                  }}
                                  value={this.state.searchBarText}
                                // onMouseEnter={() => this.setState({ hoverRechercherDown: true })}
                                // onMouseLeave={() => this.setState({ hoverRechercherDown: false })}
                                />
                              </div>
                              <div className="d-flex flex-wrap justify-content-center mt-3 ">
                                {
                                  sousThemesToShow
                                    .map((element, index) => (
                                      <Button
                                        key={`sousThemesToShow_${index}`}
                                        className={"mt-1 mr-1 ".concat(element.parentsIDlist[0].t_title.replace(/[\s&]/g, '_'), "_IconeBorder2")}
                                        variant={sousThemesSelectedID.includes(element.idtheme) ? "dark" : "light"}
                                        onClick={() => this.selectThemesFormulaire(element.idtheme, 'sousTheme')}
                                        title={this.getTitleParentList(element.parentsIDlist)}
                                      >
                                        {element.t_title}
                                      </Button>
                                    ))
                                }
                              </div>
                            </div>
                          }

                        </div>}

                      </div>
                    </div>

                  </div>
                </div>
              }



              { // Affichage des boutons de bas de page
                <div className="d-flex mt-4">
                  <div className="d-flex flex-row justify-content-center align-items-start w-100 ">

                    <Button
                      className="buttonStepper validerBox col-lg-3"
                      onClick={() => this.closeFormulaire(this.props.quote.idquote)}
                      disabled={false}
                    >
                      Annuler
                    </Button>

                    { // modification d'une citation existante
                      (this.props.formulaireType === 'thématiques') &&
                      <div className="d-flex flex-row justify-content-center align-items-start col-lg-4 ">
                        {(this.props.quote.idquote !== -1) &&
                          <Button
                            className="buttonStepper validerBox w-100 "
                            onClick={() => this.checkDeleteQuoteTheme(quote, this.props.quote.q_originID)}
                            disabled={false}
                          >
                            Modifier les thématiques
                          </Button>
                        }
                      </div>
                    }

                  </div>
                </div>
              }


            </div>

          </Modal.Body>



        </Modal>


      </div>

    )

  }

}

export default ComponentFormulaireThematiques