import React, { Component } from 'react';

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import ComponentChoixNature from './ComponentChoixNature';
import Select from 'react-select';

import {
  CheckCircleIcon, ChevronUpIcon, CircleIcon, ReplyIcon, TrashIcon,
  ChevronDownIcon, XCircleIcon, CheckCircleFillIcon, XCircleFillIcon
} from '@primer/octicons-react'

import { Button, InputGroup, DropdownButton, Dropdown, Form, ListGroup, OverlayTrigger, Tooltip, FloatingLabel } from "react-bootstrap"

import '../full.css';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr)

// const ComponentArgument = ({idArgument, titre, texte, cnature, datePublication, dateDernieremodif, completude, thématique, sujet}) => (
class ComponentFormulaireNatureEtTitre extends Component {

  state = {
    afficherFiltres: false,
    showMoreInfoNature: false,
    showConsoleLog: true,

    showSocietyDescriptionTable: false,
    showKnowledgeTable: false,
    showAdvices: false,

    buttonHovered: 0,

    debateTitleExample: false,
    showModalities: false,

    nuanceBlockShow: true,
    argumentBlockShow: true,

    // addNuancesShow: false,
    // addArgumentsShow: false,

    reinit_data: true,
  }


  initData = () => {
    if (this.props.formulaireType === 'mainPage') {
      this.setState({ nuanceBlockShow: false, argumentBlockShow: false, reinit_data: false })
    } else if (this.props.formulaireType === 'nuances') {
      this.setState({ nuanceBlockShow: false, argumentBlockShow: false, reinit_data: false })
    } else if (this.props.formulaireType === 'argument') {
      this.setState({ nuanceBlockShow: false, argumentBlockShow: false, reinit_data: false })
    } else {
      this.setState({ nuanceBlockShow: false, argumentBlockShow: false, reinit_data: false })
    }
  }


  selectExistingQuote = (row) => {

    if (row.qlt_title === "pour") {
      return (
        <div
          className="d-flex flex-column commentateurTextPour w-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="d-flex justify-content-center w-100">
            <CheckCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} />
          </div>
          <div className="d-flex justify-content-center w-100 robotoFont12px">
            <div>Déjà pour</div>
          </div>
        </div>
      )
    } else if (row.qlt_title === "contre") {
      return (
        <div
          className="d-flex flex-column commentateurTextContre w-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="d-flex justify-content-center w-100">
            <CheckCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} />
          </div>
          <div className="d-flex justify-content-center w-100 robotoFont12px">
            <div>Déjà contre</div>
          </div>
        </div>
      )
    } else if (row.qlt_title === "comment") {
      return (
        <div
          className="d-flex flex-column commentateurTextPeutEtre w-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="d-flex justify-content-center w-100">
            <CheckCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} />
          </div>
          <div className="d-flex justify-content-center w-100 robotoFont12px">
            <div>Déjà sous conditions</div>
          </div>
        </div>
      )
    } else if (row.qlt_title === "quoi" || row.qlt_title === "quand") {
      return (
        <div
          className="d-flex flex-column commentateurTextPeutEtre w-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="d-flex justify-content-center w-100">
            <CheckCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} />
          </div>
          <div className="d-flex justify-content-center w-100 robotoFont12px">
            <div>Déjà sélectionné</div>
          </div>
        </div>
      )
    } else if (row.qlt_title === "dejaArgument") {
      return (
        <div
          className="d-flex flex-column commentateurTextPeutEtre w-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="d-flex justify-content-center w-100">
            <XCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} />
          </div>
          <div className="d-flex justify-content-center w-100 robotoFont12px">
            <div>Déjà un argument</div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className="noBorder "
          style={{ backgroundColor: 'transparent' }}
        >
          {(row.selected) ? <CheckCircleFillIcon size={window.innerWidth > 575.98 ? 24 : 18} /> : <CircleIcon size={window.innerWidth > 575.98 ? 24 : 18} />}
        </div>
      )
    }

  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }

  barrerTexte(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }



  render() {
    const quote = this.props.quote
    this.props.showConsoleLog && console.log("quote :", quote)

    if (this.props.idQuoteTypeAllowed !== undefined && this.props.idQuoteTypeAllowed.length === 1) {
      quote.q_typeID = this.props.idQuoteTypeAllowed[0]
    }


    if (this.state.reinit_data === true) {
      this.initData()
    }


    const introToDisplay_currentQuote = this.props.miseEnFormeIntroSansCitation(this.props.infoTitreIntro_currentQuote, quote.q_title).concat(' ... ')
    const introToDisplay_mainQuote = this.props.miseEnFormeIntroSansCitation(this.props.infoTitreIntro_mainQuote, this.props.mainQuote.q_title).concat(' ... ')

    const guideLines = [
      {
        title: "Exprimer le débat dans sa forme la plus générale. Les différentes nuances du débat seront à renseigner dans la page débat.",
        exampleNok: ["Il est nécessaire de réduire les émissions de gaz à effet de serre de 50% d'ici 2030 par rapport à 2019 pour limiter la hausse des températures à 1,5°C."],
        exampleOk: ["Il est nécessaire de réduire les émissions de gaz à effet de serre."],
      },
      {
        title: "Les débats doivent pouvoir être tranchés par une de nos institutions (mairie, région, Assemblée Nationale, Parlement européen etc.)",
        exampleNok: ["Le député X. est complotiste", "Il faut interdire les pizzas à l'ananas."],
        exampleOk: ["Il est nécessaire d'installer un téléphérique dans la ville de Lyon (mairies de Lyon et des villes alentours)."],
      },
      {
        title: "Pour pouvoir être débattu, un titre ne doit contenir qu'une seule information.",
        exampleNok: ["L'Homme est responsable du réchauffement climatique et doit donc construire des centrales nucléaires."],
        exampleOk: ["L'Homme est reponsable du réchauffement climatique (fait).", "Il est nécessaire de construire des centrales nucléaires (proposition d'action)."],
      },
      {
        title: "Un titre ne doit pas contenir d'argument (les arguments sont à renseigner dans la page dédiée)",
        exampleNok: ["Il est nécessaire de déployer la 5G pour accompagner le développement économique de la France"],
        exampleOk: ["Il est nécessaire de déployer la 5G"],
      },
      {
        title: "Pour être clair, un titre peut éventuellement répondre aux questions : quoi, combien, où, quand, pour qui.",
        exampleNok: [],
        exampleOk: [],
      },
    ]

    // Exemple : Il est nécessaire de réduire les émissions de gaz à effet de serre de 50% d'ici 2030 et de 80% d'ici 2040 par rapport à 2019 pour limiter la hausse des températures à 1,5°C 
    const example = {
      quoi: "Il est nécessaire de ... réduire les émissions de gaz à effet de serre ", //.concat(this.barrerTexte("de 50% d'ici 2030 par rapport à 2019 pour limiter la hausse des températures à 1,5°C")),
      combien: " ... de 50% par rapport à 2019",
      quand: " ... avant 2030"
    }

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> quote :", this.props.quote)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> reference :", this.props.reference)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> commentateurData :", this.props.commentateurData)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> quoteModalities :", this.props.quoteModalities)

    this.props.showConsoleLog && console.log("list of values in key qm_originID :", this.props.quoteModalities.map(item => item.qm_originID))


    ////////////       Tri des nuances       ////////////

    // Nuances actives sur cette source
    const quoteModalities_currentRef_active = this.props.quoteModalities
      .filter((element) =>
        element.qm_quoteOriginID === this.props.mainQuote.q_originID &&
        element.qap_extractReferenceOriginID === this.props.reference.re_originID &&
        element.qap_persOriginID === this.props.commentateurData.person.pers_originID &&
        element.qap_statut === 1
      )
      .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

    const quoteModalities_IdsList = quoteModalities_currentRef_active.map(item => item.qm_originID)

    // Nuances actives sur les autres sources
    let quoteModalities_others = this.props.quoteModalities
      .filter((element) =>
        quoteModalities_IdsList.indexOf(element.qm_originID) === -1
      )
      .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> quoteModalities_currentRef_active :", quoteModalities_currentRef_active)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> quoteModalities_others :", quoteModalities_others)

    // Unique
    quoteModalities_others = quoteModalities_others.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.id_quoteModality === value.id_quoteModality
      ))
    )
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> quoteModalities_others 2 :", quoteModalities_others)

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> argumentPourContre :", this.props.argumentPourContre)


    ////////////       Tri des arguments       ////////////

    // Arguments actifs sur cette source
    let arguments_currentRef_active = []
    if (this.props.argumentPourContre !== undefined) {
      arguments_currentRef_active = this.props.argumentPourContre
        .filter((element) =>

          // element.commentateursList.map(item => item.pers_originID).indexOf(this.props.commentateurData.person.pers_originID) > -1 &&
          // element.commentateursList.map(item => item.pers_originID) : liste des commentateurs de cet argument
          // arr.indexOf("bob") > -1 : check if element is in array

          element.commentateursList
            .filter((item) => item.pers_originID === this.props.commentateurData.person.pers_originID && item.qlap_statut === 1).length > 0
        )
        .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

    }

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_currentRef_active :", arguments_currentRef_active)

    const arguments_IdsList = arguments_currentRef_active.map(item => item.quote.q_originID)

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> COND 1 :", this.props.argumentPourContre
      .filter((element) =>
        arguments_IdsList.indexOf(element.quote.q_originID) === -1
      ))

    // Nuances actives sur les autres sources
    let arguments_others = []
    if (this.props.argumentPourContre !== undefined) {
      arguments_others = this.props.argumentPourContre
        .filter((element) =>
          arguments_IdsList.indexOf(element.quote.q_originID) === -1
        )
        .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

      // Unique
      arguments_others = arguments_others.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.quote.q_originID === value.quote.q_originID
        ))
      )
    }

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_ this.props.argumentPourContre :", this.props.argumentPourContre)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_currentRef_active :", arguments_currentRef_active)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_IdsList :", arguments_IdsList)
    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_others :", arguments_others)



    return (

      <div className="d-flex flex-column col-lg-12 m-0 p-0 ">


        {/* Création et modification d'un débat */}

        {(this.props.formulaireType === 'new quote' || this.props.formulaireType === "change title") &&
          <div className="d-flex flex-column w-100 ">

            {// Bloc d'information sur la nature de l'information
              <div className="d-flex flex-column justify-content-center m-0 p-0 " style={{}}>

                <div className="d-flex flex-column mt-4 " >
                  <div className="d-flex flex-column ongletVerticalInfoBox " >
                    <div className="mr-auto ongletVerticalTitreText">Qu'est-ce qu'un débat sur Spot ?</div>
                  </div>
                  <div className="ml-5 mt-3 robotoFont16px">Un débat est une proposition d'action :</div>
                </div>



                { // Affichage des types d'information à sélectionner

                  <div className="d-flex flex-column justify-content-around col-lg-12 m-0 p-0">

                    <div className="d-flex justify-content-center mt-4 col-lg-12 " style={{ height: "230px" }} >
                      <div className="col-lg-7">
                        {(quote.q_typeID !== 0 || this.state.buttonHovered !== 0) &&
                          <ComponentChoixNature
                            originTable="selectedNature"
                            element={this.props.getArrayIndexByAttributeValue(this.props.natureInformationTable, 'id', quote.q_typeID !== 0 ? quote.q_typeID : this.state.buttonHovered)}
                            handleChangesNature={this.props.handleChangesNature}
                            q_typeID={quote.q_typeID}
                            buttonHovered={this.state.buttonHovered}
                            url_image={this.props.url_image}
                            showMoreInfoNature={true}
                            highlightBoolean={true}

                            showConsoleLog={this.props.showConsoleLog}

                            formulaireType={this.props.formulaireType}
                          />
                        }
                      </div>
                    </div>

                  </div>
                }

              </div>
            }


            { // Bloc de création du débat
              <div className="w-100 ">
                <div className="d-flex flex-row ongletVerticalTitre mt-0 mb-4 ">
                  <div className="">
                    {
                      this.props.formulaireType === "change title" ?
                        "Modifier le titre de l'information" :
                        (this.props.formulaireType === 'new quote' ?
                          "Saisir le titre du débat"
                          :
                          ""
                        )
                    }
                  </div>
                </div>


                {
                  quote.q_typeID === 0 &&
                  <div className='d-flex flex-row justify-content-center'>
                    <div className="col-lg-11 mt-2 mb-2 p-1 text-center robotoFont14px rounded" style={{ backgroundColor: ' #ec7063 ' }}>
                      Sélectionnez d'abord la nature de l'information que vous souhaitez ajouter sur Spot, puis le titre.
                    </div>
                  </div>
                }


                {quote.q_typeID !== 0 && // Saisie du titre de la citation
                  <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

                    <div className="d-flex flex-column justify-content-center col-lg-11 m-0 pl-3 pr-3 pt-3 pb-5 rounded border">

                      {
                        <div className="d-flex flex-column col-lg-12 mt-0 p-0">

                          <u className={"d-flex align-items-center mb-1 robotoFont15px"}>Renseigner le titre de la citation</u>

                          <FloatingLabel
                            controlId="floatingTextarea"
                            // label="Comments"
                            className="mt-2 "
                          >
                            <Form.Control
                              bsPrefix="col-lg-12 m-0 p-3 robotoFont15px"
                              as="textarea"
                              // placeholder="Leave a comment here"
                              value={introToDisplay_currentQuote.concat(quote.q_quoi)}
                              // onChange={e => this.changeQuoteTitle(e.target.value)}
                              onChange={e =>
                                e.target.value.length < introToDisplay_currentQuote.length ? // Si l'utilisateur efface l'intro, on vide q_quoi
                                  this.props.handleChangeQuote('q_quoi', "")
                                  :
                                  (e.target.value.slice(0, introToDisplay_currentQuote.length) !== introToDisplay_currentQuote ?  // Si l'utilisateur essaye de changer l'intro, on ne la change pas
                                    ""
                                    :
                                    this.props.handleChangeQuote('q_quoi', e.target.value.replace(introToDisplay_currentQuote, ""))
                                  )
                              }
                            />
                          </FloatingLabel>

                          <div className='d-flex flex-row m-0 pt-1 pl-2'>
                            <ReplyIcon size={14} className="rotate180deg " />
                            <p className='robotoFont12px mt-0 mb-0 mr-auto pl-2'>Exprimer le débat dans sa forme la plus générale. <br /> Les différentes nuances du débat seront à renseigner dans la page débat.</p>

                            <p className='mr-auto '></p>
                            <small
                              id="infoDescriptionDifficiledHelpBlock"
                              className={quote.q_title.length === this.props.longueurMaxTitre ? "text-right " : "text-muted robotoFont13px mt-0 pr-2"}
                              style={{
                                'fontWeight': quote.q_title.length === this.props.longueurMaxTitre ? '' : '',
                                'color': quote.q_title.length === this.props.longueurMaxTitre ? ' #ec7063 ' : '',
                              }}
                            >
                              {quote.q_title.length} car. / {this.props.longueurMaxTitre} max.
                            </small>
                          </div>


                          { // Comment rédiger un titre sur Spot
                            <div className="w-100 m-0 mt-4 mb-3 p-0 pl-5 robotoFont12px" style={{ color: 'grey' }}>
                              <div className="d-flex flex-row col-lg-12 align-items-center ">

                                <Button
                                  onClick={() => this.setState({ showAdvices: !this.state.showAdvices })}
                                  variant="light"
                                  className="d-flex flex-row align-items-center mt-0 mb-0 ml-1 pt-0 pb-0 pl-3 pr-3"
                                >
                                  <p className="mr-0 ml-1 mb-0 mt-0 p-2 robotoFont13px">Comment rédiger un titre sur Spot ?</p>
                                  {this.state.showAdvices ?
                                    <ChevronUpIcon
                                      size={16}
                                      className="m-0 p-0 "
                                    />
                                    :
                                    <ChevronDownIcon
                                      size={16}
                                      onClick={() => this.setState({ showAdvices: !this.state.showAdvices })}
                                      className="m-0 p-0 "
                                    />}
                                </Button>

                              </div>

                              {this.state.showAdvices &&
                                <ul className="col-lg-11 mt-3 pl-5 ">
                                  {
                                    guideLines.map((element, index) => (
                                      <li key={`guideLines_${index}`} className="pt-2 pl-2">
                                        <div>{element.title}</div>
                                        {element.exampleNok.length > 0 &&
                                          <ul className="pt-1">
                                            {element.exampleNok.map((exampleNok, index2) => (
                                              <li key={`exampleNok_${index2}`} className="pt-1" style={{ color: '#e55c5c' }} >
                                                <XCircleIcon size={16} />
                                                <span className='pl-2' style={{ color: 'grey' }}>{"\xa0".concat(exampleNok)}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        }
                                        {element.exampleOk.length > 0 &&
                                          <ul className="">
                                            {element.exampleOk.map((exampleOk, index3) => (
                                              <li key={`exampleOk_${index3}`} className="pt-1" style={{ color: '#B9BD4A' }} >
                                                <CheckCircleIcon size={16} />
                                                <span className='pl-2' style={{ color: 'grey' }}>{"\xa0".concat(exampleOk)}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        }
                                      </li>
                                    ))
                                  }
                                </ul>
                              }
                            </div>
                          }

                        </div>
                      }

                    </div>
                  </div>
                }


                {/* Suggestion de débats existants */}

                {this.props.showConsoleLog && console.log("listquoteSimilar :", this.props.listquoteSimilar)}
                {this.props.showConsoleLog && console.log("reorganizeArgumentsListData :", this.props.reorganizeArgumentsListData)}

                {this.props.formulaireType === "new quote" && this.props.listquoteSimilar !== undefined && this.props.listquoteSimilar.length > 0 &&

                  <div className="d-flex flex-column w-100 mt-5 mb-4 ">

                    <div className="d-flex flex-row ongletVerticalTitre w-100 ">
                      <div className="">
                        {this.props.formulaireType === "new quote" ?
                          "Ce débat est-il déjà sur Spot ?"
                          :
                          "3. L'information que vous souhaitez créer est-elle déjà sur Spot ?"
                        }
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-center w-100 ">
                      <div className="d-flex flex-row col-lg-11 m-0 p-0 ">
                        <div className="d-flex flex-column w-100 ">
                          <ListGroup className="mt-4 w-100" variant="flush" >
                            {this.props.reorganizeArgumentsListData.map((element, index) => (
                              <ListGroup.Item
                                action
                                key={`similar_quote_${index}`}
                                className="m-0 p-1"
                                active={false}
                                onClick={() => this.props.changeSelectedArgumentID(element.q_originID)}
                                disabled={element.qlt_title !== ""}
                                variant={this.props.selectedArgumentID !== 0 && this.props.selectedArgumentID !== element.q_originID ? "dark" : ""}
                              >
                                <div className="d-flex flex-row align-items-center m-0 p-0 w-100 ">
                                  <div className="d-flex col-1 col-lg-1 m-0 p-0 justify-content-center ">{this.selectExistingQuote(element)}</div>
                                  <div
                                    className="col-10 col-lg-10 m-0 p-0 robotoFont16px"
                                    style={{ color: this.props.selectedArgumentID !== 0 && this.props.selectedArgumentID !== element.q_originID ? "grey" : "" }}
                                  >
                                    {element.titleWithIntro}
                                  </div>
                                  {/*<span className="m-0 p-0 ml-auto ">{element.searchIndex * 100}%</span>*/}
                                  <div
                                    className="progress m-0 p-0 ml-auto "
                                    style={{ width: '25px', height: '6px' }}
                                    title={element.searchIndex > 0.65 ? "Correspondance élevée" :
                                      (element.searchIndex < 0.35 ? "Correspondance faible" : "Correspondance modérée")}
                                  >
                                    <div
                                      className={element.searchIndex > 0.65 ? "progress-bar bg-success" :
                                        (element.searchIndex < 0.35 ? "progress-bar bg-danger" : "progress-bar bg-warning")}
                                      role="progressbar"
                                      style={{ width: `${element.searchIndex * 100}%` }}
                                      aria-valuenow="50"
                                      aria-valuemin="0"
                                      aria-valuemax="100">
                                    </div>
                                  </div>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>

                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            }


          </div>
        }








        {
          <div className="mb-4 p-0">

            <Form className="d-flex flex-row justify-content-center w-100 ">

              <Button
                className='col-lg-4 mr-3'
                variant={this.state.nuanceBlockShow ? 'light' : 'light'}
                onClick={() => this.setState({ nuanceBlockShow: !this.state.nuanceBlockShow, argumentBlockShow: this.state.nuanceBlockShow ? false : this.state.nuanceBlockShow })}
              >
                <Form.Check // prettier-ignore
                  type='radio'
                  label='Ajouter une nuance'
                  name="group1"
                  //onChange={() => this.setState({ nuanceBlockShow: !this.state.nuanceBlockShow, argumentBlockShow: this.state.nuanceBlockShow ? false : this.state.nuanceBlockShow })}
                  onChange={() => console.log("")}
                  checked={this.state.nuanceBlockShow}
                />
              </Button>

              <Button
                className='col-lg-4 ml-3'
                variant={this.state.argumentBlockShow ? 'light' : 'light'}
                onClick={() => this.setState({ argumentBlockShow: !this.state.argumentBlockShow, nuanceBlockShow: this.state.argumentBlockShow ? false : this.state.argumentBlockShow })}
              >
                <Form.Check // prettier-ignore
                  type='radio'
                  label='Ajouter un argument'
                  name="group1"
                  // onChange={() => this.setState({ nuanceBlockShow: !this.state.nuanceBlockShow, argumentBlockShow: this.state.nuanceBlockShow ? false : this.state.nuanceBlockShow })}
                  onChange={() => console.log("")}
                  checked={this.state.argumentBlockShow}
                />
              </Button>

            </Form>

          </div>

        }





        {!this.state.argumentBlockShow &&
          < div className="d-flex flex-column mb-4 p-0 ">

            <u className="mt-0 mb-3 robotoFont15px">Nuances déjà recensées pour ce Tweet :</u>

            {quoteModalities_currentRef_active.length === 0 &&
              <div className='robotoFont15px ml-2 pl-5'>Aucune nuance référencée</div>
            }

            {quoteModalities_currentRef_active.length > 0 &&
              <ListGroup variant="flush" className='col-lg-11 pl-5 '>
                {
                  quoteModalities_currentRef_active
                    .map((element, index) => (
                      <ListGroup.Item
                        key={`modalitiesAlreadyAttached_${index}`}
                      >
                        <div className={"d-flex flex-row "}>
                          <div className={'w-100 titre_' + element["mo_title"].replace("où", "ou").replace("pour qui", "pourQui") + ' pl-4 pt-1 pb-1 mt-0 mb-0 robotoFont14px rounded'}>{element["mo_title"] + " : " + element["qm_modalityContent"]}</div>

                          <Button
                            className='ml-auto mt-1 mb-0 pt-0 pb-1 pl-1 pr-1 '
                            variant='light'
                            onClick={() => this.props.removeModalityFromReference(element.quote_author_position_ID)}
                          >
                            <TrashIcon size={15} />
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))
                }
              </ListGroup>
            }

          </div>
        }





        {!this.state.nuanceBlockShow &&
          < div className="d-flex flex-column mt-0 mb-4 p-0 ">

            <u className="mt-0 mb-3 robotoFont15px">Arguments déjà recensées pour ce Tweet :</u>

            {arguments_currentRef_active.length === 0 &&
              <div className='robotoFont15px ml-2 pl-5'>Aucun argument référencé</div>
            }

            {arguments_currentRef_active.length > 0 &&
              <ListGroup variant="flush" className='col-lg-11 pl-4 pb-4'>
                {
                  arguments_currentRef_active
                    .map((element, index) => (
                      <ListGroup.Item
                        key={`argumentsAlreadyAttached_${index}`}
                      >
                        <div className={"d-flex flex-row "}>
                          <div className={'w-100 pl-3 pt-1 pb-1 mt-0 mb-0 robotoFont14px rounded'}>
                            {
                              element["quote"]["intro"].concat(" ... ").concat(element["quote"]["q_title"])
                            }
                          </div>

                          <Button
                            className='ml-auto mt-1 mb-0 pt-0 pb-1 pl-1 pr-1 '
                            variant='light'
                            onClick={() => {
                              this.props.removeCommentateurFromArgumentAPI(element)
                              this.setState({ argumentBlockShow: false })
                            }}
                          >
                            <TrashIcon size={15} />
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))
                }
              </ListGroup>
            }


          </div>
        }







        {
          this.state.nuanceBlockShow &&  // NUANCES
          <div className="d-flex flex-column w-100 ">

            {
              <div className="w-100 ">
                <div className="d-flex flex-row ongletVerticalTitre mt-0 mb-4 ">
                  <div className="">
                    Préciser si des nuances sont exprimées dans ce Tweet
                  </div>
                </div>

                {
                  <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

                    <div className="d-flex flex-column justify-content-center col-lg-11 m-0 pl-3 pr-3 pt-3 pb-5 rounded border">

                      {false &&
                        <div>
                          <div>{"q_title : " + quote.q_title}</div>
                          <div>{"q_quoi : " + quote.q_quoi}</div>
                          <div>{"q_quand : " + quote.q_quand}</div>
                          <div>{"q_ou : " + quote.q_ou}</div>
                          <div>{"q_pourQui : " + quote.q_pourQui}</div>
                          <div>{"q_combien : " + quote.q_combien}</div>
                        </div>
                      }


                      {
                        <div className='d-flex flex-column m-0 p-0 '>

                          <Form className="d-flex justify-content-center ">
                            <Form.Group className="d-flex flex-column col-lg-12">
                              <div className="d-flex flex-column mt-2 col-lg-12">
                                <Form.Label className='sousTitreQuoi m-0 p-1 robotoFont14px'>Quoi ?</Form.Label>
                                <div className='titre_quoi m-0 p-2 col-lg-12 robotoFont14px text-left rounded'>
                                  {introToDisplay_mainQuote.concat(this.props.mainQuote.q_quoi)}
                                </div>
                                <Form.Label className='d-flex flex-row sousTitreQuoi m-0 pt-2 pl-2'>
                                  <ReplyIcon size={15} className="rotate180deg" />
                                  <p className='m-0 pl-2 robotoFont12px'>Le débat est exprimé dans sa forme la plus générale. Les différentes nuances du débat sont à renseigner ci-dessous.</p>
                                </Form.Label>
                              </div>


                              <div className="d-flex flex-column m-0 p-0 ">


                                {quoteModalities_currentRef_active.length === 0 &&
                                  < div className="d-flex flex-column mt-5 pl-4 pt-1 pb-1  robotoFont14px">
                                    Aucune nuance n'a été renseignée pour ce Tweet
                                  </div>
                                }

                                {
                                  [
                                    {
                                      id_modalityType: 4, type: 'combien', titre: 'Combien ?', sousTitre: 'Exprimer les valeurs quantifiées dans ce Tweet', existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_combien_template["qm_originID"]) > -1
                                      , value: this.props.modalite_combien_template
                                    },
                                    {
                                      id_modalityType: 3, type: 'ou', titre: 'Où ?', sousTitre: "Exprimer si ce Tweet exprime un lieu particulier : ville, département etc.", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_ou_template["qm_originID"]) > -1
                                      , value: this.props.modalite_ou_template
                                    },
                                    {
                                      id_modalityType: 2, type: 'quand', titre: 'Quand ?', sousTitre: "Exprimer si ce Tweet exprime une temporalité spécifique", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_quand_template["qm_originID"]) > -1
                                      , value: this.props.modalite_quand_template
                                    },
                                    {
                                      id_modalityType: 5, type: 'pourQui', titre: 'Pour qui ?', sousTitre: "Exprimer si ce Tweet exprime une population spécifique", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_pourQui_template["qm_originID"]) > -1
                                      , value: this.props.modalite_pourQui_template
                                    },
                                    {
                                      id_modalityType: 6, type: 'comment', titre: 'Comment ?', sousTitre: "Exprimer si ce Tweet exprime une manière spécifique de s'appliquer", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_comment_template["qm_originID"]) > -1
                                      , value: this.props.modalite_comment_template
                                    }
                                  ]
                                    .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })
                                    .map((element, index) => ( // Formulaire + DropDown ajouter de nouvelles nuances
                                      <InputGroup className="d-flex flex-column justify-content-center mt-2 pl-4" key={`new_nuance_${element["type"]}`}>
                                        <Form.Label className={`titre_${element["type"]} mt-3 mb-0 text-center robotoFont14px rounded`}>{element["titre"]}</Form.Label>

                                        <Form.Label className={`d-flex flex-row sousTitre_${element["type"]} m-0 pt-1 pl-5`}>
                                          <ReplyIcon size={15} className="rotate180deg" />
                                          <p className='mb-0 pb-0 pl-2 mr-auto robotoFont12px'>{element["sousTitre"]}<i className='pl-2 pr-2'>(optionnel)</i></p>
                                        </Form.Label>

                                        <div className='d-flex flex-row col-lg-12 mt-2'>

                                          <InputGroup className="d-flex flex-row w-100 m-0 p-0" >
                                            <Form.Control
                                              style={{
                                                fontStyle: this.state.debateTitleExample || element["existingModalitySelected"] ? "italic" : ""
                                              }}
                                              className={`robotoFont14px pt-1 pb-1 text-left rounded`}
                                              placeholder=" ... ajouter une nouvelle nuance "
                                              value={element.value["qm_modalityContent"]}
                                              onChange={e =>
                                                this.props.handleChangeQuote(`q_${element["type"]}`, e.target.value.replace(" ... ", "").replace(" ...", ""), this.props.quote.q_originID)
                                              }
                                              readOnly={this.state.debateTitleExample}
                                              disabled={element["existingModalitySelected"]}
                                            />
                                            <Button
                                              bsPrefix="inner-btn"
                                              onClick={() => this.props.handleChangeQuote(`clear_modalite_${element["type"]}`, "")}
                                            >
                                              X
                                            </Button>
                                          </InputGroup>

                                          <OverlayTrigger
                                            key={'right'}
                                            placement={'right'}
                                            overlay={element.value["qm_modalityContent"].length < 5 ?
                                              <Tooltip className='m-0 p-0' style={{ backgroundColor: 'transparent' }}>
                                                <div>Saisir au moins 5 caractères</div>
                                              </Tooltip>
                                              :
                                              <Tooltip className='m-0 p-0' style={{ backgroundColor: 'transparent' }}>
                                                <div>Ajouter cette nuance</div>
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              variant="outline-dark"
                                              id="button-addon2"
                                              className={`pt-1 pb-1 robotoFont12px titre_${element["type"]} `}
                                              onClick={() => {
                                                this.setState({ nuanceBlockShow: false }, () =>

                                                  element.value["qm_modalityContent"].length < 5 ?
                                                    ""
                                                    :
                                                    this.props.addNewModalityAPI(element.value))
                                                // détail de la modalité (titre, type))
                                              }}
                                            // disabled={element.value.length < 5}
                                            >
                                              Ajouter
                                            </Button>
                                          </OverlayTrigger>

                                        </div>

                                        {quoteModalities_others  // Modalités existantes sur d'autres sources
                                          .filter((element2) => element2.id_modalityType === element.id_modalityType).length > 0 &&
                                          <div className='d-flex col-lg-12 m-0 p-0 justify-content-center'>
                                            <div className='col-lg-11 mt-4 p-2 pl-3 robotoFont13px border rounded'>
                                              <u style={{ color: 'grey' }}>Autres nuances déjà liées à ce débat :</u>

                                              <ListGroup variant="flush" className='w-100 pl-4 mt-2'>
                                                {
                                                  quoteModalities_others
                                                    .filter((element2) => element2.id_modalityType === element.id_modalityType)
                                                    .map((element2, index2) => (
                                                      <ListGroup.Item
                                                        key={`modalities_fromOtherTweets_or_inactive_${index2}`}
                                                      >
                                                        <div className={"d-flex flex-row "}>
                                                          <div className={'w-100 pl-4 pt-1 pb-1 mt-0 mb-0 robotoFont13px rounded'}>{element2["mo_title"] + " : " + element2["qm_modalityContent"]}</div>

                                                          <Button
                                                            className={`pt-1 pb-1 robotoFont12px titre_${element2["mo_title"].replace("où", "ou").replace("pour qui", "pourQui")} `}
                                                            variant='light'
                                                            onClick={() => {
                                                              this.props.addModalityToReference(element2.qm_originID)
                                                              this.setState({ nuanceBlockShow: false })
                                                            }} // modalityOriginID, commentateurID, quoteLinkTypeID, reOriginID
                                                          >
                                                            Ajouter
                                                          </Button>
                                                        </div>
                                                      </ListGroup.Item>
                                                    ))
                                                }
                                              </ListGroup>
                                            </div>
                                          </div>
                                        }

                                      </InputGroup>
                                    ))}


                              </div>

                            </Form.Group>
                          </Form>

                          {this.state.debateTitleExample &&
                            <div className="d-flex flex-column col-lg-11 mt-4 mb-2">
                              <i className={"d-flex align-items-center mb-1 robotoFont15px"}>
                                {"L'argument suivant sera à renseigner dans la page débat : \u00ab ... pour limiter la hausse des températures à 1,5°C. \u00bb"}
                              </i>
                            </div>
                          }

                        </div>
                      }


                    </div>
                  </div>
                }

              </div>
            }


          </div>
        }














        {
          this.state.argumentBlockShow &&  // ARGUMENTS
          <div className="d-flex flex-column w-100 ">

            {
              <div className="d-flex flex-column justify-content-center m-0 p-0 " style={{}}>



                { // Affichage des types d'information à sélectionner

                  <div className="d-flex flex-column justify-content-around col-lg-12 m-0 p-0">

                    {
                      <div className="d-flex flex-column ongletVerticalTitreBox mt-0 " >
                        <div className="mr-auto ongletVerticalTitreText">1. Sélectionner la nature de l'argument</div>
                      </div>
                    }

                    {quote.q_typeID === 0 &&
                      <div className="d-flex flex-wrap justify-content-center mt-4 col-lg-12 " >

                        <div className="d-flex flex-column col-lg-12 m-0 p-0 boxIdees">
                          <div className="d-flex mt-2 col-lg-12 text-left boldFont">Idées</div>
                          <div className="d-flex flex-row justify-content-around col-lg-12 ">
                            {this.props.natureInformationTable
                              .filter((element) => element.categorie === "avis personnel")
                              .map((element, index) => (
                                <div
                                  key={`natureInformationTable_choix_avisPerso_${index}`}
                                  className={"d-flex mt-1 m-0 p-0 "}
                                >
                                  <Button
                                    className={"d-flex flex-row align-items-center m-3 "}
                                    variant="light"
                                    style={{ borderWidth: "1px", borderColor: "#808b96" }}
                                    onClick={() => { this.props.handleChangesNature(element.id) }}
                                    onMouseEnter={() => this.setState({ buttonHovered: element.id })}
                                    onMouseLeave={() => this.setState({ buttonHovered: 0 })}
                                  >
                                    {element.id === quote.q_typeID && <CheckCircleIcon size={18} className="mr-3" />}
                                    <p
                                      className={"m-0 p-0 ".concat(element.id === quote.q_typeID ? "boldFont" : "")}
                                      style={{ color: quote.q_typeID !== 0 && element.id !== quote.q_typeID ? "grey" : "black" }}
                                    >
                                      {element.nature}
                                    </p>
                                  </Button>
                                </div>
                              ))}
                          </div>
                        </div>

                        <div className="d-flex flex-column col-lg-12 ml-0 mr-0 mt-2 p-0 boxConnaissance">
                          <div className="d-flex mt-2 col-lg-12 text-left boldFont">Connaissance</div>
                          <div className="d-flex flex-row justify-content-around col-lg-12 ">
                            {this.props.natureInformationTable
                              .filter((element) => element.categorie === "connaissance")
                              .map((element, index) => (
                                <div
                                  key={`natureInformationTable_choix_connaissance_${index}`}
                                  className={"d-flex m-0 mt-1 p-0 "}
                                  style={{ position: "relative" }}
                                >
                                  <Button
                                    className="d-flex flex-row align-items-center m-3"
                                    variant="light"
                                    style={{ borderWidth: "1px", borderColor: "#808b96" }}
                                    onClick={() => { this.props.handleChangesNature(element.id) }}
                                    onMouseEnter={() => this.setState({ buttonHovered: element.id })}
                                    onMouseLeave={() => this.setState({ buttonHovered: 0 })}
                                  // disabled={element.nature === "étude" || element.nature === "dataviz"}
                                  >
                                    {element.id === quote.q_typeID && <CheckCircleIcon size={18} className="mr-3 " />}
                                    <p
                                      className={"m-0 p-0 ".concat(element.id === quote.q_typeID ? "boldFont" : "")}
                                      style={{ color: (element.nature === "étude" || element.nature === "dataviz") || (quote.q_typeID !== 0 && element.id !== quote.q_typeID) ? "grey" : "black" }}
                                    >
                                      {element.nature}
                                    </p>
                                  </Button>
                                  {(element.nature === "étude" || element.nature === "dataviz") &&
                                    <p className="robotoFont9px " style={{ position: "absolute", bottom: -14, right: 0, color: "grey" }}>en construction</p>}
                                </div>
                              ))
                            }
                          </div>

                        </div>
                      </div>
                    }


                    {
                      <div className="d-flex justify-content-center mt-4 col-lg-12 " style={{ height: "230px" }} >
                        <div className="col-lg-10">
                          {(quote.q_typeID !== 0 || this.state.buttonHovered !== 0) &&
                            <ComponentChoixNature
                              originTable="selectedNature"
                              element={this.props.getArrayIndexByAttributeValue(this.props.natureInformationTable, 'id', quote.q_typeID !== 0 ? quote.q_typeID : this.state.buttonHovered)}
                              handleChangesNature={this.props.handleChangesNature}
                              q_typeID={quote.q_typeID}
                              buttonHovered={this.state.buttonHovered}
                              url_image={this.props.url_image}
                              showMoreInfoNature={true}
                              highlightBoolean={true}

                              showConsoleLog={this.props.showConsoleLog}

                              formulaireType={this.props.formulaireType}
                            />
                          }
                        </div>
                      </div>
                    }

                  </div>
                }

              </div>
            }


            {
              <div className="w-100 mt-4 ">
                <div className="d-flex flex-row ongletVerticalTitre mt-0 mb-4 ">
                  <div className="">
                    2. Saisir le titre de l'argument
                  </div>
                </div>


                {
                  quote.q_typeID === 0 &&
                  <div className='d-flex flex-row justify-content-center'>
                    <div className="col-lg-11 mt-2 mb-2 p-1 text-center robotoFont14px rounded" style={{ backgroundColor: ' #ec7063 ' }}>
                      Sélectionnez d'abord la nature de l'argument que vous souhaitez ajouter sur Spot, puis le titre.
                    </div>
                  </div>
                }


                {arguments_others.length > 0 &&
                  < div className="d-flex flex-column m-0 p-0 ">

                    <div className="mt-3 mb-3 pl-3 ">Arguments déjà recensées dans d'autres sources :</div>

                    <ListGroup variant="flush" className='col-lg-11 pl-4 pb-4'>
                      {
                        arguments_others
                          .filter((element) => element.quote.q_typeID === quote.q_typeID)
                          .map((element, index) => (
                            <ListGroup.Item
                              key={`argumentsAlreadyAttached_${index}`}
                            >
                              <div className={"d-flex flex-row "}>
                                <div className={'w-100 pl-3 pt-1 pb-1 mt-0 mb-0 robotoFont14px rounded'}>
                                  {
                                    element["quote"]["intro"].concat(" ... ").concat(element["quote"]["q_title"])
                                  }
                                </div>

                                <Button
                                  className='ml-auto mt-1 mb-0 pt-0 pb-1 pl-1 pr-1 robotoFont13px'
                                  variant='light'
                                  onClick={() => {
                                    this.props.addCommentateurToExistingArgumentAPI(element.ql_quoteLinkID)
                                    this.setState({ argumentBlockShow: false })
                                  }}
                                >
                                  Ajouter
                                </Button>
                              </div>
                            </ListGroup.Item>
                          ))
                      }
                    </ListGroup>


                  </div>
                }


                {quote.q_typeID !== 0 &&
                  <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

                    <div className="d-flex flex-column justify-content-center col-lg-11 m-0 pl-3 pr-3 pt-3 pb-4 rounded border">

                      {false &&
                        <div>
                          <div>{"q_title : " + quote.q_title}</div>
                          <div>{"q_quoi : " + quote.q_quoi}</div>
                          <div>{"q_quand : " + quote.q_quand}</div>
                          <div>{"q_ou : " + quote.q_ou}</div>
                          <div>{"q_pourQui : " + quote.q_pourQui}</div>
                          <div>{"q_combien : " + quote.q_combien}</div>
                        </div>
                      }

                      {
                        <div className="d-flex flex-column col-lg-12 mt-0 p-0">

                          <u className={"d-flex align-items-center mb-1 robotoFont15px"}>Renseigner le titre de l'argument</u>

                          <FloatingLabel
                            controlId="floatingTextarea"
                            // label="Comments"
                            className="mt-2 "
                          >
                            <Form.Control
                              bsPrefix="col-lg-12 m-0 p-3 robotoFont15px"
                              as="textarea"
                              // placeholder="Leave a comment here"
                              value={introToDisplay_currentQuote.concat(quote.q_quoi)}
                              // onChange={e => this.changeQuoteTitle(e.target.value)}
                              onChange={e =>
                                e.target.value.length < introToDisplay_currentQuote.length ? // Si l'utilisateur efface l'intro, on vide q_quoi
                                  this.props.handleChangeQuote('q_quoi', "")
                                  :
                                  (e.target.value.slice(0, introToDisplay_currentQuote.length) !== introToDisplay_currentQuote ?  // Si l'utilisateur essaye de changer l'intro, on ne la change pas
                                    ""
                                    :
                                    this.props.handleChangeQuote('q_quoi', e.target.value.replace(introToDisplay_currentQuote, ""))
                                  )
                              }
                              disabled={quote.q_typeID === 0}
                            />
                          </FloatingLabel>

                          <div className='d-flex flex-row m-0 pt-1 pl-2'>
                            <ReplyIcon size={14} className="rotate180deg " />
                            <p className='robotoFont12px mt-0 mb-0 mr-auto pl-2'>Exprimer l'argument dans sa forme la plus générale. <br /> Les différentes nuances seront à renseigner à la prochaine étape.</p>

                            <p className='mr-auto '></p>
                            <small
                              id="infoDescriptionDifficiledHelpBlock"
                              className={quote.q_title.length === this.props.longueurMaxTitre ? "col-lg-3 text-right " : "col-lg-3 text-right text-muted robotoFont13px mt-0 pr-2"}
                              style={{
                                'fontWeight': quote.q_title.length === this.props.longueurMaxTitre ? '' : '',
                                backgroundColor: quote.q_title.length === this.props.longueurMaxTitre ? ' #ec7063 ' : '',
                              }}
                            >
                              {quote.q_title.length} car. / {this.props.longueurMaxTitre} max.
                            </small>
                          </div>


                          {// Comment rédiger un titre sur Spot
                            <div className="w-100 m-0 mt-4 mb-3 p-0 pl-5 robotoFont12px" style={{ color: 'grey' }}>
                              <div className="d-flex flex-row col-lg-12 align-items-center ">

                                <Button
                                  onClick={() => this.setState({ showAdvices: !this.state.showAdvices })}
                                  variant="light"
                                  className="d-flex flex-row align-items-center mt-0 mb-0 ml-1 pt-0 pb-0 pl-3 pr-3"
                                >
                                  <p className="mr-0 ml-1 mb-0 mt-0 p-2 robotoFont13px">Comment rédiger un titre sur Spot ?</p>
                                  {this.state.showAdvices ?
                                    <ChevronUpIcon
                                      size={16}
                                      className="m-0 p-0 "
                                    />
                                    :
                                    <ChevronDownIcon
                                      size={16}
                                      onClick={() => this.setState({ showAdvices: !this.state.showAdvices })}
                                      className="m-0 p-0 "
                                    />}
                                </Button>

                              </div>

                              {this.state.showAdvices &&
                                <ul className="col-lg-12 mt-3 pl-5 ">
                                  {
                                    guideLines.map((element, index) => (
                                      <li key={`guideLines_${index}`} className="pt-2 pl-2">
                                        <div>{element.title}</div>
                                        {element.exampleNok.length > 0 &&
                                          <ul className="pt-1">
                                            {element.exampleNok.map((exampleNok, index2) => (
                                              <li key={`exampleNok_${index2}`} className="pt-1" style={{ color: '#e55c5c' }} >
                                                <XCircleIcon size={16} />
                                                <span className='pl-2' style={{ color: 'grey' }}>{"\xa0".concat(exampleNok)}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        }
                                        {element.exampleOk.length > 0 &&
                                          <ul className="">
                                            {element.exampleOk.map((exampleOk, index3) => (
                                              <li key={`exampleOk_${index3}`} className="pt-1" style={{ color: '#B9BD4A' }} >
                                                <CheckCircleIcon size={16} />
                                                <span className='pl-2' style={{ color: 'grey' }}>{"\xa0".concat(exampleOk)}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        }
                                      </li>
                                    ))
                                  }
                                </ul>
                              }
                            </div>
                          }

                        </div>
                      }

                      <div className='d-flex flex-column col-lg-12 m-0 p-0 '>
                        <div className='d-flex justify-content-center '>
                          <Button
                            className='col-lg-4 robotoFont14px'
                            variant='outline-danger'
                            disabled={quote.q_quoi.length < 6}
                            onClick={() => {
                              this.props.addArgumentFromSourceAPI(quote)
                              this.setState({ argumentBlockShow: false })
                            }}
                          >
                            Ajouter cet argument
                          </Button>
                        </div>
                        {quote.q_quoi.length < 6 &&
                          <div className='d-flex justify-content-center robotoFont12px'>
                            <i style={{ color: '#ec7063' }}>Le titre doit contenir au moins 6 caractères</i>
                          </div>
                        }
                      </div>

                    </div>


                  </div>
                }
              </div>
            }

            {false &&
              <div className="w-100 mt-4 ">
                <div className="d-flex flex-row ongletVerticalTitre mt-0 mb-4 ">
                  <div className="">
                    3. Préciser les nuances de l'argument
                  </div>
                </div>

                {
                  <div className='d-flex flex-column m-0 p-0 '>

                    <Form className="d-flex justify-content-center ">
                      <Form.Group className="d-flex flex-column col-lg-12">
                        <div className="d-flex flex-column mt-2 col-lg-12">
                          <Form.Label className='sousTitreQuoi m-0 p-1 robotoFont14px'>Quoi ?</Form.Label>
                          <div className='titre_quoi m-0 p-2 col-lg-12 robotoFont14px text-left rounded'>
                            {introToDisplay_currentQuote.concat(quote.q_quoi)}
                          </div>
                          <Form.Label className='d-flex flex-row sousTitreQuoi m-0 pt-2 pl-2'>
                            <ReplyIcon size={15} className="rotate180deg" />
                            <p className='m-0 pl-2 robotoFont12px'>Le débat est exprimé dans sa forme la plus générale. Les différentes nuances du débat sont à renseigner ci-dessous.</p>
                          </Form.Label>
                        </div>




                        <div className="d-flex flex-column m-0 p-0 ">


                          {quoteModalities_currentRef_active.length === 0 &&
                            < div className="d-flex flex-column mt-5 pl-4 pt-1 pb-1  robotoFont14px">
                              Aucune nuance n'a été renseignée pour ce Tweet
                            </div>
                          }


                          {
                            [
                              {
                                id_modalityType: 4, type: 'combien', titre: 'Combien ?', sousTitre: 'Exprimer les valeurs quantifiées dans ce Tweet', existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_combien_template["qm_originID"]) > -1
                                , value: this.props.modalite_combien_template
                              },
                              {
                                id_modalityType: 3, type: 'ou', titre: 'Où ?', sousTitre: "Exprimer si ce Tweet exprime un lieu particulier : ville, département etc.", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_ou_template["qm_originID"]) > -1
                                , value: this.props.modalite_ou_template
                              },
                              {
                                id_modalityType: 2, type: 'quand', titre: 'Quand ?', sousTitre: "Exprimer si ce Tweet exprime une temporalité spécifique", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_quand_template["qm_originID"]) > -1
                                , value: this.props.modalite_quand_template
                              },
                              {
                                id_modalityType: 5, type: 'pourQui', titre: 'Pour qui ?', sousTitre: "Exprimer si ce Tweet exprime une population spécifique", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_pourQui_template["qm_originID"]) > -1
                                , value: this.props.modalite_pourQui_template
                              },
                              {
                                id_modalityType: 6, type: 'comment', titre: 'Comment ?', sousTitre: "Exprimer si ce Tweet exprime une manière spécifique de s'appliquer", existingModalitySelected: this.props.quoteModalities.map(item => item.qm_originID).indexOf(this.props.modalite_comment_template["qm_originID"]) > -1
                                , value: this.props.modalite_comment_template
                              }
                            ]
                              .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })
                              .map((element, index) => ( // Formulaire + DropDown ajouter de nouvelles nuances
                                <InputGroup className="d-flex flex-column justify-content-center mt-2 pl-4" key={`new_nuance_${element["type"]}`}>
                                  <Form.Label className={`titre_${element["type"]} mt-3 mb-0 text-center robotoFont14px rounded`}>{element["titre"]}</Form.Label>

                                  <Form.Label className={`d-flex flex-row sousTitre_${element["type"]} m-0 pt-1 pl-5`}>
                                    <ReplyIcon size={15} className="rotate180deg" />
                                    <p className='mb-0 pb-0 pl-2 mr-auto robotoFont12px'>{element["sousTitre"]}<i className='pl-2 pr-2'>(optionnel)</i></p>
                                  </Form.Label>

                                  <div className='d-flex flex-row col-lg-12 mt-2'>

                                    {this.props.showConsoleLog && console.log("modalite_quand_template :", this.props.modalite_quand_template["qm_modalityContent"])}
                                    {this.props.showConsoleLog && console.log("modalite_quand_template length:", this.props.modalite_quand_template["qm_modalityContent"].length)}

                                    <InputGroup className="d-flex flex-row w-100 m-0 p-0" >
                                      <Form.Control
                                        style={{
                                          fontStyle: this.state.debateTitleExample || element["existingModalitySelected"] ? "italic" : ""
                                        }}
                                        className={`robotoFont14px pt-1 pb-1 text-left rounded`}
                                        placeholder=" ... ajouter une nouvelle nuance "
                                        value={element.value["qm_modalityContent"]}
                                        onChange={e =>
                                          this.props.handleChangeQuote(`q_${element["type"]}`, e.target.value.replace(" ... ", "").replace(" ...", ""), this.props.quote.q_originID)
                                        }
                                        readOnly={this.state.debateTitleExample}
                                        disabled={element["existingModalitySelected"]}
                                      />
                                      <Button
                                        bsPrefix="inner-btn"
                                        onClick={() => this.props.handleChangeQuote(`clear_modalite_${element["type"]}`, "")}
                                      >
                                        X
                                      </Button>
                                    </InputGroup>

                                    <OverlayTrigger
                                      key={'right'}
                                      placement={'right'}
                                      overlay={element.value["qm_modalityContent"].length < 5 ?
                                        <Tooltip className='m-0 p-0' style={{ backgroundColor: 'transparent' }}>
                                          <div>Saisir au moins 5 caractères</div>
                                        </Tooltip>
                                        :
                                        <Tooltip className='m-0 p-0' style={{ backgroundColor: 'transparent' }}>
                                          <div>Ajouter cette nuance</div>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="outline-dark"
                                        id="button-addon2"
                                        className={`pt-1 pb-1 robotoFont12px titre_${element["type"]} `}
                                        onClick={() => element.value["qm_modalityContent"].length < 5 ?
                                          ""
                                          :
                                          this.props.addNewModalityAPI(
                                            element.value,  // détail de la modalité (titre, type)
                                          )}
                                      // disabled={eval(`this.props.modalite_${element["type"]}_template["qm_modalityContent"]`).length < 5}
                                      >
                                        Ajouter
                                      </Button>
                                    </OverlayTrigger>

                                  </div>

                                  {quoteModalities_others  // Modalités existantes sur d'autres sources
                                    .filter((element2) => element2.id_modalityType === element.id_modalityType).length > 0 &&
                                    <div className='d-flex col-lg-12 m-0 p-0 justify-content-center'>
                                      <div className='col-lg-11 mt-4 p-2 pl-3 robotoFont13px border rounded'>
                                        <u style={{ color: 'grey' }}>Autres nuances déjà liées à ce débat :</u>

                                        <ListGroup variant="flush" className='w-100 pl-4 mt-2'>
                                          {
                                            quoteModalities_others
                                              .filter((element2) => element2.id_modalityType === element.id_modalityType)
                                              .map((element2, index2) => (
                                                <ListGroup.Item
                                                  key={`modalities_fromOtherTweets_or_inactive_${index2}`}
                                                >
                                                  <div className={"d-flex flex-row "}>
                                                    <div className={'w-100 pl-4 pt-1 pb-1 mt-0 mb-0 robotoFont13px rounded'}>{element2["mo_title"] + " : " + element2["qm_modalityContent"]}</div>

                                                    <Button
                                                      className={`pt-1 pb-1 robotoFont12px titre_${element2["mo_title"].replace("où", "ou").replace("pour qui", "pourQui")} `}
                                                      variant='light'
                                                      onClick={() => this.props.addModalityToReference(
                                                        element2.qm_originID
                                                      )} // modalityOriginID, commentateurID, quoteLinkTypeID, reOriginID
                                                    >
                                                      Ajouter
                                                    </Button>
                                                  </div>
                                                </ListGroup.Item>
                                              ))
                                          }
                                        </ListGroup>
                                      </div>
                                    </div>
                                  }

                                </InputGroup>
                              ))}


                        </div>

                      </Form.Group>
                    </Form>

                    {this.state.debateTitleExample &&
                      <div className="d-flex flex-column col-lg-11 mt-4 mb-2">
                        <i className={"d-flex align-items-center mb-1 robotoFont15px"}>
                          {"L'argument suivant sera à renseigner dans la page débat : \u00ab ... pour limiter la hausse des températures à 1,5°C. \u00bb"}
                        </i>
                      </div>
                    }

                  </div>
                }

              </div>
            }


            {this.props.showConsoleLog && console.log("listquoteSimilar :", this.props.listquoteSimilar)}
            {this.props.showConsoleLog && console.log("reorganizeArgumentsListData :", this.props.reorganizeArgumentsListData)}

            {
              this.props.listquoteSimilar !== undefined && this.props.listquoteSimilar.length > 0 &&  // Suggestion de débats existants

              <div className="d-flex flex-column w-100 mt-5 mb-4 ">

                <div className="d-flex flex-row ongletVerticalTitre w-100 ">
                  <div className="">
                    {this.props.formulaireType === "new quote" ?
                      "Ce débat est-il déjà sur Spot ?"
                      :
                      "3. L'information que vous souhaitez créer est-elle déjà sur Spot ?"
                    }
                  </div>
                </div>

                <div className="d-flex flex-row justify-content-center w-100 ">
                  <div className="d-flex flex-row col-lg-11 m-0 p-0 ">
                    <div className="d-flex flex-column w-100 ">
                      <ListGroup className="mt-4 w-100" variant="flush" >
                        {this.props.reorganizeArgumentsListData.map((element, index) => (
                          <ListGroup.Item
                            action
                            key={`similar_quote_${index}`}
                            className="m-0 p-1"
                            active={false}
                            onClick={() => this.props.changeSelectedArgumentID(element.q_originID)}
                            disabled={element.qlt_title !== ""}
                            variant={this.props.selectedArgumentID !== 0 && this.props.selectedArgumentID !== element.q_originID ? "dark" : ""}
                          >
                            <div className="d-flex flex-row align-items-center m-0 p-0 w-100 ">
                              <div className="d-flex col-1 col-lg-1 m-0 p-0 justify-content-center ">{this.selectExistingQuote(element)}</div>
                              <div
                                className="col-10 col-lg-10 m-0 p-0 robotoFont16px"
                                style={{ color: this.props.selectedArgumentID !== 0 && this.props.selectedArgumentID !== element.q_originID ? "grey" : "" }}
                              >
                                {element.titleWithIntro}
                              </div>

                              <div
                                className="progress m-0 p-0 ml-auto "
                                style={{ width: '25px', height: '6px' }}
                                title={element.searchIndex > 0.65 ? "Correspondance élevée" :
                                  (element.searchIndex < 0.35 ? "Correspondance faible" : "Correspondance modérée")}
                              >
                                <div
                                  className={element.searchIndex > 0.65 ? "progress-bar bg-success" :
                                    (element.searchIndex < 0.35 ? "progress-bar bg-danger" : "progress-bar bg-warning")}
                                  role="progressbar"
                                  style={{ width: `${element.searchIndex * 100}%` }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100">
                                </div>
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>

                    </div>
                  </div>
                </div>
              </div>
            }

          </div >
        }



      </div >
    )
  }

}
export default ComponentFormulaireNatureEtTitre