import React, { Component } from 'react';

import ComponentDebatsModalites from './ComponentDebatsModalites'

import { PencilIcon } from '@primer/octicons-react'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';



class ModalWindowModalities extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showConsoleLog: true,

    // show: false,
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  render() {

    this.props.showConsoleLog && console.log('ModalWindowCommentateur -> this.props.commentateurData.person : ', this.props.commentateurData.person)
    this.props.showConsoleLog && console.log('ModalWindowCommentateur -> quoteModalities : ', this.props.quoteModalities)


    return (

      <div>

        <div className="">
          <div className="d-flex align-items-center justify-content-center ">
            <Button
              variant="light"
              onClick={() => {
                this.props.keepPopover !== undefined && this.props.keepPopover(true)
                // this.setState({ show: true })
                this.props.changeModalShowState(true)
              }}
              className='d-flex flex-row align-items-center justify-content-center m-0 p-0 w-100 octiconStyle transparentButton rounded'
              title="Infos sur la personne"
            >
              <span
                className="d-flex align-items-center modifierSource"
                style={{}}
              >
                {this.props.quoteModalitiesFromCommentateur.length > 0 ? "Explorer les conditions" : "Ajouter des conditions"}
              </span>
            </Button>
          </div>

          <Modal
            show={this.props.show}
            onHide={() => {
              this.props.keepPopover !== undefined && this.props.keepPopover(false)
              // this.setState({ show: false })
              this.props.changeModalShowState(false)
            }}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby=""
            className=""
          >

            <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
              <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">

                <h6 className=" ml-auto mr-auto" style={{ fontSize: '25px', fontWeight: 'bold' }}>
                  Explorer les conditions
                </h6>

              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <div className="col-lg-12 m-0 p-0 ">
                <div className="col-lg-12 ongletVerticalTitre mt-4 text-center">
                  Les conditions
                </div>

                <ComponentDebatsModalites
                  quote={this.props.quote}
                  quoteTitle={this.props.quoteTitle}
                  quoteModalitiesSelected={this.props.quoteModalitiesSelected}

                  quoteModalities={this.props.quoteModalities}

                  handleChangeModality={this.props.handleChangeModality}

                // echelleDebatSelectionneValue={this.state.echelleDebatSelectionneValue}
                // echellePlaceSelectionneeValue={this.state.echellePlaceSelectionneeValue}

                // echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                // evenementTypeList={this.props.evenementTypeList}
                // dateTypeIntroAll={this.props.dateTypeIntroAll}

                // citiesList={this.state.citiesList}
                // regionsList={this.props.regionsList}
                // departmentsList={this.props.departmentsList}

                // getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                // selectDebatEchelle1={this.selectDebatEchelle1}
                // selectDebatEchelle2={this.selectDebatEchelle2}
                // transformDepartementArrayToSelectObject={this.transformDepartementArrayToSelectObject}
                // transformVilleArrayToSelectObject={this.transformVilleArrayToSelectObject}
                // handleInputChange={this.handleInputChange}

                />

                <div className="d-flex flex-row justify-content-center col-lg-12 mt-5">
                  <Button
                    className="buttonStepper col-lg-5 p-2 "
                    style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                    onClick={() => this.props.changeModalities()}
                    disabled={false}
                  >
                    {"Modifier les conditions"}
                  </Button>
                </div>

              </div>

            </Modal.Body>

          </Modal>

        </div>



      </div>

    )
  }
}


export default ModalWindowModalities