import React, { Component } from 'react';

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import ComponentTable from './ComponentTable'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/FormControl';


import ModalWindowHelp from './ModalWindow_Help';
import Card from 'react-bootstrap/Card';

import '../full.css';
import "react-datepicker/dist/react-datepicker.css";


import $ from 'jquery';

const keyword_extractor = require("keyword-extractor");

registerLocale('fr', fr)


class ComponentFormulaireVote extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    show: false,
    showConsoleLog: true,

    filtreLegislationValue: "",
    filtreTitreScrutinValue: "",
    filtreDateScrutinValue: "",
    filtreResultatVote: "",

  }


  componentDidMount() {

  }

  componentDidUpdate = (prevProps) => {

  }

  initData = () => {

    if (true) {

    } else {

    }
  }


  closeFormulaire = () => {
    this.setState({
      filtreLegislationValue: "",
      filtreTitreScrutinValue: "",
      filtreDateScrutinValue: "",
      filtreResultatVote: "",
    })
    this.setState({ show: false })
  }


  changeFiltersValue = (attributeName, value) => {
    this.props.showConsoleLog && console.log("changeFiltersValue attributeName :", attributeName)
    this.props.showConsoleLog && console.log("changeFiltersValue value :", value)

    if (attributeName === "scr_legislature") {
      this.setState({ filtreLegislationValue: value })
    }
    if (attributeName === "scr_titre") {
      this.setState({ filtreTitreScrutinValue: value })
    }
    if (attributeName === "scr_dateScrutin") {
      this.setState({ filtreDateScrutinValue: value })
    }
    if (attributeName === "scr_sortCode") {
      this.setState({ filtreResultatVote: value })
    }
  }


  render() {


    { /*
        scr_uid: "VTANR5L15V1", scr_source: "AMO40_deputes_actifs_mandats_actifs_organes_divises_json_2022-09-22", scr_organeRef: "PO717460", … }
        scr_codeTypeVote: "SPS"
        scr_dateScrutin: "2017-07-04"
        scr_demandeur: "Conference des Presidents"
        scr_legislature: "15"
        scr_nbAbstensions: 129
        scr_nbContre: 67
        scr_nbNonVotants: 9
        scr_nbNonVotantsVolontaires: 0
        scr_nbPour: 370
        scr_nbSuffragesExprimes: 437
        scr_nbSuffragesRequis: 219
        scr_nbVotants: 566
        scr_organeRef: "PO717460"
        scr_quantiemeJourSeance: 1
        scr_seanceRef: "RUANR5L15S2017IDS20603"
        scr_sessionRef: "SCR5A2017E1"
        scr_sortCode: "adopté"
        scr_source: "AMO40_deputes_actifs_mandats_actifs_organes_divises_json_2022-09-22"
        scr_titre: "la declaration de politique generale du Gouvernement de M. Edouard Philippe (application de l'article 49, alinea premier, de la Constitution)."
        scr_uid: "VTANR5L15V1"
      */}


      
    this.props.showConsoleLog && console.log("this.props.currentItemsSelected :", this.props.currentItemsSelected)

    const checkBoxValuesToCompareWith = ((currentItemsSelected) => {
      let returnArray = []
      currentItemsSelected.forEach((element) => returnArray.push(element["scr_uid"]))
      return returnArray
    })(this.props.currentItemsSelected)

    this.props.showConsoleLog && console.log("checkBoxValuesToCompareWith :", checkBoxValuesToCompareWith)


    const headersAllScrutinsList = [
      { columnName: "#", attributeInData: "defaultIndex", filterType: "", filterValue: "", classNameAdd: { default: "text-center " }, formType: "" },
      { columnName: "Lég", attributeInData: "scr_legislature", filterType: "select", filterValue: this.state.filtreLegislationValue, classNameAdd: { default: "text-center " }, formType: "" },
      { columnName: "Intitulé du scrutin", attributeInData: "scr_titre", filterType: "input", filterValue: this.state.filtreTitreScrutinValue, classNameAdd: { default: "text-center " }, formType: "" },
      { columnName: "Date du scrutin", attributeInData: "scr_dateScrutin", filterType: "select", filterValue: this.state.filtreDateScrutinValue, classNameAdd: { default: "text-center " }, formType: "" },
      { columnName: "Nombre de votes", attributeInData: "scr_nbSuffragesExprimes", filterType: "", filterValue: "", classNameAdd: { default: "text-center " }, formType: "" },
      { columnName: "Résultat du vote", attributeInData: "scr_sortCode", filterType: "select", filterValue: this.state.filtreResultatVote, classNameAdd: { default: "text-center ", "adopté": "Pour ", "rejeté": "Contre " }, formType: "" },
      { columnName: " ", attributeInData: "", filterType: "", filterValue: "", classNameAdd: { default: "text-center " }, formType: "checkbox", formAttributeToCheck: "scr_uid", formValuesToCompareWithList: checkBoxValuesToCompareWith },
    ]




    return (
      <div
        className="m-0 p-0 "
        id={this.props.uniqueKey}
      >
        {
          <div className="d-flex align-items-center justify-content-start w-100 m-0 p-0">

            {true &&
              <Button
                onClick={() => {
                  this.setState({ show: true })
                  this.initData()
                }}
                className="d-flex justify-content-center align-items-center w-100 m-0 p-1 jeContribueBox"
              // style={{ backgroundColor: "#E55C5C", borderColor: "#E55C5C" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
              >
                <div className="ml-3 mr-3 mt-1 mb-1 ">
                  {"Ajouter un vote lié à ce débat".toUpperCase()}
                </div>
              </Button>
            }

          </div>
        }


        <Modal
          show={this.state.show}
          onHide={() => {
            // this.initData()
            // this.setState({ show: false })
            this.closeFormulaire()
          }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center w-100 ">

              <div
                className="col-12 col-lg-9 m-0 p-0 titreFormulaire "
                style={{ fontSize: window.innerWidth > 576 ? '25px' : '19px' }}
              >
                Ajouter un vote lié à ce débat
              </div>

            </Modal.Title>
          </Modal.Header>



          <Modal.Body>

            <div className="">

              <div className="d-flex justify-content-center mt-5 ml-0 mr-0 p-0 col-12 ">
                <div className="d-flex col-lg-12 col-12 m-0 p-0 ">

                  <ComponentTable
                    headersList={headersAllScrutinsList}
                    data={this.props.data}

                    url_image={this.props.url_image}

                    changeFiltersValue={this.changeFiltersValue}
                  />

                </div>
              </div>


            </div>

          </Modal.Body >

        </Modal >

      </div >

    )
  }

}
export default ComponentFormulaireVote