
import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Link } from 'react-scroll'

import { PeopleIcon, UnlockIcon, LockIcon } from '@primer/octicons-react'

// import logo from '../logo.svg';
import '../full.css';

import ModalWindow from './ModalWindow';
import ModalWindowHelp from './ModalWindow_Help';
import ModalWindowHistorique from './ModalWindowHistorique';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'


class ComponentSideMenu extends Component {

	state = {
		debats: [],

		hoveredCompletude: false,
	}


	setHover = () => {
		this.setState({ hoveredCompletude: !this.state.hoveredCompletude })
	}

	getProgressBarColor(completude) {
		if (completude > 70) {
			return 'success'
		} else if (completude > 50) {
			return 'warning'
		} else {
			return 'danger'
		}
	}


	render() {
		const quote = this.props.quote;
		const titreDesParties = this.props.titreDesParties;
		const droitsDeConnexionList = this.props.droitsDeConnexionList;
		const niveauDeLectureList = this.props.niveauDeLectureList;
		const url_image = this.props.url_image

		this.props.showConsoleLog && console.log('ComponentSideMenu -> quote : ', quote)

		const popoverCompletudeSideMenu = (
			<Popover id="popover-basic" className="tooltipStyle" style={{ position: 'relative', 'zIndex': "120" }}>
				<Popover.Header as="h3" className="tooltipStyle text-center">
					<div className="d-flex flex-row justify-content-center">
						<p className="mb-0 pb-0">Complétude</p>
						<div className="d-flex ml-3 mb-0 pb-0 ">
							<ModalWindowHelp
								pageName='Home'
								helpType='Complétude'

								url_image={url_image}
							/>
						</div>
					</div>
				</Popover.Header>
				<Popover.Body >
					<p className="tooltipText mt-1 mb-0"><strong>Partie "Pour comprendre" : </strong> {quote.q_completude + 7}% </p>
					<p className="tooltipText mt-1 mb-0"><strong>Partie "Qui pense quoi ?" : </strong> {Math.max(0, quote.q_completude - 5)}% </p>
					<p className="tooltipText mt-1 mb-0"><strong>Partie "Les arguments" : </strong> {Math.max(0, quote.q_completude - 2)}% </p>
				</Popover.Body>

			</Popover>
		)

		return (

			<Scrollbars
				style={{ width: '330px', height: '100%' }}
				onMouseLeave={() => this.props.onClick()}
			>
				<div style={{ position: 'absolute', top: "0px", left: "18px" }}>
					<Button
						variant={this.props.lockSideMenu ? "dark" : "light"}
						className="buttonHideBordersOnClick pr-2 pl-2 pt-1 pb-1"
						onClick={() => this.props.lockSideMenuFunction()}
						title="Verrouiller le menu latéral"
					>
						{this.props.lockSideMenu &&
							<LockIcon size={18} />
						}
						{!this.props.lockSideMenu &&
							<UnlockIcon size={18} />
						}
					</Button>
				</div>

				<div className={'d-flex flex-column pb-4'} style={{
					// position: 'relative',
					width: '330px',
					//'overflow-y': 'scroll',
					// 'zIndex': '90',
				}}>



					{/* Photo Spot */}

					{true &&
						<div className="d-flex flex-row">
							<div className="d-flex justify-content-center mr-auto">
								<div className="col-lg-8 ">
									<img className="img-fluid w-100 rounded  " src={url_image + this.props.getDebatePictureName(quote.idquote)} alt="" style={{ filter: 'grayscale(1)' }} />
								</div>
							</div>
							{false &&
								<button type="button" className="d-flex ml-auto whiteButton showBorder mr-4 close " aria-label="Close" onClick={this.props.onClick} >
									<span aria-hidden="true">&times;</span>
								</button>
							}
						</div>
					}


					{/* QQues informations sur le débat */}
					<div className="d-flex flex-row">
						<div className="d-flex flex-column justify-content-center ml-4 mt-2 mr-auto" >

							<small className="textMenuIntro mt-0">
								Publié le : {quote.q_datePublication}
							</small>
							<small className="textMenuIntro mt-0">
								Mis à jour le : {quote.q_creationDate}
							</small>
							<small className="d-flex flex-row textMenuIntro mt-0 ">
								<div className=" ">Type d'information : {quote.qt_title} {'\xa0\xa0'}</div>
								<div className=" ">
									<ModalWindowHelp
										pageName='SideMenu'
										helpType='NatureInformation'
										cnature={quote.typeQuote}

										url_image={url_image}

										natureInformationTable={this.props.natureInformationTable}
									/>
								</div>
							</small>
						</div>
						{false &&
							<button type="button" className="d-flex ml-auto whiteButton showBorder mr-4 close " aria-label="Close" onClick={this.props.onClick} >
								<span aria-hidden="true">&times;</span>
							</button>
						}
					</div>



					{/* Liste des thèmes   */}
					<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
						<div className="col-lg-12 ">
							<div className="d-flex flex-row ">
								<strong className="d-flex align-items-center mr-auto m-0 p-0 " > Thématiques {'\xa0\xa0'}</strong>
								<div className="d-flex align-items-center m-0 p-0 ">
									<ModalWindowHelp
										pageName='SideMenu'
										helpType='Thématiques'
										listThemes={this.props.listFullMainThemes}

										url_image={url_image}
									/>
								</div>
							</div>
							{this.props.listthemes !== undefined &&
								<div className="d-flex mt-2">
									{this.props.showConsoleLog && console.log('ComponentSideMenu -> this.props.listthemes : ', this.props.listthemes)}
									{this.props.listthemes
										.filter(({ t_photo }) => t_photo !== null)
										.map(({ t_title, t_photo }, index) => (
											<div
												key={`sideMenuThemePhoto_${index}`}
												className="d-flex flex-column col-lg-3 p-0 ml-2 "
											>
												<img className="listeThemesSideMenu " src={url_image + t_photo} alt={t_photo}></img>
												<p className="d-flex justify-content-center debatPhotoTexteGrisFonce text-center mb-0 ">{t_title}</p>
											</div>
										))
									}
								</div>
							}

							<div className="d-flex flex-row mt-2">
								<small className="pt-2 mr-auto "> Sous-thématiques {'\xa0\xa0'}</small>
							</div>
							{this.props.listthemes !== undefined &&
								<div className="d-flex flex-wrap col-lg-12 mt-2">
									{this.props.showConsoleLog && console.log('ComponentSideMenu -> this.props.listthemes : ', this.props.listthemes)}
									{this.props.sousThemesList
										.map(({ t_title }, index) => (
											<span
												key={`sideMenuSousTheme_${index}`}
												className="badge ml-1 mt-1">{t_title}</span>
										))
									}
								</div>
							}

						</div>
					</div>


					{/* Sommaire   */}
					{false &&
						<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-3 ">
							<div className="col-lg-12 ">

								<strong className="pt-2 "> Sommaire </strong>
								<div className="d-flex align-items-center btn-group-vertical mt-3 ml-4" role="group" aria-label="Button group with nested dropdown">

									<Link activeClass="active" className="col-lg-12 p-0" to="partiePourComprendre" spy={true} smooth={true} duration={500} >
										<Button variant="light" className="btn btn-primary boutonSommaire text-muted text-left col-lg-12">
											{titreDesParties[0]}
										</Button>
									</Link>
									<Link activeClass="active" className="col-lg-12 p-0" to="partieQuiPenseQuoi" spy={true} smooth={true} duration={500} >
										<Button variant="light" className="btn btn-primary boutonSommaire text-muted text-left col-lg-12" >{titreDesParties[1]}</Button>
									</Link>
									{quote.qt_title !== "étude" &&
										<Link activeClass="active" className="col-lg-12 p-0" to="partieLesArguments" spy={true} smooth={true} duration={500} >
											<Button variant="light" className="btn btn-primary boutonSommaire text-muted text-left col-lg-12" >{titreDesParties[2]}</Button>
										</Link>
									}
									<Link activeClass="active" className="col-lg-12 p-0" to="partieDebatsInduits" spy={true} smooth={true} duration={500} >
										<Button variant="light" className="btn btn-primary boutonSommaire text-muted text-left col-lg-12" >{titreDesParties[3]}</Button>
									</Link>
								</div>
							</div>
						</div>
					}


					{/* Complétude  */}
					{false &&
						<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
							<div className="col-lg-12 ">
								{/* <img className="img-fluid w-100 rounded" src={url_image+"Rectangle 502@3x.png"} alt="" />   */}
								<div className="d-flex flex-row">
									<strong className="d-flex align-items-center mr-auto "> Complétude {'\xa0\xa0'}</strong>
									<div className="d-flex align-items-center ">
										<ModalWindowHelp
											pageName='SideMenu'
											helpType='Complétude'

											url_image={url_image}
										/>
									</div>
								</div>
								<div className="progress mt-2 mb-1 " style={{ height: '14px' }}>

									<OverlayTrigger
										trigger="click"
										placement="right"
										overlay={popoverCompletudeSideMenu}
										rootClose={true}
									>
										<button
											className="whiteButton m-0 p-0 "
											onMouseEnter={() => this.setHover()}
											onMouseLeave={() => this.setHover()}
											style={{ position: 'relative', 'zIndex': '120', height: '100%', width: "100%" }}
										>
											<ProgressBar
												variant={this.state.hoveredCompletude ? this.getProgressBarColor(quote.q_completude) : 'secondary'} //   
												style={{ height: this.state.hoveredCompletude ? '14px' : '14px' }}
												now={Math.max(30, quote.q_completude)}
												label={this.state.hoveredCompletude ? `Cliquez pour en savoir plus` : `${quote.q_completude}%`}
											/>
										</button>
									</OverlayTrigger>


								</div>
							</div>
						</div>
					}


					{/* Curseur niveau de lecture   */}
					<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
						<div className="col-lg-12 ">
							<div className="d-flex flex-row">
								<strong className="d-flex align-items-center mr-auto "> Niveau de lecture {'\xa0\xa0'}</strong>
								<div className="d-flex align-items-center ">
									<ModalWindowHelp
										pageName='SideMenu'
										helpType='NiveauLecture'

										url_image={url_image}
									/>
								</div>
							</div>
							<div className="d-flex flex-column mt-3">
								<div className="d-flex justify-content-between ">
									<button className="col-lg-4 whiteButton zoomImage_10pct " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('niveau de lecture', niveauDeLectureList[0])}>{niveauDeLectureList[0]}</button>
									<button className="col-lg-4 whiteButton zoomImage_10pct " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('niveau de lecture', niveauDeLectureList[1])}>{niveauDeLectureList[1]}</button>
									<button className="col-lg-4 whiteButton zoomImage_10pct " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('niveau de lecture', niveauDeLectureList[2])}>{niveauDeLectureList[2]}</button>
								</div>
								<div className="d-flex justify-content-center ">
									<input
										type="range"
										className="col-lg-9 custom-range noBorder"
										min="0"
										max="2"
										value={niveauDeLectureList.findIndex(element => element === this.props.niveauDeLectureValue)}
										id="customRange2"
										onChange={e => this.props.setRangeValue('niveau de lecture', niveauDeLectureList[e.target.value])}
									/>
								</div>
							</div>
						</div>
					</div>



					{/* Mode contribution   */}
					<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
						<div className="col-lg-12 ">
							<strong className="d-flex "> Contribution {'\xa0\xa0'}</strong>
							<div className="d-flex justify-content-center mt-3">

								<Button
									variant={this.props.contributionActivated ? "dark" : "light"}
									className="buttonHideBordersOnClick col-lg-8 pr-2 pl-2 pt-1 pb-1"
									style={{ fontFamily: 'Roboto', fontSize: '15px' }}
									onClick={() => this.props.changeContributionMode()}
									title="Activer le mode contribution"
								>
									{this.props.contributionActivated ? "Désactiver" : "Activer"}
								</Button>
								
							</div>
						</div>
					</div>



					{/* Modération */}
					{false &&
						<div className="d-flex flex-column justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
							<div className="col-lg-12 ">
								<strong className=""> Modération </strong>
								<div className="d-flex justify-content-center align-items-center mt-3 ml-0  ">
									<ModalWindow
										modalType="Modération"
										buttonText={'Signaler ce débat '}
										buttonClassName='btn btn-warning d-inline-flex p-2 pl-3 pr-3 moderationTexte zoomImage_10pct'
										modalReasonQuestion={'Pour quelle raison souhaitez-vous signaler le débat : \u00ab ' + quote.q_title + ' \u00bb'}
										modalReasonOptions={["Ce débat est en train d'être détourné", "Ce débat est trop sujet aux émotions", "Ce débat est illégal", "..."]}
										modalTitle='Avertissement'
										modalQuestion=''
										modalButtonOkText='Signaler ce débat'
										modalButtonOkClassName='btn btn-warning'
										modalButtonNokText='Annuler'
									/>
								</div>
							</div>


							{this.props.droitsDeConnexionValue !== 'Visiteur' &&
								<div className="d-flex justify-content-center align-items-center mt-3 ">
									<PeopleIcon size={26} className="mr-3 " />
									<ModalWindow
										modalType="Modération"
										buttonText={'Suspendre ce débat '}
										buttonClassName='btn btn-warning d-inline-flex p-2 pl-3 pr-3 moderationTexte zoomImage_10pct'
										modalReasonQuestion={'Pour quelle raison souhaitez-vous suspendre le débat : \u00ab ' + quote.q_title + ' \u00bb'}
										modalReasonOptions={["Ce débat est en train d'être détourné", "Ce débat est trop sujet aux émotions", "Ce débat est illégal", "..."]}
										modalTitle='Avertissement'
										modalText="Vous vous apprêtez à suspendre ce débat. Il ne sera plus visible par les visiteurs de la plateforme. Il sera possible de le réactiver à nouveau en contactant notre service utilisateurs."
										modalQuestion='Voulez-vous continuer ?'
										modalButtonOkText='Suspendre ce débat'
										modalButtonOkClassName='btn btn-warning'
										modalButtonNokText='Annuler'
										quoteID={this.props.idquote}
										buttonAction={this.props.SuspendreQuoteAPIs}
									/>
								</div>
							}


							{this.props.droitsDeConnexionValue !== 'Visiteur' &&
								<div className="d-flex justify-content-center align-items-center mt-3 ">
									<PeopleIcon size={26} className="mr-3 " />
									<ModalWindow
										modalType="Modération"
										buttonText={'Supprimer ce débat '}
										buttonClassName='btn btn-danger d-inline-flex p-2 pl-3 pr-3 moderationTexte zoomImage_10pct'
										modalReasonQuestion={'Pour quelle raison souhaitez-vous supprimer le débat : \u00ab ' + quote.q_title + ' \u00bb'}
										modalReasonOptions={["Ce débat est illégal", "Ce débat est vide", "..."]}
										modalTitle='Attention'
										modalText='Vous vous apprêtez à supprimer définitvement ce débat. Il ne sera plus jamais accessible.'
										modalQuestion='Voulez-vous continuer ?'
										modalButtonOkText='Supprimer ce débat'
										modalButtonOkClassName='btn btn-danger'
										modalButtonNokText='Annuler'
										quoteID={this.props.idquote}
										buttonAction={this.props.DeleteQuoteAPIs}
									/>
								</div>
							}

						</div>
					}



					{/* Historique  */}
					{false &&
						<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
							<div className="col-lg-12 ">
								{/* <img className="img-fluid w-100 rounded" src={url_image+"Rectangle 502@3x.png"} alt="" />   */}
								<div className="d-flex flex-row">
									<strong className="d-flex align-items-center mr-auto "> Historique {'\xa0\xa0'}</strong>
								</div>
								<div className="mt-2 mb-1" style={{}}>

									<div className="col-lg-12 ">
										<div className="d-flex justify-content-center align-items-center mt-3 ml-0  ">
											<ModalWindowHistorique
												quote={quote}
												spot_api={this.props.spot_api}
												API_key={this.props.API_key}
											/>
										</div>
									</div>

								</div>
							</div>
						</div>
					}




					{/* Suggestion de sources */}
					{false &&
						<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-2 ">
							<div className="col-lg-12 ">
								{/* <img className="img-fluid w-100 rounded" src={url_image+"Rectangle 502@3x.png"} alt="" />   */}
								<div className="d-flex flex-row">
									<strong className="d-flex align-items-center mr-auto "> Suggestion de sources {'\xa0\xa0'}</strong>
								</div>
								<div className="mt-2 mb-1" style={{}}>

									<div className="col-lg-12 ">
										<div className="d-flex justify-content-center align-items-center mt-3 ml-0  ">
											<ModalWindow
												modalType="suggestionSources"
												buttonText={"Voir les suggestions"}
												buttonClassName='btn btn-secondary d-inline-flex p-2 pl-3 pr-3 moderationTexte zoomImage_10pct'
												modalTitle={'Articles de presse en lien avec cette information'}
												modalButtonOkText='OK'
												modalButtonOkClassName='btn btn-secondary'
											/>
										</div>
									</div>

								</div>
							</div>
						</div>
					}





					{/* Curseur type de visualisation   */}
					{this.props.droitDeConnextionAfficher && !this.props.visualisationTestUtilisateur &&
						<div className="d-flex justify-content-center menuTitres mt-4 mr-4 ml-3 pt-2 pb-3 ">
							<div className="col-lg-12 ">
								<strong className="mt-4"> Droits de connexion </strong>
								<div className="d-flex flex-column mt-3">
									<div className="d-flex justify-content-between ">
										<button className="col-lg-4 whiteButton " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('droit de connexion', droitsDeConnexionList[0])}>{droitsDeConnexionList[0]}</button>
										<button className="col-lg-4 whiteButton " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('droit de connexion', droitsDeConnexionList[1])}>{droitsDeConnexionList[1]}</button>
										<button className="col-lg-4 whiteButton " style={{ 'fontSize': '13px' }} onClick={() => this.props.setRangeValue('droit de connexion', droitsDeConnexionList[2])}>{droitsDeConnexionList[2]}</button>
									</div>
									<div className="d-flex justify-content-center ">
										<input
											type="range"
											className="col-lg-9 custom-range noBorder"
											min="0"
											max="2"
											value={droitsDeConnexionList.findIndex(element => element === this.props.droitsDeConnexionValue)}
											id="customRange3"
											onChange={e => this.props.setRangeValue('droit de connexion', droitsDeConnexionList[e.target.value])}
										/>
									</div>
								</div>
							</div>
						</div>
					}




				</div>

			</Scrollbars>

		);
	}
}

export default ComponentSideMenu;