import React, { Component } from 'react'
import { render } from 'react-dom'

import { TwitterTweetEmbed } from 'react-twitter-embed'
import ReactResizeDetector from 'react-resize-detector'

import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class ComponentTweetQuote extends Component {

  state = {

    showConsoleLog: true,
    simulationMode: false,

    showMore: false,
    quoteLinkTypeID: -1,
    mouseHoover: -1,
    confirmButtonPushed: false,

    labelisationSelected: {
      qtol_label_pour_explicite: false,
      qtol_label_contre_explicite: false,
      qtol_label_pour_implicite: false,
      qtol_label_contre_implicite: false,
      qtol_label_hors_sujet: false,
      qtol_label_nipour_nicontre: false,
      qtol_label_ambigu: false,
    },

  }

  selectLabelisation = (attributeToSelect) => {
    let labelisationNewValues = Object.assign({}, this.state.labelisationSelected)

    // Réinitialisation de toutes les valeurs à 'false'
    Object.keys(labelisationNewValues).forEach(element => { labelisationNewValues[element] = false })

    labelisationNewValues[attributeToSelect] = true

    this.setState({ labelisationSelected: labelisationNewValues })


  }

  render() {

    this.props.showConsoleLog && console.log("labelisationSelected :", this.state.labelisationSelected)

    return (

      <div className="d-flex justify-content-center col-lg-12 mt-4 ml-0 mr-0 p-0 " >

        <div className='d-flex flex-column w-100 m-0 p-0' >

          <ReactResizeDetector handleWidth handleHeight>
            {
              ({ width, height }) =>
                <div className="d-flex flex-column justify-content-center col-lg-12 m-0 p-3 ">

                  {height < 300 &&
                    <div className="d-flex flex-row justify-content-center align-items-center mt-5 mb-5 pt-5 pb-5 ">
                      <Spinner animation="grow" variant="primary" />
                    </div>
                  }

                  {height > 300 && this.props.tweetData.partiPolitique !== null &&
                    <div className="d-flex flex-column col-lg-12 mt-0 robotoFont13px " style={{ height: "40px" }}>
                      <div className="w-100 text-center ">{"Député " + this.props.tweetData.partiPolitique}</div>
                    </div>
                  }

                  <div className="" style={{ position: "relative" }}>
                    <TwitterTweetEmbed
                      className="d-flex flex-row col-lg-12 mt-0 p-0 "
                      tweetId={this.props.tweetData.idTweet}
                    />
                    <div className='w-100 h-100 ' style={{ position: "absolute", right: "0", top: "0" }}></div>
                  </div>

                  <a
                    className='text-center robotoFont12px pb-2'
                    href={`https://twitter.com/spot/status/`.concat(this.props.tweetData.idTweet)}
                    target="_blank"
                  >
                    Lien vers le Tweet
                  </a>

                  {height > 300 && this.props.showFormulaire &&
                    <div className="d-flex flex-column mt-1">

                      <div className='robotoFont13px text-center ' style={{ height: "40px", color: "grey" }}>
                        {(this.state.quoteLinkTypeID === 1 || this.state.mouseHoover === 1) && <div>Ce Tweet exprime <u>explicitement</u> un avis <u>favorable</u> à ce débat.</div>}
                        {(this.state.quoteLinkTypeID === 2 || this.state.mouseHoover === 2) && <div>Ce Tweet exprime <u>explicitement</u> un avis <u>défavorable</u> ce débat.</div>}
                        {(this.state.quoteLinkTypeID === 3 || this.state.mouseHoover === 3) && <div>On peut <u>déduire</u> que ce Tweet exprime un avis <u>favorable</u> au débat.</div>}  {/* , bien que cela ne soit <u>pas explicitement dit</u> */}
                        {(this.state.quoteLinkTypeID === 4 || this.state.mouseHoover === 4) && <div>On peut <u>déduire</u> que ce Tweet exprime un avis <u>défavorable</u> au débat.</div>}
                        {(this.state.quoteLinkTypeID === 5 || this.state.mouseHoover === 5) && <div>Ce Tweet est <u>hors-sujet</u>. Il ne traite pas du débat.</div>}
                        {(this.state.quoteLinkTypeID === 6 || this.state.mouseHoover === 6) && <div>Ce Tweet exprime un avis <u>ni favorable ni défavorable</u> au débat.</div>}
                        {(this.state.quoteLinkTypeID === 7 || this.state.mouseHoover === 7) && <div>Ce Tweet est <u>ambigu</u> ou <u>pas explicite</u>. Il ne permet de déterminer un avis favorable ou défavorable.</div>}
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey', position: 'relative', bottom: '7px' }} />
                        <div><p className="mt-2 robotoFont13px" style={{ width: '130px', textAlign: 'center', color: 'grey' }}>Classer ce Tweet</p></div>
                        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey', position: 'relative', bottom: '7px' }} />
                      </div>

                      {this.state.quoteLinkTypeID == -1 &&
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row justify-content-around">
                            <button className="col-lg-5 m-0 p-0 robotoFont13px pasdaccord "
                              onClick={() =>
                              (this.state.simulationMode ?
                                (this.setState({ quoteLinkTypeID: 1 }), this.selectLabelisation("qtol_label_pour_explicite"), setTimeout(() => { this.props.insertCommentateurFromTweetAPI(this.state.quoteLinkTypeID, this.props.tweetData, this.state.labelisationSelected) }, 200))
                                :
                                (this.setState({ quoteLinkTypeID: 1 }), this.selectLabelisation("qtol_label_pour_explicite"))
                              )}
                              onMouseEnter={() => this.setState({ mouseHoover: 1 })}
                              onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                            >explicitement pour</button>
                            <button className="col-lg-5 m-0 p-0 robotoFont13px daccordBox "
                              onClick={() =>
                              (this.state.simulationMode ?
                                (this.setState({ quoteLinkTypeID: 2 }), this.selectLabelisation("qtol_label_pour_explicite"), setTimeout(() => { this.props.insertCommentateurFromTweetAPI(this.state.quoteLinkTypeID, this.props.tweetData, this.state.labelisationSelected) }, 200))
                                :
                                (this.setState({ quoteLinkTypeID: 2 }), this.selectLabelisation("qtol_label_pour_explicite"))
                              )}
                              onMouseEnter={() => this.setState({ mouseHoover: 2 })}
                              onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                            >explicitement contre</button>
                          </div>

                          <div className="d-flex flex-row justify-content-center mt-2">
                            {!this.state.showMore && <button className="col-lg-8 m-0 p-0 btn btn-light" onClick={() => { this.setState({ showMore: true }) }}>Autres options</button>}
                            {this.state.showMore && <button className="col-lg-8 m-0 p-0 btn btn-light" onClick={() => { this.setState({ showMore: false }) }}>Afficher moins</button>}
                          </div>

                          {this.state.showMore &&
                            <div className="d-flex flex-wrap justify-content-around mt-2">
                              <button className="col-lg-5 mt-2 p-0 robotoFont13px pasdaccord" style={{ opacity: 0.6 }}
                                onClick={() => (this.setState({ quoteLinkTypeID: 3 }), this.selectLabelisation("qtol_label_pour_implicite"))}
                                onMouseEnter={() => this.setState({ mouseHoover: 3 })}
                                onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                              >implicitement pour</button>
                              <button className="col-lg-5 mt-2 p-0 robotoFont13px daccordBox" style={{ opacity: 0.6 }}
                                onClick={() => (this.setState({ quoteLinkTypeID: 4 }), this.selectLabelisation("qtol_label_contre_implicite"))}
                                onMouseEnter={() => this.setState({ mouseHoover: 4 })}
                                onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                              >implicitement contre</button>
                              <button className="col-lg-5 mt-2 p-0 robotoFont13px peutetre" style={{ opacity: 0.7 }}
                                onClick={() => (this.setState({ quoteLinkTypeID: 5 }), this.selectLabelisation("qtol_label_hors_sujet"))}
                                onMouseEnter={() => this.setState({ mouseHoover: 5 })}
                                onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                              >hors-sujet</button>
                              <button className="col-lg-5 mt-2 p-0 robotoFont13px peutetre" style={{ opacity: 0.7 }}
                                onClick={() => (this.setState({ quoteLinkTypeID: 6 }), this.selectLabelisation("qtol_label_nipour_nicontre"))}
                                onMouseEnter={() => this.setState({ mouseHoover: 6 })}
                                onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                              >ni pour ni contre</button>
                              <button className="col-lg-5 mt-2 p-0 robotoFont13px peutetre" style={{ opacity: 0.7 }}
                                onClick={() => (this.setState({ quoteLinkTypeID: 7 }), this.selectLabelisation("qtol_label_ambigu"))}
                                onMouseEnter={() => this.setState({ mouseHoover: 7 })}
                                onMouseLeave={() => this.setState({ mouseHoover: -1 })}
                              >ambigu</button>
                            </div>
                          }

                        </div>
                      }

                      {this.state.quoteLinkTypeID > 0 &&
                        <div className="d-flex flex-column justify-content-center mt-2">

                          {!this.state.confirmButtonPushed &&
                            <div className="d-flex flex-row justify-content-around mt-2">
                              <button
                                className="col-lg-5 m-0 p-0 peutetre btn btn-danger"
                                onClick={() => this.setState({ quoteLinkTypeID: -1 })}
                              >
                                Annuler
                              </button>
                              <button
                                className="col-lg-5 m-0 p-0 btn btn-success"
                                onClick={() => {
                                  this.props.insertCommentateurFromTweetAPI(this.state.quoteLinkTypeID, this.props.tweetData, this.state.labelisationSelected)
                                  this.setState({ confirmButtonPushed: true })
                                }}
                              >
                                Confirmer
                              </button>
                            </div>
                          }

                          {this.state.confirmButtonPushed &&
                            <div className="d-flex flex-row justify-content-center align-items-center mt-3 mb-3 pt-2 pb-2 ">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          }

                        </div>
                      }

                    </div>
                  }


                  {height > 300 && false &&
                    <div className="d-flex flex-column mt-4 mb-2 p-0 ">

                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey', position: 'relative', bottom: '7px' }} />
                        <div><p className="robotoFont13px" style={{ width: '180px', textAlign: 'center', color: 'grey' }}>Options supplémentaires</p></div>
                        <div style={{ flex: 1, height: '1px', backgroundColor: 'grey', position: 'relative', bottom: '7px' }} />
                      </div>

                      <Accordion className="w-100"> {/* defaultActiveKey="0" */}
                        <Accordion.Item eventKey="0" className="w-100">
                          <Accordion.Header bsprefix="w-100 mb-2 robotoFont13px" >Informations sur ...</Accordion.Header>
                          <Accordion.Body bsprefix="w-100 robotoFont14px" >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam.
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>

                      {false &&  // A activer
                        <button className="d-flex flex-row justify-content-center mt-2 p-0 btn btn-link" onClick={() => { }}>Voir d'autres Tweets de cette personne ...</button>
                      }

                    </div>
                  }

                  {false && <div className="d-flex flex-row col-lg-12 ml-0 mr-0 mt-2 p-0">{`${width}x${height}`}</div>}
                </div>
            }
          </ReactResizeDetector>

        </div>

      </div>

    )
  }

}

export default ComponentTweetQuote