import React, { Component } from 'react';

import '../full.css';

class ThemeIconBar2 extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showConsoleLog: true,
    initDataRequest: true,

    referenceDetails: [],
  }

  componentDidMount() {
    // this.initData()
  }

  initData = () => {
  }

  componentDidUpdate() {

  }


  replaceIconeText(name, showConsoleLog) {
    this.props.showConsoleLog && console.log('ThemeIconBar -> replace(name) : ', name.replace(/[\s&]/g, '_').concat('_IconeText'))
    return name.replace(/[\s&]/g, '_').concat('_IconeText')
  }

  replaceBorder(name, showConsoleLog) {
    this.props.showConsoleLog && console.log('ThemeIconBar -> replace(name) : ', name.replace(/[\s&]/g, '_').concat('_IconeText'))
    return name.replace(/[\s&]/g, '_').concat('_IconeBorder')
  }


  opaqueImage(name, showConsoleLog) {
    this.props.showConsoleLog && console.log('ThemeIconBar -> replace(name) : ', name.replace(/[\s&]/g, '_').concat('_IconeText'))
    return name.replace('.svg', '_opaque.svg')
  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {

    return (
      <button
        className="d-flex align-items-start p-0 m-0 whiteButton "
        onClick={() => this.props.onClickTheme(this.props.element.idtheme)}
        style={{ width: '120px', height: '100%', opacity: '1' }}
      >

        <div
          className="d-flex flex-column justify-content-center m-0 p-0 "
          style={{ position: 'relative', top: '0', width: '100%' }}>
          <div className="d-flex justify-content-center m-0 p-0 " style={{}}>
            {this.props.showConsoleLog && console.log("t_photo : ", this.props.element.t_photo)}
            <img
              className={"zoomImage_10pct pt-1 " + ` ${this.replaceIconeText(this.props.element.t_title, this.props.showConsoleLog)} `}
              src={this.props.selected ?
                this.props.url_image + this.opaqueImage(this.props.element.t_photo, this.props.showConsoleLog)
                :
                (this.props.selectedThemesNumber === this.props.maxThemeSelected ?
                  this.props.url_image + this.props.element.t_photo_gris
                  :
                  this.props.url_image + this.props.element.t_photo
                )}
              alt={this.props.element.t_photo}
              style={{ height: '90px', opacity: (!this.props.selected && this.props.selectedThemesNumber > 0) ? '0.2' : '1', borderRadius: '70px' }}
            />
          </div>
          <div className="d-flex justify-content-center zoomText text-center mt-0 m-0 p-0 " style={{}}>
            <p
              className={'d-flex mb-0 ml-0 p-0 text-center ' + (this.props.selected ? `${this.replaceIconeText(this.props.element.t_title, this.props.showConsoleLog)}` : (this.props.selectedThemesNumber === this.props.maxThemeSelected ? " IconeText_Gris" : `${this.replaceIconeText(this.props.element.t_title, this.props.showConsoleLog)}`))} // IconeText_Gris   ${replaceIconeText(name, this.props.showConsoleLog)}
              style={{ fontWeight: this.props.selected ? 'bold' : 'normal', height: '35px' }}
            >
              {this.props.element.t_title}
            </p>
          </div>
          {this.props.numberOfQuotesWithThisTheme !== undefined &&
            <div className={"d-flex justify-content-center mt-1 m-0 p-0 "} style={{}}>
              <span
                className={
                  (this.props.numberOfQuotesWithThisTheme < 10 ? "pr-2 pl-2 " : "pr-1 pl-1 ")
                  + ` ${this.replaceIconeText(this.props.element.t_title, this.props.showConsoleLog)} `
                  + ` ${this.replaceBorder(this.props.element.t_title, this.props.showConsoleLog)}`
                }
                style={{ fontWeight: 'bold' }}
              >
                {this.props.numberOfQuotesWithThisTheme}
              </span>
            </div>
          }
        </div>

      </button>
    )
  }
}


export default ThemeIconBar2
