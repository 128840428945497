import React, { Component } from 'react';

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import { CheckCircleIcon, CircleIcon } from '@primer/octicons-react'

// #JM Import HighCharts packages
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official"
import itemSeries from 'highcharts/modules/item-series';
// https://stackoverflow.com/questions/48460482/how-to-import-highcharts-sub-modules-in-react-app
// https://www.highcharts.com/docs/getting-started/install-from-npm
itemSeries(Highcharts);


{/* Inputs
  headersList = [
    { columnName: "#", attributeInData: "defaultIndex", filterType: "", classNameAdd: "" }, 
    { columnName: "Nom du groupe", attributeInData: "parti_pers_nom", filterType: "input", classNameAdd: "" }, 
    { columnName: "Vote", attributeInData: "svo_positionVote", filterType: "select", classNameAdd: "" }
  ]
  data = Object
*/}


class ComponentTable extends Component {

  state = {
    showConsoleLog: true,
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  returnFilterValue = (filtersToApply, scrutin) => {
    let returnValue = true

    if (Object.keys(filtersToApply).length !== 0) {

      Object.entries(filtersToApply).forEach((item, index) => {
        let key = item[0]
        let value = item[1]

        if (!String(scrutin[key]).includes(String(value))) {
          returnValue = false
        }

      })
    }

    return returnValue

  }

  getClassName = (classnameObject, valueInTable) => {
    let returnArray = []

    this.props.showConsoleLog && console.log("getClassNamevalueInTable :", valueInTable)

    Object.entries(classnameObject).forEach((entry) => {
      if (entry[0] === "default") {
        returnArray.push(entry[1])
      }
      if (valueInTable !== undefined && entry[0] === valueInTable) {
        returnArray.push(entry[1])
      }

    })

    returnArray = returnArray.join(' ')
    this.props.showConsoleLog && console.log("getClassName returnArray :", returnArray)
    return returnArray

  }


  render() {

    const dataArray = []

    let uniqueValuesDict = {}
    this.props.headersList.map((headerItem, index) => {

      let tempList = []
      this.props.data.forEach((dataItem, index2) => {
        let dataValue = dataItem[headerItem.attributeInData]
        if (!(tempList.indexOf(dataValue) > -1)) {
          tempList.push(dataValue)
        }

      })

      uniqueValuesDict[headerItem.attributeInData] = tempList

    })

    // console.log("uniqueValuesDict :", uniqueValuesDict)

    const filtersDict = (() => {
      let returnDict = {}
      this.props.headersList.forEach((element, index) => {
        if (element["filterType"] !== "") {
          returnDict[element["attributeInData"]] = element["filterValue"]
        }
      })
      return returnDict
      //return !(n > 1) ? 1 : arguments.callee(n - 1) * n;
    })()

    this.props.showConsoleLog && console.log("filtersDict :", filtersDict)

    const filtersToApply = ((filtersDict) => {
      const entries = Object.entries(filtersDict)
      this.props.showConsoleLog && console.log("entries :", entries)
      let returnDict = ((entries) => {
        let tempDict = {}
        entries.forEach((entry) => {
          if (entry[1].length > 0) {
            tempDict[entry[0]] = entry[1]
          }
        })
        return tempDict
      })(entries)

      return returnDict
    })(filtersDict)

    this.props.showConsoleLog && console.log("filtersToApply :", filtersToApply)


    return (

      <Table striped bordered hover variant="white" className="m-0 p-0 ">
        <thead>
          <tr>
            {
              this.props.headersList.map((headerItem, index) => (
                <th key={`tableVotesHeaderColumns_${index}`} className={window.innerWidth > 575.98 ? "robotoFont14px align-top " + this.getClassName(headerItem['classNameAdd']) : "robotoFont12px align-top " + this.getClassName(headerItem['classNameAdd'])}>
                  {headerItem['columnName']}

                  {headerItem.filterType === "input" && <Form.Control size="sm" type="text" className="text-left" placeholder="Filtrer..." onChange={e => this.props.changeFiltersValue(headerItem['attributeInData'], e.target.value)} />}
                  {headerItem.filterType === "select" && uniqueValuesDict.hasOwnProperty(headerItem.attributeInData) &&
                    <Form.Select size="sm">
                      <option>Filtrer...</option>
                      {uniqueValuesDict[headerItem.attributeInData]
                        .map((optionItem, index2) => (
                          <option
                            key={`voteFilter_${index}_${index2}`}
                            onClick={e => this.props.changeFiltersValue(headerItem['attributeInData'], e.target.value)}
                          >
                            {optionItem}
                          </option>
                        ))
                      }
                    </Form.Select>
                  }
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            this.props.data
              .filter((voteElement) => this.returnFilterValue(filtersToApply, voteElement))
              .map((voteElement, index1) => (
                <tr key={`tableVotes_vote_${index1}`}>
                  {
                    this.props.headersList.map((headerValue, index2) => (
                      headerValue['attributeInData'] === "defaultIndex" ?
                        <td key={`tableVotesValueColumn_${index2}`} className={window.innerWidth > 575.98 ? "robotoFont14px align-middle " + this.getClassName(headerValue['classNameAdd']) : "robotoFont12px align-middle " + this.getClassName(headerValue['classNameAdd'])}>
                          {index1 + 1}
                        </td>
                        :
                        (headerValue['formType'] === "checkbox" ?
                          <td key={`tableVotesValueColumn_${index2}`} className="align-middle ">
                            {
                              headerValue['formValuesToCompareWithList'].includes(voteElement[headerValue['formAttributeToCheck']]) ?
                                <Button
                                  onClick={() => { }}
                                  className="d-flex justify-content-center align-items-center w-100 m-0 p-1 "
                                // style={{ backgroundColor: "#E55C5C", borderColor: "#E55C5C" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                                >
                                  <CheckCircleIcon size={16} />
                                </Button>
                                :
                                <Button
                                  variant="light"
                                  onClick={() => { }}
                                  className=""
                                  style={{ border: "none", backgroundColor: "transparent" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                                >
                                  <CircleIcon size={16} />
                                </Button>
                            }
                          </td>
                          : (headerValue['attributeInData'].includes('pers_picture') ?
                            <td key={`tableVotesValueColumn_${index2}`} className={window.innerWidth > 575.98 ? "robotoFont14px " + this.getClassName(headerValue['classNameAdd']) : "robotoFont12px m-0 p-0 align-middle " + this.getClassName(headerValue['classNameAdd'])}>
                              <img
                                className="commentateurImage img-fluid m-0 p-0"
                                src={voteElement[headerValue['attributeInData']]}
                                alt=""
                                style={{ height: "60px" }}
                              />
                            </td>
                            :
                            <td key={`tableVotesValueColumn_${index2}`} className={window.innerWidth > 575.98 ? "robotoFont14px align-middle " + this.getClassName(headerValue['classNameAdd'], voteElement[headerValue['attributeInData']]) : "robotoFont12px align-middle " + this.getClassName(headerValue['classNameAdd'], voteElement[headerValue['attributeInData']])}>
                              {voteElement[headerValue['attributeInData']]}
                            </td>
                          ))
                    ))
                  }
                </tr>
              ))
          }
        </tbody>
      </Table>

    )
  }
}


export default ComponentTable
