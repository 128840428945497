import React, { Component } from 'react';

// import { ApiClient, ReferenceApi, CommentateurApi } from 'spot_api_old'

import { SquareFillIcon } from '@primer/octicons-react'

import ComponentCommentateurBox from './ComponentCommentateurBox'


import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import ProgressBar from 'react-bootstrap/ProgressBar';

class ComponentPourComprendreCard extends Component {

  state = {
    commentateurSource: [],
    referenceSource: [],

    showConsoleLog: true,

  }

  componentDidMount() {
    // this.CommentateurAPIs(this.props.argument.idquote)
  }


  prettyDate_update(element) {
    element.ref_deleteDate = new Date(element.ref_deleteDate);
    var dateObj2 = element.ref_deleteDate;
    var month = dateObj2.getUTCMonth() + 1;
    var day = dateObj2.getUTCDate();
    var year = dateObj2.getUTCFullYear();
    var hour = dateObj2.getUTCHours();
    var minute = dateObj2.getUTCMinutes();
    if (minute < 10) {
      element.ref_deleteDate = year + "/" + month + "/" + day + " " + hour + ":0" + minute;
    }
    else {
      element.ref_deleteDate = year + "/" + month + "/" + day + " " + hour + ":" + minute;
    }
  }


  callApiReferences(quoteID, commentateur) {
    let element = commentateur
    element.references = []

    let apiInstance = new this.props.spot_api.ReferenceApi();

    this.props.queryTracker('ComponentPourComprendreCard.js', 'callApiReferences')
    apiInstance.referenceQuoteIDCommentateurIDGet(element.person.idperson, quoteID, (error, dataRef, response) => {

      if (error) {
        console.error(error)
      } else {
        // Mise en forme de la date du dernier update
        // Ajout des références à la quote

        this.props.showConsoleLog && console.log('callApiReferences : ', dataRef)
        dataRef.forEach(element => {
          this.prettyDate_update(element)
        })
        element.references = dataRef
        this.props.showConsoleLog && console.log('callApiReferences -> data2 : ', element)
      }

      if (element.references.length > 0) {
        this.setState({ referenceSource: element.references[0] })
      }

      // return element
    })
  }

  CommentateurCausaliteAPIs = (quoteLinkID) => {
    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.CommentateurApi();

    this.props.showConsoleLog && console.log('CommentateurCausaliteAPIs -> quoteLinkID : ', quoteLinkID)

    this.props.queryTracker('ComponentPourComprendreCard.js', 'CommentateurCausaliteAPIs')
    // Récupérer la liste des commentateurs d'une causalité (quote_link_author_position)
    apiInstance.commentateursCausaliteGet(quoteLinkID, (error, data, response) => {
      if (error) {
        console.error(error);
      } else {

        this.props.showConsoleLog && console.log('ComponentArgument -> CommentateurCausaliteAPIs : ', quoteLinkID, ' / data1 : ', data)

        if (data.length > 0) {
          this.props.showConsoleLog && console.log('ComponentArgument -> ', quoteLinkID, ' / data11 : ', data[0])
          this.setState({ commentateurSource: data })
        }

        if (data.length > 0) {
          // this.callApiReferences(quoteID, data[0])
        }

        this.props.showConsoleLog && console.log('ComponentArgument -> data3 : ', data)

      }
    });
  }



  render() {

    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.person : ', this.props.person)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> ReferenceArgumentAPIs : ', this.state.listreferences)

    const commentateurSource = this.state.commentateurSource // this.props.commentateurs !== undefined ? this.props.commentateurs : [] // this.state.commentateurSource
    const commentateurSourcePour = this.state.commentateurSource.filter(({ qlt_title }) => qlt_title === "pour")
    const commentateurSourceContre = this.state.commentateurSource.filter(({ qlt_title }) => qlt_title === "contre")

    this.props.showConsoleLog && console.log("ComponentCommentateur -> commentateurSource : ", commentateurSource)

    const referenceSource = this.state.referenceSource

    this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> argument : ", this.props.argument)
    this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> commentateurs : ", this.props.commentateurs)
    this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> quoteLinkID : ", this.props.quoteLinkID)

    return (
      <Card
        className="w-100 mt-3 pl-1 pr-3 "
        style={{ border: 'none' }}
      >

        <Card.Header
          className="m-0 p-2 "

          style={{
            backgroundColor:
              this.props.pourOuContre === "pour" ? '#E5F1FD' :
                (this.props.pourOuContre === "contre" ? 'rgba(186, 194, 228, 0.5)' :
                  (this.props.pourOuContre === "quoi" ? '' :
                    (this.props.pourOuContre === "chronologie" ? '#faf1d0' : ""))),
            borderRadius: '5px',
          }}
        >
          <Accordion.Item
            // as={button}
            variant="link"
            eventKey={this.props.keyValue + 1}
            className="m-0 p-1 w-100 text-left robotoFont15px "
            onClick={() => {
              if (this.state.commentateurSource.length === 0) {
                this.CommentateurCausaliteAPIs(this.props.quoteLinkID)
              }
            }}
          >

            <Accordion.Header className="">

              <div className="w-100 m-0 p-0 ">

                {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> argument : ", this.props.argument)}
                {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> natureInformationTable : ", this.props.natureInformationTable)}
                {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> argument.q_typeID : ", this.props.argument.quote.q_typeID)}
                {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> argument.q_title : ", this.props.argument.quote.q_title)}
                {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> getAttributeValueInArrayOfObjectFromOtherAttribute : ",
                  this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.props.argument.quote.q_typeID, 'introduction'),
                  this.props.argument.q_title)}

                {/* Affichage du titre */}
                <div
                  className="d-flex flex-row w-100 m-0 pb-2 quoiTexte text-left "
                  style={{ fontSize: window.innerWidth > 576 ? "14px" : "12px" }}
                >
                  {this.props.argument.quote.qt_title !== "évènement" && // .concat("\xa0 -> ", this.props.argument.idquote)
                    <div>
                      {/* Intro */}
                      <span style={{ color: 'grey' }}>{this.props.argument.quote.intro.concat(" ... ")}</span>
                      {/* Titre */}
                      <span>{this.props.argument.quote.q_title}</span>
                    </div>
                  }

                  {this.props.argument.quote.qt_title === "évènement" && // .concat("\xa0 -> ", this.props.argument.idquote)
                    <div>
                      <div style={{ color: 'grey' }}>
                        {this.props.capitalizeFirstLetter(this.props.introDate2(this.props.argument.quote).concat(' ...'))}
                      </div>
                      <div>{this.props.capitalizeFirstLetter(this.props.argument.quote.q_title)}</div>
                    </div>
                  }
                </div>

              </div>
            </Accordion.Header>


            <Accordion.Collapse
              as={"div"}
              variant="link"
              eventKey={this.props.keyValue + 1}
              className="m-0 p-0 "
            >
              <Card.Body className={"m-0 robotoFont15px ".concat(window.innerWidth > 576 ? "p-0 " : "p-1 ")} style={{ border: 'none', borderColor: 'grey', borderWidth: '1px' }} >
                <ListGroup as="div" variant="flush" className="">
                  <ListGroup.Item as="div" className={"m-0 rounded  ".concat(window.innerWidth > 576 ? "pr-0 pl-0 pt-1 " : "p-1 ")}>
                    {this.props.showConsoleLog && console.log("ReferenceArgumentAPIsReturn -> argument : ", this.props.argument)}
                    {this.props.showConsoleLog && console.log("ReferenceArgumentAPIsReturn -> commentateurSource : ", this.state.commentateurSource)}
                    {this.props.showConsoleLog && console.log("ReferenceArgumentAPIsReturn -> referenceSource : ", this.state.referenceSource)}

                    <div className="w-100 m-0 p-0 ">

                      {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> idquote : ", this.props.argument.quote.idquote)}

                      {this.props.argument.commentateursList.length > 0 && // FONCTION A AJOUTER
                        <em className="d-flex flex-row w-100 mt-1 argumentNatureSous justify-content-center " >
                          Que pensent-ils.elles de cet argument ?
                        </em>
                      }

                      {true && this.props.commentateurs !== undefined && // FONCTION A AJOUTER
                        <div className="d-flex flex-row w-100 ">

                          <div className={"w-100 col-lg-6 m-0 ".concat(window.innerWidth > 575.98 ? "p-2 " : "p-0 ")}>

                            <ComponentCommentateurBox

                              // Spécifiques
                              addPosition="pour" // CFC & CC
                              classAddOn={"commentateurPOUR"} // CC
                              classAddOn2={"commentateurTextPour"} // CC
                              quoteOrArgumentForm="argument" // CFC
                              uniqueID={this.props.uniqueID.concat("_commentateurBoxPour")} // CFC
                              boxTitle="d'accord"
                              size="small"
                              quoteTitle={this.props.miseEnFormeIntroAvecCitation(
                                this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.props.selectedArgumentID, 'introduction'),
                                this.props.argument.quote.q_title)
                              } // CFC & CC
                              quoteID={this.props.selectedArgumentID} // CFC & CC
                              quoteLinkID={this.props.quoteLinkID}
                              listcommentateurs={commentateurSourcePour} // CFC

                              // Listes 
                              niveauDeLectureValue={this.props.niveauDeLectureValue}
                              listcommentateursContreId={this.state.listcommentateursContreId} // CFC & CC
                              listcommentateursPeutetreId={this.state.listcommentateursPeutetreId} // CFC & CC
                              listcommentateursPourId={this.state.listcommentateursPourId} // CFC & CC
                              listmedia={this.props.listmedia} // CFC & CC
                              listmediatype={this.props.listmediatype} // CFC & CC
                              listorganisationtype={this.props.listorganisationtype} // CFC & CC
                              listperson={this.props.listperson} // CFC & CC
                              listreferencetype={this.props.listreferencetype} // CFC & CC
                              listsomeonetype={this.props.listsomeonetype} // CFC & CC

                              // Fonctions
                              commentateursAPIs={this.props.commentateursAPIs} // CFC & CC
                              getPersonPictureName={this.props.getPersonPictureName} // CFC & CC
                              ReferenceTypeAPIs={this.props.ReferenceTypeAPIs} // CFC & CC
                              miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation} // CFC & CC

                              // Fonctions transversales
                              url_image={this.props.url_image} // CFC & CC
                              visitorLocation={this.props.visitorLocation} // CFC & CC
                              // CFC & CC
                              showConsoleLog={this.props.showConsoleLog} // CFC & CC
                              queryTracker={this.props.queryTracker} // CFC & CC
                              spot_api={this.props.spot_api}
                              API_key={this.props.API_key}

                              // Pour ComponentCommentateurBox
                              affichageNombreCommentateurs={this.props.affichageNombreCommentateurs}
                              // quoteTitle={this.props.quoteTitle}
                              CommentateurCausaliteAPIs={this.CommentateurCausaliteAPIs}

                            />

                          </div>

                          {this.props.showConsoleLog && console.log("ComponentPourComprendreCard -> quoteLinkID :", this.props.quoteLinkID)}

                          <div className={"w-100 col-lg-6 m-0 ".concat(window.innerWidth > 575.98 ? "p-2 " : "p-0 ")}>

                            <ComponentCommentateurBox

                              // Spécifiques
                              addPosition="contre" // CFC & CC
                              classAddOn={"commentateurCONTRE"} // CC
                              classAddOn2={"commentateurTextContre"} // CC
                              quoteOrArgumentForm="argument" // CFC
                              uniqueID={this.props.uniqueID.concat("_commentateurBoxContre")} // CFC
                              boxTitle="Pas d'accord"
                              size="small"
                              quoteTitle={this.props.miseEnFormeIntroAvecCitation(
                                this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.props.selectedArgumentID, 'introduction'),
                                this.props.argument.quote.q_title)} // CFC & CC
                              quoteID={this.props.selectedArgumentID} // CFC & CC
                              quoteLinkID={this.props.quoteLinkID}
                              listcommentateurs={commentateurSourceContre} // CFC

                              // Listes 
                              niveauDeLectureValue={this.props.niveauDeLectureValue}
                              listcommentateursContreId={this.state.listcommentateursContreId} // CFC & CC
                              listcommentateursPeutetreId={this.state.listcommentateursPeutetreId} // CFC & CC
                              listcommentateursPourId={this.state.listcommentateursPourId} // CFC & CC
                              listmedia={this.props.listmedia} // CFC & CC
                              listmediatype={this.props.listmediatype} // CFC & CC
                              listorganisationtype={this.props.listorganisationtype} // CFC & CC
                              listperson={this.props.listperson} // CFC & CC
                              listreferencetype={this.props.listreferencetype} // CFC & CC
                              listsomeonetype={this.props.listsomeonetype} // CFC & CC

                              // Fonctions
                              commentateursAPIs={this.props.commentateursAPIs} // CFC & CC
                              getPersonPictureName={this.props.getPersonPictureName} // CFC & CC
                              ReferenceTypeAPIs={this.props.ReferenceTypeAPIs} // CFC & CC
                              miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation} // CFC & CC

                              // Fonctions transversales
                              url_image={this.props.url_image} // CFC & CC
                              visitorLocation={this.props.visitorLocation} // CFC & CC
                              // CFC & CC
                              showConsoleLog={this.props.showConsoleLog} // CFC & CC
                              queryTracker={this.props.queryTracker} // CFC & CC
                              spot_api={this.props.spot_api}
                              API_key={this.props.API_key}

                              // Pour ComponentCommentateurBox
                              affichageNombreCommentateurs={this.props.affichageNombreCommentateurs}
                              // quoteTitle={this.props.quoteTitle}
                              CommentateurCausaliteAPIs={this.CommentateurCausaliteAPIs}

                            />

                          </div>

                        </div>
                      }

                      {false &&
                        <div className={"row justify-content-around w-100 m-0 mt-3 p-0 "}>

                          <a
                            href={'/citations_2/' + this.props.argument.quote.idquote}
                            target="_blank"
                            rel="noreferrer"
                            className='m-0 p-1 '
                            title="Aller sur la page de cette information"
                            style={{ textDecoration: "none" }}
                          >
                            <p className="modifierSource m-0 p-0 ">
                              Aller sur la page de cette information
                            </p>
                          </a>

                        </div>
                      }


                    </div>

                  </ListGroup.Item>
                </ListGroup>

              </Card.Body>
            </Accordion.Collapse>



          </Accordion.Item>
        </Card.Header>




        {/* Nature de l'information */}
        <div className="d-flex flex-row col-12 col-lg-12 mt-0 ">
          <div className="d-flex align-item-center mr-auto pt-1 ">
            {
              (this.props.argument.quote.qt_title === "fait" || this.props.argument.quote.qt_title === "projection future") &&
              this.props.argument.quote.completude !== undefined &&
              <div className="footer ">Niveau de preuve :
                <p
                  className="whiteButton ml-1"
                  // onMouseEnter={() => this.toggleHover()}
                  // onMouseLeave={() => this.toggleHover()}
                  style={{ position: 'relative', width: '100px', backgroundColor: 'transparent' }}
                >
                  <ProgressBar
                    // variant={this.state.completudeHovered ? this.getProgressBarColor(this.props.argument.completude) : this.getProgressBarColor(this.props.argument.completude)} // 'secondary'
                    style={{ height: this.state.completudeHovered ? '14px' : '12px' }}
                    now={Math.max(this.props.argument.quote.completude, 30)}
                    label={`${this.props.argument.quote.completude}%`}
                  />
                </p>
              </div>
            }

          </div>
          <div
            className="d-inline-flex flex-row-reverse w-100 m-0 p-0 pt-1 argumentNatureSous " // blockquote-footer 
          >
            <div
              className="m-0 p-0 "
              style={{
                color: this.props.argument.quote.qt_title === "fait" ? "#ffc305"
                  : (this.props.argument.quote.qt_title === "opinion" ? "#ff5733"
                    : (this.props.argument.quote.qt_title === "étude" ? "#c70039"
                      : (this.props.argument.quote.qt_title === "proposition d'action" ? "#581845"
                        : (this.props.argument.quote.qt_title === "projection future" ? "#ff5733"
                          : ""
                        ))))
              }}
            >
              <SquareFillIcon size={14} />
            </div>
            <div className="m-0 p-0 " >{this.props.argument.quote.qt_title + "\xa0"}</div> {/* Type d'information : */}
          </div>
        </div>
      </Card >

    )
  }
}


export default ComponentPourComprendreCard