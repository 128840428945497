import React, { Component } from 'react';
import { CheckIcon } from '@primer/octicons-react'

import emailjs, { init } from 'emailjs-com';


import '../full.css';


// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import ComponentGrilleDeNotes from './ComponentGrilleDeNotes'

class ComponentFormulaireQuestionnaire extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    questionnaireSousTitreShow: false,

    petitQuestionnaire: true,
    markNumb1: -1,
    markNumb2: -1,
    commentaire: '',
    recontactAccept: "non",
    emailContact: '',

    successButtonShow: false,

  }

  componentDidMount() {
    init("user_rZufcPevAotKvNRPn4d0w");
  }

  componentDidUpdate() {
  }

  changeMark = (variableName, value) => {
    if (variableName === "markNumb1") {
      this.setState({ markNumb1: value })
    }
    if (variableName === "markNumb2") {
      this.setState({ markNumb2: value })
    }
  }

  sendEmail = () => {

    this.props.showConsoleLog && console.log('emailjs in')

    var templateParams = {
      from_name: 'SPOT',
      Note01: this.state.markNumb1,
      Note02: this.state.markNumb2,
      Commentaire: this.state.commentaire,
      AccepteRecontact: this.state.recontactAccept,
      EMailContact: this.state.emailContact === "" ? "debats.ido@outlook.fr" : this.state.emailContact,
    };

    emailjs.send('service_ok2syt8', 'template_z8wkvmr', templateParams)
      .then(response => {
        this.props.showConsoleLog && console.log('emailjs SUCCESS!', response.status, response.text)
        // alert("Email sent successfully!")
        this.setState({ successButtonShow: true }, () => setTimeout(() => { this.setState({ show: false }) }, 1500))

      }, error => {
        this.props.showConsoleLog && console.log('emailjs FAILED...', error)
      });


  }


  render() {


    return (

      <div className="">
        <div className="d-flex align-items-center justify-content-center ">
          <button
            className="transparentButton "
            style={{ position: "fixed", zIndex: 100, right: 20, bottom: this.state.questionnaireSousTitreShow ? 0 : 20 }}
            onClick={() => { this.setState({ show: true }) }}
            onMouseEnter={() => this.setState({ questionnaireSousTitreShow: true })}
            onMouseLeave={() => this.setState({ questionnaireSousTitreShow: false })}
          >
            <img
              className="mascotteQuestionnaire "
              src={this.props.url_image + "Mascotte_questionnaire.png"}
              alt="Logo_questionnaire"
            />
            {this.state.questionnaireSousTitreShow &&
              <p className="questionnaireSousTitre " style={{ color: "#000000" }}>Votre avis nous intéresse</p>
            }
          </button>
        </div>

        <Modal
          show={this.state.show}
          onHide={() => { this.setState({ show: false }) }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">
              Votre avis nous intéresse !
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>



            <div className="d-flex justify-content-center">
              <Button
                className="p-3 mr-5"
                variant={this.state.petitQuestionnaire ? "dark" : "light"}
                onClick={() => this.setState({ petitQuestionnaire: true })}
              >
                {"Petit questionnaire\xa0"}
                {this.state.petitQuestionnaire &&
                  <CheckIcon size={18} />
                }
              </Button>
              <Button
                className="p-3 ml-5"
                variant={this.state.petitQuestionnaire ? "light" : "dark"}
                onClick={() => this.setState({ petitQuestionnaire: false })}
              >
                {"Commentaires & questions\xa0"}
                {!this.state.petitQuestionnaire &&
                  <CheckIcon size={18} />
                }
              </Button>
            </div>

            {this.state.petitQuestionnaire &&
              <div>
                <div className="mt-5">
                  Sur une échelle de 0 à 10, SPOT vous permet-il de mieux comprendre les débats de société ?
                </div>
                <ComponentGrilleDeNotes
                  markID="markNumb1"
                  markValue={this.state.markNumb1}
                  changeMark={this.changeMark}
                />

                <div className="mt-5">
                  Sur une échelle de 0 à 10, SPOT vous permet-il de vous faire un avis sur les débats de société ?
                </div>
                <ComponentGrilleDeNotes
                  markID="markNumb2"
                  markValue={this.state.markNumb2}
                  changeMark={this.changeMark}
                />

                <div className="mt-5">
                  Avez-vous un commentaire à nous partager ?
                </div>
                <textarea
                  className="mt-3 form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  // value={this.state.commentaire}
                  onChange={(e) => this.setState({ commentaire: e.target.value })}
                >
                </textarea>

              </div>
            }

            <div className="d-flex flex-row align-items-start pl-3 pt-0 mt-5 ">
              <p className="mt-1 ">Acceptez-vous que l'on vous recontacte ?</p>
              <Button
                className="mt-0 ml-3 mr-2 "
                variant={this.state.recontactAccept === "oui" ? "dark" : "light"}
                onClick={() => this.setState({ recontactAccept: "oui" })}
              >
                Oui
                {this.state.recontactAccept === "oui" &&
                  <CheckIcon size={18} />
                }
              </Button>
              <Button
                className="ml-2"
                variant={this.state.recontactAccept === "non" ? "dark" : "light"}
                onClick={() => this.setState({ recontactAccept: "non" })}
              >
                Non
                {this.state.recontactAccept === "non" &&
                  <CheckIcon size={18} />
                }
              </Button>

              {this.state.recontactAccept === "oui" &&
                <Form.Group className="d-flex flex-row  col-lg-6 ml-5" controlId="formBasicEmail">
                  <Form.Label className="col-lg-4 m-0 pt-2">Votre adresse e-mail</Form.Label>
                  <Form.Control
                    className="col-lg-8"
                    type="email"
                    placeholder="Enter email"
                    onChange={(e) => this.setState({ emailContact: e.target.value })}
                  />
                  <Form.Text className="col-lg-12 text-muted text-end ">
                    Nous ne communiquerons jamais votre adresse e-mail à quiconque.
                </Form.Text>
                </Form.Group>
              }


            </div>

            <div className="d-flex flex-row justify-content-center mt-5 ">

              {!this.state.successButtonShow &&
                <Button
                  className="pl-3 pr-3"
                  variant="dark"
                  onClick={() => this.sendEmail()}
                >
                  Envoyer
              </Button>
              }

              {this.state.successButtonShow &&
                <div className="d-flex flex-row justify-content-center">
                  <p className="messageEnvoye">
                    Questionnaire envoyé, merci !
                </p>
                  <span className="ml-3" style={{ color: "#58d68d" }}><CheckIcon size={18} /></span>

                </div>
              }

            </div>


          </Modal.Body>

        </Modal>

      </div >

    )
  }

}
export default ComponentFormulaireQuestionnaire