import React, { Component } from 'react';
import { CircleIcon, CheckCircleFillIcon } from '@primer/octicons-react'

import 'rc-slider/assets/index.css';

import '../full.css';

import $ from "jquery"

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

// Autres components
import ComponentReference from './ComponentReference'
import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'


class ModalAlert extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
  }

  state = {

    show: false,
    showConsoleLog: true,

  }

  componentDidMount() {
  }


  componentDidUpdate() {

  }



  render() {


    return (

      <div className="">

        <Button
          variant=""
          className="p-2 pl-3 pr-3 whiteButtonHover modifierInformations "
          style={{ 'height': '40px' }}
          onClick={() => this.setState({ show: true })}
        >
          Discuter
        </Button>

        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Work on progress ! </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-3">L'espace de discussion est en cours de développement 🙏</div >
            <div className="mt-3 mb-3">Cet espace permettra aux contributeurs d'échanger, de se comprendre et de faire des choix concertés.</div >
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.setState({ show: false })} >
              OK
            </Button>
          </Modal.Footer>
        </Modal >

      </div >

    )
  }

}
export default ModalAlert