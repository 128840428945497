import React, { Component } from 'react';

import ModalWindowHelp from './ModalWindow_Help';

import ProgressBar from 'react-bootstrap/ProgressBar';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import '../full.css';


class ComponentDebate extends Component {

  state = {
    hovered: false,
    hovered3: false,
    hovered4: false,

    showConsoleLog: true,
  }



  whitePicture(t_photo) {
    if (t_photo === null) {
      return ""
    } else {
      // this.props.showConsoleLog && console.log(t_photo)
      // this.props.showConsoleLog && console.log(t_photo.replace('.svg','_blanc.svg'))
      // return t_photo.replace('.svg','_opaque.svg')
      return t_photo.replace('.svg', '_opaque.svg')
    }
  }

  getHeight(texteExplicatif) {
    var elem = document.getElementById(texteExplicatif)
    if (elem !== null) {
      this.props.showConsoleLog && console.log('ComponentDebate -> index elem.clientHeight', elem.clientHeight)
      return (Math.floor(elem.clientHeight / 24))
    }
  }

  getProgressBarColor(completude) {
    if (completude > 70) {
      return 'success'
    } else if (completude > 50) {
      return 'warning'
    } else {
      return 'danger'
    }
  }



  render() {

    this.props.showConsoleLog && console.log('ComponentDebate -> index : ', `componentDebateTitre_${this.props.quote.idquote}`)

    this.props.showConsoleLog && console.log('ComponentDebate -> quote : ', this.props.quote)


    const popoverCompletude = (
      <Popover id="popover-basic" className="tooltipStyle">
        <Popover.Header as="h3" className="tooltipStyle text-center">
          <div className="d-flex flex-row justify-content-center">
            <p className="mb-0 pb-0">Complétude</p>
            <div className="d-flex ml-3 mb-0 pb-0 ">
              <ModalWindowHelp
                pageName='Home'
                helpType='Complétude'

                url_image={this.props.url_image}
              />
            </div>
          </div>
        </Popover.Header>
        <Popover.Body >
          <p className="tooltipText mt-1 mb-0"><strong>Partie "Pour comprendre" : </strong> {this.props.quote.completude + 7}% </p>
          <p className="tooltipText mt-1 mb-0"><strong>Partie "Qui pense quoi ?" : </strong> {Math.max(0, this.props.quote.completude - 5)}% </p>
          <p className="tooltipText mt-1 mb-0"><strong>Partie "Les arguments" : </strong> {Math.max(0, this.props.quote.completude - 2)}% </p>
        </Popover.Body>

      </Popover>
    )


    return (
      <div
        className={this.props.homePageAffichageMozaique ? "d-flex align-items-stretch col-lg-12 m-0 mb-2 p-0  " : "col-lg-12 "}
        
      >


        {this.props.homePageAffichageMozaique &&
          <div
            className="d-flex flex-row justify-content-center align-items-start col-lg-11 pt-3 pb-3 pl-0 pr-0 componentShadow "
            style={{ position: 'relative', zIndex: '40', height: '100%' }}
            onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}
          >

              {/* 1ère colonne : Vignette image + filtre dégradé + icônes  */}
              <div className="d-flex flex-column w-100 justify-content-center m-0 p-0  " >

                {false &&
                  <strong className="textEnteteComponent text-center mb-2 " >
                    {this.props.quote.q_placeScaleID === 1 ? this.props.quote.ci_name :
                      (this.props.quote.q_placeScaleID === 2 ? this.props.quote.de_name :
                        (this.props.quote.q_placeScaleID === 3 ? this.props.quote.re_name :
                          (this.props.quote.q_placeScaleID === 4 ? "Toute la France" :
                            (this.props.quote.q_placeScaleID === 5 ? "Union Européenne" :
                              (this.props.quote.q_placeScaleID === 6 ? "Monde" : " ")))))}
                  </strong>
                }

                <a
                  href={'/citations_2/' + this.props.quote.idquote}
                  className="d-flex flex-row w-100 m-0 pt-0 pb-2 pl-3 pr-3 justify-content-center "
                >
                  <div className="row w-100 " style={{ position: 'relative', }}>

                    <img
                      className="w-100 rounded border"
                      src={this.props.url_image + (this.props.quote.q_photo === "undefined" ? "Photo0.jpg" : this.props.quote.q_photo)}
                      alt=""
                      style={{
                        height: this.props.format === "Small" ? '160px' : '210px',
                        width: '100%',
                        filter: ((this.state.hovered || this.state.hovered3) ? 'none' : 'grayscale(1)'),

                      }}
                    />

                    {/* Filtre dégradé */}
                    <div
                      className="w-100 rounded debatPhotoFiltre "
                      style={{
                        position: 'absolute',
                        height: this.props.format === "Small" ? '160px' : '210px',

                      }}
                    >
                    </div>

                    {/* Affichage des icônes */}
                    {false &&
                      this.props.quote.themesList !== undefined &&
                      <div
                        className={"row w-100 ml-0 justify-content-start pt-2 ".concat(this.props.format === "Small" ? "pb-1 pl-2" : "pb-2")}
                        // href={'/citations_2/' + this.props.quote.idquote}
                        onMouseEnter={() => this.setState({ hovered3: true })}
                        onMouseLeave={() => this.setState({ hovered3: false })}
                        style={{ "textDecoration": "none", position: 'relative', bottom: 0 }}
                      >
                        {this.props.quote.themesList
                          .filter(({ t_photo }) => t_photo !== null)
                          .map(({ t_title, t_photo }, index) => (
                            <div key={`pictureTheme_${index}`} className={"d-flex flex-column col-3 col-lg-3 p-0  ".concat(this.props.format === "Small" ? "" : " ")}>
                              <img
                                className="listeThemesComponentDebate "
                                src={this.props.url_image + this.whitePicture(t_photo)}
                                alt="listeThemesComponentDebate "
                                style={{ height: this.props.format === "Small" ? '35px' : '50px' }}
                              >
                              </img>
                              <p
                                className="w-100 debatPhotoTexteBlanc text-center mb-0 "
                                style={{
                                  width: this.props.format === "Small" ? '55px' : '62px',
                                  color: "#000",
                                  fontSize: this.props.format === "Small" ? '10px' : '11px',
                                }}
                              >
                                {t_title}
                              </p>
                            </div>
                          ))
                        }

                      </div>
                    }

                  </div>
                </a>



                {/* Titre*/}
                <div
                  id={`componentDebateTitreMozaique_${this.props.quote.idquote}`}
                  className={"d-flex flex-row m-0 p-0 "}
                >
                  <a
                    className={"text-left debat_filAriane_text1 textBlack2 m-0 p-2 pl-3 pr-3 col-lg-12 "}
                    href={'/citations_2/' + this.props.quote.idquote}
                  >
                    <strong className="">

                      <div className="textGrey debatMozaique text-center">
                        {'\u00ab\xa0' + this.props.titreIntro + '\xa0...'}
                      </div>

                      <div className="d-flex flex-row ">
                        <div className="debatMozaique text-left ">
                          {this.props.quote.q_title + '\xa0\u00bb'}
                        </div>
                      </div>

                    </strong>

                  </a> {/* + replaceColor(this.props.quote.themesList[0].t_title) */}
                </div>


                {false && // Barre de complétude
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    overlay={popoverCompletude}
                    rootClose={true}
                  >
                    <button
                      className="whiteButton mt-1 ml-1"
                      onMouseEnter={() => this.setState({ hovered4: true })}
                      onMouseLeave={() => this.setState({ hovered4: false })}
                      style={{ position: 'relative', 'zIndex': '60', height: '14px' }}
                    >
                      <ProgressBar
                        variant={this.state.hovered4 ? this.getProgressBarColor(this.props.quote.completude) : 'secondary'}
                        style={{ height: this.state.hovered4 ? '14px' : '9px' }}
                        now={this.props.quote.completude}
                        label={this.state.hovered4 ? (this.props.quote.completude < 40 ? `${this.props.quote.completude}%` : `Complétude : ${this.props.quote.completude}%`) : ''}
                      />
                    </button>
                  </OverlayTrigger>
                }

              </div>


          </div>
        }

      </div>
    )
  }
}


export default ComponentDebate