import React, { Component } from 'react';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

//Elements API
// import { ApiClient, HistoryApi } from 'spot_api_old'

import { RocketIcon } from '@primer/octicons-react'


class ModalWindowHistorique extends Component {

  state = {
    show: false,
    quoteFullHistoryRaw: [],
    quoteFullHistoryTable: [],

    showConsoleLog: true,
  }

  componentDidMount() {

  }


  componentDidUpdate() {

  }


  HistoryIdAPIs(quoteID) {
    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.HistoryApi();

    this.props.showConsoleLog && console.log('HistoryIdAPIs -> quoteListIDHistorique : ', this.props.quoteListIDHistorique)

    this.props.showConsoleLog && console.log('HistoryIdAPIs -> quoteID : ', quoteID)

    apiInstance.historyGet(quoteID, (error, data2, response) => {
      this.props.showConsoleLog && console.log('HistoryIdAPIs -> data2 : ', data2)

      this.setState({ quoteFullHistoryRaw: data2 })

      this.buildHistoryTable(data2)

    })

  }

  buildHistoryTable = (quoteFullHistoryRaw) => {

    let returnHistory = []
    let newHistoryLine = {}

    let previousPage = {}
    let differenceListKeys = []

    let quoteFullHistoryRawReversed = [...quoteFullHistoryRaw]
    let reversed = quoteFullHistoryRawReversed.reverse()

    let themesAlreadyProcessed = []

    this.props.showConsoleLog && console.log('buildHistoryTable -> quoteFullHistoryRawReversed : ', quoteFullHistoryRawReversed)

    quoteFullHistoryRawReversed.forEach((pageChange, index) => {

      // changeFamily : création du débat, ajout, suppression

      let quoteChange = pageChange.quote
      let argumentChange = pageChange.arguments
      let commentateurChange = pageChange.commentateurs


      // Traitement de l'historique de "quote"

      if (quoteChange.idquote === quoteChange.q_originID) {
        newHistoryLine = { dateRaw: '', datePrint: '', user: '', changedPart: '', changeFamily: '', details: '' }
        newHistoryLine.changedPart = "-"
        newHistoryLine.changeFamily = "création du débat"
        newHistoryLine.datePrint = this.props.prettyDate_h_mn_sec(quoteChange.q_creationDate)
        newHistoryLine.details = "Titre du débat : ".concat(this.props.titreDebat(quoteChange, this.props.natureInformationTable), '.')
        newHistoryLine.dateRaw = quoteChange.q_creationDate

        returnHistory.push(newHistoryLine)
      }

      // Vérification s'il y a des changements dans quote (à l'exception des thèmes)
      Object.keys(quoteChange)
        .filter(quoteKey => quoteKey !== 'q_creationDate' && quoteKey !== 'idquote' && quoteKey !== 'themes')
        .forEach((quoteKey) => {
          this.props.showConsoleLog && console.log('buildHistoryTable -> quoteKey : ', quoteKey)
          this.props.showConsoleLog && console.log('buildHistoryTable -> quoteKey :  --->', quoteChange[quoteKey])
          this.props.showConsoleLog && console.log('buildHistoryTable -> quoteKey :  --->', previousPage.quote[quoteKey])
          this.props.showConsoleLog && console.log('buildHistoryTable -> quoteKey :  COND', quoteChange[quoteKey] !== previousPage.quote[quoteKey])

          if (previousPage.quote !== undefined && quoteChange[quoteKey] !== previousPage.quote[quoteKey]) { // Si valeurs différentes
            differenceListKeys.push(quoteKey)

            if (quoteKey === 'q_title') { // Track les différences de titre
              newHistoryLine = { dateRaw: '', datePrint: '', user: '', changedPart: '', changeFamily: '', details: '' }
              newHistoryLine.changedPart = "Titre"
              newHistoryLine.changeFamily = "Changement du titre"
              newHistoryLine.datePrint = this.props.prettyDate_h_mn_sec(quoteChange.q_creationDate)
              newHistoryLine.dateRaw = quoteChange.q_creationDate

              newHistoryLine.details =
                <div>
                  {this.props.showConsoleLog && console.log('buildHistoryTable -> compare titles : ', this.findDiff(previousPage.quote[quoteKey], quoteChange[quoteKey]))}
                  <div>{"Ancien titre : ".concat(this.props.titreDebat(previousPage.quote, this.props.natureInformationTable), '.')}</div>
                  <div>{'Nouveau titre : '.concat(this.props.titreDebat(quoteChange, this.props.natureInformationTable), '.')}</div>
                </div>

              returnHistory.push(newHistoryLine)
            }
          }

        })

      // Vérification s'il y a des changements de thèmes
      this.props.showConsoleLog && console.log('buildHistoryTable -> thèmes pageChange : ', quoteChange.themesList)
      this.props.showConsoleLog && console.log('buildHistoryTable -> thèmes previousPage : ', previousPage.quote.themesList)
      this.props.showConsoleLog && console.log('buildHistoryTable -> thèmes COND : ', JSON.stringify(quoteChange.themesList) !== JSON.stringify(previousPage.quote.themesList))

      if (quoteChange.themesList.length > 0) {
        let themes = quoteChange.themesList

        themes.forEach((element) => {
          if (!themesAlreadyProcessed.includes(element.quote_theme_id)) {
            themesAlreadyProcessed.push(element.quote_theme_id)

            if (element.qt_statut === 2) {

              newHistoryLine = { dateRaw: '', datePrint: '', user: '', changedPart: '', changeFamily: '', details: '' }
              newHistoryLine.changedPart = "Thèmes"
              newHistoryLine.changeFamily = "Suppression"
              newHistoryLine.datePrint = this.props.prettyDate_h_mn_sec(element.qt_deleteDate)
              newHistoryLine.dateRaw = element.qt_deleteDate
              newHistoryLine.details = <div>
                {"Suppression du thème : ".concat(element.t_title, '\xa0\xa0')}
                <img
                  className="rounded "
                  src={this.props.url_image + element.t_photo}
                  alt={element.t_title}
                  title={element.t_title}
                  style={{ height: '30px', objectFit: 'contain', border: 'none', borderWidth: "1px", borderColor: "#bdc3c7", margin: 0, padding: 0 }}  // height: index < 6 ? '70px' : '50px', objectFit: 'contain' 
                /></div>

              returnHistory.push(newHistoryLine)

            }

            this.props.showConsoleLog && console.log('buildHistoryTable -> thèmes Changement de thèmes ...')
            this.props.showConsoleLog && console.log('buildHistoryTable -> element : ', element)

            newHistoryLine = { dateRaw: '', datePrint: '', user: '', changedPart: '', changeFamily: '', details: '' }
            newHistoryLine.changedPart = "Thèmes"
            newHistoryLine.changeFamily = "Ajout"
            newHistoryLine.datePrint = this.props.prettyDate_h_mn_sec(element.qt_creationDate)
            newHistoryLine.dateRaw = element.qt_creationDate
            newHistoryLine.details = <div>
              {"Ajout du thème : ".concat(element.t_title, '\xa0\xa0')}
              <img
                className="rounded "
                src={this.props.url_image + element.t_photo}
                alt={element.t_title}
                title={element.t_title}
                style={{ height: '30px', objectFit: 'contain', border: 'none', borderWidth: "1px", borderColor: "#bdc3c7", margin: 0, padding: 0 }}  // height: index < 6 ? '70px' : '50px', objectFit: 'contain' 
              /></div>

            returnHistory.push(newHistoryLine)


          } else {
            this.props.showConsoleLog && console.log('buildHistoryTable -> thèmes Pas de changement de thèmes ...')
          }

        })

      }

      this.props.showConsoleLog && console.log('buildHistoryTable -> differenceListKeys : ', differenceListKeys)


      // Traitement de l'historique de "quote"

      previousPage = pageChange

    })

    this.props.showConsoleLog && console.log('buildHistoryTable -> returnHistory : ', returnHistory)

    returnHistory.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.dateRaw) - new Date(b.dateRaw);
    })

    this.props.showConsoleLog && console.log('buildHistoryTable -> returnHistory reordered : ', returnHistory)

    this.setState({ quoteFullHistoryTable: returnHistory })

  }


  findDiff(str1, str2) {
    let diff = "";
    str2.split('').forEach(function (val, i) {
      if (val != str1.charAt(i))
        diff += val;
    });
    return diff;
  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  replaceAll(string, search, replace) {
    let returnString = ""

    if (string !== null) {
      returnString = string.split(search).join(replace)
    }

    return returnString
  }


  render() {

    this.props.showConsoleLog && console.log("ModalWindowHistorique -> quote : ", this.props.quote)

    return (
      <div className="">
        <Button
          onClick={() => {
            this.setState({ show: true })
            this.HistoryIdAPIs(this.props.quote.idquote)
          }}
          className="p-2 pl-3 pr-3 whiteButtonHover modifierInformations "
          style={{ 'height': '40px' }}
        >
          Voir l'historique
        </Button>

        <Modal
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        // centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{'Historique du débat : \u00ab ' + this.capitalizeFirstLetter(this.props.quote.q_title) + ' \u00bb'}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div>
              <Table striped bordered hover size="sm" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <thead className="robotoFontHistorique">
                  <tr>
                    <th className="align-middle robotoFontHistoriqueTitres" style={{ width: '6%' }}>#</th>
                    <th className="align-middle robotoFontHistoriqueTitres" style={{ width: '15%' }}>Date</th>
                    {/* <th className="align-middle robotoFontHistoriqueTitres" style={{ width: '11%' }}>Utilisateur</th>*/}
                    <th className="align-middle robotoFontHistoriqueTitres" style={{ width: '15%' }}>Type de contribution</th>
                    <th className="align-middle robotoFontHistoriqueTitres" style={{ width: '10%' }}>Partie modifiée</th>
                    <th className="align-middle robotoFontHistoriqueTitres" style={{}}>Détails</th>
                    {/* <th className="align-middle " style={{ width: '5%' }}>Voir</th> */}
                  </tr>
                </thead>
                <tbody>
                  {this.state.quoteFullHistoryTable.map((element, index) => (

                    <tr key={`historyTableRow_${index}`}>
                      <td className="align-middle robotoFontHistoriqueContenu2" >{index + 1}</td>
                      <td className="align-middle robotoFontHistoriqueContenu2" >{element.datePrint}</td>
                      {/* <td className="align-middle robotoFontHistoriqueContenu2" >{element.user}</td>*/}
                      <td className="align-middle robotoFontHistoriqueContenu2" >{this.capitalizeFirstLetter(element.changeFamily)}</td>
                      <td className="align-middle robotoFontHistoriqueContenu2" >{element.changedPart}</td>
                      <td className="align-middle robotoFontHistoriqueContenu2" >{element.details}</td>
                      {/* <th className="align-middle " style={{ width: '5%' }}>Voir</th> */}
                    </tr>

                  ))}

                </tbody>

              </Table>
            </div>

          </Modal.Body>

          <Modal.Footer>

            <div>
              <Button variant="primary" className="btn btn-secondary" onClick={() => this.setState({ show: false })}>OK</Button>
            </div>


          </Modal.Footer>

        </Modal>
      </div >
    )
  }
}


export default ModalWindowHistorique
