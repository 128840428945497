import React, { Component } from 'react';

import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";

import ComponentChoixNature from './ComponentChoixNature';
import Select from 'react-select';

import { CheckCircleIcon, TypographyIcon, ChevronUpIcon, ChevronDownIcon, XCircleIcon, IssueClosedIcon, CheckIcon } from '@primer/octicons-react'

import { Button, ButtonGroup, InputGroup, DropdownButton, Dropdown, Form, FormControl, FormCheck } from "react-bootstrap"


import '../full.css';
import "react-datepicker/dist/react-datepicker.css";



registerLocale('fr', fr)

// const ComponentArgument = ({idArgument, titre, texte, cnature, datePublication, dateDernieremodif, completude, thématique, sujet}) => (
class ComponentDebatsModalites extends Component {

  state = {

    showConsoleLog: true,

    quandTextBool: true,
    ouTextBool: true,

    displayAll: false,
    displayCombien: false,
    displayOu: false,
    displayQuand: false,
    displayQui: false,

    initialisation: false,

  }




  render() {

    const selectedScaleID = this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue

    const echelleSelectionnee = "" // this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.echellesGeographiquesInformationTable, 'id', selectedScaleID, 'echelleInformation')

    const departmentsList = [] // this.props.transformDepartementArrayToSelectObject(this.props.departmentsList.filter(element => element.de_code !== "99" && element.de_code !== "997" && element.de_code !== "998"), 'departments')
    const regionsList = [] // this.props.transformDepartementArrayToSelectObject(this.props.regionsList, 'regions')
    const citiesList = [] // this.props.transformVilleArrayToSelectObject(this.props.citiesList)

    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> quoteModalities :", this.props.quoteModalities)
    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> citiesList :", citiesList)

    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> quoteModalitiesSelected :", this.props.quoteModalitiesSelected)

    const displayCombien = this.props.quoteModalitiesSelected[2].displayElement || this.props.quoteModalitiesSelected[2].qm_modalityContent !== ""
    const displayOu = this.props.quoteModalitiesSelected[1].displayElement || this.props.quoteModalitiesSelected[1].qm_modalityContent !== ""
    const displayQuand = this.props.quoteModalitiesSelected[0].displayElement || this.props.quoteModalitiesSelected[0].qm_modalityContent !== ""
    const displayQui = this.props.quoteModalitiesSelected[3].displayElement || this.props.quoteModalitiesSelected[3].qm_modalityContent !== ""
    const displayComment = this.props.quoteModalitiesSelected[4].displayElement || this.props.quoteModalitiesSelected[4].qm_modalityContent !== ""

    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> displayCombien :", displayCombien)
    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> displayCombien sub 1 :", this.props.quoteModalitiesSelected[2].displayElement)
    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> displayCombien sub 2 :", this.props.quoteModalitiesSelected[2].qm_modalityContent !== "")

    const modalitiesCombien = this.props.quoteModalities.filter(element => element.mo_title === "combien")
    const modalitiesOu = this.props.quoteModalities.filter(element => element.mo_title === "où")
    const modalitiesQuand = this.props.quoteModalities.filter(element => element.mo_title === "quand")
    const modalitiesQui = this.props.quoteModalities.filter(element => element.mo_title === "pour qui")
    const modalitiesComment = this.props.quoteModalities.filter(element => element.mo_title === "comment")

    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> modalitiesOu :", modalitiesOu)
    this.props.showConsoleLog && console.log("ComponentDebatsModalites -> modalitiesQuand :", modalitiesQuand)

    { this.props.showConsoleLog && console.log("ComponentDebatsModalites -> quoteModalitiesSelected[1] : ", this.props.quoteModalitiesSelected[1]) }
    { this.props.showConsoleLog && console.log("ComponentDebatsModalites -> quoteModalitiesSelected[2] : ", this.props.quoteModalitiesSelected[2]) }

    return (
      <div className="d-flex flex-column col-lg-12 m-0 p-0 ">

        <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

          <div className="d-flex flex-column col-lg-11 m-0 p-3 rounded border">

            <div>Sélectionner le type de précision :</div>

            <div className="d-flex flex-row justify-content-around mt-3 ">
              <Button
                variant="light"
                className={"fontDonneesImportantesTitre col-lg-2 border ".concat(displayCombien ? "titre_combien" : "")}
                onClick={() => this.props.handleChangeModality('combien', "displayElement", !this.props.quoteModalitiesSelected[2].displayElement)}
              >
                Combien ?
              </Button>
              <Button
                variant="light"
                className={" fontDonneesImportantesTitre col-lg-2 border ".concat(displayOu ? "titre_ou" : "")}
                onClick={() => this.props.handleChangeModality('où', "displayElement", !this.props.quoteModalitiesSelected[1].displayElement)}
              >
                Où ?
              </Button>
              <Button
                variant="light"
                className={" fontDonneesImportantesTitre col-lg-2 border ".concat(displayQuand ? "titre_quand" : "")}
                onClick={() => this.props.handleChangeModality('quand', "displayElement", !this.props.quoteModalitiesSelected[0].displayElement)}
              >
                Quand ?
              </Button>
              <Button
                variant="light"
                className={" fontDonneesImportantesTitre col-lg-2 border ".concat(displayQui ? "titre_pourQui" : "")}
                onClick={() => this.props.handleChangeModality('pour qui', "displayElement", !this.props.quoteModalitiesSelected[3].displayElement)}
              >
                Pour qui ?
              </Button>
              <Button
                variant="light"
                className={" fontDonneesImportantesTitre col-lg-2 border ".concat(displayComment ? "titre_comment" : "")}
                onClick={() => this.props.handleChangeModality('comment', "displayElement", !this.props.quoteModalitiesSelected[4].displayElement)}
              >
                Comment ?
              </Button>

            </div>
          </div>

        </div>


        <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

          <div className="d-flex flex-column col-lg-11 m-0 p-3 rounded border">

            {/* Champ 'combien' */}
            {(this.state.displayAll || displayCombien) &&
              <div className={"d-flex flex-column align-items-center mb-1 "}>

                <div className="w-100 titre_combien fontDonneesImportantesTitre rounded" id="">
                  <span
                    className="mr-auto"
                    style={{ color: this.props.quote.q_combien === "-1" ? ' #b2babb ' : '' }}
                  >
                    Combien ?
                  </span>
                </div>

                {modalitiesCombien.length > 0 &&
                  <div className="d-flex flex-column w-100 ">

                    <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                      Les quantifications déjà renseignées pour ce débat :
                    </div>
                    <div className="d-flex justify-content-center ">
                      <ButtonGroup vertical className="col-lg-6 m-0 p-0  ">
                        {modalitiesCombien.map((element, index) => (
                          <Button
                            key={`modalitiesCombien_${index}`}
                            className={"robotoFont14px mt-1 ".concat(this.props.quoteModalitiesSelected[2].id_quoteModality === element.id_quoteModality ? "activeButton" : "notActiveButton")}
                            onClick={() => this.props.handleChangeModality('combien', "id_quoteModality", this.props.quoteModalitiesSelected[2].id_quoteModality !== element.id_quoteModality ? element.id_quoteModality : 0)}
                          >
                            {element.qm_modalityContent}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </div>
                }

                <div className="d-flex flex-column w-100 ">

                  <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                    {modalitiesCombien.length === 0 ?
                      'Préciser "combien" : '
                      :
                      "Vous ne trouvez pas la quantification que vous cherchez dans la liste ci-dessus ? Ajoutez-en une :"}
                  </div>
                  <div className="d-flex flex-row col-lg-12 justify-content-center mt-3 ">
                    <input
                      type="text"
                      className="form-control text-left placeHolderCss col-lg-8 "
                      id="infoTitreCombien"
                      placeholder="ex : augmenter de 25% ; diminuer de 80 000 fonctionnaires etc." // "ex : NC (Non Concerné)"
                      value={this.props.quoteModalitiesSelected[2].qm_modalityContent}
                      onChange={e => this.props.handleChangeModality('combien', "qm_modalityContent", e.target.value)}
                      disabled={this.props.quoteModalitiesSelected[2].id_quoteModality > 0}
                      style={{ color: this.props.quote.q_combien === "-1" ? ' #b2babb ' : '' }}
                    />
                  </div>

                </div>

                {false &&
                  <div className="input-group-append d-flex flex-row align-items-center p-1 m-0 ">
                    <div className="input-group-text p-1 pr-2 m-0 ">
                      <FormCheck className="d-flex justify-content-end align-items-center w-100 p-0 m-0 pr-2 rounded">
                        <FormCheck.Input
                          id="checkBoxCombien"
                          type="checkbox"
                          className=""
                          checked={this.props.quote.q_combien === "-1"}
                          onChange={() => this.props.handleChangeModality('q_combien', "qm_modalityContent", this.props.quote.q_combien === "-1" ? "" : "-1")}
                        />
                        <FormCheck.Label className="col-lg-6 pl-1 pr-1 rounded" htmlFor="checkBoxCombien" style={{ color: this.props.quote.q_combien === "-1" ? ' #b2babb ' : '' }} title="Non concerné">NC</FormCheck.Label>
                      </FormCheck>
                    </div>
                    <div className="" style={{ width: "35px", color: '#40af2c' }}>
                      {(this.props.quote.q_combien === "-1" || this.props.quote.q_combien.length > 3) &&
                        <CheckCircleIcon size={20} className="ml-2" />
                      }
                    </div>
                  </div>
                }

              </div>
            }



            {/* Champ 'où' */}
            {(this.state.displayAll || displayOu) &&
              <div className={"d-flex flex-column align-items-center mt-4 mb-1"}>

                <div className="d-flex flex-row w-100 titre_ou fontDonneesImportantesTitre rounded" id="">
                  <span
                    className="mr-auto"
                    style={{ color: this.props.quote.q_ou === "-1" ? ' #b2babb ' : '' }}
                  >
                    Où ?
                  </span>
                </div>


                {modalitiesOu.length > 0 &&
                  <div className="d-flex flex-column w-100 ">

                    <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                      Les lieux déjà renseignés pour ce débat :
                    </div>

                    <div className="d-flex justify-content-center ">
                      <ButtonGroup vertical className="col-lg-6 m-0 p-0  ">
                        {modalitiesOu.map((element, index) => (
                          <Button
                            key={`modalitiesOu_${index}`}
                            className={"robotoFont14px mt-1 ".concat(this.props.quoteModalitiesSelected[1].id_quoteModality === element.id_quoteModality ? "activeButton" : "notActiveButton")}
                            onClick={() => this.props.handleChangeModality('où', "id_quoteModality", this.props.quoteModalitiesSelected[1].id_quoteModality !== element.id_quoteModality ? element.id_quoteModality : 0)}
                          >
                            {element.qm_modalityContent}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>

                  </div>
                }


                {this.props.showConsoleLog && console.log("ComponentDebatsModalites -> echelleDebatSelectionneValue : ", this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue)}
                {this.props.showConsoleLog && console.log("ComponentDebatsModalites -> echelleInformation : ", echelleSelectionnee)}

                <div className="d-flex flex-row justify-content-center w-100 m-0 p-0 ">

                  {false &&
                    <Button
                      variant="outline-danger"
                      className="m-0 pl-1 pr-1 pt-0 pb-0 whiteButton"
                      onClick={() => {
                        this.setState({ ouTextBool: !this.state.ouTextBool })
                        this.props.handleChangeModality('où', "init", "")
                      }}
                    >
                      <TypographyIcon size={20} />
                    </Button>
                  }

                  {this.state.ouTextBool &&
                    <div className="d-flex flex-column w-100 ">

                      <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                        {modalitiesOu.length === 0 ?
                          'Préciser un lieu : '
                          :
                          "Vous ne trouvez pas le lieu que vous cherchez dans la liste ci-dessus ? Ajoutez-en un :"}
                      </div>
                      <div className="d-flex flex-row col-lg-12 justify-content-center mt-3 ">
                        <input
                          type="text"
                          className="input-group form-control text-left placeHolderCss col-lg-8 "
                          id="infoTitreOu"
                          placeholder="ex : dans les marchés extérieurs"
                          value={this.props.quoteModalitiesSelected[1].qm_modalityContent}
                          onChange={e => this.props.handleChangeModality('où', "qm_modalityContent", e.target.value)}
                          disabled={this.props.quoteModalitiesSelected[1].id_quoteModality > 0}
                          style={{ color: this.props.quote.q_ou === "-1" ? ' #b2babb ' : '' }}
                        />
                      </div>

                    </div>

                  }

                  {/* Menu déroulant type de lieu */}
                  {!this.state.ouTextBool &&
                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={
                        (this.props.quoteModalitiesSelected[1].qm_modalityContent === "" &&
                          this.props.quoteModalitiesSelected[1].qm_modalityContentIntermediary === "") ?
                          "Type de lieu\xa0" : ([1, 2, 3].includes(this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue) ?
                            this.props.quoteModalitiesSelected[1].qm_modalityContentIntermediary :
                            this.props.quoteModalitiesSelected[1].qm_modalityContent)
                      }
                      id="input-group-dropdown-dateType"
                    >
                      <Dropdown.Header>Type de lieu</Dropdown.Header>

                      {this.props.echellesGeographiquesInformationTable.map((element, index) => (
                        <Dropdown.Item
                          key={`echellesGeographiquesInformationv2_${index}`}
                          onClick={() => this.props.selectDebatEchelle1(element.id)}
                        // active={element.title === quote.q_vd_timeFormat}
                        >
                          {element.echelleInformation}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  }



                  {this.props.showConsoleLog && console.log("ComponentDebatsModalites -> echelleDebatSelectionneValue : ", this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue)}

                  {!this.state.ouTextBool &&
                    [1, 2, 3].includes(this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue) &&
                    <div className="col-lg-6 m-0 p-0 pl-1" style={{ position: 'relative', zIndex: 80 }}>
                      <Select
                        value={{
                          value: this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue,
                          label: this.props.quoteModalitiesSelected[1].qm_modalityContent !== "" ?
                            this.props.quoteModalitiesSelected[1].qm_modalityContent :
                            (this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 1 ? "Sélectionner une ville" :
                              (this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 2 ? "Sélectionner un département" :
                                (this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 3 ? "Sélectionner une région" : "")))
                        }}
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={this.state.listeVillesValeur0.name}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        name="color2"
                        options={
                          this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 1 ?
                            citiesList :
                            (this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 2 ?
                              departmentsList :
                              regionsList)
                        }
                        onInputChange={this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 1 ? this.props.handleInputChange : ""}
                        getOptionLabel={option => `${option.label}`.concat(option.codePostal !== undefined ? `\xa0(${option.codePostal})` : "")}
                        onChange={this.props.selectDebatEchelle2}
                        placeholder={this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 1 ? "-- Sélectionner une ville --" : this.props.quoteModalitiesSelected[1].echelleDebatSelectionneValue === 2 ? "-- Sélectionner un département --" : "-- Sélectionner une région --"}
                      />
                    </div>
                  }

                  {echelleSelectionnee === "Autre" &&
                    <input
                      type="text"
                      className="input-group form-control text-left placeHolderCss"
                      id="infoTitreOu"
                      placeholder="ex : dans les marchés extérieurs"
                      value={this.props.quote.q_ou === "-1" ? "Non concerné" : this.props.quote.q_ou}
                      onChange={e => this.props.handleChangeModality('q_ou', "qm_modalityContent", e.target.value)}
                      disabled={this.props.quote.q_ou === "-1"}
                      style={{ color: this.props.quote.q_ou === "-1" ? ' #b2babb ' : '' }}
                    />
                  }

                </div>

              </div>
            }


            {/* Champ 'quand' sous format calendars */}
            {(this.state.displayAll || displayQuand) &&
              <div className={"d-flex flex-column align-items-center mt-4 mb-1 "}>

                <div className="d-flex flex-column col-lg-12 input-group mb-1 p-0">

                  <InputGroup >

                    <div className="d-flex flex-column col-lg-12 m-0 p-0 ">

                      <div className={"w-100 fontDonneesImportantesTitre titre_quand rounded"} id="">
                        <span
                          className="mr-auto "
                        // style={{ color: quote.q_quand === "-1" ? ' #b2babb ' : '' }}
                        >
                          Quand ?
                        </span>
                      </div>

                      {this.props.showConsoleLog && console.log("ComponentDebatsModalites -> quoteModalitiesSelected :", this.props.quoteModalitiesSelected)}

                      {modalitiesQuand.length > 0 &&
                        <div className="d-flex flex-column ">
                          <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>Les périodes déjà renseignées pour ce débat :</div>
                          <div className="d-flex flex-row justify-content-center ">

                            <div className="col-lg-6 m-0 p-0 ">
                              <ButtonGroup vertical className="w-100 ">
                                {modalitiesQuand.map((element, index) => (
                                  <Button
                                    key={`modalitiesQuand_${index}`}
                                    className={"robotoFont14px mt-1 ".concat(this.props.quoteModalitiesSelected[0].id_quoteModality === element.id_quoteModality ? "activeButton" : "notActiveButton")}
                                    onClick={() => this.props.handleChangeModality('quand', "id_quoteModality", this.props.quoteModalitiesSelected[0].id_quoteModality !== element.id_quoteModality ? element.id_quoteModality : 0)}
                                  >
                                    {element.qm_modalityContent}
                                  </Button>
                                ))}
                              </ButtonGroup>
                            </div>

                          </div>
                        </div>
                      }



                      <div className="d-flex flex-column ">

                        <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                          {modalitiesQuand.length === 0 ?
                            "Préciser la condition de temps : "
                            :
                            "Vous ne trouvez pas la période que vous cherchez dans la liste ci-dessus ? Ajoutez-en une :"}
                        </div>

                        <div className="d-flex flex-row justify-content-center ">

                          {false &&
                            <Button
                              variant="outline-danger"
                              className="m-0 pl-1 pr-1 pt-0 pb-0 whiteButton"
                              onClick={() => this.setState({ quandTextBool: !this.state.quandTextBool })}
                            >
                              <TypographyIcon size={20} />
                            </Button>
                          }

                          {this.state.quandTextBool &&
                            <div className="d-flex flex-row col-lg-8 mt-3 ">
                              <input
                                type="text"
                                className="input-group form-control text-left placeHolderCss"
                                id="infoTitreOu"
                                placeholder="ex : à partir de novembre 2022"
                                value={this.props.quoteModalitiesSelected[0].qm_modalityContent}
                                onChange={e => this.props.handleChangeModality('quand', "qm_modalityContent", e.target.value)}
                                disabled={this.props.quoteModalitiesSelected[0].id_quoteModality > 0}
                                style={{ color: this.props.quote.q_ou === "-1" ? ' #b2babb ' : '' }}
                              />
                            </div>

                          }


                          {!this.state.quandTextBool &&
                            <div className="d-flex flex-row ">

                              {/* Menu déroulant format de date : jj/mm/aaaa etc. */}
                              {this.props.quote.q_quand !== "-1" &&
                                <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="outline-secondary"
                                  title={this.props.quote.q_vd_timeFormat}
                                  id="input-group-dropdown-dateType"
                                >
                                  <Dropdown.Header>Format de la date</Dropdown.Header>

                                  {this.props.evenementTypeList.map((element, index) => (
                                    <Dropdown.Item
                                      key={`evenementTypeListv2_${index}`}
                                      onClick={e => this.props.handleChangeModality('q_vd_timeFormat', "qm_modalityContent", element)}
                                      active={element.title === this.props.quote.q_vd_timeFormat}
                                    >
                                      {element.title}
                                    </Dropdown.Item>
                                  ))}
                                </DropdownButton>
                              }


                              {/* Type d'intro */}
                              {this.props.quote.q_quand !== "-1" &&
                                <DropdownButton
                                  as={InputGroup.Prepend}
                                  variant="outline-secondary"
                                  title={this.props.quote.q_vd_timeIntro}
                                  id="input-group-dropdown-1"
                                >
                                  {this.props.dateTypeIntroAll
                                    .filter((element, index) => (element.timeScale === 'separator' ||
                                      element.timeScale === this.props.quote.q_vd_timeScale)
                                    )
                                    .map((element, index) => (
                                      element.timeScale === 'separator' ?
                                        <Dropdown.Header key={`timeScale_${index}`}>{element.title}</Dropdown.Header>
                                        :
                                        <Dropdown.Item
                                          key={`timeScale_${index}`}
                                          onClick={e => this.props.handleChangeModality('q_vd_timeIntro', "qm_modalityContent", element)}
                                          active={element.title === this.props.quote.q_vd_timeIntro}
                                        >
                                          {element.title}
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                              }


                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeFormat === 'hh:mm jj/mm/aaaa' &&
                                <DatePicker
                                  id="dateStart"
                                  locale="fr"
                                  className={this.props.quote.q_vd_DateBeg === '' ? "datePickerLarge pl-2 placeHolderCss" : "datePickerLarge placeHolderCss "}
                                  placeholderText={this.props.quote.q_vd_timeType === "durée" ? "Date début" : "Date"}
                                  selected={Date.parse(this.props.quote.q_vd_DateBeg)}
                                  onChange={this.props.handleChangeStartDate}
                                  maxDate={Date.parse(this.props.quote.q_vd_DateEnd)}
                                  showTimeSelect
                                  dateFormat="dd/MM/yyyy, HH:mm"
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                />
                              }

                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeFormat === 'jj/mm/aaaa' &&
                                <DatePicker
                                  id="dateStart"
                                  locale="fr"
                                  className={this.props.quote.q_vd_DateBeg === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                  placeholderText={this.props.quote.q_vd_timeType === "durée" ? "Date début" : "Date"}
                                  selected={Date.parse(this.props.quote.q_vd_DateBeg)}
                                  onChange={this.props.handleChangeStartDate}
                                  maxDate={Date.parse(this.props.quote.q_vd_DateEnd)}
                                  dateFormat="dd/MM/yyyy"
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                />
                              }
                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeFormat === 'mm/aaaa' &&
                                <DatePicker
                                  id="dateStart"
                                  locale="fr"
                                  className={this.props.quote.q_vd_DateBeg === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                  placeholderText={this.props.quote.q_vd_timeType === "durée" ? "Date début" : "Date"}
                                  selected={Date.parse(this.props.quote.q_vd_DateBeg)}
                                  onChange={this.props.handleChangeStartDate}
                                  maxDate={Date.parse(this.props.quote.q_vd_DateEnd)}
                                  dateFormat="MM/yyyy"
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  showMonthYearPicker
                                  showFullMonthYearPicker
                                />
                              }
                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeFormat === 'aaaa' &&
                                <DatePicker
                                  id="dateStart"
                                  locale="fr"
                                  className={this.props.quote.q_vd_DateBeg === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                  placeholderText={this.props.quote.q_vd_timeType === "durée" ? "Date début" : "Date"}
                                  selected={Date.parse(this.props.quote.q_vd_DateBeg)}
                                  onChange={this.props.handleChangeStartDate}
                                  maxDate={Date.parse(this.props.quote.q_vd_DateEnd)}
                                  dateFormat="yyyy"
                                  showYearPicker
                                />
                              }


                              {/* Choix de la date fin */}
                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeType === "durée" &&
                                <InputGroup.Text id="basic-addon1">{(this.props.quote.q_vd_timeFormat === 'jj/mm/aaaa' || this.props.quote.q_vd_timeFormat === 'hh:mm jj/mm/aaaa') ? "et le" : "et"}</InputGroup.Text>
                              }
                              {this.props.quote.q_quand !== "-1" && this.props.quote.q_vd_timeType === "durée" &&
                                <div>
                                  {this.props.quote.q_vd_timeFormat === 'hh:mm jj/mm/aaaa' &&
                                    <DatePicker
                                      id="dateEnd"
                                      locale="fr"
                                      className={this.props.quote.q_vd_DateEnd === '' ? "datePickerLarge pl-2 placeHolderCss" : "datePickerLarge placeHolderCss "}
                                      placeholderText="Date fin"
                                      selected={Date.parse(this.props.quote.q_vd_DateEnd)}
                                      onChange={this.props.handleChangeEndDate}
                                      // onClick={}
                                      minDate={Date.parse(this.props.quote.q_vd_DateBeg)}
                                      showTimeSelect
                                      dateFormat="dd/MM/yyyy, HH:mm"
                                      showYearDropdown
                                      dateFormatCalendar="MMMM"
                                      yearDropdownItemNumber={15}
                                      scrollableYearDropdown
                                    />
                                  }
                                  {this.props.quote.q_vd_timeFormat === 'jj/mm/aaaa' &&
                                    <DatePicker
                                      id="dateEnd"
                                      locale="fr"
                                      className={this.props.quote.q_vd_DateEnd === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                      placeholderText="Date fin"
                                      selected={Date.parse(this.props.quote.q_vd_DateEnd)}
                                      onChange={this.props.handleChangeEndDate}
                                      // onClick={}
                                      minDate={Date.parse(this.props.quote.q_vd_DateBeg)}
                                      dateFormat="dd/MM/yyyy"
                                      showYearDropdown
                                      dateFormatCalendar="MMMM"
                                      yearDropdownItemNumber={15}
                                      scrollableYearDropdown
                                    />
                                  }
                                  {this.props.quote.q_vd_timeFormat === 'mm/aaaa' &&
                                    <DatePicker
                                      id="dateEnd"
                                      locale="fr"
                                      className={this.props.quote.q_vd_DateEnd === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                      placeholderText="Date fin"
                                      selected={Date.parse(this.props.quote.q_vd_DateEnd)}
                                      onChange={this.props.handleChangeEndDate}
                                      // onClick={}
                                      minDate={Date.parse(this.props.quote.q_vd_DateBeg)}
                                      dateFormat="MM/yyyy"
                                      showYearDropdown
                                      dateFormatCalendar="MMMM"
                                      showMonthYearPicker
                                      showFullMonthYearPicker
                                    />
                                  }
                                  {this.props.quote.q_vd_timeFormat === 'aaaa' &&
                                    <DatePicker
                                      id="dateEnd"
                                      locale="fr"
                                      className={this.props.quote.q_vd_DateEnd === '' ? "datePickerSmall pl-2 placeHolderCss" : "datePickerSmall placeHolderCss "}
                                      placeholderText="Date fin"
                                      selected={Date.parse(this.props.quote.q_vd_DateEnd)}
                                      onChange={this.props.handleChangeEndDate}
                                      // onClick={}
                                      minDate={Date.parse(this.props.quote.q_vd_DateBeg)}
                                      dateFormat="yyyy"
                                      showYearPicker
                                    />
                                  }
                                </div>
                              }

                            </div>
                          }

                        </div>

                      </div>
                    </div>

                  </InputGroup>


                </div>

              </div>
            }



            {(this.state.displayAll || displayQui) &&
              <div className={"d-flex flex-column align-items-center mt-4 mb-1 "}>

                <div className="d-flex flex-row w-100 titre_pourQui fontDonneesImportantesTitre rounded" id="">
                  <span
                    className="mr-auto"
                    style={{ color: this.props.quote.q_pourQui === "-1" ? ' #b2babb ' : '' }}
                  >
                    Pour qui ?
                  </span>
                  {false && this.props.quote.q_pourQuiInd > (this.props.quote.q_quoiInd + 1) &&
                    <Button variant="" className="m-0 p-0 " onClick={() => this.props.changeIndexValue(this.props.quote.q_pourQuiInd, "moins")}>
                      <ChevronUpIcon className="" size={16} />
                    </Button>}
                  {false && this.props.quote.q_pourQuiInd < 5 &&
                    <Button variant="" className="m-0 p-0 " onClick={() => this.props.changeIndexValue(this.props.quote.q_pourQuiInd, "plus")}>
                      <ChevronDownIcon className="" size={16} />
                    </Button>}
                </div>

                {modalitiesQui.length > 0 &&

                  <div className="d-flex flex-column w-100 ">

                    <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                      "Les groupes de personnes déjà renseignés pour ce débat :"
                    </div>
                    <div className="d-flex justify-content-center w-100 ">
                      <ButtonGroup vertical className="col-lg-6 m-0 p-0 ">
                        {modalitiesQui.map((element, index) => (
                          <Button
                            key={`modalitiesQui_${index}`}
                            className={"robotoFont14px mt-1 ".concat(this.props.quoteModalitiesSelected[3].id_quoteModality === element.id_quoteModality ? "activeButton" : "notActiveButton")}
                            onClick={() => this.props.handleChangeModality('pour qui', "id_quoteModality", this.props.quoteModalitiesSelected[3].id_quoteModality !== element.id_quoteModality ? element.id_quoteModality : 0)}
                          >
                            {element.qm_modalityContent}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>

                  </div>
                }

                <div className="d-flex flex-column w-100 ">

                  <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                    {modalitiesQui.length === 0 ?
                      'Préciser "pour qui" : '
                      :
                      "Vous ne trouvez pas le groupe de personnes que vous cherchez dans la liste ci-dessus ? Ajoutez-en un :"}
                  </div>
                  <div className="d-flex flex-row col-lg-12 justify-content-center mt-3 ">
                    <input
                      type="text"
                      className="col-lg-8 form-control text-left placeHolderCss"
                      id="infoTitreQui"
                      placeholder="ex : les personnes de plus de 11 ans"
                      value={this.props.quoteModalitiesSelected[3].qm_modalityContent}
                      onChange={e => this.props.handleChangeModality('pour qui', "qm_modalityContent", e.target.value)}
                      disabled={this.props.quoteModalitiesSelected[3].id_quoteModality > 0}
                      style={{ color: this.props.quote.q_pourQui === "-1" ? ' #b2babb ' : '' }}
                    />
                  </div>

                </div>

              </div>
            }

            {/* Champ 'comment' */}
            {(this.state.displayAll || displayComment) &&
              <div className={"d-flex flex-column align-items-center mt-4 mb-1 "}>

                <div className="w-100 titre_comment fontDonneesImportantesTitre border rounded" id="">
                  <span
                    className="mr-auto"
                    style={{ color: this.props.quote.q_combien === "-1" ? ' #b2babb ' : '' }}
                  >
                    Comment ?
                  </span>
                </div>

                {console.log("this.props.quoteModalitiesSelected[4] :", this.props.quoteModalitiesSelected[4])}

                {modalitiesComment.length > 0 &&
                  <div className="d-flex flex-column w-100 ">

                    <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                      Les manières déjà renseignées pour ce débat :
                    </div>
                    <div className="d-flex justify-content-center ">
                      <ButtonGroup vertical className="col-lg-6 m-0 p-0  ">
                        {modalitiesComment.map((element, index) => (
                          <Button
                            key={`modalitiesComment_${index}`}
                            className={"robotoFont14px mt-1 ".concat(this.props.quoteModalitiesSelected[4].id_quoteModality === element.id_quoteModality ? "activeButton" : "notActiveButton")}
                            onClick={() => this.props.handleChangeModality('comment', "id_quoteModality", this.props.quoteModalitiesSelected[4].id_quoteModality !== element.id_quoteModality ? element.id_quoteModality : 0)}
                          >
                            {element.qm_modalityContent}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </div>
                }

                <div className="d-flex flex-column w-100 ">

                  <div className="robotoFont14px pl-3 pt-3" style={{ color: "grey" }}>
                    {modalitiesComment.length === 0 ?
                      'Préciser "comment" : '
                      :
                      "Vous ne trouvez pas la manière que vous cherchez dans la liste ci-dessus ? Ajoutez-en une :"}
                  </div>
                  <div className="d-flex flex-row col-lg-12 justify-content-center mt-3 ">
                    <input
                      type="text"
                      className="form-control text-left placeHolderCss col-lg-8 "
                      id="infoTitreComment"
                      placeholder="" // "ex : NC (Non Concerné)"
                      value={this.props.quoteModalitiesSelected[4].qm_modalityContent}
                      onChange={e => this.props.handleChangeModality('comment', "qm_modalityContent", e.target.value)}
                      disabled={this.props.quoteModalitiesSelected[4].id_quoteModality > 0}
                      style={{ color: this.props.quote.q_combien === "-1" ? ' #b2babb ' : '' }}
                    />
                  </div>

                </div>

              </div>
            }

          </div>
        </div>


        {/* En résumé */}
        {true && // affichage du titre en blocs à partir des composants Quoi, Combien etc.
          <div className="d-flex justify-content-center col-lg-12 m-0 p-0 mt-4 ">

            <div className="d-flex flex-column col-lg-11 m-0 p-3 rounded border">
              <div>En résumé</div>

              <div className="d-flex flex-column input-group ml-5 ">
                <div className={"d-inline-flex mt-4 "} >
                  <div className="d-flex flex-column " >
                    <small className="sousTitreQuoi pl-2 pr-2 ">Quoi ?</small>
                    <div className="p-2 titre_quoi ">{this.props.quoteTitle}</div>
                  </div>
                </div>

                {this.props.quote.q_combien !== "-1" &&
                  <div className={"d-inline-flex ml-5 pl-5 mt-1 "} >
                    <div className="d-flex flex-column " >
                      <small className="sousTitre_combien pl-2 pr-2 ">Combien ?</small>
                      <div className="p-2 titre_combien ">{this.props.quoteModalitiesSelected[2].qm_modalityContent}</div>
                    </div>
                  </div>}

                {this.props.quote.q_ou !== "-1" &&
                  <div className={"d-inline-flex ml-5 pl-5 mt-1 "} >
                    <div className="d-flex flex-column " >
                      <small className="sousTitre_ou pl-2 pr-2 ">Où ?</small>
                      <div className="p-2 titre_ou ">{this.props.quoteModalitiesSelected[1].qm_modalityContent}</div>
                    </div>
                  </div>}

                {this.props.quote.q_quand !== "-1" &&
                  < div className={"d-inline-flex ml-5 pl-5 mt-1 "} >
                    <div className="d-flex flex-column " >
                      <small className="sousTitre_quand pl-2 pr-2 ">Quand ?</small>
                      <div className="p-2 titre_quand ">{this.props.quoteModalitiesSelected[0].qm_modalityContent}</div>
                    </div>
                  </div>
                }

                {this.props.quote.q_pourQui !== "-1" &&
                  <div className={"d-inline-flex ml-5 pl-5 mt-1 "} >
                    <div className="d-flex flex-column " >
                      <small className="sousTitre_pourQui pl-2 pr-2 ">Pour qui ?</small>
                      <div className="p-2 titre_pourQui ">{this.props.quoteModalitiesSelected[3].qm_modalityContent}</div>
                    </div>
                  </div>
                }

                {this.props.quote.q_pourQui !== "-1" &&
                  <div className={"d-inline-flex ml-5 pl-5 mt-1 "} >
                    <div className="d-flex flex-column " >
                      <small className="sousTitre_comment pl-2 pr-2 ">Comment ?</small>
                      <div className="p-2 titre_comment border ">{this.props.quoteModalitiesSelected[4].qm_modalityContent}</div>
                    </div>
                  </div>
                }

              </div>
            </div>

          </div>
        }



      </div>

    )
  }

}
export default ComponentDebatsModalites