import React, { Component } from 'react';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';


class ComponentLeurPointDeVue extends Component {

  state = {
    showConsoleLog: true,
    hovered: false,
  }


  capitalize = (s) => {
    if (typeof s !== 'string') {
      return ''
    } else {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }


  render() {

    const duration = 300;

    const defaultStyle = {
      transition: `all ${duration}ms ease-in-out`,
      opacity: 0,
    }

    let infoPlusHeight = document.getElementById(`infoPlus_${this.props.indexKey}`) === null ?
      0 : document.getElementById(`infoPlus_${this.props.indexKey}`).offsetHeight


    const transitionStyles2 = {
      entering: { height: infoPlusHeight, opacity: 1 },
      entered: { height: infoPlusHeight, opacity: 1 },
      exiting: { height: 0, opacity: 0 }, // visibility: 'hidden', opacity: 0
      exited: { height: 0, opacity: 0 },
    }


    const name = ((this.props.personData.pers_firstName !== undefined &&
      this.props.personData.pers_firstName.length > 0) ?
      this.props.personData.pers_firstName.concat(" ", this.props.personData.pers_name)
      :
      this.props.personData.pers_name)

    this.props.showConsoleLog && console.log("ComponentLeurPointDeVue -> name :", name)

    return (

      <div className="col-12 col-lg-12 m-0 p-0 ">

        <Button
          variant="light"
          className={"d-flex justify-content-center col-12 col-lg-12 m-0 p-3 h-100 boutonSommaire2021 leurPointDeVueBox  ".concat(window.innerWidth > 575.98 ? "text-left " : "text-center")}
          style={{
            fontSize: window.innerWidth > 575.98 ? "13px" : "11px",
            backgroundColor: window.innerWidth > 575.98 ? '' : "#ececec",
          }}
          href={"/intervenantDebat/".concat(this.props.personData.pers_originID)}
        >

          <div className="d-flex col-lg-10 m-0 p-0 ml-auto mr-auto ">

            <div className={window.innerWidth > 575.98 ? "d-flex flex-row w-100 m-0 p-0 " : "d-flex flex-column w-100 m-0 p-0 "}>
              <img
                className="m-0 p-0 rounded "
                src={this.props.personData.pers_picture !== undefined && this.props.personData.pers_picture !== null ?
                  (this.props.personData.pers_picture.slice(0, 4) === 'http' ? this.props.personData.pers_picture : (this.props.url_image + this.props.personData.pers_picture))
                  : ""}
                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                // title={this.state.hovered ? "Cliquer pour en savoir +" : ""}
                alt=""
                style={{ objectFit: 'contain', maxHeight: '90px', width: 'auto', filter: 'none' }}
              />
              
            </div>



          </div>

        </Button>

      </div>

    )
  }
}


export default ComponentLeurPointDeVue