import React, { Component } from 'react';

import {
  BroadcastIcon, NoEntryIcon, PlusCircleIcon, CheckCircleFillIcon, XIcon,
  CircleIcon, XCircleIcon, SyncIcon, ChevronRightIcon, ChevronLeftIcon,
  TriangleLeftIcon, TriangleRightIcon
} from '@primer/octicons-react'

import DatePicker, { registerLocale } from "react-datepicker";

// Autres components
import ModalDeleteReference from './ModalDeleteReference'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


// import BootstrapTable from 'react-bootstrap-table-next'
// import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
// import paginationFactory from 'react-bootstrap-table2-paginator'

import ReactPlayer from 'react-player';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';

import Slider, { SliderTooltip } from 'rc-slider';

import "react-datepicker/dist/react-datepicker.css";

const SliderComponent = Slider.createSliderWithTooltip(Slider);
const RangeComponent = Slider.createSliderWithTooltip(Slider.Range);


class ComponentReference extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showConsoleLog: true,

    mediatypeIDFilter: 0, // type de medium : 1 - Média ; 2 - Revue scientifique ; 3 - Réseau social etc.
    // mediaSelectedID: 0, // ID du média sélectionné (int)
    mediaNameFilter: '',

    reorganizeMediasListData: [],

    videoDuration: -1,
    seeking: false,
    played: 0,
    value: 2,
    playing: false,

    playedStart: 0,
    playedEnd: 1,

    validated: true,

    changeMediaMenu: false,

    listmedia: [],
    mediatypeID: 0,
    mediatypeSelectedName: "",

  }

  componentDidMount() {
    this.initData()
  }


  scriptmp4(start = 2) {
    let vid = document.getElementById("dispVideo");
    vid.currentTime = start;
    let firsttime = true;
    vid.addEventListener("timeupdate", function () {
      if (vid.currentTime >= 3 && firsttime) {
        vid.pause();
        firsttime = false;
      }
    });
  }


  componentDidUpdate() {
    if (document.getElementById("dispVideo") != null) {
      this.scriptmp4();
    }
    if (document.getElementById("twittervid") != null) {
      let script = document.createElement("script");
      script.setAttribute("src", "https://platform.twitter.com/widgets.js")
      script.setAttribute("async", true)
      script.setAttribute("charset", "utf-8")
      document.getElementById("twittervid").appendChild(script)
    }

  }

  initData = (exceptions) => {

    this.props.showConsoleLog && console.log("initData -> reference 0 :", this.props.reference)
    this.props.showConsoleLog && console.log("initData -> addOrModifyOrVisualize :", this.props.addOrModifyOrVisualize)

    this.setState({

      mediatypeIDFilter: 0,
      // mediaSelectedID: this.props.reference !== undefined && this.props.reference.media_idperson !== undefined ? this.props.reference.media_idperson : 0,
      mediaNameFilter: '',

      playedStart: this.props.reference === undefined ? 0 : this.props.reference.re_videoStart,
      playedEnd: this.props.reference === undefined ? 1 : this.props.reference.re_videoEnd,

      mediatypeSelectedName: this.props.reference === undefined ? "" : this.props.reference.media_typeTitle,

      changeMediaMenu: false,

    }, () => {
      this.reorganizeMediasList()

    })

  }



  getMediaFromArrayOfMediaList = (idperson) => {
    const listmedia = this.props.listmedia
    const mediaObject = {}

    this.props.showConsoleLog && console.log('getMediaFromArrayOfMediaList -> listmedia :', listmedia)
    this.props.showConsoleLog && console.log('getMediaFromArrayOfMediaList -> idperson :', idperson)

    if (listmedia !== undefined && idperson !== 0) {
      listmedia.forEach((element) => {
        if (element.person.idperson === idperson) {
          Object.assign(mediaObject, element.person)
        }
      })
    }

    return mediaObject
  }


  handleDuration = (videoDuration) => {
    this.props.showConsoleLog && console.log('video -> onDuration : ', videoDuration)
    this.setState({ videoDuration })
  }


  handleSeekMouseDown = values => {
    this.props.showConsoleLog && console.log("video -> handleSeekMouseDown ", values)
    // this.setState({ seeking: true })
  }

  handleSeekChange = played => {
    this.props.showConsoleLog && console.log("video -> handleSeekChange : ", played)
    this.setState({ played })
    this.player.seekTo(played)
  }

  handleSeekChange2 = played => {
    this.props.showConsoleLog && console.log("video -> handleSeekChange : ", played)
    this.setState({ playedStart: played[0], playedEnd: played[1] })
    this.props.setReferenceNewAttributeValue("re_videoStartEnd", played[0], played[1])
    // this.player.seekTo(played[0])
  }

  handleSeekMouseUp = values => {
    this.props.showConsoleLog && console.log("video -> handleSeekMouseUp : ", values)
    this.setState({ seeking: false })
    this.player.seekTo(values)
  }


  handleProgress = state => {
    this.props.showConsoleLog && console.log('video -> onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (true || !this.state.seeking) {
      this.setState(state)
    }
  }

  handlePlay = play => {
    this.props.showConsoleLog && console.log('video -> handlePlay')
    this.setState({ playing: true })
  }

  handlePause = pause => {
    this.props.showConsoleLog && console.log('video -> handlePause')
    this.setState({ playing: false })
  }


  urls = (url) => {

    return (

      <ReactPlayer
        ref={this.ref}
        // height='100%'
        url={url} /// {`https://www.youtube.com/embed/${id}`}
        playing={this.state.played < this.state.playedEnd ? this.state.playing : false}
        onDuration={this.handleDuration}
        onProgress={this.handleProgress}
        onPlay={this.handlePlay}
        onPause={this.handlePause}
        onReady={() => this.handleDuration}
        controls={true}
        light={false}
        config={{
          youtube: {
            playerVars: { showinfo: 0, rel: 0, modestbranding: 1, controls: 2 }
          },
        }}
      />

    )

  }

  ref = player => {
    this.player = player
  }

  reorganizeMediasList = () => {

    const data = []
    let listMedias = this.state.listmedia

    this.props.showConsoleLog && console.log('reorganizeMediasList -> listMedias : ', listMedias)
    this.props.showConsoleLog && console.log('reorganizeMediasList -> mediaNameFilter : ', this.state.mediaNameFilter)
    this.props.showConsoleLog && console.log('reorganizeMediasList -> mediatypeIDFilter : ', this.state.mediatypeIDFilter)

    listMedias !== null && listMedias.length > 0 &&
      listMedias
        .filter((media, index) =>
          // media.person.idperson > 57 &&
          (this.state.mediaNameFilter !== "" ?
            media.person.pers_name.toLowerCase().includes(this.state.mediaNameFilter.toLowerCase())
            :
            true) &&
          (this.state.mediatypeIDFilter !== 0 ?
            media.med_typeID === this.state.mediatypeIDFilter
            :
            true)
        )
        .slice(0, 1000)
        .forEach((media) => {
          data.push({
            // exp: "+",
            id: media.person.idperson,
            picture: media.person.pers_picture,
            nom: media.person.pers_name,
            selected: media.person.idperson === this.props.reference.media_idperson,
            alreadySelected: this.alreadySelectedCheck(media.person.idperson),
            mediaType: media.mt_title,
          })
        })

    this.setState({ reorganizeMediasListData: data })

    const expandRow = {
      onlyOneExpanding: true,
      // showExpandColumn: true,
      renderer: row => (
        <div>
          <p>{`This Expand row is belong to rowKey ${row.nom}`}</p>
        </div>
      ),
      // expanded: this.state.expanded,
      // onExpand: this.handleOnExpand,
    }

    this.setState({ reorganizeMediasListExpandRow: expandRow })

  }

  alreadySelectedCheck = (idperson) => {
    let stateReturn = "not selected"

    this.props.showConsoleLog && console.log("ComponentReference -> alreadySelectedCheck -> idperson", idperson)
    this.props.showConsoleLog && console.log("ComponentReference -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentReference -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentReference -> alreadySelectedCheck -> includes", this.props.listcommentateursContreId.includes(idperson))
    this.props.showConsoleLog && console.log("ComponentReference -> alreadySelectedCheck -> stateReturn", stateReturn)

    if (this.props.listcommentateursPourId !== undefined && this.props.listcommentateursPourId.includes(idperson)) {
      stateReturn = "pour"
    }
    if (this.props.listcommentateursPeutetreId !== undefined && this.props.listcommentateursPeutetreId.includes(idperson)) {
      stateReturn = "peut etre"
    }
    if (this.props.listcommentateursContreId !== undefined && this.props.listcommentateursContreId.includes(idperson)) {
      stateReturn = "contre"
    }

    return stateReturn

  }


  changeSelectedMedia = (idToChange) => {

    let reorganizeMediasListData = this.state.reorganizeMediasListData

    this.props.showConsoleLog && console.log("changeSelectedMedia -> idToChange : ", idToChange)
    this.props.showConsoleLog && console.log("changeSelectedMedia -> reorganizeMediasListData : ", reorganizeMediasListData)

    this.props.showConsoleLog && console.log("changeSelectedMedia -> listmedia : ", this.state.listmedia)

    let selectMediaData = {}
    // récupération des données du média sélectionné
    this.state.listmedia.forEach((element) => {
      if (element.person.idperson === idToChange) {
        selectMediaData = element
        this.setState({ mediatypeSelectedName: element.mt_title })
      }
    })

    this.props.showConsoleLog && console.log("changeSelectedMedia -> selectMediaData : ", selectMediaData)

    reorganizeMediasListData.forEach((element) => {
      if (element.id === idToChange) {
        element.selected = true
      } else {
        element.selected = false
      }
    })

    this.props.showConsoleLog && console.log("changeSelectedMedia -> reorganizeMediasListData : ", reorganizeMediasListData)

    this.setState({ reorganizeMediasListData: reorganizeMediasListData }) // , () => this.nextStep()
    // this.props.setSelectedMedia(this.getMediaFromArrayOfMediaList(idToChange))
    this.props.setReferenceNewAttributeValue("mediaSelection", selectMediaData)

  }


  secondsToHHMMSS = (time) => {
    let returnTime = ''

    let hoursStr = ''
    let minutesStr = ''
    let secondsStr = ''

    let hoursNum = Math.floor(time / 3600)
    let minutesNum = Math.floor((time - hoursNum * 3600) / 60)
    let secondsNum = Math.floor((time - hoursNum * 3600 - minutesNum * 60))

    if (hoursNum < 10) {
      hoursStr = '0'.concat(hoursNum.toString())
    } else {
      hoursStr = hoursNum.toString()
    }
    if (minutesNum < 10) {
      minutesStr = '0'.concat(minutesNum.toString())
    } else {
      minutesStr = minutesNum.toString()
    }
    if (secondsNum < 10) {
      secondsStr = '0'.concat(secondsNum.toString())
    } else {
      secondsStr = secondsNum.toString()
    }

    this.props.showConsoleLog && console.log("hours / time : ", time)
    this.props.showConsoleLog && console.log("hours : ", hoursStr, " / minutes : ", minutesStr, " / seconds : ", secondsStr)

    if (hoursNum === 0) {
      returnTime = minutesStr.concat(':', secondsStr)
    } else {
      returnTime = hoursStr.concat(':', minutesStr, ':', secondsStr)
    }

    return (returnTime)
  }

  setVideoTitle = (url) => {

    this.props.showConsoleLog && console.log("setVideoTitle in : ", url)
    this.props.showConsoleLog && console.log("setVideoTitle COND : ", url.includes('twitter.com'))
    this.props.showConsoleLog && console.log("setVideoTitle in cond : ", url === "")
    this.props.showConsoleLog && console.log("setVideoTitle ref_type_id : ", this.props.reference.ref_type_id)
    this.props.showConsoleLog && console.log("setVideoTitle ref_type_title : ", this.props.reference.ref_type_title)
    this.props.showConsoleLog && console.log("setVideoTitle ref_type_title cond : ", this.props.reference.ref_type_title === "vidéo")

    if (url === "") {
      // this.setState({ reference: { ...this.state.reference, ref_title: "", ref_link: "", re_videoStart: 0.0001, re_videoEnd: 1 } })
      this.props.setReferenceNewAttributeValue("ref_title", url)
    } else {

      if (url.includes('twitter.com')) {
        // this.setState({ reference: { ...this.state.reference, ref_title: 'Twit', ref_link: "", re_videoStart: 0.0001, re_videoEnd: 1 } }, () => this.setState({ reference: { ...this.state.reference, ref_link: url } }))
        this.props.setReferenceNewAttributeValue("ref_link", url)
      } else {

        if (this.props.reference.ref_type_title === "vidéo") {

          this.props.showConsoleLog && console.log("setVideoTitle 1 : ", url)
          this.props.showConsoleLog && console.log("setVideoTitle 1b : ", 'https://noembed.com/embed?url='.concat(url))
          fetch('https://noembed.com/embed?url='.concat(url)) // .concat(url)
            .then(response => response.json())
            .then((jsonData) => {
              // jsonData is parsed json object received from url
              this.props.showConsoleLog && console.log("setVideoTitle 2 : ", jsonData)
              this.props.showConsoleLog && console.log("setVideoTitle 2' : ", jsonData.title)
              // this.setState({ reference: { ...this.state.reference, ref_title: this.replaceAll(jsonData.title, "'", "\\'"), ref_link: url, re_videoStart: 0.0001, re_videoEnd: 1 } })
              this.props.setReferenceNewAttributeValue("ref_linkANDref_title", url, jsonData.title)
            })
            .catch((error) => {
              // handle your errors here
              this.props.showConsoleLog && console.error("setVideoTitle 3 : ", error)
            })

        } else {
          // this.setState({ reference: { ...this.state.reference, ref_link: url, re_videoStart: 0.0001, re_videoEnd: 1 } })
          this.props.setReferenceNewAttributeValue("ref_link", url)
        }
      }
    }
  }

  replaceAll(string, search, replace) {
    let returnString = ""
    if (string !== "" && string !== null && string !== undefined) {
      returnString = string.split(search).join(replace)
    }
    return returnString
  }

  getMediatypeidFromPersonid(listmedia, commentateurID) {
    let mediaTypeIDReturn = -1

    // console.log("getMediatypeidFromPersonid -> listmedia : ", listmedia)
    // console.log("getMediatypeidFromPersonid -> commentateurID : ", commentateurID)

    if (listmedia !== undefined) {

      listmedia.forEach((element) => {
        if (element.person.idperson === commentateurID) {
          mediaTypeIDReturn = element.med_typeID
        }
      })

    }

    return mediaTypeIDReturn

  }

  getPersonNameByID = IDperson => {
    var listperson = this.props.listperson
    var personName = 'person not found'
    IDperson = Number(IDperson)

    this.props.showConsoleLog && console.log('getPersonNameByID -> IDperson : ', IDperson)
    this.props.showConsoleLog && console.log('getPersonNameByID -> listperson : ', listperson)

    listperson !== undefined && listperson !== null &&
      listperson.forEach((element) => {

        if (element.idperson === IDperson) {
          personName = element.pers_name;
          this.props.showConsoleLog && console.log('getPersonNameByID -> personne trouvée : ', element.pers_name)
        }
      })

    return personName
  }

  handleDateParution = date => {
    this.props.showConsoleLog && console.log('handleDateParution -> date 1 : ', date.setHours(date.getHours() + 6))
    this.props.showConsoleLog && console.log('handleDateParution -> date 2 : ', this.transformDateFormat(date))

    this.props.setReferenceNewAttributeValue("ref_dateParution", this.transformDateFormat(date))

    // let temp = this.state.quote
    // this.setState({ date1: date, date2: this.transformDateFormat(date) })
  }

  transformDateFormat = (date) => {
    let dateNewFormat1 = date.toISOString().split('.')[0].replace('T', ' ')
    let dateNewFormat2 = new Date(date.setTime(date.getTime())) // Ajout de 2h (l'heure du SelectPicker est à l'heure GMT => si on choisit le 25 fév 00:00:00 heure locale, SelectPicker sélectionne 24 fév 22:00:00)
    let dateNewFormat3 = dateNewFormat2.toISOString().split('.')[0].replace('T', ' ')

    this.props.showConsoleLog && console.log('transformDateFormat -> dateNewFormat1 : ', dateNewFormat1)
    this.props.showConsoleLog && console.log('transformDateFormat -> dateNewFormat2 : ', dateNewFormat2)
    this.props.showConsoleLog && console.log('transformDateFormat -> dateNewFormat3 : ', dateNewFormat3)

    return dateNewFormat3
  }

  handleSubmit = (event) => {

    event.preventDefault();

    console.log("handleSubmit -> in")

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation();

      console.log("handleSubmit -> stop")
    } else {
      console.log("handleSubmit -> continue")
    }

    this.setState({ validated: true })

  };


  render() {

    this.props.showConsoleLog && console.log('ComponentReference -> this.props.person : ', this.props.person)
    this.props.showConsoleLog && console.log('ComponentReference -> reference : ', this.props.sourceType, this.props.reference)

    this.props.showConsoleLog && console.log('ComponentReference -> listmedia : ', this.props.listmedia)

    this.props.showConsoleLog && console.log('ComponentReference -> media_idperson : ', this.props.reference.media_idperson)
    this.props.showConsoleLog && console.log('ComponentReference -> getMediaFromArrayOfMediaList : ', this.getMediaFromArrayOfMediaList(this.props.reference.media_idperson))

    this.props.showConsoleLog && console.log('ComponentReference -> commentateurData : ', this.props.addOrModifyOrVisualize, this.props.commentateurData)
    this.props.showConsoleLog && console.log('ComponentReference -> reorganizeMediasListData : ', this.props.addOrModifyOrVisualize, this.state.reorganizeMediasListData)

    this.props.showConsoleLog && console.log('ComponentReference -> CommentateurCausaliteAPIs : ', this.props.CommentateurCausaliteAPIs)

    this.props.showConsoleLog && console.log('ComponentReference -> played : ', this.state.played)

    const showAllMediasTable = this.props.reference.media_idperson === 0

    this.props.showConsoleLog && console.log("selmed - addOrModifyOrVisualize ", this.props.addOrModifyOrVisualize, " - reference : ", this.props.reference)
    this.props.showConsoleLog && console.log("selmed - addOrModifyOrVisualize ", this.props.addOrModifyOrVisualize, " - media_idperson : ", this.props.reference.media_idperson)
    this.props.showConsoleLog && console.log("selmed - addOrModifyOrVisualize ", this.props.addOrModifyOrVisualize, " - showAllMediasTable : ", showAllMediasTable)


    if (this.state.listmedia !== this.props.listmedia) {
      this.setState({ listmedia: this.props.listmedia }, () => this.reorganizeMediasList())
    }


    const columnsMedias = [
      { // CircleIcon CheckCircleFillIcon
        dataField: 'selectionner',
        text: window.innerWidth > 575.98 ? '' : '',
        isDummyField: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <button
              className="noBorder "
              style={{ backgroundColor: 'transparent' }}
              onClick={() => {
                this.changeSelectedMedia(row.id)
                this.setState({ changeMediaMenu: false })
                // if (this.props.sourceSelectedChange !== undefined) { this.props.sourceSelectedChange(true) } // permet de passer à l'étape suivante dans le Stepper (pourcréation seulement, pas visualiastion des sources)
              }}
            >
              {row.selected ? <CheckCircleFillIcon size={24} /> : <CircleIcon size={24} />}
            </button>
          )
        },
        formatExtraData: this.props.reference.media_idperson,
        headerStyle: { width: '13%', fontSize: window.innerWidth > 575.98 ? '15px' : '13px', textAlign: 'center', verticalAlign: 'middle' },
        style: { padding: '5px', textAlign: 'center', verticalAlign: 'middle' }
      },
      {
        dataField: 'picture', text: 'Icône',
        formatter: (cell, row) => {
          return (
            <img
              className="rounded "
              src={cell === null ? "" : (cell.slice(0, 4) === 'http' ? row.picture : (this.props.url_image + row.picture))}
              alt=""
              style={{ maxHeight: '60px', maxWidth: '95%' }}
            />
          )
        },
        headerStyle: { width: '15%', fontSize: window.innerWidth > 575.98 ? '15px' : '13px', textAlign: 'center', verticalAlign: 'middle' },
        style: { fontSize: window.innerWidth > 575.98 ? '14px' : '13px', margin: '0px', padding: window.innerWidth > 575.98 ? '5px' : '2px', textAlign: 'center', verticalAlign: 'middle' }
      },
      {
        dataField: 'nom', text: 'Nom',
        /* filter: textFilter({
          placeholder: '...',
          style: {
            marginTop: '10px'
          },
          // onFilter: filterVal => console.log(`Filter Value media: ${filterVal}`)
        }), */
        headerStyle: { fontSize: window.innerWidth > 575.98 ? '15px' : '13px', textAlign: 'center', verticalAlign: 'middle' },
        style: { fontSize: window.innerWidth > 575.98 ? '14px' : '13px', margin: '0px', padding: '5px', verticalAlign: 'middle' }
      },
      {
        dataField: 'informations', text: 'Informations',
        isDummyField: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <div>{row.mediaType}</div>
          )
        },
        headerStyle: { fontSize: window.innerWidth > 575.98 ? '15px' : '13px', textAlign: 'center' },
        style: { fontSize: window.innerWidth > 575.98 ? '14px' : '13px', margin: '0px', padding: '5px', verticalAlign: 'middle' }
      },

    ]

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      const activeStyle = {};
      if (active) {
        activeStyle.backgroundColor = 'black';
        activeStyle.color = 'white';
      } else {
        activeStyle.backgroundColor = 'gray';
        activeStyle.color = 'black';
      }
      if (typeof page === 'string') {
        activeStyle.backgroundColor = 'white';
        activeStyle.color = 'black';
      }
      return (
        <li key={this.props.uniqueID.concat(`pageButtonRender_${page}`)} className="page-item">
          <a href="#" className="p-2 pr-3 pl-3" onClick={handleClick} style={activeStyle}>{page}</a>
        </li>
      );
    };

    const options = {
      pageButtonRenderer,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true
    }

    { this.props.showConsoleLog && console.log("ComponentReference -> quoteOrArgumentForm :", this.props.quoteOrArgumentForm) }
    { this.props.showConsoleLog && console.log("ComponentReference -> addOrModifyOrVisualize :", this.props.addOrModifyOrVisualize) }

    { this.props.showConsoleLog && console.log("ComponentReference -> mediatypeSelectedName :", this.state.mediatypeSelectedName) }


    return (

      <div className="w-100 m-0 p-1 pb-4 border rounded ">

        {this.props.addOrModifyOrVisualize === "visualize" && // Titre en cas de visualisation d'une source
          <div className="d-flex flex-column justify-content-center ">
            <div className="d-flex flex-row w-100 ">
              <div className="d-flex flex-row col-lg-12 justify-content-center align-items-center rounded text-center ml-auto mr-auto ">
                {this.props.indexMax > 1 &&
                  <Button
                    variant="light"
                    onClick={() => {
                      let indexChange = (((this.props.index - 1) % this.props.indexMax) + this.props.indexMax) % this.props.indexMax

                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> dec index : ", this.props.index)
                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> dec indexMax : ", this.props.indexMax)
                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> dec calc : ", indexChange)

                      this.props.changeLabelValue(this.props.sourceType === "primaire" ? "CarouselPrimaireIndex" : "CarouselSecondaireIndex", indexChange)
                    }}
                    className='d-flex align-items-center p-0 mr-3'
                    title="Voir la source suivante"
                    style={{ height: '20px' }}
                  >
                    <ChevronLeftIcon size={16} />
                  </Button>
                }
                {"Source ".concat((this.props.reference.re_sourcePrimaire === null || this.props.reference.re_sourcePrimaire === -1) ? "" : this.props.sourceType,
                  (this.props.indexMax !== undefined && this.props.indexMax > 1) ?
                    "\xa0".concat(this.props.index + 1, "/", this.props.indexMax) : "")
                }
                {this.props.indexMax > 1 &&
                  <Button
                    variant="light"
                    onClick={() => {
                      let indexChange = (this.props.index + 1) % this.props.indexMax

                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> inc index : ", this.props.index)
                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> inc indexMax : ", this.props.indexMax)
                      this.props.showConsoleLog && console.log("CarouselPrimaireIndex -> inc calc : ", indexChange)

                      this.props.changeLabelValue(this.props.sourceType === "primaire" ? "CarouselPrimaireIndex" : "CarouselSecondaireIndex", indexChange)
                    }}
                    className='d-flex align-items-center p-0 ml-3'
                    title="Voir la source suivante"
                    style={{ height: '20px' }}
                  >
                    <ChevronRightIcon size={16} />
                  </Button>
                }
              </div>


              {this.props.showConsoleLog && console.log("ComponentReference ->  :")}
              {this.props.showConsoleLog && console.log("ComponentReference ->  :")}

              {this.props.showConsoleLog && console.log("ComponentReference -> quoteLinkID :", this.props.quoteLinkID)}

            </div>

            {this.props.selectReferenceIndexToChange !== undefined &&
              <div className='d-flex justify-content-end m-0 p-0 '>
                <Button
                  variant="light"
                  onClick={() => {
                    this.props.showConsoleLog && console.log("ComponentReference -> reference : ", this.props.reference)
                    this.props.showConsoleLog && console.log("ComponentReference -> media_idperson : ", this.props.reference.idreference)
                    this.props.selectReferenceIndexToChange(this.props.reference.idreference)
                  }}
                  className='m-0 p-1 transparentButton'
                  title="Modifier cette source"
                >
                  <div className="modifierSource octiconStyle m-0 p-0 pr-2">
                    <span>Modifier cette source</span>
                    <SyncIcon size={20} className="ml-3" />
                  </div>
                </Button>
              </div>
            }

            {this.props.deleteReference !== undefined &&

              <div className='d-flex justify-content-end m-0 p-0 '>
                <ModalDeleteReference
                  reference={this.props.reference}
                  deleteReference={this.props.deleteReference}
                  changeLabelValue={this.props.changeLabelValue}
                />
              </div>

            }


          </div>
        }

        {this.props.selectReferenceIndexToChange !== undefined && this.props.addOrModifyOrVisualize === "modify" &&
          <div className='d-flex justify-content-end m-0 p-0 '>
            <Button
              variant="light"
              onClick={() => {
                this.props.showConsoleLog && console.log("ComponentReference -> reference : ", this.props.reference)
                this.props.showConsoleLog && console.log("ComponentReference -> media_idperson : ", this.props.reference.idreference)
                this.props.selectReferenceIndexToChange(-1)
              }}
              className='m-0 p-1 transparentButton'
              title="Modifier cette source"
            >
              <div className="modifierSource octiconStyle m-0 p-0 pr-2">
                <span>Annuler</span>
                <XIcon size={20} className="ml-3" />
              </div>
            </Button>
          </div>
        }

        {this.props.showConsoleLog && console.log("ps -> reference", this.props.reference)}

        <div className="form-group mt-4 ">

          {this.props.showConsoleLog && console.log("sur quel med -> listperson", this.props.listperson)}
          {this.props.showConsoleLog && console.log("sur quel med -> commentateurData", this.props.commentateurData)}
          {this.props.showConsoleLog && console.log("sur quel med -> som_gender :", this.props.commentateurData.som_gender)}

          {this.props.showConsoleLog && console.log("sur quel med -> sourceType", this.props.sourceType)}
          {this.props.showConsoleLog && console.log("sur quel med -> re_sourcePrimaire", this.props.reference.re_sourcePrimaire)}

          {this.props.showConsoleLog && console.log("sur quel med -> idperson", this.props.commentateurData.idperson)}

          {(this.props.listperson !== undefined && this.props.listperson !== null && this.props.listperson.length && Number(this.props.commentateurData.idperson) > 0) ? // Personne sélectionnée
            ((this.props.reference.re_sourcePrimaire === null || this.props.reference.re_sourcePrimaire === -1) ? // Création d'une source
              <div
                className="col-lg-12 m-0 p-0 robotoFont16px "
                style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
              >
                {"Sur quel support\xa0"}
                <em style={{ color: '#ec7063' }}>{this.getPersonNameByID(this.props.commentateurData.idperson)}</em>
                {"\xa0s'est-".concat(this.props.commentateurData.som_gender === "femme" ? "elle exprimée ? \xa0" : "il exprimé ? \xa0")}
              </div>
              :
              (this.props.reference !== undefined && this.props.reference.re_sourcePrimaire === true ? // Source primaire 
                <div
                  className="col-lg-12 m-0 p-0 robotoFont16px "
                  style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                >
                  {"Sur quel support\xa0"}
                  <em style={{ color: '#ec7063' }}>{this.getPersonNameByID(this.props.commentateurData.idperson)}</em>
                  {"\xa0s'est-".concat(this.props.commentateurData.som_gender === "femme" ? "elle directement exprimée ? \xa0" : "il directement exprimé ? \xa0")}
                </div>
                :
                <div
                  className="col-lg-12 m-0 p-0 robotoFont16px "
                  style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                >
                  {"Quel autre média a relayé les propos de\xa0"}
                  <em style={{ color: '#ec7063' }}>{this.getPersonNameByID(this.props.commentateurData.idperson)}</em>
                  {"\xa0 ? \xa0"}
                </div>
              )
            )
            :
            <div></div>
          }


          {this.props.showConsoleLog && console.log('mediatypeIDFilter : ', this.state.mediatypeIDFilter)}
          {this.props.showConsoleLog && console.log('media_idperson : ', this.props.reference.media_idperson)}
          {this.props.showConsoleLog && console.log('addOrModifyOrVisualize : ', this.props.addOrModifyOrVisualize)}

          {(showAllMediasTable || this.state.changeMediaMenu) && // Tableau de recherche d'un média
            <div className={"m-0 mt-2 p-0 pt-2 "}>
              {/* Nom du média */}
              <div className="d-flex flex-row ">
                <div className="mr-auto "></div>

              </div>

              {this.props.showConsoleLog && console.log("ComponentRef -> mediaNameFilter :", this.state.mediaNameFilter)}
              {this.props.showConsoleLog && console.log("ComponentRef -> mediatypeIDFilter :", this.state.mediatypeIDFilter)}

              { // Filtres
                <div className="w-100 " >
                  <div className="col-lg-10 mt-2 p-2 pt-3 pb-3 ml-auto mr-auto rounded " style={{ backgroundColor: "#F5F5F5" }}>
                    <div className="row w-100 justify-content-around m-0 p-0 ">

                      <div className="col-lg-5 m-0 p-0 ">
                        <FormControl
                          id={this.props.uniqueID.concat("barreRechercheMedia")}
                          type="text"
                          className="w-100 text-left border "
                          placeholder={"\xa0\xa0Filtrer par mot clé"}
                          onChange={(e) => {
                            this.setState({ mediaNameFilter: e.target.value })
                            this.props.MediaAPIs(this.state.mediatypeIDFilter, e.target.value)
                          }}
                          style={{
                            border: 'solid',
                            borderWidth: '3px',
                            borderColor: (this.state.hoverRechercherUp || this.state.hoverRechercherDown) ? '#f1948a' : '#ffffff',
                          }}
                        />
                      </div>

                      {this.props.showConsoleLog && console.log("ComponentReference -> listmediatype : ", this.props.listmediatype)}

                      {/* Type de média */}
                      <div className="col-lg-5 m-0 p-0 ">
                        <select
                          className="w-100 form-control "
                          id={this.props.uniqueID.concat("addCommentateur_selectMediaType")}
                          value={this.state.mediatypeIDFilter}
                          // onChange={e => { this.handleChangeMediaType(e) }}
                          onChange={e => {
                            this.props.MediaAPIs(parseInt(e.target.value), this.state.mediaNameFilter)
                            this.setState({ mediatypeIDFilter: parseInt(e.target.value) })
                          }}
                        >
                          <option value={0}> Filtrer par type de média </option>
                          {this.props.listmediatype !== undefined &&
                            this.props.listmediatype.map(({ type_id, type_title }, index) => (
                              <option key={this.props.uniqueID.concat(`listmediatype1_${index}`)} value={type_id}>{type_title}</option>
                            ))
                          }
                        </select>
                      </div>

                    </div>

                    {this.props.showConsoleLog && console.log("filterType : ", this.state.filterType)}
                    {this.props.showConsoleLog && console.log("listmediatype : ", this.props.listmediatype)}
                    {this.props.showConsoleLog && console.log("mediatypeIDFilter : ", this.state.mediatypeIDFilter)}
                    {this.props.showConsoleLog && console.log("listreferencetype : ", this.props.listreferencetype)}

                  </div>
                </div>
              }

              <div className="w-100 mt-2">
                {/*
                  <BootstrapTable
                    keyField='nom'
                    columns={columnsMedias} // this.state.reorganizePersonsListColumns
                    data={this.state.reorganizeMediasListData} // 
                    // filter={filterFactory({ afterFilter })}
                    // expandRow={this.state.reorganizePersonsListExpandRow}
                    // pagination={paginationFactory(options)}
                    striped
                    hover
                    condensed
                  />
                */}
              </div>

            </div>
          }


          {this.props.showConsoleLog && console.log("selmed -> media_idperson : ", this.props.reference.media_idperson)}
          {this.props.showConsoleLog && console.log("selmed -> addOrModifyOrVisualize : ", this.props.addOrModifyOrVisualize)}
          {this.props.showConsoleLog && console.log("selmed -> reference : ", this.props.reference)}

          {this.props.showConsoleLog && console.log("selmed -> changeMediaMenu : ", this.state.changeMediaMenu)}


          {(!showAllMediasTable && !this.state.changeMediaMenu) && // Média sélectionné
            <div>
              <div className="d-flex flex-row justify-content-center align-items-center col-lg-12 mt-3 ">

                {this.props.reference.media_pers_title !== undefined && this.props.reference.media_pers_title !== "undefined" && this.props.reference.media_pers_title !== null &&
                  <div className="col-lg-6 p-0 ">
                    <Table className="m-0" striped bordered hover>
                      <tbody className="">
                        <tr>
                          {(this.props.reference.media_pers_picture !== undefined && this.props.reference.media_pers_picture !== "undefined" && this.props.reference.media_pers_picture !== null) &&
                            <td style={{ width: '20%' }}>
                              <img
                                className="rounded "
                                src={this.props.reference.media_pers_picture.slice(0, 4) === 'http' ? this.props.reference.media_pers_picture : (this.props.url_image + this.props.reference.media_pers_picture)}
                                alt=""
                                style={{ maxHeight: '80px', maxWidth: '95%' }}
                              />
                            </td>
                          }
                          <td style={{ verticalAlign: 'middle' }}>
                            {this.props.reference.media_pers_title}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                }

                {this.props.addOrModifyOrVisualize === "add" && (this.props.referenceAlreadyInDatabase == undefined || !this.props.referenceAlreadyInDatabase) &&
                  <div className="p-0 ml-4">
                    <Button //Bouton pour changer le média
                      variant="light"
                      onClick={() => {
                        this.setState({ changeMediaMenu: true, mediaNameFilter: "" }, () => this.reorganizeMediasList())
                      }}
                      className="btn btn-secondary d-inline-flex p-2 pl-3 pr-3 moderationTexte zoomImage_10pct  whiteButtonShadow octiconStyle"
                      style={{ 'borderRadius': '20px', 'height': '40px' }}
                    >
                      <BroadcastIcon size={24} />
                    </Button>
                  </div>
                }

              </div>

            </div>
          }

        </div>


        {/* Type de référence */}
        {!showAllMediasTable &&
          <div className="form-group col-lg-12 m-0 mt-4 p-0 ">

            {(this.props.argumentPourContre !== 'quoi' && this.props.argumentPourContre !== 'quand') &&
              <div className="row form-group col-lg-12 mt-4">

                <div
                  className="m-0 p-0 robotoFont16px text-left col-lg-2 "
                  style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                >
                  {"Type de support :"}
                </div>

                <div className="d-flex justify-content-start input-group col-lg-10">
                  <Form inline={true} bsPrefix="row justify-content-start col-lg-12 m-0 p-0 ">
                    {this.props.listreferencetype
                      .filter((element) => element.type_title !== "image")
                      .map((element, index) => (
                        <div key={this.props.uniqueID.concat(`sourceReferenceTypeCheckbox_${index}_`, this.props.sourceType)} className={window.innerWidth > 575.98 ? "mr-5 pr-3 " : "col-6 m-0 p-0 text-center "}>
                          <Form.Check
                            custom
                            // inline
                            label={this.props.capitalizeFirstLetter(element.type_title)}
                            type='radio'
                            id={this.props.uniqueID.concat(`radioButtonFormSourceReferenceType_${index}`, this.props.sourceType)}
                            onChange={() => {
                              this.props.setReferenceNewAttributeValue("ref_type", element.type_id)
                            }}
                            checked={this.props.reference.ref_type_id === element.type_id}
                            disabled={this.props.addOrModifyOrVisualize === "visualize"}
                          />
                        </div>
                      ))}
                  </Form>
                </div>

              </div>}
          </div>
        }

        {this.props.showConsoleLog && console.log("url -> reference :", this.props.reference)}

        {false && <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="First name"
                defaultValue="Mark"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last name"
                defaultValue="Otto"
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button type="submit">Submit form</Button>
        </Form>}

        {this.props.showConsoleLog && console.log("getReferenceFromUrlAPI ind -> referenceAlreadyInDatabase :", this.props.referenceAlreadyInDatabase)}


        {/* Sélection de l'URL */}
        {// !showAllMediasTable &&
          <div className="row form-group col-lg-12 mt-4">
            <div
              className="m-0 p-0 robotoFont16px col-lg-2 "
              style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
            >
              {"URL :"}
            </div>
            <div className="d-flex justify-content-start input-group col-lg-10">
              <input
                type="text"
                className="form-control text-left align-middle col-lg-12"
                id={this.props.uniqueID.concat("addCommentateur_URL")}
                placeholder=" -- "
                value={this.props.reference.ref_link}
                onChange={e => {
                  this.props.showConsoleLog && console.log("url change init 2 : ", e.target.value)
                  this.setVideoTitle(e.target.value)
                  this.setState({ playedStart: 0, playedEnd: 1 })
                }}
                disabled={this.props.addOrModifyOrVisualize === "visualize" || (this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase)}
              />
              {this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase &&
                <em
                  className="w-100 robotoFont16px "
                  style={{ fontSize: window.innerWidth > 575.98 ? '15px' : '13px', color: "#E55C5C" }}
                >
                  Cette source existe déjà sur Spot. Vous n'avez pas besoin de renseigner les champs grisés ci-dessous.
                </em>
              }
            </div>
          </div>
        }


        {this.props.showConsoleLog && console.log("aff mediatypeIDFilter", this.props.sourceType, this.state.mediatypeIDFilter)}
        {this.props.showConsoleLog && console.log("aff getMediatypeidFromPersonid", this.props.sourceType, this.getMediatypeidFromPersonid(this.props.listmedia, this.props.reference.media_idperson))}


        {/* Si média : nom de l'émission */}
        {false && !showAllMediasTable && (this.props.reference.ref_type_title === "audio" || this.props.reference.ref_type_title === "vidéo") &&
          this.getMediatypeidFromPersonid(this.props.listmedia, this.props.reference.media_idperson) === 1 &&
          <div className="row form-group col-lg-12 mt-4">
            <div
              className="m-0 p-0 robotoFont16px col-lg-2 "
              style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
            >
              Nom de l'émission
            </div>
            <div className="d-flex justify-content-start input-group col-lg-10 ">
              <input
                type="text"
                className="form-control text-left col-lg-12"
                id={this.props.uniqueID.concat("addEmissionTitle")}
                placeholder=" -- "
                value={this.props.reference.ref_BroadcastTitle}
                onChange={e => this.props.setReferenceNewAttributeValue("ref_BroadcastTitle", e.target.value)}
                disabled={this.props.addOrModifyOrVisualize === "visualize" || (this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase)}
              />
            </div>
          </div>
        }


        {/* Titre du support : article, émission */}
        {// this.props.reference.ref_type_title !== "vidéo" &&
          this.state.mediatypeSelectedName !== "Réseau social" && // !showAllMediasTable &&
          // this.getMediatypeidFromPersonid(this.props.listmedia, this.props.reference.media_idperson) === 1 &&
          < div className="row form-group col-lg-12 mt-4">
            <div
              className="m-0 p-0 robotoFont16px col-lg-2 "
              style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
            >
              {
                this.props.reference.ref_type_title === "" ? "Titre du support :" :
                  (this.props.reference.ref_type_title.includes("écrit") ? "Titre de l'article :" :
                    (this.props.reference.ref_type_title === "audio" ? "Titre de l'audio :" :
                      (this.props.reference.ref_type_title === "vidéo" ? "Titre de la vidéo :" : "" // "Titre de l'image :"
                      )))
              }
            </div>
            <div className="d-flex justify-content-start input-group col-lg-10 ">
              <input
                type="text"
                className="form-control text-left col-lg-12"
                id={this.props.uniqueID.concat("addTitreSupport")}
                placeholder=" -- "
                value={this.props.reference.ref_title}
                onChange={e => this.props.setReferenceNewAttributeValue("ref_title", e.target.value)}
                disabled={this.props.addOrModifyOrVisualize === "visualize" || (this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase)}
              />
            </div>
          </div>
        }




        {/* Sélection de la date de diffusion / publication */}
        {
          true && // !showAllMediasTable &&
          <div className="row form-group col-lg-12 mt-4">
            <div
              className="m-0 p-0 robotoFont16px col-lg-2 "
              style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
            >
              {this.props.reference.ref_type_title.includes("écrit") || this.props.reference.ref_type_title === "image" ?
                "Date de publication :"
                :
                "Date de diffusion :"
              }
            </div>
            <div className="d-flex justify-content-start input-group col-lg-10">
              <DatePicker
                id={this.props.uniqueID.concat("dateParution")}
                locale="fr"
                className="datePickerLarge pl-2"
                placeholderText="Sélectionner"
                selected={Date.parse(this.props.reference.ref_dateParution)}
                onChange={this.handleDateParution}
                // showTimeSelect
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                disabled={this.props.addOrModifyOrVisualize === "visualize" || (this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase)}
              />
            </div>
          </div>
        }


        {this.props.showConsoleLog && console.log("ref_link", this.props.sourceType, this.props.reference.ref_link)}



        {false &&
          !showAllMediasTable && this.props.reference.ref_type_title === "vidéo" && // Titre de la vidéo (uniquement pour vidéo)
          <div className="row form-group col-lg-12 mt-4 ">
            <div
              className="m-0 p-0 robotoFont16px col-lg-2 "
              style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
            >
              Titre de la vidéo :
            </div>
            <div className="d-flex justify-content-center input-group col-lg-10 ">
              <Form.Control
                as="textarea"
                rows={window.innerWidth > 576 ? '' : 2}
                className="form-control text-left col-lg-12"
                id={this.props.uniqueID.concat("titreVideo")}
                placeholder={"Titre"}
                disabled={this.props.addOrModifyOrVisualize === "visualize" || (this.props.referenceAlreadyInDatabase !== undefined && this.props.referenceAlreadyInDatabase)}
                value={this.props.reference.ref_linkTitle}
                onChange={e => {
                  this.props.setReferenceNewAttributeValue("ref_linkTitle", e.target.value)
                  // this.setState({ reference: { ...this.state.reference, ref_title: this.replaceAll(e.target.value, "'", "\\'") } })
                }} // , () => this.nextStep()
                style={{
                  boder: 'solid',
                  height: window.innerWidth > 576 ? '65px' : '', // this.state.reference.ref_title.length < 49 && window.innerWidth > 576 ? '40px' : '',
                }}
                required
              />
            </div>
          </div>
        }

        {/* Affichage de la vidéo (si url renseigné) */}
        {
          this.props.reference.ref_link !== null && this.props.reference.ref_link !== undefined && this.props.reference.ref_link !== "" && !showAllMediasTable &&
          <div className="d-flex justify-content-center">
            {
              this.props.reference.ref_link.includes('twitter.com') ?

                <TwitterTweetEmbed
                  tweetId={this.props.reference.ref_link.substring(this.props.reference.ref_link.lastIndexOf("/") + 1, this.props.reference.ref_link.indexOf("?") === -1 ? this.props.reference.ref_link.length : this.props.reference.ref_link.indexOf("?"))}
                />
                :
                ((this.props.reference.ref_type_title === "vidéo" || this.props.reference.ref_type_title === "audio") ?
                  this.urls(this.props.reference.ref_link)
                  :
                  ""
                )
            }

          </div>
        }

        {
          false &&
          <p>
            {"Duration : " + this.state.videoDuration +
              " / played : " + this.state.played +
              " / playedStart : " + this.state.playedStart + this.state.played +
              " / playedEnd : " + this.state.playedEnd +
              " / playing : " + this.state.playing +
              " / seeking : " + this.state.seeking}
          </p>
        }

        {this.props.showConsoleLog && console.log("video -> videoDuration : ", this.props.videoDuration)}

        {/* Boutons d'intéraction avec la vidéo */}
        {
          this.props.reference.ref_type_title === "vidéo" && this.props.reference.ref_link !== "" && !showAllMediasTable && // this.state.videoDuration !== -1 && 
          <div className="row justify-content-center">
            <div className="col-lg-8 mt-4 p-3 whiteButtonShadow">

              <div className="row w-100 justify-content-center mt-3 ">
                <div className="row col-lg-11 ">
                  <SliderComponent // 
                    // marks={{ 600: '1h', 1200: '2h' }}
                    min={0} max={0.999999} step='0.001'
                    defaultValue={this.state.played}
                    value={this.state.played}
                    onBeforeChange={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange}
                    onAfterChange={this.handleSeekMouseUp}

                    tipFormatter={value => this.secondsToHHMMSS(Math.floor(value * this.state.videoDuration))}

                  />
                </div>

                <div className="d-flex flex-row w-100 justify-content-center">
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.max(0.0001, this.state.played - 10 / this.state.videoDuration) })
                      this.player.seekTo(Math.max(0.0001, this.state.played - 10 / this.state.videoDuration))
                    }}
                  >
                    -10s<TriangleLeftIcon size={24} />
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.max(0.0001, this.state.played - 1 / this.state.videoDuration) })
                      this.player.seekTo(Math.max(0.0001, this.state.played - 1 / this.state.videoDuration))
                    }}
                  >
                    -1s<TriangleLeftIcon size={24} />
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.max(0.0001, this.state.played + -0.1 / this.state.videoDuration) })
                      this.player.seekTo(Math.max(0.0001, this.state.played + -0.1 / this.state.videoDuration))
                    }}
                  >
                    -0.1s<TriangleLeftIcon size={24} />
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => { this.setState({ playing: !this.state.playing }) }}
                  >
                    {this.state.playing ? 'Pause' : 'Play'}
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.min(0.99999, this.state.played + 0.1 / this.state.videoDuration) })
                      this.player.seekTo(Math.min(0.99999, this.state.played + 0.1 / this.state.videoDuration))
                    }}
                  >
                    <TriangleRightIcon size={24} />+0.1s
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.min(0.99999, this.state.played + 1 / this.state.videoDuration) })
                      this.player.seekTo(Math.min(0.99999, this.state.played + 1 / this.state.videoDuration))
                    }}
                  >
                    <TriangleRightIcon size={24} />+1s
                  </Button>
                  <Button
                    variant="light"
                    onClick={() => {
                      this.setState({ played: Math.min(0.99999, this.state.played + 10 / this.state.videoDuration) })
                      this.player.seekTo(Math.min(0.99999, this.state.played + 10 / this.state.videoDuration))
                    }}
                  >
                    <TriangleRightIcon size={24} />+10s
                  </Button>
                </div>

                {
                  <div className="d-flex flex-row justify-content-around">
                    <Button
                      variant="light"
                      onClick={() => {
                        this.props.setReferenceNewAttributeValue("re_videoStart", this.state.played)
                        this.setState({ playedStart: this.state.played }) // reference: { ...this.state.reference, re_videoStart: this.state.played } })
                      }}
                    >
                      Définir comme début de l'extrait
                    </Button>

                    <Button
                      variant="light"
                      onClick={() => {
                        this.props.setReferenceNewAttributeValue("re_videoEnd", this.state.played)
                        this.setState({ playedEnd: this.state.played })
                      }}
                    >
                      Définir comme fin de l'extrait
                    </Button>
                  </div>
                }

              </div>

              <label className="mt-4" htmlFor="addCommentateur_selectEndTimeLabel">Sélectionner l'extrait de la vidéo en déplaçant les 2 curseurs :</label>

              <div className="row w-100 justify-content-center mt-1 ">
                <div className="row col-lg-11 ">
                  <RangeComponent
                    min={0} max={0.999999} step='0.001'
                    defaultValue={[0, 1]}
                    value={[this.props.reference.re_videoStart, this.props.reference.re_videoEnd]}
                    marks={this.state.marks}
                    disabled={this.props.addOrModifyOrVisualize === "visualize"}

                    onBeforeChange={this.handleSeekMouseDown}
                    onChange={this.handleSeekChange2}

                    tipFormatter={value => this.secondsToHHMMSS(Math.floor(value * this.state.videoDuration))}
                  />
                </div>

                <div className="row w-100 justify-content-center">
                  <Button
                    className="mt-2"
                    variant="light"
                    onClick={() => {
                      this.player.seekTo(this.state.playedStart)
                      this.setState({ playing: true })
                    }}
                  >
                    Jouer l'extrait
                  </Button>
                </div>

              </div>


            </div>
          </div>
        }

        {this.props.showConsoleLog && console.log("Propos directs -> this.props.reference :", this.props.reference)}
        {this.props.showConsoleLog && console.log("Propos directs -> commentateurID :", this.props.commentateurData.idperson)}
        {this.props.showConsoleLog && console.log("Propos directs -> getPersonNameByID :", this.getPersonNameByID(this.props.commentateurData.idperson).concat(this.props.reference.ref_type_title.includes("écrit") ? "\xa0dans\xa0" : "\xa0sur\xa0", this.props.reference.media_pers_title, "\xa0: "))}
        {this.props.showConsoleLog && console.log("Propos directs -> miseEnFormeIntroAvecCitation :", this.props.miseEnFormeIntroAvecCitation)}

        {this.props.showConsoleLog && console.log("Propos directs -> re_sourcePrimaire :", this.props.reference.re_sourcePrimaire)}

        {
          !showAllMediasTable &&
          <div className="row justify-content-center">
            <div className="col-lg-11 mt-4 pt-4 pb-2 whiteButtonShadow ">
              <div className="form-group ">

                {
                  <div className="row m-0 mb-3 p-0 ">
                    <div
                      className="col-lg-6 m-0 p-0 "
                      style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                    >
                      S'agit-il d'une source primaire ou secondaire ?
                    </div>

                    <div className="col-lg-6 m-0 p-0 ">
                      <Form inline={true} bsPrefix="row justify-content-start col-lg-11 m-0 p-0 ">
                        {[{ title: 'primaire', value: 1 }, { title: 'secondaire', value: 0 }]
                          .map((element, index) => (
                            <div key={this.props.uniqueID.concat(`sourcePrimaireSecondaireCheckbox_${index}`)} className="mr-5 ">
                              <Form.Check
                                custom
                                // inline
                                label={this.props.capitalizeFirstLetter(element.title)}
                                type='radio'
                                id={this.props.uniqueID.concat(`radioButtonPrimaireSecondaire_${index}`)}
                                onChange={() => {
                                  // this.setState({ reference: { ...this.state.reference, re_sourcePrimaire: element.value } })
                                  this.props.setReferenceNewAttributeValue("re_sourcePrimaire", element.value)
                                }}
                                checked={this.props.reference.re_sourcePrimaire === element.value}
                                disabled={this.props.addOrModifyOrVisualize === "visualize"}
                              />
                            </div>
                          ))
                        }
                      </Form>
                    </div>

                  </div>
                }

                {this.props.reference !== undefined && this.props.reference.re_sourcePrimaire === 1 &&
                  <div className="form-group col-lg-12 ">
                    <label htmlFor="addCommentateur_ProposDirects">
                      {this.props.person === undefined ? // Ajout d'un argument
                        this.props.miseEnFormeIntroAvecCitation("Propos directs de ", this.props.commentateurData.person.pers_firstName.concat(' ', this.props.commentateurData.person.pers_name, this.props.reference.ref_type_title.includes("écrit") ? "\xa0dans\xa0" : "\xa0dans\xa0", this.props.reference.media_pers_title, "\xa0: "))
                        :
                        this.props.miseEnFormeIntroAvecCitation("Propos directs de ", this.props.person.pers_name) + " : "
                      }
                    </label>
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <InputGroup className="">
                          <InputGroup.Prepend>
                            <InputGroup.Text>{'\u00ab'}</InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            aria-label="Amount (to the nearest dollar)"
                            id={this.props.uniqueID.concat("addCommentateur_ProposDirects")}
                            placeholder=" -- "
                            className="text-left"
                            value={this.props.reference.re_proposDirect}
                            onChange={e => {
                              this.props.setReferenceNewAttributeValue("re_proposDirect", e.target.value)
                              // this.setState({ reference: { ...this.state.reference, re_proposDirect: e.target.value } })
                            }}
                            disabled={this.props.addOrModifyOrVisualize === "visualize"}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>{'\u00bb'}</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>

                    </div>
                  </div>
                }

                {this.props.reference !== undefined && this.props.reference.re_sourcePrimaire === 0 &&
                  <div className="form-group col-lg-12 ">
                    <label htmlFor="addCommentateur_URL">{"Propos rapportés par\xa0".concat(this.props.reference.media_pers_title, "\xa0:")}</label>
                    <div className="d-flex justify-content-center input-group">
                      <input
                        type="text"
                        className="form-control text-left col-lg-10"
                        id={this.props.uniqueID.concat("addProposRapportes")}
                        placeholder=" -- "
                        value={this.props.reference.re_proposRapporte}
                        onChange={e => {
                          this.props.setReferenceNewAttributeValue("re_proposRapporte", e.target.value)
                          // this.setState({ reference: { ...this.state.reference, re_proposRapporte: e.target.value } })
                        }}
                        disabled={this.props.addOrModifyOrVisualize === "visualize"}
                      />
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
        }

        {this.props.showConsoleLog && console.log("re_electionPresidentielle2022 :", this.props.reference.re_electionPresidentielle2022)}

      </div >

    )
  }
}


export default ComponentReference

