import React, { Component } from 'react';

// Elements Bootstrap
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';



class ComponentIntervenantResume extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showConsoleLog: true,

    show: false,

    showMore: false,
  }


  prettyDate(date, format) {  //FONCTION A REUTILISER DANS TOUS LES FONCTIONS D'APIS
    date = new Date(date);
    var dateObj = date;
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    switch (format) {
      case undefined: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
      case 'année': return year
      case 'mm/aaaa': return (month < 10 ? "0".concat(month) : "".concat(month)) + "/" + year;
      default: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
    }
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {

    this.props.showConsoleLog && console.log('ComponentIntervenantResume -> commentateurData : ', this.props.commentateurData)

    let person = this.props.commentateurData !== undefined ? this.props.commentateurData.person : this.props.personData

    return (

      <div className="row justify-content-center m-0 p-0 ">
        <div className="row col-lg-11 m-0 p-0 ">
          <div className="d-flex justify-content-center col-12 col-lg-2 m-0 p-0 ">
            <img
              className="rounded "
              src={person.pers_picture !== undefined && person.pers_picture !== null ?
                (person.pers_picture.slice(0, 4) === 'http' ? person.pers_picture : (this.props.url_image + person.pers_picture))
                : ""}
              // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
              // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
              // title={this.state.hovered ? "Cliquer pour en savoir +" : ""}
              alt=""
              style={{ maxHeight: '200px', maxWidth: '100%', filter: 'none' }}
            />
          </div>
          <div className={"col-12 col-lg-10 m-0 p-3 pl-4 border rounded robotoFont14px ".concat(window.innerWidth > 575.98 ? "" : "mt-3 ")}>
            <div className="pt-1">{"Nom : " + person.pers_firstName + " " + person.pers_name}</div>
            {person.pers_birthDate !== null && <div className="pt-1">{"Date de naissance : " + this.prettyDate(person.pers_birthDate)}</div>}
            {person.som_nationality !== null && <div className="pt-1">{"Nationalité : " + person.som_nationality}</div>}
            {person.pers_link !== null &&
              <div className="pt-1">{"Site internet :\xa0"}
                <a
                  href={'//' + person.pers_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {person.pers_link}
                </a>
              </div>
            }
            {person.pers_mail1 !== null &&
              <div className="pt-1">
                {"Envoyer un email :\xa0"}
                <a href={"mailto:" + person.pers_mail1}>{person.pers_mail1}</a>
              </div>
            }
            <div className="mt-2">
              {person.pers_facebook !== null &&
                <a
                  href={"https://www.facebook.com/" + person.pers_facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="ml-4"
                    src={this.props.url_image + "Réseaux_Facebook_Icon2.jpg"}
                    alt="Footer"
                    style={{ height: '27px', 'objectFit': 'contain' }}
                  />
                </a>
              }
              {person.pers_twitter !== null && person.pers_twitter !== undefined &&
                <a
                  href={"https://twitter.com/" + person.pers_twitter.slice(1)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="ml-4"
                    src={this.props.url_image + "Réseaux_Twitter_Icon2.jpg"}
                    alt="Footer"
                    style={{ height: '27px', 'objectFit': 'contain' }} />
                </a>
              }
            </div>

          </div>


          <div className="row col-lg-12 m-0 mt-3 p-0 ">
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th className="robotoFont14px text-center align-middle">#</th>
                  <th className="robotoFont14px align-middle " >Postes</th>
                  <th className="robotoFont14px text-center align-middle">Date de début</th>
                  <th className="robotoFont14px text-center align-middle">Date de fin</th>
                </tr>
              </thead>
              <tbody>
                {person.organisationList !== undefined && person.organisationList.length > 0 &&
                  person.organisationList
                    .sort(function (a, b) { return new Date(b.so_datedebut) - new Date(a.so_datedebut) })
                    .slice(0, this.state.showMore ? person.organisationList.length : 6)
                    .map((element, index) => (
                      <tr key={`professionMandat_${index}`}>
                        <td className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}>{index + 1}</td>
                        <td className={"text-left align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}>
                          {this.capitalizeFirstLetter(element.so_profession) + (element.organisationName !== null ?
                            (" de " + element.organisationName) : "")}
                        </td>
                        <td className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}>
                          {element.so_datedebut !== null ? this.prettyDate(element.so_datedebut, 'mm/aaaa') : ""}
                        </td>
                        <td className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}>
                          {element.so_datefin !== null ? this.prettyDate(element.so_datefin, 'mm/aaaa') : ""}
                        </td>
                      </tr>
                    ))
                }

              </tbody>
            </Table>

            <div className="d-flex flex-row justify-content-center w-100">
              <Button
                onClick={() => this.setState({ showMore: !this.state.showMore })}
                className="d-inline-flex justify-content-center align-items-center jeContribueBox col-6 col-lg-3 m-0 p-1 "
                style={{ backgroundColor: "#fadbd8", borderColor: " #f5b7b1 " }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
              >
                <div className="ml-3 mr-3 mt-1 mb-1" style={{ color: " #E55C5C " }}>
                  {this.state.showMore ? "Voir moins" : "Voir plus"}
                </div>
              </Button>
            </div>

          </div>
        </div>

      </div>



    )
  }
}


export default ComponentIntervenantResume

