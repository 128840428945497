import React, { useState } from 'react';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';


function ModalWindow({ modalType, buttonText, buttonClassName, modalReasonQuestion, modalReasonOptions, modalTitle, modalText, modalQuestion, modalButtonOkText, modalButtonOkClassName, modalButtonNokText, quoteID, buttonAction }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }

  const tableHistorique = [
    {
      date: "9 novembre 2020 à 13:34",
      id: "Pautard",
      categorie: "Ajout d'argument",
      detail: "Argument '' ",
    },
    {
      date: "9 novembre 2020 à 7:49‎",
      id: "Lamiot",
      categorie: "Ajout d'un commentateur",
      detail: "Commentateur ''",
    },
    {
      date: "1 novembre 2020 à 13:37",
      id: "Bédévore",
      categorie: "Modification du 'Quoi ?'",
      detail: "Modification '' ",
    },
    {
      date: "1 novembre 2020 à 13:37",
      id: "Bédévore",
      categorie: "Suppression d'un argument",
      detail: "Argument '' "
    },
    {
      date: "27 octobre 2020 à 09:52",
      id: "Lamiot",
      categorie: "Ajout d'un argument",
      detail: "Argument '' ",
    },
    {
      date: "",
      id: "",
      categorie: "",
      detail: "",
    },
  ]


  const tableSuggestionSources = [
    {
      media: "Le Monde",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
    {
      media: "Le Figaro",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
    {
      media: "Libération",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
    {
      media: "Twitter",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
    {
      media: "Facebook",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
    {
      media: "",
      titre: "",
      lien: "",
      dejaSurSpot: "",
    },
  ]



  return (
    <div>
      <Button variant="primary" onClick={handleShow} className={buttonClassName} style={{ 'height': '40px' }}>
        {buttonText}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={ (modalType === "historique" || modalType === "suggestionSources") ? "xl" : "lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {
            (modalType === undefined || modalType === "Modération") &&
            <div>

              <strong className="mt-4">{modalReasonQuestion}</strong>

              <Form className="ml-4 mt-4">
                {modalReasonOptions.map((element, index) => (
                  <div key={`default-radio${index}`} className="mb-3">
                    <Form.Check
                      custom
                      type={'radio'}
                      id={`default-radio-${index}`}
                      label={element}
                      name={"modalRadioButtons"}
                    />
                  </div>
                ))}
                <Form.Check
                  custom
                  type={'radio'}
                  id={`default-radio-autre`}
                  label={'Autre : '}
                  name={"modalRadioButtons"}
                />
                <input className="form-control text-left form-control-sm mt-2 " type="text" placeholder="-- Veuillez indiquer la raison --" />
              </Form>

              <strong className="mt-4">{modalText}</strong>
              <div className="mt-4 "><h4>{modalQuestion}</h4></div>
            </div>
          }

          {
            modalType === "historique" &&
            <div>
              <Table striped bordered hover size="sm" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <thead>
                  <tr>
                    <th className="align-middle " style={{}}>Date</th>
                    <th className="align-middle ">Utilisateur</th>
                    <th className="align-middle ">Type de contribution</th>
                    <th className="align-middle ">Détail</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableHistorique.map((element, index) => (
                      <tr key={`tableHistorique_${index}`}>
                        <td className="align-middle ">{element.date}</td>
                        <td className="align-middle ">{element.id}</td>
                        <td className="align-middle ">{element.categorie}</td>
                        <td className="align-middle ">{element.detail}</td>
                      </tr>
                    ))
                  }

                </tbody>
              </Table>
            </div>
          }

          {
            modalType === "suggestionSources" &&
            <div>
              <div className="mt-2">SPOT vous propose des articles en lien avec ce débat. Aidez-nous à compléter ce débat ;)</div>
              <Table striped bordered hover size="sm" className="mt-4" style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <thead>
                  <tr>
                    <th className="align-middle " style={{}}>Média</th>
                    <th className="align-middle ">Titre</th>
                    <th className="align-middle ">Lien</th>
                    <th className="align-middle ">Déjà sur SPOT ?</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableSuggestionSources.map((element, index) => (
                      <tr key={`tableSuggestionSources_${index}`}>
                        <td className="align-middle ">{element.media}</td>
                        <td className="align-middle ">{element.titre}</td>
                        <td className="align-middle ">{element.lien}</td>
                        <td className="align-middle ">{element.dejaSurSpot}</td>
                      </tr>
                    ))
                  }

                </tbody>
              </Table>

              {false &&
                <form method="GET" action="https://www.qwant.com" id="form">
                  <div align="left">
                    <table bgcolor="#FFFFFF">
                      <tbody>
                        <tr>
                          <td>
                            <a href="https://www.qwant.com">
                              <img src="https://www.qwant.com/img/logo/q-48.png" border="0" alt="Qwant" align="absmiddle" />
                            </a>
                            <input className="" type="text" name="q" id="query" size="31" maxlength="255" />
                            <input type="hidden" name="l" value="fr" />
                            <input type="submit" name="btnG" value="go" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              }
              
            </div>
          }


        </Modal.Body>

        <Modal.Footer>
          {modalType === "Modération" &&
            <div>
              <Button variant="secondary mr-3" onClick={handleClose}>
                {modalButtonNokText}
              </Button>
              <Button
                variant="primary"
                className={modalButtonOkClassName}
                onClick={() => {
                  handleClose()
                }}
              >
                {modalButtonOkText}
              </Button>
            </div>
          }

          {modalType === "historique" &&
            <div>
              <Button variant="primary" className={modalButtonOkClassName} onClick={() => handleClose()}>{modalButtonOkText}</Button>
            </div>
          }


        </Modal.Footer>

      </Modal>
    </div>
  );
}


export default ModalWindow
