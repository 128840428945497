import React, { Component } from 'react';

import ComponentIntervenantResume from './ComponentIntervenantResume'

import { PencilIcon } from '@primer/octicons-react'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';



class ModalWindowCommentateur extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showConsoleLog: true,

    show: false,
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  render() {

    this.props.showConsoleLog && console.log('ModalWindowCommentateur -> this.props.commentateurData.person : ', this.props.commentateurData.person)


    return (

      <div>

        <div className="">
          <div className="d-flex align-items-center justify-content-center ">
            <Button
              variant="primary"
              onClick={() => {
                this.props.keepPopover !== undefined && this.props.keepPopover(true)
                this.setState({ show: true })
              }}
              className='d-flex flex-row align-items-center m-0 p-0 octiconBox'
              title="Infos sur la personne"
              style={{ backgroundColor: 'transparent' }}
            >
              <span className="d-flex align-items-center tooltipStyle" style={{ color: "white", backgroundColor: 'transparent', textDecoration: "underline dotted" }} >
                {this.props.commentateurData.person.pers_firstName !== null && this.props.commentateurData.person.pers_firstName !== undefined ?
                  this.props.commentateurData.person.pers_firstName.concat("\xa0", this.props.commentateurData.person.pers_name)
                  :
                  this.props.commentateurData.person.pers_name
                }
              </span>
            </Button>
          </div>

          <Modal
            show={this.state.show}
            onHide={() => {
              this.props.keepPopover !== undefined && this.props.keepPopover(false)
              this.setState({ show: false })
            }}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby=""
            className=""
          >

            <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
              <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">

                <h6 className=" ml-auto mr-auto" style={{ fontSize: '25px', fontWeight: 'bold' }}>{this.props.commentateurData.person.pers_firstName + " " + this.props.commentateurData.person.pers_name}</h6>

              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              <ComponentIntervenantResume
                url_image={this.props.url_image}
                showConsoleLog={this.props.showConsoleLog}

                commentateurData={this.props.commentateurData}

                keepPopover={this.props.keepPopover}

                format="column"
              />

              {true &&
                <a
                  className="row w-100 justify-content-center text-center robotoFont16px mt-3 "
                  href={'/intervenantDebat/' + this.props.commentateurData.person.idperson}
                >
                  <div className="">
                    {this.props.miseEnFormeIntroAvecCitation("Voir la page Spot de", this.props.commentateurData.person.pers_name, 1)}
                  </div>
                </a>
              }

            </Modal.Body>

          </Modal>

        </div>



      </div>

    )
  }
}


export default ModalWindowCommentateur