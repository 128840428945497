import React, { Component } from 'react';
import {
  PlusCircleIcon,
  PencilIcon, XIcon, TrashIcon
} from '@primer/octicons-react'

import 'rc-slider/assets/index.css';

import '../full.css';


// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Autres components
import ComponentReference from './ComponentReference'
import ComponentSources from './ComponentSources'


class ComponentFormulaireReference extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.player = React.createRef();
  }

  state = {

    show: false,

    commentateur: {
      quote_link_typeID: 0,
    },

    commentateurData: {
      idperson: 0,
      pers_name: "",
      pers_type: "someone",
      so_typeID: 0,
      so_profession: "",
      orga_typeID: 0
    },

    referenceNew: { // Création d'une nouvelle référence
      ref_title: "",
      ref_type_id: 0, // 3
      ref_type_title: "",
      ref_BroadcastTitle: "",
      ref_link: "", // "https://www.youtube.com/watch?v=L2HlxgOXiQM"
      ref_linkTitle: "",
      ref_linkChannel: "",
      ref_dateParution: "",
      ref_lieuParution: "",

      ref_creationDate: "",
      ref_deleteDate: "",
      ref_completeness: 0,

      ref_referencePayante: false,
      ref_prix: 0.001,
      ref_nombrePages: 0,
      ref_editeur: "",

      re_proposDirect: "",
      re_proposRapporte: "",
      re_videoStart: 0.0001,
      re_videoEnd: 0.999,
      re_extractPageNumber: 0,
      re_extractPayant: false,
      re_sourcePrimaire: -1,
      re_electionPresidentielle2022: 0,
      qap_creationDate: "",
      qap_deleteDate: "",
      qap_dateDebut: "",
      qap_dateFin: "",

      media_idperson: 0,
      media_orga_typeID: 0,
      media_ot_title: "",
      media_pers_picture: "",
      media_pers_title: "",
      media_pers_type: "",
      media_reliability: 0,
      media_typeTitle: "",

      idquote_link_type: 0,
      qlt_title: "",

    },

    reference: {}, // Références à visualiser

    creerPersonne: false,

    showConsoleLog: true,

    // reorganizeMediasListData: [],
    reorganizeMediasListExpandRow: {},

    selectedFilterMediaName: '',

    errorMessage: false,
    errorIndex: 0,

    afficherOptions: false,

    personToDeleteSelected: -1,

    sourceSelected: false,
    referenceAlreadyInDatabase: false,

    showAllMediasTable: true,

    changeMainQuote: false,

    listperson: [],
    firstRow: 0,
    numberOfPersons: 20,

    referenceIDToChange: -1,
    addOrModifyOrVisualize: "visualize", // visualize, add, modify, delete

    showAllParts: false,

    type_person: "someone",

    listmedia: [],
    mediatypeID: 0, // type de medium : 1 - Média ; 2 - Revue scientifique ; 3 - Réseau social etc.

  }


  componentDidMount() {
    this.initData()
    // this.reorganizeMediasList(this.props.listmedia)
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {

  }


  initData = (exceptions) => {

    this.props.showConsoleLog && console.log("initData -> reference 0 :", this.props.reference)

    this.setState({
      commentateur: {
        ...this.state.commentateur,
        quote_link_typeID: this.props.addPosition === "pour" ? 1 : (this.props.addPosition === "contre" ? 2 : 3)
      },
      commentateurData: this.props.commentateurData !== undefined ? this.props.commentateurData : {
        idperson: 0,
        pers_name: "",
        pers_type: this.state.type_person,
        so_typeID: 0,
        so_profession: "",
        orga_typeID: 0
      },

      type_person: "someone", // (exceptions !== undefined && exceptions === "type_person") ? 
      mediatypeID: 0, // this.props.reference !== undefined ? this.props.reference.media_orga_typeID : 0,
      // mediaID: this.props.reference !== undefined && this.props.reference.media_idperson !== undefined ? this.props.reference.media_idperson : 0,

      afficherOptions: false,
      selectedFilterMediaName: '',
      creerPersonne: false,

      personToDeleteSelected: -1,
      sourceSelected: false,
      showAllMediasTable: true,
      changeMainQuote: this.props.quoteOrArgumentForm === "mainQuote",

      listperson: [],
      firstRow: 0,
      numberOfPersons: 20,
      referenceIDToChange: -1,
      addOrModifyOrVisualize: "visualize",
    }, () => {
      // this.reorganizePersonsList(this.state.listperson)
      // this.reorganizeMediasList(this.props.listmedia)
    })


    if (this.props.reference === undefined) {

      this.clearReferenceNew()

    } else {

      var temp = this.props.reference
      this.props.showConsoleLog && console.log("initData -> reference 1 :", temp)
      this.setState({ referenceNew: temp })

    }

    this.MediaAPIs()


  }

  clearReferenceNew = () => {

    this.setState({

      referenceNew: {
        ref_title: "",
        ref_type_id: 0, // 3
        ref_type_title: "",
        ref_BroadcastTitle: "",
        ref_link: "", // "https://www.youtube.com/watch?v=L2HlxgOXiQM"
        ref_linkTitle: "",
        ref_linkChannel: "",
        ref_dateParution: "",
        ref_lieuParution: "",

        ref_creationDate: "",
        ref_deleteDate: "",
        ref_completeness: 0,

        ref_referencePayante: false,
        ref_prix: 0.001,
        ref_nombrePages: 0,
        ref_editeur: "",

        re_proposDirect: "",
        re_proposRapporte: "",
        re_videoStart: 0.0001,
        re_videoEnd: 0.999,
        re_extractPageNumber: 0,
        re_extractPayant: false,
        re_sourcePrimaire: -1,
        re_electionPresidentielle2022: 0,

        qap_creationDate: "",
        qap_deleteDate: "",
        qap_dateDebut: "",
        qap_dateFin: "",

        media_idperson: 0,
        media_orga_typeID: 0,
        media_ot_title: "",
        media_pers_picture: "",
        media_pers_title: "",
        media_pers_type: "",
        media_reliability: 0,
        media_typeTitle: "",

        idquote_link_type: this.props.addPosition === "pour" ? 1 :
          (this.props.addPosition === "contre" ? 2 :
            (this.props.addPosition === "sous conditions" ? 3 : 0)),
        qlt_title: this.props.addPosition,

      },
    })

  }




  MediaAPIs = (mediatypeID, textFilter) => {
    if (this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key
      let apiInstance = new this.props.spot_api.MediaApi()
      let opts = { mediatypeID: null, filterText: null }
      if (mediatypeID !== undefined && mediatypeID > 0) {
        opts['mediatypeID'] = mediatypeID
      }
      if (textFilter !== undefined && textFilter.length > 0) {
        opts['filterText'] = textFilter
      }

      this.props.showConsoleLog && console.log('ComponentFormulaireReference -> mediatypeID : ', mediatypeID)

      apiInstance.mediaGet(0, 20, opts, (error, data, response) => {
        this.props.showConsoleLog && console.log('ComponentFormulaireReference -> MediaAPIs : ', data)
        this.setState({ listmedia: data })
      });
    }
  }



  checkChangeReference = () => {

    this.props.showConsoleLog && console.log("checkChangeReference -> referenceIDToChange :", this.state.referenceIDToChange)

    if (this.state.referenceIDToChange === -1) {
      this.props.showConsoleLog && console.log("checkChangeReference -> Pas de référence sélectionnée ")

    } else {

      let referencePrev = this.props.referenceList[this.state.referenceIDToChange]
      let referenceNew = this.state.referenceNew
      let sameReference = JSON.stringify(referencePrev) === JSON.stringify(referenceNew)

      this.props.showConsoleLog && console.log("checkChangeReference -> referencePrev : ", referencePrev)
      this.props.showConsoleLog && console.log("checkChangeReference -> referenceNew : ", referenceNew)
      this.props.showConsoleLog && console.log("checkChangeReference -> same check ////// ")
      this.props.showConsoleLog && console.log("checkChangeReference -> sameReference : ", sameReference)


      let dataFromExtractReferencePrev = {}

      dataFromExtractReferencePrev.re_proposDirect = referencePrev.re_proposDirect
      dataFromExtractReferencePrev.re_proposRapporte = referencePrev.re_proposRapporte
      dataFromExtractReferencePrev.re_videoStart = referencePrev.re_videoStart
      dataFromExtractReferencePrev.re_videoEnd = referencePrev.re_videoEnd
      dataFromExtractReferencePrev.re_extractPageNumber = referencePrev.re_extractPageNumber
      dataFromExtractReferencePrev.re_extractPayant = referencePrev.re_extractPayant
      dataFromExtractReferencePrev.re_sourcePrimaire = referencePrev.re_sourcePrimaire
      dataFromExtractReferencePrev.qap_dateDebut = referencePrev.qap_dateDebut
      dataFromExtractReferencePrev.qap_dateFin = referencePrev.qap_dateFin

      let dataFromExtractReferenceNew = {}

      dataFromExtractReferenceNew.re_proposDirect = referenceNew.re_proposDirect
      dataFromExtractReferenceNew.re_proposRapporte = referenceNew.re_proposRapporte
      dataFromExtractReferenceNew.re_videoStart = referenceNew.re_videoStart
      dataFromExtractReferenceNew.re_videoEnd = referenceNew.re_videoEnd
      dataFromExtractReferenceNew.re_extractPageNumber = referenceNew.re_extractPageNumber
      dataFromExtractReferenceNew.re_extractPayant = referenceNew.re_extractPayant
      dataFromExtractReferenceNew.re_sourcePrimaire = referenceNew.re_sourcePrimaire
      dataFromExtractReferenceNew.qap_dateDebut = referenceNew.qap_dateDebut
      dataFromExtractReferenceNew.qap_dateFin = referenceNew.qap_dateFin

      let sameExtractReference = JSON.stringify(dataFromExtractReferencePrev) === JSON.stringify(dataFromExtractReferenceNew)

      this.props.showConsoleLog && console.log("checkChangeReference -> sameExtractReference : ", sameExtractReference)


      let dataFromReferenceOnlyPrev = {}

      dataFromReferenceOnlyPrev.ref_title = referencePrev.ref_title
      dataFromReferenceOnlyPrev.ref_BroadcastTitle = referencePrev.ref_BroadcastTitle
      dataFromReferenceOnlyPrev.ref_type_id = referencePrev.ref_type_id
      dataFromReferenceOnlyPrev.ref_link = referencePrev.ref_link
      dataFromReferenceOnlyPrev.ref_linkTitle = referencePrev.ref_linkTitle
      dataFromReferenceOnlyPrev.ref_linkChannel = referencePrev.ref_linkChannel
      dataFromReferenceOnlyPrev.ref_dateParution = referencePrev.ref_dateParution
      dataFromReferenceOnlyPrev.ref_lieuParution = referencePrev.ref_lieuParution
      dataFromReferenceOnlyPrev.ref_creationDate = referencePrev.ref_creationDate
      dataFromReferenceOnlyPrev.ref_deleteDate = referencePrev.ref_deleteDate
      dataFromReferenceOnlyPrev.ref_reliability = referencePrev.ref_reliability
      dataFromReferenceOnlyPrev.ref_completeness = referencePrev.ref_completeness
      dataFromReferenceOnlyPrev.ref_referencePayante = referencePrev.ref_referencePayante
      dataFromReferenceOnlyPrev.ref_prix = referencePrev.ref_prix
      dataFromReferenceOnlyPrev.ref_nombrePages = referencePrev.ref_nombrePages
      dataFromReferenceOnlyPrev.ref_editeur = referencePrev.ref_editeur

      let dataFromReferenceOnlyNew = {}

      dataFromReferenceOnlyNew.ref_title = referenceNew.ref_title
      dataFromReferenceOnlyNew.ref_BroadcastTitle = referenceNew.ref_BroadcastTitle
      dataFromReferenceOnlyNew.ref_type_id = referenceNew.ref_type_id
      dataFromReferenceOnlyNew.ref_link = referenceNew.ref_link
      dataFromReferenceOnlyNew.ref_linkTitle = referenceNew.ref_linkTitle
      dataFromReferenceOnlyNew.ref_linkChannel = referenceNew.ref_linkChannel
      dataFromReferenceOnlyNew.ref_dateParution = referenceNew.ref_dateParution
      dataFromReferenceOnlyNew.ref_lieuParution = referenceNew.ref_lieuParution
      dataFromReferenceOnlyNew.ref_creationDate = referenceNew.ref_creationDate
      dataFromReferenceOnlyNew.ref_deleteDate = referenceNew.ref_deleteDate
      dataFromReferenceOnlyNew.ref_reliability = referenceNew.ref_reliability
      dataFromReferenceOnlyNew.ref_completeness = referenceNew.ref_completeness
      dataFromReferenceOnlyNew.ref_referencePayante = referenceNew.ref_referencePayante
      dataFromReferenceOnlyNew.ref_prix = referenceNew.ref_prix
      dataFromReferenceOnlyNew.ref_nombrePages = referenceNew.ref_nombrePages
      dataFromReferenceOnlyNew.ref_editeur = referenceNew.ref_editeur

      let sameReferenceOnly = JSON.stringify(dataFromReferenceOnlyPrev) === JSON.stringify(dataFromReferenceOnlyNew)

      this.props.showConsoleLog && console.log("checkChangeReference -> sameReferenceOnly : ", sameReferenceOnly)

      if (sameReference && sameExtractReference && sameReferenceOnly) {
        this.props.showConsoleLog && console.log("checkChangeReference -> La référence n'a pas changé ")
        this.finishUpdate2()
      } else {

        let referenceNew = this.cleanApostrophFromReference(this.state.referenceNew)

        if (!sameReferenceOnly) {

          this.props.showConsoleLog && console.log("checkChangeReference -> Modification de reference only ")

          this.changeReferenceOnly(referenceNew, sameExtractReference)

        } else if (!sameExtractReference) {

          this.props.showConsoleLog && console.log("checkChangeReference -> Pas de modification de reference only ")

          this.changeExtractReference(referenceNew)

        } else {
          this.props.showConsoleLog && console.log("checkChangeReference -> Ne devrait pas arriver ")
        }

      }

    }

  }



  changeReferenceOnly = (referenceNew, sameExtractReference) => {

    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.ReferenceApi()

    this.props.showConsoleLog && console.log('changeReferenceOnly -> referenceNew :', referenceNew)

    // Modifier une référence (reference)
    this.props.queryTracker('ComponentFormulaireReference.js', 'changeReferenceOnly')
    apiInstance.referencesPatch(referenceNew, (error, data, response) => {
      if (error) {
        this.props.showConsoleLog && console.log('changeReferenceOnly -> erreur 101 ')

        console.error(error)
        this.setState({ errorMessage: true, errorIndex: 101 })
      } else {

        this.props.showConsoleLog && console.log('changeReferenceOnly -> Modification de reference only ok ')

        if (!sameExtractReference) {

          this.props.showConsoleLog && console.log("changeReferenceOnly -> Modification d'extract Reference ")

          this.changeExtractReference(referenceNew)

        } else {

          this.props.showConsoleLog && console.log("changeReferenceOnly -> Pas de modification d'extract Reference ")
          this.finishUpdate2()

        }

      }
    })
  }

  changeExtractReference = (referenceNew) => {

    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.ReferenceApi()

    this.props.showConsoleLog && console.log('changeReferenceOnly -> referenceNew :', referenceNew)

    let referenceExtractID = referenceNew.reference_extract_ID

    // Modifier une référence (reference)
    this.props.queryTracker('ComponentFormulaireReference.js', 'changeReferenceOnly')
    apiInstance.referenceExtractReferenceExtractIDPatch(referenceExtractID, referenceNew, (error, data, response) => {
      if (error) {
        this.props.showConsoleLog && console.log('changeReferenceOnly -> erreur 110 ')

        console.error(error)
        this.setState({ errorMessage: true, errorIndex: 110 })
      } else {

        this.props.showConsoleLog && console.log('changeReferenceOnly -> change reference ok ')
        this.finishUpdate2()

      }
    })
  }

  deleteReference = (idReferenceToDelete) => {

    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.ReferenceApi()

    this.props.showConsoleLog && console.log('deleteReference -> idReferenceToDelete :', idReferenceToDelete)

    // Modifier une référence (reference)
    this.props.queryTracker('ComponentFormulaireReference.js', 'deleteReference')
    apiInstance.referenceReferenceIDDelete(idReferenceToDelete, (error, data, response) => {
      if (error) {
        this.props.showConsoleLog && console.log('deleteReference -> erreur 111 ')

        console.error(error)
        this.setState({ errorMessage: true, errorIndex: 111 })
      } else {

        this.props.showConsoleLog && console.log('deleteReference -> delete reference ok ')
        this.finishUpdate2()

      }
    })

  }

  changeArgumentCommentateurPosition = (referenceNew) => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> commentateur ', this.state.commentateur)
    this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> quoteLinkID ', this.props.quoteLinkID)
    this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> commentateur ', this.state.commentateur)
    this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> quoteLinkTypeID ', this.state.commentateur.quote_link_typeID)
    this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> referenceNew ', referenceNew)

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.queryTracker('ComponentFormulaireReference.js', 'changeArgumentCommentateurPosition')
    apiInstance.commentateurCausaliteCommentateurIDPatch(this.state.commentateurData.idperson, this.props.quoteLinkID,
      this.state.commentateur.quote_link_typeID, referenceNew, (error, data, response) => { // Modifier la position d'un commentateur d'une causalité (quote_link_author_position)
        if (error) {
          console.error(error);
          this.props.showConsoleLog && console.log('changeArgumentCommentateurPosition -> erreur 112 ')
          this.setState({ errorMessage: true, errorIndex: 112 })
        }
        else {

          // Rechargement des commentateurs pour update des ref sur la page Citation
          // this.props.commentateursAPIs(parseInt(this.props.quoteID))

          this.finishUpdate()

        }
      })

  }

  finishUpdate2 = () => {

    this.props.commentateursAPIs(this.props.quoteID, this.props.commentateurData.person.idperson)  // Rechargement des commentateurs pour update des ref sur la page Citation

    this.setState({ addOrModifyOrVisualize: "visualize" })

  }

  finishUpdate = () => {

    this.props.showConsoleLog && console.log('finishUpdate -> OK ')
    this.props.showConsoleLog && console.log('finishUpdate -> CommentateurCausaliteAPIs ', this.props.CommentateurCausaliteAPIs)

    this.props.keepPopover !== undefined && this.props.keepPopover(false)
    this.setState({
      afficherOptions: false, // show: false 
    }, () => {
      this.props.changeShowComponentReference()
      if (this.state.changeMainQuote) {
        this.props.commentateursAPIs(this.props.quoteID)
      } else { // Mise à jour des référence des arguments
        this.props.CommentateurCausaliteAPIs(this.props.quoteLinkID)
      }
    }) // , parseInt(this.state.commentateurData.idperson), "refresh"))

  }

  checkAddReference = () => {

    this.props.showConsoleLog && console.log("checkAddReference in")
    
    if (this.state.referenceNew.media_idperson !== 0) { // Si la personne existe déjà dans la BDD

      let idperson = this.state.commentateurData.idperson

      this.props.showConsoleLog && console.log("checkAddReference -> Le média existe déjà dans la BDD")
      this.props.showConsoleLog && console.log("checkAddReference -> idperson", idperson)

      this.checkReferenceAlreadyExist()

    } else {
      this.props.showConsoleLog && console.log("checkAddReference -> Problème d'id ?")
    }
  }


  checkReferenceAlreadyExist = () => {



    this.props.showConsoleLog && console.log('checkReferenceAlreadyExist -> referenceAlreadyInDatabase', this.state.referenceAlreadyInDatabase)

    if (this.state.referenceAlreadyInDatabase) {

      this.props.showConsoleLog && console.log('checkReferenceAlreadyExist -> reference reuse')

      let referenceNew = this.cleanApostrophFromReference(this.state.referenceNew)
      let commentateurID = this.props.commentateurData.person.idperson

      this.checkLinkQuoteTo(commentateurID, referenceNew)

    } else {

      this.props.showConsoleLog && console.log('checkReferenceAlreadyExist -> Create new reference')
      this.addReference()

    }
  }


  addReference = () => {

    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.ReferenceApi();

    let referenceNew = this.cleanApostrophFromReference(this.state.referenceNew)

    let commentateurID = this.props.commentateurData.person.idperson
    let quoteID = this.props.quoteID
    let quoteLinkTypeID = this.props.commentateurData.quote_link_typeID

    this.props.showConsoleLog && console.log('addReference -> idperson : ', commentateurID)
    this.props.showConsoleLog && console.log('addReference -> referenceNew : ', referenceNew)
    this.props.showConsoleLog && console.log('addReference -> quoteID : ', quoteID)
    this.props.showConsoleLog && console.log('addReference -> id_quoteModality : ', this.props.quote.id_quoteModality)
    this.props.showConsoleLog && console.log('addReference -> quoteLinkTypeID : ', quoteLinkTypeID)
    this.props.showConsoleLog && console.log('addReference -> commentateurData : ', this.props.commentateurData)


    this.props.queryTracker('ComponentFormulaireReference.js', 'addReference')
    apiInstance.referencesPost(commentateurID, this.props.quote.id_quoteModality, quoteLinkTypeID, referenceNew, (error, data, response_ref) => { // Ajouter une nouvelle référence (table reference)
      if (error) {
        this.props.showConsoleLog && console.log('addReference -> error : referencesPost', error)
        console.error(error);
      }
      else {

        this.props.showConsoleLog && console.log('addReference -> référence créée')

        this.finishUpdate2()
        // this.props.commentateursAPIs(quoteID, commentateurID)  // Rechargement des commentateurs pour update des ref sur la page Citation

        // this.props.keepPopover !== undefined && this.props.keepPopover(false)
        // this.setState({
        //   afficherOptions: false, //show: false 
        // })
        // this.props.changeShowComponentReference()

        // this.checkLinkQuoteTo(idperson, referenceNew)

      }
    })

  }


  checkLinkQuoteTo = (idperson, referenceNew) => {

    this.props.showConsoleLog && console.log("checkLinkQuoteTo in")

    if (this.state.changeMainQuote) { // Ajout d'une personne à une quote
      this.props.showConsoleLog && console.log("checkLinkQuoteTo -> Ajout d'une personne à une quote")
      this.props.showConsoleLog && console.log("checkLinkQuoteTo -> idperson", idperson)
      this.linkQuoteToPerson(idperson, referenceNew)
    } else { // Ajout d'une personne à un argument (causalité)
      this.props.showConsoleLog && console.log("checkLinkQuoteTo -> Ajout d'une personne à un argument")
      this.linkSourceToPerson(idperson)
    }

  }

  cleanApostrophFromReference(referenceNew) {

    let returnObject = {}

    // Nom des variables à changer dans l'API
    let temporaryVariable = {
      ref_title: this.replaceAll(this.state.referenceNew.ref_title, "'", "\\'"),
      ref_linkTitle: this.replaceAll(this.state.referenceNew.ref_linkTitle, "'", "\\'"),
      ref_linkChannel: this.replaceAll(this.state.referenceNew.ref_linkChannel, "'", "\\'"),
      ref_BroadcastTitle: this.replaceAll(this.state.referenceNew.ref_BroadcastTitle, "'", "\\'"),
      ref_lieuParution: this.replaceAll(this.state.referenceNew.ref_lieuParution, "'", "\\'"),
      re_videoStart: this.state.referenceNew.re_videoStart === 0 ? 0.0001 : this.state.referenceNew.re_videoStart,
      re_proposDirect: this.replaceAll(this.state.referenceNew.re_proposDirect, "'", "\\'"),
      re_proposRapporte: this.replaceAll(this.state.referenceNew.re_proposRapporte, "'", "\\'"),
    }

    returnObject = Object.assign(referenceNew, temporaryVariable)

    return returnObject
  }


  linkQuoteToPerson = (idperson, referenceNew) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.showConsoleLog && console.log('linkQuoteToPerson -> idperson : ', idperson)
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> quoteID : ', parseInt(this.props.quoteID))
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> id_quoteModality : ', this.props.quote.id_quoteModality)
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> commentateur : ', this.state.commentateur)
    this.props.showConsoleLog && console.log('linkQuoteToPerson -> referenceNew : ', referenceNew)

    this.props.queryTracker('ComponentFormulaireReference.js', 'linkQuoteToPerson')
    apiInstance.commentateurCommentateurIDPost(idperson, this.props.quote.id_quoteModality, this.state.commentateur.quote_link_typeID, referenceNew,
      (error, data, response) => { // Lier une quote à une personne (quote_author_position)
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('linkQuoteToPerson -> Lien quote -> personne OK')
          this.props.showConsoleLog && console.log('linkQuoteToPerson -> response : ', response)

          this.finishUpdate2()

        }
      })
  }

  linkSourceToPerson = (idperson, referenceNew) => { // Lier un argument à une personne (quote_link_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    this.props.showConsoleLog && console.log('linkSourceToPerson -> idperson : ', idperson)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> quoteLinkID : ', this.props.quoteLinkID)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> commentateur : ', this.state.commentateur)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> quote_link_typeID : ', this.state.commentateur.quote_link_typeID)
    this.props.showConsoleLog && console.log('linkSourceToPerson -> referenceNew : ', referenceNew)

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.queryTracker('ComponentFormulaireReference.js', 'linkSourceToPerson')
    apiInstance.commentateurCausaliteCommentateurIDPost(idperson, this.props.quoteLinkID, this.state.commentateur.quote_link_typeID, referenceNew,
      (error, data, response) => { // Lier un argument à une personne (quote_link_author_position)
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('linkSourceToPerson -> Lien quote -> personne OK')
          this.props.showConsoleLog && console.log('linkSourceToPerson -> response : ', response)


          let apiInstance2 = new this.props.spot_api.PersonApi()

          this.props.queryTracker('ComponentFormulaireReference.js', 'linkSourceToPerson')
          apiInstance2.personPersonIDGet(idperson, (error, data, response) => { // Récupérer une personne pour avoir le nom dans l'historique
            if (error) {
              console.error(error)
            }
            else {

              let res = JSON.parse(response.text)
              let resObj = res[0]

              this.props.showConsoleLog && console.log('personPersonIDGet -> data : ', data)
              this.props.showConsoleLog && console.log('personPersonIDGet -> response : ', response)
              this.props.showConsoleLog && console.log('personPersonIDGet -> res : ', res)

              this.props.showConsoleLog && console.log("linkSourceToPerson -> CommentateurCausaliteAPIs : ", this.props.CommentateurCausaliteAPIs)

              //this.setState({ show: false }, () => this.props.CommentateurCausaliteAPIs(this.props.quoteLinkID))

              this.props.CommentateurCausaliteAPIs(this.props.quoteLinkID)
              this.props.changeShowComponentReference()

            }
          })
        }
      })
  }


  alreadySelectedCheck = (idperson) => {
    let stateReturn = "not selected"

    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> idperson", idperson)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> listcommentateursPourId", this.props.listcommentateursPourId)
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> includes", this.props.listcommentateursContreId.includes(idperson))
    this.props.showConsoleLog && console.log("ComponentFormulaireCommentateur -> alreadySelectedCheck -> stateReturn", stateReturn)

    if (this.props.listcommentateursPourId !== undefined && this.props.listcommentateursPourId.includes(idperson)) {
      stateReturn = "pour"
    }
    if (this.props.listcommentateursPeutetreId !== undefined && this.props.listcommentateursPeutetreId.includes(idperson)) {
      stateReturn = "peut etre"
    }
    if (this.props.listcommentateursContreId !== undefined && this.props.listcommentateursContreId.includes(idperson)) {
      stateReturn = "contre"
    }

    return stateReturn

  }


  replaceAll(string, search, replace) {
    let returnString = ""
    if (string !== "" && string !== null && string !== undefined) {
      returnString = string.split(search).join(replace)
    }
    return returnString
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  sourceSelectedChange = (booleanValue) => {
    this.setState({ sourceSelected: booleanValue })
  }





  getMediaFromArrayOfMediaList = (idperson) => {
    const listmedia = this.props.listmedia
    const mediaObject = {}

    this.props.showConsoleLog && console.log('getMediaFromArrayOfMediaList -> listmedia :', listmedia)

    if (listmedia !== undefined && idperson !== 0) {
      listmedia.forEach((element) => {
        if (element.person.idperson === idperson) {
          Object.assign(mediaObject, element.person)
        }
      })
    }

    return mediaObject
  }


  setReferenceNewAttributeValue = (attributeName, attributeValue, attributeValue2) => {

    this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeName : ", attributeName)
    this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeValue : ", attributeValue)

    if (attributeName === "re_sourcePrimaire") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_sourcePrimaire: attributeValue } })
    }
    if (attributeName === "re_electionPresidentielle2022") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_electionPresidentielle2022: attributeValue } })
    }
    if (attributeName === "mediaSelection") {
      // let tempSelectedMedia = this.getMediaFromArrayOfMediaList(attributeValue)
      // this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> tempSelectedMedia : ", tempSelectedMedia)

      let tempSelectedMedia = attributeValue.person
      this.setState({
        referenceNew: {
          ...this.state.referenceNew,
          media_idperson: tempSelectedMedia.idperson,
          // media_orga_typeID: tempSelectedMedia.orga_typeID,
          // media_ot_title: tempSelectedMedia.ot_title,
          media_pers_picture: tempSelectedMedia.pers_picture,
          media_pers_title: tempSelectedMedia.pers_name,
          media_pers_type: tempSelectedMedia.pers_type,
        }
      })
    }
    if (attributeName === "ref_type") {
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> attributeValue : ", attributeValue)
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> listreferencetype : ", this.props.listreferencetype)
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue -> listreferencetype[] : ", this.props.listreferencetype[attributeValue - 1].type_title)

      this.setState({ referenceNew: { ...this.state.referenceNew, ref_type_id: attributeValue, ref_type_title: this.props.listreferencetype[attributeValue - 1].type_title } })
    }
    if (attributeName === "ref_link") {
      this.getReferenceFromUrlAPI(attributeValue)  //Suppression des espaces ?
    }
    if (attributeName === "ref_linkANDref_title") {
      // this.setState({ referenceNew: { ...this.state.referenceNew, ref_link: attributeValue, ref_linkTitle: attributeValue2, re_videoStart: 0.0001, re_videoEnd: 1 } })
      this.getReferenceFromUrlAPI(attributeValue, attributeValue2)
    }
    if (attributeName === "ref_linkTitle") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_linkTitle: attributeValue } })
    }
    if (attributeName === "ref_title") {
      this.props.showConsoleLog && console.log("setReferenceNewAttributeValue in ref_title -> attributeValue : ", attributeValue)
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_title: attributeValue } })
    }
    if (attributeName === "ref_dateParution") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_dateParution: attributeValue } })
    }
    if (attributeName === "initVideoExtract") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoStart: 0.0001, re_videoEnd: 0.999 } })
    }
    if (attributeName === "re_proposDirect") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_proposDirect: attributeValue } })
    }
    if (attributeName === "re_proposRapporte") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_proposRapporte: attributeValue } })
    }
    if (attributeName === "re_videoStartEnd") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoStart: attributeValue, re_videoEnd: attributeValue2 } })
    }
    if (attributeName === "re_videoStart") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoStart: attributeValue } })
    }
    if (attributeName === "re_videoEnd") {
      this.setState({ referenceNew: { ...this.state.referenceNew, re_videoEnd: attributeValue } })
    }
    if (attributeName === "ref_BroadcastTitle") {
      this.setState({ referenceNew: { ...this.state.referenceNew, ref_BroadcastTitle: attributeValue } })
    }

  }

  getReferenceFromUrlAPI = (urlSearch, optionalLinkTitle) => {
    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    let apiInstance = new this.props.spot_api.ReferenceApi()

    this.props.showConsoleLog && console.log("getReferenceFromUrlAPI -> urlSearch : ", urlSearch)
    this.props.showConsoleLog && console.log("getReferenceFromUrlAPI -> optionalLinkTitle : ", optionalLinkTitle)

    if (urlSearch !== "") { // l'url n'est pas vide

      this.props.queryTracker('ComponentFormulaireReference.js', 'getReferenceFromUrlAPI')
      // Lier une référence existante à une quote existante et à un commentateur existant (quote_author_reference)
      apiInstance.referenceFromURLGet(urlSearch, (error, data, response) => {
        if (error) {
          this.props.showConsoleLog && console.log("getReferenceFromUrlAPI -> error : ", error)
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log("getReferenceFromUrlAPI -> data : ", data)


          if (data.length === 0) { // l'url n'existe pas dans la BDD
            this.setState({
              referenceAlreadyInDatabase: false,
              showAllMediasTable: true,
              referenceNew: {
                ...this.state.referenceNew,

                ref_link: urlSearch,
                ref_title: optionalLinkTitle === undefined ? "" : optionalLinkTitle,
                ref_BroadcastTitle: "",
                ref_linkTitle: optionalLinkTitle === undefined ? "" : optionalLinkTitle,
                ref_linkChannel: "",
                ref_dateParution: "",
                ref_lieuParution: "",
                ref_creationDate: "",
                ref_deleteDate: "",
                ref_completeness: 0,

                ref_referencePayante: false,
                ref_prix: 0.001,
                ref_nombrePages: 0,
                ref_editeur: "",

                // media_idperson: 0,
                // media_orga_typeID: 0,
                // media_ot_title: "",
                // media_pers_picture: "",
                // media_pers_title: "",
                // media_pers_type: "",
                // media_reliability: 0,
                // media_typeTitle: "",

                re_videoStart: 0.0001,
                re_videoEnd: 0.999,
              }
            })
          } else { // l'url existe dans la BDD

            let temp = data[0]

            temp.ref_link = urlSearch
            temp.re_videoStart = 0.0001
            temp.re_videoEnd = 0.999
            temp.referenceID = temp.idreference
            // temp.ref_type_title = "vidéo"
            temp.ref_type_title = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.listreferencetype, 'type_id', temp.ref_type_id, 'type_title')  
            temp = Object.assign(this.state.referenceNew, temp)

            this.props.showConsoleLog && console.log("url existe dans la BDD -> referenceNew : ", temp)

            this.setState({
              referenceAlreadyInDatabase: true,
              showAllMediasTable: false,
              referenceNew: temp,
            })
          }

        }
      })

    } else {  // l'url est vide

      this.setState({
        referenceAlreadyInDatabase: false,
        showAllMediasTable: true,
        referenceNew: {
          ...this.state.referenceNew,

          ref_link: urlSearch,
          ref_linkTitle: optionalLinkTitle === undefined ? "" : optionalLinkTitle,

          ref_BroadcastTitle: "",
          ref_linkTitle: "",
          ref_linkChannel: "",
          ref_dateParution: "",
          ref_lieuParution: "",
          ref_creationDate: "",
          ref_deleteDate: "",
          ref_completeness: 0,

          ref_referencePayante: false,
          ref_prix: 0.001,
          ref_nombrePages: 0,
          ref_editeur: "",

          re_videoStart: 0.0001,
          re_videoEnd: 0.999,
        }
      })

    }

  }


  selectReferenceIndexToChange = (idreference) => {

    this.props.showConsoleLog && console.log("selectReferenceIndexToChange -> idreference :", idreference)
    this.props.showConsoleLog && console.log("selectReferenceIndexToChange -> referenceList :", this.props.referenceList)

    if (idreference === -1) { // Fermeture de la partie "modifier la source"

      this.setState({ addOrModifyOrVisualize: "visualize", referenceIDToChange: -1 })
      this.clearReferenceNew() // Réinitialisation de referenceNew

    } else {

      this.props.referenceList.forEach((element, index) => { // Recherche de la référence sélectionnée dans this.props.referenceList
        if (element.idreference === idreference) {
          let referenceTemp = Object.assign({}, this.props.referenceList[index])

          this.props.showConsoleLog && console.log("selectReferenceIndexToChange -> id :", index)
          this.props.showConsoleLog && console.log("selectReferenceIndexToChange -> referenceTemp :", referenceTemp)

          this.setState({ addOrModifyOrVisualize: "modify", referenceIDToChange: index, referenceNew: referenceTemp })
        }
      })

    }


  }


  render() {
    const url_image = this.props.url_image

    const person = this.state.commentateurData
    const listperson = this.state.listperson

    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> referenceList :", this.props.referenceList)
    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> changeMainQuote :", this.state.changeMainQuote)
    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> quoteOrArgumentForm :", this.props.quoteOrArgumentForm)
    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> referenceAlreadyInDatabase :", this.state.referenceAlreadyInDatabase)

    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> commentateurData.idperson :", this.state.commentateurData.idperson)


    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> quoteID :", this.props.quoteID)
    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> commentateurData :", this.props.commentateurData)
    this.props.showConsoleLog && console.log("ComponentFormulaireReference -> idperson :", this.props.commentateurData.person.idperson)


    if (this.state.show !== this.props.showComponentReference) {
      this.setState({ show: this.props.showComponentReference })
    }

    return (

      <div className="">
        <div className="d-flex align-items-center justify-content-center ">

          {
            <Button
              variant="light"
              onClick={() => {
                this.props.keepPopover !== undefined && this.props.keepPopover(true)
                this.props.changeShowComponentReference()
                this.initData()
                // this.setState({ show: this.props.showComponentReference }, () => this.initData())
              }}
              className='row w-100 justify-content-center m-0 p-1 transparentButton rounded'
              title={this.props.referenceList.length === 0 ?
                "Ajouter des sources"
                :
                "Explorer les sources"
              }
            >
              <span className="row w-100 justify-content-center m-0 p-0 octiconStyle" >
                <p className="modifierSource m-0 p-0 pr-2">
                  {this.props.referenceList.length === 0 ?
                    "Ajouter des sources"
                    :
                    "Explorer les sources"
                  }
                </p>
              </span>
            </Button>
          }



        </div>


        <Modal
          show={this.state.show}
          onHide={() => {
            this.props.keepPopover !== undefined && this.props.keepPopover(false)
            this.setState({ afficherOptions: false }) //, show: false })
            this.props.changeShowComponentReference()
          }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">


              {

                <div className="d-flex flex-column justify-content-center ">

                  <div
                    className={"d-flex justify-content-center col-12 m-0 p-0 text-center ".concat("col-lg-12 ")}
                    style={{ fontSize: window.innerWidth > 576 ? '25px' : '17px', fontWeight: 'bold' }}
                  >
                    <span>
                      {
                        this.state.addOrModifyOrVisualize === "add" ? "Ajouter une source\xa0" :
                          (this.state.addOrModifyOrVisualize === "modify" ? "Modifier la source\xa0" :
                            (this.state.addOrModifyOrVisualize === "visualize" ? "Voir les sources\xa0" : ""))
                      }
                    </span>
                    <span>{this.props.quoteOrArgumentForm === "mainQuote" ? "(débat)" : "(argument)"}</span>
                  </div>

                </div>
              }


            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="row w-100 justify-content-center m-0 p-1 " >

            {this.state.addOrModifyOrVisualize === "visualize" && this.props.contributionActivated &&
              <div className='d-flex flex-row justify-content-end col-lg-12 m-0 p-0 '>
                <Button
                  variant="light"
                  onClick={() => {
                    this.setState({ addOrModifyOrVisualize: "add" })
                  }}
                  className='m-0 p-1 transparentButton'
                  title="Ajouter une source"
                >
                  <div className="modifierSource octiconStyle m-0 p-0 pr-2">
                    <span>Ajouter une source</span>
                    <PencilIcon size={20} className="ml-3" />
                  </div>
                </Button>
              </div>
            }

            {this.state.addOrModifyOrVisualize === "add" &&
              <div className='d-flex flex-row justify-content-end col-lg-12 m-0 p-0 '>
                <Button
                  variant="light"
                  onClick={() => {
                    this.setState({ addOrModifyOrVisualize: "visualize" })
                  }}
                  className='m-0 p-1 transparentButton'
                  title="Annuler"
                >
                  <div className="modifierSource octiconStyle m-0 p-0 pr-2">
                    <span>Annuler</span>
                    <XIcon size={20} className="ml-3" />
                  </div>
                </Button>
              </div>
            }

            {this.state.showAllParts && <div className="col-lg-12 m-0 p-0 ">
              <div className="ongletVerticalTitre col-lg-12 mt-4">
                Positionnement du commentateur
              </div>

              <div className="d-flex flex-row justify-content-center col-lg-12 mt-3 ">

                <div className="d-flex col-lg-3 ">
                  <button
                    type="button"
                    className={this.state.quote_link_typeID === 1 ? "col-lg-9 p-2 daccordBox" : "col-lg-9 p-2 whiteButtonShadow"}
                    onClick={() => this.setState({ quote_link_typeID: 1 })}
                  >
                    <strong style={{ color: this.state.quote_link_typeID === 1 ? "black" : "grey" }}>
                      D'accord
                    </strong>
                  </button>
                </div>

                <div className="d-flex col-lg-3 ">
                  <button
                    type="button"
                    className={this.state.quote_link_typeID === 3 ? "col-lg-9 p-2 peutetre" : "col-lg-9 p-2 whiteButtonShadow"}
                    onClick={() => this.setState({ quote_link_typeID: 3 })}
                  >
                    <strong style={{ color: this.state.quote_link_typeID === 3 ? "black" : "grey" }}>
                      Sous conditions
                    </strong>
                  </button>
                </div>

                <div className="d-flex col-lg-3 ">
                  <button
                    type="button"
                    className={this.state.quote_link_typeID === 2 ? "col-lg-9 p-2 pasdaccord" : "col-lg-9 p-2 whiteButtonShadow"}
                    onClick={() => this.setState({ quote_link_typeID: 2 })}
                  >
                    <strong style={{ color: this.state.quote_link_typeID === 2 ? "black" : "grey" }}>
                      Pas d'accord
                    </strong>
                  </button>
                </div>

              </div>

            </div>}


            {
              <div className='col-lg-12 p-0'>


                {/* Renseigner la source */}
                {(this.state.showAllParts || this.state.addOrModifyOrVisualize === "add") &&
                  <div className="w-100 m-0 p-0 ">

                    <div className="ongletVerticalTitre text-center mt-3 ">
                      {(this.props.quoteOrArgumentForm === "mainQuote" ? "Ajouter une source au débat" :
                        (this.props.quoteOrArgumentForm === "argument" ? "Ajouter une source à l'argument" : "What ?"))}
                    </div>

                    <div className="d-flex flex-row m-0 p-0 justify-content-around">

                      {
                        <div className={"col-12 m-0 p-1 pb-4 ".concat("col-lg-10 ")}>
                          <ComponentReference
                            quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                            addOrModifyOrVisualize="add"
                            referenceType="formulaire"
                            // sourceType="secondaire"
                            uniqueID={this.props.uniqueID.concat("_formulaire")}
                            qt_title={this.props.qt_title}

                            referenceAlreadyInDatabase={this.state.referenceAlreadyInDatabase}
                            showAllMediasTable={this.state.showAllMediasTable}
                            // sourceType="primaire"
                            listmedia={this.state.listmedia}
                            listperson={this.state.listperson}
                            listreferencetype={this.props.listreferencetype}
                            listmediatype={this.props.listmediatype}
                            commentateurData={this.state.commentateurData}

                            reference={this.state.referenceNew}

                            listcommentateursPourId={this.props.listcommentateursPourId}
                            listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}
                            listcommentateursContreId={this.props.listcommentateursContreId}

                            capitalizeFirstLetter={this.capitalizeFirstLetter}
                            sourceSelectedChange={this.sourceSelectedChange}
                            setReferenceNewAttributeValue={this.setReferenceNewAttributeValue}
                            commentateursAPIs={this.props.commentateursAPIs}
                            CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                            showConsoleLog={this.props.showConsoleLog}

                            miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                            ReferenceAPIs={this.props.ReferenceAPIs}

                            url_image={this.props.url_image}


                            visitorLocation={this.props.visitorLocation}
                            queryTracker={this.props.queryTracker}
                            spot_api={this.props.spot_api}
                            API_key={this.props.API_key}

                            MediaAPIs={this.MediaAPIs}
                          />
                        </div>
                      }

                      {this.props.showConsoleLog && console.log("nbSP nbSourcePrimaire :", this.props.nbSourcePrimaire)}


                    </div>

                  </div>
                }


                {this.state.addOrModifyOrVisualize === "visualize" && this.props.referenceList.length > 0 &&
                  <div>
                    <div className="ongletVerticalTitre text-center mt-3 ">
                      {"Visualiser les sources"}
                    </div>

                    <div className="mt-3" style={{}}>
                      <ComponentSources // ComponentFormulaireCommentateur
                        quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                        addOrModifyOrVisualize="visualize"
                        addPosition={this.props.addPosition}
                        type="modification"
                        quoteID={this.props.quoteID}
                        quoteLinkID={this.props.quoteLinkID}
                        quoteTitle={this.props.quoteTitle}
                        qt_title={this.props.qt_title}

                        getPersonPictureName={this.getPersonPictureName}
                        commentateursAPIs={this.props.commentateursAPIs}

                        url_image={this.props.url_image}
                        showConsoleLog={this.props.showConsoleLog}

                        keepPopover={this.keepPopover}
                        referenceList={this.props.referenceList}
                        // ReferenceArgumentAPIs={this.ReferenceArgumentAPIs}
                        // commentateurID={this.props.commentateurData.idperson}
                        commentateurData={this.props.commentateurData}
                        miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                        ReferenceAPIs={this.props.ReferenceAPIs}

                        selectReferenceIndexToChange={this.selectReferenceIndexToChange}
                        deleteReference={this.deleteReference}

                        listsomeonetype={this.props.listsomeonetype}
                        listorganisationtype={this.props.listorganisationtype}
                        listreferencetype={this.props.listreferencetype}
                        listmediatype={this.props.listmediatype}
                        listperson={this.props.listperson}
                        listmedia={this.props.listmedia}

                        ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                        CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                        listcommentateursPourId={this.props.listcommentateursPourId}
                        listcommentateursContreId={this.props.listcommentateursContreId}
                        listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}

                        nbSourcePrimaire={this.props.nbSourcePrimaire}
                        nbSourceSecondaire={this.props.nbSourceSecondaire}

                        visitorLocation={this.props.visitorLocation}
                        queryTracker={this.props.queryTracker}
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}
                      />
                    </div>
                  </div>
                }


                {this.state.addOrModifyOrVisualize === "modify" &&
                  <div className="w-100 m-0 p-0 ">

                    <div className="ongletVerticalTitre text-center mt-3 ">
                      {"Modifier une source"}
                    </div>

                    {this.props.showConsoleLog && console.log("this.props.referenceList :", this.props.referenceList)}
                    {this.props.showConsoleLog && console.log("this.props.referenceList :", this.state.referenceNew)}


                    {// this.props.referenceList.length > 0 && 
                      <div className={"d-flex flex-row m-0 p-0 ml-auto mr-auto justify-content-around  ".concat(" col-lg-10 ")}>
                        <ComponentReference
                          quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                          addOrModifyOrVisualize="modify"
                          referenceType="formulairef"
                          // sourceType="secondaire"
                          uniqueID={this.props.uniqueID.concat("_formulaire")}
                          qt_title={this.props.qt_title}

                          reference={this.state.referenceNew} // this.props.referenceList[this.state.referenceIDToChange]

                          referenceAlreadyInDatabase={this.state.referenceAlreadyInDatabase}
                          showAllMediasTable={this.state.showAllMediasTable}
                          // sourceType="primaire"
                          listmedia={this.props.listmedia}
                          listperson={this.state.listperson}
                          listreferencetype={this.props.listreferencetype}
                          listmediatype={this.props.listmediatype}
                          commentateurData={this.state.commentateurData}

                          listcommentateursPourId={this.props.listcommentateursPourId}
                          listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}
                          listcommentateursContreId={this.props.listcommentateursContreId}

                          capitalizeFirstLetter={this.capitalizeFirstLetter}
                          sourceSelectedChange={this.sourceSelectedChange}
                          setReferenceNewAttributeValue={this.setReferenceNewAttributeValue}
                          commentateursAPIs={this.props.commentateursAPIs}
                          CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}
                          selectReferenceIndexToChange={this.selectReferenceIndexToChange}

                          showConsoleLog={this.props.showConsoleLog}

                          miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                          ReferenceAPIs={this.props.ReferenceAPIs}

                          url_image={this.props.url_image}


                          visitorLocation={this.props.visitorLocation}
                          queryTracker={this.props.queryTracker}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}
                        />
                      </div>
                    }

                  </div>
                }



              </div>
            }

            <div className='col-12 col-lg-12 m-0 p-0 rounded '>

              <div className="row w-100 justify-content-center align-items-start m-0 p-0 pt-3">

                {this.props.showConsoleLog && console.log("ref_type_id :", this.state.referenceNew.ref_type_id)}

                {this.state.addOrModifyOrVisualize === "add" && // Ajouter une nouvelle source
                  <div className="d-flex flex-column justify-content-center col-lg-4 ">
                    <Button
                      className="buttonStepper col-lg-12 p-2 "
                      style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                      onClick={() => this.checkAddReference()}
                      disabled={this.state.referenceNew.ref_type_id === 0}
                    >
                      {"Ajouter une nouvelle source".concat(this.props.quoteOrArgumentForm === "mainQuote" ? " (débat)" : " (argument)")}
                    </Button>
                    <div className="row justify-content-center mt-1 mb-3 ">
                      <em
                        className="ml-auto mr-auto text-center robotoFont15px "
                        style={{ fontSize: window.innerWidth > 575.98 ? '13px' : '12px', color: "#E55C5C" }}
                      >
                        {this.state.referenceNew.media_idperson === 0 ?
                          "Veuillez sélectionner un média"
                          :
                          (this.state.referenceNew.ref_type_id === 0 ? "Veuillez sélectionner un type de support" : "")
                        }
                      </em>
                    </div>
                  </div>
                }


                {this.state.addOrModifyOrVisualize === "modify" &&
                  <div className="d-flex flex-row justify-content-center col-lg-4 ">
                    <Button
                      className="buttonStepper col-lg-12 p-2 "
                      style={{ fontSize: window.innerWidth > 575.98 ? '16px' : '14px' }}
                      onClick={() => this.checkChangeReference()}
                      disabled={false}
                    >
                      {"Modifier cette source".concat(this.props.quoteOrArgumentForm === "mainQuote" ? " (débat)" : " (argument)")}
                    </Button>
                  </div>
                }

              </div>

              {this.state.errorMessage &&
                <div className="row justify-content-center" >
                  <div style={{ color: 'red' }}>{"Erreur " + this.state.errorIndex + " ..."}</div>
                </div>
              }

            </div>

          </Modal.Body>

        </Modal>

      </div >

    )
  }

}
export default ComponentFormulaireReference