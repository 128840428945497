import React, { Component } from 'react';

import ComponentTable from './ComponentTable'
import ComponentFormulaireVote from './ComponentFormulaireVote'


// #JM Import HighCharts packages
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official"
import itemSeries from 'highcharts/modules/item-series';
// https://stackoverflow.com/questions/48460482/how-to-import-highcharts-sub-modules-in-react-app
// https://www.highcharts.com/docs/getting-started/install-from-npm
itemSeries(Highcharts);



class ComponentAssemblee extends Component {

  state = {
    showConsoleLog: true,

    scrutinQuote: {},

    allScrutins: [],

    initDataRequest: true,

    visualisationVotes: "partis"  // partis ou deputes
  }

  initData() {

    this.ScrutinQuoteAPI(this.props.quoteID)
    this.ScrutinsAllAPI()
    console.log("ComponentAssemblee quoteID :", this.props.quoteID)

  }


  ScrutinQuoteAPI = (quoteID) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      this.props.showConsoleLog && console.log('ScrutinQuoteAPI in')

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ScrutinApi();

      this.props.queryTracker('Citations_2.js', 'ScrutinQuoteAPI')

      this.props.showConsoleLog && console.log('ScrutinQuoteAPI quoteID :', quoteID)

      apiInstance.getScrutinsFromQuoteIdGet(quoteID, (error, dataScrutins, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('ScrutinQuoteAPI -> dataScrutins : ', dataScrutins)


          apiInstance.getPersonsVoteFromQuoteIdGet(quoteID, (error, dataVotesDeputes, response) => {
            if (error) {
              console.error(error);
            }
            else {
              this.props.showConsoleLog && console.log('ScrutinQuoteAPI -> dataVotesDeputes : ', dataVotesDeputes)


              apiInstance.getPartisVoteFromQuoteIdGet(quoteID, (error, dataVotesPartis, response) => {
                if (error) {
                  console.error(error);
                }
                else {
                  this.props.showConsoleLog && console.log('ScrutinQuoteAPI -> dataVotesPartis : ', dataVotesPartis)

                  dataVotesDeputes.forEach(element => {
                    element["personne_fullName"] = element.depute_pers_prenom + " " + element.depute_pers_nom
                  })

                  let scrutinQuote = {}
                  scrutinQuote['scrutinQuote'] = dataScrutins
                  scrutinQuote['votesPersonnes'] = dataVotesDeputes
                  scrutinQuote['votesPartis'] = dataVotesPartis

                  this.props.showConsoleLog && console.log('ScrutinQuoteAPI -> scrutinQuote final : ', scrutinQuote)

                  this.setState({ scrutinQuote: scrutinQuote });

                }
              });

            }
          });

        }
      });
    }
  }


  ScrutinsAllAPI = () => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      this.props.showConsoleLog && console.log('ScrutinsAllAPI in')

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ScrutinApi();

      this.props.queryTracker('Citations_2.js', 'ScrutinsAllAPI')

      apiInstance.getAllScrutinsGet((error, dataScrutins, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('ScrutinsAllAPI -> dataScrutins : ', dataScrutins)

          dataScrutins.forEach((element, index) => {
            element["scr_dateScrutin"] = element["scr_dateScrutin"].substring(0, 10)
          })

          this.setState({ allScrutins: dataScrutins.sort((a, b) => -1 * a.scr_dateScrutin.localeCompare(b.scr_dateScrutin)) });

        }
      });
    }
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  setVisualisationVote = (voteType) => {
    this.setState({ visualisationVotes: voteType })
  }

  render() {

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("ComponentAssemblee.js -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    const dataScrutinToDisplay = (Object.keys(this.state.scrutinQuote).length === 0 || this.state.scrutinQuote['scrutinQuote'].length === 0) ? {} : this.state.scrutinQuote['scrutinQuote'][0]

    const numToChiffreRomainDict = {
      "12": "XII",
      "13": "XIII",
      "14": "XIV",
      "15": "XV",
      "16": "XVI",
      "17": "XVII",
      "18": "XVIII",
      "19": "XIX",
    }

    const anneeElectionLigislature = {
      "12": "2002",
      "13": "2007",
      "14": "2112",
      "15": "2017",
      "16": "2022",
      "17": "2027",
      "18": "2032",
      "19": "2037",
    }

    const assembleeOptions = {

      chart: {
        type: 'item'
      },

      title: {
        text: Object.keys(dataScrutinToDisplay).length === 0 ? "Assemblée nationale" : 'Assemblée nationale - ' + numToChiffreRomainDict[dataScrutinToDisplay['scr_legislature']] + 'ème législature'
      },

      subtitle: {
        text: (Object.keys(dataScrutinToDisplay).length === 0 ? 'Source : ' : 'Élection de ' + anneeElectionLigislature[dataScrutinToDisplay['scr_legislature']] + '. Source : ') +
          '<a href="https://data.assemblee-nationale.fr/acteurs/deputes-en-exercice" target="_blank">data.assemblee-nationale.fr</a> '
      },

      legend: {
        labelFormat: '{name} <span style="opacity: 0.4">{y}</span>',
        enabled: true,
        navigation:
        {
          activeColor: "#003399",
          animation: true,
          arrowSize: 12,
          enabled: false,
          inactiveColor: "#cccccc",
          style: undefined,
        },
      },

      series: [{
        name: 'Députés',
        keys: ['name', 'y', 'color', 'label'],
        data: [
          ['Nouvelle Union populaire sociale et solidaire (NUPES) - ', 131, '#bb2621', 'NUPES'],  // Nouvelle Union populaire sociale et solidaire
          ['Divers gauche (DVG) - ', 22, '#d3768d', 'DVG'],
          ['Régionaliste (REG) - ', 10, '#80cf5f', 'REG'],
          ['Divers centre (DVC) - ', 5, '#db8e00', 'DVC'],
          ['Renaissance (REN) - ', 245, '#edd700', 'REN'],
          ['Union des démocrates et indépendants (UDI) - ', 3, '#81aaf0', 'UDI'],  // Union des démocrates et indépendants 
          ['Les Républicains (LR) - ', 61, '#4e73dc', 'LR'],
          ['Divers droite (DVD) - ', 11, '#7d4ec8', 'DVD'],
          ['Rassemblement national (RN) - ', 89, '#2d3f80', 'RN']
        ],
        dataLabels: {
          enabled: true,
          format: '{point.label}',
          style: {
            textOutline: '3px contrast'
          }
        },

        // Désactivation des filtres (automatiques par Highcharts) lorsqu'on clique sur un item de la légende
        // https://stackoverflow.com/questions/13275648/disable-click-on-legend-in-highcharts-column-graph
        // https://www.highcharts.com/forum/viewtopic.php?t=7977
        point: {
          events: {
            legendItemClick: function (event) {
              console.log("event 2 :", event);
              return false; // <== returning false will cancel the default action
            }
          }
        },
        allowPointSelect: true,

        // Circular options
        center: ['50%', '88%'],
        size: '170%',
        startAngle: -90,
        endAngle: 90
      }],

      responsive: {
        rules: [{
          condition: {
            maxWidth: 600
          },
          chartOptions: {
            series: [{
              dataLabels: {
                distance: -30
              }
            }]
          }
        }]
      }
    }

    this.props.showConsoleLog && console.log('this.state.scrutinQuote : ', this.state.scrutinQuote)

    const dataVotes = Object.keys(this.state.scrutinQuote).length === 0 ? [] :
      (this.state.visualisationVotes === "partis" ? this.state.scrutinQuote['votesPartis'] : this.state.scrutinQuote['votesPersonnes'])

    const headersList = this.state.visualisationVotes === "partis" ?
      [
        { columnName: "#", attributeInData: "defaultIndex", filterType: "", filterValue: "", classNameAdd: "" },
        { columnName: "Nom du groupe", attributeInData: "parti_pers_nom", filterType: "input", filterValue: "", classNameAdd: "" },
        { columnName: "Vote", attributeInData: "svo_positionVote", filterType: "select", filterValue: "", classNameAdd: "" }
      ]
      :
      (window.innerWidth > 575.98 ?
        [
          { columnName: "#", attributeInData: "defaultIndex", filterType: "", filterValue: "", classNameAdd: "" },
          { columnName: "", attributeInData: "depute_pers_picture", filterType: "", filterValue: "", classNameAdd: "" },
          { columnName: "Nom du député", attributeInData: "personne_fullName", filterType: "input", filterValue: "", classNameAdd: "" },
          { columnName: "Vote du député", attributeInData: "svo_positionVote", filterType: "select", filterValue: "", classNameAdd: "" },
          { columnName: "Groupe parlementaire", attributeInData: "parti_pers_nom", filterType: "select", filterValue: "", classNameAdd: "" }
        ]
        :
        [
          { columnName: "", attributeInData: "depute_pers_picture", filterType: "", filterValue: "", classNameAdd: "" },
          { columnName: "Nom du député", attributeInData: "personne_fullName", filterType: "input", filterValue: "", classNameAdd: "" },
          { columnName: "Vote du député", attributeInData: "svo_positionVote", filterType: "select", filterValue: "", classNameAdd: "" },
          { columnName: "Groupe parlementaire", attributeInData: "parti_pers_nom", filterType: "select", filterValue: "", classNameAdd: "" }
        ]
      )

    this.props.showConsoleLog && console.log("dataVotes :", dataVotes)

    return (

      <div className="w-100 m-0 p-0 ">


        {(Object.keys(dataScrutinToDisplay).length !== 0 && dataVotes.length !== 0) &&
          <div className="d-flex justify-content-center m-0 p-0 ">

            <div className="col-lg-10 alert alert-secondary alert-dismissible fade show" role="alert">
              <strong>Informations sur le scrutin</strong>

              <div className={window.innerWidth > 575.98 ? "d-flex flex-row pt-3 pl-5 " : "pt-3 pl-0 pr-0 m-0"}>

                <div className={window.innerWidth > 575.98 ? "col-lg-7" : ""}>
                  <div className={window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont12px"}>{"Date du scrutin : " + dataScrutinToDisplay['scr_dateScrutin'].substring(0, 10) + " (" + dataScrutinToDisplay['scr_legislature'] + 'ème législature)'}</div>
                  <div className={window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont12px mt-2"}>{"Demandeur : " + dataScrutinToDisplay['scr_demandeur']}</div>
                  <div className={window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont12px mt-2"}>{"Texte de loi : " + dataScrutinToDisplay['scr_titre']}</div>
                </div>

                <div className={window.innerWidth > 575.98 ? "col-lg-5" : ""}>
                  <div className={window.innerWidth > 575.98 ? "robotoFont14px font-weight-bold" : "robotoFont12px mt-3 font-weight-bold"}>{"Résultat du vote : Texte " + dataScrutinToDisplay['scr_sortCode']}</div>

                  <div className={window.innerWidth > 575.98 ? "robotoFont14px mt-2" : "robotoFont12px mt-3"}>{"Détails du vote : "}</div>
                  <div className={window.innerWidth > 575.98 ? "pl-5 robotoFont14px" : "pl-4 robotoFont12px"}>
                    <div className="">{"Nombre de votants : " + dataScrutinToDisplay['scr_nbVotants']}</div>
                    <div className="">{"Nombre de suffrages exprimés : " + dataScrutinToDisplay['scr_nbSuffragesExprimes']}</div>
                    <div className="">{"Nombre de suffrages requis : " + dataScrutinToDisplay['scr_nbSuffragesRequis']}</div>
                    <div className="">{"Nombre de non-votants : " + dataScrutinToDisplay['scr_nbNonVotants']}</div>
                    <div className={parseInt(dataScrutinToDisplay['scr_nbPour']) >= parseInt(dataScrutinToDisplay['scr_nbSuffragesRequis']) ? "font-weight-bold" : ""}>{"Nombre de pour : " + dataScrutinToDisplay['scr_nbPour']}</div>
                    <div className={parseInt(dataScrutinToDisplay['scr_nbContre']) > parseInt(dataScrutinToDisplay['scr_nbSuffragesRequis']) ? "font-weight-bold" : ""}>{"Nombre de contre : " + dataScrutinToDisplay['scr_nbContre']}</div>
                    <div className="">{"Nombre d'abstentions : " + dataScrutinToDisplay['scr_nbAbstensions']}</div>
                  </div>
                </div>
              </div>

              <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>
        }

        <HighchartsReact
          // constructorType={"mapChart"}
          highcharts={Highcharts}
          options={assembleeOptions}
        />

        {dataVotes.length !== 0 &&
          <div className="d-flex justify-content-center mt-4 ">
            {// Boutons de filtres

              [{ buttonId: "partis", buttonText: "Votes par groupe politique" }, { buttonId: "deputes", buttonText: "Votes par député" }]
                .map((element, index) => (
                  <button
                    key={`boutonVisualisationVotes${index}`}
                    className={this.state.visualisationVotes === element.buttonId ? "boutonFiltreNatureBoxSelected ".concat(window.innerWidth > 575.98 ? "ml-5 mr-5 pl-5 pr-5 " : " col-6 ml-auto mr-auto mt-2 ") : "boutonFiltreNatureBox ".concat(window.innerWidth > 575.98 ? "ml-5 mr-5 pl-5 pr-5 " : " col-6 ml-auto mr-auto mt-2 ")}
                    onClick={() => this.setVisualisationVote(element.buttonId)}
                  >
                    <div
                      className={this.state.visualisationVotes === element.buttonId ? "boutonFiltreNatureContentSelected pl-2" : "boutonFiltreNatureContent pl-2 "}
                      style={{ fontSize: window.innerWidth > 575.98 ? "13px" : "11px" }}
                    >
                      {element.buttonText}
                    </div>

                  </button>
                ))

            }
          </div>
        }


        {dataVotes.length !== 0 &&
          <div className="d-flex justify-content-center mt-5 ml-0 mr-0 p-0 col-12 ">
            <div className="d-flex col-lg-10 col-12 m-0 p-0 ">

              <ComponentTable
                headersList={headersList}
                data={dataVotes}

                url_image={this.props.url_image}
              />

            </div>
          </div>
        }

        {//dataVotes.length === 0 &&
          this.state.allScrutins.length !== 0 &&
          <div className="d-flex justify-content-center mt-4 ">
            <div className="col-lg-4">
              <ComponentFormulaireVote
                data={this.state.allScrutins}
                currentItemsSelected={Object.keys(this.state.scrutinQuote).length === 0 ? [] : this.state.scrutinQuote['scrutinQuote']}
              />
            </div>
          </div>

        }


      </div >

    )
  }
}


export default ComponentAssemblee
