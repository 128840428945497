// PAGE QUI AFFICHES TOUTES LES CITATIONS

// @flow
import React, { Component } from 'react';
import { ChevronDownIcon, ThreeBarsIcon, XIcon, ClockIcon, SquareFillIcon } from '@primer/octicons-react'

import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import Sidebar from "react-sidebar";

import { Scrollbars } from 'react-custom-scrollbars';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, EffectCards } from "swiper";

import '/node_modules/swiper/swiper.min.css'
import '/node_modules/swiper/modules/pagination/pagination.min.css'
import '/node_modules/swiper/modules/free-mode/free-mode.min.css'
import '/node_modules/swiper/modules/effect-cards/effect-cards.min.css'


import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

import ComponentDebate from './ComponentDebate'
import ModalWindow from './ModalWindow'
import ModalWindowHistorique from './ModalWindowHistorique'
import ComponentSideMenu from './ComponentSideMenu'

import ComponentPourComprendreCard from './ComponentPourComprendreCard'
import ComponentFormulaireThematiques from './ComponentFormulaireThematiques'
import ComponentFormulaireQuestionnaire from './ComponentFormulaireQuestionnaire'
import ComponentBandeauHautDePage from './ComponentBandeauHautDePage'
import ComponentFooter from './ComponentFooter'
import ComponentCommentateurBox from './ComponentCommentateurBox'
import ComponentArgumentBox from './ComponentArgumentBox'
import ComponentArgumentSmall from './ComponentArgumentSmall'
import ModalFormulaireQuoteManager from './ModalFormulaireQuoteManager'

import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'

import ModalModeration from './ModalModeration'
import ComponentAssemblee from './ComponentAssemblee'

import FlagIcon from './FlagIcon.js'

// Elements Bootstrap
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import ListGroup from 'react-bootstrap/ListGroup'
import ModalWindowHelp from './ModalWindow_Help'
import Spinner from 'react-bootstrap/Spinner'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import Skeleton from 'react-loading-skeleton';

// import { hotjar } from 'react-hotjar'

import $ from "jquery"

//Elements API
// import { ApiClient, QuoteApi, QuoteTypeApi, ArgumentApi, CommentateurApi, ReferenceApi, ThemeApi, TypeApi, QQOQCCApi } from 'spot_api_old'

// Importation des data JSON
import PaysMonde from './data_countries-FR'
import ListeDeputes from './data_nosdeputes.fr_deputes_en_mandat_2020-08-02'

// #JM Import HighCharts packages
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official"
import highchartsMap from "highcharts/modules/map"
import proj4 from "proj4";
import mapDataIEFR from "@highcharts/map-collection/countries/fr/fr-all.geo.json"
import mapDataIEFD from "@highcharts/map-collection/countries/fr/fr-all-all.geo.json"
import mapDataIEEU from "@highcharts/map-collection/custom/european-union.geo.json"
import mapDataIEWO from "@highcharts/map-collection/custom/world.geo.json"

import itemSeries from 'highcharts/modules/item-series';

highchartsMap(Highcharts);

// https://stackoverflow.com/questions/48460482/how-to-import-highcharts-sub-modules-in-react-app
// https://www.highcharts.com/docs/getting-started/install-from-npm
itemSeries(Highcharts);

// const mql = window.matchMedia(`(min-width: 200px)`)

class Citations extends Component {
  constructor(props) {
    super(props);
  }


  state = {
    quote: {
      q_title: "",
      q_text: "",
      q_statut: 1,
      q_typeID: 0,
      themeID: [],
      sousthemeID: 0,
      q_completude: 0,
      q_placeScaleID: 0,
      q_placePositionID: 0,
      q_quoi: "",
      q_combien: "",
      q_ou: "",
      q_quand: "",
      q_pourQui: "",

      placeScaleID: 0,
      placePositionID: 0,

    },

    q_datePublication_temp: "",
    listarguments: [],
    debatsinduits: [],
    listcommentateurs: [],
    nbcommentateurs_Pour: 0,
    nbcommentateurs_PeutEtre: 0,
    nbcommentateurs_Contre: 0,

    listreferences: [],
    argument: {
      quote_link_typeID: 0,
      quote: {
        themeID: [],
        sousthemeID: [],
        q_statut: 1,
        q_typeID: 0,
        q_title: "",
        q_completude: 0,
        q_text: ""
      },
      chapitre_typeID: 0,
    },

    tab_NiveauPreuve_active: false,

    tab_Commentateurs_state: 'active',
    tab_Specialistes_state: 'notActive',
    tab_Institutions_state: 'notActive',
    tab_AutresPays_state: 'notActive',

    tab_villes_state: 'notActive',
    tab_departements_state: 'notActive',
    tab_regions_state: 'notActive',
    tab_europe_state: 'notActive',
    tab_monde_state: 'active',

    listThemes: [],
    listFullMainThemes: [],
    listSousthemes: [],

    argumentParNature: true,

    titreDesParties: [],

    filtresActifsNature: [],
    filtresActifsThematiques: [],
    filtresActifsFamille: [],

    maxThemeSelected: 4,

    checkboxSelectionnerToutNature: true,
    checkboxSelectionnerToutThematiques: true,
    checkboxSelectionnerToutFamille: true,

    checkboxDeselectionnerToutNature: false,
    checkboxDeselectionnerToutThematiques: false,

    prisesPositionsEchelle: ['Pour', 'Vote blanc', 'Contre'], // , 'Non renseigné'

    // State liés au QQOQCC
    descriptions: {},
    places: [],
    qui: [],
    events: [],
    contextes: [],
    situations: [],
    definitions: [],

    bodyMarginLeft: '0px', // window.innerWidth < 575.98 ? '0px' : '80px'
    sidebarDocked: false, // window.innerWidth < 575.98 ? false : true
    menuShowHide: 'Hidden', // window.innerWidth < 575.98 ? 'Hidden' : 'Showed'
    mouseEnterShowSideMenu: false,
    lockSideMenu: false,

    niveauDeLectureList: ['Facile', 'Moyen', 'Difficile'],
    niveauDeLectureValue: 'Moyen',

    droitsDeConnexionList: ['Visiteur', 'Modérateur', 'Développeur'],
    droitsDeConnexionValue: 'Visiteur',
    droitDeConnextionAfficher: true,

    formulaireQuoteIndex: 'Page 1',

    affichageNombreCommentateurs: 3,
    voirPlus: false,

    scrollPosition: 0,

    visualisationTestUtilisateur: true,

    randomListePays: [],
    randomListeDeputes: [],

    affichageFiltresArguments: false,
    filtresArgumentsMouseHover: false,

    ongletComprendreSelected: "quoi",
    ongletQuiPenseQuoiSelected: "commentateurs",  // "commentateurs"  "institutions" "autresPays"
    ongletArgumentsThematiqueSelected: "",

    showConsoleLog: true,

    popoverNatureInformationShow: false,
    popoverNatureInformationHold: false,

    popoverEchelleGeoInformationShow: false,
    popoverEchelleGeoInformationHold: false,

    popoverThematiqueInformationMouseEnter: -1,
    popoverThematiqueInformationShow: [false, false, false, false],
    popoverThematiqueInformationHold: [false, false, false, false],
    popoverThematiqueInformationText: ["", "", "", ""],

    listargumentsLoaded: false,
    mainQuoteLoaded: false,

    visitorLocation: this.props.visitorLocation,

    reorganizeDeputesListColumns: [],
    reorganizeDeputesListData: [],
    reorganizeDeputesListExpandRow: {},
    expanded: [],

    questionnaireSousTitreShow: false,

    popoverLoiActee: false,
    popoverLoiActeeHold: false,

    changeGeneralInformationsHover: false,

    initDataRequest: true,

    natureInformationTable: [],

    listperson: [],

    quoteModalities: [],

    anyCommentateurFactCheckingOK: false,
    anyCommentateurFactCheckingNOK: false,
    anyCommentateurCandidatDeclare2022: false,
    anyCommentateurCandidatPossible2022: false,

    quoteListIDHistorique: [],

    contributionActivated: true,
    toShowLater: true,

    showNuances: false,
    showArguments: false,

    quoteModalitiesSelected: [],
    argumentsSelected: [],

    commentateur_argument_dict: {},
    init_commentateur_argument_dict: true,
  }


  // on commence par executer les fonctions qui donnent la 1ere citation mere et ses filles
  componentDidMount() {
    Events.scrollEvent.register('begin', function () {
    });

    Events.scrollEvent.register('end', function () {
    });

    window.addEventListener('scroll', this.listenToScroll)

  }


  componentDidUpdate() {
    // this.props.showConsoleLog && console.log('ListeDeputes : ', ListeDeputes)
    // this.props.showConsoleLog && console.log('votesDeputesLoiRetraites : ', votesDeputesLoiRetraites)
    // this.props.showConsoleLog && console.log('votesDeputesLoiRetraites : ', votesDeputesLoiRetraites.scrutin.ventilationVotes.organe.groupes.groupe[0].vote.decompteNominatif) // absentions / contres / nonVotants / pours
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  componentWillMount() {

    if (process.env.REACT_APP_NODEENV === "developpement" || process.env.REACT_APP_NODEENV === "development") {
      import('spot_api_dev').then(module => {

        let spot_api = module
        let defaultClient = spot_api.ApiClient.instance;
        let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
        ApiKeyAuth.apiKey = this.props.API_key;
        let apiInstance = new spot_api.QuoteApi();
        let apiInstance2 = new spot_api.HistoryApi();

        this.props.showConsoleLog && console.log("spot_api_dev -> apiInstance :", apiInstance)
        this.props.showConsoleLog && console.log("spot_api_dev -> apiInstance2 :", apiInstance2)

        this.props.showConsoleLog && console.log("spot_api_dev -> historySyntheseQuoteIDGet :", apiInstance2.historySyntheseQuoteIDGet)

        this.props.showConsoleLog && console.log("componentWillMount -> idquote :", parseInt(this.props.match.params.idquote))

        apiInstance2.historySyntheseQuoteIDGet(parseInt(this.props.match.params.idquote), (error, data, response) => {

          this.setState({ q_datePublication_temp: data[0].q_creationDate })
          let lastIdQuote = data[data.length - 1].idquote

          this.props.showConsoleLog && console.log("componentWillMount -> data :", data)
          this.props.showConsoleLog && console.log("componentWillMount -> lastIdQuote :", lastIdQuote)

          if (data === null) {

            // window.location.href = '/error/'

          } else {

            this.props.showConsoleLog && console.log("quoteListIDHistoriqueQuoteIDGet -> data :", data)
            if (parseInt(this.props.match.params.idquote) !== lastIdQuote) {
              window.location.href = '/citations_2/' + lastIdQuote
            }

          }


        })

      })
    } else {

      import('spot_api_prod').then(module => {

        let spot_api = module
        let defaultClient = spot_api.ApiClient.instance;
        let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
        ApiKeyAuth.apiKey = this.props.API_key;
        let apiInstance = new spot_api.QuoteApi();
        let apiInstance2 = new spot_api.HistoryApi();

        this.props.showConsoleLog && console.log("spot_api_prod -> apiInstance :", apiInstance)
        this.props.showConsoleLog && console.log("spot_api_prod -> apiInstance2 :", apiInstance2)
        this.props.showConsoleLog && console.log("spot_api_prod -> quoteListIDHistoriqueQuoteIDGet :", apiInstance.quoteListIDHistoriqueQuoteIDGet)

        apiInstance2.historySyntheseQuoteIDGet(parseInt(this.props.match.params.idquote), (error, data, response) => {

          this.setState({ q_datePublication_temp: data[0].q_creationDate })
          let lastIdQuote = data[data.length - 1].idquote

          this.props.showConsoleLog && console.log("componentWillMount -> data :", data)
          this.props.showConsoleLog && console.log("componentWillMount -> lastIdQuote :", lastIdQuote)

          if (parseInt(this.props.match.params.idquote) !== lastIdQuote) {
            window.location.href = '/citations_2/' + lastIdQuote
          }

        })

      })
    }

    this.setState({ randomListePays: this.getArrayOfRandomInt(4, Object.keys(PaysMonde).length) })
    this.setState({ randomListeDeputes: this.getArrayOfRandomInt(this.state.prisesPositionsEchelle.length, ListeDeputes.deputes.length) })

  }


  initData = () => {
    const quoteID = parseInt(this.props.match.params.idquote)
    this.QuotePrincipaleAPIs(quoteID)
    this.DebatInduitAPIs(quoteID)
    // this.ArgumentsAPIs(quoteID)
    this.commentateursAPIs(quoteID)

    this.reorganizeDeputesList()

  }



  reorganizeDeputesList = () => {
    const data = []

    const deputes = ListeDeputes.deputes
    const prisesPositionsEchelle = this.state.prisesPositionsEchelle   // prisesPositionsEchelle: ['Pour', 'Vote blanc', 'Contre', 'Non renseigné'],

    const selectOptions = {
      'Pour': 'Pour',
      'Vote blanc': 'Vote blanc',
      'Contre': 'Contre',
      // 'Non renseigné': 'Non renseigné'
    }


    const columns = [
      {
        dataField: 'exp', text: '+',
        style: { fontSize: '14px', margin: '0px', paddingLeft: '0px', paddingRight: '0px', paddingBottom: '5px', paddingTop: '5px', textAlign: 'center' },
        headerStyle: { width: '3%', fontSize: '18px', textAlign: 'center', verticalAlign: 'middle' },
        formatter: (cellContent, row) => (
          <span
            className={"badge badge-warning"}
            style={{
              fontSize: '12px',
              margin: "0px",
              backgroundColor: row.prisePosition === "Pour" ?
                "#c2c5e1"
                :
                (row.prisePosition === "Contre" ? "#cfedef" : "")
            }}>
            +
          </span>
        )
      },
      {
        dataField: 'id', text: '#',
        /* filter: textFilter({
          placeholder: '...',
          style: {
            marginTop: '10px'
          }
        }), */
        headerStyle: { width: '10%', fontSize: '18px', textAlign: 'center' },
        style: { fontSize: '14px', margin: '0px', padding: '5px', textAlign: 'center' }
      },
      {
        dataField: 'nom', text: 'Député',
        /* filter: textFilter({
          placeholder: '...',
          style: {
            marginTop: '10px'
          },
        }), */
        headerStyle: { width: '25%', fontSize: '15px', textAlign: 'center' },
        style: { fontSize: '14px', margin: '0px', padding: '5px' }
      },
      {
        dataField: 'groupe_sigle', text: 'Groupe',
        /* filter: textFilter({
          placeholder: '...',
          style: {
            marginTop: '10px'
          },
        }), */
        headerStyle: { width: '10%', fontSize: '15px', textAlign: 'center' },
        style: { fontSize: '14px', margin: '0px', padding: '5px', textAlign: 'center' }
      },
      {
        dataField: 'nom_circo', text: 'Circonscription',
        /* filter: textFilter({
          placeholder: '...',
          style: {
            marginTop: '10px'
          },
        }), */
        headerStyle: { width: '25%', fontSize: '15px', textAlign: 'center' },
        style: { fontSize: '14px', margin: '0px', padding: '5px' }
      },
      {
        dataField: 'prisePosition', text: 'Vote',
        headerStyle: { width: '15%', fontSize: '15px', textAlign: 'center' },
        style: { fontSize: '14px', margin: '0px', padding: '5px' },
        classes: (cell, row, rowIndex, colIndex) => {
          if (rowIndex % 2 === 0) return 'demo-row-even';
          return 'demo-row-odd';
        },
        // filter: selectFilter({ options: selectOptions, placeholder: '...', style: { marginTop: '10px' }, }),
        formatter: (cellContent, row) => (
          selectOptions[row.prisePosition],
          // console.log("formatter -> cellContent : ", cellContent),
          // console.log("formatter -> row : ", row),
          <span
            className={"badge "}
            style={{
              width: "80px",
              fontSize: '12px',
              margin: "0px",
              backgroundColor: row.prisePosition === "Pour" ?
                "#c2c5e1"
                :
                (row.prisePosition === "Contre" ? "#cfedef" : "")
            }}>
            {row.prisePosition}
          </span>
        )
      },
    ]

    this.props.showConsoleLog && console.log('reorganizeDeputesList 1 : ', deputes)

    deputes.forEach(({ depute }) => {
      data.push({
        // exp: "+",
        id: depute.id,
        nom: depute.nom,
        groupe_sigle: depute.groupe_sigle,
        nom_circo: depute.nom_circo.concat(' (', depute.num_deptmt, ')'),
        prisePosition: prisesPositionsEchelle[Math.floor(Math.random() * this.state.prisesPositionsEchelle.length)],
        // source: depute.source ,
      })
    })

    const expandRow = {
      onlyOneExpanding: true,
      // showExpandColumn: true,
      renderer: row => (
        <div>
          {console.log("gregre row : ", row)}
          <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
        </div>
      ),
      // expanded: this.state.expanded,
      // onExpand: this.handleOnExpand,

    }

    this.props.showConsoleLog && console.log('reorganizeDeputesList 2 : ', data)

    this.setState({ reorganizeDeputesListColumns: columns })
    this.setState({ reorganizeDeputesListData: data })
    this.setState({ reorganizeDeputesListExpandRow: expandRow })


  }


  handleOnExpand = (row, isExpand, rowIndex, e) => {

    console.log("gregre row 1 : ", row.id)
    console.log("gregre row 2 : ", isExpand)
    console.log("gregre row 3 : ", rowIndex)
    console.log("gregre row 4 : ", e)

    if (isExpand) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.id)
      }));
    }

  }


  titreDesParties = () => {
    this.props.showConsoleLog && console.log('titreDesParties -> quote : ', this.state.quote)
    this.props.showConsoleLog && console.log('titreDesParties -> qt_title : ', this.state.quote.qt_title)
    if (this.state.quote.qt_title === undefined) {
      return []
    } else if (this.state.quote.qt_title === 'évènement') {
      return []
    } else {
      return [
        { title: "Qui s'est exprimé publiquement ?", to: 'partieQuiPenseQuoi' },
        { title: 'Les arguments', to: 'partieLesArguments' },
        { title: 'Pour aller + loin', to: 'partiePourComprendre' },
        // { title: "Les solutions", to: 'partieSolutions' },
      ]
    }
  }



  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    this.setState({
      scrollPosition: scrolled,
    })
  }


  prettyDate(date, format) {  //FONCTION A REUTILISER DANS TOUS LES FONCTIONS D'APIS
    date = new Date(date);
    var dateObj = date;
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    switch (format) {
      case undefined: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
      case 'année': return year
      default: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
    }
  }

  prettyDate_heure(date) {
    date = new Date(date);
    var dateObj2 = date;
    var month = dateObj2.getUTCMonth() + 1;
    var day = dateObj2.getUTCDate();
    var year = dateObj2.getUTCFullYear();
    var hour = (dateObj2.getUTCHours()) % 24;
    var minute = dateObj2.getUTCMinutes();

    date = (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year + " " + hour + (minute < 10 ? ":0".concat(minute) : ":".concat(minute));

    return date
  }

  prettyDate_h_mn_sec(date) {
    // console.log("/// prettyDate_h_mn_sec /// ")
    // console.log("prettyDate_h_mn_sec 1 :",date)

    date = new Date(date);
    var dateObj2 = date;
    var month = dateObj2.getUTCMonth() + 1;
    var day = dateObj2.getUTCDate();
    var year = dateObj2.getUTCFullYear();
    var hour = (dateObj2.getUTCHours()) % 24;
    var minute = dateObj2.getUTCMinutes();
    var second = dateObj2.getUTCSeconds();

    // console.log("prettyDate_h_mn_sec 2 :",date)

    date = (day < 10 ? "0".concat(day) : day) + "/" +
      (month < 10 ? "0".concat(month) : "".concat(month)) + "/" +
      year + " " +
      hour + ":" +
      (minute < 10 ? "0".concat(minute) : "".concat(minute)) + ":" +
      (second < 10 ? "0".concat(second) : "".concat(second))

    // console.log("prettyDate_h_mn_sec 3 :",date)

    return date
  }

  quoteModalitesAPIs = (q_originID) => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QuoteApi();

      this.props.showConsoleLog && console.log('quoteModalitesAPIs -> q_originID : ', q_originID)

      this.props.queryTracker('Citations_2.js', 'quoteModalitesAPIs')
      apiInstance.quoteModalitiesQuoteIDGet(q_originID, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          this.props.showConsoleLog && console.log('quoteModalitesAPIs -> data : ', data)

          this.setState({ quoteModalities: data, init_commentateur_argument_dict: true })  // init_commentateur_argument_dict : pour réinitialiser les personnes liées à une modalité
          this.commentateursAPIs(q_originID)
        }
      });


    }
  }



  QuotePrincipaleAPIs(quoteID) {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.HistoryApi();

      this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> apiInstance : ', apiInstance)

      apiInstance.historySyntheseQuoteIDGet(quoteID, (error, data, response) => {

        this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> idquote : ', this.props.match.params.idquote)
        this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> hist data : ', data)

        let lastIdQuote = data[data.length - 1].idquote
        this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> lastIdQuote : ', lastIdQuote)

        if (data !== null && quoteID !== lastIdQuote) {
          quoteID = lastIdQuote
        }
        this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> quoteID : ', quoteID)

        this.setState({ quoteListIDHistorique: data })

        apiInstance = new this.props.spot_api.QuoteApi();

        this.props.queryTracker('Citations_2.js', 'QuotePrincipaleAPIs')
        apiInstance.quoteQuoteIDGet(quoteID, (error, data, response) => {
          if (error) {
            console.error(error);
          } else {
            this.props.showConsoleLog && console.log('QuotePrincipaleAPIs : ', data)
            // data.q_datePublication = this.prettyDate(data.q_datePublication);
            data.q_creationDate = this.prettyDate_heure(data.q_creationDate);
            data.q_title = data.q_title.replace("\\'", "'")
            // data.q_text = data.q_text.replace("\\'", "'")
            data.q_datePublication = this.prettyDate_heure(this.state.q_datePublication_temp)

            this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> q_datePublication_temp : ', this.state.q_datePublication_temp)

            this.props.showConsoleLog && console.log('QuotePrincipaleAPIs -> data quote : ', data)

            this.setState({ quote: data, mainQuoteLoaded: true }, () => {
              this.setState({ titreDesParties: this.titreDesParties() })
              this.ArgumentsAPIs(data.qm_originID)
            })

            if (data.qt_title === 'fait' || data.qt_title === 'étude') {
              this.setState({ ongletComprendreSelected: "niveauDePreuve" })
            }

            this.quoteModalitesAPIs(data.q_originID)

          }
        });

      })

    }
  }


  // Permet de récupérer les arguments de la citation quoteID et de les stocker dans this.state.listarguments
  ArgumentsAPIs = (qm_originID) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ArgumentApi();

      this.props.showConsoleLog && console.log('ArgumentsAPIs -> qm_originID : ', qm_originID)

      // Récupérer la liste de tous les arguments d'une citation donnée
      apiInstance.argumentGet(parseInt(qm_originID), (error, data, response) => {
        if (error) {
          console.error(error);
        } else {

          this.props.showConsoleLog && console.log('ArgumentsAPIs -> all : ', data)
          this.props.showConsoleLog && console.log('ArgumentsAPIs -> natureInformationTable : ', this.props.natureInformationTable)


          // Comptage du nombre d'arguments par nature d'argument
          let tempNatureInformationTable = this.props.natureInformationTable

          tempNatureInformationTable.forEach(element => {
            element.nbArguments =
              data
                .filter((argument) => argument.quote.qt_title === element.nature &&
                  (argument.qlt_title === "pour" || argument.qlt_title === "contre" || argument.qlt_title === "comment"))
                .length
          })

          this.props.showConsoleLog && console.log('ArgumentsAPIs -> tempNatureInformationTable : ', tempNatureInformationTable)

          data.forEach((element, index) => {
            this.props.showConsoleLog && console.log('ArgumentsAPIs -> element', index + 1, ":", element)
            let intro = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.quote.q_typeID, 'introduction')
            this.props.showConsoleLog && console.log("ArgumentsAPIs -> intro :", intro)

            let introAdaptee = this.miseEnFormeIntroSansCitation(intro, element["quote"]["q_title"])
            this.props.showConsoleLog && console.log("ArgumentsAPIs -> introAdaptee :", introAdaptee)

            element["quote"]["intro"] = intro
            element["quote"]["introAdaptee"] = introAdaptee
          })

          this.props.showConsoleLog && console.log('ArgumentsAPIs -> data :', data)

          this.setState({ listarguments: data, listargumentsLoaded: true, natureInformationTable: tempNatureInformationTable, init_commentateur_argument_dict: true }) // init_commentateur_argument_dict : pour réinitialiser les personnes liées à une modalité

          // this.MainThemesAPIs(data)


        }
      })
    }
  }


  MainThemesAPIs(listarguments) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key

      let apiInstance = new this.props.spot_api.ThemeApi()

      this.props.queryTracker('Citations_2.js', 'MainThemesAPIs')
      apiInstance.themeThemeprincipauxGet((error, data, response) => {
        if (error) {
          console.error(error)
        } else {
          data.forEach(element => {
            element.t_photo_gris = element.t_photo.replace('.svg', '_gris.svg')
            element.selected = false
          })

          this.setState({ listFullMainThemes: data })
          this.props.showConsoleLog && console.log('MainThemesAPIs : ', data);
        }
      });
    }
  }

  countElementsEqualToValueInArrayOfArguments(arrayOfArguments, value, quoteLinkType) {
    let count = 0

    this.props.showConsoleLog && console.log('countElementsEqualToValueInArrayOfArguments -> data : ', arrayOfArguments)
    this.props.showConsoleLog && console.log('countElementsEqualToValueInArrayOfArguments -> data : ', value)

    arrayOfArguments.forEach(element => {
      if (element.qlt_title === quoteLinkType && element.quote.qt_title === value) {
        count += 1
      }
    })

    return count
  }


  DebatInduitAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QuoteApi();

      this.props.queryTracker('Citations_2.js', 'DebatInduitAPIs')
      apiInstance.quoteQuoteIDDebatsinduitsGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          if (data !== null) {
            data.forEach(element => {
              element.q_datePublication = this.prettyDate(element.q_datePublication);
              element.q_creationDate = this.prettyDate_heure(element.q_creationDate);
              if (element.q_title !== undefined && element.q_text !== undefined) {
                element.q_title = element.q_title.replace("\\'", "'")
                element.q_text = element.q_text.replace("\\'", "'")
              }
              if (element.themes === undefined) {// Si jamais les thèmes des débats ne sont pas récupérés
                this.props.queryTracker('Citations_2.js', 'DebatInduitAPIs')
                apiInstance.quoteQuoteIDGet(element.idquote, (error, data2, response) => {
                  if (error) {
                    console.error(error);
                  } else {
                    if (data2.themes === undefined) {
                      element.themes = [];
                    }
                    else {
                      element.themes = data2.themes;
                    }
                  }
                })
              }
            });
            this.setState({ debatsinduits: data });
          }
        }
      });
    }
  }


  commentateursAPIs = (quoteID, optionnalCommentateurIdToColor) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key
      let apiInstance = new this.props.spot_api.CommentateurApi()

      this.props.showConsoleLog && console.log('commentateursAPIs in -> quoteID : ', parseInt(quoteID))
      this.props.showConsoleLog && console.log('commentateursAPIs in -> optionnalCommentateurIdToColor : ', optionnalCommentateurIdToColor)

      this.props.queryTracker('Citations_2.js', 'commentateursAPIs')
      apiInstance.commentateursGet(parseInt(quoteID), (error, data, response) => {
        if (error) {
          console.error(error);
        } else {

          this.props.showConsoleLog && console.log('commentateursAPIs 1 : ', data)

          this.setState({ listcommentateurs: data })

          this.setState({ nbcommentateurs_Pour: data.filter(({ qlt_title }) => qlt_title === "pour") })
          this.setState({ nbcommentateurs_PeutEtre: data.filter(({ qlt_title }) => qlt_title === "sous conditions") })
          this.setState({ nbcommentateurs_Contre: data.filter(({ qlt_title }) => qlt_title === "contre") })

          this.setState({
            anyCommentateurFactCheckingOK: data.filter(({ qap_factChecking, qlt_title }) => qap_factChecking && qlt_title === "pour").length > 0,
            anyCommentateurFactCheckingNOK: data.filter(({ qap_factChecking, qlt_title }) => qap_factChecking && qlt_title === "contre").length > 0
          })
          this.setState({
            anyCommentateurCandidatDeclare2022: data.filter(({ person }) => [
              // Logo couleur
              419711,	// 	Nathalie Arthaud
              // 419721,	// 	François Asselineau
              71,	// 	Nicolas Dupont-Aignan
              326961,	// 	Anne Hidalgo
              34441,	// 	Yannick Jadot
              161,	// 	Jean Lassalle
              3781,	// 	Marine Le Pen
              191,	// 	Jean-Luc Mélenchon
              // 13931,	// 	Arnaud Montebourg
              17111,	// 	Valérie Pécresse
              419731,	// 	Philippe Poutou
              3861,	// 	Fabien Roussel
              // 419741,	// 	Antoine Waechter
              419751,	// 	Éric Zemmour
              // 419761,	// 	Florian Philippot
              // 18301, // Taubira
              // Logo noir & blanc
              34381, // Macron
            ].includes(person.idperson)).length > 0,
            anyCommentateurCandidatPossible2022: data.filter(({ person }) => [
              // Logo noir & blanc
              -1, //34381, // Macron
            ].includes(person.idperson)).length > 0,
          })

        }
      });
    }
  }


  SousThemesAPIs(value) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ThemeApi();
      let opts = { themesID: [] }
      opts.themesID = value

      this.props.queryTracker('Citations_2.js', 'SousThemesAPIs')
      apiInstance.themeSousthemeGet(opts, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('SousThemesAPIs : ', data);
          if (data != null) {
            this.setState({ listSousthemes: data });
          }
        }
      });
    }
  }


  SuspendreQuoteAPIs(event, quoteID) {
    event.preventDefault();
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QuoteApi();

      this.props.queryTracker('Citations_2.js', 'SuspendreQuoteAPIs')
      apiInstance.quoteQuoteIDSuspendrequotePatch(quoteID, (error, data, response) => {
        if (!error) {
          this.props.showConsoleLog && console.log("quote suspendue");
        }
        this.props.history.push("/");
      });
    }
  }

  DeleteQuoteAPIs(event, quoteID) {
    event.preventDefault();
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QuoteApi();

      this.props.queryTracker('Citations_2.js', 'DeleteQuoteAPIs')
      apiInstance.quoteQuoteIDDelete(quoteID, (error, data, response) => {
        if (!error) {
          this.props.showConsoleLog && console.log("quote supprimée");
        }
        this.props.history.push("/");
      });
    }
  }


  DescriptionsAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'DescriptionsAPIs')
      apiInstance.descriptionsGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('DescriptionsAPIs : ', data)
          if (data.desc_descriptionMoy !== undefined && data.desc_descriptionDiff !== undefined &&
            data.desc_descriptionMoy !== null && data.desc_descriptionDiff !== null) {
            data.desc_descriptionMoy = data.desc_descriptionMoy.replace("\\'", "'")
            data.desc_descriptionDiff = data.desc_descriptionDiff.replace("\\'", "'")
          }
          if (data !== null) {
            this.setState({ descriptions: data });
          }
        }
      });
    }
  }

  PlacesAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'PlacesAPIs')
      apiInstance.placeGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('PlacesAPIs : ', data)
          if (data !== null) {
            this.setState({ places: data });
          }
        }
      });
    }
  }

  QuiAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'QuiAPIs')
      apiInstance.quiGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('QuiAPIs : ', data)
          if (data !== null) {
            this.setState({ qui: data });
          }
        }
      });
    }
  }

  EventAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'EventAPIs')
      apiInstance.eventGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          if (data !== null) {
            data.forEach((element) => {
              element.ev_date = this.prettyDate_heure(element.ev_date);
              if (element.ev_dateFin != null) {
                element.ev_dateFin = this.prettyDate_heure(element.ev_dateFin);
              }
            })
            this.setState({ events: data });
          }
        }
      });
    }
  }

  // ?? = contexte ?
  ContextesAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'ContextesAPIs')
      apiInstance.contextesGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('contextesAPIs : ', data)
          if (data !== null) {
            this.setState({ contextes: data });
          }
        }
      });
    }
  }

  AvantApresAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'AvantApresAPIs')
      apiInstance.avantapresGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('AvantApresAPIs : ', error)
          if (data !== null) {
            this.setState({ situations: data });
          }
        }
      });
    }
  }

  DefinitionAPIs(quoteID) {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.QQOQCCApi();

      this.props.queryTracker('Citations_2.js', 'DefinitionAPIs')
      apiInstance.definitionGet(quoteID, (error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.props.showConsoleLog && console.log('DefinitionAPIs : ', data)
          if (data !== null) {
            this.setState({ definitions: data });
          }
        }
      });
    }
  }



  argumentTrierPar = (type) => {

    if (type === 'nature') {
      this.setState({ argumentParNature: true })
    } else {
      this.setState({ argumentParNature: false })
      var listThematiquesArgumentsSelectionnes = this.getAttributListNiv3(this.state.listarguments, 'quote', 'themes', 't_title')
      if (listThematiquesArgumentsSelectionnes.length > 0 && this.state.ongletArgumentsThematiqueSelected === "") {
        this.setState({ ongletArgumentsThematiqueSelected: listThematiquesArgumentsSelectionnes[0] })
      }
    }

  }


  menuShowHide = index => {
    var { menuShowHide } = this.state

    if (menuShowHide === 'Showed' && !this.state.lockSideMenu) {
      this.setState({ menuShowHide: 'Hidden' })
      this.setState({ sidebarDocked: false })
      this.setState({ bodyMarginLeft: '0px' })
      setTimeout(() => {
        this.setState({
          mouseEnterShowSideMenu: false
        })
      }, 300)

    }
    else {
      this.setState({ menuShowHide: 'Showed' })
      this.setState({ sidebarDocked: true })
      this.setState({ bodyMarginLeft: '80px' })
      setTimeout(() => {
        this.setState({
          mouseEnterShowSideMenu: true
        })
      }, 200)
    }
  }


  getAttributListNiv1 = (objectName, attributeRequest) => {
    var concat = []
    var concatSansDoublons = []

    this.props.showConsoleLog && console.log('getAttributListNiv1 -> objectName : ', objectName)
    this.props.showConsoleLog && console.log('getAttributListNiv1 -> attributeRequest : ', attributeRequest)

    if (Array.isArray(objectName)) {
      objectName.forEach((element) => (
        concat.push($(element).attr(attributeRequest))
      ))
    } else {
      concat.push($(objectName).attr(attributeRequest))
    }

    concatSansDoublons = Array.from(new Set(concat))

    return concatSansDoublons

  }


  getAttributListNiv2 = (objectName, attributeRequestLevel1, attributeRequestLevel2, orderedBy) => {
    var concat = []
    var concatTemp = []
    var concatSansDoublons = []
    var currentObj = ''
    var test = []


    this.props.showConsoleLog && console.log("getAttributListNiv2 -> objectName : ", objectName,
      " / attributeRequestLevel1 : ", attributeRequestLevel1,
      " / attributeRequestLevel2 : ", attributeRequestLevel2,
      " / orderedBy : ", orderedBy,
    )

    // Si objectName est un Array
    if (Array.isArray(objectName)) {

      if (orderedBy === undefined) {

        objectName.forEach((element, index) => {
          currentObj = $(element).attr(attributeRequestLevel1)
          concat.push($(currentObj).attr(attributeRequestLevel2))
        })

      } else {

        this.props.showConsoleLog && console.log("getAttributListNiv22 -> objectName : ", objectName)
        objectName.forEach((element, index) => {
          var tempObj = {
            qt_title: "",
            q_typeID: 0,
          }

          currentObj = $(element).attr(attributeRequestLevel1)

          this.props.showConsoleLog && console.log("getAttributListNiv22 -> currentObj : ", currentObj)

          tempObj.qt_title = $(currentObj).attr(attributeRequestLevel2)
          tempObj.q_typeID = $(currentObj).attr(orderedBy)

          this.props.showConsoleLog && console.log("getAttributListNiv22 -> tempObj : ", tempObj)

          concatTemp.push(tempObj)

          this.props.showConsoleLog && console.log("getAttributListNiv22 -> concatTemp : ", concatTemp)
        })

        this.props.showConsoleLog && console.log("getAttributListNiv22 -> concat avant : ", concatTemp)
        concatTemp.sort(function (a, b) { return a.q_typeID - b.q_typeID; })
        this.props.showConsoleLog && console.log("getAttributListNiv22 -> concat après : ", concatTemp)

        concatTemp.forEach((element, index) => (
          concat.push(element.qt_title)
        ))
        this.props.showConsoleLog && console.log("getAttributListNiv22 -> concat final : ", concat)
      }


    } else {

      this.props.showConsoleLog && console.log("getAttributListNiv23 -> objectName : ", objectName)
      this.props.showConsoleLog && console.log("getAttributListNiv23 -> attributeRequestLevel1 : ", attributeRequestLevel1)
      this.props.showConsoleLog && console.log("getAttributListNiv23 -> attributeRequestLevel2 : ", attributeRequestLevel2)

      currentObj = this.getAttributListNiv1(objectName, attributeRequestLevel1)

      this.props.showConsoleLog && console.log("getAttributListNiv23 -> currentObj : ", currentObj)

      test = currentObj[0]

      if (test !== undefined) {
        this.props.showConsoleLog && console.log("getAttributListNiv23 -> test : ", test)

        test.forEach((element, index) => {
          concat.push($(element).attr(attributeRequestLevel2))
          this.props.showConsoleLog && console.log("getAttributListNiv23 -> concat : ", concat)
        })
      }

      this.props.showConsoleLog && console.log("getAttributListNiv23 -> result concat : ", concat)

    }

    concatSansDoublons = Array.from(new Set(concat))

    return concatSansDoublons

  }


  getAttributListNiv3 = (objectName, attributeRequestLevel1, attributeRequestLevel2, attributeRequestLevel3) => {
    var concat = []
    var concatSansDoublons = []
    var currentObj2 = ''
    var test = []

    this.props.showConsoleLog && console.log('getAttributListNiv3 -> objectName : ', objectName)
    this.props.showConsoleLog && console.log('getAttributListNiv3 -> attributeRequestLevel1 : ', attributeRequestLevel1)
    this.props.showConsoleLog && console.log('getAttributListNiv3 -> attributeRequestLevel2 : ', attributeRequestLevel2)
    this.props.showConsoleLog && console.log('getAttributListNiv3 -> attributeRequestLevel3 : ', attributeRequestLevel3)

    objectName.forEach((element, index) => {

      this.props.showConsoleLog && console.log('getAttributListNiv3 -> element : ', element)
      currentObj2 = this.getAttributListNiv1(this.getAttributListNiv1(element, attributeRequestLevel1), attributeRequestLevel2)
      this.props.showConsoleLog && console.log('getAttributListNiv3 -> currentObj2 / ', index, ' : ', currentObj2)
      this.props.showConsoleLog && console.log('getAttributListNiv3 -> currentObj22 / ', index, ' : ', currentObj2[0])

      test = currentObj2[0]

      if (test !== undefined) {
        test
          .filter(({ t_photo }) => t_photo !== null)
          .forEach((element2) => {
            concat.push($(element2).attr(attributeRequestLevel3))
            this.props.showConsoleLog && console.log('getAttributListNiv3 -> concat : ', concat)
          })
      }
    })

    concatSansDoublons = Array.from(new Set(concat))

    return concatSansDoublons
  }





  filtresActifsNatureV2 = element => {
    var { filtresActifsNature } = this.state

    if (filtresActifsNature.includes(element)) {
      // si l'élément apparait dans la liste des filtres, alors on l'enlève
      this.setState({ filtresActifsNature: [] })
    } else {
      // si l'élément n'apparait pas dans la liste des filtres, alors on l'ajoute
      this.setState({ filtresActifsNature: [element] })
    }

  }


  filtresActifsNature = (element, listNatureArgumentsSelectionnes) => {
    var { filtresActifsNature } = this.state
    var index

    this.props.showConsoleLog && console.log('filtresActifsNature init : ', filtresActifsNature)
    this.props.showConsoleLog && console.log('filtresActifsNature init : ', listNatureArgumentsSelectionnes)

    // alert(element.replace(/[\' &]/g,'_'))

    // si on clique sur sélectionner tout, on vide la liste des filtres et on passe toutes les checkboxes à checked
    if (element === 'Sélectionner tout') {

      filtresActifsNature = []
      this.setState({ filtresActifsNature: filtresActifsNature })
      this.props.showConsoleLog && console.log('filtresActifsNature (Sélectionner tout) : ', filtresActifsNature)
      listNatureArgumentsSelectionnes.map((element) => (
        $(`:checkbox#nature-checkbox-${element.replace(/[' &]/g, '_')}`).prop('checked', true)
      ))


    } else if (element === 'Désélectionner tout') {
      filtresActifsNature = listNatureArgumentsSelectionnes
      this.setState({ filtresActifsNature: filtresActifsNature })
      listNatureArgumentsSelectionnes.map((element) => (
        $(`:checkbox#nature-checkbox-${element.replace(/[' &]/g, '_')}`).prop('checked', false)
      ))
    } else {

      if (filtresActifsNature.includes(element)) {
        // si l'élément apparait dans la liste des filtres, alors on l'enlève
        index = filtresActifsNature.indexOf(element)
        filtresActifsNature.splice(index, 1)
        this.setState({ filtresActifsNature: filtresActifsNature })
      } else {
        // si l'élément n'apparait pas dans la liste des filtres, alors on l'ajoute
        filtresActifsNature.push(element)
        this.setState({ filtresActifsNature: filtresActifsNature })
      }
      this.props.showConsoleLog && console.log('filtresActifsNature (Filtre actif) : ', filtresActifsNature)

    }

    if (filtresActifsNature.length > 0) {
      this.setState({ checkboxSelectionnerToutNature: false })
    } else {
      this.setState({ checkboxSelectionnerToutNature: true })
    }

    if (listNatureArgumentsSelectionnes.length === filtresActifsNature.length) {
      this.setState({ checkboxDeselectionnerToutNature: true })
      this.setState({ checkboxSelectionnerToutNature: false })
    } else {
      this.setState({ checkboxDeselectionnerToutNature: false })
    }

    // alert(filtresActifsThematiques)

  }



  filtresActifsThematiques = (element, listThematiquesArgumentsSelectionnes) => {
    var { filtresActifsThematiques } = this.state
    var index

    // alert(element.replace(/[\' &]/g,'_'))

    // si on clique sur sélectionner tout, on vide la liste des filtres et on passe toutes les checkboxes à checked
    if (element === 'Sélectionner tout') {

      filtresActifsThematiques = []
      this.setState({ filtresActifsThematiques: filtresActifsThematiques })

      listThematiquesArgumentsSelectionnes.map((element) => (
        $(`:checkbox#thematique-checkbox-${element.replace(/[ &]/g, '_')}`).prop('checked', true)
      ))

    } else if (element === 'Désélectionner tout') {
      filtresActifsThematiques = listThematiquesArgumentsSelectionnes
      this.setState({ filtresActifsThematiques: filtresActifsThematiques })

      listThematiquesArgumentsSelectionnes.map((element) => (
        $(`:checkbox#thematique-checkbox-${element.replace(/[ &]/g, '_')}`).prop('checked', false)
      ))
    }
    else {

      if (filtresActifsThematiques.includes(element)) {
        // si l'élément apparait dans la liste des filtres, alors on l'enlève
        index = filtresActifsThematiques.indexOf(element)
        filtresActifsThematiques.splice(index, 1)
        this.setState({ filtresActifsThematiques: filtresActifsThematiques })
      } else {
        // si l'élément n'apparait pas dans la liste des filtres, alors on l'ajoute
        filtresActifsThematiques.push(element)
        this.setState({ filtresActifsThematiques: filtresActifsThematiques })
      }

    }

    if (filtresActifsThematiques.length > 0) {
      this.setState({ checkboxSelectionnerToutThematiques: false })
    } else {
      this.setState({ checkboxSelectionnerToutThematiques: true })
    }

    if (listThematiquesArgumentsSelectionnes.length === filtresActifsThematiques.length) {
      this.setState({ checkboxDeselectionnerToutThematiques: true })
      this.setState({ checkboxSelectionnerToutThematiques: false })
    } else {
      this.setState({ checkboxDeselectionnerToutThematiques: false })
    }

  }



  filtresActifsFamille = (element, listFamillesArgumentsSelectionnes) => {
    var { filtresActifsFamille } = this.state
    var index

    // alert(element.replace(/[\' &]/g,'_'))

    // si on clique sur sélectionner tout, on vide la liste des filtres et on passe toutes les checkboxes à checked
    if (element === 'Sélectionner tout') {

      filtresActifsFamille = []
      this.setState({ filtresActifsFamille: filtresActifsFamille })

      listFamillesArgumentsSelectionnes.map((element) => (
        $(`:checkbox#custom-checkbox-${element.replace(/[ &]/g, '_')}`).prop('checked', true)
      ))


    } else {

      if (filtresActifsFamille.includes(element)) {
        // si l'élément apparait dans la liste des filtres, alors on l'enlève
        index = filtresActifsFamille.indexOf(element)
        filtresActifsFamille.splice(index, 1)
        this.setState({ filtresActifsFamille: filtresActifsFamille })
      } else {
        // si l'élément n'apparait pas dans la liste des filtres, alors on l'ajoute
        filtresActifsFamille.push(element)
        this.setState({ filtresActifsFamille: filtresActifsFamille })
      }

    }

    if (filtresActifsFamille.length > 0) {
      this.setState({ checkboxSelectionnerToutFamille: false })
    } else {
      this.setState({ checkboxSelectionnerToutFamille: true })
    }

    // alert(filtresActifsThematiques)

  }

  getArrayOfRandomInt = (max, lengthOfArray) => {
    return Array(lengthOfArray).fill().map(() => Math.floor(Math.random() * Math.floor(max))) // Math.floor(Math.random() * Math.floor(max))
  }

  setRangeValue = (listChoice, value) => {
    if (listChoice === 'niveau de lecture') {
      this.props.showConsoleLog && console.log("Niveau de lecture :", value)
      this.setState({ niveauDeLectureValue: value })
      // this.props.showConsoleLog && console.log('Citation_2 -> setRangeValue -> niveauDeLectureValue = ', value)
    }
    if (listChoice === 'droit de connexion') {
      this.props.showConsoleLog && console.log("droit de connexion :", value)
      this.setState({ droitsDeConnexionValue: value })
      // this.props.showConsoleLog && console.log('Citation_2 -> setRangeValue -> droitsDeConnexionValue = ', value)
    }

  }


  formulaireModifQuoteIndex = (action) => {
    if (action === 'Page 1') {
      this.setState({ formulaireQuoteIndex: 'Page 1' })
    }
    if (action === 'Page 2') {
      this.setState({ formulaireQuoteIndex: 'Page 2' })
    }
  }


  getPersonPictureName(pers_type, idperson) {
    if (pers_type === 'someone') {
      return `Personnage${idperson % 10 + 1}.png`
    }

    if (pers_type === 'organisation') {
      return `Entreprise${idperson % 6 + 1}.png`
    }

  }


  getDebatePictureName(idcitation) {
    switch (idcitation) {
      case 1: return 'Photo8.jpg'
      case 7: return 'Photo8.jpg'
      case 15: return 'Photo9.jpg'
      case 99: return 'Photo4.jpg'
      case 106: return 'Photo5.jpg'
      case 120: return 'Photo6.jpg'
      case 121: return 'Photo7.jpg'
      default: return 'Photo0.jpg'
    }
  }

  affichageNombreCommentateurs = (niveauDeLecture) => {
    if (this.state.voirPlus) {
      return 20
    } else {
      switch (niveauDeLecture) {
        case 'Facile': return 6
        case 'Moyen': return 9
        case 'Difficile': return 20
        default: return 3
      }
    }

  }

  voirPlus = () => {
    this.props.showConsoleLog && console.log('fonction voirPlus -> this.state.voirPlus : ', this.state.voirPlus, ' // !this.state.voirPlus : ', !this.state.voirPlus)
    this.setState({ voirPlus: !this.state.voirPlus })
  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  miseEnFormeIntroAvecCitation(intro, citation, numberMinus) {

    if (intro !== undefined && citation !== undefined) {

      let fstLetter = citation.charAt(0).toLowerCase()

      if (fstLetter === 'a' || fstLetter === 'e' || fstLetter === 'i' || fstLetter === 'o'
        || fstLetter === 'u' || fstLetter === 'y' || fstLetter === 'à'
        || fstLetter === 'é' || fstLetter === 'è' || fstLetter === 'ê'
      ) {
        if (numberMinus === undefined) {
          return intro.slice(0, intro.length - 2).concat("'", citation)
        } else {
          return intro.slice(0, intro.length - numberMinus).concat("'", citation)
        }
      } else {
        return intro.concat(" ", citation)
      }
    } else {
      return <div ><Spinner animation="border" /></div>
    }

  }

  miseEnFormeIntroSansCitation(intro, citation) {
    let returnIntro = ''

    if (intro !== undefined && citation !== undefined) {
      let fstLetter = citation.charAt(0).toLowerCase()
      if (fstLetter === 'a' || fstLetter === 'e' || fstLetter === 'i' || fstLetter === 'o'
        || fstLetter === 'u' || fstLetter === 'y' || fstLetter === 'à'
        || fstLetter === 'é' || fstLetter === 'è' || fstLetter === 'ê'
      ) {
        returnIntro = intro.slice(0, intro.length - 2).concat("'")
      } else {
        returnIntro = intro
      }
    }

    return returnIntro

  }


  getElementYPositionById = (id) => {
    var testDiv = document.getElementById(id)
    if (testDiv !== null) {
      return (testDiv.offsetTop + testDiv.offsetHeight)
    } else {
      return 1
    }
  }

  getDebateTitleYPosition = (type) => {
    var testDiv = document.getElementById("titreDebat")
    let positionRecalee = 0

    if (type === 'padding') {
      if (testDiv !== null) {
        positionRecalee = testDiv.offsetTop + testDiv.offsetHeight + 33
      } else {
        positionRecalee = 127
      }
    }

    if (type === 'margin') {
      positionRecalee = -60
    }

    return `${positionRecalee}px`

  }

  getOffsetLeft = () => {
    var testDiv = document.getElementById("mainContainer")
    if (testDiv !== null) {
      let positionRecalee = testDiv.offsetLeft - 40

      // this.props.showConsoleLog && console.log('getDebateTitleYPosition -> type : ', type, ' // positionRecalee : ', positionRecalee)

      return `${positionRecalee}px`
    } else {
      return '0px'
    }
    // return testDiv.offsetTop
  }

  closePopupInformationThematique = () => {
    var temp2 = [false, false, false, false]
    setTimeout(() => {
      this.setState({ popoverThematiqueInformationHold: temp2 })
    }, 150);
  }

  closePopupInformationNature = () => {
    setTimeout(() => {
      this.setState({ popoverNatureInformationShow: false })
      this.setState({ popoverNatureInformationHold: false })
    }, 150);
  }

  lockSideMenuFunction = () => {
    this.setState({ lockSideMenu: !this.state.lockSideMenu })
  }


  getNatureTypeDetails = (currentType, natureInformationTable) => {

    var temp = {
      nature: "",
      definition: "",
      critere: "",
      introduction: "",
      t_photo: "",
    }

    if (currentType !== undefined) {
      natureInformationTable.forEach((element, index) => {
        if (currentType === element.nature) {
          temp = element
        }
      })
    }

    return temp

  }

  getEchellesGeographiqueTypeDetails = (currentType, echellesGeographiquesInformationTable) => {

    var temp = {
      id: 0,
      echelleDebat: "",
      institution: "",
      image: "",
    }

    if (currentType !== undefined) {
      echellesGeographiquesInformationTable.forEach((element, index) => {
        if (currentType === element.id) {
          temp = element
        }
      })
    }

    return temp

  }


  getScaleMap = (qPlaceScaleID) => {		// #JM
    var scaleMapReturn = {}
    if (qPlaceScaleID === 1) { scaleMapReturn = mapDataIEFR }
    else if (qPlaceScaleID === 2) { scaleMapReturn = mapDataIEFD }
    else if (qPlaceScaleID === 3) { scaleMapReturn = mapDataIEFR }
    else if (qPlaceScaleID === 4) { scaleMapReturn = mapDataIEFR }
    else if (qPlaceScaleID === 5) { scaleMapReturn = mapDataIEEU }
    else if (qPlaceScaleID === 6) { scaleMapReturn = mapDataIEWO }
    else { scaleMapReturn = mapDataIEWO }
    return scaleMapReturn
  }

  getPositionMap = (qPlaceScaleID, qPlacePositionID) => {	// #JM
    var positionMapReturn = {}
    if (qPlaceScaleID === 4) { positionMapReturn = "fr-all" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 1) { positionMapReturn = "fr-gua" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 2) { positionMapReturn = "fr-mq" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 3) { positionMapReturn = "fr-gf" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 4) { positionMapReturn = "fr-lre" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 5) { positionMapReturn = "fr-may" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 6) { positionMapReturn = "fr-idf" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 7) { positionMapReturn = "fr-cvl" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 8) { positionMapReturn = "fr-bfc" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 9) { positionMapReturn = "fr-nor" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 10) { positionMapReturn = "fr-hdf" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 11) { positionMapReturn = "fr-ges" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 12) { positionMapReturn = "fr-pdl" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 13) { positionMapReturn = "fr-bre" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 14) { positionMapReturn = "fr-naq" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 15) { positionMapReturn = "fr-occ" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 16) { positionMapReturn = "fr-ara" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 17) { positionMapReturn = "fr-pac" }
    if (qPlaceScaleID === 3 && qPlacePositionID === 18) { positionMapReturn = "fr-cor" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 1) { positionMapReturn = "fr-ara-ai" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 2) { positionMapReturn = "fr-hdf-as" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 3) { positionMapReturn = "fr-ara-al" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 4) { positionMapReturn = "fr-pac-ap" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 5) { positionMapReturn = "fr-pac-ha" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 6) { positionMapReturn = "fr-pac-am" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 7) { positionMapReturn = "fr-ara-ah" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 8) { positionMapReturn = "fr-ges-an" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 9) { positionMapReturn = "fr-occ-ag" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 10) { positionMapReturn = "fr-ges-ab" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 11) { positionMapReturn = "fr-occ-ad" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 12) { positionMapReturn = "fr-occ-av" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 13) { positionMapReturn = "fr-pac-bd" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 14) { positionMapReturn = "fr-nor-cv" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 15) { positionMapReturn = "fr-ara-cl" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 16) { positionMapReturn = "fr-naq-ct" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 17) { positionMapReturn = "fr-naq-cm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 18) { positionMapReturn = "fr-cvl-ch" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 19) { positionMapReturn = "fr-naq-cz" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 20) { positionMapReturn = "fr-bfc-co" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 21) { positionMapReturn = "fr-bre-ca" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 22) { positionMapReturn = "fr-naq-cr" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 23) { positionMapReturn = "fr-naq-dd" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 24) { positionMapReturn = "fr-bfc-db" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 25) { positionMapReturn = "fr-ara-dm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 26) { positionMapReturn = "fr-nor-eu" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 27) { positionMapReturn = "fr-cvl-el" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 28) { positionMapReturn = "fr-bre-fi" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 29) { positionMapReturn = "fr-cor-cs" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 30) { positionMapReturn = "fr-cor-hc" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 31) { positionMapReturn = "fr-occ-ga" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 32) { positionMapReturn = "fr-occ-hg" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 33) { positionMapReturn = "fr-occ-ge" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 34) { positionMapReturn = "fr-naq-gi" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 35) { positionMapReturn = "fr-occ-he" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 36) { positionMapReturn = "fr-bre-iv" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 37) { positionMapReturn = "fr-cvl-in" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 38) { positionMapReturn = "fr-cvl-il" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 39) { positionMapReturn = "fr-ara-is" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 40) { positionMapReturn = "fr-bfc-ju" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 41) { positionMapReturn = "fr-naq-ld" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 42) { positionMapReturn = "fr-cvl-lc" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 43) { positionMapReturn = "fr-ara-lr" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 44) { positionMapReturn = "fr-ara-hl" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 45) { positionMapReturn = "fr-pdl-la" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 46) { positionMapReturn = "fr-cvl-lt" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 47) { positionMapReturn = "fr-occ-lo" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 48) { positionMapReturn = "fr-naq-lg" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 49) { positionMapReturn = "fr-occ-lz" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 50) { positionMapReturn = "fr-pdl-ml" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 51) { positionMapReturn = "fr-nor-mh" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 52) { positionMapReturn = "fr-ges-mr" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 53) { positionMapReturn = "fr-ges-hm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 54) { positionMapReturn = "fr-pdl-my" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 55) { positionMapReturn = "fr-ges-mm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 56) { positionMapReturn = "fr-ges-ms" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 57) { positionMapReturn = "fr-bre-mb" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 58) { positionMapReturn = "fr-ges-mo" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 59) { positionMapReturn = "fr-bfc-ni" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 60) { positionMapReturn = "fr-hdf-no" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 61) { positionMapReturn = "fr-hdf-oi" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 62) { positionMapReturn = "fr-nor-or" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 63) { positionMapReturn = "fr-hdf-pc" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 64) { positionMapReturn = "fr-ara-pd" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 65) { positionMapReturn = "fr-naq-pa" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 66) { positionMapReturn = "fr-occ-hp" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 67) { positionMapReturn = "fr-occ-po" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 68) { positionMapReturn = "fr-ges-br" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 69) { positionMapReturn = "fr-ges-hr" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 70) { positionMapReturn = "fr-ara-rh" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 71) { positionMapReturn = "fr-bfc-hn" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 72) { positionMapReturn = "fr-bfc-sl" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 73) { positionMapReturn = "fr-pdl-st" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 74) { positionMapReturn = "fr-ara-sv" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 75) { positionMapReturn = "fr-ara-hs" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 76) { positionMapReturn = "fr-idf-vp" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 77) { positionMapReturn = "fr-nor-sm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 78) { positionMapReturn = "fr-idf-se" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 79) { positionMapReturn = "fr-idf-yv" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 80) { positionMapReturn = "fr-naq-ds" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 81) { positionMapReturn = "fr-hdf-so" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 82) { positionMapReturn = "fr-occ-ta" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 83) { positionMapReturn = "fr-occ-tg" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 84) { positionMapReturn = "fr-pac-vr" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 85) { positionMapReturn = "fr-pac-vc" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 86) { positionMapReturn = "fr-pdl-vd" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 87) { positionMapReturn = "fr-naq-vn" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 88) { positionMapReturn = "fr-naq-hv" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 89) { positionMapReturn = "fr-ges-vg" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 90) { positionMapReturn = "fr-bfc-yo" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 91) { positionMapReturn = "fr-bfc-tb" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 92) { positionMapReturn = "fr-idf-es" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 93) { positionMapReturn = "fr-idf-hd" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 94) { positionMapReturn = "fr-idf-ss" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 95) { positionMapReturn = "fr-idf-vm" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 96) { positionMapReturn = "fr-idf-vo" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 97) { positionMapReturn = "fr-gua-gp" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 98) { positionMapReturn = "fr-mq-mq" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 99) { positionMapReturn = "fr-gf-gf" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 100) { positionMapReturn = "fr-lre-re" }
    if (qPlaceScaleID === 2 && qPlacePositionID === 101) { positionMapReturn = "fr-may-yt" }
    return positionMapReturn
  }


  changeContributionMode = () => {
    this.setState({ contributionActivated: !this.state.contributionActivated })
  }


  quoteModalitySelection = (qm_originID) => {

    this.props.showConsoleLog && console.log("quoteModalitySelection -> qm_originID :", qm_originID)
    this.props.showConsoleLog && console.log("quoteModalitySelection -> quoteModalitiesSelected :", this.state.quoteModalitiesSelected)
    this.props.showConsoleLog && console.log("quoteModalitySelection -> COND :", this.state.quoteModalitiesSelected.indexOf(qm_originID))

    if (this.state.quoteModalitiesSelected.indexOf(qm_originID) > -1) {
      const arr = this.state.quoteModalitiesSelected.filter((item) => item !== qm_originID)
      this.setState({ quoteModalitiesSelected: arr, argumentsSelected: [] })
    } else {
      // this.setState({ quoteModalitiesSelected: this.state.quoteModalitiesSelected.concat(qm_originID) })
      this.setState({ quoteModalitiesSelected: [qm_originID], argumentsSelected: [] })
    }

  }


  argumentSelection = (argument_originID) => {

    this.props.showConsoleLog && console.log("argumentSelection -> argument_originID :", argument_originID)
    this.props.showConsoleLog && console.log("argumentSelection -> argumentsSelected :", this.state.argumentsSelected)
    this.props.showConsoleLog && console.log("argumentSelection -> COND :", this.state.argumentsSelected.indexOf(argument_originID))

    if (this.state.argumentsSelected.indexOf(argument_originID) > -1) {
      const arr = this.state.argumentsSelected.filter((item) => item !== argument_originID)
      this.setState({ argumentsSelected: arr, quoteModalitiesSelected: [] })
    } else {
      // this.setState({ argumentsSelected: this.state.argumentsSelected.concat(argument_originID) })
      this.setState({ argumentsSelected: [argument_originID], quoteModalitiesSelected: [] })
    }

  }



  get_commentateur_argument_dict = (listarguments) => {
    let commentateur_argument_dict = {}

    listarguments.forEach(element => {
      commentateur_argument_dict[element.ql_supportID] = element.commentateursList
    });

    this.setState({ commentateur_argument_dict: commentateur_argument_dict, init_commentateur_argument_dict: false })
  }

  //affichage total de la page
  render() {

    this.props.showConsoleLog && console.log("anyCommentateurFactCheckingOK :", this.state.anyCommentateurFactCheckingOK)
    this.props.showConsoleLog && console.log("anyCommentateurCandidatDeclare2022 :", this.state.anyCommentateurCandidatDeclare2022)


    if (typeof window !== "undefined") {		// #JM
      window.proj4 = window.proj4 || proj4;
    }

    const graphOptions = {
      title: {
        text: "Nombre d'antennes 5G en France",
        style: { color: "#333333", fontSize: "14px" },
      },
      subtitle: {
        text: "<a href='https://www.data.gouv.fr/fr/datasets/mon-reseau-mobile/' target='_blank' rel='noreferrer'>Source : ARCEP</a>",
        useHTML: true,
        style: { color: "#333333", fontSize: "12px", textDecoration: "underline" },
      },
      yAxis: {
        title: {
          text: "Nombre d'antennes 5G"
        }
      },

      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: { // don't display the dummy year
          month: '%e. %b',
          year: '%b'
        },
        title: {
          text: 'Date'
        }
      },

      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom'
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },

      series: [{
        name: 'Nombre total',
        data: [
          [Date.UTC(2020, 12, 31), 8675],
          [Date.UTC(2021, 1, 31), 9961],
          [Date.UTC(2021, 2, 28), 11089],
          [Date.UTC(2021, 3, 31), 12860],
          [Date.UTC(2021, 4, 30), 14225],
          [Date.UTC(2021, 5, 31), 15392],
        ],
        color: "#581845",
      }, {
        name: 'Sur la bande 700MHz',
        data: [
          [Date.UTC(2020, 12, 31), 5640],
          [Date.UTC(2021, 1, 31), 6273],
          [Date.UTC(2021, 2, 28), 7044],
          [Date.UTC(2021, 3, 31), 8073],
          [Date.UTC(2021, 4, 30), 8796],
          [Date.UTC(2021, 5, 31), 9574],
        ],
        color: "#c70039",
      }, {
        name: 'Sur la bande 2100MHz',
        data: [
          [Date.UTC(2020, 12, 31), 2287],
          [Date.UTC(2021, 1, 31), 2771],
          [Date.UTC(2021, 2, 28), 2931],
          [Date.UTC(2021, 3, 31), 3167],
          [Date.UTC(2021, 4, 30), 3423],
          [Date.UTC(2021, 5, 31), 3545],
        ],
        color: "#ff5733",
      }, {
        name: 'Sur la bande 3500MHz',
        data: [
          [Date.UTC(2020, 12, 31), 1198],
          [Date.UTC(2021, 1, 31), 1594],
          [Date.UTC(2021, 2, 28), 1979],
          [Date.UTC(2021, 3, 31), 2838],
          [Date.UTC(2021, 4, 30), 3435],
          [Date.UTC(2021, 5, 31), 4022],
        ],
        color: "#ffc305",
      },
      ]

    }


    let mapOptions = {
      chart: {
        map: "",
        margin: 0,
        spacing: [0, 0, 0, 0],
        height: window.innerWidth > 575.98 ? "320px" : "250px",
      },
      title: {
        text: ""
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "lat: {point.lat}, lon: {point.lon}",
        enabled: false,
      },
      mapNavigation: {
        enabled: false,
        buttonOptions: {
          verticalAlign: "bottom"
        }
      },
      colorAxis: {
        min: 0,
        minColor: "#fff6dd",
        maxColor: "#e55c5c"
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: "BaseMap",
          mapData: this.getScaleMap(this.state.quote.q_placeScaleID),
          data: [
            [this.getPositionMap(this.state.quote.q_placeScaleID, this.state.quote.q_placePositionID), 1]
          ],
          borderColor: "#A0A0A0",
          nullColor: "#A0A0A0", // Couleur de fond de la carte // this.state.quote.q_placeScaleID === 4 ?  : "#adadad" 
          states: {
            hover: {
              color: '#ffe8e6'
            }
          },
          dataLabels: {
            enabled: false,
            format: '{point.name}'
          },
          showInLegend: false
        },
        {
          type: "mappoint", // mapbubble mappoint 
          name: "Villes",
          color: "#000",
          colorKey: 'clusterPointsAmount',
          data: this.state.quote.q_placeScaleID === 1 ?
            [
              // { z: 1, cityname: "Bordeaux", lat: 44.837789, lon: -0.57918 },
              { z: 1, name: this.state.quote.ci_name, cityname: this.state.quote.ci_name, lat: this.state.quote.ci_gps_lat, lon: this.state.quote.ci_gps_lng },
            ]
            :
            [{}],
          dataLabels: {
            color: "#000",
            shadow: false,
            className: "noTextShadow",
          },
          cursor: undefined, //"pointer",
          point: {
            events: {
              click: function () {
                console.log(this.cityname);
              }
            }
          }
        }
      ]
    };

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("Citation_2 -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    const url_image = this.props.url_image


    this.props.showConsoleLog && console.log("Citation_2 -> params.idquote :", parseInt(this.props.match.params.idquote))
    this.props.showConsoleLog && console.log("Citation_2 -> params.pathParam2 :", this.props.match.params.pathParam2)

    this.props.showConsoleLog && console.log("Citation_2 -> quoteModalities :", this.state.quoteModalities)



    const quote = this.state.quote
    const quoteID = parseInt(this.props.match.params.idquote)
    this.props.showConsoleLog && console.log('Citation_2 -> quotePrincipale : ', quote)
    this.props.showConsoleLog && console.log('Citation_2 -> droit de co : ', this.state.droitsDeConnexionValue)

    const listarguments = this.state.listarguments
    this.props.showConsoleLog && console.log('Citation_2 -> listarguments : ', listarguments)


    if (listarguments !== undefined &&
      listarguments.length > 0
      && this.state.init_commentateur_argument_dict
    ) {
      this.get_commentateur_argument_dict(listarguments)
    }

    const listcommentateurs = this.state.listcommentateurs.filter((element) =>
      this.state.quoteModalitiesSelected !== undefined &&
      (this.state.quoteModalitiesSelected.length === 0 ||
        this.state.quoteModalitiesSelected.some(r => element.modalitiesList.map(n => n.id_quoteModality).indexOf(r) >= 0)
      )
      &&
      this.state.argumentsSelected !== undefined &&
      (this.state.argumentsSelected.length === 0 ||
        this.state.argumentsSelected.some(r => this.state.commentateur_argument_dict[r].map(n => n.pers_originID).indexOf(element.person.pers_originID) > -1)
      ))
    this.props.showConsoleLog && console.log('Citation_2 -> listcommentateurs : ', listcommentateurs)
    this.props.showConsoleLog && console.log('Citation_2 -> quoteModalitiesSelected : ', this.state.quoteModalitiesSelected)
    this.props.showConsoleLog && console.log('Citation_2 -> COND : ', this.state.listcommentateurs.filter((element) =>
      this.state.quoteModalitiesSelected !== undefined &&
      (this.state.quoteModalitiesSelected.length === 0 ||
        this.state.quoteModalitiesSelected.some(r => element.modalitiesList.map(n => n.id_quoteModality).indexOf(r) >= 0)
      )))

    this.props.showConsoleLog && console.log('Citation_2 -> listsomeonetype : ', this.props.listsomeonetype)
    this.props.showConsoleLog && console.log('Citation_2 -> listorganisationtype : ', this.props.listorganisationtype)
    this.props.showConsoleLog && console.log('Citation_2 -> listreferencetype : ', this.props.listreferencetype)
    this.props.showConsoleLog && console.log('Citation_2 -> listmediatype : ', this.props.listmediatype)
    this.props.showConsoleLog && console.log('Citation_2 -> listperson : ', this.state.listperson)
    this.props.showConsoleLog && console.log('Citation_2 -> listmedia : ', this.props.listmedia)

    this.props.showConsoleLog && console.log('Citation_2 -> spot_api : ', this.props.spot_api)

    this.props.showConsoleLog && console.log('Citation_2 -> natureInformationTable : ', this.props.natureInformationTable)
    this.props.showConsoleLog && console.log('Citation_2 -> quoteID : ', quoteID)

    const quoteIntro = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', quote.q_typeID, 'introduction')

    this.props.showConsoleLog && console.log('Citation_2 -> quoteIntro : ', quoteIntro)

    const listcommentateursPour = [].concat(listcommentateurs)
      .filter(({ qlt_title }) => qlt_title === "pour")
    const listcommentateursPeutetre = [].concat(listcommentateurs)
      .filter(({ qlt_title }) => qlt_title === "sous conditions")
    const listcommentateursContre = [].concat(listcommentateurs)
      .filter(({ qlt_title }) => qlt_title === "contre")

    this.props.showConsoleLog && console.log("listcommentateursPour :", listcommentateursPour)
    this.props.showConsoleLog && console.log("listcommentateursPeutetre :", listcommentateursPeutetre)
    this.props.showConsoleLog && console.log("listcommentateursContre :", listcommentateursContre)


    const listcommentateursPourId = this.getAttributListNiv2(listcommentateursPour, 'person', 'pers_originID')
    const listcommentateursPeutetreId = this.getAttributListNiv2(listcommentateursPeutetre, 'person', 'pers_originID')
    const listcommentateursContreId = this.getAttributListNiv2(listcommentateursContre, 'person', 'pers_originID')

    this.props.showConsoleLog && console.log("listcommentateursPour list :", listcommentateursPourId)
    this.props.showConsoleLog && console.log("listcommentateursPeutetre list :", listcommentateursPeutetreId)
    this.props.showConsoleLog && console.log("listcommentateursContre list :", listcommentateursContreId)


    this.props.showConsoleLog && console.log('Citation_2 -> listarguments : ', listarguments)
    this.props.showConsoleLog && console.log("listarguments filter", listarguments.filter((element) => element.qlt_title === "quand"))

    let listargumentsQuand = []
    let listargumentsQuoi = []
    let listargumentsComment = []
    let listargumentsPour = []
    let listargumentsContre = []
    let listargumentsSolutions = []

    let quoteListIDHistorique = []
    if (this.state.quoteListIDHistorique.length > 0) {
      quoteListIDHistorique = this.getAttributListNiv1(this.state.quoteListIDHistorique, 'idquote')
    }
    this.props.showConsoleLog && console.log('Citation_2 -> local quoteListIDHistorique : ', quoteListIDHistorique)

    if (this.state.quoteListIDHistorique !== undefined && listarguments !== undefined) {

      listargumentsQuand = listarguments
        .filter((element) => element.qlt_title === "quand" &&
          (quoteListIDHistorique.includes(element.ql_mainID))
        )
        .sort((a, b) =>
          a.quote.q_vd_DateBeg !== undefined && b.quote.q_vd_DateBeg !== undefined ?
            (a.quote.q_vd_DateBeg > b.quote.q_vd_DateBeg ? -1 : 1) : 1
        )

      this.props.showConsoleLog && console.log('Citation_2 -> quoteID : ', quoteID)
      this.props.showConsoleLog && console.log('Citation_2 -> listargumentsQuand sorted : ', listargumentsQuand)

      listargumentsQuoi = [].concat(listarguments).filter((element) => element.qlt_title === "quoi" && quoteListIDHistorique.includes(element.ql_mainID))
      this.props.showConsoleLog && console.log('Citation_2 -> listargumentsQuoi : ', listargumentsQuoi)
      this.props.showConsoleLog && console.log('Citation_2 -> quoteListIDHistorique : ', this.state.quoteListIDHistorique)

      listargumentsComment = [].concat(listarguments).filter((element) => element.qlt_title === "comment" && quoteListIDHistorique.includes(element.ql_mainID))

      listargumentsPour = [].concat(listarguments).filter((element) => element.qlt_title === "pour"
        //  && quoteListIDHistorique.includes(element.ql_mainID)
      )
      listargumentsContre = [].concat(listarguments).filter((element) => element.qlt_title === "contre"
        // && quoteListIDHistorique.includes(element.ql_mainID)
      )

      this.props.showConsoleLog && console.log('Citation_2 -> listargumentsPour : ', listargumentsPour)
      this.props.showConsoleLog && console.log('Citation_2 -> listargumentsContre : ', listargumentsContre)


      listargumentsSolutions = [].concat(listarguments).filter((element) => element.qlt_title === "pour" && quoteListIDHistorique.includes(element.ql_supportID))
      this.props.showConsoleLog && console.log('Citation_2 -> listargumentsSolutions : ', listargumentsSolutions)


    }


    let perChunk = 4 // items per chunk   

    const listargumentsQuoiSplitted = listargumentsQuoi.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    this.props.showConsoleLog && console.log('listargumentsQuoiSplitted : ', listargumentsQuoiSplitted)


    const listargumentsQuandSplitted = listargumentsQuand.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    this.props.showConsoleLog && console.log('listargumentsQuandSplitted : ', listargumentsQuandSplitted)


    this.props.showConsoleLog && console.log('Citation_2 -> listargumentsPour : ', listargumentsPour)

    var listNatureArgumentsSelectionnes = this.getAttributListNiv2(listarguments, 'quote', 'qt_title', 'q_typeID')
    this.props.showConsoleLog && console.log('listNatureArgumentsSelectionnes : ', listNatureArgumentsSelectionnes)

    var listThematiquesArgumentsSelectionnes = this.getAttributListNiv3(listarguments, 'quote', 'themes', 't_title')
    this.props.showConsoleLog && console.log('listThematiquesArgumentsSelectionnes : ', listThematiquesArgumentsSelectionnes)

    this.props.showConsoleLog && console.log('Debats Induits :', this.state.debatsinduits)
    this.props.showConsoleLog && console.log('filtresActifsNature  :', this.state.filtresActifsNature)

    const echellesGeographiquesInformationTable = this.props.echellesGeographiquesInformationTable
    const natureInformationTable = this.props.natureInformationTable



    const popoverLoiActee = (
      <div
        id="popoverLoiActee"
        className="row align-items-center textWhite borderRadius8px p-2 pr-4 pl-4 ml-4 mt-0"
        style={{ backgroundColor: "#000000", left: "60px", position: 'relative', zIndex: '100' }}
        onMouseEnter={() => { this.setState({ popoverLoiActeeHold: true }) }}
        onMouseLeave={() => { this.setState({ popoverLoiActeeHold: false }) }}
      >

        <div className="w-100 wrap align-items-center text-center">
          <div className="textWhite p-2 ">{"Adoptée en Août 2019 (Gouvernement Philippe) :\xa0"}
            <a
              className="textWhite font-italic "
              href="https://www.assemblee-nationale.fr/dyn/15/dossiers/interets_defense_reseaux_radioelectriques_mobiles"
              style={{ textDecoration: "underline dotted" }}
              target="_blank"
              rel="noreferrer"
            >
              détails
            </a>
          </div>
        </div>

      </div>

    )

    const popoverThematiqueInformation = (
      <div
        id="popoverThematiqueInformation"
        className="row align-items-center textWhite borderRadius8px p-2 pr-4 pl-4 ml-4 mt-0"
        style={{ backgroundColor: "#000000", left: "60px", position: 'relative', zIndex: '100' }}
        onMouseEnter={() => {
          var temp = this.state.popoverThematiqueInformationHold
          temp[this.state.popoverThematiqueInformationMouseEnter] = true
          this.setState({ popoverThematiqueInformationHold: temp })
        }}
        onMouseLeave={() => {
          var temp = this.state.popoverThematiqueInformationHold
          temp[this.state.popoverThematiqueInformationMouseEnter] = false
          this.setState({ popoverThematiqueInformationHold: temp })
        }}

      >

        <div>
          {
            "Thématique principale : " +
            this.capitalizeFirstLetter(this.state.popoverThematiqueInformationText[this.state.popoverThematiqueInformationMouseEnter])
          }
        </div>

        <div className="ml-3 ">
          <ModalWindowHelp
            pageName='PageCitation'
            helpType='Thématiques'
            listThemes={this.props.listThemes}
            closePopupInformationThematique={this.closePopupInformationThematique}

            url_image={url_image}
          />
        </div>
      </div>
    )


    const tableEtudeDetails_Domaine = [
      {
        titre: "Type d'étude",
        contenu: "Etude médicale",
      },
      {
        titre: "Catégorie d'étude",
        contenu: "Epidémiologie",
      },
    ]


    const tableEtudeDetails_Source = [
      {
        titre: "Date de publication",
        contenu: "12/07/2020",
      },
      {
        titre: "Auteurs",
        contenu: "C. Rondin, J. Michelet",
      },
      {
        titre: "Pays de publication",
        contenu: "Etats-Unis",
      },
      {
        titre: "Journal",
        contenu: "JAMA",
      },
      {
        titre: "Numéro d'enregistrement",
        contenu: "NCT73803792",
      },
      {
        titre: "Source",
        contenu: "www.source.fr",
      },
    ]


    const tableEtudeDetails_Protocole = [
      {
        titre: "Type d’étude",
        contenu: "Expérimentale", // observationnelle, expérimentale
      },
      {
        titre: "Nombre de sujets",
        contenu: "1200",
      },
      {
        titre: "Caractéristiques des patients",
        contenu: "Hospitalisés pour Covid-19",
      },
      {
        titre: "Randomisation",
        contenu: "Oui", // Randomisation (booléen) / caractéristiques de l’échantillon
      },
      {
        titre: "Double aveugle",
        contenu: "Non",
      },
      {
        titre: "Traitement administré",
        contenu: "",
      },
      {
        titre: "Posologie",
        contenu: "",
      },
      {
        titre: "Critère de jugement",
        contenu: "",
      },
      {
        titre: "Conclusion",
        contenu: "",
      },
      {
        titre: "Risques de biais",
        contenu: "",
      },
    ]



    const natureTypeDetails = this.getNatureTypeDetails(quote.qt_title, natureInformationTable)
    this.props.showConsoleLog && console.log('natureTypeDetails : ', natureTypeDetails)

    this.props.showConsoleLog && console.log('randomListePays : ', this.state.randomListePays)
    this.props.showConsoleLog && console.log('randomListeDeputes : ', this.state.randomListeDeputes)

    this.props.showConsoleLog && console.log('ListeDeputes : ', ListeDeputes)



    return (

      <div className="m-0" style={{ position: "relative" }} >

        {!this.state.toShowLater && this.state.listargumentsLoaded && window.innerWidth > 575.98 &&
          <ComponentFormulaireQuestionnaire
            url_image={url_image}
            showConsoleLog={this.props.showConsoleLog}
          />
        }


        { /* Menu latéral */}
        {true && window.innerWidth > 575.98 &&
          <Sidebar
            sidebar={<ComponentSideMenu
              quote={quote}
              titreDesParties={this.state.titreDesParties}
              idquote={quoteID}
              SuspendreQuoteAPIs={this.SuspendreQuoteAPIs.bind(this)}
              DeleteQuoteAPIs={this.DeleteQuoteAPIs.bind(this)}
              onClick={this.menuShowHide}
              droitsDeConnexionList={this.state.droitsDeConnexionList}
              niveauDeLectureList={this.state.niveauDeLectureList}
              droitDeConnextionAfficher={this.state.droitDeConnextionAfficher}
              setRangeValue={this.setRangeValue}
              niveauDeLectureValue={this.state.niveauDeLectureValue}
              droitsDeConnexionValue={this.state.droitsDeConnexionValue}
              getDebatePictureName={this.getDebatePictureName}
              listthemes={quote.themesList}
              sousThemesList={quote.sousThemesList}
              url_image={url_image}
              listFullMainThemes={this.state.listFullMainThemes}
              visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
              showConsoleLog={this.props.showConsoleLog}
              lockSideMenu={this.state.lockSideMenu}
              lockSideMenuFunction={this.lockSideMenuFunction}

              natureInformationTable={natureInformationTable}
              API_key={this.props.API_key}

              changeContributionMode={this.changeContributionMode}
              contributionActivated={this.state.contributionActivated}

            // setDroitsDeConnexionValue={this.setRangeValue()}
            />}
            open={false}  // this.state.sidebarOpen
            docked={this.state.sidebarDocked} // this.state.sidebarDocked
            // defaultSidebarWidth={'500'}
            // onSetOpen={false}   
            sidebarClassName={' '}
            styles={{
              root: {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden"
              },
              sidebar: {
                zIndex: 105,
                position: "fixed",
                // top: 60,
                left: 0,
                bottom: 0,
                transition: "transform .3s ease-out",
                WebkitTransition: "-webkit-transform .3s ease-out",
                willChange: "transform",
                overflowY: "auto",
                overflowX: 'hidden',
                backgroundColor: "#ffffff",
              },
              content: {
                zIndex: 0,
                position: "fixed",
                // top: 50,
                left: 0, // this.state.bodyMarginLeft
                right: 0,
                bottom: 0,
                overflowY: "auto",
                overflowX: 'hidden',
                // WebkitOverflowScrolling: "touch",
                transition: "left .3s ease-out, right .3s ease-out"
              },
              overlay: {
                zIndex: 1,
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0,
                visibility: "hidden",
                transition: "opacity .3s ease-out, visibility .9s ease-out",
                backgroundColor: "#000",
              },
              dragHandle: {
                zIndex: 1,
                position: "fixed",
                top: 0,
                bottom: 0
              }
            }}
          >


            {
              !this.state.mouseEnterShowSideMenu &&
              <div
                className="d-flex align-items-start justify-content-center"
                style={{
                  position: 'absolute',
                  height: '50px%',
                  width: '25px',
                  top: '100px',
                  left: '0px',
                  backgroundColor: ' #d7dbdd ',
                  transition: ".3s ease-out",
                  background: "#f4f6f6",// "linear-gradient(0.25turn, #99a3a4 , #f2f3f4 )",
                  boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.15)"
                }}
                onMouseEnter={() => this.menuShowHide()}
              >
                <div
                  className="d-flex flex-column"
                  style={{
                    position: 'relative',
                    backgroundColor: "linear-gradient(0.25turn, #99a3a4 , #f2f3f4 ) ",
                    color: " #000 ",
                    marginTop: "0px",
                  }}
                >

                  <div
                    className="mt-2"
                    style={{ color: " #515a5a " }}
                  >
                    <ThreeBarsIcon size={21} />
                  </div>


                </div>
              </div>
            }

          </Sidebar>
        }



        {/* Bandeau en haut de page */}

        {true && this.state.scrollPosition > 0.15 && window.innerWidth > 575.98 &&
          <div
            className="d-flex flex-wrap align-items-center titreDebatUnderline mt-0 pt-3 pl-0 col-12 w-100 "
            id="titreDebat"
            style={{
              position: 'fixed',
              zIndex: '100',
              // paddingLeft: '40px',
              // borderBottom: (this.state.scrollPosition > 0 ? 'solid' : 'none'),
              minHeight: '69px',
              // width: '95%',
              left: this.state.bodyMarginLeft,
              transition: "left .3s ease-out, right .3s ease-out",
              top: '-10px',
            }}
          >
            {/* Barre d'icônes : nature d'info, échelle et thématiques */}
            <div className="d-flex flex-row justify-content-center m-0 pb-0 col-lg-2 " > {/* // style={{ width: this.getOffsetLeft() }} */}
              {!this.state.mouseEnterShowSideMenu && natureTypeDetails.t_photo !== "" &&
                <div className="row m-0 ">

                  <div className="d-flex flex-row m-0  ">
                    {quote.themesList !== undefined &&
                      quote.themesList
                        .filter(({ t_photo }) => t_photo !== null)
                        .map(({ t_title, t_photo }, index) => (

                          <OverlayTrigger
                            // trigger="click" 
                            key={`infoBulleTop_${index}`}
                            show={this.state.popoverThematiqueInformationShow[index] || this.state.popoverThematiqueInformationHold[index]}
                            placement="bottom"
                            overlay={popoverThematiqueInformation}
                            rootClose={false}
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <div
                              className="d-flex p-0 ml-2 "
                            >
                              <button
                                className="d-flex flex-column noBorder m-0 p-0"
                                style={{ backgroundColor: "#1fe0" }}
                                onMouseEnter={() => {
                                  this.setState({ popoverThematiqueInformationMouseEnter: index })

                                  var temp1 = this.state.popoverThematiqueInformationText
                                  temp1[index] = t_title
                                  this.setState({ popoverThematiqueInformationText: temp1 })

                                  var temp2 = [false, false, false, false]
                                  temp2[index] = true
                                  this.setState({ popoverThematiqueInformationShow: temp2 })

                                  this.setState({ popoverNatureInformationShow: false })
                                  this.setState({ popoverNatureInformationHold: false })

                                  this.setState({ popoverEchelleGeoInformationShow: false })
                                  this.setState({ popoverEchelleGeoInformationHold: false })
                                }}
                                onMouseLeave={() => {
                                  var temp2 = [false, false, false, false]
                                  this.setState({ popoverThematiqueInformationShow: temp2 })
                                }}
                              >

                                <img
                                  className="listeThemesSideMenu "
                                  src={url_image + t_photo}
                                  alt={t_photo}
                                  style={{ height: "45px" }}
                                >
                                </img>

                                <div className="noBorder" style={{ height: "7px", backgroundColor: "#1fe0" }}>
                                </div>
                              </button>
                            </div>
                          </OverlayTrigger>
                        ))}
                  </div>
                </div>
              }
            </div>

            {/* Titre du débat */}
            <div className="d-flex flex-row titreDebat m-0 p-0 pl-5 col-lg-8 ">

              {
                this.props.titreDebat(this.state.quote, this.props.natureInformationTable)
                  .concat(this.props.match.params.pathParam2 === undefined ? "" :
                    " ... ".concat(this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.state.quoteModalities, 'id_quoteModality', parseInt(this.props.match.params.pathParam2), 'qm_modalityContent')))
              }

              {parseInt(quoteID) === 1 &&
                <OverlayTrigger
                  // trigger="click" 
                  key={`infoBulleTopPopoverLoiActee2`}
                  show={this.state.popoverLoiActee || this.state.popoverLoiActeeHold}
                  placement="bottom"
                  overlay={popoverLoiActee}
                  rootClose={false}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <button
                    className="align-self-center ml-auto whiteButton"
                    onMouseEnter={() => { this.setState({ popoverLoiActee: true }) }}
                    onMouseLeave={() => { this.setState({ popoverLoiActee: false }) }}
                  >
                    <img
                      className="listeThemesSideMenu "
                      src={url_image + "Logo_de_la_République_française_(1999).png"}
                      alt={"Logo_de_la_République_française"}
                      style={{ height: window.innerWidth > 575.98 ? "30px" : "30px" }}
                    >
                    </img>
                  </button>
                </OverlayTrigger>
              }
            </div>


            {/* Informations générales */}
            <div className="d-flex flex-row justify-content-center m-0 p-0 col-lg-2  " style={{}}>
              <div className="p-0 mr-3 mt-0 mb-0 align-self-center" style={{ height: '40px', width: '1px', border: 'solid', borderColor: 'black', borderWidth: '1px' }}></div> {/* Séparateur vetical */}
              <div className="m-0 pb-0  " style={{}}>
                {quote.q_typeID !== 0 &&
                  <div className="d-flex flex-row w-100 p-0 m-0 relanconsNature ">{quote.qt_title.toUpperCase()}</div>
                }
                <div className="d-flex flex-row w-100 p-0 m-0 relanconsEchelle ">{this.getEchellesGeographiqueTypeDetails(this.state.quote.q_placeScaleID, echellesGeographiquesInformationTable).echelleDebat.toLowerCase()}</div>
              </div>
            </div>

          </div>
        }


        {/* Menu : Accueil, Rechercher un débat, A propos */}
        <ComponentBandeauHautDePage
          url_image={this.props.url_image}
          pageName="citation"
          pageRechercheDisplay={this.props.pageRechercheDisplay}
        />


        {/* Container de toute la page, colonne centrale */}
        <div className="container " id="mainContainer ">

          <div
            className="d-flex flex-column col-12 p-0 "
            style={{
              width: '100%',
              position: 'relative',
              // top: '58px',
              left: this.state.bodyMarginLeft,
              transition: "left .3s ease-out, right .3s ease-out"
            }}
          >

            { /* Design 2021 - Header : photo + titre */}
            {this.state.listargumentsLoaded &&
              <div className="row w-100 justify-content-center m-0 p-0 " id='headerDebatePage' style={{ position: 'relative' }}>

                <div className="row col-12 col-lg-12 justify-content-center m-0 p-0 ">

                  {/* Header : partie de gauche : photo */}
                  <div className="row col-12 col-lg-5 justify-content-center m-0 p-2 pr-3 ">
                    <div className="row w-100 justify-content-center m-0 p-0 ">

                      {window.innerWidth > 575.98 && !this.state.toShowLater &&
                        <div className="d-flex flex-row justify-content-around col-lg-12 ">

                          <div className="d-inline-flex">
                            <ModalModeration />
                          </div>

                          <div className="d-inline-flex">
                            <ModalWindowHistorique
                              quote={quote}
                              showConsoleLog={this.props.showConsoleLog}
                              spot_api={this.props.spot_api}
                              API_key={this.props.API_key}
                              quoteListIDHistorique={this.state.quoteListIDHistorique}
                              prettyDate_heure={this.prettyDate_heure}
                              prettyDate_h_mn_sec={this.prettyDate_h_mn_sec}
                              titreDebat={this.props.titreDebat}
                              natureInformationTable={this.props.natureInformationTable}
                              getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                              url_image={this.props.url_image}

                            />
                          </div>

                          <ModalWindow
                            modalType="Modération"
                            buttonText={'Signaler ce débat '}
                            buttonClassName='p-2 pl-3 pr-3 whiteButtonHover modifierInformations '
                            modalReasonQuestion={'Pour quelle raison souhaitez-vous signaler le débat : \u00ab ' + quote.q_title + ' \u00bb'}
                            modalReasonOptions={["Ce débat est en train d'être détourné", "Ce débat est trop sujet aux émotions", "Ce débat est illégal", "..."]}
                            modalTitle='Avertissement'
                            modalQuestion=''
                            modalButtonOkText='Signaler ce débat'
                            modalButtonOkClassName='btn btn-warning'
                            modalButtonNokText='Annuler'
                          />

                        </div>
                      }

                      {(quote.q_photo === null || quote.q_photo === undefined || quote.q_photo === "undefined" || quote.q_photo.length === 0) ?
                        <button className="m-0 p-0 whiteButton">
                          <img
                            className="rounded w-100 border"
                            src={url_image + "Photo0.jpg"}
                            alt=""
                            style={{ height: window.innerWidth > 575.98 ? '400px' : '150px', filter: 'grayscale(1)' }}
                          />
                        </button>
                        :
                        <div className="d-flex flex-row w-100 m-0 p-0 ">
                          <img
                            className="rounded w-100 border"
                            src={url_image + quote.q_photo}
                            alt=""
                            style={{ height: window.innerWidth > 575.98 ? '400px' : '200px', filter: 'grayscale(1)' }}
                          />
                        </div>
                      }

                    </div>

                  </div>

                  {/* Header : partie de droite : titre etc. */}
                  <div className={"row col-12 col-lg-7 m-0  ".concat(window.innerWidth > 575.98 ? 'pl-4 p-3 ' : 'p-0')} >

                    <div className="w-100 mt-0 p-0 ">

                      { // Si pas de thématique associée au débat -> bouton invitant à renseigner des thématiques
                        // (quote.themesList === undefined || quote.themesList.length === 0) &&
                        <div className="d-flex flex-row w-100 m-0 p-0 ">

                          {this.props.showConsoleLog && console.log("Citations_2 : listargumentsLoaded :", this.state.listargumentsLoaded)}
                          {this.props.showConsoleLog && console.log("Citations_2 : themesList :", quote.themesList)}
                          {this.props.showConsoleLog && console.log("Citations_2 : quote :", quote)}

                          {/* Barre de logos thématiques */}
                          <div className="d-flex flex-row m-0 p-0 ">
                            {this.state.listargumentsLoaded && quote.themesList !== undefined &&
                              quote.themesList
                                .filter(({ t_photo }) => t_photo !== null)
                                .map(({ t_title, t_photo }, index) => (

                                  <OverlayTrigger
                                    // trigger="click" 
                                    key={`infoBulleTop_${index}`}
                                    show={this.state.popoverThematiqueInformationShow[index] || this.state.popoverThematiqueInformationHold[index]}
                                    placement="bottom"
                                    overlay={popoverThematiqueInformation}
                                    rootClose={false}
                                    style={{ backgroundColor: "#ffffff" }}
                                  >
                                    <div
                                      className="p-0 mr-3  "
                                    >
                                      <button
                                        className="noBorder m-0 p-0" // 
                                        style={{ backgroundColor: this.state.changeGeneralInformationsHover ? "#f1948a" : "#fff", }}
                                        onMouseEnter={() => {
                                          this.setState({ popoverThematiqueInformationMouseEnter: index })

                                          var temp1 = this.state.popoverThematiqueInformationText
                                          temp1[index] = t_title
                                          this.setState({ popoverThematiqueInformationText: temp1 })

                                          var temp2 = [false, false, false, false]
                                          temp2[index] = true
                                          this.setState({ popoverThematiqueInformationShow: temp2 })

                                          this.setState({ popoverNatureInformationShow: false })
                                          this.setState({ popoverNatureInformationHold: false })

                                          this.setState({ popoverEchelleGeoInformationShow: false })
                                          this.setState({ popoverEchelleGeoInformationHold: false })
                                        }}
                                        onMouseLeave={() => {
                                          var temp2 = [false, false, false, false]
                                          this.setState({ popoverThematiqueInformationShow: temp2 })
                                        }}
                                      >

                                        <img
                                          className="listeThemesSideMenu "
                                          src={url_image + t_photo}
                                          alt={t_photo}
                                          style={{ height: window.innerWidth > 575.98 ? "55px" : "45px" }}
                                        >
                                        </img>

                                      </button>
                                    </div>
                                  </OverlayTrigger>
                                ))}

                          </div>


                          {this.state.contributionActivated &&
                            <ComponentFormulaireThematiques     // Ajout d'une thématique

                              quote={this.state.quote}
                              quoteID={quoteID}

                              url_image={url_image}
                              showConsoleLog={this.props.showConsoleLog}

                              spot_api={this.props.spot_api}
                              API_key={this.props.API_key}

                              listThemes={this.props.listThemes}
                              formulaireType='thématiques'
                              maxThemeSelected={this.state.maxThemeSelected}

                              QuotePrincipaleAPIs={this.QuotePrincipaleAPIs.bind(this)}
                              getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                            />
                          }


                          {this.state.quoteListIDHistorique !== undefined && quoteListIDHistorique.includes(1) && // Logo république française (loi actée)
                            <OverlayTrigger
                              // trigger="click" 
                              key={`infoBulleTopPopoverLoiActee1`}
                              show={this.state.popoverLoiActee || this.state.popoverLoiActeeHold}
                              placement="bottom"
                              overlay={popoverLoiActee}
                              rootClose={false}
                              style={{ backgroundColor: "#ffffff" }}
                            >
                              <button
                                className="align-self-center ml-auto whiteButton"
                                onMouseEnter={() => { this.setState({ popoverLoiActee: true }) }}
                                onMouseLeave={() => { this.setState({ popoverLoiActee: false }) }}
                              >
                                <img
                                  className="listeThemesSideMenu "
                                  src={url_image + "Logo_de_la_République_française_(1999).png"}
                                  alt={"Logo_de_la_République_française"}
                                  style={{ height: window.innerWidth > 575.98 ? "30px" : "30px" }}
                                >
                                </img>
                              </button>
                            </OverlayTrigger>
                          }
                        </div>

                      }


                      {/* Titre du débat */}
                      <div className="row col-lg-12 p-0 m-0 mt-4 mb-0">
                        <div
                          className="relanconsDebat "
                          style={{
                            color: this.state.changeGeneralInformationsHover ? "#e55c5c" : "#000",
                            fontSize: window.innerWidth > 575.98 ? "21px" : "19px"
                          }}
                        >
                          {
                            this.props.titreDebat(this.state.quote, this.props.natureInformationTable)
                              .concat(this.props.match.params.pathParam2 === undefined ? "" : " ... ")
                          }
                          <div>{this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.state.quoteModalities, 'id_quoteModality', parseInt(this.props.match.params.pathParam2), 'qm_modalityContent')}</div>
                        </div>
                      </div>


                    </div>

                    {this.props.showConsoleLog && console.log("Citations_2.js -> listThemes :", this.props.listThemes)}

                    <div
                      className="d-flex w-100 justify-content-end m-0 p-0 "
                      style={{ height: '40px' }}
                    >
                      <div
                        className="d-inline-flex m-0 p-0 "
                        style={{ height: '40px' }}
                      >

                        <ModalFormulaireQuoteManager
                          formulaireType='change title'
                          listquoteSimilar={this.props.listquoteSimilar}

                          departmentsList={this.props.departmentsList}

                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                          miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}

                          natureInformationTable={this.props.natureInformationTable}
                          natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                          natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                          natureInformationTable_faits={this.props.natureInformationTable_faits}

                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                          addQuote={this.props.addQuote}

                          url_image={this.props.url_image}
                          showConsoleLog={this.props.showConsoleLog}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          contributionActivated={this.state.contributionActivated}

                          quote={quote}
                          capitalizeFirstLetter={this.capitalizeFirstLetter}

                        />
                      </div>
                    </div>



                    {/* nature de l'info & échelle */}

                    <div className={"row col-12 col-lg-11 p-0 m-0 ".concat(window.innerWidth > 575.98 ? "mt-1 mb-3 " : "mt-1 ")} style={{}}>

                      {quote.q_typeID !== 0 &&
                        <div
                          className="row col-lg-11 p-0 m-0 relanconsNature "
                          style={{ color: this.state.changeGeneralInformationsHover ? "#e55c5c" : "#000", }}
                        >
                          {quote.qt_title.toUpperCase()}
                        </div>
                      }
                      <div
                        className="row col-lg-11 p-0 m-0 relanconsEchelle "
                        style={{
                          color: this.state.changeGeneralInformationsHover ? "#e55c5c" : "#000",
                          fontSize: window.innerWidth > 575.98 ? "14px" : "12px"
                        }}
                      >
                        {this.getEchellesGeographiqueTypeDetails(this.state.quote.q_placeScaleID, echellesGeographiquesInformationTable).echelleDebat.toLowerCase()}
                      </div>
                      <div
                        className="row col-5 col-lg-3 p-0 m-0 mt-1"
                        style={{ height: '1px', border: 'solid', borderColor: 'black', borderWidth: '1px' }}
                      >
                      </div>
                    </div>



                    {/* Sommaire */}
                    {
                      <div
                        className={"row m-0 p-0 col-12 col-lg-7 ".concat(window.innerWidth > 575.98 ? "pl-3 " : "")}
                        aria-label="Button group with nested dropdown"
                      >

                        {this.state.titreDesParties
                          .filter((element, index) => window.innerWidth > 575.98 ? true : element.title !== "Les solutions")
                          .map((element, index) => (
                            <Link
                              key={`headerSommaireItems_${index}`}
                              className="col-4 col-lg-12 m-0 p-0 "
                              activeClass="active"
                              to={element.to}
                              offset={window.innerWidth > 575.98 ? -80 : -10}
                              spy={true} smooth={true} duration={500}
                            >
                              <Button
                                variant="light"
                                className={"w-100 m-0  boutonSommaire2021  ".concat(window.innerWidth > 575.98 ? "text-left p-1 pl-4 pr-5 " : "text-center")}
                                style={{
                                  fontSize: window.innerWidth > 575.98 ? "13px" : "11px",
                                  backgroundColor: window.innerWidth > 575.98 ? '' : "#ececec",
                                }}
                              >
                                {element.title}
                              </Button>
                            </Link>
                          ))}

                      </div>
                    }

                  </div>

                </div>
              </div>
            }

            {/* Chargement header */}
            {!this.state.listargumentsLoaded &&
              <div className="row w-100 p-0 ">

                {/* Header : partie de gauche : photo */}
                <div className="d-flex flex-column col-lg-6 p-2 ">
                  <div className="pr-3 ">
                    <div className="mt-3">
                      <div className="m-0 p-0 ">

                        <Skeleton className="" count={1} height={370} width={'100%'} />

                      </div>
                    </div>
                  </div>
                </div>

                {/* Header : partie de droite : titre etc. */}
                <div className="d-flex align-items-center col-lg-6 p-2 pl-5 " >
                  <div className="d-flex flex-column w-100 ">

                    {/* Barre de logos thématiques */}
                    <div className="row m-0 mt-2 ">
                      <Skeleton className="mr-3" count={3} height={45} width={45} />
                    </div>

                    {/* nature de l'info & échelle */}
                    <div className="d-inline-flex flex-column mt-3">
                      <Skeleton className="" count={1} height={20} width={190} />
                      <Skeleton className="" count={1} height={20} width={65} />
                    </div>

                    {/* Titre du débat */}
                    <div className="d-flex flex-column mt-4 w-100 ">
                      <Skeleton className="" count={1} height={40} width={'95%'} />
                      <Skeleton className="" count={1} height={40} width={'95%'} />
                    </div>

                    {/* Sommaire */}
                    <div className="d-flex btn-group-vertical mt-3 ml-4" role="group" aria-label="Button group with nested dropdown">
                      <Skeleton className="" count={1} height={20} width={95} />
                      <Skeleton className="" count={1} height={20} width={95} />
                      <Skeleton className="" count={1} height={20} width={95} />
                    </div>

                  </div>
                </div>

              </div>
            }



            {this.state.quote.qt_title !== 'évènement' && // Design 2021 Qui pense quoi
              <div className="row col-12 col-lg-12 m-0 p-0">
                < div className={"row col-12 col-lg-12 m-0 mt-5  ".concat(window.innerWidth > 575.98 ? "p-3 " : "p-0 ")}>

                  <div className="d-inline-flex  ">
                    <Link
                      className="m-0 p-0 "
                      activeClass="active"
                      to="partieQuiPenseQuoi"
                      offset={window.innerWidth > 575.98 ? -80 : -10}
                      spy={true} smooth={true} duration={500} >
                      <div
                        className={"mt-1 titreGrandesPartiesPageDebat2021 pr-4 ".concat(window.innerWidth > 575.98 ? "pl-3 text-left" : "text-center")}
                        id='partieQuiPenseQuoi'
                        style={{ fontSize: window.innerWidth > 575.98 ? "19px" : "16px" }}
                      >
                        {this.state.titreDesParties[0] !== undefined && this.state.titreDesParties[0].title}
                      </div>
                    </Link>
                  </div>


                  <div className="d-flex flex-row w-100 m-0 p-0 justify-content-center " style={{ position: 'relative' }}>

                    <Link
                      className={window.innerWidth > 575.98 ? "col-lg-2 mt-0 mb-0 mr-3 ml-3 p-0" : "col-4 mt-0 mb-0 p-1"}
                      activeClass="active"
                      to="partieQuiPenseQuoi"
                      offset={window.innerWidth > 575.98 ? -80 : -10}
                      spy={true} smooth={true} duration={300} >
                      <div className="w-100 m-0 p-0 ">
                        <button
                          className={"w-100 quoiPenseQuoiOnglets text-center m-0 p-0 ".concat(this.state.ongletQuiPenseQuoiSelected === 'commentateurs' ? " bandeauAccueilOptionsFormatSelectedShadow " : " bandeauAccueilOptionsFormatShadow ")}
                          onClick={() => this.setState({ ongletQuiPenseQuoiSelected: 'commentateurs' })}
                          style={{ fontSize: window.innerWidth > 575.98 ? "15px" : "12px" }}
                        >
                          {window.innerWidth > 575.98 ? "Dans les médias" : "Médias"}
                        </button>
                      </div>
                    </Link>

                    <Link
                      className={window.innerWidth > 575.98 ? "col-lg-2 mt-0 mb-0 mr-3 ml-3 p-0" : "col-4 mt-0 mb-0 p-1"}
                      activeClass="active"
                      to="partieQuiPenseQuoi"
                      offset={window.innerWidth > 575.98 ? -80 : -10}
                      spy={true} smooth={true} duration={300} >
                      <div className="w-100 m-0 p-0 ">
                        <button
                          className={"w-100 quoiPenseQuoiOnglets text-center m-0 p-0 ".concat(this.state.ongletQuiPenseQuoiSelected === 'institutions' ? " bandeauAccueilOptionsFormatSelectedShadow " : " bandeauAccueilOptionsFormatShadow ")}
                          onClick={() => this.setState({ ongletQuiPenseQuoiSelected: 'institutions' })}
                          style={{ fontSize: window.innerWidth > 575.98 ? "15px" : "12px" }}
                        >
                          {window.innerWidth > 575.98 ? "Dans nos institutions" : "Institutions"}
                        </button>
                      </div>
                    </Link>

                    {!this.state.toShowLater &&
                      <div className={window.innerWidth > 575.98 ? "col-lg-2 mt-0 mb-0 mr-3 ml-3 p-0" : "col-4 mt-0 mb-0 p-1"}>
                        <button
                          className={"w-100 quoiPenseQuoiOnglets text-center m-0 p-0 ".concat(this.state.ongletQuiPenseQuoiSelected === 'autresPays' ? " bandeauAccueilOptionsFormatSelectedShadow " : " bandeauAccueilOptionsFormatShadow ")}
                          onClick={() => this.setState({ ongletQuiPenseQuoiSelected: 'autresPays' })}
                          style={{ fontSize: window.innerWidth > 575.98 ? "15px" : "12px" }}
                        >
                          {window.innerWidth > 575.98 ? "Dans les autres pays" : "Autres pays"}
                        </button>
                      </div>
                    }

                  </div>

                  {this.state.ongletQuiPenseQuoiSelected === "commentateurs" &&
                    <div className='d-flex flex-column col-lg-12 mt-4 ml-0 mr-0 p-0'>

                      <div className="d-flex flex-row w-100 justify-content-center">

                        {this.state.quoteModalities
                          .filter((value, index, self) =>  // unique
                            index === self.findIndex((t) => (
                              t.id_quoteModality === value.id_quoteModality
                            ))).length > 0 &&
                          <Button
                            variant={this.state.showNuances ? "dark" : "light"}
                            className="col-lg-4 mr-4"
                            style={{ borderWidth: "1px", borderColor: "#808b96" }}
                            onClick={() => { this.setState({ showNuances: !this.state.showNuances, showArguments: false, quoteModalitiesSelected: [], argumentsSelected: [] }) }}
                          >
                            {this.state.showNuances ?
                              "Cacher les nuances de ce débat"
                              :
                              "Voir les nuances de ce débat"}
                          </Button>
                        }

                        {this.props.showConsoleLog && console.log("Citation_2 -> listargumentsPour", listargumentsPour)}
                        {this.props.showConsoleLog && console.log("Citation_2 -> listargumentsContre", listargumentsContre)}

                        {true && //(listargumentsPour.filter((element) => element.commentateursList.length > 0).length > 0 ||
                          // listargumentsContre.filter((element) => element.commentateursList.length > 0).length > 0) &&
                          <Button
                            variant={this.state.showArguments ? "dark" : "light"}
                            className="col-lg-4 ml-4"
                            style={{ borderWidth: "1px", borderColor: "#808b96" }}
                            onClick={() => { this.setState({ showArguments: !this.state.showArguments, showNuances: false, quoteModalitiesSelected: [], argumentsSelected: [] }) }}
                          >
                            {this.state.showArguments ?
                              "Cacher les arguments"
                              :
                              "Voir les arguments de ce débat"}
                          </Button>
                        }

                      </div>

                      {this.props.showConsoleLog && console.log("Citations_2 -> quoteModalities :", this.state.quoteModalities)}
                      {this.props.showConsoleLog && console.log("Citations_2 -> quoteModalitiesSelected :", this.state.quoteModalitiesSelected)}

                      {this.state.showNuances &&
                        <div className='d-flex flex-wrap justify-content-start mt-3'>

                          {this.state.quoteModalities
                            .filter((value, index, self) =>  // unique
                              index === self.findIndex((t) => (
                                t.id_quoteModality === value.id_quoteModality
                              )))
                            .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })  // order
                            .map((element, index) => (

                              <Button
                                key={`f_modalitiesAlreadyAttached_${index}`}
                                style={{
                                  color: this.state.quoteModalitiesSelected.length > 0 && this.state.quoteModalitiesSelected.indexOf(element.qm_originID) === -1 ?
                                    'grey' : 'black',
                                }}
                                className={'d-inline-flex ' + ' pt-1 pb-1 pl-2 pr-2 mt-2 mb-0 mr-3 robotoFont13px rounded '.concat(this.state.quoteModalitiesSelected.length > 0 && this.state.quoteModalitiesSelected.indexOf(element.qm_originID) === -1 ? 'titre_neutre' : 'titre_' + element["mo_title"].replace("où", "ou").replace("pour qui", "pourQui"))}
                                onClick={() => this.quoteModalitySelection(element.qm_originID)}
                              >
                                {element["mo_title"] + " : " + element["qm_modalityContent"]}
                              </Button>

                            ))}
                        </div>
                      }

                    </div>
                  }



                  {this.props.showConsoleLog && console.log("Citations_2 -> listargumentsPour :", listargumentsPour)}
                  {this.props.showConsoleLog && console.log("Citations_2 -> argumentsSelected :", this.state.argumentsSelected)}


                  {// Présentation des arguments     argumentSelection

                    this.state.ongletQuiPenseQuoiSelected === "commentateurs" &&

                    <div className='d-flex flex-column col-lg-12 m-0 p-0'>

                      {this.state.showArguments &&
                        <div className='d-flex flex-row col-lg-12 mt-4 p-2 '>

                          <div className="d-flex flex-column col-lg-6 mr-1 p-3 border rounded ">
                            <u className='w-100 text-center pt-0 pb-4 robotoFont15px'>Arguments pour</u>
                            <div className='d-flex flex-wrap justify-content-start col-lg-12 m-0 p-0 '>

                              {listargumentsPour
                                .filter((element) => element.commentateursList.length > 0)
                                .sort(function (a, b) { return a.q_typeID - b.q_typeID })  // order
                                .map((element, index) => (

                                  <div key={`arguments_pour_list_${index}`} className="h-auto d-inline-block mt-0 mr-3 p-0 ">
                                    <ComponentArgumentSmall
                                      argument={element}
                                      natureInformationTable={this.props.natureInformationTable}
                                      showConsoleLog={this.props.showConsoleLog}
                                      introDate2={this.props.introDate2}
                                      capitalizeFirstLetter={this.capitalizeFirstLetter}

                                      argumentSelection={this.argumentSelection}
                                      argumentsSelected={this.state.argumentsSelected}
                                    />
                                  </div>

                                ))}
                            </div>

                          </div>


                          <div className="d-flex flex-column col-lg-6 ml-1 p-3 border rounded ">
                            <u className='w-100 text-center pt-0 pb-4 robotoFont15px'>Arguments contre</u>
                            <div className='d-flex flex-wrap justify-content-start col-lg-12 m-0 p-0 '>

                              {listargumentsContre
                                .filter((element) => element.commentateursList.length > 0)
                                .sort(function (a, b) { return a.q_typeID - b.q_typeID })  // order
                                .map((element, index) => (

                                  <p key={`arguments_contre_list_${index}`} className="h-auto d-inline-block mt-0 mr-3 p-0 ">
                                    <ComponentArgumentSmall
                                      argument={element}
                                      natureInformationTable={this.props.natureInformationTable}
                                      showConsoleLog={this.props.showConsoleLog}
                                      introDate2={this.props.introDate2}
                                      capitalizeFirstLetter={this.capitalizeFirstLetter}

                                      argumentSelection={this.argumentSelection}
                                      argumentsSelected={this.state.argumentsSelected}
                                    />
                                  </p>

                                ))}
                            </div>

                          </div>

                        </div>
                      }

                    </div>
                  }


                  {
                    this.state.contributionActivated &&
                    this.state.ongletQuiPenseQuoiSelected === "commentateurs" &&
                    <div className='mt-4 ' style={{ position: 'relative', right: '0' }}>
                      <ComponentFormulaireCommentateur
                        formulaireType='mainPage'
                        // addPosition={this.props.addPosition}

                        // quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                        quote={this.state.quote}
                        quoteTitle={this.miseEnFormeIntroAvecCitation(quoteIntro, quote.q_title)}

                        quoteID={quoteID}
                        // quoteLinkID={quote_link_typeID} // New
                        listcommentateurs={listcommentateurs}
                        listsomeonetype={this.props.listsomeonetype}
                        listorganisationtype={this.props.listorganisationtype}
                        listreferencetype={this.props.listreferencetype}
                        listmediatype={this.props.listmediatype}
                        listperson={this.props.listperson}
                        listmedia={this.props.listmedia}

                        echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                        evenementTypeList={this.props.evenementTypeList}
                        dateTypeIntroAll={this.props.dateTypeIntroAll}
                        regionsList={this.props.regionsList}
                        departmentsList={this.props.departmentsList}
                        quoteModalities={this.state.quoteModalities}

                        listcommentateursPourId={listcommentateursPourId}
                        listcommentateursContreId={listcommentateursContreId}
                        listcommentateursPeutetreId={listcommentateursPeutetreId}

                        ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                        getPersonPictureName={this.getPersonPictureName}
                        commentateursAPIs={this.commentateursAPIs}
                        quoteModalitesAPIs={this.quoteModalitesAPIs}
                        miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                        // CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}
                        getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                        url_image={url_image}
                        showConsoleLog={this.props.showConsoleLog}

                        visitorLocation={this.props.visitorLocation}
                        queryTracker={this.props.queryTracker}
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}

                        niveauDeLectureValue={this.state.niveauDeLectureValue}

                        listquoteSimilar={this.props.listquoteSimilar}
                        natureInformationTable={this.props.natureInformationTable}
                        miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}

                        ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                        ArgumentsAPIs={this.ArgumentsAPIs}

                        argumentPourContre={listarguments.filter((element) => (element.qlt_title === "pour" || element.qlt_title === "contre"))}
                      />
                    </div>
                  }

                  {this.props.showConsoleLog && console.log("Citations_2 -> quoteIntro :", quoteIntro)}

                  {
                    this.state.ongletQuiPenseQuoiSelected === "commentateurs" &&

                    <div className={window.innerWidth > 575.98 ? "d-flex flex-row w-100 m-0 p-0 " : "d-flex flex-column w-100 m-0 p-0 "}>

                      <div className={"w-100 col-lg-4 mt-3 ".concat(window.innerWidth > 575.98 ? "p-2 " : "p-0 ")}>

                        <ComponentCommentateurBox

                          // Spécifiques
                          addPosition="pour" // CFC & CC
                          classAddOn={"commentateurPOUR"} // CC
                          classAddOn2={"commentateurTextPour"} // CC
                          quoteOrArgumentForm="mainQuote" // CFC
                          uniqueID={"CommentateurBoxPour"} // CFC
                          boxTitle="d'accord"
                          quoteTitle={this.miseEnFormeIntroAvecCitation(quoteIntro, quote.q_title)} // CFC & CC
                          quoteID={quoteID} // CFC & CC
                          listcommentateurs={listcommentateursPour} // CFC
                          qt_title={this.state.quote.qt_title}
                          quote={this.state.quote}

                          contributionActivated={this.state.contributionActivated}

                          // Listes
                          niveauDeLectureValue={this.state.niveauDeLectureValue}
                          listcommentateursContreId={listcommentateursContreId} // CFC & CC
                          listcommentateursPeutetreId={listcommentateursPeutetreId} // CFC & CC
                          listcommentateursPourId={listcommentateursPourId} // CFC & CC
                          listmedia={this.props.listmedia} // CFC & CC
                          listmediatype={this.props.listmediatype} // CFC & CC
                          listorganisationtype={this.props.listorganisationtype} // CFC & CC
                          listperson={this.state.listperson} // CFC & CC
                          listreferencetype={this.props.listreferencetype} // CFC & CC
                          listsomeonetype={this.props.listsomeonetype} // CFC & CC

                          echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                          institutionsInformationTable={this.props.institutionsInformationTable}
                          evenementTypeList={this.props.evenementTypeList}
                          dateTypeIntroAll={this.props.dateTypeIntroAll}
                          regionsList={this.props.regionsList}
                          departmentsList={this.props.departmentsList}
                          quoteModalities={this.state.quoteModalities}

                          // Fonctions
                          commentateursAPIs={this.commentateursAPIs} // CFC & CC
                          quoteModalitesAPIs={this.quoteModalitesAPIs}
                          getPersonPictureName={this.getPersonPictureName} // CFC & CC
                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs} // CFC & CC
                          ArgumentsAPIs={this.ArgumentsAPIs}
                          miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFC & CC
                          affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          // Fonctions transversales
                          url_image={url_image} // CFC & CC
                          visitorLocation={this.props.visitorLocation} // CFC & CC
                          // CFC & CC
                          showConsoleLog={this.props.showConsoleLog} // CFC & CC
                          queryTracker={this.props.queryTracker} // CFC & CC
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          listquoteSimilar={this.props.listquoteSimilar}
                          natureInformationTable={this.props.natureInformationTable}
                          miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}

                          listarguments={listarguments.filter((element) => (element.qlt_title === "pour" || element.qlt_title === "contre"))}

                          quoteModalitiesSelected={this.state.quoteModalitiesSelected}
                          argumentsSelected={this.state.argumentsSelected}

                        />

                      </div>

                      {this.props.showConsoleLog && console.log("citation_2 -> this.props.qt_title :", this.state.quote.qt_title)}

                      <div className={"w-100 col-lg-4 mt-3 ".concat(window.innerWidth > 575.98 ? "p-2 " : "p-0 ")}>

                        <ComponentCommentateurBox

                          // Spécifiques
                          addPosition="sous conditions" // CFC & CC
                          classAddOn={"commentateurPEUTETRE"} // CC
                          classAddOn2={"commentateurTextPeutEtre"} // CC
                          quoteOrArgumentForm="mainQuote" // CFC
                          uniqueID={"CommentateurBoxPeutEtre"} // CFC
                          boxTitle="sous conditions"
                          listcommentateurs={listcommentateursPeutetre} // CFC
                          qt_title={this.state.quote.qt_title}
                          quote={this.state.quote}
                          quoteIntro={quoteIntro}

                          contributionActivated={this.state.contributionActivated}

                          // Listes
                          quoteID={quoteID} // CFC & CC
                          quoteTitle={this.miseEnFormeIntroAvecCitation(quoteIntro, quote.q_title)} // CFC & CC
                          niveauDeLectureValue={this.state.niveauDeLectureValue}
                          listcommentateursContreId={listcommentateursContreId} // CFC & CC
                          listcommentateursPeutetreId={listcommentateursPeutetreId} // CFC & CC
                          listcommentateursPourId={listcommentateursPourId} // CFC & CC
                          listmedia={this.props.listmedia} // CFC & CC
                          listmediatype={this.props.listmediatype} // CFC & CC
                          listorganisationtype={this.props.listorganisationtype} // CFC & CC
                          listperson={this.state.listperson} // CFC & CC
                          listreferencetype={this.props.listreferencetype} // CFC & CC
                          listsomeonetype={this.props.listsomeonetype} // CFC & CC

                          echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                          institutionsInformationTable={this.props.institutionsInformationTable}
                          evenementTypeList={this.props.evenementTypeList}
                          dateTypeIntroAll={this.props.dateTypeIntroAll}
                          regionsList={this.props.regionsList}
                          departmentsList={this.props.departmentsList}
                          quoteModalities={this.state.quoteModalities}

                          // Fonctions
                          commentateursAPIs={this.commentateursAPIs} // CFC & CC
                          quoteModalitesAPIs={this.quoteModalitesAPIs}
                          getPersonPictureName={this.getPersonPictureName} // CFC & CC
                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs} // CFC & CC
                          ArgumentsAPIs={this.ArgumentsAPIs}
                          miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFC & CC
                          affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          // Fonctions transversales
                          url_image={url_image} // CFC & CC
                          visitorLocation={this.props.visitorLocation} // CFC & CC
                          // CFC & CC
                          showConsoleLog={this.props.showConsoleLog} // CFC & CC
                          queryTracker={this.props.queryTracker} // CFC & CC
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          listquoteSimilar={this.props.listquoteSimilar}
                          natureInformationTable={this.props.natureInformationTable}
                          miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}

                          listarguments={listarguments.filter((element) => (element.qlt_title === "pour" || element.qlt_title === "contre"))}

                          quoteModalitiesSelected={this.state.quoteModalitiesSelected}
                          argumentsSelected={this.state.argumentsSelected}
                        />


                      </div >


                      <div className={"w-100 col-lg-4 mt-3 ".concat(window.innerWidth > 575.98 ? "p-2 " : "p-0 ")}>

                        <ComponentCommentateurBox

                          // Spécifiques
                          addPosition="contre" // CFC & CC
                          classAddOn={"commentateurCONTRE"} // CC
                          classAddOn2={"commentateurTextContre"} // CC
                          quoteOrArgumentForm="mainQuote" // CFC
                          uniqueID={"CommentateurBoxContre"} // CFC
                          boxTitle="pas d'accord"
                          listcommentateurs={listcommentateursContre} // CFC
                          qt_title={this.state.quote.qt_title}
                          quote={this.state.quote}
                          quoteIntro={quoteIntro}

                          contributionActivated={this.state.contributionActivated}

                          // Listes
                          quoteID={quoteID} // CFC & CC
                          quoteTitle={this.miseEnFormeIntroAvecCitation(quoteIntro, quote.q_title)} // CFC & CC
                          niveauDeLectureValue={this.state.niveauDeLectureValue}
                          listcommentateursContreId={listcommentateursContreId} // CFC & CC
                          listcommentateursPeutetreId={listcommentateursPeutetreId} // CFC & CC
                          listcommentateursPourId={listcommentateursPourId} // CFC & CC
                          listmedia={this.props.listmedia} // CFC & CC
                          listmediatype={this.props.listmediatype} // CFC & CC
                          listorganisationtype={this.props.listorganisationtype} // CFC & CC
                          listperson={this.state.listperson} // CFC & CC
                          listreferencetype={this.props.listreferencetype} // CFC & CC
                          listsomeonetype={this.props.listsomeonetype} // CFC & CC

                          echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                          institutionsInformationTable={this.props.institutionsInformationTable}
                          evenementTypeList={this.props.evenementTypeList}
                          dateTypeIntroAll={this.props.dateTypeIntroAll}
                          regionsList={this.props.regionsList}
                          departmentsList={this.props.departmentsList}
                          quoteModalities={this.state.quoteModalities}

                          // Fonctions
                          commentateursAPIs={this.commentateursAPIs} // CFC & CC
                          quoteModalitesAPIs={this.quoteModalitesAPIs}
                          getPersonPictureName={this.getPersonPictureName} // CFC & CC
                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs} // CFC & CC
                          ArgumentsAPIs={this.ArgumentsAPIs}
                          miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFC & CC
                          affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          // Fonctions transversales
                          url_image={url_image} // CFC & CC
                          visitorLocation={this.props.visitorLocation} // CFC & CC
                          // CFC & CC
                          showConsoleLog={this.props.showConsoleLog} // CFC & CC
                          queryTracker={this.props.queryTracker} // CFC & CC
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          listquoteSimilar={this.props.listquoteSimilar}
                          natureInformationTable={this.props.natureInformationTable}
                          miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}

                          listarguments={listarguments.filter((element) => (element.qlt_title === "pour" || element.qlt_title === "contre"))}

                          quoteModalitiesSelected={this.state.quoteModalitiesSelected}
                          argumentsSelected={this.state.argumentsSelected}
                        />


                      </div >

                    </div >
                  }

                  {!this.state.toShowLater &&
                    this.state.ongletQuiPenseQuoiSelected === 'commentateurs' &&
                    (this.state.anyCommentateurCandidatDeclare2022 ||
                      this.state.anyCommentateurCandidatPossible2022 ||
                      this.state.anyCommentateurFactCheckingOK ||
                      this.state.anyCommentateurFactCheckingNOK) &&
                    <div className="d-flex flex-row justify-content-center w-100 mt-3 ">

                      <div className="d-flex flex-row col-lg-7 p-2 quiPenseQuoiBox ">

                        {window.innerWidth > 575.98 && <u className="robotoFont13px col-lg-2 p-1 ">{"Légende"}</u>}

                        <div className="col-lg-10 ">

                          {this.state.anyCommentateurCandidatDeclare2022 &&
                            <div className={window.innerWidth > 575.98 ? "p-1 pl-4" : "p-1 "}>
                              <img
                                className="commentateurImage "
                                src={this.props.url_image + "Logo_2022.png"}
                                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                                alt=""
                                style={{
                                  height: '20px',
                                }}
                              />
                              <span className="robotoFont13px">{"\xa0:\xa0Candidat déclaré à l'élection présidentielle"}</span>
                            </div>
                          }

                          {this.state.anyCommentateurCandidatPossible2022 &&
                            <div className="p-1 pl-4">
                              <img
                                className="commentateurImage "
                                src={this.props.url_image + "Logo_2022.png"}
                                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                                alt=""
                                style={{
                                  height: '20px',
                                  filter: 'grayscale(1)',
                                }}
                              />
                              <span className="robotoFont13px">{"\xa0:\xa0Possible candidat à l'élection présidentielle (non-déclaré)"}</span>
                            </div>
                          }

                          {this.state.anyCommentateurFactCheckingOK &&
                            <div className="p-1 pl-4">
                              <img
                                className="commentateurImage "
                                src={this.props.url_image + "Logo_factChecking_OK.png"}
                                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                                alt=""
                                style={{
                                  height: '30px',
                                }}
                              />
                              <span className="robotoFont13px">{"\xa0:\xa0Fact-checking en accord avec la thèse de cette page"}</span>
                            </div>
                          }

                          {this.state.anyCommentateurFactCheckingNOK &&
                            <div className="p-1 pl-4">
                              <img
                                className="commentateurImage "
                                src={this.props.url_image + "Logo_factChecking_NOK.png"}
                                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                                alt=""
                                style={{
                                  height: '30px',
                                }}
                              />
                              <span className="robotoFont13px">{"\xa0:\xa0Fact-checking contredisant la thèse de cette page"}</span>
                            </div>
                          }

                        </div>

                      </div>
                    </div>
                  }


                </div >



                {this.state.ongletQuiPenseQuoiSelected === "institutions" &&
                  <div className="d-flex flex-column ">

                    <div
                      className={'tab-pane fade show ' + this.state.tab_Institutions_state}
                      role="tabpanel"
                      aria-labelledby="institutions-tab"
                    /*style={{
                      position: 'relative',
                      height: '550px',
                      overflowY: 'scroll',
                      zIndex: '60',
                    }}*/
                    >


                      <div className="d-flex titreLesInstitutions mb-2">
                        L'Assemblée Nationale
                      </div>

                      {/*
                        idvote_institution: '1',
                        scr_creationDate: '2022-11-11 21:07:08',
                        scr_source: 'AMO40_deputes_actifs_mandats_actifs_organes_divises_json_2022-09-22',
                        scr_uid: 'VTANR5L15V1',
                        scr_organeRef: 'PO717460',
                        scr_legislature: '15',
                        scr_sessionRef: 'SCR5A2017E1',
                        scr_seanceRef: 'RUANR5L15S2017IDS20603',
                        scr_dateScrutin: '2017-07-04 00:00:00',
                        scr_quantiemeJourSeance: '1',
                        scr_codeTypeVote: 'SPS',
                        scr_sortCode: 'adopté',
                        scr_titre: 'la declaration de politique generale du Gouvernement de M.Edouard Philippe(application de l\'article 49, alinea premier, de la Constitution).',
                        scr_demandeur: 'Conference des Presidents',
                        scr_nbVotants: '566',
                        scr_nbSuffragesExprimes: '437',
                        scr_nbSuffragesRequis: '219',
                        scr_nbNonVotants: '9',
                        scr_nbPour: '370',
                        scr_nbContre: '67',
                        scr_nbAbstensions: '129',
                        scr_nbNonVotantsVolontaires: '0',
                      */}



                      <div className="d-flex justify-content-center mb-2 ">

                        <ComponentAssemblee
                          // Highcharts={Highcharts}
                          // assembleeOptions={assembleeOptions}

                          // visualisationVotes={this.state.visualisationVotes}
                          // setVisualisationVote={this.setVisualisationVote}

                          // headersList={headersList}
                          // dataVotes={dataVotes}

                          quoteID={quoteID}
                          spot_api={this.props.spot_api}
                          queryTracker={this.props.queryTracker}
                          API_key={this.props.API_key}
                          url_image={url_image}
                          showConsoleLog={this.props.showConsoleLog}

                        />

                      </div>

                      {this.props.showConsoleLog && console.log("BootstrapTable -> ListeDeputes : ", ListeDeputes)}

                    </div>
                  </div>
                }




                {this.state.ongletQuiPenseQuoiSelected === "autresPays" &&
                  <div className="d-flex flex-column ">


                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                      <strong>Partie en construction</strong>
                      <div>Cette partie présentera l'action des pays du monde sur le débat en cours de visualisation (à différentes échelles : par continent, monde entier).</div>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className={'tab-pane ' + this.state.tab_AutresPays_state} id="messages" role="tabpanel" aria-labelledby="autresPays-tab" style={{
                      position: 'relative',
                      height: '500px',
                      overflowY: 'scroll',
                      zIndex: '60',
                    }}>

                      <div className="d-flex justify-content-center mb-2">
                        <img
                          className="img-thumbnail"
                          src={url_image + "Les autres pays.png"}
                          alt="Les autres pays.png"
                          style={{ height: '350px' }}
                        />
                      </div>

                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr className='text-center align-middle'>
                            {/* <th style={{width: '5%'}}>#</th> */}
                            <th style={{ width: '5%' }}></th>
                            <th style={{ width: '25%' }}>Pays</th>
                            <th>Situation dans les autres pays du monde</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(PaysMonde)
                            .filter((key) =>
                              key.toLowerCase() !== "an" &&
                              key.toLowerCase() !== "ea" &&
                              key.toLowerCase() !== "dg" &&
                              key.toLowerCase() !== "cp" &&
                              key.toLowerCase() !== "ac" &&
                              key.toLowerCase() !== "ic" &&
                              key.toLowerCase() !== "qo" &&
                              key.toLowerCase() !== "cs" &&
                              key.toLowerCase() !== "ta"
                            )
                            .map((key, index) => {
                              let valueRandom = this.state.randomListePays[index]

                              return (
                                <tr key={key.toLowerCase()}>
                                  <td>
                                    <FlagIcon code={key.toLowerCase()} />
                                  </td>
                                  <td>{PaysMonde[key]}</td>
                                  <td className='text-center align-middle'>{"Situation" + (valueRandom + 1)}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </div>

                  </div>
                }
              </div>

            }

            {/* Design 2021  Les Arguments */}

            {quote.qt_title !== "étude" && this.state.quote.qt_title !== 'évènement' && !this.state.toShowLater &&
              <div className={"d-flex flex-column mt-5 ".concat(window.innerWidth > 575.98 ? "p-3 " : "pt-2 pl-2 pr-1 pb-3 ")} style={{ backgroundColor: '#f5f5f5' }}>

                <div className="d-inline-flex  ">
                  <Link
                    className="m-0 p-0 "
                    activeClass="active"
                    to="partieLesArguments"
                    offset={window.innerWidth > 575.98 ? -80 : -10}
                    spy={true} smooth={true} duration={500} >
                    <div
                      className={"mt-1 titreGrandesPartiesPageDebat2021 pr-4  ".concat(window.innerWidth > 575.98 ? "pl-3 text-left" : "text-center")}
                      id='partieLesArguments'
                      style={{ fontSize: window.innerWidth > 575.98 ? "19px" : "16px" }}
                    >
                      {this.state.titreDesParties[1] !== undefined && this.state.titreDesParties[1].title}
                    </div>
                  </Link>
                </div>

                {this.props.showConsoleLog && console.log("aa -> filtresActifsNature : ", this.state.filtresActifsNature.includes(quote.qt_title))}
                {this.props.showConsoleLog && listarguments[0] !== undefined && console.log("aa -> qt_title : ", listarguments[0].quote.qt_title)}
                {this.props.showConsoleLog && listarguments[0] !== undefined && console.log("aa -> filtresActifsNature : ", this.state.filtresActifsNature.includes(listarguments[0].quote.qt_title))}

                {this.props.showConsoleLog && console.log("aa -> natureInformationTable : ", this.state.natureInformationTable)}

                <div className={window.innerWidth > 575.98 ? "d-flex flex-row justify-content-center " : "d-flex flex-column "}>
                  {this.state.natureInformationTable !== [] &&  // Boutons de filtres

                    this.state.natureInformationTable
                      .filter((element) => element.nature !== 'sondage' && element.nbArguments > 0)
                      .map((element, index) => (
                        <button
                          key={`boutonFiltreNature_0${index}`}
                          className={this.state.filtresActifsNature.includes(element.nature) ? "boutonFiltreNatureBoxSelected ".concat(window.innerWidth > 575.98 ? "ml-3 mr-3 " : " col-6 ml-auto mr-auto mt-2 ") : "boutonFiltreNatureBox ".concat(window.innerWidth > 575.98 ? "ml-3 mr-3 " : " col-6 ml-auto mr-auto mt-2 ")}
                          onClick={() => this.filtresActifsNatureV2(element.nature)}
                        >
                          <div className="d-flex flex-row align-items-center ">
                            <div
                              className="d-flex align-items-center m-0 p-0 "
                              style={{
                                color: element.nature === "fait" ? "#ffc305"
                                  : (element.nature === "opinion" ? "#ff5733"
                                    : (element.nature === "étude" ? "#c70039"
                                      : (element.nature === "proposition d'action" ? "#581845"
                                        : (element.nature === "projection future" ? "#ff5733"
                                          : ""
                                        ))))
                              }}
                            >
                              <SquareFillIcon size={14} />
                            </div>
                            <div
                              className={this.state.filtresActifsNature.includes(element.nature) ? "boutonFiltreNatureContentSelected pl-2" : "boutonFiltreNatureContent pl-2"}
                              style={{ fontSize: window.innerWidth > 575.98 ? "13px" : "11px" }}
                            >
                              {element.nature}{this.state.filtresActifsNature.includes(element.nature) ?
                                <XIcon className="ml-2" size={16} />
                                :
                                ('\xa0(' + element.nbArguments + ')')
                              }
                            </div>
                          </div>
                        </button>
                      ))
                  }
                </div>

                <div className={window.innerWidth > 575.98 ? "d-flex flex-row " : "d-flex flex-column "}>

                  <div className={"d-flex w-100 col-12 col-lg-6 pt-4 pb-0 ".concat(window.innerWidth > 575.98 ? "pl-3 pr-3 " : "pl-1 pr-1 ")}>

                    <ComponentArgumentBox

                      // Spécifique
                      argumentPourContre={"pour"} // CFA
                      listarguments={listargumentsPour} // CFA
                      listargumentsAll={listarguments}
                      boxTitle={"favorable"}
                      uniqueID="argumentBoxPour"

                      mainQuoteTitle={this.state.quote.q_title} // CPCC
                      quoteID={quoteID} // CFA
                      quote={this.state.quote}
                      quoteTitle={quote.q_title} // CFA
                      filtresActifsNature={this.state.filtresActifsNature}

                      commentateurs={listcommentateursPour} // A supprimer lorsque l'API permettant de récupérer les personne d'accord avec la causalité sera prête


                      // Fonctions
                      ArgumentsAPIs={this.ArgumentsAPIs} // CFA & CPCC
                      ListQuoteAPIs={this.props.ListQuoteAPIs}
                      capitalizeFirstLetter={this.capitalizeFirstLetter} // CPCC
                      miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFA & CPCC
                      miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation} // CFA & CPCC
                      titreDebat={this.props.titreDebat} // CFA & CPCC
                      introDate2={this.props.introDate2} // CPCC
                      addQuote={this.props.addQuote} // CFA
                      getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                      // Listes
                      linkTypeList={this.props.linkTypeList} // CPCC
                      listquoteSimilar={this.props.listquoteSimilar}

                      // CFA & CPCC
                      quoteIntro={quoteIntro} // CFA & CPCC
                      listquoteSearch={this.props.listquoteSearch} // CFA & CPCC
                      listThemes={this.props.listThemes} // CPCC
                      natureInformationTable={natureInformationTable} // CFA & CPCC
                      natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                      natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                      natureInformationTable_faits={this.props.natureInformationTable_faits}
                      echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                      institutionsInformationTable={this.props.institutionsInformationTable}

                      // Fonctions transversales
                      url_image={url_image} // CFA & CPCC
                      visitorLocation={this.props.visitorLocation} // CPCC & CFA
                      queryTracker={this.props.queryTracker} // CFA & CPCC
                      // CFA & CPCC
                      showConsoleLog={this.props.showConsoleLog} // CFA & CPCC
                      spot_api={this.props.spot_api}
                      API_key={this.props.API_key}

                      // Pour ComponentCommentateurBox
                      affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                      commentateursAPIs={this.commentateursAPIs}
                      getPersonPictureName={this.getPersonPictureName}
                      listmedia={this.props.listmedia}
                      listmediatype={this.props.listmediatype}
                      listorganisationtype={this.props.listorganisationtype}
                      listperson={this.state.listperson}
                      listreferencetype={this.props.listreferencetype}
                      listsomeonetype={this.props.listsomeonetype}
                      niveauDeLectureValue={this.state.niveauDeLectureValue}
                      ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                      evenementTypeList={this.props.evenementTypeList}
                      dateTypeIntro={this.props.dateTypeIntro}
                      dateTypeIntro2={this.props.dateTypeIntro2}
                      dateTypeIntroAll={this.props.dateTypeIntroAll}

                      regionsList={this.props.regionsList}
                      departmentsList={this.props.departmentsList}


                      ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                      ListSimilarQuoteAPIsReturn={this.ListSimilarQuoteAPIsReturn}

                    />


                  </div>



                  <div className={"d-flex w-100 col-12 col-lg-6 pt-4 pb-0 ".concat(window.innerWidth > 575.98 ? "pl-3 pr-3 " : "pl-1 pr-1 ")}>

                    <ComponentArgumentBox

                      // Spécifique
                      argumentPourContre={"contre"} // CFA
                      listarguments={listargumentsContre} // CFA
                      listargumentsAll={listarguments}
                      boxTitle={"défavorable"}
                      uniqueID="argumentsContre"

                      mainQuoteTitle={this.state.quote.q_title} // CPCC
                      quoteID={quoteID} // CFA
                      quote={this.state.quote}
                      quoteTitle={quote.q_title} // CFA
                      filtresActifsNature={this.state.filtresActifsNature}

                      commentateurs={listcommentateursContre} // A supprimer lorsque l'API permettant de récupérer les personne d'accord avec la causalité sera prête

                      // Fonctions
                      ArgumentsAPIs={this.ArgumentsAPIs} // CFA & CPCC
                      ListQuoteAPIs={this.props.ListQuoteAPIs}
                      capitalizeFirstLetter={this.capitalizeFirstLetter} // CPCC
                      miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFA & CPCC
                      miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation} // CFA & CPCC
                      titreDebat={this.props.titreDebat} // CFA & CPCC
                      introDate2={this.props.introDate2} // CPCC
                      addQuote={this.props.addQuote} // CFA
                      getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                      // Listes
                      linkTypeList={this.props.linkTypeList} // CPCC
                      listquoteSimilar={this.props.listquoteSimilar}

                      // CFA & CPCC
                      quoteIntro={quoteIntro} // CFA & CPCC
                      listquoteSearch={this.props.listquoteSearch} // CFA & CPCC
                      listThemes={this.props.listThemes} // CPCC
                      natureInformationTable={natureInformationTable} // CFA & CPCC
                      natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                      natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                      natureInformationTable_faits={this.props.natureInformationTable_faits}
                      echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                      institutionsInformationTable={this.props.institutionsInformationTable}

                      // Fonctions transversales
                      url_image={url_image} // CFA & CPCC
                      visitorLocation={this.props.visitorLocation} // CPCC & CFA
                      queryTracker={this.props.queryTracker} // CFA & CPCC
                      // CFA & CPCC
                      showConsoleLog={this.props.showConsoleLog} // CFA & CPCC
                      spot_api={this.props.spot_api}
                      API_key={this.props.API_key}

                      // Pour ComponentCommentateurBox
                      affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                      commentateursAPIs={this.commentateursAPIs}
                      getPersonPictureName={this.getPersonPictureName}
                      listmedia={this.props.listmedia}
                      listmediatype={this.props.listmediatype}
                      listorganisationtype={this.props.listorganisationtype}
                      listperson={this.state.listperson}
                      listreferencetype={this.props.listreferencetype}
                      listsomeonetype={this.props.listsomeonetype}
                      niveauDeLectureValue={this.state.niveauDeLectureValue}
                      ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                      evenementTypeList={this.props.evenementTypeList}
                      dateTypeIntro={this.props.dateTypeIntro}
                      dateTypeIntro2={this.props.dateTypeIntro2}
                      dateTypeIntroAll={this.props.dateTypeIntroAll}

                      regionsList={this.props.regionsList}
                      departmentsList={this.props.departmentsList}


                      ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                      ListSimilarQuoteAPIsReturn={this.ListSimilarQuoteAPIsReturn}

                    />

                  </div>



                  {false &&
                    <div className="d-flex w-100 col-lg-12 p-4 pb-2 ">

                      <ComponentArgumentBox

                        // Spécifique
                        argumentPourContre={"comment"} // CFA
                        listarguments={listargumentsComment} // CFA
                        listargumentsAll={listarguments}
                        boxTitle={"sous conditions"}
                        uniqueID="argumentsConditions"

                        mainQuoteTitle={this.state.quote.q_title} // CPCC
                        quoteID={quoteID} // CFA
                        quote={this.state.quote}
                        quoteTitle={quote.q_title} // CFA
                        filtresActifsNature={this.state.filtresActifsNature}

                        commentateurs={[]} // A supprimer lorsque l'API permettant de récupérer les personne d'accord avec la causalité sera prête

                        // Fonctions
                        ArgumentsAPIs={this.ArgumentsAPIs} // CFA & CPCC
                        ListQuoteAPIs={this.props.ListQuoteAPIs}
                        capitalizeFirstLetter={this.capitalizeFirstLetter} // CPCC
                        miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation} // CFA & CPCC
                        miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation} // CFA & CPCC
                        titreDebat={this.props.titreDebat} // CFA & CPCC
                        introDate2={this.props.introDate2} // CPCC
                        addQuote={this.props.addQuote} // CFA
                        getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                        // Listes
                        linkTypeList={this.props.linkTypeList} // CPCC
                        listquoteSimilar={this.props.listquoteSimilar}

                        // CFA & CPCC
                        quoteIntro={quoteIntro} // CFA & CPCC
                        listquoteSearch={this.props.listquoteSearch} // CFA & CPCC
                        listThemes={this.props.listThemes} // CPCC
                        natureInformationTable={natureInformationTable} // CFA & CPCC
                        natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                        natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                        natureInformationTable_faits={this.props.natureInformationTable_faits}
                        echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                        institutionsInformationTable={this.props.institutionsInformationTable}

                        // Fonctions transversales
                        url_image={url_image} // CFA & CPCC
                        visitorLocation={this.props.visitorLocation} // CPCC & CFA
                        queryTracker={this.props.queryTracker} // CFA & CPCC
                        // CFA & CPCC
                        showConsoleLog={this.props.showConsoleLog} // CFA & CPCC
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}

                        // Pour ComponentCommentateurBox
                        affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                        commentateursAPIs={this.commentateursAPIs}
                        getPersonPictureName={this.getPersonPictureName}
                        listmedia={this.props.listmedia}
                        listmediatype={this.props.listmediatype}
                        listorganisationtype={this.props.listorganisationtype}
                        listperson={this.state.listperson}
                        listreferencetype={this.props.listreferencetype}
                        listsomeonetype={this.props.listsomeonetype}
                        niveauDeLectureValue={this.state.niveauDeLectureValue}
                        ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                        evenementTypeList={this.props.evenementTypeList}
                        dateTypeIntro={this.props.dateTypeIntro}
                        dateTypeIntro2={this.props.dateTypeIntro2}
                        dateTypeIntroAll={this.props.dateTypeIntroAll}

                        regionsList={this.props.regionsList}
                        departmentsList={this.props.departmentsList}


                        ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                        ListSimilarQuoteAPIsReturn={this.ListSimilarQuoteAPIsReturn}

                      />

                    </div>
                  }

                </div>
              </div >
            }



            {this.state.niveauDeLectureValue !== "Facile" && // Design 2021 Pour comprendre
              this.state.listargumentsLoaded &&  // !this.state.toShowLater &&
              <div className={"d-flex flex-column col-12 col-lg-12 m-0 mt-5 ".concat(window.innerWidth > 575.98 ? "p-3 " : "p-1 ")} style={{ backgroundColor: '#f5f5f5' }}>

                <div className="d-inline-flex  ">
                  <Link
                    className="m-0 p-0 "
                    activeClass="active"
                    to="partiePourComprendre"
                    offset={window.innerWidth > 575.98 ? -80 : -10}
                    spy={true} smooth={true} duration={500} >
                    <div
                      className={"mt-1 titreGrandesPartiesPageDebat2021 pr-4 ".concat(window.innerWidth > 575.98 ? "pl-3 text-left" : "pt-2 text-center")} id='partiePourComprendre'
                      style={{ fontSize: window.innerWidth > 575.98 ? "19px" : "16px" }}
                    >
                      {this.state.titreDesParties[2] !== undefined && this.state.titreDesParties[2].title}
                    </div>
                  </Link>
                </div>

                {
                  <div className={"w-100  ".concat(window.innerWidth > 575.98 ? "p-4 " : "p-0 pt-0 ")}>

                    <div className={window.innerWidth > 575.98 ? "d-flex flex-row w-100 m-0 p-0  " : "d-flex flex-column w-100 mt-0 p-0  "}>

                      <div className={'col-12 m-0 pourComprendreBox  '.concat(window.innerWidth > 575.98 ? "mt-1 p-3 " : "p-2 ", quoteID === 1 ? "col-lg-7 " : "col-lg-12 ")} style={{ position: 'relative' }}>

                        <strong
                          className="robotoFont16px "
                          style={{ fontSize: window.innerWidth > 575.98 ? "16px" : "14px" }}
                        >
                          De quoi s'agit-il ?
                        </strong>

                        <div className="w-100 ">
                          <div className="d-flex flex-row w-100 justify-content-center m-0 p-0 ">
                            {listargumentsQuoi.length === 0 &&
                              <p className="w-100 text-center quoiTexte mt-2 pl-3 pr-3 " style={{ color: '#C4C4C4' }}>
                                En quelques mots, présentez le sujet du débat
                              </p>
                            }

                            {this.props.showConsoleLog && console.log("listarguments :", listarguments)}

                          </div>
                        </div>

                        {this.props.showConsoleLog && console.log("Citation_2.js -> listargumentsQuoi : ", listargumentsQuoi)}

                        {listargumentsQuoi.length > 0 &&

                          <div className="row justify-content-end m-0 p-0 w-100 ">

                            {window.innerWidth > 575.98 &&
                              <div className="row m-0 p-0 col-12 col-lg-12">

                                <Accordion
                                  className={'mt-3 w-100 '.concat(window.innerWidth > 575.98 ? "pr-0 " : "")}
                                  bsPrefix={{ backgroundColor: 'transparent' }}
                                > {/* defaultActiveKey="0" */}

                                  {listargumentsQuoi
                                    .map((element, index) => (

                                      <div key={`accordionCardQuoi_d_${element.idquote}_${index}`} className=" ">
                                        <ComponentPourComprendreCard
                                          argument={element}
                                          keyValue={element.quote.idquote}
                                          pourOuContre={"quoi"}
                                          quoteLinkID={element.quote_link_typeID}
                                          uniqueID={`accordionCardQuoi2_d`.concat(`_${element.quote.idquote}`)}

                                          mainQuoteTitle={this.state.quote.q_title}

                                          listquoteSearch={this.props.listquoteSearch}
                                          natureInformationTable={natureInformationTable}
                                          natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                                          natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                                          natureInformationTable_faits={this.props.natureInformationTable_faits}
                                          echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                                          institutionsInformationTable={this.props.institutionsInformationTable}


                                          visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
                                          quoteIntro={quoteIntro}
                                          listThemes={this.props.listThemes}

                                          linkTypeList={this.props.linkTypeList}

                                          // Fonctions
                                          ArgumentsAPIs={this.ArgumentsAPIs}
                                          ListQuoteAPIs={this.props.ListQuoteAPIs}
                                          titreDebat={this.props.titreDebat}
                                          miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                                          miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                                          capitalizeFirstLetter={this.capitalizeFirstLetter}
                                          introDate2={this.props.introDate2}
                                          affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                                          queryTracker={this.props.queryTracker}

                                          visitorLocation={this.props.visitorLocation}
                                          showConsoleLog={this.props.showConsoleLog}
                                          url_image={url_image}
                                          spot_api={this.props.spot_api}
                                          API_key={this.props.API_key}

                                          evenementTypeList={this.props.evenementTypeList}
                                          dateTypeIntro={this.props.dateTypeIntro}
                                          dateTypeIntro2={this.props.dateTypeIntro2}
                                          dateTypeIntroAll={this.props.dateTypeIntroAll}

                                          regionsList={this.props.regionsList}
                                          departmentsList={this.props.departmentsList}
                                        />
                                      </div>

                                    ))}
                                </Accordion>

                              </div>
                            }


                            {window.innerWidth < 575.98 &&

                              <div className="col-12 m-0 mt-3 p-0 ">

                                <Accordion
                                  className=" m-0 p-0 "
                                  bsPrefix={{ backgroundColor: 'transparent' }}
                                >

                                  <Swiper
                                    slidesPerView={1}
                                    spaceBetween={30}
                                    freeMode={true}
                                    pagination={{
                                      clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                  >

                                    {
                                      listargumentsQuoi !== undefined && listargumentsQuoi.length > 0 && // quote.qt_title
                                      // .filter((element) = element.qlt_title === "quand")   // affichage uniquement des arguments qui appartiennent à la catégorie affichée
                                      listargumentsQuoiSplitted.map((listargumentsQuoiItem, index1) => (
                                        <SwiperSlide
                                          key={`accordionCardQuoiResponsive_split_${index1}`}
                                        >

                                          {listargumentsQuoiItem.map((element, index2) => (

                                            <ComponentPourComprendreCard
                                              key={`CardQuoiResponsive_${index1}_${index2}`}
                                              argument={element}
                                              keyValue={element.quote.idquote}
                                              pourOuContre={"quoi"}
                                              quoteLinkID={element.quote_link_typeID}
                                              uniqueID={`accordionCardQuoi2_d`.concat(`_${element.quote.idquote}`)}

                                              mainQuoteTitle={this.state.quote.q_title}

                                              listquoteSearch={this.props.listquoteSearch}
                                              natureInformationTable={natureInformationTable}
                                              natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                                              natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                                              natureInformationTable_faits={this.props.natureInformationTable_faits}
                                              echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                                              institutionsInformationTable={this.props.institutionsInformationTable}


                                              visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
                                              quoteIntro={quoteIntro}
                                              listThemes={this.props.listThemes}

                                              linkTypeList={this.props.linkTypeList}

                                              // Fonctions
                                              ArgumentsAPIs={this.ArgumentsAPIs}
                                              ListQuoteAPIs={this.props.ListQuoteAPIs}
                                              titreDebat={this.props.titreDebat}
                                              miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                                              miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                                              capitalizeFirstLetter={this.capitalizeFirstLetter}
                                              introDate2={this.props.introDate2}
                                              affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                                              getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                                              queryTracker={this.props.queryTracker}

                                              visitorLocation={this.props.visitorLocation}
                                              showConsoleLog={this.props.showConsoleLog}
                                              url_image={url_image}
                                              spot_api={this.props.spot_api}
                                              API_key={this.props.API_key}

                                              evenementTypeList={this.props.evenementTypeList}
                                              dateTypeIntro={this.props.dateTypeIntro}
                                              dateTypeIntro2={this.props.dateTypeIntro2}
                                              dateTypeIntroAll={this.props.dateTypeIntroAll}

                                              regionsList={this.props.regionsList}
                                              departmentsList={this.props.departmentsList}
                                            />

                                          ))

                                          }

                                        </SwiperSlide>

                                      ))
                                    }

                                  </Swiper>

                                </Accordion>


                              </div >

                            }

                          </div>
                        }

                      </div>


                      {quoteID === 1 &&
                        <div
                          className={'col-12 col-lg-5 '.concat(window.innerWidth > 575.98 ? "m-0 pl-4 " : "mt-4 ml-0 mr-0 p-0 ")}
                          style={{ position: 'relative' }}
                        >

                          <div
                            className={'col-12 col-lg-12 h-100 m-0 pourComprendreBox '.concat(window.innerWidth > 575.98 ? "mt-1 p-3 " : "p-2 pl-2")}
                            style={{ position: 'relative' }}
                          >

                            <strong
                              className="robotoFont16px "
                              style={{ fontSize: window.innerWidth > 575.98 ? "16px" : "14px" }}
                            >
                              En chiffres
                            </strong>

                            <HighchartsReact
                              // constructorType={"mapChart"}
                              highcharts={Highcharts}
                              options={graphOptions}
                            />



                          </div>

                        </div>
                      }

                    </div>

                    <div className={window.innerWidth > 575.98 ? "d-flex flex-row w-100 m-0 p-0 " : "d-flex flex-column w-100 m-0 p-0 "}>

                      <div className={'col-12 col-lg-4 mt-4 pourComprendreBox '.concat(window.innerWidth > 575.98 ? "p-3 " : "p-2 ")} style={{ position: 'relative' }}>

                        <strong
                          className="w-100 robotoFont16px "
                          style={{ fontSize: window.innerWidth > 575.98 ? "16px" : "14px" }}
                        >
                          Où ?
                        </strong>


                        <div className="w-100 " >
                          {true &&
                            <div className="d-flex flex-row w-100 justify-content-center mb-3 pl-3 ">
                              {quote.q_placeScaleID === 0 &&
                                <p className="w-100 text-center quoiTexte mt-2 mb-2 p-0 " style={{ color: '#C4C4C4' }}>
                                  Où ça se passe ?
                                </p>
                              }

                            </div>
                          }

                          {quote.q_placeScaleID !== 0 &&
                            <div className="d-flex flex-column justify-content-center w-100 mt-2 p-0 ">

                              <strong className="w-100 text-center ouSousTitreGris ">
                                {quote.q_placeScaleID === 1 ? quote.ci_name :
                                  (quote.q_placeScaleID === 2 ? quote.de_name :
                                    (quote.q_placeScaleID === 3 ? quote.re_name :
                                      (quote.q_placeScaleID === 4 ? "Toute la France" :
                                        (quote.q_placeScaleID === 5 ? "Union Européenne" :
                                          (quote.q_placeScaleID === 6 ? "Monde" : "")))))}
                              </strong>

                              <div className={"mt-2 pl-2 pr-2  ".concat(window.innerWidth > 575.98 ? "" : "mt-2 ")}>
                                <HighchartsReact
                                  constructorType={"mapChart"}
                                  highcharts={Highcharts}
                                  options={mapOptions}
                                />
                              </div>

                            </div>
                          }
                        </div>


                      </div>

                      <div
                        className={'col-12 col-lg-8 mt-4 '.concat(window.innerWidth > 575.98 ? "pl-4 " : "p-0 mr-0 ml-0")}
                        style={{ position: 'relative' }}
                      >

                        <div className={"w-100 h-100 pourComprendreBox m-0 ".concat(window.innerWidth > 575.98 ? "p-3 " : "p-2 pb-3")}>

                          <strong
                            className="w-100 robotoFont16px "
                            style={{ fontSize: window.innerWidth > 575.98 ? "16px" : "14px", height: '30px' }}
                          >
                            Actualité de ce débat
                          </strong>

                          <div className={listargumentsQuand.length === 0 ? "d-flex flex-row w-100 justify-content-center " : ""} style={{ position: listargumentsQuand.length > 0 ? 'absolute' : 'relative', right: '8px', top: '8px' }}>

                            {listargumentsQuand.length === 0 &&
                              <p className="w-100 text-center quoiTexte mt-2 pl-3 pr-3 " style={{ color: '#C4C4C4' }}>
                                Ajoutez une chronologie
                              </p>
                            }

                          </div>

                          {this.props.showConsoleLog && console.log("listargumentsQuand :", listargumentsQuand)}

                          {listargumentsQuand.length > 0 &&

                            <div className="w-100 ">

                              {window.innerWidth > 575.98 &&

                                <Scrollbars
                                  className=""
                                  style={{ width: '100%', height: '400px', maxHeight: '400px' }}
                                >

                                  <Accordion
                                    className="d-flex flex-row w-100 m-0 p-0 "
                                    bsPrefix={{ backgroundColor: 'transparent' }}
                                  >

                                    <VerticalTimeline
                                      layout={'1-column-right'}
                                      className=""
                                    >
                                      {
                                        listargumentsQuand.map((element, index) => (
                                          <div
                                            key={`accordionCardQuand_${element.idquote}_${index}`}
                                            className="mb-4 pb-0 "
                                          >
                                            {this.props.showConsoleLog && console.log("VerticalTimelineElement -> element :", element)}
                                            {this.props.showConsoleLog && console.log("VerticalTimelineElement -> q_title :", quote.q_title)}
                                            {this.props.showConsoleLog && console.log("VerticalTimelineElement -> quoteIntro :", quoteIntro)}


                                            <VerticalTimelineElement
                                              className="m-0 p-0 "
                                              textClassName=""
                                              dateClassName={"height0 "}
                                              contentStyle={{ padding: 0, marginTop: 0, marginBottom: 0, width: window.innerWidth > 575.98 ? '' : '100%' }}
                                              contentArrowStyle={{ borderRight: '7px solid #faf1d0' }}
                                              iconStyle={{ background: '#faf1d0', color: '#000', paddingBottom: '20px', width: window.innerWidth > 575.98 ? '' : '5px' }}
                                              icon={window.innerWidth > 575.98 ? <ClockIcon size={14} /> : ''}
                                            // style={{ marginBottom: '14px', height: '70px' }}  // `${Math.floor(this.capitalizeFirstLetter(quote.q_title).length / 100)}px`
                                            >

                                              <ComponentPourComprendreCard
                                                argument={element}
                                                keyValue={element.quote.idquote}
                                                pourOuContre={"chronologie"}
                                                quoteLinkID={element.quote_link_typeID}
                                                uniqueID={`accordionCardQuand2`.concat(`_${element.quote.idquote}`)}

                                                mainQuoteTitle={this.state.quote.q_title}

                                                listquoteSearch={this.props.listquoteSearch}
                                                natureInformationTable={natureInformationTable}
                                                natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                                                natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                                                natureInformationTable_faits={this.props.natureInformationTable_faits}
                                                echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                                                institutionsInformationTable={this.props.institutionsInformationTable}


                                                visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
                                                quoteIntro={quoteIntro}
                                                listThemes={this.props.listThemes}

                                                linkTypeList={this.props.linkTypeList}

                                                // Fonctions
                                                ArgumentsAPIs={this.ArgumentsAPIs}
                                                ListQuoteAPIs={this.props.ListQuoteAPIs}
                                                titreDebat={this.props.titreDebat}
                                                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                                                miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                                                capitalizeFirstLetter={this.capitalizeFirstLetter}
                                                introDate2={this.props.introDate2}
                                                affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                                                getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                                                queryTracker={this.props.queryTracker}

                                                visitorLocation={this.props.visitorLocation}
                                                showConsoleLog={this.props.showConsoleLog}
                                                url_image={url_image}
                                                spot_api={this.props.spot_api}
                                                API_key={this.props.API_key}

                                                evenementTypeList={this.props.evenementTypeList}
                                                dateTypeIntro={this.props.dateTypeIntro}
                                                dateTypeIntro2={this.props.dateTypeIntro2}
                                                dateTypeIntroAll={this.props.dateTypeIntroAll}

                                                regionsList={this.props.regionsList}
                                                departmentsList={this.props.departmentsList}
                                              />

                                            </VerticalTimelineElement>


                                          </div >
                                        ))
                                      }

                                    </VerticalTimeline>
                                  </ Accordion>
                                </ Scrollbars>
                              }

                              {window.innerWidth < 575.98 &&

                                <div className="col-12 m-0 mt-3 p-0 ">

                                  <Accordion
                                    className=" m-0 p-0 "
                                    bsPrefix={{ backgroundColor: 'transparent' }}
                                  >

                                    <Swiper
                                      slidesPerView={1}
                                      spaceBetween={30}
                                      freeMode={true}
                                      pagination={{
                                        clickable: true,
                                      }}
                                      modules={[Pagination]}
                                      className="mySwiper"
                                    >

                                      {
                                        listargumentsQuand !== undefined && listargumentsQuand.length > 0 && // quote.qt_title
                                        // .filter((element) = element.qlt_title === "quand")   // affichage uniquement des arguments qui appartiennent à la catégorie affichée
                                        listargumentsQuandSplitted.map((listargumentsQuandItem, index1) => (
                                          <SwiperSlide
                                            key={`accordionCardQuandResponsive_${index1}`}
                                          >

                                            {listargumentsQuandItem.map((element, index2) => (

                                              <ComponentPourComprendreCard
                                                key={`CardQuandResponsive_${index1}_${index2}`}
                                                argument={element}
                                                keyValue={element.quote.idquote}
                                                pourOuContre={"chronologie"}
                                                quoteLinkID={element.quote_link_typeID}
                                                uniqueID={`accordionCardQuandResponsive2`.concat(`_${element.quote.idquote}`)}

                                                mainQuoteTitle={this.state.quote.q_title}

                                                listquoteSearch={this.props.listquoteSearch}
                                                natureInformationTable={natureInformationTable}
                                                natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                                                natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                                                natureInformationTable_faits={this.props.natureInformationTable_faits}
                                                echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                                                institutionsInformationTable={this.props.institutionsInformationTable}


                                                visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
                                                quoteIntro={quoteIntro}
                                                listThemes={this.props.listThemes}

                                                linkTypeList={this.props.linkTypeList}

                                                // Fonctions
                                                ArgumentsAPIs={this.ArgumentsAPIs}
                                                ListQuoteAPIs={this.props.ListQuoteAPIs}
                                                titreDebat={this.props.titreDebat}
                                                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                                                miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                                                capitalizeFirstLetter={this.capitalizeFirstLetter}
                                                introDate2={this.props.introDate2}
                                                affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                                                getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                                                queryTracker={this.props.queryTracker}

                                                visitorLocation={this.props.visitorLocation}
                                                showConsoleLog={this.props.showConsoleLog}
                                                url_image={url_image}
                                                spot_api={this.props.spot_api}
                                                API_key={this.props.API_key}

                                                evenementTypeList={this.props.evenementTypeList}
                                                dateTypeIntro={this.props.dateTypeIntro}
                                                dateTypeIntro2={this.props.dateTypeIntro2}
                                                dateTypeIntroAll={this.props.dateTypeIntroAll}

                                                regionsList={this.props.regionsList}
                                                departmentsList={this.props.departmentsList}
                                              />

                                            ))

                                            }

                                          </SwiperSlide>

                                        ))
                                      }

                                    </Swiper>

                                  </Accordion>


                                </div >

                              }

                            </div >

                          }

                          {false &&
                            <div className="row m-0 p-0 w-100 justify-content-center mt-auto ">
                              <button className="whiteButton m-0 mt-2 p-0 "
                                style={{ color: '#C4C4C4' }}>

                                <ChevronDownIcon
                                  className="m-0 p-0 "
                                  size={16}
                                />
                              </ button>
                            </div >
                          }


                        </div >
                      </div >

                    </div>


                    {/* false &&
                      <div className={'row col-12 col-lg-12 m-0 mt-4 pourComprendreBox  '.concat(window.innerWidth > 575.98 ? "p-3 " : "p-2 ")} style={{ position: 'relative' }}>

                        <strong
                          className="w-100 robotoFont16px "
                          style={{ fontSize: window.innerWidth > 575.98 ? "16px" : "14px" }}
                        >
                          Comment ?
                        </strong>

                        {listargumentsComment !== undefined && listargumentsComment.length > 0 &&
                          <div className="" style={{ position: 'absolute', right: 0, top: 0 }}>
                            
                          </div>
                        }


                        {listargumentsComment !== undefined && listargumentsComment.length > 0 &&
                          <Accordion className='w-100 mt-3 ' style={{}}> 
                            {listarguments.length > 0 && listarguments // quote.qt_title
                              .filter((element) => element.qlt_title === "comment")   // affichage uniquement des arguments qui appartiennent à la catégorie affichée
                              .map(({ quote }, index) => (
                                <div key={`accordionCardComment_${this.props.index}`}>
                                //ComponentPourComprendre à ajouter
                                </div>
                              ))}
                          </Accordion>
                        }

                        {listargumentsComment === undefined || listargumentsComment.length === 0 &&
                          <div className="row w-100 justify-content-center m-0 mt-3 mb-3 p-0 ">
                            <p className="w-100 text-center quoiTexte m-0 mt-2 mb-2 p-0 " style={{ color: '#C4C4C4' }}>
                              Ajoutez une modalité
                            </p>
                            <div>
                            </div>

                          </div>
                        }

                        {false && listargumentsQuand !== undefined && listargumentsQuand.length > 0 &&
                          <div className="w-100 mt-auto">
                            <div className="row justify-content-center m-0 p-0 ">
                              <button className="whiteButton m-0 mt-2 p-0 " style={{ color: '#C4C4C4' }} >
                                <ChevronDownIcon className="m-0 p-0 " size={16} />
                              </button>
                            </div>
                          </div>
                        }

                      </div>
                    */}


                    {false && // A rajouter
                      <div className='row m-0 mt-4 p-3 w-100 pourComprendreBox ' >
                        <strong className="robotoFont16px ">Qui est concerné ?</strong>
                        <div className="" style={{ position: 'absolute', right: 0, top: 0 }}>

                        </div>

                        <div className="row col-lg-12 m-0 p-0">
                          <div className='col col-lg-6 m-0 mt-4 p-3 ' style={{ border: 'solid', borderColor: ' #f8f9f9 ' }}>
                            <strong className=" ">Liste des personnes directement concernées</strong>

                            <Accordion className='mt-3 ' style={{}}> {/* defaultActiveKey="0" */}
                              {this.state.qui
                                .filter(({ who_directlyIncluded }) => who_directlyIncluded === 1)
                                .map(({ groupe }, index) => (
                                  <Card key={`personnesDirectementConcernees_${index}`} className="" style={{ border: 'none' }}>
                                    <Card.Header className="m-0 p-0 " >
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={index + 1}
                                        className="m-0 p-2 w-100 text-left"
                                        style={{ color: 'black', backgroundColor: '#ffffff' }}
                                      >

                                        <div className="row ">
                                          <div className="col-lg-10 quoiTexte">
                                            {groupe.gt_title + ' ' + groupe.gr_title}
                                          </div>
                                          {false &&
                                            <div className="col-lg-2">

                                              {groupe.idgroupe === 66 &&
                                                <div className="m-0 ">
                                                  <img
                                                    className="listeThemesComponentDebate ml-2 "
                                                    src={url_image + "11_Icone_Ecologie.svg"}
                                                    title="Qualité de vie"
                                                    alt="11_Icone_Ecologie.svg"
                                                  >
                                                  </img>
                                                  <img
                                                    className="listeThemesComponentDebate ml-2 "
                                                    src={url_image + "01_Icone_Santé.svg"}
                                                    title="Santé"
                                                    alt="01_Icone_Santé.svg"
                                                  >
                                                  </img>
                                                </div>
                                              }

                                              {groupe.idgroupe === 69 &&
                                                <div className=" ">
                                                  <img
                                                    className="listeThemesComponentDebate ml-2 "
                                                    src={url_image + "11_Icone_Ecologie.svg"}
                                                    title="Qualité de vie"
                                                    alt="11_Icone_Ecologie.svg"
                                                  >
                                                  </img>
                                                  <img
                                                    className="listeThemesComponentDebate ml-2"
                                                    src={url_image + "08_Icone_Economie.svg"}
                                                    title="Economie"
                                                    alt="08_Icone_Economie.svg"
                                                  >
                                                  </img>
                                                </div>
                                              }

                                              {groupe.idgroupe === 81 &&
                                                <div className=" ">
                                                  <img
                                                    className="listeThemesComponentDebate ml-2"
                                                    src={url_image + "08_Icone_Economie.svg"}
                                                    title="Economie"
                                                    alt="08_Icone_Economie.svg"
                                                  >
                                                  </img>
                                                </div>
                                              }

                                            </div>
                                          }
                                        </div>
                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index + 1} className="m-0 p-0 ">
                                      <Card.Body className="m-0 p-2 " >
                                        <ListGroup variant="flush">
                                          <ListGroup.Item action >
                                            {groupe.idgroupe === 66 &&
                                              <div className="text-muted ml-4 ">
                                                Raison : Les antennes peuvent provoquer des troubles auprès de personnes sensibles
                                              </div>
                                            }
                                            {groupe.idgroupe === 69 &&
                                              <div className="text-muted ml-4 ">
                                                Raison : La 5G permettra de ...
                                              </div>
                                            }
                                            {groupe.idgroupe === 81 &&
                                              <div className="text-muted ml-4 ">
                                                Raison : Le déploiement de la 5G est un vecteur de croissance économique
                                              </div>
                                            }
                                          </ListGroup.Item>
                                        </ListGroup>

                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                ))}

                            </Accordion>

                          </div>

                          <div className='col col-lg-6 m-0 mt-4 p-3 ' style={{ border: 'solid', borderColor: ' #f8f9f9 ' }}>
                            <strong >Liste des personnes indirectement concernées</strong>

                            <Accordion className='mt-3 ' style={{}}> {/* defaultActiveKey="0" */}
                              {this.state.qui
                                .filter(({ who_directlyIncluded }) => who_directlyIncluded === 0)
                                .map(({ groupe }, index) => (
                                  <Card key={`personnesIndirectementConcernees_${index}`} className="" style={{ border: 'none' }}>
                                    <Card.Header className="m-0 p-0 " >
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={index + 1}
                                        className="m-0 p-2 w-100 text-left"
                                        style={{ color: 'black', backgroundColor: '#ffffff' }}
                                      >

                                        <div className="row ">
                                          <div className="col-lg-10 quoiTexte">
                                            {groupe.gt_title + ' ' + groupe.gr_title}
                                          </div>
                                          <div className="col-lg-2">
                                            {false && groupe.idgroupe === 71 &&
                                              <div className=" ">
                                                <img
                                                  className="listeThemesComponentDebate ml-2 "
                                                  src={url_image + "05_Icone_Finances.svg"}
                                                  title="Fiscalité"
                                                  alt="05_Icone_Finances.svg"
                                                >
                                                </img>
                                                <img
                                                  className="listeThemesComponentDebate ml-2"
                                                  src={url_image + "01_Icone_Santé.svg"}
                                                  title="Santé"
                                                  alt="01_Icone_Santé.svg"
                                                >
                                                </img>
                                              </div>
                                            }
                                          </div>
                                        </div>

                                      </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={index + 1} className="m-0 p-0 ">
                                      <Card.Body className="m-0 p-2 " >
                                        <ListGroup variant="flush">
                                          <ListGroup.Item action >
                                            {groupe.idgroupe === 71 &&
                                              <div>
                                                <div className="text-muted ml-4 ">
                                                  Raisons :
                                                </div>
                                                <div className="row text-align-center ml-5 mt-2 ">
                                                  <div className="col-lg-10 text-muted">
                                                    Les antennes-relais rapporteront des millions d'euros de taxes
                                                  </div>
                                                  <img
                                                    className="listeThemesComponentDebate ml-2 pb-1"
                                                    src={url_image + "05_Icone_Finances.svg"}
                                                    title="Fiscalité"
                                                    alt="05_Icone_Finances.svg"
                                                  >
                                                  </img>
                                                </div>

                                                <div className="row text-align-center ml-5 mt-2 ">
                                                  <div className="col-lg-10 text-muted">
                                                    Les antennes peuvent provoquer des troubles auprès de personnes sensibles
                                                  </div>
                                                  <img
                                                    className="listeThemesComponentDebate ml-2 pb-1"
                                                    src={url_image + "01_Icone_Santé.svg"}
                                                    title="Santé"
                                                    alt="01_Icone_Santé.svg"
                                                  >
                                                  </img>
                                                </div>
                                              </div>
                                            }
                                          </ListGroup.Item>
                                        </ListGroup>

                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                ))}

                            </Accordion>

                          </div>
                        </div>

                        {false && <div className="w-100 mt-auto ">
                          <div className="d-flex justify-content-center m-0 p-0 ">
                            <button className="whiteButton m-0 mt-2 p-0 " style={{ color: '#C4C4C4' }} >
                              <ChevronDownIcon className="m-0 p-0 " size={16} />
                            </button>
                          </div>
                        </div>}

                      </div>
                    }

                  </div>
                }

              </div>
            }




            {!this.state.listargumentsLoaded && // Chargement Pour comprendre
              <div className="row mt-5 col-lg-12 p-3 " style={{ backgroundColor: '#f5f5f5' }}>

                <Skeleton className="mt-2" count={1} height={40} width={270} />

                <div className='row w-100 m-0 mt-4 p-3 pourComprendreBox  ' > {/* Quoi */}
                  <Skeleton className="mt-2" count={1} height={20} width={120} />
                  <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                  <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                </div>

                <div className='row w-100 m-0 mt-4 p-0  ' >

                  <div className='d-flex col-lg-4 p-0 ' > {/* Où */}
                    <div className="d-flex flex-column w-100 p-3 pourComprendreBox">

                      <Skeleton className="mt-2" count={1} height={20} width={120} />
                      <Skeleton className="mt-2" count={1} height={290} width={'100%'} />

                    </div>
                  </div>

                  <div className='d-flex col-lg-8 m-0 p-0 pl-4 ' >
                    <div className='d-flex flex-column w-100 p-0 m-0 ' >

                      <div className="d-flex flex-column m-0 p-0 p-3 w-100 pourComprendreBox" style={{ height: '50%' }}> {/* Quand */}
                        <Skeleton className="mt-2" count={1} height={20} width={120} />
                        <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                        <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                      </div>

                      <div className='d-flex flex-column m-0 mt-4 pourComprendreBox p-3  ' style={{ height: '50%' }} > {/* Comment */}
                        <Skeleton className="mt-2" count={1} height={20} width={120} />
                        <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                        <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                      </div>

                    </div>
                  </div>

                </div>



                <div className='row m-0 mt-4 p-3 w-100 pourComprendreBox ' > {/* Qui est concerné */}
                  <Skeleton className="row m-0 mt-2" count={1} height={20} width={120} />

                  <div className="row col-lg-12 m-0 p-0">
                    <div className='col col-lg-6 m-0 mt-4 p-3  ' style={{ border: 'solid', borderColor: ' #f8f9f9 ' }}>
                      <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                      <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                    </div>

                    <div className='col col-lg-6 m-0 mt-4 p-3  ' style={{ border: 'solid', borderColor: ' #f8f9f9 ' }}>
                      <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                      <Skeleton className="mt-2" count={1} height={40} width={'100%'} />
                    </div>
                  </div>

                </div>

              </div>
            }





            {/* Titre de la partie : Les solutions */}
            {false &&
              <div className=" mt-1" style={{ position: 'relative', paddingTop: this.getDebateTitleYPosition('padding'), marginTop: this.getDebateTitleYPosition('margin') }}>

                <div className="d-inline-flex  ">
                  <Link
                    className="m-0 p-0 "
                    activeClass="active"
                    to="partieSolutions"
                    offset={window.innerWidth > 575.98 ? -80 : -10}
                    spy={true} smooth={true} duration={500} >
                    <div
                      className={"mt-1 titreGrandesPartiesPageDebat2021 pr-4 ".concat(window.innerWidth > 575.98 ? "pl-3 text-left" : "text-center")}
                      id='partieSolutions'
                      style={{ fontSize: window.innerWidth > 575.98 ? "19px" : "16px" }}
                    >
                      {this.state.titreDesParties[3] !== undefined && this.state.titreDesParties[3].title}
                    </div>
                  </Link>
                </div>

                <div className="" style={{ position: listargumentsSolutions.length !== 0 ? 'absolute' : 'relative', top: '24px', right: '24px' }}>

                  {listargumentsSolutions.length === 0 &&
                    <p className="w-100 text-center quoiTexte mt-2 " style={{ color: '#C4C4C4' }}>
                      Ajoutez une proposition de solution
                    </p>
                  }

                </div>


                {listargumentsSolutions.length !== 0 && window.innerWidth > 575.98 &&
                  <div className="argumentBoxLines " style={{ width: '100%', height: '450px', maxHeight: '450px' }}>
                    <Scrollbars
                      style={{ width: '100%', height: '500px', maxHeight: '440px' }}
                    >
                      <div className="" style={{ width: '100%', maxHeight: '440px' }}>
                        <Accordion className=' ' style={{}} > {/* defaultActiveKey="0" */}
                          {listargumentsSolutions
                            .map((element) => (

                              <div className="d-flex w-100 m-0 p-0 " key={`argument_solutions_${quote.idquote}`}>
                                <ComponentPourComprendreCard
                                  argument={element}
                                  keyValue={element.quote.idquote}
                                  pourOuContre={element.quote.qlt_title}
                                  quoteLinkID={element.quote_link_typeID}
                                  uniqueID={`argument_solutions2`.concat(`_${element.quote.idquote}`)}

                                  mainQuoteTitle={this.state.quote.q_title}

                                  listquoteSearch={this.props.listquoteSearch}
                                  natureInformationTable={natureInformationTable}
                                  natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                                  natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                                  natureInformationTable_faits={this.props.natureInformationTable_faits}
                                  echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                                  institutionsInformationTable={this.props.institutionsInformationTable}


                                  visualisationTestUtilisateur={this.state.visualisationTestUtilisateur}
                                  quoteIntro={quoteIntro}
                                  listThemes={this.props.listThemes}

                                  linkTypeList={this.props.linkTypeList}

                                  // Fonctions
                                  ArgumentsAPIs={this.ArgumentsAPIs}
                                  ListQuoteAPIs={this.props.ListQuoteAPIs}
                                  titreDebat={this.props.titreDebat}
                                  miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                                  miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                                  capitalizeFirstLetter={this.capitalizeFirstLetter}
                                  introDate2={this.props.introDate2}
                                  affichageNombreCommentateurs={this.affichageNombreCommentateurs}
                                  getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                                  queryTracker={this.props.queryTracker}

                                  visitorLocation={this.props.visitorLocation}
                                  showConsoleLog={this.props.showConsoleLog}
                                  url_image={url_image}
                                  spot_api={this.props.spot_api}
                                  API_key={this.props.API_key}

                                  evenementTypeList={this.props.evenementTypeList}
                                  dateTypeIntro={this.props.dateTypeIntro}
                                  dateTypeIntro2={this.props.dateTypeIntro2}
                                  dateTypeIntroAll={this.props.dateTypeIntroAll}

                                  regionsList={this.props.regionsList}
                                  departmentsList={this.props.departmentsList}
                                />
                              </div>
                            ))
                          }
                        </Accordion>
                      </div>
                    </Scrollbars>
                  </div>
                }

              </div>
            }



            {/* Titre de la partie : Débats induits */}
            {false && <div>
              <div className=" mt-1" id='partieDebatsInduits' style={{ paddingTop: this.getDebateTitleYPosition('padding'), marginTop: this.getDebateTitleYPosition('margin') }}>
                <div className="separationHorizontaleGris"> </div>
                <h3 className="d-flex justify-content-center titreGrandesPartiesPageDebat p-2">{this.state.titreDesParties[4] !== undefined && this.state.titreDesParties[4].title}</h3>
              </div>

              <div className={true ? "d-flex flex-wrap w-100 m-0 p-0 mt-3 " : "d-flex flex-column"}>
                {this.state.debatsinduits
                  .map((element, index) => (

                    <div key={`debatInduit_${element.idquote}_${index}`} className={true ? "col-lg-4 m-0 p-0 " : "d-flex flex-column"}>

                      <ComponentDebate
                        quote={element}
                        titreIntro={this.miseEnFormeIntroSansCitation(quoteIntro, element.q_title)}

                        url_image={url_image}

                        showConsoleLog={this.props.showConsoleLog}
                        homePageAffichageMozaique={true}
                        format="Large"
                      />

                    </div>
                  ))}
              </div>
            </div>}

          </div >
        </div >

        <div className="mt-3"></div> {/* Espace blanc */}

        {/* FOOTER */}
        <ComponentFooter
          url_image={this.props.url_image}
        />

        <div className="bottomHorizontal"> </div>


      </div >
    )
  }
}

export default Citations;
