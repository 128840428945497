import React, { Component } from 'react';
import { CheckIcon } from '@primer/octicons-react'

import emailjs, { init } from 'emailjs-com';


import '../full.css';


// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import ComponentGrilleDeNotes from './ComponentGrilleDeNotes'

class ComponentFormulaireQuestionnaireContact extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    questionnaireSousTitreShow: false,

    nom: '',
    commentaire: '',
    emailContact: '',

    successButtonShow: false,

  }

  componentDidMount() {
    init("user_rZufcPevAotKvNRPn4d0w");
  }

  componentDidUpdate() {
  }

  changeMark = (variableName, value) => {
    if (variableName === "markNumb1") {
      this.setState({ markNumb1: value })
    }
    if (variableName === "markNumb2") {
      this.setState({ markNumb2: value })
    }
  }

  sendEmail = () => {

    this.props.showConsoleLog && console.log('emailjs in')

    var templateParams = {
      Nom: this.state.nom,
      Commentaire: this.state.commentaire,
      EMailContact: this.state.emailContact === "" ? "debats.ido@outlook.fr" : this.state.emailContact,
    };

    emailjs.send('service_8stsbad', 'template_y0zubl2', templateParams)
      .then(response => {
        this.props.showConsoleLog && console.log('emailjs SUCCESS!', response.status, response.text)
        // alert("Email sent successfully!")
        this.setState({ successButtonShow: true }, () => setTimeout(() => { this.setState({ show: false }) }, 1500))

      }, error => {
        this.props.showConsoleLog && console.log('emailjs FAILED...', error)
      });


  }


  render() {


    return (

      <div className="">
        <div className="d-flex align-items-center justify-content-center ">
          <Button
            onClick={() => this.setState({ show: true })}
            // href={element.boutonLink}
            // target={element.titre === 'Faites un don' ? "_blank" : ""}
            className="d-inline-flex justify-content-center align-items-center jeContribueBox col-lg-12 m-0 p-1 "
            style={{ backgroundColor: "#E55C5C", borderColor: "#E55C5C" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
          >
            <div className="ml-3 mr-3 mt-1 mb-1">
              {"je contacte Spot".toUpperCase()}
            </div>
          </Button>
        </div>

        <Modal
          show={this.state.show}
          onHide={() => { this.setState({ show: false }) }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center col-lg-12 ">
              Rentrons en contact !
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <div className="row align-items-start pt-0 mt-5 ">

              {
                <Form.Group className="row  col-lg-10 m-0 ml-5" controlId="formBasicEmail">
                  <Form.Label className="col-lg-4 m-0 pt-2">Quel est votre nom ? </Form.Label>
                  <Form.Control
                    className="col-lg-8"
                    type="text"
                    placeholder="Votre nom"
                    onChange={(e) => this.setState({ nom: e.target.value })}
                  />
                </Form.Group>
              }

            </div>


            <div className="row align-items-start pt-0 mt-5 ">

              {
                <Form.Group className="row  col-lg-10 m-0 ml-5" controlId="formBasicEmail">
                  <Form.Label className="col-lg-4 m-0 pt-2">Quelles est votre adresse e-mail ? </Form.Label>
                  <Form.Control
                    className="col-lg-8"
                    type="email"
                    placeholder="Votre adresse email"
                    onChange={(e) => this.setState({ emailContact: e.target.value })}
                  />
                  <Form.Text className="col-lg-12 text-muted text-end ">
                    Nous ne communiquerons jamais votre adresse e-mail à quiconque.
                  </Form.Text>
                </Form.Group>
              }

            </div>



            {
              <div>

                <div className="mt-5">
                  Si vous avez un commentaire à nous partager, c'est ici que ça se passe :) :
                </div>
                <textarea
                  className="mt-3 form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  // value={this.state.commentaire}
                  onChange={(e) => this.setState({ commentaire: e.target.value })}
                >
                </textarea>

              </div>
            }

            <div className="mt-4" >Nous vous contacterons dans les plus brefs délais.</div>

            <div className="row justify-content-center mt-5 ">

              {!this.state.successButtonShow &&
                <Button
                  className="pl-3 pr-3"
                  variant="dark"
                  onClick={() => this.sendEmail()}
                >
                  Envoyer
                </Button>
              }

              {this.state.successButtonShow &&
                <div className="row justify-content-center">
                  <p className="messageEnvoye">
                    Contact envoyé, merci !
                  </p>
                  <span className="ml-3" style={{ color: "#58d68d" }}><CheckIcon size={18} /></span>

                </div>
              }

            </div>


          </Modal.Body>

        </Modal>

      </div >

    )
  }

}
export default ComponentFormulaireQuestionnaireContact