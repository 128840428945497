import React, { Component } from 'react';

import ComponentBandeauHautDePage from './ComponentBandeauHautDePage'
import ComponentFooter from './ComponentFooter'
import ComponentIntervenantResume from './ComponentIntervenantResume'


// Elements Bootstrap
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

//Elements API
// import { ApiClient, CommentateurApi, PersonApi, HistoryApi } from 'spot_api_old'


class IntervenantPage extends Component {

  state = {
    showConsoleLog: true,
    personData: {},
    quoteData: {},

    initDataRequest: true,
  }

  componentDidMount() {
    // this.initData()
  }

  initData = () => {
    this.PersonAPIs(parseInt(this.props.match.params.idIntervenant))
    this.CommentateurDetailsAPI(parseInt(this.props.match.params.idIntervenant))
  }


  componentDidUpdate() {

  }

  PersonAPIs = (personID) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      this.props.showConsoleLog && console.log('PersonAPIs in : ', personID)

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.PersonApi();

      this.props.queryTracker('IntervenantPage.js', 'PersonAPIs')

      apiInstance.personPersonIDGet(personID, (error, data, response) => {
        if (error) {
          console.error(error)
        }
        else {
          let res = JSON.parse(response.text)
          let resObj = res[0]

          this.props.showConsoleLog && console.log('personPersonIDGet PersonAPIs -> data : ', data)
          this.props.showConsoleLog && console.log('personPersonIDGet PersonAPIs -> res : ', res)
          this.props.showConsoleLog && console.log('personPersonIDGet PersonAPIs -> resObj : ', resObj)

          this.setState({ personData: resObj })
        }

      })
    }
  }


  CommentateurDetailsAPI = (personID) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      this.props.showConsoleLog && console.log('PersonAPIs in : ', personID)

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.CommentateurApi();

      this.props.queryTracker('IntervenantPage.js', 'PersonAPIs')

      apiInstance.commentateurDetailsCommentateurIDGet(personID, (error, data, response) => {
        if (error) {
          console.error(error)
        }
        else {

          this.props.showConsoleLog && console.log('commentateurDetailsCommentateurIDGet -> data : ', data)

          this.setState({ commentateurQuoteList: data })
        }

      })
    }
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }

  getUniqueQuote = (commentateurQuoteList) => { // Permet de supprimer les quotes qui apparaissent plusieurs fois (car plusieurs sources)
    let uniqueListID = []
    let returnList = []

    this.props.showConsoleLog && console.log('getUniqueQuote -> commentateurQuoteList : ', commentateurQuoteList)

    if (commentateurQuoteList !== undefined) {
      commentateurQuoteList.forEach(element => {
        this.props.showConsoleLog && console.log('getUniqueQuote -> uniqueListID : ', uniqueListID)

        if (!uniqueListID.includes(element.q_originID)) {
          uniqueListID.push(element.q_originID)
          returnList.push(element)
        }
      })
    }

    return returnList

  }

  prettyDate(date, format) {  //FONCTION A REUTILISER DANS TOUS LES FONCTIONS D'APIS
    date = new Date(date);
    var dateObj = date;
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    switch (format) {
      case undefined: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
      case 'année': return year
      default: return (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year;
    }
  }

  render() {
    this.props.showConsoleLog && console.log('IntervenantPage -> personData : ', this.state.personData)
    this.props.showConsoleLog && console.log('IntervenantPage -> personData check : ', Object.keys(this.state.personData).length)

    this.props.showConsoleLog && console.log('IntervenantPage -> spot_api : ', this.props.spot_api)

    const commentateurQuoteListUnique = this.getUniqueQuote(this.state.commentateurQuoteList)

    this.props.showConsoleLog && console.log('IntervenantPage -> commentateurQuoteListUnique : ', commentateurQuoteListUnique)

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("IntervenantPage -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }


    return (
      <div className="">

        <ComponentBandeauHautDePage
          url_image={this.props.url_image}
          pageName="intervenant"
          pageRechercheDisplay={this.props.pageRechercheDisplay}
        />

        <div className="container " id="IntervenantPage" style={{ minHeight: '600px' }}>

          <div className="w-100 text-center mt-5 intervenantTitle ">
            {this.state.personData.pers_firstName !== undefined &&
              this.state.personData.pers_firstName.length > 0 ?
              this.state.personData.pers_firstName.concat(" ", this.state.personData.pers_name)
              :
              this.state.personData.pers_name}
          </div>

          {commentateurQuoteListUnique.length !== 0 &&
            <div className="mt-5 ">
              <ComponentIntervenantResume
                url_image={this.props.url_image}
                showConsoleLog={this.props.showConsoleLog}

                personData={this.state.personData}
                // commentateurQuoteList={this.state.commentateurQuoteList}

                keepPopover={this.props.keepPopover}

                format="row"
              />

              <div className="mt-5 ">
                <strong className="robotoFont17px mt-4 ">
                  {"Débats sur lesquels\xa0" +
                    (this.state.personData.pers_firstName !== undefined && this.state.personData.pers_firstName.length > 0 ?
                      this.state.personData.pers_firstName.concat(" ", this.state.personData.pers_name)
                      :
                      this.state.personData.pers_name)
                    + "\xa0s'est\xa0" +
                    (this.state.personData.som_gender === "femme" ? "exprimée :" : "exprimé :")
                  }
                </strong>
              </div>



              <Table striped bordered hover className="mt-4 col-12 " responsive>
                <thead>
                  <tr>
                    {false && <th className="robotoFont14px text-center">#</th>}
                    <th
                      className={"align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont14px ")}
                    >
                      Débat
                    </th>
                    <th className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont14px ")}>
                      Position
                    </th>
                    <th className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont14px ")}>
                      Thématiques
                    </th>
                    <th className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont14px ")}>
                      Date de parution
                    </th>
                    <th className={"text-center align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont14px ")}>
                      Média
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    commentateurQuoteListUnique.map((element, index) => (
                      <tr
                        key={`commentateurQuoteListUnique_${index}`}
                      >
                        {false && <td className="robotoFont14px text-center align-middle">{index + 1}</td>}

                        {/* Débat */}
                        <td
                          className={"align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}
                        >
                          <a
                            href={'/citations_2/' + element.idquote}
                            style={{ color: "black", textDecoration: "underline dotted" }}
                          >
                            {this.props.miseEnFormeIntroAvecCitation(
                              this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction'),
                              element.q_title) + "\xa0"
                            }
                          </a>
                          {element.modalitiesList !== undefined &&
                            element.modalitiesList.filter((element) => element.id_modalityType !== 1).length > 0 &&
                            <div className={"w-100 mt-2 ".concat(window.innerWidth > 575.98 ? "pl-5 " : "pl-4 pr-4 ")}>
                              <div>Les conditions :</div>
                              <ul>
                                {element.modalitiesList
                                  .filter((element) => element.id_modalityType !== 1)
                                  .map((modality, index) => (
                                    <li key={`quoteData_${index}_quoteModality${modality.id_quoteModality}`}>
                                      <a
                                        href={'/citations_2/' + element.idquote + '/' + modality.id_quoteModality}
                                        style={{ color: "black", textDecoration: "underline dotted" }}
                                      >
                                        {modality.qm_modalityContent}
                                      </a>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          }


                        </td>

                        {/* Pour / contre */}
                        <td className="robotoFont14px align-middle text-center">
                          <Badge
                            bg="dark"
                            style={{
                              backgroundColor: element.qlt_title === "pour" ? "#c2c5e1" :
                                (element.qlt_title === "contre" ? "#cfedef" : "")
                            }}
                            className="ml-2"
                          >
                            {element.qlt_title}
                          </Badge>
                        </td>

                        {/* Thématiques */}
                        <td className="robotoFont14px text-center align-middle">
                          {element.themesList !== undefined && element.themesList.length > 0 &&
                            element.themesList
                              .filter((element) => element.t_mainTheme === 1)
                              .map((theme, index) => (
                                <img
                                  key={`intervenantPage_themesList_${index}`}
                                  className="rounded "
                                  src={
                                    (theme.t_photo.slice(0, 4) === 'http' ?
                                      theme.t_photo : (this.props.url_image + theme.t_photo))

                                  }
                                  alt=""
                                  style={{ maxHeight: '30px', maxWidth: '100%', filter: 'none' }}
                                  title={theme.t_title}
                                />
                              ))
                          }
                          {element.themesList !== undefined && element.themesList.length > 0 &&
                            element.themesList
                              .filter((element) => (element.t_mainTheme !== 1 || element.t_mainTheme === null))
                              .map((theme, index) => (
                                <div
                                  key={`themeCommentateurDetails_${index}`}
                                  className='robotoFont12px'
                                >
                                  {theme.t_title + "\xa0"}
                                </div>
                              ))
                          }
                        </td>


                        {/* Date de parution */}
                        <td className={"align-middle ".concat(window.innerWidth > 575.98 ? "robotoFont14px " : "robotoFont13px ")}>
                          {element.referenceList.length > 0 && element.referenceList[0].ref_dateParution.length > 0 ? this.prettyDate(element.referenceList[0].ref_dateParution) : ""}
                        </td>


                        {/* Média */}
                        <td className="robotoFont14px text-center align-middle">{
                          element.referenceList.length > 0 ?
                            (element.referenceList[0].media_pers_picture !== null &&
                              element.referenceList[0].media_pers_picture !== "" &&
                              element.referenceList[0].media_pers_picture !== undefined ?
                              <img
                                className="rounded "
                                src={
                                  (element.referenceList[0].media_pers_picture.slice(0, 4) === 'http' ?
                                    element.referenceList[0].media_pers_picture : (this.props.url_image + element.referenceList[0].media_pers_picture))

                                }
                                alt=""
                                style={{ maxHeight: '30px', maxWidth: '100%', filter: 'none' }}
                                title={element.referenceList[0].media_pers_title}
                              />
                              : element.referenceList[0].media_pers_title
                            )
                            : ""
                        }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>





              {false &&
                <div>
                  <div className="robotoFont16px mt-4 ">{"Les informations relayées par\xa0" + this.state.personData.pers_name}</div>

                  <div className="robotoFont16px mt-4 ">{"Les actions politiques de\xa0" + this.state.personData.pers_name}</div>

                  <div className="robotoFont16px mt-4 ">{"Les argumentations de\xa0" + this.state.personData.pers_name}</div>
                </div>
              }

            </div>
          }

        </div>



        {/* FOOTER */}
        <ComponentFooter
          url_image={this.props.url_image}
        />

      </div >
    )
  }
}


export default IntervenantPage
