import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';

import { BroadcastIcon, IssueReopenedIcon } from '@primer/octicons-react'


class ComponentBandeauHautDePage extends Component {

  state = {

  }



  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {


    return (

      <div className="d-flex flex-row col-12 mr-0 ml-0 mb-2 p-0 " style={{ marginTop: window.innerWidth > 576 ? "40px" : "20px" }}>

        <a
          href="/"
          className={"d-flex flex-row col-3 col-lg-6 m-0 align-self-center ".concat(window.innerWidth > 576 ? "pl-5" : 'pl-2')}
          style={{ border: 'none', zIndex: '80' }}
        >
          <img
            className=""
            src={this.props.url_image + "SPOT_Logo_bleu_light.png"}
            alt="Logo de la plateforme"
            style={{ height: window.innerWidth > 576 ? '50px' : '30px' }}
          />
        </a>

        <div className="col-9 col-lg-6 m-0 p-0 " style={{ backgroundColor: 'transparent' }}>
          <div className="row w-100 m-0 p-0 " style={{ backgroundColor: 'transparent' }}>

            {window.innerWidth > 576 &&
              <Link
                className="col-4 col-lg-4 m-0 p-0 "
                to="/"
                onClick={() => this.props.pageRechercheDisplay !== undefined && this.props.pageRechercheDisplay(false)}
              >
                <button
                  className={this.props.pageName === "home" ?
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormatSelected  "
                    :
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormat  "
                  }
                  style={{
                    fontSize: window.innerWidth > 576 ? '14px' : '11px'
                  }}
                >
                  Accueil
                </button>
              </Link>
            }

            {window.innerWidth < 576 &&
              <div className="col-2 m-0 p-0"></div>
            }

            {// this.props.pageRechercheDisplay !== undefined &&
              <Link
                className="col-5 col-lg-4 m-0 p-0 "
                to="/"
                onClick={() => this.props.pageRechercheDisplay !== undefined && this.props.pageRechercheDisplay(true)}
              >
                <button
                  className={this.props.pageName === "search" ?
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormatSelected  "
                    :
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormat  "
                  }
                  style={{
                    fontSize: window.innerWidth > 576 ? '14px' : '11px'
                  }}
                >
                  Rechercher un débat
                </button >
              </Link>
            }

            {true &&
              <a // <Link
                className="col-5 col-lg-4 m-0 p-0 "
                href="/Spot_Apropos"
              // onClick={() => this.props.pageRechercheDisplay(true)}
              >
                <button
                  className={this.props.pageName === "apropos" ?
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormatSelected  "
                    :
                    "w-100 pb-1 m-0 p-0 w-100 text-center bandeauAccueilOptionsFormat  "
                  }
                  style={{
                    fontSize: window.innerWidth > 576 ? '14px' : '11px',
                  }}
                >
                  {this.capitalizeFirstLetter('à propos')}
                </button >
              </a> // </Link>
            }

          </div>
        </div>
      </div >

    )
  }
}


export default ComponentBandeauHautDePage
