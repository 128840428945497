import React, { Component } from 'react';


import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'
import ComponentCommentateur from './ComponentCommentateur'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import '/node_modules/swiper/swiper.min.css'
import '/node_modules/swiper/modules/pagination/pagination.min.css'
import '/node_modules/swiper/modules/free-mode/free-mode.min.css'
import '/node_modules/swiper/modules/effect-cards/effect-cards.min.css'
// import "./styles.css";
// import required modules
import { FreeMode, Pagination, EffectCards } from "swiper";


class ComponentCommentateurBox extends Component {

  state = {

    showConsoleLog: true,

    commentateur_argument_dict: {},
    init_commentateur_argument_dict: false,

  }


  render() {

    this.props.showConsoleLog && console.log('ComponentCommentateurBox -> quoteLinkID : ', this.props.quoteLinkID)
    this.props.showConsoleLog && console.log('ComponentCommentateurBox -> uniqueID : ', this.props.uniqueID)
    this.props.showConsoleLog && console.log('ComponentCommentateurBox -> commentateur_argument_dict : ', this.state.commentateur_argument_dict)


    const stylesSw2 = {
      root: {
        padding: '0 100px',
        // border: 'solid',
      },
      slideContainer: {
        padding: '0 10px',
        // border: 'solid',
      },
      slide: {
        padding: 15,
        minHeight: 100,
        color: '#ec7063',
        // border: 'solid',
      },
      slide1: {
        backgroundColor: '#fff',
        borderRadius: '5px',
      },
    }


    return (

      <div
        className={"m-0 quiPenseQuoiBox ".concat(window.innerWidth > 575.98 ? "w-100 h-100 pb-1 " : "col-12 pb-1 ")}
        style={{ position: 'relative', zIndex: '90' }}
      >

        <div className="d-flex w-100 flex-column " >


          <div className="quiPenseQuoiTitre text-center mt-3 mb-1" > {this.props.boxTitle.toUpperCase()} </div>

          {this.props.showConsoleLog && console.log("Citations_2 - listmedia :", this.props.listmedia)}


          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> listcommentateurs", this.props.listcommentateurs)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> quoteModalitiesSelected", this.props.quoteModalitiesSelected)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> argumentsSelected", this.props.argumentsSelected)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> listarguments", this.props.listarguments)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> affichageNombreCommentateurs : ", this.props.affichageNombreCommentateurs)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> niveauDeLectureValue : ", this.props.niveauDeLectureValue)}
          {this.props.showConsoleLog && console.log("ComponentCommentateurBox -> affichageNombreCommentateurs() : ", this.props.affichageNombreCommentateurs(this.props.niveauDeLectureValue))}

          {this.props.showConsoleLog && console.log('ComponentCommentateurBox -> this.props.argumentsSelected[0] : ', this.props.argumentsSelected[0])}
          {this.props.showConsoleLog && console.log('ComponentCommentateurBox -> commentateur_argument_dict 2 : ', this.state.commentateur_argument_dict[this.props.argumentsSelected[0]])}

          {
            window.innerWidth > 575.98 &&

            <section className="d-flex flex-wrap mt-2 pb-2 ml-0 pl-0 " >
              {
                this.props.listcommentateurs
                  .filter((element) => true
                    /*this.props.quoteModalitiesSelected !== undefined &&
                    (this.props.quoteModalitiesSelected.length === 0 ||
                      this.props.quoteModalitiesSelected.some(r => element.modalitiesList.map(n => n.id_quoteModality).indexOf(r) >= 0)
                    )
                    &&
                    this.props.argumentsSelected !== undefined &&
                    (this.props.argumentsSelected.length === 0 ||
                      this.props.argumentsSelected.some(r => this.state.commentateur_argument_dict[r].map(n => n.pers_originID).indexOf(element.person.pers_originID) > -1)
                    )*/
                  )
                  .slice(0, this.props.size !== undefined && this.props.size === "small" ? 2 :
                    this.props.affichageNombreCommentateurs(this.props.niveauDeLectureValue))
                  .map((element, index) => (

                    <div
                      key={this.props.uniqueID.concat(`_commentateur${element.person.idperson}`)}
                      className={"d-flex align-items-start m-0 mt-4 p-0 ".concat(this.props.size !== undefined && this.props.size === "small" ? "col-lg-6 " : "col-lg-4 ")}
                    >
                      {this.props.showConsoleLog && console.log("listcommentateurs - element : ", element)}
                      {this.props.showConsoleLog && console.log("listcommentateurs - idperson : ", element.person.idperson)}
                      {this.props.showConsoleLog && console.log("listcommentateurs - uniqueID : ", this.props.uniqueID.concat(`_${element.person.idperson}`))}
                      {this.props.showConsoleLog && console.log("listcommentateurs - element.reference : ", element.reference)}
                      {this.props.showConsoleLog && element.reference !== undefined && console.log("listcommentateurs - element.reference[0] : ", element.reference[0])}

                      <ComponentCommentateur
                        size={this.props.size}
                        addPosition={this.props.addPosition}
                        quoteID={this.props.quoteID}
                        quoteLinkID={this.props.quoteLinkID}
                        commentateurData={element}
                        reference={element.reference}
                        commentateur={element}
                        index={index}
                        getPersonPictureName={this.props.getPersonPictureName}
                        classAddOn={this.props.classAddOn}
                        classAddOn2={this.props.classAddOn2}
                        url_image={this.props.url_image}
                        uniqueID={this.props.uniqueID.concat(`_commentateur${element.person.idperson}`)}
                        quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                        qt_title={this.props.qt_title}
                        quote={this.props.quote}

                        contributionActivated={this.props.contributionActivated}

                        quoteTitle={this.props.quoteTitle}
                        commentateursAPIs={this.props.commentateursAPIs}
                        miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                        CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                        listsomeonetype={this.props.listsomeonetype}
                        listorganisationtype={this.props.listorganisationtype}
                        listreferencetype={this.props.listreferencetype}
                        listmediatype={this.props.listmediatype}
                        listperson={this.props.listperson}
                        listmedia={this.props.listmedia}

                        ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                        quoteModalities={this.props.quoteModalities}
                        quoteModalitesAPIs={this.props.quoteModalitesAPIs}
                        ArgumentsAPIs={this.props.ArgumentsAPIs}

                        listcommentateursPourId={this.props.listcommentateursPourId}
                        listcommentateursContreId={this.props.listcommentateursContreId}
                        listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}
                        listcommentateurs={this.props.listcommentateurs}

                        getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                        visitorLocation={this.props.visitorLocation}
                        queryTracker={this.props.queryTracker}
                        showConsoleLog={this.props.showConsoleLog}
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}

                        miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}
                        natureInformationTable={this.props.natureInformationTable}

                        listarguments={this.props.listarguments}
                      />
                    </div>
                  ))
              }
            </section>
          }



          {
            window.innerWidth < 575.98 &&

            <div className="mt-3 mb-2">

              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >

                {
                  this.props.listcommentateurs
                    .slice(0, this.props.affichageNombreCommentateurs(this.props.niveauDeLectureValue))
                    .map((element, index) => (
                      <SwiperSlide key={this.props.uniqueID.concat(`_${element.person.idperson}`)}
                        // className="d-flex align-items-start col-lg-4 m-0 mt-4 p-0 "
                        className=" "
                      >
                        {this.props.showConsoleLog && console.log("listcommentateurs - element : ", element)} {this.props.showConsoleLog && console.log("listcommentateurs - element.reference : ", element.reference)} {this.props.showConsoleLog && element.reference !== undefined && console.log("listcommentateurs - element.reference[0] : ", element.reference[0])}
                        <ComponentCommentateur
                          addPosition={this.props.addPosition}
                          quoteID={this.props.quoteID}
                          quoteLinkID={this.props.quoteLinkID}
                          commentateurData={element}
                          reference={element.reference}
                          commentateur={element}
                          index={index}
                          getPersonPictureName={this.props.getPersonPictureName}
                          classAddOn={this.props.classAddOn}
                          classAddOn2={this.props.classAddOn2}
                          url_image={this.props.url_image}
                          uniqueID={this.props.uniqueID.concat(`_${element.person.idperson}`)}
                          quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                          qt_title={this.props.qt_title}
                          quote={this.props.quote}

                          quoteTitle={this.props.quoteTitle}
                          commentateursAPIs={this.props.commentateursAPIs}
                          miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                          CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                          listsomeonetype={this.props.listsomeonetype}
                          listorganisationtype={this.props.listorganisationtype}
                          listreferencetype={this.props.listreferencetype}
                          listmediatype={this.props.listmediatype}
                          listperson={this.props.listperson}
                          listmedia={this.props.listmedia}

                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                          quoteModalities={this.props.quoteModalities}
                          quoteModalitesAPIs={this.props.quoteModalitesAPIs}

                          listcommentateursPourId={this.props.listcommentateursPourId}
                          listcommentateursContreId={this.props.listcommentateursContreId}
                          listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}
                          listcommentateurs={this.props.listcommentateurs}

                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          visitorLocation={this.props.visitorLocation}
                          queryTracker={this.props.queryTracker}
                          showConsoleLog={this.props.showConsoleLog}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}
                        />
                      </SwiperSlide>
                    ))
                }

              </Swiper>

            </div>

          }


        </div>
      </div>

    )
  }
}


export default ComponentCommentateurBox