import React, { Component } from 'react';

import '../full.css';


// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


class ComponentGrilleDeNotes extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    notes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],

  }


  render() {

    return (

      <div className="d-flex flex-row justify-content-center mt-3">
        <p className="pt-2 mr-4 align-middle ">(Pas pertinent du tout)</p>
        {
          this.state.notes.map((element) => (
            <Button
              className="mr-1"
              variant={this.props.markValue === element ? "dark" : "light"}
              onClick={() => this.props.changeMark(this.props.markID, element)}
            >
              {element}
            </Button>
          ))
        }
        <p className="pt-2 ml-3 align-middle ">(Très pertinent)</p>

      </div>

    )
  }

}
export default ComponentGrilleDeNotes