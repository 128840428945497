import React, { Component } from 'react';

import ComponentBandeauHautDePage from './ComponentBandeauHautDePage'
import ComponentFooter from './ComponentFooter'


//Elements API
// import { ApiClient, ReferenceApi } from 'spot_api_old'


class SourcePage extends Component {

  state = {
    showConsoleLog: true,
    initDataRequest: true,

    referenceDetails: [],
  }

  componentDidMount() {
    // this.initData()
  }

  initData = () => {

    this.referenceDetailsAPIs(parseInt(this.props.match.params.idSource))
  }

  componentDidUpdate() {

  }


  referenceDetailsAPIs = (sourceID) => {

    this.props.showConsoleLog && console.log('referenceDetailsAPIs in : ', sourceID)

    let defaultClient = this.props.spot_api.ApiClient.instance;
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
    ApiKeyAuth.apiKey = this.props.API_key;
    let apiInstance = new this.props.spot_api.ReferenceApi();

    this.props.queryTracker('SourcePage.js', 'referenceDetailsAPIs')

    apiInstance.referenceDetailsReferenceIDGet(sourceID, (error, data, response) => {
      if (error) {
        console.error(error)
        this.props.showConsoleLog && console.log('referenceDetailsAPIs -> error : ', error)
      }
      else {

        this.props.showConsoleLog && console.log('referenceDetailsAPIs -> data : ', data)

        this.setState({ referenceDetails: data })
      }

    })
  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {

    this.props.showConsoleLog && console.log('SourcePage -> referenceDetails : ', this.state.referenceDetails)

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("SourcePage -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    return (
      <div className="">

        <ComponentBandeauHautDePage
          url_image={this.props.url_image}
          pageName="intervenant"
          pageRechercheDisplay={this.props.pageRechercheDisplay}
        />

        <div className="container " id="SourcePage">

          <div className="w-100 text-center mt-5 intervenantTitle ">
            {"Source " + this.props.match.params.idSource}
          </div>

          <div className="mt-5 ">

            <div className="robotoFont16px mt-4 ">{"Informations relayées par cette source"}</div>
            <ul>
              {this.state.referenceDetails.map((element, index) => (
                <li>
                  <a href={'/citations_2/' + element.idquote}>
                    {this.props.miseEnFormeIntroAvecCitation(
                      this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction'),
                      element.q_title)
                    }
                  </a>
                </li>
              ))}
            </ul>

            <div className="robotoFont16px mt-4 ">{"Les intervenants directs et indirects"}</div>
            <ul>
              {this.state.referenceDetails.map((element, index) => (
                <li>{element.commentateur_pers_title}</li>
              ))}
            </ul>

            <div className="robotoFont16px mt-4 ">{"Répartition faits / opinions"}</div>

            <div className="robotoFont16px mt-4 ">{"Réparition sources primaires / secondaires"}</div>

          </div>

        </div>



        {/* FOOTER */}
        <ComponentFooter
          url_image={this.props.url_image}
        />

      </div >
    )
  }
}


export default SourcePage
