import React, { Component } from 'react';

import '../full.css';

// Elements Bootstrap
import Pagination from 'react-bootstrap/Pagination';

class ComponentPagination extends Component {

	state = {
		debats: [],
	}

	pageNumberVector(nbDebatsTotal) {
		const pageNumberVector = []
		const nbDebatsParPage = this.props.nbDebatsParPage
		const nbPages = Math.floor((nbDebatsTotal - 1) / nbDebatsParPage + 1)

		for (let i = 0; i < nbPages; ++i) {
			pageNumberVector.push(i + 1)
		}

		return pageNumberVector

	}


	render() {

		return (

			<Pagination className='paginationGlobal'>
				{this.pageNumberVector(this.props.nombreDebats).map((element, index) => (

					<Pagination.Item
						key={index}
						active={element === this.props.selectedPage}
						className={'paginationItemStyle'}
						onClick={() => this.props.onClick(element)}
					>
						{element}
					</Pagination.Item>

				))}

			</Pagination>

		)
	}
}

export default ComponentPagination;

