import React, { Component } from 'react';

import ComponentFormulaireNatureEtTitre from './ComponentFormulaireNatureEtTitre';

import Button from 'react-bootstrap/Button';

import '../full.css';
import "react-datepicker/dist/react-datepicker.css";

import $ from 'jquery';

const keyword_extractor = require("keyword-extractor");


class ComponentFormulaireQuoteManager extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    quote: {
      q_title: "",
      q_quoi: "",

      q_typeID: 0,
    },

    showConsoleLog: true,
    initAlreadyLaunched: false,
    simulationMode: false,

    longueurMaxTitre: 120,

    // Templates des modalités
    modalite_quand_template: {
      id_modalityType: 2,
      mo_title: "quand",
      qm_modalityContent: "",
      qm_quoteOriginID: 0,

      id_quoteModality: 0,
      qm_originID: 0,
      clearTemplateName: "clear_modalite_quand",
    },
    modalite_ou_template: {
      id_modalityType: 3,
      mo_title: "ou",
      qm_modalityContent: "",
      qm_quoteOriginID: 0,

      id_quoteModality: 0,
      qm_originID: 0,
      clearTemplateName: "clear_modalite_ou",
    },
    modalite_combien_template: {
      id_modalityType: 4,
      mo_title: "combien",
      qm_modalityContent: "",
      qm_quoteOriginID: 0,

      id_quoteModality: 0,
      qm_originID: 0,
      clearTemplateName: "clear_modalite_combien",
    },
    modalite_pourQui_template: {
      id_modalityType: 5,
      mo_title: "pour qui",
      qm_modalityContent: "",
      qm_quoteOriginID: 0,

      id_quoteModality: 0,
      qm_originID: 0,
      clearTemplateName: "clear_modalite_pourQui",
    },
    modalite_comment_template: {
      id_modalityType: 6,
      mo_title: "comment",
      qm_modalityContent: "",
      qm_quoteOriginID: 0,

      id_quoteModality: 0,
      qm_originID: 0,
      quote_author_position_ID: 0,
      clearTemplateName: "clear_modalite_comment",
    },

  }

  componentDidMount() {
  }

  componentDidUpdate = () => {
  }


  initData = () => {

    if (this.props.formulaireType === 'new quote' ||
      this.props.formulaireType === 'nuances' ||
      this.props.formulaireType === 'argument') { // création d'une nouvelle citation

      this.searchSimilarQuote('')

      this.setState({
        quote: {
          q_title: "",
          q_quoi: "",
          q_typeID: 0,

          qm_modalityID: 1, 
          qm_modalityContent: "",
        },

        echelleDebatSelectionneValue: 0,

        initAlreadyLaunched: true, // doit être passé à true avant de lancer handleChangesNature


        // Templates des modalités
        modalite_quand_template: {
          id_modalityType: 2,
          mo_title: "quand",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote === undefined ? 0 : this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_quand",
        },
        modalite_ou_template: {
          id_modalityType: 3,
          mo_title: "ou",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote === undefined ? 0 : this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_ou",
        },
        modalite_combien_template: {
          id_modalityType: 4,
          mo_title: "combien",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote === undefined ? 0 : this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_combien",
        },
        modalite_pourQui_template: {
          id_modalityType: 5,
          mo_title: "pour qui",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote === undefined ? 0 : this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_pourQui",
        },
        modalite_comment_template: {
          id_modalityType: 6,
          mo_title: "comment",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote === undefined ? 0 : this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          quote_author_position_ID: 0,
          clearTemplateName: "clear_modalite_comment",
        },
      }, () => {
        this.handleChangesNature(this.props.formulaireType === 'new quote' ? 1 : 0)
      })

    }
    else if (this.props.formulaireType === 'change title') { // quote en props => modification d'une citation

      this.setState({
        quote: { ...this.props.quote },

        initAlreadyLaunched: true, // doit être passé à true avant de lancer handleChangesNature
      })


    }
    else if (this.props.formulaireType === 'argument') {

    }
  }


  addNewModalityAPI = (quoteModality) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.QuoteApi()


    let idperson = this.props.commentateurData.person.pers_originID  // origin id de la personne
    let quote_link_typeID = this.props.quote_link_typeID  // position pour / contre
    let re_originID = this.props.reference.re_originID  // origin id de la reference_extract

    this.props.showConsoleLog && console.log("------------------------------")
    this.props.showConsoleLog && console.log("addNewModalityAPI -> idperson :", idperson)
    this.props.showConsoleLog && console.log("addNewModalityAPI -> quote_link_typeID :", quote_link_typeID)
    this.props.showConsoleLog && console.log("addNewModalityAPI -> quoteModality :", quoteModality)
    this.props.showConsoleLog && console.log("addNewModalityAPI -> re_originID :", re_originID)


    quoteModality.qm_modalityContent = this.replaceAll(quoteModality.qm_modalityContent, "'", "\\'")


    if (quoteModality.qm_quoteOriginID === 0 || quoteModality.qm_quoteOriginID === undefined || quoteModality.qm_quoteOriginID === null) {
      console.log(" !!!! addNewModalityAPI Problème de qm_quoteOriginID !!!!")
    } else {
      !this.state.simulationMode && true &&
        apiInstance.quoteModalitiesNewPost(idperson, quote_link_typeID, re_originID, quoteModality,
          (error, data, response) => { // Lier une quote à une personne (quote_author_position)
            if (error) {
              console.error(error);
            }
            else {

              this.props.showConsoleLog && console.log('addNewModalitiesAPI -> Ajout modalité OK')
              this.props.showConsoleLog && console.log('addNewModalitiesAPI -> response : ', response)


              this.props.quoteModalitesAPIs(this.props.quote.q_originID)

              this.handleChangeQuote(quoteModality.clearTemplateName)
            }
          })
    }

  }

  addModalityToReference = (modalityOriginID) => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.QuoteApi()

    let commentateurID = this.props.commentateurData.person.pers_originID
    let quoteLinkTypeID = this.props.quote_link_typeID
    let reOriginID = this.props.reference.re_originID

    this.props.showConsoleLog && console.log("addModalityToReference -> commentateurID :", commentateurID)
    this.props.showConsoleLog && console.log("addModalityToReference -> quoteLinkTypeID :", quoteLinkTypeID)
    this.props.showConsoleLog && console.log("addModalityToReference -> reOriginID :", reOriginID)
    this.props.showConsoleLog && console.log("addModalityToReference -> modalityOriginID :", modalityOriginID)

    true && apiInstance.addModalityToReferencePost(modalityOriginID, commentateurID, quoteLinkTypeID, reOriginID, (error, data, response) => { // Retirer une modalité d'une source
      if (error) {
        console.error(error);
      }
      else {

        this.props.showConsoleLog && console.log('addModalityToReference -> Modalité associée OK')
        this.props.showConsoleLog && console.log('addModalityToReference -> response : ', response)

        this.props.quoteModalitesAPIs(this.props.quote.q_originID)
      }
    })

  }



  addArgumentFromSourceAPI = (argument) => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ArgumentApi();

      let commentateurID = this.props.commentateurData.person.pers_originID
      let quoteLinkTypeID = this.props.quote_link_typeID
      let reOriginID = this.props.reference.re_originID
      let quote_qm_originID = this.props.quote.qm_originID

      argument.q_title = this.replaceAll(argument.q_title, "'", "\\'")
      argument.q_quoi = this.replaceAll(argument.q_quoi, "'", "\\'")

      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> argument :", argument)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> quote_qm_originID :", this.props.quote.q_originID)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> this.props.commentateurData :", this.props.commentateurData)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> commentateurID :", commentateurID)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> quoteLinkTypeID :", quoteLinkTypeID)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> reOriginID :", reOriginID)
      this.props.showConsoleLog && console.log("addArgumentFromSourceAPI -> quote_qm_originID :", quote_qm_originID)

      true && apiInstance.addNewargumentFromSourcePost(commentateurID, quoteLinkTypeID, reOriginID, quote_qm_originID, argument, (error, data, response) => { // Retirer une modalité d'une source
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('addArgumentFromSourceAPI -> Argument OK')
          this.props.showConsoleLog && console.log('addArgumentFromSourceAPI -> response : ', response)

          this.initData()
          // this.props.quoteModalitesAPIs(this.props.quote.q_originID)

          this.props.ArgumentsAPIs(this.props.quote.qm_originID)
        }
      })

    }
  }


  addCommentateurToExistingArgumentAPI = (quoteLinkID) => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ArgumentApi();

      this.props.showConsoleLog && console.log("addCommentateurToExistingArgumentAPI -> qlap_quoteLinkID :", quoteLinkID)

      let commentateurID = this.props.commentateurData.person.pers_originID
      let quoteLinkTypeID = this.props.quote_link_typeID
      let reOriginID = this.props.reference.re_originID

      this.props.showConsoleLog && console.log("addCommentateurToExistingArgumentAPI -> commentateurID :", commentateurID)
      this.props.showConsoleLog && console.log("addCommentateurToExistingArgumentAPI -> quoteLinkTypeID :", quoteLinkTypeID)
      this.props.showConsoleLog && console.log("addCommentateurToExistingArgumentAPI -> reOriginID :", reOriginID)

      true && apiInstance.manageArgumentFromSourcePost(quoteLinkID, commentateurID, quoteLinkTypeID, reOriginID, (error, data, response) => { // Retirer une modalité d'une source
        if (error) {
          console.error(error);
        }
        else {

          this.props.showConsoleLog && console.log('addCommentateurToExistingArgumentAPI -> Modalité retirée OK')
          this.props.showConsoleLog && console.log('addCommentateurToExistingArgumentAPI -> response : ', response)

          this.props.ArgumentsAPIs(this.props.quote.qm_originID)
          this.initData()

        }
      })

    }

  }


  removeCommentateurFromArgumentAPI = (element) => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.ArgumentApi();

      let commentateurID = this.props.commentateurData.person.pers_originID
      let quoteLinkTypeID = this.props.quote_link_typeID
      let reOriginID = this.props.reference.re_originID

      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> commentateurID :", commentateurID)
      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> quoteLinkTypeID :", quoteLinkTypeID)
      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> reOriginID :", reOriginID)

      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> element :", element)
      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> commentateursList :", element.commentateursList)

      let filter = element.commentateursList.filter((element) => (
        element.pers_originID === commentateurID &&
        element.quoteLinkTypeID === quoteLinkTypeID &&
        element.refExtract_originID === reOriginID &&
        element.qlap_statut === 1
      ))
      this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> filter :", filter)

      if (filter.length !== 1) {
        console.log("removeCommentateurFromArgumentAPI problème #1 : 0 ou plusieurs liens trouvés ... ")
      } else {

        let quote_link_author_position_ID = filter[0].quote_link_author_position_ID
        this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> quote_link_author_position_ID :", quote_link_author_position_ID)
        this.props.showConsoleLog && console.log("removeCommentateurFromArgumentAPI -> isInteger :", Number.isInteger(quote_link_author_position_ID))

        if (!Number.isInteger(quote_link_author_position_ID)) {
          console.log("removeCommentateurFromArgumentAPI problème #2 : quote_link_author_position_ID is not integer ... ")
        } else {

          true && apiInstance.manageArgumentFromSourcePatch(quote_link_author_position_ID, (error, data, response) => { // Supprimer un commentateur d'un argument
            if (error) {
              console.error(error);
            }
            else {

              this.props.showConsoleLog && console.log('removeCommentateurFromArgumentAPI -> Suppression OK')
              this.props.showConsoleLog && console.log('removeCommentateurFromArgumentAPI -> response : ', response)

              // this.initData()
              // this.props.quoteModalitesAPIs(this.props.quote.q_originID)

              this.props.ArgumentsAPIs(this.props.quote.qm_originID)
            }
          })

        }

      }

    }
  }

  removeModalityFromReference = (quote_author_position_ID) => {

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key
    let apiInstance = new this.props.spot_api.QuoteApi()

    this.props.showConsoleLog && console.log('removeModalityFromReference -> quote_author_position_ID : ', quote_author_position_ID)

    true && apiInstance.removeModalityFromReferenceQuoteAuthorPositionIDDelete(quote_author_position_ID, (error, data, response) => { // Retirer une modalité d'une source
      if (error) {
        console.error(error);
      }
      else {

        this.props.showConsoleLog && console.log('removeModalityFromReference -> Modalité retirée OK')
        this.props.showConsoleLog && console.log('removeModalityFromReference -> response : ', response)

        this.props.quoteModalitesAPIs(this.props.quote.q_originID)
      }
    })

  }


  reorganizeArgumentsList = (listQuotes, quote) => {

    let data = []

    let tempListQuote = listQuotes.slice()

    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> listQuotes : ', listQuotes)
    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> tempListQuote : ', tempListQuote)

    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> quote.q_typeID : ', quote.q_typeID)

    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> this.state.quote : ', this.state.quote)
    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> this.props.quote : ', this.props.quote)

    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> argumentPourContre : ', this.props.argumentPourContre)
    this.props.showConsoleLog && console.log('reorganizeArgumentsList -> filterType : ', this.state.filterType)

    listQuotes !== undefined && listQuotes.length > 0 &&
      tempListQuote
        .filter((quote, index) => (
          this.props.argumentPourContre === undefined ? true :
            (this.props.argumentPourContre === "quoi" ?
              quote.q_typeID === 3
              :
              (this.props.argumentPourContre === "quand" ?
                quote.q_typeID === 2
                :
                (this.props.argumentPourContre === "comment" ?
                  (quote.q_typeID === 1 || quote.q_typeID === 3)
                  :
                  true)
              ))
          // && (this.state.searchBarText === "" ?
          //   true
          //   :
          //   quote.q_title.toLowerCase().includes(this.state.searchBarText.toLowerCase()))
        ) &&
          (this.state.filterType === undefined || this.state.filterType < 0 ?
            true
            :
            this.state.filterType === quote.q_typeID
          )
          &&
          quote.qt_title !== 'sondage'
        )
        .slice(0, 100)
        .forEach((quote) => {
          this.props.showConsoleLog && console.log('reorganizeArgumentsList -> listArgumentPour : ', this.state.listArgumentPour)
          quote.selected = quote.q_originID === this.state.selectedArgumentID

          quote.qlt_title =
            (this.props.argumentPourContre == "pour" || this.props.argumentPourContre == "contre" || this.props.argumentPourContre == "comment") ?
              this.state.listArgumentPour.includes(quote.q_originID) ? "pour" :
                (this.state.listArgumentContre.includes(quote.q_originID) ? "contre" :
                  (this.state.listArgumentPeutEtre.includes(quote.q_originID) ? "comment" : ""))
              :
              (this.props.argumentPourContre == "quoi" && (this.state.listArgumentPour.includes(quote.q_originID) || this.state.listArgumentContre.includes(quote.q_originID) || this.state.listArgumentPeutEtre.includes(quote.q_originID)) ? "dejaArgument" :
                (this.props.argumentPourContre == "quoi" && this.state.listArgumentQuoi.includes(quote.q_originID) ? "quoi" :
                  (this.props.argumentPourContre == "quand" && this.state.listArgumentQuand.includes(quote.q_originID) ? "quand" : "")))

          quote.titleWithIntro = quote.qt_title === "évènement" ?
            <div>
              <span style={{ color: 'grey' }}>
                {quote.q_vd_DateBeg !== undefined ?
                  <span style={{}}>{this.props.capitalizeFirstLetter(this.props.introDate2(quote).concat(",\xa0"))}</span>
                  :
                  <span style={{}}>{this.props.capitalizeFirstLetter(quote.q_quand.concat(",\xa0"))}</span>
                }
              </span>
              <span>{quote.q_title}</span>
            </div>
            :
            <div>
              <span style={{ color: 'grey' }}>
                {
                  this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', quote.q_typeID, 'introduction').concat("...\xa0")
                }
              </span>
              <span>{quote.q_title}</span>
            </div>

          data.push(quote)

        })

    // this.setState({ reorganizeArgumentsListData: data })

    this.props.showConsoleLog && console.log("reorganizeArgumentsList -> data :", data)

    return data

  }

  searchSimilarQuote = (sentence) => {

    // const sentence ="le développement de la 5G augmentera la consommation d'énergie de la France"
    // const sentence = "mettre le sujet du contrôle des frontières à l'agenda de la présidence francaise de l'UE avant juin 2022" // readSync('example.txt')

    if (this.props.ListSimilarQuoteAPIs !== undefined) {
      if (sentence.length > 3) {

        //  Extract the keywords
        const extraction_result =
          keyword_extractor.extract(sentence, {
            language: "french",
            remove_digits: true,
            return_changed_case: true,
            remove_duplicates: false,
            return_chained_words: false,
            return_max_ngrams: false

          });

        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> this.state.extraction_result : ', this.state.extraction_result)
        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> extraction_result : ', extraction_result)

        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> extraction_result : ', JSON.stringify(extraction_result))
        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> COND : ', JSON.stringify(this.state.extraction_result) !== JSON.stringify(extraction_result))

        if (JSON.stringify(this.state.extraction_result) !== JSON.stringify(extraction_result) || this.state.quote.q_typeID !== this.state.q_typeIDTampon) {
          this.setState({ extraction_result: extraction_result, q_typeIDTampon: this.state.quote.q_typeID })
          this.props.ListSimilarQuoteAPIs('SimilareQuotes', 0, 20, [], this.state.quote.q_typeID !== 0 ? [this.state.quote.q_typeID] : [], extraction_result)
        }

        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> q_typeID : ', this.state.quote.q_typeID)

      } else { // Initialisation des searchQuote à coder
        this.props.showConsoleLog && console.log('ComponentFormulaireQuoteManager -> ListSimilarQuoteAPIs : ', this.props.ListSimilarQuoteAPIs)
        this.props.ListSimilarQuoteAPIs !== undefined && this.props.ListSimilarQuoteAPIs('SimilareQuotes', 0, 20, [], this.state.quote.q_typeID !== 0 ? [this.state.quote.q_typeID] : [], [])
      }

    }

  }


  handleChangesNature = (selectedOption) => {
    let value = -1

    if (typeof selectedOption === 'number') {
      value = selectedOption
    } else {
      value = selectedOption.value
    }

    let temp_qt_title = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, "id", value, "nature")

    if (this.state.quote.q_typeID === value) {
      value = 0
    }

    if (selectedOption === 4 || selectedOption === 5) {
      value = 0
    }


    this.setState({
      quote: {
        ...this.state.quote,
        // q_title: infoTitreGuideSansIntro,
        q_typeID: value,
        qt_title: temp_qt_title,

        q_quand: "",
        q_vd_DateBeg: "",
        q_vd_DateEnd: "",
        q_vd_timeFormat: "jj/mm/aaaa",
        q_vd_timeIntro: "le",
        q_vd_timeScale: "jour",
        q_vd_timeType: "date"
      }
    }, () => this.searchSimilarQuote(this.state.quote.q_quoi))

  }


  getArrayIndexByAttributeValue = (objectsArray, attributeName, attributeValue) => {
    let result = []
    objectsArray.forEach((element, index) => {
      this.props.showConsoleLog && console.log('getArrayIndexByAttributeValue -> attributeValue : ', attributeValue, ' // element : ', element, ' // element.getAttribute(attributeName) : ', $(element).attr(attributeName))
      if (($(element).attr(attributeName)) === attributeValue) {
        this.props.showConsoleLog && console.log('getArrayIndexByAttributeValue -> OK ')
        result = element
      }
    })

    return result
  }

  initFormulaireArgument = () => {
    this.props.showConsoleLog && console.log('ComponentFormulaireArgument - initFormulaireArgument')

    let tempQuote_link_typeID = 0
    let tempIdquotetype = []

    // Nature des informations à afficher dans la liste de sélection (fait, évènements etc.)
    if (this.props.quoteNature === "fait" || this.props.argumentPourContre === 'quoi') {
      tempIdquotetype.push(3)
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type quoi')
    }
    if (this.props.argumentPourContre === 'quand') {
      tempIdquotetype.push(2)
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type quand')
    }
    if (this.props.argumentPourContre === 'comment') {
      tempIdquotetype.push(1)
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type comment')
    }

    // Définition du type de lien entre les citations
    if (this.props.argumentPourContre === 'pour' || this.props.argumentPourContre === 'solutions') {
      tempQuote_link_typeID = 1
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type pour')
    }
    if (this.props.argumentPourContre === 'contre') {
      tempQuote_link_typeID = 2
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type contre')
    }
    if (this.props.argumentPourContre === 'quoi') {
      tempQuote_link_typeID = 4
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type pour')
    }
    if (this.props.argumentPourContre === 'quand') {
      tempQuote_link_typeID = 5
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type contre')
    }
    if (this.props.argumentPourContre === 'comment') {
      tempQuote_link_typeID = 6
      this.props.showConsoleLog && console.log('ComponentFormulaireArgument : type contre')
    }

    this.props.showConsoleLog && console.log('ComponentFormulaireArgument -> tempIdquotetype : ', tempIdquotetype)

    this.setState(
      {

        listArgumentPour: [],
        listArgumentContre: [],
        listArgumentPeutEtre: [],
        listArgumentQuoi: [],
        listArgumentQuand: [],

        // reorganizeArgumentsListData: [],

        quote_link_typeID: tempQuote_link_typeID,

        selectedArgumentID: this.props.selectedArgumentID !== undefined ? this.props.selectedArgumentID : 0, // Si l'argument est renseigné en props => formulaire modification

        idQuoteTypeAllowed: tempIdquotetype,
        // menuAjouterSupprimer: 'Ajouter',
        // searchBarText: "",
        argumentNouveau: true,
        // afficherMenuSelectionNouvelArgument: false,
        filterType: -1,
        // listquoteSearch: [],
      },
    )

    // this.props.ListQuoteAPIs('SearchPage', 0, 20, [], [], [])

    this.props.showConsoleLog && console.log('ComponentFormulaireArgument -> listquoteSearch : ', this.props.listquoteSearch)

    if (this.props.selectedArgumentID === undefined) {
      this.setState({ selectedArgumentID: 0 }) // permet de décocher un argument précédemment sélectionné
    }


    this.checkKindOfArguments(this.props.listargumentsAll)

  }


  checkKindOfArguments = (listargumentsAll) => {

    this.props.showConsoleLog && console.log("checkKindOfArguments -> listargumentsAll : ", listargumentsAll)

    let listArgumentPour = []
    let listArgumentContre = []
    let listArgumentPeutEtre = []
    let listArgumentQuoi = []
    let listArgumentQuand = []
    let listArgumentQuand2 = []

    if (listargumentsAll !== undefined) {
      listargumentsAll.forEach((argument) => {
        let pastQuotesIDList = argument.quote.pastQuotesIDList
        if (argument.qlt_title === "pour") {
          // listArgumentPour.push(argument.ql_supportID)
          listArgumentPour = listArgumentPour.concat(pastQuotesIDList)
        }
        if (argument.qlt_title === "contre") {
          // listArgumentContre.push(argument.ql_supportID)
          listArgumentContre = listArgumentContre.concat(pastQuotesIDList)
        }
        if (argument.qlt_title === "comment") {
          // listArgumentPeutEtre.push(argument.ql_supportID)
          listArgumentPeutEtre = listArgumentPeutEtre.concat(pastQuotesIDList)
        }
        if (argument.qlt_title === "quoi") {
          // listArgumentQuoi.push(argument.ql_supportID)
          listArgumentQuoi = listArgumentQuoi.concat(pastQuotesIDList)
        }
        if (argument.qlt_title === "quand") {
          // listArgumentQuand.push(argument.ql_supportID)
          listArgumentQuand = listArgumentQuand.concat(pastQuotesIDList)
        }
      })
    }

    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentPour :", listArgumentPour)
    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentContre :", listArgumentContre)
    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentPeutEtre :", listArgumentPeutEtre)
    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentQuoi :", listArgumentQuoi)
    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentQuand :", listArgumentQuand)
    this.props.showConsoleLog && console.log("checkKindOfArguments -> listArgumentQuand2 :", listArgumentQuand2)

    this.setState({
      listArgumentPour: listArgumentPour,
      listArgumentContre: listArgumentContre,
      listArgumentPeutEtre: listArgumentPeutEtre,
      listArgumentQuoi: listArgumentQuoi,
      listArgumentQuand: listArgumentQuand,
    })
  }

  replaceAll(string, search, replace) {
    let returnString = ""
    if (string !== "" && string !== null && string !== undefined) {
      returnString = string.split(search).join(replace)
    }
    return returnString
  }

  handleChangeQuote = (attributeName, attributeValue, q_originID) => {
    var shortenText = ''

    this.changeSelectedArgumentID(0)

    this.props.showConsoleLog && console.log("handleChangeQuote -> selectDebatEchelle attributeName", attributeName)
    this.props.showConsoleLog && console.log("handleChangeQuote -> selectDebatEchelle attributeValue", attributeValue)

    if (attributeName === 'titre') {
      shortenText = attributeValue.slice(0, this.state.longueurMaxTitre)
      this.props.showConsoleLog && console.log('Evènement titre -> shortenText : ', shortenText)
      this.setState({ quote: { ...this.state.quote, q_title: shortenText } })
    }

    if (attributeName === 'nature') {

      let infoTitreGuideSansIntro =
        this.state.infoTitreQuoi +
        (this.state.infoTitreCombien === '' ? '' : '\xa0' + this.state.infoTitreCombien) +
        (this.state.infoTitreOu === '' ? '' : '\xa0' + this.state.infoTitreOu) +
        (this.state.infoTitreQuand === '' ? '' : '\xa0' + this.state.infoTitreQuand) +
        (this.state.infoTitreQui === '' ? '' : '\xa0' + this.state.infoTitreQui)

      this.setState({
        quote: {
          ...this.state.quote,
          q_title: infoTitreGuideSansIntro,
          q_typeID: attributeValue,
        }
      })
    }

    if (attributeName === 'q_quoi') {
      shortenText = attributeValue.slice(0, this.state.longueurMaxTitre)
      this.setState({ quote: { ...this.state.quote, q_quoi: shortenText } }, () => {
        this.changeTitle()
        this.searchSimilarQuote(attributeValue)
      })
    }
    if (attributeName === 'q_combien') {
      this.setState({ modalite_combien_template: { ...this.state.modalite_combien_template, qm_modalityContent: attributeValue } }, () => this.changeTitle())
    }
    if (attributeName === 'q_ou') {
      this.props.showConsoleLog && console.log("handleChangeQuote -> selectDebatEchelle ")
      this.setState({ modalite_ou_template: { ...this.state.modalite_ou_template, qm_modalityContent: attributeValue } }
        //   , () => this.changeTitle()
      )
    }
    if (attributeName === 'q_quand') {
      this.props.showConsoleLog && console.log("modalite_quand_template -> attributeValue :", attributeValue)
      this.setState({ modalite_quand_template: { ...this.state.modalite_quand_template, qm_modalityContent: attributeValue } },
        //  () => this.setState({ quote: { ...this.state.quote, qm_modalityContent: this.props.introDate2(this.state.quote) } },
        // () => this.changeTitle()
        //)
      )
    }
    if (attributeName === 'q_pourQui') {
      this.setState({ modalite_pourQui_template: { ...this.state.modalite_pourQui_template, qm_modalityContent: attributeValue } }, () => this.changeTitle())
    }
    if (attributeName === 'q_comment') {
      this.setState({ modalite_comment_template: { ...this.state.modalite_comment_template, qm_modalityContent: attributeValue } }, () => this.changeTitle())
    }
    if (attributeName === 'q_vd_timeIntro') {

      this.props.showConsoleLog && console.log("handleChangeQuote -> attributeName :", attributeName)
      this.props.showConsoleLog && console.log("handleChangeQuote -> attributeValue :", attributeValue)

      this.setState({
        quote: {
          ...this.state.quote,
          q_vd_timeIntro: attributeValue.title,
          q_vd_timeType: attributeValue.timeType, // timeType === "date" ou "duree"
          q_vd_DateEnd: null,
        }
      }, () => this.setState({ quote: { ...this.state.quote, q_quand: this.props.introDate2(this.state.quote) } }, () => this.changeTitle()))
    }
    if (attributeName === 'q_vd_timeFormat') {

      this.props.showConsoleLog && console.log("handleChangeQuote -> attributeName :", attributeName)
      this.props.showConsoleLog && console.log("handleChangeQuote -> attributeValue :", attributeValue)

      this.setState({
        quote: {
          ...this.state.quote,
          q_vd_timeFormat: attributeValue.title, // timeScale === "jj/mm/aaaa" etc.
          q_vd_timeScale: attributeValue.timeScale, // timeScale === "jour" ou "mois"
          q_vd_timeIntro: attributeValue.timeScale === "jour" ? "le" : "en",
          q_vd_timeType: "date",
          q_vd_DateEnd: null,
        }
      }, () => this.setState({ quote: { ...this.state.quote, q_quand: this.props.introDate2(this.state.quote) } }, () => this.changeTitle()))
    }

    if (attributeName === 'modalite_quand_template') {
      this.setState({ modalite_quand_template: attributeValue }, () => this.changeTitle())
    }

    if (attributeName === 'modalite_ou_template') {
      this.setState({ modalite_ou_template: attributeValue }, () => this.changeTitle())
    }

    if (attributeName === 'modalite_combien_template') {
      this.setState({ modalite_combien_template: attributeValue }, () => this.changeTitle())
    }

    if (attributeName === 'modalite_pourQui_template') {
      this.setState({ modalite_pourQui_template: attributeValue }, () => this.changeTitle())
    }

    if (attributeName === 'modalite_comment_template') {
      this.setState({ modalite_comment_template: attributeValue }, () => this.changeTitle())
    }

    if (attributeName === 'clear_modalite_quand') {
      this.setState({
        modalite_quand_template: {
          id_modalityType: 2,
          mo_title: "quand",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_quand",
        }
      }, () => this.changeTitle())
    }

    if (attributeName === 'clear_modalite_ou') {
      this.setState({
        modalite_ou_template: {
          id_modalityType: 3,
          mo_title: "ou",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_ou",
        }
      }, () => this.changeTitle())
    }

    if (attributeName === 'clear_modalite_combien') {
      this.setState({
        modalite_combien_template: {
          id_modalityType: 4,
          mo_title: "combien",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_combien",
        }
      }, () => this.changeTitle())
    }

    if (attributeName === 'clear_modalite_pourQui') {
      this.setState({
        modalite_pourQui_template: {
          id_modalityType: 5,
          mo_title: "pour qui",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          clearTemplateName: "clear_modalite_pourQui",
        }
      }, () => this.changeTitle())
    }

    if (attributeName === 'clear_modalite_comment') {
      this.setState({
        modalite_comment_template: {
          id_modalityType: 6,
          mo_title: "comment",
          qm_modalityContent: "",
          qm_quoteOriginID: this.props.quote.q_originID,

          id_quoteModality: 0,
          qm_originID: 0,
          quote_author_position_ID: 0,
          clearTemplateName: "clear_modalite_comment",
        }
      }, () => this.changeTitle())
    }

  }


  changeSelectedArgumentID = (value) => {
    this.props.showConsoleLog && console.log("changeSelectedArgumentID -> value :", value)
    this.setState({
      selectedArgumentID: (this.state.selectedArgumentID === value ? 0 : value),
      argumentNouveau: this.state.selectedArgumentID === value || value === 0,
    }, // () => this.reorganizeArgumentsList(this.props.listquoteSimilar)
    )
  }


  changeTitle = () => {

    let title = ""

    this.props.showConsoleLog && console.log("changeTitle -> quote : ", this.state.quote)

    this.props.showConsoleLog && console.log("changeTitle -> q_quoiInd : ", this.state.quote.q_quoiInd)
    this.props.showConsoleLog && console.log("changeTitle -> q_combienInd : ", this.state.quote.q_combienInd)
    this.props.showConsoleLog && console.log("changeTitle -> q_quandInd : ", this.state.quote.q_quandInd)
    this.props.showConsoleLog && console.log("changeTitle -> q_quand : ", this.state.quote.q_quand)
    this.props.showConsoleLog && console.log("changeTitle -> q_ouInd : ", this.state.quote.q_ouInd)
    this.props.showConsoleLog && console.log("changeTitle -> q_pourQuiInd : ", this.state.quote.q_pourQuiInd)

    title = this.state.quote.q_quoi === "-1" ? "" : this.removeFirstLastSpace(this.state.quote.q_quoi)

    this.setState({ quote: { ...this.state.quote, q_title: title } })
  }



  removeFirstLastSpace = (stringValue) => {
    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue : ", '"'.concat(stringValue, '"'))
    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue 1st : ", stringValue.slice(0, 1))
    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue last : ", stringValue.slice(-1))

    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue 1st cond : ", stringValue.slice(0, 1) === " ")
    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue last cond : ", stringValue.slice(-1) === " ")

    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue 1st cut : ", stringValue.slice(1))
    this.props.showConsoleLog && console.log("removeFirstLastSpace -> stringValue last cut : ", stringValue.slice(0, -1))

    let returnString = stringValue

    while (returnString.slice(0, 1) === " ") {
      returnString = returnString.slice(1)
    }

    while (returnString.slice(-1) === " ") {
      returnString = returnString.slice(0, -1)
    }

    this.props.showConsoleLog && console.log("removeFirstLastSpace -> returnString : ", '"'.concat(returnString, '"'))

    return returnString

  }


  checkAdd = (quote) => {
    this.props.showConsoleLog && console.log("checkAdd -> quote : ", quote)
    if (this.state.quote.q_typeID !== 0 && quote.q_title.length > 3) {
      this.props.addQuote(quote, true) // create quote and loadNewPage 
    } else {
      this.setState({ missingData: true })
    }
  }



  render() {

    const quote = this.state.quote
    const reorganizeArgumentsListData = this.props.listquoteSimilar !== undefined ? this.reorganizeArgumentsList(this.props.listquoteSimilar, quote) : []

    this.props.showConsoleLog && console.log("show :", this.props.show)
    this.props.showConsoleLog && console.log("initAlreadyLaunched :", this.state.initAlreadyLaunched)
    this.props.showConsoleLog && console.log("q_typeID :", this.state.quote.q_typeID)


    if (this.props.show && !this.state.initAlreadyLaunched) {
      this.initData()
    }

    this.props.showConsoleLog && console.log("this.props.quoteModalities :", this.props.quoteModalities)
    this.props.showConsoleLog && console.log("ComponentFormulaireQuoteManager -> this.props.quote :", this.props.quote)

    const infoTitreIntro_currentQuote = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.state.quote.q_typeID, 'introduction')
    const infoTitreIntro_mainQuote = this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', this.props.quote === undefined ? this.state.quote.q_typeID : this.props.quote.q_typeID, 'introduction')

    this.props.showConsoleLog && console.log("ComponentFormulaireQuoteManager -> modalite_quand_template :", this.state.modalite_quand_template)
    
    
    this.props.showConsoleLog && console.log("ComponentFormulaireQuoteManager -> commentateurData :", this.props.commentateurData)
    
    return (

      <div className="d-flex flex-column col-lg-12 m-0 p-0 " >


        <div className={'d-flex justify-content-center m-0 p-0 rounded '}>
          <div className='d-flex col-12 col-lg-12 m-0 p-0 borderRadius' >
            <div className='d-flex flex-column w-100 m-0 p-0 ' >

              { // Sélection de la nature de l'info et du titre

                <div className="d-flex borderRadius ">

                  <ComponentFormulaireNatureEtTitre
                    // Variables
                    formulaireType={this.props.formulaireType}
                    quote={this.state.quote} // Quote en train d'être modifiée 
                    mainQuote={this.props.quote === undefined ? this.state.quote : this.props.quote} // Quote sur laquelle on agit
                    infoTitreIntro_currentQuote={infoTitreIntro_currentQuote}
                    infoTitreIntro_mainQuote={infoTitreIntro_mainQuote}

                    // Constantes
                    url_image={this.props.url_image}
                    showConsoleLog={this.props.showConsoleLog}

                    natureInformationTable={this.props.natureInformationTable}

                    evenementTypeList={this.props.evenementTypeList}
                    dateTypeIntroAll={this.props.dateTypeIntroAll}

                    longueurMaxTitre={this.state.longueurMaxTitre}

                    citiesList={this.props.citiesList}
                    departmentsList={this.props.departmentsList}
                    regionsList={this.props.regionsList}

                    selectDebatEchelle1={this.selectDebatEchelle1}
                    selectDebatEchelle2={this.selectDebatEchelle2}
                    handleInputChange={this.props.handleInputChange}
                    echelleDebatSelectionneValue={this.state.echelleDebatSelectionneValue}
                    echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}

                    // Fonctions
                    handleChangeQuote={this.handleChangeQuote}
                    handleChangesNature={this.handleChangesNature}
                    miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}
                    getArrayIndexByAttributeValue={this.getArrayIndexByAttributeValue}
                    handleChangeStartDate={this.handleChangeStartDate}
                    handleChangeEndDate={this.handleChangeEndDate}
                    transformDepartementArrayToSelectObject={this.props.transformDepartementArrayToSelectObject}
                    transformVilleArrayToSelectObject={this.props.transformVilleArrayToSelectObject}

                    listquoteSimilar={this.props.listquoteSimilar}

                    getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                    reorganizeArgumentsListData={reorganizeArgumentsListData}
                    selectedArgumentID={this.state.selectedArgumentID}
                    changeSelectedArgumentID={this.changeSelectedArgumentID}
                    idQuoteTypeAllowed={this.state.idQuoteTypeAllowed}
                    argumentPourContre={this.props.argumentPourContre}

                    modalite_combien_template={this.state.modalite_combien_template}
                    modalite_ou_template={this.state.modalite_ou_template}
                    modalite_quand_template={this.state.modalite_quand_template}
                    modalite_pourQui_template={this.state.modalite_pourQui_template}
                    modalite_comment_template={this.state.modalite_comment_template}

                    quoteModalities={this.props.quoteModalities}
                    addNewModalityAPI={this.addNewModalityAPI}
                    removeModalityFromReference={this.removeModalityFromReference}
                    addModalityToReference={this.addModalityToReference}

                    reference={this.props.reference}
                    commentateurData={this.props.commentateurData}
                    addArgumentFromSourceAPI={this.addArgumentFromSourceAPI}
                    removeCommentateurFromArgumentAPI={this.removeCommentateurFromArgumentAPI}
                    addCommentateurToExistingArgumentAPI={this.addCommentateurToExistingArgumentAPI}

                    initData={this.initData}
                  />

                </div>
              }


              { // Affichage des boutons de bas de page
                <div className="d-flex mt-4">
                  <div className="d-flex flex-row justify-content-center align-items-start w-100 ">

                    <Button
                      className="col-lg-3 border"
                      onClick={() => {
                        this.props.changeShowState(false)
                        this.props.keepPopover(false)
                      }}
                      // disabled={false}
                      variant='light'
                    >
                      {this.props.formulaireType === "nuances" || this.props.formulaireType === "argument" ? "Fermer" : "Annuler"}
                    </Button>

                    {this.props.formulaireType === "new quote" &&
                      <div className="d-flex flex-column col-lg-6 ">

                        <Button
                          className="buttonStepper validerBox w-100 ml-auto mr-auto text-center"
                          style={{ backgroundColor: "#E55C5C", borderColor: "#E55C5C" }}
                          onClick={() => this.checkAdd(quote)}
                          disabled={(this.state.quote.q_typeID === 0 || quote.q_title.length <= 3)}
                        >
                          Créer un nouveau débat
                        </Button>

                        <em
                          className="ml-auto mr-auto text-center robotoFont15px "
                          style={{ fontSize: window.innerWidth > 575.98 ? '13px' : '12px', color: "#E55C5C" }}
                        >
                          {this.state.quote.q_typeID === 0 ? "Veuillez sélectionner la nature de l'information" :
                            ((this.state.quote.q_typeID !== 0 && quote.q_title.length <= 3) ? "Veuillez saisir un titre" : "")}
                        </em>

                      </div>
                    }




                  </div>
                </div>
              }



            </div>
          </div>



        </div>

      </div>

    )
  }
}

export default ComponentFormulaireQuoteManager