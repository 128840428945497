import React, { Component, useRef } from 'react';


import ComponentPourComprendreCard from './ComponentPourComprendreCard'

import { Scrollbars } from 'react-custom-scrollbars';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import '/node_modules/swiper/swiper.min.css'
import '/node_modules/swiper/modules/pagination/pagination.min.css'
import '/node_modules/swiper/modules/free-mode/free-mode.min.css'
import '/node_modules/swiper/modules/effect-cards/effect-cards.min.css'
// import "./styles.css";
// import required modules
import { FreeMode, Pagination, EffectCards } from "swiper";


// Elements Bootstrap
import Accordion from 'react-bootstrap/Accordion'



class ComponentArgumentBox extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.scrollbar = React.createRef()
  }

  state = {

    showConsoleLog: true,

  }


  render() {

    this.props.showConsoleLog && console.log("ComponentArgumentBox -> listquoteSimilar :", this.props.listquoteSimilar)

    const stylesSw1 = {
      root: {
        paddingLeft: '0',
        paddingRight: '40px',
        maxWidth: window.innerWidth * 0.9,
        // border: 'solid',
      },
      slideContainer: {
        padding: '0 10px',
        // border: 'solid',
      },
      slide: {
        padding: 15,
        minHeight: 50,
        color: '#ec7063',
        // border: 'solid',
      },
      slide1: {
        backgroundColor: '#faf1d0',
        borderRadius: '5px',
      },
    }


    const scrollToTop = (id) => {
      if (!this.scrollbar || !this.scrollbar.current) {
        return;
      }

      this.props.showConsoleLog && console.log("scrollToTop -> id : ", id)

      var testDiv = document.getElementById(id)

      this.props.showConsoleLog && console.log("scrollToTop -> offsetTop : ", testDiv.offsetTop)
      this.props.showConsoleLog && console.log("scrollToTop -> offsetHeight : ", testDiv.offsetHeight)

      this.scrollbar.current.view.scroll({
        top: testDiv.offsetTop,
        behavior: 'smooth',
      });

    };

    this.props.showConsoleLog && console.log("ComponentArgumentBox -> argumentPourContre : ", this.props.argumentPourContre)
    this.props.showConsoleLog && console.log("ComponentArgumentBox -> listarguments : ", this.props.listarguments)

    let filteredArguments = this.props.listarguments.filter(({ quote }) =>
      // && this.checkIfVect1IncludesValuesFromVect2(this.getAttributListNiv2(quote, 'themes', 't_title'), this.state.filtresActifsThematiques) === false    // affichage des arguments seulement si leurs thématiques n'apparaissent PAS dans la liste des paramètres filtrés
      (this.props.filtresActifsNature.length === 0 || this.props.filtresActifsNature.includes(quote.qt_title))  // affichage des arguments seulement si leur nature n'apparait PAS dans la liste des paramètres filtrés
    )

    this.props.showConsoleLog && console.log('filteredArguments : ', filteredArguments)

    let perChunk = 3 // items per chunk   

    let filteredArgumentsSplitted = filteredArguments.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])
    this.props.showConsoleLog && console.log('filteredArgumentsSplitted : ', filteredArgumentsSplitted)

    return (

      <div className="d-flex w-100 p-0 pb-3 quiPenseQuoiBox ">
        <div className="d-flex w-100 flex-column " style={{ position: 'relative' }}>

          <div className="quiPenseQuoiTitre text-center mt-4 mb-4 ">
            {this.props.boxTitle.toUpperCase()}
          </div>

          {this.props.showConsoleLog && console.log("Citation_2.js -> this.props.listarguments : ", this.props.listarguments)}

          {this.props.listarguments.length !== 0 && window.innerWidth > 575.98 &&
            <div
              className="argumentBoxLines "
              style={{
                width: '100%',
                height: this.props.argumentPourContre === "comment" ? '250px' : '400px',
                maxHeight: this.props.argumentPourContre === "comment" ? '250px' : '400px',
              }}
            >
              <Scrollbars
                // onScrollFrame={handleScroll}
                ref={this.scrollbar}
                style={{
                  width: '100%',
                  height: this.props.argumentPourContre === "comment" ? '300px' : '450px',
                  maxHeight: this.props.argumentPourContre === "comment" ? '240px' : '390px',
                }}
              // onScrollFrame={(values) => console.log("scroll : ", values.top)}
              // onScrollStop={() => }
              >
                <div className=""
                  style={{
                    width: '100%',
                    maxHeight: this.props.argumentPourContre === "comment" ? '240px' : '390px',
                  }}
                >
                  <Accordion
                    className='m-0 p-0'
                    bsPrefix={{ backgroundColor: 'transparent' }}
                  // onSelect={(e) => console.log("Accordion : ", e)}
                  > {/* defaultActiveKey="0" */}
                    {filteredArguments
                      .map((element) => (

                        <div
                          className="w-100 m-0 pl-1 pr-1 "
                          key={`argument_`.concat(`_${element.quote.idquote}`)} // this.props.formulaireType, this.props.argumentPourContre, 
                          id={`argument_`.concat(`_${element.quote.idquote}`)}
                          onClick={() => setTimeout(() => {
                            scrollToTop(`argument_`.concat(`_${element.quote.idquote}`))
                          }, 350)}
                        >
                          <ComponentPourComprendreCard
                            argument={element}
                            keyValue={element.quote.idquote}
                            pourOuContre={element.qlt_title}
                            quoteLinkID={element.ql_quoteLinkID}

                            commentateurs={this.props.commentateurs} // A supprimer lorsque l'API permettant de récupérer les personne d'accord avec la causalité sera prête

                            mainQuoteTitle={this.props.mainQuoteTitle}

                            listquoteSearch={this.props.listquoteSearch}
                            natureInformationTable={this.props.natureInformationTable}
                            natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                            natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                            natureInformationTable_faits={this.props.natureInformationTable_faits}
                            listThemes={this.props.listThemes}

                            linkTypeList={this.props.linkTypeList}
                            echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}

                            // Fonctions
                            ArgumentsAPIs={this.props.ArgumentsAPIs}
                            ListQuoteAPIs={this.props.ListQuoteAPIs}
                            titreDebat={this.props.titreDebat}
                            miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                            miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}
                            capitalizeFirstLetter={this.props.capitalizeFirstLetter}
                            introDate2={this.props.introDate2}
                            getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                            queryTracker={this.props.queryTracker}

                            visitorLocation={this.props.visitorLocation}
                            showConsoleLog={this.props.showConsoleLog}
                            url_image={this.props.url_image}
                            spot_api={this.props.spot_api}
                            API_key={this.props.API_key}


                            // Pour ComponentCommentateurBox
                            affichageNombreCommentateurs={this.props.affichageNombreCommentateurs}
                            commentateursAPIs={this.props.commentateursAPIs}
                            getPersonPictureName={this.props.getPersonPictureName}
                            listmedia={this.props.listmedia}
                            listmediatype={this.props.listmediatype}
                            listorganisationtype={this.props.listorganisationtype}
                            listperson={this.props.listperson}
                            listreferencetype={this.props.listreferencetype}
                            listsomeonetype={this.props.listsomeonetype}
                            niveauDeLectureValue={this.props.niveauDeLectureValue}
                            quoteTitle={this.props.quoteTitle}
                            ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                            evenementTypeList={this.props.evenementTypeList}
                            dateTypeIntro={this.props.dateTypeIntro}
                            dateTypeIntro2={this.props.dateTypeIntro2}
                            dateTypeIntroAll={this.props.dateTypeIntroAll}

                            regionsList={this.props.regionsList}
                            departmentsList={this.props.departmentsList}
                          />

                        </div>
                      ))
                    }
                  </Accordion>
                </div>
              </Scrollbars>
            </div>
          }


          {this.props.listarguments.length !== 0 && window.innerWidth < 575.98 &&
            <div className="argumentBoxLines " style={{}}>

              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >

                {filteredArgumentsSplitted.map((filteredArgumentsItem, index) => (

                  <SwiperSlide
                    key={`argumentSplit_${index}_${filteredArgumentsItem[0].qlt_title}`}
                    className="m-0 p-0  "
                  >

                    {
                      filteredArgumentsItem.map(({ qlt_title, quote, ql_quoteLinkID }, index2) => (
                        <Accordion
                          className='m-0 p-0 '
                          bsPrefix={{ backgroundColor: "transparent" }}
                          key={`argumentSplit_${index}_${filteredArgumentsItem[0].qlt_title}_${index2}`}
                        > {/* defaultActiveKey="0" */}

                          <div className="w-100 m-0 p-0 " >
                            <ComponentPourComprendreCard
                              argument={quote}
                              keyValue={quote.idquote}
                              pourOuContre={qlt_title}
                              quoteLinkID={ql_quoteLinkID}
                              uniqueID={this.props.uniqueID.concat(`_argumentSwipe${quote.idquote}`)}

                              commentateurs={this.props.commentateurs} // A supprimer lorsque l'API permettant de récupérer les personne d'accord avec la causalité sera prête

                              mainQuoteTitle={this.props.mainQuoteTitle}

                              listquoteSearch={this.props.listquoteSearch}
                              natureInformationTable={this.props.natureInformationTable}
                              natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                              natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                              natureInformationTable_faits={this.props.natureInformationTable_faits}
                              listThemes={this.props.listThemes}

                              linkTypeList={this.props.linkTypeList}
                              echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}

                              // Fonctions
                              ArgumentsAPIs={this.props.ArgumentsAPIs}
                              ListQuoteAPIs={this.props.ListQuoteAPIs}
                              titreDebat={this.props.titreDebat}
                              miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                              miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}
                              capitalizeFirstLetter={this.props.capitalizeFirstLetter}
                              introDate2={this.props.introDate2}
                              getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                              queryTracker={this.props.queryTracker}

                              visitorLocation={this.props.visitorLocation}
                              showConsoleLog={this.props.showConsoleLog}
                              url_image={this.props.url_image}
                              spot_api={this.props.spot_api}
                              API_key={this.props.API_key}

                              // Pour ComponentCommentateurBox
                              affichageNombreCommentateurs={this.props.affichageNombreCommentateurs}
                              commentateursAPIs={this.props.commentateursAPIs}
                              getPersonPictureName={this.props.getPersonPictureName}
                              listmedia={this.props.listmedia}
                              listmediatype={this.props.listmediatype}
                              listorganisationtype={this.props.listorganisationtype}
                              listperson={this.props.listperson}
                              listreferencetype={this.props.listreferencetype}
                              listsomeonetype={this.props.listsomeonetype}
                              niveauDeLectureValue={this.props.niveauDeLectureValue}
                              quoteTitle={this.props.quoteTitle}
                              ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                              evenementTypeList={this.props.evenementTypeList}
                              dateTypeIntro={this.props.dateTypeIntro}
                              dateTypeIntro2={this.props.dateTypeIntro2}
                              dateTypeIntroAll={this.props.dateTypeIntroAll}

                              regionsList={this.props.regionsList}
                              departmentsList={this.props.departmentsList}

                            />
                          </div>
                        </Accordion>

                      ))
                    }

                  </SwiperSlide>
                ))
                }

              </Swiper>

            </div>
          }


        </div>


      </div>



    )
  }
}


export default ComponentArgumentBox