import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

import { ChevronRightIcon } from '@primer/octicons-react'


class ComponentFooter extends Component {

  state = {

  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }



  render() {


    return (

      <div className="col-12 w-100 m-0 p-0 ">

        {/* FOOTER */}
        <div className="d-flex justify-content-center mt-5 " style={{ backgroundColor: '#000723' }}> {/* 000723 */}
          <div className="d-flex flex-column col-lg-11 mt-2 ">


            <div className="d-flex justify-content-center ">
              <div className="d-flex col-lg-4 p-0 ">

                {false &&
                  <div className="d-flex flex-column ">

                    <div className="footerContentTitle mt-5 text-center">
                      {"rejoignez notre newsletter !".toUpperCase()}
                    </div>

                    <div className="footerContentText mt-4 text-center">
                      Abonnez-vous à notre newsletter pour vous tenir au courant des derniers débats qui ont lieu sur notre plateforme.
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <div className="d-flex col-8 col-lg-8 p-0 " >
                        <div className="row w-100 " style={{ position: 'relative' }}>
                          <FormControl
                            type="text"
                            className="d-flex form-control inputEmail text-left w-100"
                            placeholder="email"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onClick={() => {
                              alert('Merci pour votre intérêt ! Nous ne récupérons pas encore les mails ;) ')
                            }}

                          />
                          <Button
                            variant="light"
                            className="btn btn-dark boutonEnvoyerEmail p-0"
                            onClick={() => {
                              alert('Merci pour votre intérêt ! Nous ne récupérons pas encore les mails ;) ')
                            }}
                            style={{ position: 'absolute', right: 0 }}
                          >
                            <ChevronRightIcon className="" size={24} />
                          </Button>
                        </div>
                      </div>
                    </div>

                  </div>
                }
              </div>
            </div>

            <div id="" className={window.innerWidth > 575.98 ? "d-flex flex-row justify-content-around mt-3 pb-4 " : "d-flex flex-column mt-2 pb-3 "}>

              {true && <div id="" className="d-flex flex-row justify-content-center col-12 col-lg-2 m-0 mt-2 p-0 ">
                <a
                  href="https://www.facebook.com/Spotdebats/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="ml-4"
                    src={this.props.url_image + "Réseaux_Facebook_Icon2.jpg"}
                    alt="Footer"
                    style={{ height: '27px', 'objectFit': 'contain' }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/spotdebats"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="ml-4"
                    src={this.props.url_image + "Réseaux_LinkedIn_Icon2.jpg"}
                    alt="Footer"
                    style={{ height: '27px', 'objectFit': 'contain' }}
                  />
                </a>
                <a
                  href="https://twitter.com/Spotdebats"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="ml-4"
                    src={this.props.url_image + "Réseaux_Twitter_Icon2.jpg"}
                    alt="Footer"
                    style={{ height: '27px', 'objectFit': 'contain' }} />
                </a>
              </div>}


              {true && <div id="" className={"row justify-content-center col-12 col-lg-7 p-0 ".concat(window.innerWidth > 575.98 ? "mt-0 " : "mt-3 ")}>
                <p className="col-12 col-lg-3 footerMentions m-0 mt-2 p-0 align-self-center text-center " href="#" disabled>Conditions d’utilisation</p>
                <p className="col-12 col-lg-3 footerMentions m-0 mt-2 p-0 align-self-center text-center " href="#" >Politique de confidentialité</p>
                <p className="col-12 col-lg-3 footerMentions m-0 mt-2 p-0 align-self-center text-center " href="#" >Plan du site</p>
                <p className="col-12 col-lg-3 footerMentions m-0 mt-2 p-0 align-self-center text-center " href="#" >Mentions légales</p>
              </div>}

              <p
                className={"col-12 col-lg-2 footerMentions m-0 p-0 align-self-center text-center  ".concat(window.innerWidth > 575.98 ? "mt-2 " : "mt-4 ")}
                style={{ color: '#ffffff' }}
              >
                {`© ${new Date().getFullYear()} SPOT Creative Commons`}
              </p>
            </div>

          </div>

        </div>

      </div>

    )
  }
}


export default ComponentFooter
