import React, { Component } from 'react';

// import { ApiClient, ReferenceApi } from 'spot_api_old'

// Elements Bootstrap
import { Button, ListGroup } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import ReactPlayer from 'react-player'
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { FacebookProvider, EmbeddedPost } from 'react-facebook';

import ComponentSources from './ComponentSources'
import ModalWindowCommentateur from './ModalWindowCommentateur'
import ComponentFormulaireReference from './ComponentFormulaireReference'
import ModalWindowModalities from './ModalWindowModalities'
import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'

import 'bootstrap/dist/css/bootstrap.min.css';


class ComponentCommentateur extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.player = React.createRef();
  }

  state = {
    hovered: false,
    listreferences: [],

    showPopover: false,
    keepPopover: false,

    playing: false,
    videoDuration: -1,

    showConsoleLog: true,

    referenceList: [],

    nbSourcePrimaire: 0,
    nbSourceSecondaire: 0,

    tweetLoaded: false,

    showComponentReference: false,

    quoteModalitiesSelectedInit: [],
    quoteModalitiesSelected: [
      {
        id_modalityType: 2,
        mo_title: "quand",
        qm_modalityContent: "",
        qm_quoteOriginID: 0,

        id_quoteModality: 0,
        displayElement: false,
        quote_author_position_ID: 0,
      },
      {
        id_modalityType: 3,
        mo_title: "où",
        qm_modalityContent: "",
        qm_quoteOriginID: 0,

        id_quoteModality: 0,
        displayElement: false,
        quote_author_position_ID: 0,
      },
      {
        id_modalityType: 4,
        mo_title: "combien",
        qm_modalityContent: "",
        qm_quoteOriginID: 0,

        id_quoteModality: 0,
        displayElement: false,
        quote_author_position_ID: 0,
      },
      {
        id_modalityType: 5,
        mo_title: "pour qui",
        qm_modalityContent: "",
        qm_quoteOriginID: 0,

        id_quoteModality: 0,
        displayElement: false,
        quote_author_position_ID: 0,
      },
      {
        id_modalityType: 6,
        mo_title: "comment",
        qm_modalityContent: "",
        qm_quoteOriginID: 0,

        id_quoteModality: 0,
        displayElement: false,
        quote_author_position_ID: 0,
      },
    ],

    simulationMode: false,

    showModalQuoteModalities: false,
  }

  componentDidMount() {
    if (this.props.commentateurData.person.colorful) {
      setTimeout(() => this.props.commentateursAPIs(this.props.quoteID), 1200)
    }

  }

  componentWillUnmount() {
  }

  changeShowComponentReference = () => {

    this.setState({ showComponentReference: !this.state.showComponentReference })

  }

  initComponent = () => {

    this.setState({
      showPopover: !this.state.showPopover,
      playing: false,
      tweetLoaded: false,
    })

    setTimeout(() => this.setState({ tweetLoaded: true }), 3000)

  }


  changeModalShowState = (value) => {
    this.setState({ showModalQuoteModalities: value })
  }


  ReferenceAPIs = (quoteID, idperson, optMessage) => {
    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {
      let defaultClient = this.props.spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = this.props.API_key

      this.props.showConsoleLog && console.log('ReferenceAPIs -> quoteID -> ', parseInt(quoteID))
      this.props.showConsoleLog && console.log('ReferenceAPIs -> idperson : ', idperson)

      let apiInstance = new this.props.spot_api.ReferenceApi();

      this.props.queryTracker('Citations_2.js', 'ReferenceAPIs')

      // Récupérer la liste de toutes les références d'une citation donnée et d'un commentateur donné
      apiInstance.referenceQuoteIDCommentateurIDGet(parseInt(quoteID), parseInt(idperson), (error, dataReference, response) => {
        if (error) {
          console.error(error);
        } else {

          this.props.showConsoleLog && console.log('ReferenceAPIs -> dataReference : ', dataReference)

          if (false && dataReference !== null) {
            dataReference.forEach(element => {
              // this.props.showConsoleLog && console.log('dataReference.forEach -> element : ', element)
              // this.props.showConsoleLog && console.log('dataReference.forEach -> dataReference : ', dataReference)
              let trash = Object.assign(element, { nbSourcePrimaire: this.getNumberPrimarySource(dataReference), nbSourceSecondaire: this.getNumberSecondarySource(dataReference) })
              element.ref_deleteDate = this.prettyDate_heure(element.ref_creationDate)
              // element.nbSourcePrimaire = this.getNumberPrimarySource(dataReference)
              // element.nbSourceSecondaire = this.getNumberSecondarySource(dataReference)
            })
          }

          this.props.showConsoleLog && console.log('ReferenceAPIs 2 : ', optMessage === undefined ? "init : " : optMessage.concat(" : "), dataReference)
          this.props.showConsoleLog && console.log('ReferenceAPIs 2 -> nbSourcePrimaire : ', dataReference.filter(({ re_sourcePrimaire }) => re_sourcePrimaire).length)
          this.props.showConsoleLog && console.log('ReferenceAPIs 2 -> nbSourceSecondaire : ', dataReference.filter(({ re_sourcePrimaire }) => !re_sourcePrimaire).length)

          this.setState({ referenceList: dataReference })
          this.setState({ nbSourcePrimaire: dataReference.filter(({ re_sourcePrimaire }) => re_sourcePrimaire).length })
          this.setState({ nbSourceSecondaire: dataReference.filter(({ re_sourcePrimaire }) => re_sourcePrimaire === null || re_sourcePrimaire !== true).length })

        }
      })
    }
  }


  getNumberPrimarySource(dataReference) {
    let numberReturn = 0
    numberReturn = dataReference.filter(({ re_sourcePrimaire }) => re_sourcePrimaire).length
    return numberReturn
  }

  getNumberSecondarySource(dataReference) {
    let numberReturn = 0
    numberReturn = dataReference.filter(({ re_sourcePrimaire }) => !re_sourcePrimaire).length
    return numberReturn
  }

  prettyDate_heure(date, optionnalFormat) {
    date = new Date(date);
    var dateObj2 = date;
    var month = dateObj2.getUTCMonth() + 1;
    var day = dateObj2.getUTCDate();
    var year = dateObj2.getUTCFullYear();
    var hour = (dateObj2.getUTCHours() + 2) % 24;
    var minute = dateObj2.getUTCMinutes();

    if (optionnalFormat !== undefined && optionnalFormat === "jj/mm/aaaa") {
      date = (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year
    } else {
      date = (day < 10 ? "0".concat(day) : day) + (month < 10 ? "/0".concat(month) : "/".concat(month)) + "/" + year + " " + hour + (minute < 10 ? ":0".concat(minute) : ":".concat(minute))
    }

    return date
  }


  capitalize = (s) => {
    if (typeof s !== 'string') {
      return ''
    } else {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }

  ref = player => {
    this.player = player
  }

  handleDuration = (videoDuration) => {
    this.props.showConsoleLog && console.log('onDuration : ', videoDuration)
    this.setState({ videoDuration })
  }

  handleProgress = state => {
    this.props.showConsoleLog && console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (true) {
      this.setState(state)
    }
  }

  handlePlay = play => {
    this.props.showConsoleLog && console.log('handlePlay')
    this.setState({ playing: true })
  }

  handlePause = pause => {
    this.props.showConsoleLog && console.log('handlePause')
    this.setState({ playing: false })
  }

  keepPopover = (value) => {
    this.setState({ keepPopover: value })
  }

  checkModalitiesSelected = () => {

    let returnQuoteModalitiesSelected = []

    let tempQuoteModalitiesFromCommentateur = this.props.commentateurData.modalitiesList.filter((element) => element.qm_modalityID !== 1)

    // let tempQuoteModalitiesSelected = this.state.quoteModalitiesSelected
    let tempQuoteModalitiesSelected = []
    this.state.quoteModalitiesSelected.forEach((element) => {
      let tempModality = Object.assign({}, element)
      tempQuoteModalitiesSelected.push(tempModality)
    })

    this.props.showConsoleLog && console.log('checkModalitiesSelected -> tempQuoteModalitiesFromCommentateur : ', tempQuoteModalitiesFromCommentateur)
    this.props.showConsoleLog && console.log('checkModalitiesSelected -> tempQuoteModalitiesSelected : ', tempQuoteModalitiesSelected)

    this.props.showConsoleLog && console.log('checkModalitiesSelected -> this.props.quote : ', this.props.quote)

    tempQuoteModalitiesFromCommentateur.forEach((modalityFromCommentateur) => {

      tempQuoteModalitiesSelected.forEach((element) => {
        element.qm_quoteOriginID = this.props.quote.q_originID
        if (element.qm_modalityContent === "") {
          element.displayElement = false
        }
        if (element.id_modalityType === modalityFromCommentateur.id_modalityType) {
          // element.id_modalityType = modalityFromCommentateur.
          // element.mo_title = modalityFromCommentateur.
          element.qm_modalityContent = modalityFromCommentateur.qm_modalityContent
          // element.qm_quoteOriginID = modalityFromCommentateur.qm_quoteOriginID
          element.id_quoteModality = modalityFromCommentateur.id_quoteModality
          element.quote_author_position_ID = modalityFromCommentateur.quote_author_position_ID

          element.displayElement = true
        }
      })

    })

    returnQuoteModalitiesSelected = tempQuoteModalitiesSelected

    this.props.showConsoleLog && console.log('checkModalitiesSelected -> returnQuoteModalitiesSelected : ', returnQuoteModalitiesSelected)

    return returnQuoteModalitiesSelected

  }

  handleChangeModality = (modalityTypeName, modalityTypeToChange, modalityValue) => {

    this.props.showConsoleLog && console.log("handleChangeModality -> modalityTypeName :", modalityTypeName)
    this.props.showConsoleLog && console.log("handleChangeModality -> modalityTypeToChange :", modalityTypeToChange)
    this.props.showConsoleLog && console.log("handleChangeModality -> modalityValue :", modalityValue)

    let tempQuoteModalitiesSelected = this.state.quoteModalitiesSelected

    this.props.showConsoleLog && console.log('handleChangeModality -> tempQuoteModalitiesSelected 1 :', tempQuoteModalitiesSelected)

    tempQuoteModalitiesSelected.forEach((element) => {
      element.qm_quoteOriginID = this.props.quote.q_originID
      if (element.mo_title === modalityTypeName) {

        if (modalityTypeToChange === "init") {
          element.qm_modalityContent = ""
          element.qm_modalityContentIntermediary = ""
          element.echelleDebatSelectionneValue = 0
          element.echellePlaceSelectionneeValue = 0

        }

        if (modalityTypeToChange === "id_quoteModality") {
          this.props.showConsoleLog && console.log("handleChangeModality -> modalityValue :", modalityValue)
          this.props.showConsoleLog && console.log("handleChangeModality ->  COND :", modalityValue === 0)

          element.id_quoteModality = modalityValue
          element.qm_modalityContent = modalityValue === 0 ? "" : this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.quoteModalities, 'id_quoteModality', modalityValue, 'qm_modalityContent')
        }

        if (modalityTypeToChange === "qm_modalityContent") {
          element.qm_modalityContent = modalityValue
          element.qm_modalityContentIntermediary = ""
          element.id_quoteModality = 0
        }

        if (modalityTypeToChange === "displayElement") {
          element.displayElement = modalityValue
        }

      }
    })
    this.setState({ quoteModalitiesSelected: tempQuoteModalitiesSelected })

    this.props.showConsoleLog && console.log('handleChangeModality -> quoteModalitiesSelected 2 :', tempQuoteModalitiesSelected)

    /* 
    quoteModalitiesSelected: [
      {
        id_modalityType: 2,
        mo_title: "quand",
        qm_modalityContent: "",
      },
      {
        id_modalityType: 3,
        mo_title: "où",
        qm_modalityContent: "",
        qm_modalityContentIntermediary: "",
      },
      {
        id_modalityType: 4,
        mo_title: "combien",
        qm_modalityContent: "",
      },
      {
        id_modalityType: 5,
        mo_title: "pour qui",
        qm_modalityContent: "",
      },
    ],

    */

  }


  getObjectFromAttributeValue = (objectList, modalitySelected) => {

    let returnObject = {}

    objectList.forEach((element, index) => {
      if (modalitySelected.mo_title === element.mo_title) {

        if (modalitySelected.id_quoteModality === element.id_quoteModality && modalitySelected.qm_modalityContent === element.qm_modalityContent) {
          this.props.showConsoleLog && console.log('changeModalities -> Modality NO change')
          returnObject = element
        } else {
          this.props.showConsoleLog && console.log('changeModalities -> Modality change')

        }

      }
    })

    return returnObject

  }

  replaceAll(string, search, replace) {
    let stringReturn = ""

    if (string !== undefined && string !== "") {
      stringReturn = string.split(search).join(replace)
    }

    return stringReturn
  }


  linkQuoteToPersonNorefAPI = (idperson, quoteModality, lastIteration, modalitiesToRemove) => { // Lier une quote à une personne (quote_author_position)

    let defaultClient = this.props.spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = this.props.API_key

    let apiInstance = new this.props.spot_api.CommentateurApi()

    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> idperson : ', idperson)
    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> id_quoteModality : ', this.props.quote.id_quoteModality)
    this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> commentateur : ', this.props.commentateurData)

    this.state.simulationMode && console.log('linkQuoteToPersonNorefAPI -> Ajout de la modalité // idperson :', idperson, ' // id_quoteModality :', quoteModality.id_quoteModality, ' // qm_modalityContent :', quoteModality.qm_modalityContent, ' // quote_link_typeID :', this.props.commentateurData.quote_link_typeID, ' // lastIteration :', lastIteration)

    if (this.state.simulationMode && lastIteration) {
      if (modalitiesToRemove.length > 0) {
        this.removeModalities(idperson, modalitiesToRemove)
      } else {
        this.state.simulationMode && console.log(' *** FIN linkQuoteToPersonNorefAPI *** ')
      }
    }

    this.props.queryTracker('ComponentCommentateur.js', 'linkQuoteToPersonNorefAPI')
    {
      !this.state.simulationMode &&
        apiInstance.commentateurNoRefCommentateurIDPost(idperson, quoteModality.id_quoteModality, this.props.commentateurData.quote_link_typeID,
          (error, data, response) => { // Lier une quote à une personne (quote_author_position)
            if (error) {
              console.error(error);
            }
            else {

              this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> Lien quote -> personne OK')
              this.props.showConsoleLog && console.log('linkQuoteToPersonNorefAPI -> response : ', response)

              if (modalitiesToRemove.length > 0) {
                this.removeModalities(idperson, modalitiesToRemove)
              } else {
                if (lastIteration) {
                  this.props.commentateursAPIs(parseInt(this.props.quoteID), idperson)  // Rechargement des commentateurs pour update des ref sur la page Citation
                  this.setState({ afficherOptions: false, showModalQuoteModalities: false }, () => setTimeout(() => this.keepPopover(false), 2200))
                  this.props.quoteModalitesAPIs(parseInt(this.props.quoteID))
                }
              }



            }
          })
    }
  }


  render() {

    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.commentateurData : ', this.props.commentateurData)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.commentateur : ', this.props.commentateur)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> listreferencetype : ', this.props.listreferencetype)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> listmediatype : ', this.props.listmediatype)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> commentateurData : ', this.props.commentateurData)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> commentateur : ', this.props.commentateur)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> listReference : ', this.props.commentateur.referenceList)

    let nbSourcePrimaire = this.props.commentateur.referenceList === undefined ? 0 : this.props.commentateur.referenceList.filter(({ re_sourcePrimaire }) => re_sourcePrimaire).length
    let nbSourceSecondaire = this.props.commentateur.referenceList === undefined ? 0 : this.props.commentateur.referenceList.filter(({ re_sourcePrimaire }) => !re_sourcePrimaire).length

    this.props.showConsoleLog && console.log('ComponentCommentateur -> nbSourcePrimaire : ', nbSourcePrimaire)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> nbSourceSecondaire : ', nbSourceSecondaire)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.commentateur.referenceList : ', this.props.commentateur.referenceList)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> quoteModalitiesSelectedInit : ', this.state.quoteModalitiesSelectedInit)

    let referenceToDisplayInPopup = this.props.commentateur.referenceList[0]

    if (!this.state.tweetLoaded &&
      document.getElementById('tweetDiv') !== null &&
      document.getElementById('tweetDiv').clientHeight > 100) {
      this.setState({ tweetLoaded: true })
    }

    const quoteModalitiesFromCommentateur = this.props.commentateur.modalitiesList.filter((element) => element.id_modalityType !== 1)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> quoteModalitiesFromCommentateur : ', this.props.commentateur.person.pers_name, ' -> ', quoteModalitiesFromCommentateur)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> quoteModalitiesSelected : ', this.state.quoteModalitiesSelected)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> referenceList : ', this.props.commentateur.referenceList)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> referenceToDisplayInPopup : ', referenceToDisplayInPopup)


    const quoteModalities_currentRef_active = this.props.quoteModalities
      .filter((element) =>
        // element.qm_quoteOriginID === this.props.quote.q_originID &&
        // element.qap_extractReferenceOriginID === this.props.reference.re_originID &&
        element.qap_persOriginID === this.props.commentateurData.person.pers_originID &&
        element.qap_statut === 1
      )
      .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.listarguments : ', this.props.listarguments)



    ////////////       Tri des arguments       ////////////

    // Arguments actifs sur cette source
    const arguments_currentRef_active = this.props.listarguments
      .filter((element) =>

        // element.commentateursList.map(item => item.pers_originID).indexOf(this.props.commentateurData.person.pers_originID) > -1 &&
        // element.commentateursList.map(item => item.pers_originID) : liste des commentateurs de cet argument
        // arr.indexOf("bob") > -1 : check if element is in array

        element.commentateursList
          .filter((item) => item.pers_originID === this.props.commentateurData.person.pers_originID && item.qlap_statut === 1).length > 0
      )
      .sort(function (a, b) { return a.id_modalityType - b.id_modalityType })

    const argumentPourContre = this.props.listarguments.filter((element) => (element.qlt_title === "pour" || element.qlt_title === "contre"))

    this.props.showConsoleLog && console.log("ComponentFormulaireNatureEtTitre -> arguments_currentRef_active :", arguments_currentRef_active)



    this.props.showConsoleLog && console.log('ComponentCommentateur -> argumentPourContre : ', argumentPourContre)

    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.quote : ', this.props.quote)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.commentateurData : ', this.props.commentateurData)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> this.props.quoteModalities : ', this.props.quoteModalities)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> quoteModalities_currentRef_active : ', quoteModalities_currentRef_active)
    this.props.showConsoleLog && console.log('ComponentCommentateur -> arguments_currentRef_active : ', arguments_currentRef_active)

    const popoverCommentateur = (
      <Popover
        key={"pop_".concat(this.props.uniqueID)}
        className={"tooltipStyle ".concat(this.props.quoteOrArgumentForm === "argument" ? "tooltipZindex2" : "tooltipZindex1")}
        style={{ minWidth: window.innerWidth > 576 ? '700px' : '300px' }}
      >

        {this.props.showConsoleLog && console.log("Popover -> quoteModalitiesFromCommentateur : ", quoteModalitiesFromCommentateur)}

        <div className="d-flex flex-row m-0 p-0 ">
          <div className="col-lg-6 col-12 m-0 p-0 ">



            {/* Propos directs du commentateur */}
            {this.props.commentateur.referenceList !== undefined &&
              this.props.commentateur.referenceList.length > 0 &&
              referenceToDisplayInPopup.re_sourcePrimaire !== "" &&
              referenceToDisplayInPopup.re_proposDirect !== "" && referenceToDisplayInPopup.re_proposDirect !== null &&
              <Popover.Body >
                <div className="imessage ">
                  <p className="from-them">&ldquo;{"\xa0" + referenceToDisplayInPopup.re_proposDirect + "\xa0"}&rdquo;</p>
                </div>
              </Popover.Body>
            }

            {
              <Popover.Header as="h3" className="tooltipStyle text-center">
                <div className="row justify-content-center m-0 p-0" style={{ position: 'relative' }}>
                  <div className=" m-0 p-0">
                    <ModalWindowCommentateur
                      url_image={this.props.url_image}
                      showConsoleLog={this.props.showConsoleLog}

                      commentateurData={this.props.commentateurData}

                      keepPopover={this.keepPopover}
                      miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                    />
                  </div>
                </div>

              </Popover.Header>
            }
            {this.props.commentateurData.person.organisationList !== undefined && this.props.commentateurData.person.organisationList.length !== 0 &&
              <Popover.Body >
                <ul className="m-0 p-0 mb-1 pl-2 tooltipText">
                  {this.props.commentateurData.person.organisationList
                    .slice(0, 2)
                    .map((element, index) => (
                      <li className="m-0 pt-1" key={`commentateurOrganisation_${index}`}>
                        <span className="tooltipText">{this.capitalizeFirstLetter(element.so_profession) +
                          (element.organisationName !== null && element.organisationName !== "Assemblée nationale" ?
                            " de ".concat(element.organisationName)
                            :
                            "")
                        }
                        </span>
                        {element.organisationName === "Assemblée nationale" &&
                          <img
                            className="ml-3 rounded "
                            src={this.props.url_image + "Logo_de_la_République_française_(1999).png"}
                            alt=""
                            style={{ maxHeight: '15px', maxWidth: '95%' }}
                          />
                        }
                      </li>
                    ))
                  }
                </ul>
              </Popover.Body>
            }

            {
              <div className="w-100 mt-4">

                <div className='d-flex justify-content-center '>
                  <div className='col-lg-6' style={{ backgroundColor: 'white', height: '1px' }}>{" "}</div>
                </div>

                <Popover.Header as="h3" className="tooltipStyle text-center  mt-2">

                  <div className="d-flex flex-row justify-content-center " style={{ position: 'relative' }}>
                    <div className="">
                      {"Position"}
                    </div>
                  </div>

                  <div className="d-flex flex-row justify-content-center mt-3" style={{ position: 'relative' }}>
                    <div className={"col-lg-10 p-1 rounded ".concat(this.props.commentateurData.qlt_title)} >
                      {this.props.commentateurData.qlt_title}
                    </div>
                  </div>

                </Popover.Header>
              </div>
            }


            {
              <div className="w-100 mt-4">

                <div className='d-flex justify-content-center '>
                  <div className='col-lg-6' style={{ backgroundColor: 'white', height: '1px' }}>{" "}</div>
                </div>

                <Popover.Header as="h3" className="tooltipStyle text-center  mt-2">

                  <div className="d-flex flex-row justify-content-center " style={{ position: 'relative' }}>
                    <div className="">
                      {quoteModalities_currentRef_active.length === 1 ? "Nuance" : "Nuances"}
                    </div>

                    {this.props.contributionActivated &&
                      <div style={{ position: 'absolute', right: 0, top: -5 }} >
                        <ComponentFormulaireCommentateur
                          formulaireType='nuances'
                          addPosition={this.props.addPosition}

                          quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                          quote={this.props.quote}
                          quoteTitle={this.props.quoteTitle}

                          quoteID={this.props.quoteID}
                          quoteLinkID={this.props.quoteLinkID} // New
                          listcommentateurs={this.props.listcommentateurs}
                          listsomeonetype={this.props.listsomeonetype}
                          listorganisationtype={this.props.listorganisationtype}
                          listreferencetype={this.props.listreferencetype}
                          listmediatype={this.props.listmediatype}
                          listperson={this.props.listperson}
                          listmedia={this.props.listmedia}

                          echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                          evenementTypeList={this.props.evenementTypeList}
                          dateTypeIntroAll={this.props.dateTypeIntroAll}
                          regionsList={this.props.regionsList}
                          departmentsList={this.props.departmentsList}
                          quoteModalities={this.props.quoteModalities}

                          listcommentateursPourId={this.props.listcommentateursPourId}
                          listcommentateursContreId={this.props.listcommentateursContreId}
                          listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}

                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                          ArgumentsAPIs={this.props.ArgumentsAPIs}

                          getPersonPictureName={this.props.getPersonPictureName}
                          commentateursAPIs={this.props.commentateursAPIs}
                          quoteModalitesAPIs={this.props.quoteModalitesAPIs}
                          miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                          CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}
                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          url_image={this.props.url_image}
                          showConsoleLog={this.props.showConsoleLog}

                          visitorLocation={this.props.visitorLocation}
                          queryTracker={this.props.queryTracker}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          niveauDeLectureValue={this.props.niveauDeLectureValue}

                          listquoteSimilar={this.props.listquoteSimilar}
                          natureInformationTable={this.props.natureInformationTable}
                          miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}

                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}

                          reference={this.props.commentateur.referenceList.length > 0 ? this.props.commentateur.referenceList[0] : undefined}
                          commentateurData={this.props.commentateurData}
                          argumentPourContre={argumentPourContre}

                          keepPopover={this.keepPopover}
                        />
                      </div>
                    }

                  </div>

                </Popover.Header>
                {quoteModalities_currentRef_active.length > 0 &&
                  <Popover.Body >
                    {quoteModalitiesFromCommentateur.length > 0 && false &&
                      <ul className="m-0 p-0 mb-1 pl-2 tooltipText">
                        {quoteModalitiesFromCommentateur.map((element, index) => (
                          <a
                            href={'/citations_2/' + this.props.quote.idquote + "/" + element.id_quoteModality}
                            style={{ color: "white", textDecoration: "underline dotted" }}
                            key={`quoteModalitiesFromCommentateur123_${index}`}
                          >
                            <li className="m-0 pt-1" key={`quoteModalitiesFromCommentateur_${index}`}>
                              {element.mo_title + " : " + element.qm_modalityContent}
                            </li>
                          </a>
                        ))
                        }
                      </ul>
                    }

                    <div className='d-flex flex-wrap' >
                      {
                        quoteModalities_currentRef_active
                          .map((element, index) => (
                            <p
                              key={`commentateur_modalitiesAlreadyAttached_${index}`}
                              style={{ backgroundColor: 'transparent' }}
                              className={'d-inline-flex titre_' + element["mo_title"].replace("où", "ou").replace("pour qui", "pourQui") + ' pt-0 pb-0 mt-2 mb-0 mr-2 robotoFont13px rounded'}
                            >
                              {element["mo_title"] + " : " + element["qm_modalityContent"]}
                            </p>
                          ))
                      }
                    </div>

                  </Popover.Body>
                }

                {this.props.showConsoleLog && console.log("CompCom -> this.props.quoteModalities : ", this.props.quoteModalities)}



              </div>
            }


            {
              <div className="w-100 mt-4">

                <div className='d-flex justify-content-center '>
                  <div className='col-lg-6' style={{ backgroundColor: 'white', height: '1px' }}>{" "}</div>
                </div>

                <Popover.Header as="h3" className="tooltipStyle text-center  mt-2">
                  <div className="d-flex flex-row justify-content-center " style={{ position: 'relative' }}>
                    <div className="">
                      {this.props.listarguments.length === 1 ? "Argument" : "Arguments"}
                    </div>

                    {this.props.contributionActivated &&
                      <div style={{ position: 'absolute', right: 0, top: -5 }} >
                        <ComponentFormulaireCommentateur
                          formulaireType='argument'
                          addPosition={this.props.addPosition}

                          quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                          quote={this.props.quote}
                          quoteTitle={this.props.quoteTitle}

                          quoteID={this.props.quoteID}
                          quoteLinkID={this.props.quoteLinkID} // New
                          listcommentateurs={this.props.listcommentateurs}
                          listsomeonetype={this.props.listsomeonetype}
                          listorganisationtype={this.props.listorganisationtype}
                          listreferencetype={this.props.listreferencetype}
                          listmediatype={this.props.listmediatype}
                          listperson={this.props.listperson}
                          listmedia={this.props.listmedia}

                          echellesGeographiquesInformationTable={this.props.echellesGeographiquesInformationTable}
                          evenementTypeList={this.props.evenementTypeList}
                          dateTypeIntroAll={this.props.dateTypeIntroAll}
                          regionsList={this.props.regionsList}
                          departmentsList={this.props.departmentsList}
                          quoteModalities={this.props.quoteModalities}

                          listcommentateursPourId={this.props.listcommentateursPourId}
                          listcommentateursContreId={this.props.listcommentateursContreId}
                          listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}

                          ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                          ArgumentsAPIs={this.props.ArgumentsAPIs}

                          getPersonPictureName={this.props.getPersonPictureName}
                          commentateursAPIs={this.props.commentateursAPIs}
                          quoteModalitesAPIs={this.props.quoteModalitesAPIs}
                          miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                          CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}
                          getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                          url_image={this.props.url_image}
                          showConsoleLog={this.props.showConsoleLog}

                          visitorLocation={this.props.visitorLocation}
                          queryTracker={this.props.queryTracker}
                          spot_api={this.props.spot_api}
                          API_key={this.props.API_key}

                          niveauDeLectureValue={this.props.niveauDeLectureValue}

                          listquoteSimilar={this.props.listquoteSimilar}
                          natureInformationTable={this.props.natureInformationTable}
                          miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}

                          ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}

                          reference={this.props.commentateur.referenceList.length > 0 ? this.props.commentateur.referenceList[0] : undefined}
                          commentateurData={this.props.commentateurData}
                          argumentPourContre={argumentPourContre}

                          keepPopover={this.keepPopover}
                        />
                      </div>
                    }

                  </div>
                </Popover.Header>
                {arguments_currentRef_active.length > 0 &&
                  <Popover.Body >
                    {arguments_currentRef_active.length > 0 && false &&
                      <ul className="m-0 p-0 mb-1 pl-2 tooltipText">
                        {arguments_currentRef_active.map((element, index) => (
                          <a
                            href={'/citations_2/' + this.props.quote.idquote + "/" + element.id_quoteModality}
                            style={{ color: "white", textDecoration: "underline dotted" }}
                          >
                            <li className="m-0 pt-1">
                              {element.mo_title + " : " + element.qm_modalityContent}
                            </li>
                          </a>
                        ))
                        }
                      </ul>
                    }

                    <ListGroup variant="flush" className='col-lg-12 p-0 m-0 ' >
                      {
                        arguments_currentRef_active
                          .slice(0, 3)
                          .map((element, index) => (
                            <div className='d-flex flex-column m-0 p-0' key={`arguments_currentRef_active_${index}`} >
                              <ListGroup.Item
                                key={`commentateur_argumentsAlreadyAttached_${index}`}
                                style={{ backgroundColor: 'white' }}
                                className={'d-flex w-100 pt-1 pb-1 mt-2 mb-0 robotoFont13px rounded'}
                              >
                                {element["quote"]["intro"].concat(" ... ").concat(element["quote"]["q_title"])}
                              </ListGroup.Item>
                              <div className='d-flex justify-content-end w-100 m-0 p-0 ' style={{ position: 'relative', top: '-2px' }}>
                                <i
                                  className='col-lg-6 m-0 pl-3 pr-3 pb-0 text-center robotoFont11px'
                                  style={{ color: 'grey', backgroundColor: 'white', borderBottomRightRadius: '9px', borderBottomLeftRadius: '9px', position: 'relative', top: '-3px' }}
                                >
                                  {element["quote"]["qt_title"]}
                                </i>
                              </div>
                            </div>
                          ))
                      }
                    </ListGroup>

                  </Popover.Body>
                }

                {this.props.showConsoleLog && console.log("CompCom -> this.props.quoteModalities : ", this.props.quoteModalities)}

              </div>
            }

            {false &&
              <div className="w-100 mt-4">

                <div className='d-flex justify-content-center '>
                  <div className='col-lg-6' style={{ backgroundColor: 'white', height: '1px' }}>{" "}</div>
                </div>

                <Popover.Header as="h3" className="tooltipStyle text-center  mt-2">

                  <div className="d-flex flex-row justify-content-center " style={{ position: 'relative' }}>
                    <div className="">
                      {"Arguments défendus / combattus"}
                    </div>

                  </div>

                </Popover.Header>
              </div>
            }




            {
              this.props.commentateur.referenceList !== undefined &&
              this.props.commentateur.referenceList.length === 0 &&
              <div className="row justify-content-center " style={{ position: 'relative' }}>
                <em className="mt-1">Il n'y a pas encore de source</em>
              </div>
            }
          </div>

          <div className="col-lg-6 col-12 m-0 p-0 ">

            {
              this.props.commentateur.referenceList !== undefined &&
              // referenceToDisplayInPopup.medias[0] !== undefined &&
              <Popover.Header as="h3" className="tooltipStyle text-center mt-2">
                <div className="d-flex flex-row justify-content-center " style={{ position: 'relative' }}>
                  <div className="">Source</div>

                </div>
              </Popover.Header>
            }

            {
              <Popover.Body >

                {false && referenceToDisplayInPopup !== null
                  && referenceToDisplayInPopup !== undefined
                  && referenceToDisplayInPopup !== "" && referenceToDisplayInPopup.ref_dateParution !== "" &&
                  <div className="mt-1 tooltipText" >
                    {"Le\xa0".concat(this.prettyDate_heure(referenceToDisplayInPopup.ref_dateParution, "jj/mm/aaaa"))}
                  </div>
                }

                { // Affichage du nom du média
                  this.props.commentateur.referenceList !== undefined &&
                  this.props.commentateur.referenceList.length > 0 &&
                  referenceToDisplayInPopup.media_typeTitle !== undefined && referenceToDisplayInPopup.media_typeTitle !== null &&

                  <div className="d-flex flex-wrap tooltipText mt-2 mb-0">
                    <strong>{referenceToDisplayInPopup.media_typeTitle + ' :\xa0 '} </strong>
                    <div
                      className=""
                      style={{ color: "white" }} // textDecoration: "underline dotted"
                    >
                      {referenceToDisplayInPopup.media_pers_title}
                    </div>
                  </div>
                }

                {this.props.commentateur.referenceList !== undefined &&
                  this.props.commentateur.referenceList.length > 0 &&
                  <div className={(referenceToDisplayInPopup.ref_link === null
                    || referenceToDisplayInPopup.ref_link === undefined
                    || referenceToDisplayInPopup.ref_link === "") ?
                    "row tooltipText mt-2 m-0 p-0 "
                    :
                    "tooltipText mt-2 mb-0 "
                  } >

                    {this.props.showConsoleLog && console.log('bbb -> referenceToDisplayInPopup.ref_link : ', referenceToDisplayInPopup)}

                    {
                      ((referenceToDisplayInPopup.ref_linkTitle !== null &&
                        referenceToDisplayInPopup.ref_linkTitle.length > 0) ||
                        (referenceToDisplayInPopup.ref_title !== null &&
                          referenceToDisplayInPopup.ref_title.length > 0)) &&
                      <strong className="">
                        {this.capitalizeFirstLetter(referenceToDisplayInPopup.ref_type_title.concat("\xa0:\xa0"))}
                      </strong>
                    }

                    { // Avec une référence -> balise <a>
                      referenceToDisplayInPopup.ref_link !== null
                      && referenceToDisplayInPopup.ref_link !== undefined
                      && referenceToDisplayInPopup.ref_link !== "" &&
                      <a
                        className=""
                        href={referenceToDisplayInPopup.ref_link}
                        style={{ color: "white", textDecoration: "underline dotted" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {referenceToDisplayInPopup.ref_linkTitle !== null && referenceToDisplayInPopup.ref_linkTitle.length > 0 ?
                          referenceToDisplayInPopup.ref_linkTitle.slice(0, 65).concat(referenceToDisplayInPopup.ref_linkTitle.length > 65 ? "..." : "")
                          :
                          (referenceToDisplayInPopup.ref_title !== null && referenceToDisplayInPopup.ref_title.length > 0 ?
                            referenceToDisplayInPopup.ref_title.slice(0, 65).concat(referenceToDisplayInPopup.ref_title.length > 65 ? "..." : "") : "")
                        }
                      </a>
                    }

                    { // Sans référence -> balise <div>
                      (referenceToDisplayInPopup.ref_link === null
                        || referenceToDisplayInPopup.ref_link === undefined
                        || referenceToDisplayInPopup.ref_link === "") &&
                      <div
                        className="" style={{ color: "white" }}
                      >
                        {referenceToDisplayInPopup.ref_linkTitle.length > 70 ?
                          (referenceToDisplayInPopup.ref_linkTitle.slice(0, 70) + "...")
                          :
                          referenceToDisplayInPopup.ref_linkTitle
                        }
                      </div>
                    }

                    {referenceToDisplayInPopup.ref_link !== null
                      && referenceToDisplayInPopup.ref_link !== undefined
                      && referenceToDisplayInPopup.ref_link !== "" &&

                      <div className="mt-3 m-0 p-0 ">

                        {referenceToDisplayInPopup.ref_type_title === "vidéo" &&
                          <div>
                            <ReactPlayer
                              width="100%"
                              height="150px"
                              ref={this.ref}
                              // height='100%'
                              url={referenceToDisplayInPopup.ref_link} /// {`https://www.youtube.com/embed/${id}`}
                              playing={this.state.played < referenceToDisplayInPopup.re_videoEnd ? this.state.playing : false}
                              onDuration={this.handleDuration}
                              onProgress={this.handleProgress}
                              onPlay={this.handlePlay}
                              onPause={this.handlePause}
                              // onReady={() => console.log('onReady')}
                              controls={true}
                              light={false}
                              config={{
                                youtube: {
                                  playerVars: { showinfo: 0, rel: 0, modestbranding: 1, controls: 2 }
                                },
                              }}
                            />


                            <div className="d-flex flex-row justify-content-center">
                              <Button
                                className="mt-2 p-2 tooltipTextBlack"
                                variant="light "
                                onClick={() => {
                                  this.player.seekTo(referenceToDisplayInPopup.re_videoStart) // referenceToDisplayInPopup. 
                                  // this.setState({ playing: true })
                                  this.handlePlay()
                                }}
                              >
                                {referenceToDisplayInPopup.re_videoStart > 0.001 && referenceToDisplayInPopup.re_videoEnd !== 1 ?
                                  "Voir l'extrait" : "Voir la vidéo"}
                              </Button>
                            </div>

                          </div>
                        }
                        {this.props.showConsoleLog && console.log("tweetLoaded", this.state.tweetLoaded)}
                        {this.props.showConsoleLog && console.log("this.props.commentateur :", this.props.commentateur)}
                        {referenceToDisplayInPopup.ref_link.includes('twitter.com') &&
                          <div className="" id="tweetDiv">
                            <TwitterTweetEmbed
                              tweetId={referenceToDisplayInPopup.ref_link.substring(referenceToDisplayInPopup.ref_link.lastIndexOf("/") + 1, referenceToDisplayInPopup.ref_link.indexOf("?") === -1 ? referenceToDisplayInPopup.ref_link.length : referenceToDisplayInPopup.ref_link.indexOf("?"))}
                            />
                            {!this.state.tweetLoaded &&
                              <div className="spinner-border text-light" role="status">
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }

                  </div>
                }

                {this.props.showConsoleLog && console.log("ComponentCommentateur -> referenceList :", this.props.commentateur.referenceList)}
                {this.props.showConsoleLog && console.log("ComponentCommentateur -> commentateur :", this.props.commentateur)}
                {this.props.showConsoleLog && console.log("ComponentCommentateur -> commentateur referenceList :", this.props.commentateur.referenceList)}
                {this.props.showConsoleLog && console.log("ComponentCommentateur -> commentateurData :", this.props.commentateurData)}


                {false &&
                  <div className="mt-3" style={{}}>
                    <ComponentSources // ComponentCommentateur
                      quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                      addPosition={this.props.addPosition}
                      type="modification"
                      quoteID={this.props.quoteID}
                      quoteLinkID={this.props.quoteLinkID}
                      quoteTitle={this.props.quoteTitle}
                      qt_title={this.props.qt_title}

                      getPersonPictureName={this.getPersonPictureName}
                      commentateursAPIs={this.props.commentateursAPIs}

                      url_image={this.props.url_image}
                      showConsoleLog={this.props.showConsoleLog}

                      keepPopover={this.keepPopover}
                      referenceList={this.props.commentateur.referenceList}
                      // ReferenceArgumentAPIs={this.ReferenceArgumentAPIs}
                      // commentateurID={this.props.commentateurData.person.idperson}
                      commentateurData={this.props.commentateurData}
                      miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                      ReferenceAPIs={this.ReferenceAPIs}

                      listsomeonetype={this.props.listsomeonetype}
                      listorganisationtype={this.props.listorganisationtype}
                      listreferencetype={this.props.listreferencetype}
                      listmediatype={this.props.listmediatype}
                      listperson={this.props.listperson}
                      listmedia={this.props.listmedia}

                      ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}
                      CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                      listcommentateursPourId={this.props.listcommentateursPourId}
                      listcommentateursContreId={this.props.listcommentateursContreId}
                      listcommentateursPeutetreId={this.props.listcommentateursPeutetreId}

                      nbSourcePrimaire={nbSourcePrimaire}
                      nbSourceSecondaire={nbSourceSecondaire}

                      visitorLocation={this.props.visitorLocation}
                      queryTracker={this.props.queryTracker}
                      spot_api={this.props.spot_api}
                      API_key={this.props.API_key}
                    />
                  </div>
                }
                <div className="ml-auto mt-3">
                  {false &&
                    <ComponentFormulaireReference
                      key={"_ajou"}
                      quoteOrArgumentForm={this.props.quoteOrArgumentForm}
                      uniqueID={"_ajouterSource"}
                      sourceType={this.props.sourceType}
                      addPosition={this.props.addPosition}
                      quoteID={this.props.quoteID}
                      quoteLinkID={this.props.quoteLinkID}
                      quoteTitle="aa"
                      qt_title={this.props.qt_title}
                      quote={this.props.quote}

                      contributionActivated={this.props.contributionActivated}

                      listsomeonetype={this.props.listsomeonetype}
                      listorganisationtype={this.props.listorganisationtype}
                      listreferencetype={this.props.listreferencetype}
                      listmediatype={this.props.listmediatype}
                      listperson={this.props.listperson}
                      listmedia={this.props.listmedia}
                      commentateurData={this.props.commentateurData}
                      listcommentateurs={this.props.listcommentateurs}

                      referenceList={this.props.commentateur.referenceList}
                      nbSourcePrimaire={nbSourcePrimaire}
                      nbSourceSecondaire={nbSourceSecondaire}

                      ReferenceTypeAPIs={this.props.ReferenceTypeAPIs}

                      getPersonPictureName={this.props.getPersonPictureName}
                      commentateursAPIs={this.props.commentateursAPIs}
                      miseEnFormeIntroAvecCitation={this.props.miseEnFormeIntroAvecCitation}
                      ReferenceAPIs={this.props.ReferenceAPIs}
                      changeLabelValue={this.props.changeLabelValue}
                      CommentateurCausaliteAPIs={this.props.CommentateurCausaliteAPIs}

                      url_image={this.props.url_image}
                      showConsoleLog={this.props.showConsoleLog}
                      showComponentReference={this.state.showComponentReference}
                      changeShowComponentReference={this.changeShowComponentReference}
                      getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}

                      visitorLocation={this.props.visitorLocation}
                      queryTracker={this.props.queryTracker}
                      spot_api={this.props.spot_api}
                      API_key={this.props.API_key}
                    />
                  }

                </div>
              </Popover.Body>
            }
          </div>
        </div>

      </Popover>
    );


    { this.props.showConsoleLog && console.log("ComponentCommentateur -> showComponentReference", this.state.showComponentReference) }
    { this.props.showConsoleLog && console.log("ComponentCommentateur -> quoteModalities", this.props.quoteModalities) }

    return (
      <OverlayTrigger
        trigger="click"
        onToggle={() =>  // onToggle
        {
          this.props.showConsoleLog && console.log("on test 1")
          this.initComponent()
        }}
        onEnter={() =>  // onEnter
        {
          this.props.showConsoleLog && console.log("on test 2")
          // this.initQuoteModalitiesSelected()
        }}
        show={this.state.showPopover || this.state.keepPopover}
        placement={window.innerWidth > 576 ? "auto" : "bottom"}
        overlay={popoverCommentateur}
        rootClose={!this.state.showComponentReference}
        style={{ position: 'relative', zIndex: '110' }}
      >
        <div className="d-flex flex-column justify-content-center col-lg-12 m-0 p-0 " >



          {(this.props.commentateurData.qap_factChecking) &&
            <div style={{ position: "absolute", right: '5px', top: '0px' }} >
              <img
                className="commentateurImage "
                src={this.props.url_image + (this.props.commentateurData.qlt_title === "pour" ? "Logo_factChecking_OK.png" : "Logo_factChecking_NOK.png")}
                // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                title="fact-checking"
                alt=""
                style={{
                  height: '40px',
                  position: 'relative',
                  zIndex: 100,
                }}
              />
            </div>
          }

          {this.props.showConsoleLog && console.log('Citations_2 -> this.props.commentateurData : ', this.props.commentateurData)}


          <div className="d-flex flex-row justify-content-center ">

            <button
              id={`this.props.commentateurData${this.props.commentateurData.person.idperson}`}
              className={this.props.classAddOn + " d-flex flex-row justify-content-center rounded-circle p-0 buttonHideBordersOnClick "}
              onMouseEnter={() => this.setState({ hovered: true })}
              onMouseLeave={() => this.setState({ hovered: false })}
              style={{ position: 'relative', zIndex: '100', width: "80px" }}
            >
              {(this.props.commentateurData.person.pers_picture !== "undefined" && this.props.commentateurData.person.pers_picture !== undefined && this.props.commentateurData.person.pers_picture !== "") ?
                <img
                  className="commentateurImage rounded-circle zoomImage_10pct "
                  src={this.props.commentateurData.person.pers_picture === null ? "" : (this.props.commentateurData.person.pers_picture.slice(0, 4) === 'http' ? this.props.commentateurData.person.pers_picture : (this.props.url_image + this.props.commentateurData.person.pers_picture))}
                  // onMouseEnter={() => {
                  //   this.setState({ hovered: !this.state.hovered })
                  //   this.props.showConsoleLog && console.log("ComponentCommentateur -> uniqueID :", this.props.uniqueID)
                  //   this.props.showConsoleLog && console.log("ComponentCommentateur -> CommentateurCausaliteAPIs :", this.props.CommentateurCausaliteAPIs)
                  // }}
                  // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                  // title={this.state.hovered ? "Cliquer pour en savoir +" : ""}
                  alt=""
                  style={{ filter: (this.state.hovered || this.state.showPopover || (this.props.commentateurData.person.colorful !== undefined && this.props.commentateurData.person.colorful)) ? 'none' : 'grayscale(1)' }}
                /> /* {this.props.url_image + this.props.commentateurData.person.pers_picture} */
                :
                <img
                  className="commentateurImage rounded-circle img-fluid w-100 zoomImage_10pct"
                  src={this.props.url_image + this.props.getPersonPictureName(this.props.commentateurData.person.pers_type, this.props.commentateurData.person.idperson)}
                  // onMouseEnter={() => this.setState({ hovered: !this.state.hovered })}
                  // onMouseLeave={() => this.setState({ hovered: !this.state.hovered })}
                  // title={this.state.hovered ? "Cliquer pour en savoir +" : ""}
                  alt=""
                  style={{ filter: (this.state.hovered || this.state.showPopover || (this.props.commentateurData.person.colorful !== undefined && this.props.commentateurData.person.colorful)) ? 'none' : 'grayscale(1)' }}
                /> /* {this.props.url_image + this.props.commentateurData.person.pers_picture} */
              }
            </button>
          </div>
          {(this.props.index >= 0) &&
            <div
              className={this.props.classAddOn2.concat(" text-center m-0 mt-2 p-0  ", this.props.size !== undefined && this.props.size === "small" ? "pr-2 pl-2 " : "")}
              style={{ height: '40px' }}
            >
              {this.props.commentateurData.person.pers_firstName !== null && this.props.commentateurData.person.pers_firstName !== undefined ?
                (this.props.commentateurData.person.pers_firstName.concat("\xa0", this.props.commentateurData.person.pers_name).length >= 15 ?
                  (
                    <div>
                      <div>{this.props.commentateurData.person.pers_firstName}</div>
                      <div>{this.props.commentateurData.person.pers_name}</div>
                    </div>
                  )
                  :
                  this.props.commentateurData.person.pers_firstName.concat("\xa0", this.props.commentateurData.person.pers_name)
                )
                :
                this.props.commentateurData.person.pers_name
              }
            </div>
          }
        </div>
      </OverlayTrigger>

    );
  }
}


export default ComponentCommentateur