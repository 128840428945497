import React, { Component } from 'react';
import { TrashIcon, CheckCircleFillIcon } from '@primer/octicons-react'

import 'rc-slider/assets/index.css';

import '../full.css';

import $ from "jquery"

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

// Autres components
import ComponentReference from './ComponentReference'
import ComponentFormulaireCommentateur from './ComponentFormulaireCommentateur'


class ModalDeleteReference extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
  }

  state = {

    show: false,
    showConsoleLog: true,

  }

  componentDidMount() {
  }


  componentDidUpdate() {

  }


  capitalizeFirstLetter(string) {
    if (string !== undefined && string !== null && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  render() {


    return (

      <div className="">

        {this.props.contributionActivated &&
          <Button
            variant="light"
            onClick={() => {
              this.props.showConsoleLog && console.log("ModalDeleteReference -> reference :", this.props.reference)
              this.setState({ show: true })
            }}
            className='m-0 p-1 transparentButton'
            title="Supprimer cette source"
          >
            <div className="modifierSource octiconStyle m-0 p-0 pr-2">
              <span>Supprimer cette source</span>
              <TrashIcon size={20} className="ml-3" />
            </div>
          </Button>
        }

        <Modal show={this.state.show} onHide={() => this.setState({ show: false })}>
          <Modal.Header closeButton style={{ backgroundColor: '#f1948a' }}>
            <Modal.Title className="text-center">{this.capitalizeFirstLetter("êtes-vous sûr de vouloir supprimer cette source ?")}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: '#f7c7c3' }}>
            <div className="mt-3 text-justify">Pour garantir l'intégriter des informations organisées par la communauté sur Spot, cette source pourra être rétablie depuis l'historique du débat.</div >
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: '#f7c7c3' }}>
            <Button variant="light" onClick={() => this.setState({ show: false })} >
              Annuler
            </Button>
            <Button variant="danger" onClick={() => {
              this.setState({ show: false })
              this.props.changeLabelValue('both', 0)
              this.props.deleteReference(this.props.reference.idreference)
            }} >
              OK
            </Button>
          </Modal.Footer>
        </Modal >

      </div >

    )
  }

}
export default ModalDeleteReference