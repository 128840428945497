//PAGE D'ACCUEIL

// const API_URL = process.env.API_URL || "http://localhost:4000" // "http://192.168.99.100:4000/"; // 
import React, { Component } from 'react';
import { ChevronRightIcon, ListUnorderedIcon, SearchIcon } from '@primer/octicons-react'
import Select from 'react-select';

import Skeleton from 'react-loading-skeleton';

import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import '/node_modules/swiper/swiper.min.css'
import '/node_modules/swiper/modules/pagination/pagination.min.css'
import '/node_modules/swiper/modules/free-mode/free-mode.min.css'
import '/node_modules/swiper/modules/effect-cards/effect-cards.min.css'

import { FreeMode, Pagination, EffectCards } from "swiper";


import '../full.css';


import ComponentDebate from './ComponentDebate';
import ModalFormulaireQuoteManager from './ModalFormulaireQuoteManager';
import ComponentPagination from './ComponentPagination';
import ComponentLeurPointDeVue from './ComponentLeurPointDeVue';
import ComponentBandeauHautDePage from './ComponentBandeauHautDePage';
import ComponentFooter from './ComponentFooter';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Carousel from 'react-bootstrap/Carousel';

// import { useMatomo } from '@datapunt/matomo-tracker-react'

// import { hotjar } from 'react-hotjar'

// Importation des data JSON
// import Villes from './france_cities';
// import Departements from './france_departments';
// import Regions from './france_regions';



import $ from "jquery"

// const keyword_extractor = require("keyword-extractor");

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


class Home extends Component {

  // le state correspond à des objets qui sont utilisés tout le long du code
  state = {

    debats: [],
    selectedThemesID: [],
    selectedThemesNumber: 0,
    selectedThemesName: [],

    menuContributionShowHide: 'Hidden',
    quote: {
      q_title: "",
      // q_text: "",
      q_statut: 1,
      q_typeID: 0,
      themeID: [],
      sousthemeID: 0,
      q_completude: 0,
      q_placeScaleID: 0,
      q_placePositionID: 0,
      q_quoi: "",
      q_combien: "",
      q_ou: "",
      q_quand: "",
      q_pourQui: "",

      placeScaleID: 0,
      placePositionID: 0,
    },

    longueurTextTitre: 0,
    longueurTextDescription: 0,

    thematiqueSelected: [],
    thematiqueSelectedNumber: 0,

    completudeSelect: 0,

    echelleDebatSelectionneText: "Débats nationaux",
    echelleDebatSelectionneValue: -1,

    echellePlaceSelectionneeText: "",
    echellePlaceSelectionneeValue: 0,

    maxThemeSelected: 4,

    maxArrayLength: 50,

    selectedPage: 1,

    selectedOption: null,

    showConsoleLog: true,

    homePageAffichageMozaique: true,
    nbDebatsParPage: 12,

    visitorLocation: this.props.visitorLocation,

    reactSelectTextInput: "",

    searchBarText: "",

    hoverRechercherUp: false,
    hoverRechercherDown: false,

    barreRechercheBoutonThematiqueActive: false,

    relanconsDebatsCurrentIndex: 0,
    relanconsDebatsIndexAccumulator: 0,

    hoverCarroussel: false,

    helpMessage: false,

    personsHomePage: [],

    initDataRequest: true,

  }

  // correpond au fonction que l'on va éxecuter quand on charge la page
  componentDidMount() {

    // this.props.showConsoleLog && console.log('Villes : ', Villes)
    // this.props.showConsoleLog && console.log('Departements : ', Departements)
    // this.props.showConsoleLog && console.log('Regions : ', Regions)

    this.props.showConsoleLog && console.log("Home_2 in")

    {
      setTimeout(() => (
        this.props.spotAnalytics(this.props.spot_api, 'home page')
      ), 2000)
    }


    // hotjar.initialize('2092984', 6)

  }


  initData = () => {
    this.personsHomePageAPI()
  }


  shuffle(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }


  personsHomePageAPI = () => {

    this.props.showConsoleLog && console.log('personsHomePageAPI in')

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.PersonApi();

      let personsHomePage = []

      const personsID = [
        // Logo couleur
        // 419711,	// 	Nathalie Arthaud
        // 419721,	// 	François Asselineau
        71,	// 	Nicolas Dupont-Aignan
        // 326961,	// 	Anne Hidalgo
        34441,	// 	Yannick Jadot
        // 161,	// 	Jean Lassalle
        3781,	// 	Marine Le Pen
        191,	// 	Jean-Luc Mélenchon
        // 13931,	// 	Arnaud Montebourg */
        // 17111,	// 	Valérie Pécresse 
        // 419731,	// 	Philippe Poutou
        3861,	// 	Fabien Roussel
        // 419741,	// 	Antoine Waechter
        419751,	// 	Éric Zemmour
        // 419761,	// 	Florian Philippot
        // Logo noir & blanc
        34381, // Macron
        // 18301, // Taubira 
      ]

      const shuffled = personsID.sort(() => 0.5 - Math.random());

      this.props.showConsoleLog && console.log(" personsHomePageAPI -> personsID :", personsID)
      this.props.showConsoleLog && console.log(" personsHomePageAPI -> shuffled :", shuffled)

      const personsToShow = shuffled //[17111].concat(shuffled.slice(0, 5))

      this.props.showConsoleLog && console.log(" personsHomePageAPI -> personsToShow :", personsToShow)

      personsToShow.forEach((personID) => {

        apiInstance.personPersonIDGet(personID, (error, data, response) => {
          if (error) {
            console.error(error);
          } else {

            let res = JSON.parse(response.text)
            let resObj = res[0]

            this.props.showConsoleLog && console.log('personsHomePageAPI -> data : ', data)
            this.props.showConsoleLog && console.log('personsHomePageAPI -> response : ', response)
            this.props.showConsoleLog && console.log('personsHomePageAPI -> res : ', res)

            this.props.showConsoleLog && console.log('personsHomePageAPI -> data : ', data)

            personsHomePage.push(resObj)

          }
        });

      })

      this.props.showConsoleLog && console.log("personsHomePageAPI -> personsHomePage", personsHomePage)

      this.setState({ personsHomePage: personsHomePage })

    }
  }

  componentWillMount() {

    {
      setTimeout(() => (
        this.setState({ helpMessage: true })
      ), 25000)
    }

  }


  selectedThemes2 = (idThemeToChange) => {
    let { selectedThemesNumber, selectedThemesID } = this.state
    let listThemes = this.props.listThemes

    listThemes.map((element) => {
      this.props.showConsoleLog && console.log("selectedThemes2 -> selectedThemesNumber : ", selectedThemesNumber)
      if (element.idtheme === idThemeToChange) {
        if (element.selected) { // Them to remove
          const indice = selectedThemesID.findIndex(selectedThemesID => selectedThemesID === idThemeToChange)
          selectedThemesID.splice(indice, 1)

          element.selected = false
          selectedThemesNumber = selectedThemesNumber - 1

        } else { // Them to add
          if (selectedThemesNumber < this.state.maxThemeSelected) {
            element.selected = true
            selectedThemesNumber = selectedThemesNumber + 1
            selectedThemesID.push(idThemeToChange)
          }
        }
      }
    })

    this.setState({ listThemes: listThemes })
    this.setState({ selectedThemesNumber: selectedThemesNumber })

    this.props.showConsoleLog && console.log("selectedThemesID : ", selectedThemesID)

    this.setState({ selectedThemesID: selectedThemesID })
    // this.props.ListQuoteAPIsSearch(selectedThemesID)

  }

  selectedPage = argument => {
    this.setState({ selectedPage: argument })

    var Scroll = require('react-scroll');
    var scroll = Scroll.animateScroll;

    scroll.scrollTo(480);

    // alert(argument)
  }


  menuContributionShowHide = index => {
    var { menuContributionShowHide } = this.state

    if (menuContributionShowHide === 'Showed') {
      this.setState({ menuContributionShowHide: 'Showed' }) // this.setState({ menuContributionShowHide: 'Hidden' }) 
    }
    else {
      this.setState({ menuContributionShowHide: 'Showed' })
    }
  }



  miseEnFormeApostrophe(intro, citation) {

    if (intro !== undefined && citation !== undefined) {
      let fstLetter = citation.charAt(0).toLowerCase()
      if (fstLetter === 'a' || fstLetter === 'e' || fstLetter === 'i' || fstLetter === 'o' || fstLetter === 'u' || fstLetter === 'y' || fstLetter === 'à') {
        return intro.slice(0, intro.length - 1).concat("'", citation)
      } else {
        return intro.slice(0, intro.length).concat(" ", citation)
      }
    }
  }


  handleChangeTitre = (event) => {
    this.setState({ longueurTextTitre: event.target.value.length })
    this.setState({
      quote: {
        ...this.state.quote,
        q_title: event.target.value,
      }
    })
  }

  handleChangeDescription = (event) => {
    this.setState({ longueurTextDescription: event.target.value.length })
    this.setState({
      quote: {
        ...this.state.quote,
        // q_text: event.target.value,
      }
    })
  }


  handleChangeThematiqueSelection = (event) => {
    var options = event.target.options;
    var value = [];
    var incrementOk = 0

    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value[incrementOk] = options[i].value;
        incrementOk = incrementOk + 1
      }
    }
    //this.props.showConsoleLog && console.log('value : ' + value)

    this.setState({ thematiqueSelected: value })
    this.setState({ thematiqueSelectedNumber: value.length })
    this.setState({
      quote: {
        ...this.state.quote,
        themeID: value,
      }
    })
    this.SousThemesAPIs(value);
  }

  handleChangeCompletudeSelect = (event) => {
    this.setState({ completudeSelect: event.target.value });
    this.setState({
      quote: {
        ...this.state.quote,
        q_completude: event.target.value,
      }
    })
  }

  onChange(e, attribut) {
    if (attribut === 'nature') {
      this.setState({
        quote: {
          ...this.state.quote,
          q_typeID: e.target.value,
        }
      })
    }
    if (attribut === 'titre') {
      this.handleChangeTitre(e);
    }
    if (attribut === 'themes') {
      this.handleChangeThematiqueSelection(e);
    }
    if (attribut === 'description') {
      this.handleChangeDescription(e);
    }
    if (attribut === 'completude') {
      this.handleChangeCompletudeSelect(e);
    }
    if (attribut === 'soustheme') {
      this.setState({
        quote: {
          ...this.state.quote,
          sousthemeID: e.target.value,
        }
      })
    }

    alert('index : ' + e + ' - attribut : ' + attribut)
  }

  selectDebatEchelle1 = (selectedOption) => {

    this.props.showConsoleLog && console.log('selectDebatEchelle1 / echelleDebatSelectionneText -> value : ', selectedOption.value + 1, ' // label : ', selectedOption.label)

    this.setState({ echelleDebatSelectionneValue: selectedOption.value + 1 })
    this.setState({ echelleDebatSelectionneText: selectedOption.label })

    this.setState({ echellePlaceSelectionneeValue: 0 })

  }

  selectDebatEchelle2 = (selectedOption) => {
    // ['Débats nationaux', 'Débats locaux', 'Débats départementaux', 'Débats régionaux', 'Débats européens']

    this.props.showConsoleLog && console.log('selectDebatEchelle2 -> value : ', selectedOption.value, ' // label : ', selectedOption.label)

    this.setState({ echellePlaceSelectionneeValue: selectedOption.value })
    this.setState({ echellePlaceSelectionneeText: selectedOption.label })

  }


  transformArrayToSelectObject = (array, arrayIndexes) => {
    let selectObject = []

    if (arrayIndexes === undefined) {
      array.forEach((element, index) => {
        selectObject.push({
          value: index,
          label: element,
        })
      })
    }
    else {
      array.forEach((element, index) => {
        if (arrayIndexes.includes(index)) {
          selectObject.push({
            value: index,
            label: `${element}`,
            isDisabled: false
          })
        }
      })
    }


    return selectObject
  }



  transformVilleArrayToSelectObject = (array, arrayIndexes) => {
    let selectObject = []

    if (arrayIndexes === undefined) {
      array.forEach((element, index) => {
        selectObject.push({ value: element.id, label: `${element.name}`, codePostal: element.zip_code })
      })
    }
    else {
      array.forEach((element, index) => {
        if (arrayIndexes.includes(index)) {
          selectObject.push({ value: element.id, label: `${element.name}`, codePostal: element.zip_code })
        }
      })
    }

    return selectObject
  }


  transformDepartementArrayToSelectObject = (array, arrayIndexes) => {
    let selectObject = []

    if (arrayIndexes === undefined) {
      array.forEach((element, index) => {
        selectObject.push({ value: element.id, label: `${element.name}`, codePostal: element.code })
      })
    }
    else {
      array.forEach((element, index) => {
        if (arrayIndexes.includes(index)) {
          selectObject.push({ value: element.id, label: `${element.name}`, codePostal: element.code })
        }
      })
    }


    return selectObject
  }

  handleInputChange = (inputValue) => {
    this.props.showConsoleLog && console.log('handleInputChange villes ', inputValue)

    this.setState({ reactSelectTextInput: inputValue })
  }

  getAttributeValueInArrayOfObjectFromOtherAttribute = (arrayOfObjects, attributeName, attributeValue, attributeSearch) => {
    var searchValueResult = ''

    // this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> arrayOfObjects : ', arrayOfObjects)

    // this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeName : ', attributeName)
    // this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeValue : ', attributeValue)
    // this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeSearch : ', attributeSearch)

    arrayOfObjects.forEach((element, index) => {
      this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> element : ', element)
      if ($(element).attr(attributeName) === attributeValue) {
        searchValueResult = $(element).attr(attributeSearch)
      }
    })

    // this.props.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> searchValueResult : ', searchValueResult)

    return searchValueResult

  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }

  getThemeBackgroundColor(index) {
    let colorReturn = "#fbfbfb"

    if (index === 0) {
      colorReturn = "#F57842"
    }
    if (index === 1) {
      colorReturn = "#FCB81A"
    }
    if (index === 2) {
      colorReturn = "#B9BD4A"
    }
    if (index === 3) {
      colorReturn = "#69C7C9"
    }
    if (index === 4) {
      colorReturn = "#1B51A4"
    }

    return colorReturn
  }


  getThemeIDArrayFromThemeObjectArray(themesArray) {
    let returnArray = []

    // console.log("getThemeIDArrayFromThemeObjectArray -> : ", themesArray)

    themesArray.forEach((element, index) => {
      if (element.t_photo !== null) {
        returnArray = returnArray.concat(element.idtheme)
      }
    })

    return returnArray

  }

  checkIfVect1IncludesValuesFromVect2 = (Vect1, Vect2) => {
    var boolean = false

    if (Number.isInteger(Vect1)) {
      Vect1 = [Vect1]
    }

    if (Number.isInteger(Vect2)) {
      Vect2 = [Vect2]
    }

    this.props.showConsoleLog && console.log('checkIfVect1IncludesValuesFromVect2 -> Vect1 : ', Vect1)
    this.props.showConsoleLog && console.log('checkIfVect1IncludesValuesFromVect2 -> Vect2 : ', Vect2)

    if ((Array.isArray(Vect1) && Array.isArray(Vect2))) {
      Vect2.forEach((element, index) => {
        if (Vect1.includes(element)) {
          boolean = true
        }
      })
    }

    this.props.showConsoleLog && console.log('checkIfVect1IncludesValuesFromVect2 -> return : ', boolean)

    return boolean

  }



  render() {


    const listquoteMainPage = this.props.listquoteMainPage !== undefined ? this.props.listquoteMainPage : []
    const listquoteSearch = this.props.listquoteSearch !== undefined ? this.props.listquoteSearch : []

    this.props.showConsoleLog && console.log('Home_2.js -> listquoteMainPage : ', listquoteMainPage)

    const listThemes = this.props.listThemes;
    this.props.showConsoleLog && console.log('Home_2.js -> listThemes & listThemes : ', listThemes)
    this.props.showConsoleLog && console.log('Home_2.js -> searchBarChange : ', this.state.searchBarText)

    const listquoteLoaded = this.props.listquoteLoaded;
    this.props.showConsoleLog && console.log('Home_2.js -> listquoteLoaded : ', listquoteLoaded)

    const relanconsDebatsCurrentIndexes = [7, 8]

    const grandesThematiques = [{ index: 1, label: 'culture' }, { index: 3, label: 'économie' }, { index: 8, label: window.innerWidth > 575.98 ? 'environnement' : 'environ- nement' }, { index: 10, label: 'santé' }, { index: 6, label: 'société' }]
    const rechercheEchelles = [{ index: 1, label: 'près de chez moi' }, { index: 4, label: 'national' }, { index: 5, label: 'européen' }, { index: 6, label: 'mondial' }]

    const numeros = [1, 2, 3, 4, 5, 7, 8, 9, 10]

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("Home -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    /*
    // const sentence ="le développement de la 5G augmentera la consommation d'énergie de la France"
    const sentence = "mettre le sujet du contrôle des frontières à l'agenda de la présidence francaise de l'UE avant juin 2022" // readSync('example.txt')

    //  Extract the keywords
    const extraction_result =
      keyword_extractor.extract(sentence, {
        language: "french",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: false,
        return_chained_words: false,
        return_max_ngrams: false

      });


    this.props.showConsoleLog && console.log('Home_2.js -> extraction_result : ', extraction_result)



    retext()
      .use(retextPos) // Make sure to use `retext-pos` before `retext-keywords`.
      .use(retextKeywords)
      .process(sentence)
      .then((file) => {
        console.log()
        console.log('Keywords:')
        file.data.keywords.forEach((keyword) => {
          console.log(keyword)
        })

        console.log()
        console.log('Key-phrases:')
        file.data.keyphrases.forEach((phrase) => {
          console.log(phrase)
        })
      })

    // unified().use(retextKeywords)

    */

    const leurPointDevue = [
      {
        id: 1,
        debat: "Il est nécessaire de développer la 5G",
        person: "Jean Rémy 1 (en construction)",
        parole: "Je ne suis pas très d'accord avec cela",
      },
      {
        id: 2,
        debat: "Il est nécessaire de développer la 6G",
        person: "Aline Rémy 2 (en construction)",
        parole: "Je ne suis pas très d'accord avec cela",
      },
      {
        id: 3,
        debat: "Il est nécessaire de développer la 7G",
        person: "Jean Rémy 3 (en construction)",
        parole: "Je ne suis pas très d'accord avec cela",
      },
      {
        id: 4,
        debat: "Il est nécessaire de développer la 8G",
        person: "Aline Rémy 4 (en construction)",
        parole: "Je ne suis pas très d'accord avec cela",
      },
    ]


    const customStyles = {
      menu: (provided, state) => ({
        ...provided,

        color: 'black',
        padding: 10,
        // width: 320,
      }),

      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        fontFamily: 'Roboto',
      }),

      control: (provided, state) => ({
        ...provided,

        border: 'solid white',
        borderColor: 'white',
        boxShadow: 'none',
        height: 50,
        // width: '100%',
        borderRadius: 50,


        ':hover': {
          borderColor: '#f1948a',
          color: '#f1948a'
        }
      }),

      container: (provided, state) => ({
        ...provided,

        fontFamily: 'Roboto',
        fontSize: 14,
        // width: 210,
        height: 48,
        borderRadius: 50,
        outline: 'none'
      }),

      singleValue: (provided, state) => ({
        ...provided,

        color: 'black',
        textAlign: 'center',
        width: '90%',

        ':hover': {
          color: '#f1948a'
        }

      }),

    }


    const stylesSw1 = {
      root: {
        paddingLeft: '0',
        paddingRight: '40px',
        maxWidth: window.innerWidth * 0.9,
        // border: 'solid',
      },
      slideContainer: {
        padding: '0 10px',
        // border: 'solid',
      },
      slide: {
        padding: 15,
        minHeight: 50,
        color: '#000',
        // border: 'solid',
      },
      slide1: {
        borderRadius: '5px',
      },
    }


    return (

      <div className="d-flex flex-column " style={{ width: "100%" }}>

        {listquoteLoaded &&
          <ComponentBandeauHautDePage
            url_image={this.props.url_image}
            pageName={this.props.pageRechercheShow ? "search" : "home"}
            pageRechercheDisplay={this.props.pageRechercheDisplay}
          />
        }

        <div className={"container ".concat(window.innerWidth > 575.98 ? "" : "m-0 p-0 ")} style={{ width: "100%" }}> {/* overflowX: 'hidden' */}

          <div className="d-flex flex-column w-100 m-0 p-0 " style={{ 'marginTop': '0px' }}> {/* style={{ 'marginTop': '30px' }} */}

            {/* Header */}
            <div className="d-flex ">
              {!listquoteLoaded &&
                <div className="d-flex flex-column w-100 ">

                  {this.state.helpMessage &&
                    <div className="mt-2 text-center ">
                      Vous rencontrez un problème de chargement ? Essayez de recharger la page ou contactez-nous à l'adresse suivante : contact@spotdebats.org
                    </div>
                  }
                  <div
                    className="d-flex flex-column justify-content-center mt-5 w-100 animate-flicker "
                    style={{ height: "700px" }}
                  >
                    <img
                      className="ml-auto mr-auto " // img-fluid
                      src={this.props.url_image + "Spot_bulle_fond_blanc.png"}
                      alt=""
                      style={{ height: '50px', width: '50px' }}
                    />
                  </div>
                </div>
              }
            </div>


            {listquoteLoaded && !this.props.pageRechercheShow &&
              <div className={"d-flex col-12 col-lg-12 m-0 p-0 justify-content-center  "} style={{ height: '366px', position: 'relative' }}>

                <div
                  className="d-flex align-items-center col-12 col-lg-6 m-0 p-5  "
                  style={{ position: window.innerWidth > 575.98 ? "relative" : "absolute", zIndex: 40 }}
                >
                  <div className="d-flex flex-column " >
                    <div className="d-flex bandeauAccueilBox1Format1">Transformer l'information en savoir</div>
                    <div className="d-flex mt-4 p-0  ">
                      <div
                        className="col-lg-10 m-0 p-0 text-justify bandeauAccueilBox1Format2"
                        style={{ fontSize: window.innerWidth > 575.98 ? "14px" : "12px" }}
                      >
                        SPOT permet pour chaque débat de société de rassembler,
                        organiser et synthétiser de manière collaborative l'éventail des informations déjà publiées
                        dans les médias et sur les réseaux sociaux. SPOT est neutre et non lucratif !
                      </div>
                    </div>
                    <div className="d-flex mt-4 col-lg-6 ">

                      <ModalFormulaireQuoteManager
                        formulaireType='new quote'
                        listquoteSimilar={this.props.listquoteSimilar}

                        departmentsList={this.props.departmentsList}

                        getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                        miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}

                        natureInformationTable={this.props.natureInformationTable}
                        natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                        natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                        natureInformationTable_faits={this.props.natureInformationTable_faits}

                        ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                        addQuote={this.props.addQuote}

                        url_image={this.props.url_image}
                        showConsoleLog={this.props.showConsoleLog}
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}



                      />

                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-6 m-0 p-0  " style={{ position: 'relative', zIndex: 30 }}>

                  <img
                    className="w-100 " // img-fluid
                    src={this.props.url_image + (window.innerWidth > 575.98 ? "Home_imagebandeauHautDePage.jpg" : "Home_imagebandeauHautDePage_responsive.jpg")}
                    alt=""
                    style={{ height: window.innerWidth > 575.98 ? '400px' : '', width: '100%', objectFit: 'cover', filter: window.innerWidth > 575.98 ? '' : 'grayscale(1) opacity(25%)' }}

                  />

                  {window.innerWidth < 575.98 &&
                    <div className=" debatPhotoFiltreBlanc m-0 p-0 rounded " style={{ height: '100%', width: '100%', position: 'absolute', zIndex: 35, top: 0 }}></div>
                  }

                </div>

              </div>
            }



            {/* Bouton pour sélection mode 'mozaïque' ou mode 'liste' */}
            {false && listquoteLoaded &&
              <div className="d-flex justify-content-end " style={{}} >
                <button
                  className="rectangleFiltrer "
                  onClick={() => this.setState({ homePageAffichageMozaique: !this.state.homePageAffichageMozaique })}
                  style={{
                    position: 'relative',
                    right: '-5%',
                    top: '38px',
                    backgroundColor: this.state.homePageAffichageMozaique ? "#fbfcfc" : "#d4e6f1"
                  }}
                >
                  <ListUnorderedIcon size={24} />
                </button>
              </div>
            }

            {this.props.showConsoleLog && console.log('Home_2 -> listquoteMainPage', listquoteMainPage)}


            {/* Présentation des débats. Version 2021. */}
            {listquoteLoaded && !this.props.pageRechercheShow && listquoteMainPage.length > 0 &&
              <div className="row ml-0 mr-0 mb-0 p-0 justify-content-center relanconsLeDebatBox " style={{ marginTop: '80px' }} >

                <div className="col-11 col-lg-6 m-0 p-2 ">
                  <div className={window.innerWidth > 575.98 ? 'pr-3' : ''}>

                    <div
                      className="pageAccueilTitreParties"
                      style={{ fontSize: window.innerWidth > 575.98 ? '22px' : '18px' }}
                    >
                      Les débats du moment
                    </div>

                    <div className="mt-3 " >
                      <Carousel
                        controls={false}
                      > {/* activeIndex={index} onSelect={handleSelect} */}

                        {listquoteMainPage
                          .filter((element) => element.q_homePageDebatMoment)
                          .map((element, index) => (
                            <Carousel.Item
                              key={`carrousselDebatsMoment_${index}`}
                              onMouseEnter={() => this.setState({ hoverCarroussel: true })}
                              onMouseLeave={() => this.setState({ hoverCarroussel: false })}
                            >
                              <div
                                className={""}
                                style={{ position: 'relative', }}
                              >
                                <a
                                  href={'/citations_2/' + element.idquote}
                                  className=""
                                >
                                  <img
                                    className="rounded w-100 border" // img-fluid
                                    src={this.props.url_image + (element.q_photo === "undefined" ? "Photo0.jpg" : element.q_photo)}
                                    alt=""
                                    style={{ height: window.innerWidth > 575.98 ? '420px' : '220px', filter: this.state.hoverCarroussel ? 'none' : 'grayscale(1)' }}

                                  />

                                  <div
                                    className="w-100 debatPhotoFiltre "
                                    style={{
                                      position: 'absolute',
                                      height: window.innerWidth > 575.98 ? '420px' : '220px',
                                      top: 0,
                                    }}
                                  >
                                  </div>
                                </a>


                              </div>

                              <div
                                className="row w-100 carouselBottom m-0 p-0 "
                                style={{ minHeight: window.innerWidth > 575.98 ? '177px' : '50px' }}
                              >
                                <div
                                  className="w-100 m-0 p-3 pb-4 carouselText align-self-center text-center"
                                  style={{ fontSize: window.innerWidth > 575.98 ? '18px' : '13px' }}
                                >
                                  {this.props.miseEnFormeIntroAvecCitation(this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction'),
                                    element.q_title)}
                                </div>
                              </div>

                            </Carousel.Item>
                          ))
                        }

                      </Carousel>
                    </div>


                  </div>
                </div>

                <div className={"col-11 col-lg-6 m-0 p-0  ".concat(window.innerWidth > 575.98 ? '' : 'mt-5')} >
                  <div className={"w-100 m-0 p-2  ".concat(window.innerWidth > 575.98 ? 'pl-4 ' : '')} >

                    <div
                      className="pageAccueilTitreParties "
                      style={{ fontSize: window.innerWidth > 575.98 ? '22px' : '18px' }}
                    >
                      Les débats à long terme
                    </div>

                    {listquoteLoaded && window.innerWidth > 575.98 &&
                      <div className={this.state.homePageAffichageMozaique ? "d-flex flex-wrap w-100 m-0 mt-3 p-0 " : "d-flex flex-column mt-3 "}>
                        {false && [...Array(Math.min(listquoteMainPage.length, 4)).keys()].map((element, index) => (
                          <div key={`debate_${listquoteMainPage[element].idquote}`} className={this.state.homePageAffichageMozaique ? "d-flex align-items-stretch col-lg-6 m-0 p-0 " : "d-flex flex-column"}>
                            <ComponentDebate
                              quote={listquoteMainPage[element]}
                              titreIntro={
                                this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', listquoteMainPage[element].q_typeID, 'introduction')
                              }

                              url_image={this.props.url_image}

                              showConsoleLog={this.props.showConsoleLog}
                              homePageAffichageMozaique={this.state.homePageAffichageMozaique}
                              format="Small"
                            />
                          </div>
                        ))}

                        {listquoteMainPage
                          .filter((element) => element.q_homePageDebatLongTerme)
                          .map((element, index) => (
                            <div key={`debate_${element.idquote}`} className={this.state.homePageAffichageMozaique ? "d-flex align-items-stretch col-lg-6 m-0 p-0 " : "d-flex flex-column"}>
                              {this.props.showConsoleLog && console.log("listquoteMainPage -> element :", element)}
                              <ComponentDebate
                                quote={element}
                                titreIntro={
                                  this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction')
                                }

                                url_image={this.props.url_image}

                                showConsoleLog={this.props.showConsoleLog}
                                homePageAffichageMozaique={this.state.homePageAffichageMozaique}
                                format="Small"
                              />
                            </div>
                          ))}

                      </div>}

                    {window.innerWidth < 575.98 &&
                      <div className={this.state.homePageAffichageMozaique ? "d-flex flex-wrap w-100 m-0 mt-3 p-0 " : "d-flex flex-column mt-3 "}>

                        <SwipeableViews
                          className=" "
                          style={stylesSw1.root}
                          slideStyle={stylesSw1.slideContainer}
                        // animateHeight
                        >

                          {listquoteMainPage
                            .filter((element) => (element.q_title.includes("autoriser le déploiement de la 5G") ||
                              element.q_title.includes("construire de nouveaux réacteurs nucléaires")))
                            .map((element, index) => (

                              <div
                                key={`debate_${element.idquote}`}
                                className={this.state.homePageAffichageMozaique ? "d-flex align-items-stretch col-lg-6 m-0 p-0 h-100 " : "d-flex flex-column"}
                                style={Object.assign({}, stylesSw1.slide, stylesSw1.slide1)}
                              >

                                <ComponentDebate
                                  quote={element}
                                  titreIntro={
                                    this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction')
                                  }

                                  url_image={this.props.url_image}

                                  showConsoleLog={this.props.showConsoleLog}
                                  homePageAffichageMozaique={this.state.homePageAffichageMozaique}
                                  format="Small"
                                />
                              </div>

                            ))}

                        </SwipeableViews>

                      </div>}

                  </div>
                </div>

              </div>
            }

            <div className="row mt-5 m-0 p-0 justify-content-center " >

              {/* Présentation des débats. Version 2021. */}
              {listquoteLoaded && !this.props.pageRechercheShow && this.state.personsHomePage.length > 0 &&
                <div className="col-11 col-lg-12 w-100 m-0 p-2  " >

                  <div
                    className="pageAccueilTitreParties w-100 "
                    style={{ fontSize: window.innerWidth > 575.98 ? '22px' : '18px' }}
                  >
                    Leur point de vue
                  </div>

                  {window.innerWidth > 575.98 &&
                    <div className="row mt-4">
                      {this.state.personsHomePage.length > 0 &&
                        this.state.personsHomePage
                          .slice(0, 3)
                          .map((element, index) => (
                            <div key={`leurPointDevue_${index}`} className="col-lg-4 ">
                              <ComponentLeurPointDeVue
                                // id={element.id}
                                // debat={element.debat}
                                personData={element}
                                // parole={element.parole}
                                miseEnFormeApostrophe={this.miseEnFormeApostrophe}
                                showConsoleLog={this.props.showConsoleLog}
                                url_image={this.props.url_image}
                              />
                            </div>
                          ))
                      }
                    </div>
                  }

                  {window.innerWidth < 575.98 &&
                    <div className="d-flex flex-column mt-4 ">

                      <div className="">

                        <Swiper
                          slidesPerView={2}
                          spaceBetween={20}
                          freeMode={true}
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          className="mySwiper"
                        >

                          {
                            this.state.personsHomePage
                              .map((element, index) => (
                                <SwiperSlide key={`leurPointDevue_${index}`} className="">
                                  <ComponentLeurPointDeVue
                                    // id={element.id}
                                    // debat={element.debat}
                                    personData={element}
                                    // parole={element.parole}
                                    miseEnFormeApostrophe={this.miseEnFormeApostrophe}
                                    showConsoleLog={this.props.showConsoleLog}
                                    url_image={this.props.url_image}
                                  />
                                </SwiperSlide>
                              ))
                          }

                        </Swiper>

                      </div>


                      {false &&
                        <div className="col-12 ">

                          <Swiper
                            effect={"cards"}
                            grabCursor={true}
                            modules={[EffectCards]}
                            className="mySwiper"
                          >

                            {this.state.personsHomePage.length > 0 &&
                              this.state.personsHomePage
                                .map((element, index) => (
                                  <SwiperSlide key={`leurPointDevue_${index}`} className="">
                                    <ComponentLeurPointDeVue
                                      // id={element.id}
                                      // debat={element.debat}
                                      personData={element}
                                      // parole={element.parole}
                                      miseEnFormeApostrophe={this.miseEnFormeApostrophe}
                                      showConsoleLog={this.props.showConsoleLog}
                                      url_image={this.props.url_image}
                                    />
                                  </SwiperSlide>
                                ))
                            }

                          </Swiper>

                        </div>
                      }

                    </div>
                  }

                </div>
              }


              {/* Thématiques */}
              {listquoteLoaded && !this.props.pageRechercheShow &&
                <div className="col-11 col-lg-12 w-100 mt-4 m-0 p-0  " >

                  <div
                    className="pageAccueilTitreParties p-2 w-100 "
                    style={{ fontSize: window.innerWidth > 575.98 ? '22px' : '18px' }}
                  >
                    Thématiques
                  </div>

                  <div className="d-flex flex-wrap justify-content-center mt-4 m-0 p-0 ">
                    {this.props.showConsoleLog && console.log("listThemes : ", this.state.listThemes)}
                    {grandesThematiques
                      .slice(0, 5)
                      .map((element, index) => (

                        <div
                          key={`catégories_${index}`}
                          className="d-flex justify-content-center m-0 p-2 "
                          style={{ width: window.innerWidth > 576 ? '20%' : '32%', height: window.innerWidth > 576 ? '200px' : '110px' }}
                        >

                          <div className="d-flex align-items-center col-12 col-lg-10 m-0 p-0 border rounded" style={{ backgroundColor: this.getThemeBackgroundColor(index), opacity: 0.85 }}>
                            <div
                              className="thematiquesListFont w-100 col-12 col-lg-12 m-0 p-0 text-center "
                              style={{ fontSize: window.innerWidth > 576 ? '17px' : '14px' }}
                            >
                              {this.capitalizeFirstLetter(element.label)}
                            </div>
                          </div>

                        </div>

                      ))
                    }
                  </div>

                </div>
              }


              {/* Relançons le débat */}
              {listquoteLoaded && !this.props.pageRechercheShow && listquoteMainPage.length > Math.max(relanconsDebatsCurrentIndexes) &&

                <div className="col-12 col-lg-12 relanconsLeDebatBox w-100 mt-5 m-0 p-2  " >

                  <div
                    className={"m-0 p-0 pageAccueilTitreParties w-100 ".concat(window.innerWidth > 575.98 ? "" : "pl-3 ")}
                    style={{ fontSize: window.innerWidth > 575.98 ? '22px' : '18px' }}
                  >
                    Relançons le débat ! (en construction)
                  </div>

                  <div className="col-12 col-lg-12 p-2 mt-3 pr-3 justify-content-center">

                    <Carousel
                      controls={false}
                    >
                      {/* onSlide={() => (
                        this.props.showConsoleLog && console.log("Carousel change : ", relanconsDebatsCurrentIndexes[(this.state.relanconsDebatsCurrentIndex + 1) % relanconsDebatsCurrentIndexes.length]),
                        this.setState({
                          relanconsDebatsIndexAccumulator: this.state.relanconsDebatsIndexAccumulator + 1,
                          relanconsDebatsCurrentIndex: relanconsDebatsCurrentIndexes[(this.state.relanconsDebatsIndexAccumulator + 1) % relanconsDebatsCurrentIndexes.length]
                        })
                      )}*/}
                      {/* activeIndex={index} onSelect={handleSelect} */}

                      {relanconsDebatsCurrentIndexes.map((element, index) => (
                        <Carousel.Item
                          key={`carrousselDebatsMoment_${index}`}
                          onMouseEnter={() => this.setState({ hoverCarroussel: true })}
                          onMouseLeave={() => this.setState({ hoverCarroussel: false })}
                        >
                          <a
                            className={"row w-100 m-0 p-0 "}
                            href={'/citations_2/' + listquoteMainPage[element].idquote}
                            style={{ position: 'relative', }}
                          >

                            <div

                              className="col-12 col-lg-6 m-0 p-0 "
                            >
                              <img
                                className="rounded w-100 border" // img-fluid
                                src={this.props.url_image + (listquoteMainPage[element].q_photo === "undefined" ? "Photo0.jpg" : listquoteMainPage[element].q_photo)}
                                alt=""
                                style={{ height: window.innerWidth > 575.98 ? '420px' : '220px', filter: this.state.hoverCarroussel ? 'none' : 'grayscale(1)' }}

                              />

                            </div>


                            <div className={"d-flex align-items-center col-12 col-lg-6 m-0 p-2  ".concat(window.innerWidth > 575.98 ? 'pl-5 ' : 'mt-3 pl-3 pr-3 ')} >

                              <div className="d-flex flex-column ">

                                <div className="d-inline-flex flex-column " style={{ width: '190px' }}>
                                  <div
                                    className="relanconsNature "
                                    style={{ fontSize: window.innerWidth > 575.98 ? '15px' : '13px' }}
                                  >
                                    {"Proposition d'action".toUpperCase()}
                                  </div>
                                  <div
                                    className="relanconsEchelle "
                                    style={{ fontSize: window.innerWidth > 575.98 ? '14px' : '12px' }}
                                  >
                                    débat national
                                  </div>
                                  <div className="mt-1" style={{ height: '1px', border: 'solid', borderColor: 'black', borderWidth: '1px' }}></div>
                                </div>

                                <div className="d-flex flex-column mt-2 ">
                                  <div
                                    className="relanconsDebat "
                                    style={{ fontSize: window.innerWidth > 575.98 ? '21px' : '16px' }}
                                  >
                                    {this.props.miseEnFormeIntroAvecCitation(
                                      this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', listquoteMainPage[element].q_typeID, 'introduction'),
                                      listquoteMainPage[element].q_title)
                                    }
                                  </div>
                                </div>

                              </div>

                            </div>

                          </a>

                        </Carousel.Item>
                      ))
                      }

                    </Carousel>

                  </div>

                </div>
              }

            </div>



            {/* Page recherche */}
            {this.props.pageRechercheShow &&
              <section className="d-flex flex-column col-12 mt-0 p-0 " id="listeDebats">

                {listquoteLoaded &&
                  <div
                    className="titrePageRechercher"
                    style={{ fontSize: window.innerWidth > 575.98 ? "27px" : "20px" }}
                  >
                    Recherche
                  </div>
                }

                {/* Barre de recherche */}
                {listquoteLoaded &&
                  <div className="d-flex flex-column justify-content-center rechercheBox  mt-4 pt-4 pb-4 ">

                    <div className={"d-flex flex-row barreRechercheAll col-10 col-lg-8 ml-auto mr-auto "} >
                      <FormControl
                        id="barreRechercheChamp"
                        type="text"
                        className="col-10 col-lg-11 m-0 p-0 barreRechercheChamps text-center "
                        placeholder="Tapez un mot clé"
                        onChange={(e) => this.setState({ searchBarText: e.target.value })}
                        style={{
                          border: 'solid',
                          borderWidth: '3px',
                          borderColor: (this.state.hoverRechercherUp || this.state.hoverRechercherDown) ? '#f1948a' : '#ffffff',
                          height: window.innerWidth > 575.98 ? "52px" : "45px"
                        }}
                        onMouseEnter={() => this.setState({ hoverRechercherDown: true })}
                        onMouseLeave={() => this.setState({ hoverRechercherDown: false })}
                      />


                      <div
                        className="d-flex justify-content-center iconeRecherche m-0 p-0 pl-1 ml-auto col-2 col-lg-1"
                        style={{ width: '60px', backgroundColor: '#E55C5C', color: 'white' }}
                      >
                        <span className="align-self-center "><SearchIcon size={24} /></span>
                      </div>


                    </div>

                    {/* grandesThematiques rechercheEchelles */}
                    <div className="d-flex flex-row justify-content-around w-100 m-0 mt-4 pl-3 pr-3 ">

                      {rechercheEchelles.map((element, index) => (
                        <div
                          key={`rechercheEchellesSelection_${index}`}
                          className=" col-9 col-lg-3 mb-2"
                        >
                          <button
                            className={"w-100 p-2 ".concat(this.state.echelleDebatSelectionneValue === element.index ? "rechercheEchellesSelected" : "rechercheEchelles")}
                            style={{ fontSize: window.innerWidth > 575.98 ? "14px" : "13px" }}
                            onClick={() => this.setState({ echelleDebatSelectionneValue: this.state.echelleDebatSelectionneValue === element.index ? -1 : element.index })}
                          >
                            {this.capitalizeFirstLetter(element.label)}
                          </button>
                        </div>
                      ))}

                    </div>

                    {this.props.showConsoleLog && console.log("thematiqueSelected : ", this.state.thematiqueSelected)}

                    <div className={"d-flex flex-row col-12 col-lg-7 mt-4 p-0 ml-auto mr-auto ".concat(window.innerWidth > 575.98 ? "justify-content-around " : "justify-content-center ")}>

                      {grandesThematiques.map((element, index) => (
                        <button
                          key={`grandesThematiquesSelection_${index}`}
                          className={`${element.label === "environ- nement" ? 'environnement' : element.label}Box ml-2 mr-2 mb-2 pt-2 pb-2 pr-3 pl-3 `.concat(this.state.thematiqueSelected.includes(element.index) ? "rechercheEchellesSelected" : "rechercheEchelles")}
                          onClick={() => this.setState({ thematiqueSelected: this.state.thematiqueSelected.includes(element.index) ? [] : [element.index] })}
                        >
                          {this.capitalizeFirstLetter(element.label === "environ- nement" ? 'environnement' : element.label)}
                        </button>
                      ))}

                    </div>



                  </div>
                }

                {false && <div className="pageAccueilTitreParties mt-5 ">Résultat de la recherche</div>}

                {this.props.showConsoleLog && listquoteSearch[1] !== undefined && console.log("getThemeIDArrayFromThemeObjectArray : ", this.getThemeIDArrayFromThemeObjectArray(listquoteSearch[1].themesList))}

                {
                  listquoteSearch
                    .filter(
                      ({ q_placeScaleID, q_placePositionID, q_title, themesList }) =>
                        (this.state.echelleDebatSelectionneValue === -1 ? true :
                          (this.state.echelleDebatSelectionneValue === 1 ? (q_placeScaleID === 1 || q_placeScaleID === 2 || q_placeScaleID === 3) :
                            q_placeScaleID === this.state.echelleDebatSelectionneValue)) &&
                        (this.state.thematiqueSelected.length === 0 ? true :
                          this.checkIfVect1IncludesValuesFromVect2(this.getThemeIDArrayFromThemeObjectArray(themesList), this.state.thematiqueSelected)) &&
                        (this.state.searchBarText === "" ? true :
                          (q_title.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                            // || q_text.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                          )) &&
                        (this.state.echellePlaceSelectionneeValue === 0 ? true : this.state.echellePlaceSelectionneeValue === q_placePositionID)
                    ).length === 0 && listquoteLoaded &&
                  <div className="text-center aucunResultat mt-4">
                    Aucun résultat
                  </div>
                }

                {this.props.showConsoleLog && console.log("listquoteSearch : ", listquoteSearch)}

                <div className={this.state.homePageAffichageMozaique ? "d-flex flex-wrap justify-content-center w-100 m-0 mt-5 p-0 " : "d-flex flex-column"}>
                  {listquoteSearch
                    .filter(
                      ({ q_placeScaleID, q_placePositionID, q_title, themesList }) =>
                        (this.state.echelleDebatSelectionneValue === -1 ? true :
                          (this.state.echelleDebatSelectionneValue === 1 ? (q_placeScaleID === 1 || q_placeScaleID === 2 || q_placeScaleID === 3) :
                            q_placeScaleID === this.state.echelleDebatSelectionneValue)) &&
                        (this.state.thematiqueSelected.length === 0 ? true :
                          this.checkIfVect1IncludesValuesFromVect2(this.getThemeIDArrayFromThemeObjectArray(themesList), this.state.thematiqueSelected)) &&
                        (this.state.searchBarText === "" ? true :
                          (q_title.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                            // || q_text.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                          )) &&
                        (this.state.echellePlaceSelectionneeValue === 0 ? true : this.state.echellePlaceSelectionneeValue === q_placePositionID)
                    )
                    .slice((this.state.selectedPage - 1) * this.state.nbDebatsParPage, (this.state.selectedPage - 1) * this.state.nbDebatsParPage + this.state.nbDebatsParPage)
                    .map((element, index) => (

                      <div key={`debate_${element.idquote}`} className={this.state.homePageAffichageMozaique ? "d-flex align-items-stretch col-11 col-lg-4 m-0 p-0 " : "d-flex flex-column"}>
                        <ComponentDebate
                          quote={element}
                          titreIntro={
                            // this.props.getAttributeValueInArrayOfObjectFromOtherAttribute(this.props.natureInformationTable, 'id', element.q_typeID, 'introduction')
                            this.props.introDebat(element, this.props.natureInformationTable)
                          }

                          url_image={this.props.url_image}

                          showConsoleLog={this.props.showConsoleLog}
                          homePageAffichageMozaique={this.state.homePageAffichageMozaique}
                          format="Large"
                        />
                      </div>
                    ))}
                </div>

                <div className="d-flex justify-content-center w-100 mt-4 ">
                  <ComponentPagination
                    nombreDebats={listquoteSearch
                      .filter(
                        ({ q_placeScaleID, q_placePositionID, q_title, themesList }) =>
                          (this.state.echelleDebatSelectionneValue === -1 ? true :
                            (this.state.echelleDebatSelectionneValue === 1 ? (q_placeScaleID === 1 || q_placeScaleID === 2 || q_placeScaleID === 3) :
                              q_placeScaleID === this.state.echelleDebatSelectionneValue)) &&
                          (this.state.thematiqueSelected.length === 0 ? true :
                            this.checkIfVect1IncludesValuesFromVect2(this.getThemeIDArrayFromThemeObjectArray(themesList), this.state.thematiqueSelected)) &&
                          (this.state.searchBarText === "" ? true :
                            (q_title.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                              // || q_text.toLowerCase().includes(this.state.searchBarText.toLowerCase())
                            )) &&
                          (this.state.echellePlaceSelectionneeValue === 0 ? true : this.state.echellePlaceSelectionneeValue === q_placePositionID)
                      ).length}
                    onClick={this.selectedPage}
                    selectedPage={this.state.selectedPage}
                    nbDebatsParPage={this.state.nbDebatsParPage}
                  />
                </div>
              </section>


            }



          </div>


        </div>

        <div className="d-flex col-12 mt-3 mr-0 ml-0 p-0 ">

          {/* FOOTER */}
          {listquoteLoaded &&
            <ComponentFooter
              url_image={this.props.url_image}
            />
          }
        </div>

      </div >

    )
  }

}

export default Home;
