// MENU EN HAUT DE LA PAGE
// Il permet d'accéder à chaque page du site selon un chemin d'acces

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Components React
import Home from './components/Home_2';
import Citations_2 from './components/Citations_2';
import IntervenantPage from './components/IntervenantPage';
import SourcePage from './components/SourcePage';
import PageAPropos from './components/PageAPropos';
import ComponentTweetLabelisation from './components/ComponentTweetLabelisation';
import Error from './components/Error';

import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css'; // Import de css personnalisé.
import './full.css';

// import { hotjar } from 'react-hotjar'

import $ from "jquery"

let spot_api = {}
//Element API
if (process.env.REACT_APP_NODEENV === "developpement" || process.env.REACT_APP_NODEENV === "development") {
  import('spot_api_dev').then(module => spot_api = module)
} else {
  import('spot_api_prod').then(module => spot_api = module)
}
// top

const MODE_ACCES = process.env.REACT_APP_NODEENV
const url_image = process.env.REACT_APP_URLIMAGE

const API_key = process.env.REACT_APP_APIKEY

class App extends Component {
  state = {

    showConsoleLog: false,

    echellesGeographiques: ['Débats locaux', 'Débats départementaux', 'Débats régionaux', 'Débats nationaux', 'Débats européens'], // , 'Non concerné'
    echellesGeographiquesSing: ['Débat local', 'Débat départemental', 'Débat régional', 'Débat national', 'Débat européen'],

    visitorLocation: {
      ipAdressV4: "",
      ipAdressV6: "",
      country_name: "",
      region: "",
      city: "",
      timeZone: ""
    },

    listquoteMainPage: [],
    listquoteSearch: [],
    listquoteSimilar: [],

    listquoteLoaded: false,
    listAllThemes: [],
    listThemes: [],
    listSousthemes: [],

    listsomeonetype: [],
    listorganisationtype: [],
    listreferencetype: [],
    listmediatype: [],
    linkTypeList: [],

    listperson: [],
    listmedia: [],

    queryTrackerNumber: 0,

    pageRechercheShow: false,

    initDataRequest: true,

    dateTypeSelected: 1,
    dateTypeIntro: ['', 'Le ', 'Depuis le / A partir du ', 'Entre le '],
    dateTypeIntro2: ['', 'En ', 'Depuis / A partir de ', 'Entre '],

    dateTypeIntroAll: [
      { title: '', type: 'level0' },

      { title: 'Date', timeType: 'separator', timeScale: 'separator' },
      { title: 'le', timeType: 'date', timeScale: 'jour' },
      { title: 'en', timeType: 'date', timeScale: 'mois' },
      { title: 'depuis le / à partir du', timeType: 'date', timeScale: 'jour' },
      { title: 'depuis / à partir de', timeType: 'date', timeScale: 'mois' },
      { title: 'avant le', timeType: 'date', timeScale: 'jour' },
      { title: 'avant', timeType: 'date', timeScale: 'mois' },

      { title: 'Durée', timeType: 'separator', timeScale: 'separator' },
      { title: 'entre le ... et ...', timeType: 'durée', timeScale: 'jour' },
      { title: 'entre ... et ...', timeType: 'durée', timeScale: 'mois' },
    ],

    evenementTypeList: [
      { title: 'hh:mm jj/mm/aaaa', timeScale: 'jour' },
      { title: 'jj/mm/aaaa', timeScale: 'jour' },
      { title: 'mm/aaaa', timeScale: 'mois' },
      { title: 'aaaa', timeScale: 'mois' }
    ],
    evenementType: 'jj/mm/aaaa',

    regionsList: [],
    departmentsList: [],

  }


  componentDidMount() {

    this.initData()

  }

  initData = () => {

    this.ListQuoteAPIs('HomePage', 0, 20, [], [1], [])
    this.ListQuoteAPIs('SearchPage', 0, 20, [], [], [])

    this.AllThemesAPIs()
    // this.MainThemesAPIs()
    // this.SousThemesAPIs()
    // this.QuoteTypeAPIs()

    this.SomeoneTypeAPIs()
    this.OrganisationTypeAPIs()
    this.ReferenceTypeAPIs()
    this.MediaTypeAPIs()
    // this.PersonListAPIs()
    this.MediaAPIs()

    this.LinkTypeAPIs()

    this.getGeoInfo()

    this.regionsAPIs()
    this.departmentsAPIs()


    // hotjar.initialize('2092984', 6)
  }

  componentWillMount() {

    const publicIp = require('public-ip');
    (async () => {

      let ipAdressV4 = "0000:0000:0000:0000:0000:0000:0000:0000"
      let ipAdressV6 = "0000:0000:0000:0000:0000:0000:0000:0000"

      try {
        ipAdressV4 = await publicIp.v4()
        this.state.showConsoleLog && console.log("Adresse IP v4 ok")
      } catch {
        this.state.showConsoleLog && console.log("ERROR : Adresse IP v4 introuvable ...")
      }
      try {
        ipAdressV6 = await publicIp.v6()
        this.state.showConsoleLog && console.log("Adresse IP v6 ok")
      } catch {
        this.state.showConsoleLog && console.log("ERROR : Adresse IP v6 introuvable ...")
      }

      this.setState({ visitorLocation: { ...this.state.visitorLocation, ipAdressV4: ipAdressV4, ipAdressV6: ipAdressV6 } })
    })()
  }

  pageRechercheDisplay = (value) => {
    this.setState({ pageRechercheShow: value })
  }

  getGeoInfo = () => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data
      this.setState({
        visitorLocation: {
          ...this.state.visitorLocation,
          country_name: data.country_name,
          region: data.region,
          city: data.city,
          timeZone: data.timezone
        }
      })
    }).catch((error) => {
      console.log(error)
    })
  }

  queryTracker = (pageName, functionName) => {
    // console.log("queryTracker # :", ' -> Page : ', pageName, ' -> Fonction : ', functionName)
    // console.log("queryTracker #", this.state.queryTrackerNumber + 1, ' -> Page : ', pageName, ' -> Fonction : ', functionName)
    this.setState({ queryTrackerNumber: this.state.queryTrackerNumber + 1 })
  }



  spotAnalytics = (spot_api, visitedPage) => {

    this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> spot_api', spot_api)
    this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> ApiClient', spot_api.ApiClient)

    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.AnalyticsApi()

      this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> visitedPage', visitedPage)
      this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> MODE_ACCES', MODE_ACCES)

      if (MODE_ACCES !== "developpement" && MODE_ACCES !== "development") {

        apiInstance.visitProductionSitePost(visitedPage, (error, data, response) => {
          if (error) {
            console.error(error);
            this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> post NOK')
          } else {
            this.state.showConsoleLog && console.log('SomeoneTypeAPIs -> post OK')
            // this.setState({ listsomeonetype: data })
          }
        })

      } else {
        this.state.showConsoleLog && console.log("SomeoneTypeAPIs -> mode dev : pas d'analytics")
      }
    }
  }

  regionsAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.PlaceApi()

      apiInstance.placeRegionsGet((error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          this.state.showConsoleLog && console.log('regionsAPIs -> data : ', data)
          this.setState({
            regionsList: data.sort(function (a, b) { // ordre alphabétique
              if (a.re_name < b.re_name) { return -1; }
              if (a.re_name > b.re_name) { return 1; }
              return 0;
            })
          })
        }
      })
    }
  }

  departmentsAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.PlaceApi()

      apiInstance.placeDepartmentsGet((error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          this.state.showConsoleLog && console.log('departmentsAPIs -> data : ', data)
          this.setState({ departmentsList: data })
        }
      })
    }
  }


  SomeoneTypeAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.TypeApi()

      this.queryTracker('App.js', 'SomeoneTypeAPIs')
      apiInstance.someonetypeGet((error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          // console.log('SomeoneTypeAPIs -> listsomeonetype : ', data)
          this.setState({ listsomeonetype: data })
        }
      })
    }
  }


  OrganisationTypeAPIs() {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.TypeApi()

      this.queryTracker('App.js', 'OrganisationTypeAPIs')
      apiInstance.organisationtypeGet((error, data, response) => {
        // this.state.showConsoleLog && console.log('OrganisationTypeAPIs : ', data)
        this.setState({ listorganisationtype: data })
      })
    }

  }



  ReferenceTypeAPIs = (mediaID) => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = API_key;
      let apiInstance = new spot_api.TypeApi();
      let opts = { mediaID: {} };
      opts['mediaID'] = mediaID;

      this.queryTracker('App.js', 'ReferenceTypeAPIs')
      apiInstance.referencetypeGet(opts, (error, data, response) => {
        // console.log('ReferenceTypeAPIs : ', data)
        this.setState({ listreferencetype: data })
      })
    }
  }


  MediaTypeAPIs = (referenceID) => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.TypeApi()
      let opts = { referenceID: {} }
      opts['referenceID'] = referenceID;

      // console.log('MediaTypeAPIs -> : ', referenceID)

      this.queryTracker('App.js', 'MediaTypeAPIs')
      apiInstance.mediatypeGet(opts, (error, data, response) => {
        this.state.showConsoleLog && console.log('MediaTypeAPIs : ', data)
        this.setState({ listmediatype: data })
      })
    }

  }


  LinkTypeAPIs() {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = API_key;
      let apiInstance = new spot_api.TypeApi();

      this.queryTracker('Citations_2.js', 'LinkTypeAPIs')
      apiInstance.linktypeGet((error, data, response) => {
        if (error) {
          console.error(error);
        }
        else {
          this.state.showConsoleLog && console.log('LinkTypeAPIs : ', data)
          if (data != null) {
            this.setState({ linkTypeList: data });
          }
        }
      });
    }
  }

  PersonListAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.PersonApi()

      this.queryTracker('App.js', 'PersonListAPIs')
      apiInstance.personGet((error, data, response) => {
        this.state.showConsoleLog && console.log('PersonListAPIs : ', data)
        this.setState({ listperson: data })

        // this.reorganizePersonsList(data)
      })
    }
  }

  MediaAPIs = (mediatypeID) => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.MediaApi()
      let opts = { mediatypeID: {} }
      opts['mediatypeID'] = mediatypeID

      // console.log('MediaAPIs -> mediatypeID : ', mediatypeID)

      this.queryTracker('App.js', 'MediaAPIs')
      apiInstance.mediaGet(0, 20, opts, (error, data, response) => {
        this.state.showConsoleLog && console.log('ComponentFormulaireCommentateur -> MediaAPIs : ', data)
        this.setState({ listmedia: data })
      });
    }
  }


  AllThemesAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key

      let apiInstance = new spot_api.ThemeApi()

      this.queryTracker('App.js', 'AllThemesAPIs')
      apiInstance.themeGet((error, data, response) => {
        if (error) {
          console.error(error)
        } else {

          this.state.showConsoleLog && console.log('AllThemesAPIs : ', data)
          this.setState({ listAllThemes: data })

        }
      });
    }
  }

  ListQuoteAPIs = (pageType, firstRowToReturn, numberOfRowsToReturn, selectedThemesID, quoteTypesID, filterText) => {
    if (spot_api.ApiClient !== undefined) {
      this.state.showConsoleLog && console.log('ListQuoteAPIs -> spot_api :', spot_api)

      this.state.showConsoleLog && console.log('ListQuoteAPIs -> quoteTypesID :', quoteTypesID)
      this.state.showConsoleLog && console.log('ListQuoteAPIs -> filterText :', filterText)

      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.QuoteApi();
      let opts = { themesID: null, quoteTypesID: null, filterText: null };
      if (selectedThemesID.length > 0) {
        opts['themesID'] = selectedThemesID;
      }
      if (quoteTypesID.length > 0) {
        opts['quoteTypesID'] = quoteTypesID;
      }
      if (filterText.length > 0) {
        opts['filterText'] = filterText;
      }

      this.state.showConsoleLog && console.log('ListQuoteAPIs -> in opts :', opts)


      this.queryTracker('App.js', 'ListQuoteAPIs')
      // Récupérer la liste de toutes les quotes triées selon un thème (ou pas)
      apiInstance.quoteGet(firstRowToReturn, numberOfRowsToReturn, opts, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {

          this.state.showConsoleLog && console.log('ListQuoteAPIs data 1 -> ', pageType, ' : ', data)

          if (pageType === 'HomePage') {
            this.setState({ listquoteMainPage: data, listquoteLoaded: true })
          } else if (pageType === 'SearchPage') {
            this.setState({ listquoteSearch: data })
          } else if (pageType === 'SimilareQuotes') {
            this.setState({ listquoteSimilar: data })
          }

        }
      })
    }

  }


  ListSimilarQuoteAPIs = (pageType, firstRowToReturn, numberOfRowsToReturn, selectedThemesID, quoteTypesID, filterText) => {

    this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in filterText :', filterText)

    if (spot_api.ApiClient !== undefined && filterText.length > 0) {

      let defaultClient = spot_api.ApiClient.instance
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
      ApiKeyAuth.apiKey = API_key
      let apiInstance = new spot_api.QuoteApi();

      let opts = { quoteTypesID: null };
      if (quoteTypesID.length > 0) {
        opts['quoteTypesID'] = quoteTypesID;
      }

      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in pageType :', pageType)
      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in firstRowToReturn :', firstRowToReturn)
      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in numberOfRowsToReturn :', numberOfRowsToReturn)
      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in selectedThemesID :', selectedThemesID)
      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in quoteTypesID :', quoteTypesID)
      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in filterText :', filterText)

      this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs -> in opts :', opts)


      this.queryTracker('App.js', 'ListSimilarQuoteAPIs')
      // Récupérer les quotes matchant avec une liste de strings
      apiInstance.quoteSearchGet(firstRowToReturn, numberOfRowsToReturn, filterText, opts, (error, data, response) => {
        if (error) {
          console.error(error);
        } else {

          this.state.showConsoleLog && console.log('ListSimilarQuoteAPIs data 1 -> ', pageType, ' : ', data)

          this.setState({ listquoteSimilar: data })

        }
      })
    } else {
      this.setState({ listquoteSimilar: [] })
    }

  }


  SousThemesAPIs = (value) => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = API_key;
      let apiInstance = new spot_api.ThemeApi();
      let opts = { themesID: [] }
      opts.themesID = value
      /*if(event!=undefined && event.target.value>0){
          opts['themeID']=event.target.value;
          this.setState({quote:{
              q_title:this.state.quote.q_title,
              q_text:this.state.quote.q_text,
              q_statut:this.state.quote.q_statut,
              q_typeID: this.state.quote.q_typeID,
              themeID:event.target.value,
              sousthemeID:this.state.quote.sousthemeID}})
      }*/

      this.queryTracker('App.js', 'SousThemesAPIs')
      apiInstance.themeSousthemeGet(opts, (error, data, response) => {
        this.state.showConsoleLog && console.log('SousThemesAPIs ', data);
        this.setState({ listSousthemes: data });
      });
    }

  }



  QuoteTypeAPIs = () => {
    if (spot_api.ApiClient !== undefined) {
      let defaultClient = spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = API_key;
      let apiInstance = new spot_api.QuoteTypeApi();

      this.queryTracker('App.js', 'QuoteTypeAPIs')
      apiInstance.quoteTypeGet((error, data, response) => {
        if (error) {
          console.error(error);
        } else {
          this.setState({ listeQuoteTypes: data });
        }
        this.state.showConsoleLog && console.log('QuoteTypeAPIs -> data : ', data);
      });
    }

  }


  listOfAllThemesInQuotes(listquoteMainPage) {
    let themeList = []

    this.state.showConsoleLog && console.log('listOfAllThemesInQuotes : ', listquoteMainPage)

    if (listquoteMainPage !== []) {
      listquoteMainPage.forEach(quote => {
        quote.themesList !== undefined &&
          quote.themesList.forEach(theme => {
            themeList.push(theme.t_title)
          })
      })
    }

    return themeList
  }


  countElementsEqualToValueInArray(array, value) {
    let count = 0

    array.forEach(element => {
      if (element === value) {
        count += 1
      }
    })

    return count
  }


  addQuote = (quote, loadNewPage) => {

    let replaceAll = (string, search, replace) => {
      let returnValue = null
      if (string !== undefined && string !== null) {
        returnValue = string.split(search).join(replace)
      }
      return returnValue
    }

    this.state.showConsoleLog && console.log("in addQuote -> quote : ", quote)
    this.state.showConsoleLog && console.log("in addQuote -> loadNewPage : ", loadNewPage)

    const myquote = quote
    myquote.q_title = replaceAll(quote.q_title, "'", "\\'") // quote.q_title.replace("'", "\\'")
    myquote.q_text = replaceAll(quote.q_text, "'", "\\'") // quote.q_text.replace("'", "\\'")
    myquote.q_quoi = replaceAll(quote.q_quoi, "'", "\\'") // quote.q_quoi.replace("'", "\\'")
    myquote.q_quand = replaceAll(quote.q_quand, "'", "\\'") // quote.q_quand.replace("'", "\\'")
    myquote.q_ou = replaceAll(quote.q_ou, "'", "\\'") // quote.q_ou.replace("'", "\\'")
    myquote.q_combien = replaceAll(quote.q_combien, "'", "\\'") // quote.q_combien.replace("'", "\\'")
    myquote.q_pourQui = replaceAll(quote.q_pourQui, "'", "\\'") // quote.q_pourQui.replace("'", "\\'")

    this.state.showConsoleLog && console.log("addQuote -> myquote : ", myquote)

    let defaultClient = spot_api.ApiClient.instance
    let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth']
    ApiKeyAuth.apiKey = API_key
    let apiInstance = new spot_api.QuoteApi()

    this.queryTracker('App.js', 'addQuote')
    {
      true && apiInstance.quotePost(myquote, (error, data, response) => { // Insérer une nouvelle quote

        if (error) {
          console.error(error)
        } else {

          this.state.showConsoleLog && console.log("addQuote -> response api : ", response)

          let res = JSON.parse(response.text)
          let quoteID = res['results'].insertId

          if (loadNewPage) {
            window.location.href = '/citations_2/' + quoteID
          }

        }
      })
    }

  }


  introDebat = (quote, natureInformationTable) => {

    let intro = ""

    this.state.showConsoleLog && console.log('in titreDebat -> quote : ', quote)

    if (
      quote !== undefined &&
      this.getAttributeValueInArrayOfObjectFromOtherAttribute(natureInformationTable, 'id', quote.q_typeID, 'nature') !== "évènement"
    ) {
      if (natureInformationTable === undefined) {
        // titre = quote.q_title
      } else {
        intro = this.miseEnFormeIntroSansCitation(
          this.getAttributeValueInArrayOfObjectFromOtherAttribute(natureInformationTable, 'id', quote.q_typeID, 'introduction'),
          quote.q_title)
      }
    } else {

      if (quote.q_vd_DateBeg !== null && quote.q_vd_DateBeg !== "") {

        intro = this.capitalizeFirstLetter(intro.concat(this.introDate2(quote), ', '))

        this.state.showConsoleLog && console.log("out td : ", intro)
        this.state.showConsoleLog && console.log('td chemin 2 fin / ', intro)

      } else {
        // titre = this.capitalizeFirstLetter(quote.q_title)
      }

    }
    return intro


  }

  titreDebat = (quote, natureInformationTable) => {
    let titre = ""

    this.state.showConsoleLog && console.log('in titreDebat -> quote : ', quote)

    if (
      quote !== undefined &&
      this.getAttributeValueInArrayOfObjectFromOtherAttribute(natureInformationTable, 'id', quote.q_typeID, 'nature') !== "évènement"
    ) {
      if (natureInformationTable === undefined) {
        titre = quote.q_title
      } else {
        titre = this.miseEnFormeIntroAvecCitation(
          this.getAttributeValueInArrayOfObjectFromOtherAttribute(natureInformationTable, 'id', quote.q_typeID, 'introduction'),
          quote.q_title)
      }
    } else {

      if (quote.q_vd_DateBeg !== null && quote.q_vd_DateBeg !== "") {

        titre = this.capitalizeFirstLetter(titre.concat(this.introDate2(quote), ', ', quote.q_title))

        this.state.showConsoleLog && console.log("out td : ", titre)
        this.state.showConsoleLog && console.log('td chemin 2 fin / ', titre)

      } else {
        titre = this.capitalizeFirstLetter(quote.q_title)
      }

    }
    return titre
  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }


  introDate2 = (quote) => {
    let titre = ''

    let dateTypeIntroAll = [
      { title: '', type: 'level0' },
      { title: 'Date', timeType: 'separator', timeScale: 'separator' },
      { title: 'le', timeType: 'date', timeScale: 'jour' },
      { title: 'en', timeType: 'date', timeScale: 'mois' },
      { title: 'depuis le / à partir du', timeType: 'date', timeScale: 'jour' },
      { title: 'depuis / à partir de', timeType: 'date', timeScale: 'mois' },
      { title: 'avant le', timeType: 'date', timeScale: 'jour' },
      { title: 'avant', timeType: 'date', timeScale: 'mois' },
      { title: 'Durée', timeType: 'separator', timeScale: 'separator' },
      { title: 'entre le ... et ...', timeType: 'durée', timeScale: 'jour' },
      { title: 'entre ... et ...', timeType: 'durée', timeScale: 'mois' },
    ]

    let joursSemaine = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi']
    let moisAnnee = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']

    let vd_DateBeg = new Date(quote.q_vd_DateBeg)
    let vd_DateEnd = new Date(quote.q_vd_DateEnd)

    let dateNow = Date.now()


    this.state.showConsoleLog && console.log("introDate2 in -> quote : ", quote)

    this.state.showConsoleLog && console.log("introDate2 -> vd_DateBeg : ", vd_DateBeg)
    this.state.showConsoleLog && console.log("introDate2 -> vd_DateEnd : ", vd_DateEnd)
    this.state.showConsoleLog && console.log("introDate2 -> dateNow : ", dateNow)

    this.state.showConsoleLog && console.log("introDate2 -> compar 1 : ", vd_DateBeg < dateNow)
    this.state.showConsoleLog && console.log("introDate2 -> compar 2 : ", vd_DateBeg > dateNow)

    if (quote.q_quand === "-1") { // L'utilisateur ne souhaite pas renseigner la partie 'quand'
      titre = "-1"
    } else {

      if (quote.q_vd_DateBeg !== "") {

        if (quote.q_vd_timeIntro === 'depuis le / à partir du') {
          if (vd_DateBeg < dateNow) {
            titre = titre.concat('depuis le')
          } else {
            titre = titre.concat('à partir du')
          }
        } else if (quote.q_vd_timeIntro === 'depuis / à partir de') {
          if (vd_DateBeg < dateNow) {
            titre = titre.concat('depuis')
          } else {
            titre = titre.concat('à partir de')
          }
        } else if (quote.q_vd_timeIntro === 'entre le ... et ...') {
          titre = titre.concat('entre le')
        } else if (quote.q_vd_timeIntro === 'entre ... et ...') {
          titre = titre.concat('entre')
        } else {
          titre = titre.concat(quote.q_vd_timeIntro)
        }

        this.state.showConsoleLog && console.log("introDate2 in -> titre inter 1 : ", titre)

        this.state.showConsoleLog && console.log("introDate2 -> vd_DateBeg.getMonth() : ", vd_DateBeg.getMonth())
        this.state.showConsoleLog && console.log("introDate2 -> vd_DateBeg : ", vd_DateBeg)
        this.state.showConsoleLog && console.log("introDate2 -> vd_DateEnd : ", vd_DateEnd)
        this.state.showConsoleLog && console.log("introDate2 -> vd_DateEnd.getMonth() : ", vd_DateEnd.getMonth())

        if (quote.q_vd_timeFormat === "hh:mm jj/mm/aaaa") {
          titre = titre.concat(' ', vd_DateBeg.getDate(),
            vd_DateBeg.getDate() === 1 ? 'er ' : ' ',
            (quote.q_vd_DateEnd !== null && vd_DateBeg.getMonth() === vd_DateEnd.getMonth()) ? '' : moisAnnee[vd_DateBeg.getMonth()], // affichage du mois seulement si mois début différent de mois fin (ex : entre le 27 (!!non!!) et le 29 avril)
            ' ',
            vd_DateBeg.getFullYear() === vd_DateEnd.getFullYear() ? '' : vd_DateBeg.getFullYear(),   // affichage de l'année seulement si mois début différent de mois fin
            ' à ',
            vd_DateBeg.getHours(), 'h',
            vd_DateBeg.getMinutes() === 0 ? "" : (vd_DateBeg.getMinutes() < 10 ? "0".concat(vd_DateBeg.getMinutes()) : vd_DateBeg.getMinutes()))
        }
        if (quote.q_vd_timeFormat === "jj/mm/aaaa") {
          titre = titre.concat(' ', vd_DateBeg.getDate(),
            vd_DateBeg.getDate() === 1 ? 'er ' : ' ',
            (quote.q_vd_DateEnd !== null && vd_DateBeg.getMonth() === vd_DateEnd.getMonth()) ? '' : moisAnnee[vd_DateBeg.getMonth()],   // affichage du mois seulement si mois début différent de mois fin (ex : entre le 27 (!!non!!) et le 29 avril)
            ' ',
            vd_DateBeg.getFullYear() === vd_DateEnd.getFullYear() ? '' : vd_DateBeg.getFullYear()    // affichage de l'année seulement si mois début différent de mois fin
          )
        }
        if (quote.q_vd_timeFormat === "mm/aaaa") {
          titre = titre.concat(' ', moisAnnee[vd_DateBeg.getMonth()], ' ', vd_DateBeg.getFullYear())
        }
        if (quote.q_vd_timeFormat === "aaaa") {
          titre = titre.concat(' ', vd_DateBeg.getFullYear())
        }

        if (quote.q_vd_timeType === "durée" && quote.q_vd_DateEnd !== null) {

          if (quote.q_vd_timeFormat === "jj/mm/aaaa" || quote.q_vd_timeFormat === "hh:mm jj/mm/aaaa") {
            titre = titre.concat(" et le ")
          } else {
            titre = titre.concat(" et ")
          }

          if (quote.q_vd_timeFormat === "hh:mm jj/mm/aaaa") {
            titre = titre.concat(' ', vd_DateEnd.getDate(), vd_DateEnd.getDate() === 1 ? 'er ' : ' ',
              moisAnnee[vd_DateEnd.getMonth()], ' ', vd_DateEnd.getFullYear(), ' à ', vd_DateEnd.getHours(), 'h', vd_DateEnd.getMinutes() === 0 ? "" : (vd_DateEnd.getMinutes() < 10 ? "0".concat(vd_DateEnd.getMinutes()) : vd_DateEnd.getMinutes()))
          }
          if (quote.q_vd_timeFormat === "jj/mm/aaaa") {
            titre = titre.concat(' ', vd_DateEnd.getDate(), vd_DateEnd.getDate() === 1 ? 'er ' : ' ', moisAnnee[vd_DateEnd.getMonth()], ' ', vd_DateEnd.getFullYear())
          }
          if (quote.q_vd_timeFormat === "mm/aaaa") {
            titre = titre.concat(' ', moisAnnee[vd_DateEnd.getMonth()], ' ', vd_DateEnd.getFullYear())
          }
          if (quote.q_vd_timeFormat === "aaaa") {
            titre = titre.concat(' ', vd_DateEnd.getFullYear())
          }
        }
      }
    }

    this.state.showConsoleLog && console.log("introDate2 in -> titre fin : ", titre)

    return titre
  }




  miseEnFormeIntroSansCitation = (intro, citation) => {
    this.state.showConsoleLog && console.log('miseEnFormeIntro -> intro : ', intro)
    this.state.showConsoleLog && console.log('miseEnFormeIntro -> citation : ', citation)

    if (intro !== undefined && citation !== undefined) {
      let fstLetter = citation.charAt(0).toLowerCase()
      this.state.showConsoleLog && console.log('miseEnFormeIntro -> fstLetter : ', fstLetter)
      if (fstLetter === 'a' || fstLetter === 'e' || fstLetter === 'i' || fstLetter === 'o' || fstLetter === 'u' || fstLetter === 'y' || fstLetter === 'à' || fstLetter === 'é' || fstLetter === 'è' || fstLetter === 'ê') {
        return intro.slice(0, intro.length - 2).concat("'")
      } else {
        return intro
      }
    } else {
      return []
    }
  }


  miseEnFormeIntroAvecCitation = (intro, citation) => {

    if (intro !== undefined && citation !== undefined) {
      let fstLetter = citation.charAt(0).toLowerCase()
      if (fstLetter === 'a' || fstLetter === 'e' || fstLetter === 'i' || fstLetter === 'o' || fstLetter === 'u' || fstLetter === 'y' || fstLetter === 'à') {
        return intro.slice(0, intro.length - 2).concat("'", citation)
      } else {
        return intro.concat(citation)
      }
    }
  }


  replaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
  }

  getAttributeValueInArrayOfObjectFromOtherAttribute = (arrayOfObjects, attributeName, attributeValue, attributeSearch) => {
    var searchValueResult = ''

    if (arrayOfObjects !== undefined) {

      this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> arrayOfObjects : ', arrayOfObjects)

      this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeName : ', attributeName)
      this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeValue : ', attributeValue)
      this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> attributeSearch : ', attributeSearch)

      arrayOfObjects.forEach((element, index) => {
        this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> element : ', element)
        if ($(element).attr(attributeName) === attributeValue) {
          searchValueResult = $(element).attr(attributeSearch)
        }
      })

      this.state.showConsoleLog && console.log('getAttributeValueInArrayOfObjectFromOtherAttribute -> searchValueResult : ', searchValueResult)

    }

    return searchValueResult

  }



  render() {

    this.state.showConsoleLog && console.log("App.js -> NODE_ENV : ", process.env.NODE_ENV)
    this.state.showConsoleLog && console.log("App.js -> MODE_ACCES : ", MODE_ACCES)
    this.state.showConsoleLog && console.log("App.js -> url_image : ", url_image)
    this.state.showConsoleLog && console.log("App.js -> API_key : ", API_key)

    this.state.showConsoleLog && console.log("App.js -> spot_api : ", spot_api)
    this.state.showConsoleLog && console.log("App.js -> ApiClient : ", spot_api.ApiClient)


    if (spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.state.showConsoleLog && console.log("App.js -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    const echellesGeographiquesInformationTable = [
      {
        id: 1,
        echelleDebat: "débat local",
        echelleInformation: "une ville",
        institution: "Mairie",
        image: "institution_mairie.png",
        intro: "dans",
      },
      {
        id: 2,
        echelleDebat: "débat départemental",
        echelleInformation: "un département",
        institution: "Conseil départemental",
        image: "institution_conseilDepartemental.png",
        intro: "dans",
      },
      {
        id: 3,
        echelleDebat: "débat régional",
        echelleInformation: "une région",
        institution: "Conseil régional",
        image: "institution_conseilRegional.png",
        intro: "dans",
      },
      {
        id: 4,
        echelleDebat: "débat national",
        echelleInformation: "toute la France",
        institution: "Parlement : Assemblée Nationale et Sénat",
        image: "institution_assembléeNationale.png",
        intro: "dans",
      },
      {
        id: 5,
        echelleDebat: "débat européen",
        echelleInformation: "union européenne",
        institution: "Parlement européen",
        image: "institution_parlementEuropeen.png",
        intro: "dans l'",
      },
      /*{
        id: 6,
        echelleDebat: "débat international",
        echelleInformation: "union intergouvernementale",
        institution: "ONU etc.",
        image: "institution_parlementEuropeen.png",
        intro: "dans une",
      },*/
      {
        id: 6,
        echelleDebat: "débat mondial",
        echelleInformation: "le monde",
        institution: "",
        image: "institutionWorld.png",
        intro: "dans",
      },
      {
        id: 8,
        echelleDebat: "autre",
        echelleInformation: "autre",
        institution: "",
        image: "",
        intro: "",
      },

    ]



    const institutionsInformationTable = [
      {
        id: 1,
        echelleDebat: "mairie",
        image: "institution_mairie.png",
        intro: "dans",
      },
      {
        id: 2,
        echelleDebat: "conseil départemental",
        image: "",
        intro: "dans",
      },
      {
        id: 3,
        echelleDebat: "conseil régional",
        image: "",
        intro: "dans",
      },
      {
        id: 4,
        echelleDebat: "parlement",
        image: "",
        intro: "au",
      },
      {
        id: 5,
        echelleDebat: "union européenne",
        image: "",
        intro: "dans",
      },
      {
        id: 6,
        echelleDebat: "union intergouvernementale",
        image: "",
        intro: "dans",
      },

    ]


    const natureInformationTable_description_probleme = [
      {
        id: 100,
        nature: "description de notre société",
        interetSociete: "description",
        categorie: "connaissance ou avis personnel",
        definition: "Element étudié ou ressenti permettant de mieux comprendre notre monde (la société ou la nature).",
        critere: "",
        introduction: "",
        t_photo: "Aide_Connaissance2.png",
      },
      {
        id: 1,
        nature: "solution à un problème de société",
        interetSociete: "solution",
        categorie: "avis personnel",
        definition: "Proposition qui vise à changer ou maintenir une propriété de notre monde. Une proposition d'action est une opinion portée par le changement. C'est la matière de la Démocratie.",
        critere: "Volonté de changement",
        introduction: "Il est nécessaire de ",
        t_photo: "Aide_PropositionAction1.png",
      },
    ]

    const natureInformationTable_fait_opinion = [
      {
        id: 101,
        nature: "connaissance",
        interetSociete: "",
        categorie: "connaissance",
        definition: "Information se basant sur des éléments factuels et nous apprenant quelque chose sur le monde tel qu'il a été, tel qu'il est ou tel qu'il sera.",
        critere: "",
        introduction: "",
        t_photo: "Aide_Connaissance1.png",
      },
      {
        id: 6,
        nature: "opinion",
        interetSociete: "problème",
        categorie: "avis personnel",
        definition: "Prise de position se basant sur des ressentis ou des valeurs, et non étayée par des études.",
        critere: "Prise de position non prouvée, ou s'appuyant sur des valeurs",
        introduction: "Je pense que ",
        t_photo: "Aide_Opinion1.png",
      },
    ]



    const natureInformationTable_faits = [
      {
        id: 3,
        nature: "fait",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Information qui nous apprend quelque chose sur le monde tel qu'il est. Se base sur la méthode scientifique expérimentale. Elle doit être argumentée par des études.",
        critere: "S'appuie sur des preuves, des études",
        introduction: "Il a été montré que ",
        t_photo: "Aide_Fait1.png",
      },
      {
        id: 2,
        nature: "évènement",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Fait décrivant une action qui s'est déroulée, se déroule ou se déroulera, à une date ou sur une période précise.",
        critere: "Date ou période précise",
        introduction: "",
        t_photo: "Aide_Evenement1.png",
      },
      {
        id: 4,
        nature: "étude",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Etude visant à démontrer un fait par la mesure et l'expérience. S'applique en général aux sciences physiques, sciences de la vie et sciences sociales.",
        critere: "Niveau de preuve",
        introduction: "Une étude montre que ",
        t_photo: "Aide_Etude1.png",
      },
      {
        id: 5,
        nature: "dataviz",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "La visualisation des données aide à éclairer des informations en apparence complexes ou noyées dans plusieurs paramètres.",
        critere: "S'appuie sur des preuves, des études",
        introduction: "Des données montrent que",
        t_photo: "Aide_Dataviz1.png",
      },
    ]

    const natureInformationTable = [

      {
        id: 1,
        nature: "proposition d'action",
        interetSociete: "solution",
        categorie: "avis personnel",
        definition: "Proposition qui vise à changer ou maintenir une propriété de notre monde. Une proposition d'action est une opinion portée par le changement. C'est la matière de la Démocratie.",
        critere: "Volonté de changement",
        introduction: "Il est nécessaire de ",
        t_photo: "Aide_PropositionAction1.png",
      },
      {
        id: 6,
        nature: "opinion",
        interetSociete: "problème",
        categorie: "avis personnel",
        definition: "Prise de position non étayée par des études, ou se basant sur des valeurs",
        critere: "Prise de position non prouvée, ou s'appuyant sur des valeurs",
        introduction: "Je pense que ",
        t_photo: "Aide_Opinion1.png",
      },
      {
        id: 2,
        nature: "évènement",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Fait décrivant une action qui s'est déroulée, se déroule ou se déroulera, à une date ou sur une période précise. ",
        critere: "Date ou période précise",
        introduction: "",
        t_photo: "Aide_Evenement1.png",
      },
      {
        id: 3,
        nature: "fait",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Information qui nous apprend quelque chose sur le monde tel qu'il est. Se base sur la méthode scientifique expérimentale.",
        critere: "S'appuie sur des preuves, des études",
        introduction: "Il a été montré que ",
        t_photo: "Aide_Fait1.png",
      },
      {
        id: 4,
        nature: "étude",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Etude visant à démontrer un fait par la mesure et l'expérience. S'applique à tout type de science (sciences exactes, sciences physico-chimiques, sciences humaines)",
        critere: "Niveau de preuve",
        introduction: "Une étude montre que ",
        t_photo: "Aide_Etude1.png",
      },
      {
        id: 5,
        nature: "dataviz",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "La visualisation des données aide à éclairer des informations en apparence complexes ou noyées dans une grande quantité de paramètres.",
        critere: "S'appuie sur des preuves, des études",
        introduction: "Des données montrent que ",
        t_photo: "Aide_Dataviz1.png",
      },
      /* {
        id: 6,
        nature: "projection future",
        interetSociete: "problème",
        categorie: "connaissance",
        definition: "Information qui projette ce que le monde sera, sur la base d'études",
        critere: "Projection s'appuyant sur des preuves",
        introduction: "Il est probable que ",
        t_photo: "Aide_futur_fait1.png",
      },
      {
        id: 8,
        nature: "projection future opinion",
        interetSociete: "problème",
        categorie: "avis personnel",
        definition: "Information qui projette ce que le monde sera, sur la base de valeurs",
        critere: "Projection non prouvée, ou s'appuyant s'appuyant sur des valeurs",
        introduction: "Il est pensable que ",
        t_photo: "Aide_futur_opinion1.png",
      }, */

    ]


    {/*
        id: 5,
        nature: "sondage",
        categorie: "connaissance",
        definition: "Méthode statistique d'analyse d'une population humaine ou non humaine à partir d'un échantillon de cette population.",
        critere: "Interrogation d'un échantillon d'une population",
        introduction: "« Un sondage montre que … »",
        t_photo: "Aide_sondage1.png.jpg",
      */}

    return (
      <div className="m-0 " >
        <BrowserRouter >

          <Switch>
            <Route exact path="/" render={(props) =>
              <Home
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                echellesGeographiques={this.state.echellesGeographiques}
                echellesGeographiquesSing={this.state.echellesGeographiquesSing}
                echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                institutionsInformationTable={institutionsInformationTable}
                natureInformationTable={natureInformationTable}
                natureInformationTable_description_probleme={natureInformationTable_description_probleme}
                natureInformationTable_fait_opinion={natureInformationTable_fait_opinion}
                natureInformationTable_faits={natureInformationTable_faits}

                addQuote={this.addQuote}
                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}
                miseEnFormeIntroSansCitation={this.miseEnFormeIntroSansCitation}
                introDate2={this.introDate2}
                getAttributeValueInArrayOfObjectFromOtherAttribute={this.getAttributeValueInArrayOfObjectFromOtherAttribute}
                titreDebat={this.titreDebat}
                introDebat={this.introDebat}

                listquoteMainPage={this.state.listquoteMainPage}
                listquoteSearch={this.state.listquoteSearch}
                listquoteSimilar={this.state.listquoteSimilar}

                listquoteLoaded={this.state.listquoteLoaded}
                listThemes={this.state.listAllThemes}
                // listSousthemes={this.state.listSousthemes}

                ListQuoteAPIs={this.ListQuoteAPIs}
                ListSimilarQuoteAPIs={this.ListSimilarQuoteAPIs}

                queryTracker={this.queryTracker}
                pageRechercheDisplay={this.pageRechercheDisplay}
                pageRechercheShow={this.state.pageRechercheShow}
                spotAnalytics={this.spotAnalytics}

                dateTypeSelected={this.state.dateTypeSelected}
                dateTypeIntro={this.state.dateTypeIntro}
                dateTypeIntro2={this.state.dateTypeIntro2}
                dateTypeIntroAll={this.state.dateTypeIntroAll}
                evenementTypeList={this.state.evenementTypeList}
                evenementType={this.state.evenementType}

                regionsList={this.state.regionsList}
                departmentsList={this.state.departmentsList}


                {...props}
              />}
            />
            <Route exact path="/citations_2/:idquote/:pathParam2?" render={(props) =>
              <Citations_2
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                echellesGeographiquesInformationTable={echellesGeographiquesInformationTable}
                institutionsInformationTable={institutionsInformationTable}
                natureInformationTable={natureInformationTable}
                natureInformationTable_description_probleme={natureInformationTable_description_probleme}
                natureInformationTable_fait_opinion={natureInformationTable_fait_opinion}
                natureInformationTable_faits={natureInformationTable_faits}

                listsomeonetype={this.state.listsomeonetype}
                listorganisationtype={this.state.listorganisationtype}
                listreferencetype={this.state.listreferencetype}
                listmediatype={this.state.listmediatype}
                listperson={this.state.listperson}
                listmedia={this.state.listmedia}
                linkTypeList={this.state.linkTypeList}
                listThemes={this.state.listAllThemes}

                addQuote={this.addQuote}
                ReferenceTypeAPIs={this.ReferenceTypeAPIs}
                ListQuoteAPIs={this.ListQuoteAPIs}
                ListSimilarQuoteAPIs={this.ListSimilarQuoteAPIs}
                ListSimilarQuoteAPIsReturn={this.ListSimilarQuoteAPIsReturn}

                queryTracker={this.queryTracker}
                pageRechercheDisplay={this.pageRechercheDisplay}
                introDate2={this.introDate2}
                titreDebat={this.titreDebat}
                getAttributeValueInArrayOfObjectFromOtherAttribute={this.getAttributeValueInArrayOfObjectFromOtherAttribute}
                spotAnalytics={this.spotAnalytics}

                listquoteSearch={this.state.listquoteSearch}
                listquoteSimilar={this.state.listquoteSimilar}

                dateTypeSelected={this.state.dateTypeSelected}
                dateTypeIntro={this.state.dateTypeIntro}
                dateTypeIntro2={this.state.dateTypeIntro2}
                dateTypeIntroAll={this.state.dateTypeIntroAll}
                evenementTypeList={this.state.evenementTypeList}
                evenementType={this.state.evenementType}

                regionsList={this.state.regionsList}
                departmentsList={this.state.departmentsList}

                {...props}
              />}
            />

            <Route exact path="/intervenantDebat/:idIntervenant" render={(props) =>
              <IntervenantPage
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                queryTracker={this.queryTracker}
                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}

                natureInformationTable={natureInformationTable}

                getAttributeValueInArrayOfObjectFromOtherAttribute={this.getAttributeValueInArrayOfObjectFromOtherAttribute}

                {...props}
              />}
            />

            <Route exact path="/source/:idSource" render={(props) =>
              <SourcePage
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                queryTracker={this.queryTracker}
                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}

                natureInformationTable={natureInformationTable}

                getAttributeValueInArrayOfObjectFromOtherAttribute={this.getAttributeValueInArrayOfObjectFromOtherAttribute}

                {...props}
              />}
            />

            <Route exact path="/Spot_Apropos" render={(props) =>
              <PageAPropos
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                queryTracker={this.queryTracker}
                miseEnFormeIntroAvecCitation={this.miseEnFormeIntroAvecCitation}

                natureInformationTable={natureInformationTable}

                getAttributeValueInArrayOfObjectFromOtherAttribute={this.getAttributeValueInArrayOfObjectFromOtherAttribute}

                {...props}
              />}
            />

            <Route exact path="/labelisationTweets_DaccordPasDaccord" render={(props) =>
              <ComponentTweetLabelisation
                url_image={url_image}
                visitorLocation={this.state.visitorLocation}
                showConsoleLog={this.state.showConsoleLog}
                spot_api={spot_api}
                API_key={API_key}

                queryTracker={this.queryTracker}

                {...props}
              />}
            />

            <Route component={Error} />


          </Switch>


        </BrowserRouter>
      </div>
    );
  }
}

export default App;
