import React, { Component } from 'react';
import {
  ThumbsupIcon, ThumbsdownIcon, ArrowSwitchIcon, SyncIcon,
  MuteIcon, SmileyIcon, FlameIcon, LightBulbIcon,
  CrossReferenceIcon, UnverifiedIcon,
  ArrowUpIcon, ArrowUpRightIcon, DiffIcon, ArrowDownRightIcon, ArrowDownIcon
} from '@primer/octicons-react'

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';

import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';


import $ from "jquery"

class ComponentTweetLabelisation extends Component {

  constructor(props) {
    super(props);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  state = {
    pageType: "labelTweetDisAgrees", // labelTweetRepliesDisAgrees, labelTweetDisAgrees

    showConsoleLog: true,
    initDataRequest: true,

    displayGoldPersons: false,

    tweetList: [],
    currentTweetDict: [],
    currentTweetID: "",

    spotUserList: [],
    currentSpotUserId: "",
    spotUserName: "",
    spotUserNameTemp: "",
    spotUserKnown: false,
    spotUserTweetCount: 0,

    familyList: [],
    currentFamilyDict: [],
    currentFamilyValue: "",

    showTweet: true,

    gameOver: false,

    hovered: [false, false, false, false, false],

    keepSameTweetFamily: true,

    selectedTweetPertinent: [false, false, false, false, false, false, false, false],
    selectedDaccordPasdaccord: [false, false, false, false, false, false, false, false, false, false],

    nbMaxiFiltresAPI: 5,
    nbMaxiTweetsAPI: 400,

    commentFromUser: " ",
    labelisationTag: "labelisation1"
  }

  componentDidMount() {
    // this.initData()

  }

  initData = () => {
    this.getTwitterFiltersAPIs()
    this.getSpotUsersAPIs()
    // this.state.spotUserName === "" ? this.doNothing() : (this.state.pageType === 'labelTweetRepliesDisAgrees' ? this.getConversationIdsAPIs() : this.getSpotUsersAPIs())
    //this.state.spotUserName === "" ? this.doNothing() : this.getSpotUserData(this.state.spotUserList, this.state.spotUserNameTemp)
  }

  componentDidUpdate() {

  }

  doNothing() {

  }

  capitalizeFirstLetter(string) {
    if (string !== undefined && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ""
    }
  }

  scrollToTop() {
    scroll.scrollToTop();
  }


  extractRandomElementFromDictArrayAndRemove = (dictArrayToExtract, attributeRequest) => {

    this.props.showConsoleLog && console.log("extractRandomElementFromDictArrayAndRemove in -> dictArrayToExtract :", dictArrayToExtract, " - :", attributeRequest)

    var selectedElement
    var selectedTweetObj
    // var attributeRequest

    // Prend un élément (dict) au hasard dans la liste
    selectedTweetObj = dictArrayToExtract[Math.floor(Math.random() * dictArrayToExtract.length)]
    selectedElement = $(selectedTweetObj).attr(attributeRequest);

    this.props.showConsoleLog && console.log("extractRandomElementFromDictArrayAndRemove in -> selectedElement :", selectedElement)

    let index
    index = dictArrayToExtract.indexOf(selectedTweetObj)
    if (index > -1) { // only splice array when item is found
      dictArrayToExtract.splice(index, 1); // 2nd parameter means remove one item only
    }

    this.props.showConsoleLog && console.log("extractRandomElementFromDictArrayAndRemove -> dictArrayToExtract : ", dictArrayToExtract, " - selectedElement :", selectedElement)

    return [dictArrayToExtract, selectedTweetObj]

  }

  changeFamily = (familyType) => { // familyType : "conversation" "filters"

    this.props.showConsoleLog && console.log("changeFamily in -> ", familyType)

    let newFamilyList
    let attributeDiscrimination
    let originFamilyValue

    this.props.showConsoleLog && console.log("changeFamily this.state.familyList -> ", this.state.familyList)

    if (this.state.familyList.length > 1) {


      newFamilyList = []

      this.state.familyList.forEach((element, index) => {
        this.props.showConsoleLog && console.log("changeFamily element -> ", element)
        newFamilyList.push(Object.assign({}, element))
      })

      this.props.showConsoleLog && console.log("changeFamily newFamilyList -> ", newFamilyList)

      if (familyType === "conversation") {
        attributeDiscrimination = "conversation_id"
      } else if (familyType === "filters") {
        attributeDiscrimination = "filters"
      }


      originFamilyValue = this.state.currentFamilyDict[0][attributeDiscrimination]

      let tempList
      let selectedItemFullObject
      let selectedItemAttributeValue

      selectedItemAttributeValue = originFamilyValue

      while (selectedItemAttributeValue === originFamilyValue) {
        this.props.showConsoleLog && console.log("changeFamily newFamilyList -> ", newFamilyList)
        this.props.showConsoleLog && console.log("changeFamily attributeDiscrimination -> ", attributeDiscrimination)

        this.props.showConsoleLog && console.log("iter changeFamily INIT -> ", originFamilyValue, " - AFTER ->", selectedItemAttributeValue)

        tempList = this.extractRandomElementFromDictArrayAndRemove(newFamilyList, attributeDiscrimination)

        this.props.showConsoleLog && console.log("changeFamily tempList -> ", tempList)

        newFamilyList = tempList[0]
        selectedItemFullObject = tempList[1]
        this.props.showConsoleLog && console.log("changeFamily selectedItemFullObject -> ", selectedItemFullObject)
        selectedItemAttributeValue = tempList[1][attributeDiscrimination]
        this.props.showConsoleLog && console.log('new family : ', newFamilyList, ' - selectedItemAttributeValue : ', selectedItemFullObject)
      }

      this.props.showConsoleLog && console.log("final changeFamily INIT -> ", originFamilyValue, " - AFTER ->", selectedItemAttributeValue)

      this.setState({
        // familyList: newFamilyList,
        currentFamilyDict: [selectedItemFullObject],
        currentFamilyValue: selectedItemAttributeValue
      }, () => familyType === "filters" ? this.getTweetsFromFilters() : this.getRepliesIdsAPIs(selectedItemAttributeValue))

    } else {

      if (familyType === "filters") {
        this.setState({
          // familyList: newFamilyList,
          currentFamilyDict: [this.state.familyList[0]],
          currentFamilyValue: this.state.familyList[0]["filters"]
        }, () => this.getTweetsFromFilters())

        this.getTweetsFromFilters()
      } else {
        this.getRepliesIdsAPIs(this.state.currentFamilyValue)
      }

    }


  }


  getRepliesIdsAPIs(conversationID) {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      apiInstance.getTweetRepliesGet("abc", conversationID, (error, tweetData, response) => {

        this.props.showConsoleLog && console.log('getConversationIdsAPIs -> tweetData : ', tweetData)
        this.props.showConsoleLog && console.log('getConversationIdsAPIs -> currentTweetID : ', tweetData[0].idTweet)

        let returnSuggestionTweet
        returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(tweetData, "idTweet")
        this.props.showConsoleLog && console.log('tweetData : ', returnSuggestionTweet[0], ' - selectedTweet : ', returnSuggestionTweet[1])

        this.setState({ tweetList: returnSuggestionTweet[0], currentTweetID: returnSuggestionTweet[1]["idTweet"], currentTweetDict: [returnSuggestionTweet[1]], showTweet: false })
        setTimeout(() => { this.setState({ showTweet: true }) }, 1)

      })

    }
  }


  getConversationIdsAPIs = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      this.props.showConsoleLog && console.log('getConversationIdsAPIs -> apiInstance : ', apiInstance)

      apiInstance.getTweetConversationGet(this.state.spotUserName, (error, data, response) => {

        this.setState({ familyList: data }, () => this.changeFamily("conversation"))

      })

    }
  }

  replaceAll = (string, search, replace) => {
    return string.split(search).join(replace);
  }

  postTweetLabelAPIs() {

    this.props.showConsoleLog && console.log('postTweetLabelAPIs in')

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      this.props.showConsoleLog && console.log('getConversationIdsAPIs -> apiInstance : ', apiInstance)


      this.props.showConsoleLog && console.log('postTweetLabelAPIs in this.state.currentTweetID : ', this.state.currentTweetID)
      this.props.showConsoleLog && console.log('postTweetLabelAPIs in this.state.spotUserName :', this.state.spotUserName)
      this.props.showConsoleLog && console.log('postTweetLabelAPIs in this.state.selectedTweetPertinent :', this.state.selectedTweetPertinent)
      this.props.showConsoleLog && console.log('postTweetLabelAPIs in this.state.selectedDaccordPasdaccord :', this.state.selectedDaccordPasdaccord)

      apiInstance.tweetLabelisatonIdTweetPost(this.state.currentTweetID, this.state.spotUserName,
        this.state.selectedTweetPertinent, this.state.selectedDaccordPasdaccord,
        this.replaceAll(this.state.commentFromUser, "'", "\\'"), this.state.labelisationTag, (error, data, response) => {

          this.props.showConsoleLog && console.log('postTweetLabelAPIs post OK')

          this.setState({ spotUserTweetCount: this.state.spotUserTweetCount + 1 })

          this.setState({
            selectedTweetPertinent: [false, false, false, false, false, false, false, false],
            selectedDaccordPasdaccord: [false, false, false, false, false, false, false, false, false, false],
            commentFromUser: " ",
          })

          if (this.state.tweetList.length == 0) { // Si la liste des réponses est terminée, alors on change de Tweet de référence


            let tempFamilyList
            tempFamilyList = []

            this.props.showConsoleLog && console.log("postTweetLabelAPIs -> liste vide", tempFamilyList)

            // Suppression de la famille vide
            this.state.familyList.forEach((element) => {
              if (element["filters"] !== this.state.currentFamilyDict[0]["filters"]) {
                tempFamilyList.push(Object.assign({}, element))
              }
            })

            this.props.showConsoleLog && console.log("SUPPRESSION tempFamilyList : ", tempFamilyList)

            this.setState({ familyList: tempFamilyList }, () => {

              if (this.state.familyList.length == 0) { // Si la liste des conversations est terminée, alors game over
                this.setState({ gameOver: true })
              } else {
                this.props.showConsoleLog && console.log('postTweetLabelAPIs -> Changement de conversation !! ')

                if (this.state.pageType === "labelTweetRepliesDisAgrees") {
                  this.changeFamily("conversation")
                } else if (this.state.pageType === "labelTweetDisAgrees") {
                  this.changeFamily("filters")
                }
              }

            })

          } else {

            let returnSuggestionTweet
            returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(this.state.tweetList, "idTweet")
            this.props.showConsoleLog && console.log('tweetData : ', returnSuggestionTweet[0], ' - selectedTweet : ', returnSuggestionTweet[1])

            this.setState({ tweetList: returnSuggestionTweet[0], currentTweetID: returnSuggestionTweet[1]["idTweet"], currentTweetDict: returnSuggestionTweet[1], showTweet: false })
            setTimeout(() => { this.setState({ showTweet: true }) }, 1)

          }

        })

    }
  }


  getSpotUserData = (spotUserList, spotUserName) => {
    this.props.showConsoleLog && console.log("spotUserList :", spotUserList)
    this.props.showConsoleLog && console.log("spotUserName :", spotUserName)
    spotUserList.forEach((element) => {
      if (element.spotUserName === spotUserName) {
        this.props.showConsoleLog && console.log('')
        this.setState({ spotUserKnown: true, spotUserTweetCount: element.count })
      }
    })
  }

  getSpotUsersAPIs = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      this.props.showConsoleLog && console.log('getSpotUsersAPIs -> apiInstance :', apiInstance)

      apiInstance.getTweetLabelisationUsersGet((error, data, response) => {

        this.props.showConsoleLog && console.log('getSpotUsersAPIs -> data :', data)

        this.setState({ spotUserList: data })

        if (this.state.spotUserName !== "") {
          this.getSpotUserData(data, this.state.spotUserName)
        }

      })

    }

  }


  getTwitterUsersAPIs = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      apiInstance.getTwitterUsersGet((error, data, response) => {

        this.props.showConsoleLog && console.log('getTwitterUsersAPIs -> data :', data)

        let returnSuggestionTweet
        returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(data, "author_id")
        this.props.showConsoleLog && console.log('spotUserList : ', returnSuggestionTweet[0], ' - selectedObj : ', returnSuggestionTweet[1])

        this.setState({ familyList: returnSuggestionTweet[0], currentFamilyDict: [returnSuggestionTweet[1]] }, () => this.getTweetsFromTwitterUser())

        // this.setState({ familyList: data })

      })

    }

  }


  getTweetsFromTwitterUser = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      apiInstance.getTweetsFromTwitterUserGet(this.state.currentFamilyDict[0]["author_id"], (error, tweetData, response) => {

        this.props.showConsoleLog && console.log('getTweetsFromTwitterUser -> data :', tweetData)

        let returnSuggestionTweet
        returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(tweetData, "idTweet")
        this.props.showConsoleLog && console.log('tweetData : ', returnSuggestionTweet[0], ' - selectedTweet : ', returnSuggestionTweet[1])

        this.setState({ tweetList: returnSuggestionTweet[0], currentTweetID: returnSuggestionTweet[1]["idTweet"], currentTweetDict: returnSuggestionTweet[1], showTweet: false })
        setTimeout(() => { this.setState({ showTweet: true }) }, 1)

      })

    }

  }




  getTwitterFiltersAPIs = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      apiInstance.getTwitterFiltersGet(this.state.nbMaxiFiltresAPI, (error, data, response) => {

        this.props.showConsoleLog && console.log('getTwitterFiltersAPIs -> data :', data)

        //let returnSuggestionTweet
        //returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(data, "author_id")
        //this.props.showConsoleLog && console.log('spotUserList : ', returnSuggestionTweet[0], ' - selectedObj : ', returnSuggestionTweet[1])

        let randomValue
        randomValue = Math.floor(Math.random() * data.length)

        this.props.showConsoleLog && console.log("randomValue :", randomValue)

        this.setState({
          familyList: data,
          currentFamilyDict: [data[randomValue]],
          currentFamilyValue: data[randomValue]["filters"],
        }, () => this.changeFamily("filters"))

        // this.setState({ familyList: data })
      })

    }

  }


  getTweetsFromFilters = () => {

    if (this.props.spot_api !== undefined && this.props.spot_api.ApiClient !== undefined) {

      let defaultClient = this.props.spot_api.ApiClient.instance;
      let ApiKeyAuth = defaultClient.authentications['ApiKeyAuth'];
      ApiKeyAuth.apiKey = this.props.API_key;
      let apiInstance = new this.props.spot_api.TweetApi();

      this.props.showConsoleLog && console.log('getTweetsFromFilters -> this.state.currentFamilyDict :', this.state.currentFamilyDict)

      apiInstance.getTweetsFromFiltersGet(this.state.currentFamilyDict[0]["filters"], this.state.nbMaxiTweetsAPI, (error, tweetData, response) => {

        this.props.showConsoleLog && console.log('getTweetsFromFilters -> data :', tweetData)

        let returnSuggestionTweet
        returnSuggestionTweet = this.extractRandomElementFromDictArrayAndRemove(tweetData, "idTweet")
        this.props.showConsoleLog && console.log('tweetData : ', returnSuggestionTweet[0], ' - selectedTweet : ', returnSuggestionTweet[1])

        this.setState({
          tweetList: returnSuggestionTweet[0],
          currentTweetID: returnSuggestionTweet[1]["idTweet"],
          currentTweetDict: returnSuggestionTweet[1], showTweet: false
        })
        setTimeout(() => { this.setState({ showTweet: true }) }, 1)

      })

    }

  }



  selectTweetPertinent = (valueSelected) => {
    let tempSelectedValues = []
    tempSelectedValues = this.state.selectedTweetPertinent

    if (valueSelected == 0) {
      if (tempSelectedValues[0]) {
        tempSelectedValues = [false, false, false, false, false, false, false, false]
      } else {
        tempSelectedValues = [true, false, false, false, false, false, false, false]
      }

    } else {
      tempSelectedValues[0] = false
      // tempSelectedValues[1] = false
      tempSelectedValues[valueSelected] = !tempSelectedValues[valueSelected]
    }

    {/*else if (valueSelected == 1) {
        if (tempSelectedValues[1]) {
          tempSelectedValues = [false, false, false, false, false, false, false, false]
        } else {
          tempSelectedValues = [false, true, false, false, false, false, false, false]
        }
      }
    */}


    this.setState({ selectedTweetPertinent: tempSelectedValues, selectedDaccordPasdaccord: [false, false, false, false, false, false, false, false, false, false] })
  }

  selectDaccordPasdaccord = (valueSelected) => {
    let tempSelectedValues = []
    let keepDouteValue

    tempSelectedValues = this.state.selectedDaccordPasdaccord
    keepDouteValue = tempSelectedValues[tempSelectedValues.length - 1]

    if (valueSelected == 0) {
      if (tempSelectedValues[0]) {
        tempSelectedValues = [false, false, false, false, false, false, false, false, false, false]
      } else {
        tempSelectedValues = [true, false, false, false, false, false, false, false, false, false]
      }

    } else if (tempSelectedValues[valueSelected]) {
      tempSelectedValues = [false, false, false, false, false, false, false, false, false, keepDouteValue]
    } else {
      tempSelectedValues = [false, false, false, false, false, false, false, false, false, keepDouteValue]
      tempSelectedValues[valueSelected] = true
    }

    this.setState({ selectedDaccordPasdaccord: tempSelectedValues })
  }



  render() {

    if (this.props.spot_api.ApiClient !== undefined && this.state.initDataRequest) { // As long as spot_api is not loaded 
      this.props.showConsoleLog && console.log("ComponentTweetLabelisation -> initData call ")
      this.setState({ initDataRequest: false })
      this.initData()
    }

    this.props.showConsoleLog && console.log('this.state.tweetList : ', this.state.tweetList)
    this.props.showConsoleLog && console.log('this.state.currentTweetID : ', this.state.currentTweetID)
    this.props.showConsoleLog && console.log('this.state.spotUserName : ', this.state.spotUserName)
    this.props.showConsoleLog && console.log('this.state.spotUserNameTemp : ', this.state.spotUserNameTemp)

    this.props.showConsoleLog && console.log('this.state.tweetList : ', this.state.tweetList)
    this.props.showConsoleLog && console.log('this.state.currentTweetDict : ', this.state.currentTweetDict)
    this.props.showConsoleLog && console.log('this.state.currentTweetID : ', this.state.currentTweetID)

    this.props.showConsoleLog && console.log('this.state.familyList : ', this.state.familyList)


    let grilleDaccordPasDaccord = []
    let grilleTweetPertinent = []

    if (this.state.pageType === "labelTweetRepliesDisAgrees") {
      grilleDaccordPasDaccord = [
        // { value: -5, color: "#ff0000" },
        // { value: -4, color: "#fc920d" },
        // { value: -3, color: "#fbb00f" },
        // { value: -2, color: "#fbc511" },
        // { value: -1, color: "#fbe214" },
        // { value: 0, color: "#faff16" },
        // { value: 1, color: "#d6ff12" },
        // { value: 2, color: "#acff0e" },
        // { value: 3, color: "#85ff0a" },
        // { value: 4, color: "#55ff06" },
        // { value: 5, color: "#1bff00" },
        { value: -2, backgroundColor: "#ff0000", showSubtitle: (window.innerWidth < 575.98 || this.state.hovered[0]), text: "La réponse n'est pas du tout d'accord avec le Tweet de référence" },
        { value: -1, backgroundColor: "#fbb00f", showSubtitle: (window.innerWidth < 575.98 || this.state.hovered[1]), text: "La réponse est plutôt en désaccord avec le Tweet de référence" },
        { value: 0, backgroundColor: "#faff16", showSubtitle: (window.innerWidth < 575.98 || this.state.hovered[2]), text: "La réponse traite bien du même sujet mais ne prend pas partie en d'accord / pas d'accord. Il a + tendance à donner un complément d'information. Si la réponse est ironique ou hors-sujet, merci de cocher la case grise en-bas à gauche" },
        { value: 1, backgroundColor: "#85ff0a", showSubtitle: (window.innerWidth < 575.98 || this.state.hovered[3]), text: "La réponse est plutôt d'accord avec le Tweet de référence" },
        { value: 2, backgroundColor: "#1bff00", showSubtitle: (window.innerWidth < 575.98 || this.state.hovered[4]), text: "La réponse soutient très fortement le Tweet de référence" },
      ]
    } else {

      grilleTweetPertinent = [
        { value: 2, selectedValue: this.state.selectedTweetPertinent[2], backgroundColor: "#ccebc5", textColor: "#000000", complementClassName: " col-lg-10 text-left ", text: (this.state.currentFamilyDict.length > 0 ? "Traite d'un autre sujet que \u00ab " + this.state.currentFamilyDict[0]["familleDebat"] + " \u00bb" : "Traite d'un autre sujet"), logo: <CrossReferenceIcon size={16} /> },
        { value: 3, selectedValue: this.state.selectedTweetPertinent[3], backgroundColor: "#a8ddb5", textColor: "#000000", complementClassName: " col-lg-10 text-left ", text: "Humour, sarcasme", logo: <SmileyIcon size={16} /> },
        { value: 4, selectedValue: this.state.selectedTweetPertinent[4], backgroundColor: "#7bccc4", textColor: "#000000", complementClassName: " col-lg-10 text-left ", text: "Attaque personnelle ou attaque des actions/prises de position passées", logo: <FlameIcon size={16} /> },
        { value: 5, selectedValue: this.state.selectedTweetPertinent[5], backgroundColor: "#4eb3d3", textColor: "#000000", complementClassName: " col-lg-10 text-left ", text: "Pas d'avis exprimé : Information ou fait", logo: <LightBulbIcon size={16} /> },
        { value: 6, selectedValue: this.state.selectedTweetPertinent[6], backgroundColor: "#2b8cbe", textColor: "#000000", complementClassName: " col-lg-10 text-left ", text: "Pas explicite : Le Tweet pourrait être écrit par qqu'un qui a une opinion contraire", logo: <MuteIcon size={16} /> },
        { value: 1, selectedValue: this.state.selectedTweetPertinent[1], backgroundColor: "#084081", textColor: "#ffffff", complementClassName: " col-lg-10 text-left ", text: (this.state.currentFamilyDict.length > 0 ? "Le Tweet est pertinent car il prend position pour / contre sur le sujet \u00ab " + this.state.currentFamilyDict[0]["familleDebat"] + " \u00bb" : "Le Tweet est pertinent car il prend position pour / contre"), logo: <ThumbsupIcon size={16} /> },
        { value: 0, selectedValue: this.state.selectedTweetPertinent[0], backgroundColor: "#bdc3c7", textColor: "#000000", complementClassName: " col-lg-6 mt-4 text-center", text: "Je ne sais pas / Je ne souhaite pas répondre", logo: <UnverifiedIcon size={16} /> },
      ]


      grilleDaccordPasDaccord = [
        { value: 1, selectedValue: this.state.selectedDaccordPasdaccord[1], backgroundColor: "#88bfb1", textColor: "#000000", complementClassName: " col-lg-11 text-left ", text: "Pour explicitement : dit clairement qu'il/elle est pour ", logo: <ArrowUpIcon size={16} /> },
        { value: 2, selectedValue: this.state.selectedDaccordPasdaccord[2], backgroundColor: "#a8ddb5", textColor: "#000000", complementClassName: " col-lg-11 text-left ", text: "Pour implicitement : cite des arguments pour, sans dire explicitement qu'il/elle est pour", logo: <ArrowUpRightIcon size={16} /> },
        { value: 3, selectedValue: this.state.selectedDaccordPasdaccord[3], backgroundColor: "#dbe6a2", textColor: "#000000", complementClassName: " col-lg-11 text-left ", text: "Pour implicitement : cite l'avis d'une autre personne qui est pour", logo: <ArrowUpRightIcon size={16} /> },
        { value: 4, selectedValue: this.state.selectedDaccordPasdaccord[4], backgroundColor: "#fccb87", textColor: "#000000", complementClassName: " col-lg-11 text-left ", text: "Sous conditions : exprime des conditions pour être d'accord ou pas", logo: <DiffIcon size={16} /> },
        { value: 5, selectedValue: this.state.selectedDaccordPasdaccord[5], backgroundColor: "#f08664", textColor: "#ffffff", complementClassName: " col-lg-11 text-left ", text: "Contre implicitement : cite des arguments contre, sans dire explicitement qu'il/elle est contre", logo: <ArrowDownRightIcon size={16} /> },
        { value: 6, selectedValue: this.state.selectedDaccordPasdaccord[6], backgroundColor: "#e5574f", textColor: "#ffffff", complementClassName: " col-lg-11 text-left ", text: "Contre implicitement : cite l'avis d'une autre personne qui est contre", logo: <ArrowDownRightIcon size={16} /> },
        { value: 7, selectedValue: this.state.selectedDaccordPasdaccord[7], backgroundColor: "#b65070", textColor: "#ffffff", complementClassName: " col-lg-11 text-left ", text: "Contre implicitement : cite des alternatives, sans dire explicitement qu'il/elle est contre", logo: <ArrowDownRightIcon size={16} /> },
        { value: 8, selectedValue: this.state.selectedDaccordPasdaccord[8], backgroundColor: "#78478b", textColor: "#ffffff", complementClassName: " col-lg-11 text-left ", text: "Contre explicitement : dit clairement qu'il/elle est contre", logo: <ArrowDownIcon size={16} /> },
        { value: 0, selectedValue: this.state.selectedDaccordPasdaccord[0], backgroundColor: "#bdc3c7", textColor: "#000000", complementClassName: " col-lg-6 mt-4 text-center", text: "Je ne sais pas / Je ne souhaite pas répondre", logo: <UnverifiedIcon size={16} /> },
      ]

    }


    return (
      <div className="mb-5 pb-5">

        <header className="tweetHeader pt-3 pb-3 ">
          <div className="d-flex align-items-start text-center">
            Labélisation de Tweets
          </div>
        </header>

        <Accordion defaultActiveKey="-1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Rappel du besoin</Accordion.Header>
            <Accordion.Body>
              <div className={window.innerWidth > 575.98 ? "d-flex flex-row" : "d-flex flex-column"}>
                <img
                  className=" "
                  src={this.props.url_image + "tweetLabelisation_Explication.png"}
                  alt={"Explication Tweet"}
                  style={{
                    height: window.innerWidth > 575.98 ? "630px" : "",
                    width: window.innerWidth > 575.98 ? "" : "100%",
                  }}
                >
                </img>
              </div>
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>

        <div className="d-flex flex-row align-items-start col-lg-12">

          {this.state.gameOver &&
            <div className='d-flex flex-row w-100 mt-5 justify-content-center '>
              <div className='col-lg-4 text-center thankYouText '>Merci ! Il n'y a plus de Tweet à labéliser ! ❤️</div>
            </div>
          }

          {!this.state.gameOver &&
            <div className="d-flex flex-column justify-content-center col-lg-12 m-0 p-0 ">


              {true &&
                <div className={"d-flex pt-4 justify-content-center " + (window.innerWidth > 575.98 ? "flex-row align-items-center " : "flex-column ")}>
                  <div className={window.innerWidth > 575.98 ? "" : "mb-3"}>
                    Je renseigne mon nom ou un pseudo :
                  </div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mon nom ou un pseudo"
                    className="ml-4"
                  >
                    <Form.Control
                      type="input"
                      placeholder=""
                      onChange={e => this.setState({ spotUserNameTemp: e.target.value.toLowerCase() })}
                      value={this.state.spotUserNameTemp}
                      disabled={this.state.spotUserName != ""}
                    />
                    {this.state.spotUserName !== "" &&
                      <Form.Text id="passwordHelpBlock" muted>
                        {this.state.spotUserKnown ? "Utilisateur connu" : "Nouvel utilisateur"}
                      </Form.Text>
                    }
                  </FloatingLabel>
                  {this.state.spotUserName != "" && window.innerWidth > 575.98 &&
                    <Button variant="success" className="ml-4" disabled>OK</Button>
                  }
                  {this.state.spotUserName == "" &&
                    <Button
                      variant="dark"
                      className="ml-4"
                      onClick={e => {
                        this.setState({ spotUserName: this.state.spotUserNameTemp }, () => this.state.pageType === "labelTweetRepliesDisAgrees" ? this.getConversationIdsAPIs() : this.changeFamily("filters"))
                        this.getSpotUserData(this.state.spotUserList, this.state.spotUserNameTemp)
                      }}
                    >Valider</Button>
                  }
                  {this.state.spotUserName != "" && this.state.spotUserTweetCount > 0 &&
                    <div className="col-lg-2 ml-4 ">
                      <div>{this.state.spotUserTweetCount + " Tweets déjà labélisés :)"}</div>
                      <div>{"\t❤️".repeat(this.state.spotUserTweetCount / 10 + 1)}</div>
                    </div>
                  }
                  {this.state.spotUserName == "" &&
                    <ul className={window.innerWidth > 575.98 ? "col-lg-4 mt-0 mb-0 ml-5 p-0 " : "col-12 mt-4 mb-0 ml-2 p-2"}>
                      <li className={"tweetSmallText " + (window.innerWidth > 575.98 ? "m-0 p-0 " : "pt-2")}>{" Ton nom/pseudo permettra de ne pas te soumettre plusieurs fois le même Tweet"}</li>
                      <li className={"tweetSmallText " + (window.innerWidth > 575.98 ? "m-0 p-0 " : "pt-2")}>{" Privilégier un nom ou un pseudo court"}</li>
                      <li className={"tweetSmallText " + (window.innerWidth > 575.98 ? "m-0 p-0 " : "pt-2")}>{" Les lettres majuscules ne sont pas saisissables"}</li>
                      <li className={"tweetSmallText " + (window.innerWidth > 575.98 ? "m-0 p-0 " : "pt-2")}>{" Essaye de te souvenir de ton pseudo pour une prochaine utilisation ;)"}</li>
                    </ul>
                  }
                </div>
              }

              {(this.state.spotUserName != "") &&
                <div className="col-12 m-0 p-0 ">

                  <div className="d-flex justify-content-center ">


                    {this.state.currentTweetID !== "" &&
                      <div className={window.innerWidth > 575.98 ? "d-flex flex-row justify-content-left col-lg-12 mt-4 p-4 " : "d-flex flex-column justify-content-left col-12 mt-4 p-4 "}>


                        {this.state.showTweet &&
                          <div className="d-flex flex-column col-12 col-lg-5 m-0 pl-0 pt-4 justify-content-center niceBorder ">

                            <div className="questionStyle w-100 m-0 p-0 text-center ">Le Tweet à analyser</div>

                            {this.props.showConsoleLog && console.log("this.state.currentFamilyDict :", this.state.currentFamilyDict)}

                            <div className="ml-4">
                              <TwitterTweetEmbed
                                //onLoad={function noRefCheck() { }}
                                tweetId={this.state.currentTweetID}
                              />

                              <div className="lightMessage text-center ">{this.state.tweetList.length + " tweets restants sur ce thème"}</div>
                            </div>

                            {this.props.showConsoleLog && console.log("this.state.currentFamilyDict : ", this.state.currentFamilyDict)}


                            <div className="d-flex flex-row col-12 col-lg-12 ml-0 mt-2 mb-2 p-1 pl-3 text-left ">
                              {`Thème des Tweets proposés : \u00ab ` + this.state.currentFamilyDict[0]["familleDebat"] + ` \u00bb`}
                            </div>

                            {
                              //this.state.currentTweetID
                            }

                            {this.state.familyList.length > 1 &&
                              <div className="d-flex justify-content-center p-4">
                                <Button
                                  onClick={() => this.state.pageType === "labelTweetRepliesDisAgrees" ? this.getConversationIdsAPIs() : this.changeFamily("filters")}
                                  className="d-flex justify-content-center align-items-center justify-content-center col-lg-4"
                                  style={{ color: '#646363', backgroundColor: "#bdc3c7", borderColor: "#bdc3c7" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                                >
                                  <SyncIcon className="m-0 p-0 " size={17} />
                                  <p className="m-0 pl-3 tweetPageButtonContent">Changer de thème</p>
                                </Button>

                              </div>
                            }
                          </div>
                        }

                        {this.state.pageType === "labelTweetRepliesDisAgrees" &&
                          <div className="col-12 col-lg-7 m-0 p-0 ">
                            <div className="d-flex flex-row col-12 m-0 p-0 justify-content-center pt-4 ">
                              <div className="col-12 col-lg-8 m-0 p-0 text-center ">Sur une échelle de -2 (pas du tout d'accord) à 2 (complètement d'accord), le Tweet réponse ci-dessus est-il d'accord ou pas avec le Tweet de référence ?</div>
                            </div>

                            <div className="d-flex flex-row justify-content-around align-items-center col-12 m-0 p-0 pt-4 ">
                              <ThumbsdownIcon className="m-0 p-0 " size={20} />
                              {
                                grilleDaccordPasDaccord.map((element, index) => (
                                  <Button
                                    key={`tweet_${index}`}
                                    className="d-flex flex-row justify-content-center align-items-center "
                                    variant={'light'}
                                    style={{ backgroundColor: element.backgroundColor, width: "14%" }}
                                    onClick={() => {
                                      this.postTweetLabelAPIs(element.value, false, false)
                                    }}
                                    onMouseEnter={() => {
                                      let tempHovered
                                      tempHovered = this.state.hovered
                                      tempHovered[index] = true
                                      this.setState({ hovered: tempHovered })
                                    }}
                                    onMouseLeave={() => this.setState({ hovered: [false, false, false, false, false] })}
                                  >
                                    {element.value}

                                  </Button>
                                ))
                              }
                              <ThumbsupIcon className="m-0 p-0 " size={20} />
                            </div>

                            {window.innerWidth > 575.98 &&
                              <div className="d-flex mt-4 col-lg-12 justify-content-center ">
                                <div className="d-flex col-lg-10 ">
                                  <div className="d-flex flex-column w-100 ">
                                    {
                                      grilleDaccordPasDaccord.map((element, index) => (
                                        element.showSubtitle && <div className="p-3 w-100 " style={{ border: 'solid', borderColor: element.color }}>{element.text}</div>
                                      ))
                                    }

                                  </div>
                                </div>
                              </div>
                            }

                            <div className="d-flex flex-row justify-content-around pt-4">

                              <div className="col-6 col-lg-5">
                                <Button
                                  onClick={() => {
                                    this.postTweetLabelAPIs(-100, true, false)
                                  }}
                                  className="d-flex justify-content-center align-items-center justify-content-center"
                                  style={{ color: 'white', backgroundColor: "#bdc3c7", borderColor: "#bdc3c7" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                                >
                                  <div className={window.innerWidth > 575.98 ? "d-flex flex-row m-0 p-0" : "d-flex flex-column m-0 p-0"}>
                                    <ArrowSwitchIcon className="ml-auto mr-auto p-0 " size={20} />
                                    <div className="m-0 p-0 col-12 col-lg-10 ml-auto ">
                                      {"Il n'est pas pertinent de qualifier la réponse en d'accord/pas d'accord : hors-sujet ou humour "}
                                    </div>
                                  </div>

                                </Button>
                              </div>


                              <div className="col-6 col-lg-5">
                                <Button
                                  onClick={() => {
                                    this.postTweetLabelAPIs(-100, false, true)
                                  }}
                                  className="d-flex justify-content-center align-items-center justify-content-center"
                                  style={{ color: 'white', backgroundColor: "#bdc3c7", borderColor: "#bdc3c7" }} // style={{ backgroundColor: "#76d2d8", borderColor: "#2cabb3" }}
                                >
                                  <div className={window.innerWidth > 575.98 ? "d-flex flex-row m-0 p-0" : "d-flex flex-column m-0 p-0"}>
                                    <SyncIcon className="ml-auto mr-auto p-0 " size={20} />
                                    <div className="m-0 p-0 col-12 col-lg-10 ml-auto">
                                      <div>{"J'ai un doute. Passer au Tweet suivant (avec le même Tweet de référence)"}</div>
                                    </div>
                                  </div>

                                </Button>
                              </div>

                            </div>


                            {window.innerWidth < 575.98 &&
                              <div className="d-flex mt-5 col-12 col-lg-12 p-0 justify-content-center ">
                                <div className="d-flex col-12 col-lg-10 m-0 mt-3 p-0 ">
                                  <div className="d-flex flex-column w-100 ">
                                    <div className="col-12 m-0 p-0 text-center">Grille de notation</div>
                                    {
                                      grilleDaccordPasDaccord.map((element, index) => (
                                        <div
                                          className={"d-flex flex-row m-0 p-0 w-100 "}
                                          style={{ border: 'solid', borderColor: element.color }}
                                        >
                                          <div className="col-1 m-0 p-0 text-center " style={{ backgroundColor: element.color }}>{element.value}</div>
                                          <div className="p-1">{element.text}</div>
                                        </div>
                                      ))
                                    }

                                  </div>
                                </div>
                              </div>
                            }

                          </div>
                        }


                        {this.state.pageType === "labelTweetDisAgrees" &&
                          <div className="w-100 ">

                            <div className="d-flex flex-column w-100 m-0 p-0 ">
                              <div className="d-flex flex-row col-12 m-0 p-0 justify-content-center pt-4 ">
                                <div className="questionStyle w-100 m-0 p-0 text-center ">1. Le Tweet est-il pertinent ou non ?</div>
                              </div>


                              <div className="d-flex flex-column justify-content-around align-items-center w-100 m-0 p-0 pt-4 ">

                                <div className="lightMessage">Plusieurs choix possibles</div>

                                {
                                  grilleTweetPertinent.map((element, index) => (
                                    <Button
                                      key={`grilleTweetPertinent_${index}`}
                                      className={"pl-4 mt-1 " + element.complementClassName}
                                      variant={'light'}
                                      style={{
                                        backgroundColor: element.backgroundColor,
                                        color: element.textColor,
                                        opacity: this.state.selectedTweetPertinent.includes(true) ? (element.selectedValue ? 1 : 0.5) : 1,
                                      }}
                                      onClick={() => {
                                        this.selectTweetPertinent(element.value)
                                      }}
                                    >
                                      <div className="d-flex flex-row m-0 p-0 align-items-center ">
                                        <p className="m-0 p-0">{element.logo}</p>
                                        <p className="mt-0 mb-0 pt-0 pb-0 ml-4 ">{element.text}</p>
                                      </div>
                                    </Button>
                                  ))
                                }
                              </div>


                              {this.props.showConsoleLog && console.log("this.state.selectedTweetPertinent : ", this.state.selectedTweetPertinent)}

                              <Form >
                                {
                                  <div key={`default-'checkbox'`} className="p-3 ">
                                    <Form.Check
                                      type={'checkbox'}
                                      id={`default-checkbox`}
                                      label={`Je réponds mais j'ai un petit doute`}
                                      className="text-center"
                                      checked={this.state.selectedTweetPertinent[this.state.selectedTweetPertinent.length - 1]}
                                      onChange={() => {
                                        let tempSelectedTweetPertinent
                                        tempSelectedTweetPertinent = this.state.selectedTweetPertinent
                                        tempSelectedTweetPertinent[tempSelectedTweetPertinent.length - 1] = !tempSelectedTweetPertinent[tempSelectedTweetPertinent.length - 1]
                                        this.setState({ selectedTweetPertinent: tempSelectedTweetPertinent })
                                      }}
                                    />
                                  </div>
                                }
                              </Form>

                            </div>


                            {this.state.selectedTweetPertinent[1] &&
                              <div className="d-flex flex-column w-100 m-0 p-0 mt-5" style={{ opacity: this.state.selectedTweetPertinent[1] ? 1 : 0.4 }} >
                                <div className="d-flex flex-row col-12 m-0 p-0 justify-content-center pt-4 ">
                                  <div className=" col-12 col-lg-8 m-0 p-0 text-center ">

                                    <div className="questionStyle">2. Quel est l'avis de l'auteur ?</div>
                                    {
                                      //<p className="">Seulement si le Tweet est pertinent</p>
                                    }
                                    <p>{"Au sujet du débat portant sur : " + this.state.currentFamilyDict[0]["familleDebat"]}</p>

                                  </div>
                                </div>

                                <div className="d-flex flex-column justify-content-around align-items-center col-12 m-0 p-0 pt-4 ">

                                  <div className="lightMessage">Un seul choix possible :</div>

                                  {
                                    grilleDaccordPasDaccord.filter((element) => element.value !== 4).map((element, index) => (
                                      <Button
                                        key={`grilleDaccordPasdaccord_${index}`}
                                        className={"pl-4 mt-1 " + element.complementClassName}
                                        variant={'light'}
                                        style={{
                                          backgroundColor: element.backgroundColor,
                                          color: element.textColor,
                                          opacity: this.state.selectedDaccordPasdaccord.includes(true) ? (element.selectedValue ? 1 : 0.5) : 1,
                                        }}
                                        onClick={() => {
                                          this.selectDaccordPasdaccord(element.value)
                                        }}
                                        disabled={!this.state.selectedTweetPertinent[1]}
                                      >
                                        <div className="d-flex flex-row m-0 p-0 align-items-center ">
                                          <p className="m-0 p-0">{element.logo}</p>
                                          <p className="mt-0 mb-0 pt-0 pb-0 ml-4 ">{element.text}</p>
                                        </div>
                                      </Button>
                                    ))
                                  }

                                  {this.props.showConsoleLog && console.log("this.state.selectedDaccordPasdaccord : ", this.state.selectedDaccordPasdaccord)}

                                  <Form >
                                    {
                                      <div key={`default-'checkbox'`} className="p-3 ">
                                        <Form.Check
                                          type={'checkbox'}
                                          id={`default-checkbox`}
                                          label={`Je réponds mais j'ai un petit doute`}
                                          className="text-center"
                                          checked={this.state.selectedDaccordPasdaccord[this.state.selectedDaccordPasdaccord.length - 1]}
                                          onChange={() => {
                                            let tempSelectedDaccordPasdaccord
                                            tempSelectedDaccordPasdaccord = this.state.selectedDaccordPasdaccord
                                            this.props.showConsoleLog && console.log("tempSelectedDaccordPasdaccord[-1] : ", tempSelectedDaccordPasdaccord[tempSelectedDaccordPasdaccord.length - 1])
                                            tempSelectedDaccordPasdaccord[tempSelectedDaccordPasdaccord.length - 1] = !tempSelectedDaccordPasdaccord[tempSelectedDaccordPasdaccord.length - 1]
                                            this.setState({ selectedDaccordPasdaccord: tempSelectedDaccordPasdaccord })
                                          }}
                                        />
                                      </div>
                                    }
                                  </Form>
                                </div>


                              </div>
                            }



                            <div className="d-flex flex-column justify-content-center align-items-center col-12 m-0 p-0 pt-4 mt-5 ">

                              <Button
                                className={"col-lg-6 mt-1 "}
                                variant={'success'}
                                style={{}}
                                onClick={() => {
                                  this.postTweetLabelAPIs()
                                  this.scrollToTop()
                                }}
                                disabled={this.state.selectedTweetPertinent[1] ? !this.state.selectedDaccordPasdaccord.slice(0, -1).includes(true) : !this.state.selectedTweetPertinent.slice(0, -1).includes(true)}
                              >
                                Valider
                              </Button>
                              <div className="lightMessage">{!this.state.selectedTweetPertinent.slice(0, -1).includes(true) ? "Besoin de répondre à la première question :)" : (this.state.selectedTweetPertinent[1] && !this.state.selectedDaccordPasdaccord.slice(0, -1).includes(true) ? "Besoin de répondre à la seconde question stp ? :)" : "Tu peux valider !")}</div>


                              <div className="col-lg-10 mt-5">
                                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '100px' }}
                                    value={this.state.commentFromUser}
                                    onChange={e => this.setState({ commentFromUser: e.target.value })}
                                  />
                                </FloatingLabel>
                              </div>

                            </div>


                          </div>
                        }


                      </div>
                    }

                  </div>

                </div>
              }



            </div>
          }

        </div>

      </div>
    )
  }
}


export default ComponentTweetLabelisation
