import React, { Component } from 'react';

import { Transition } from 'react-transition-group';

class ComponentChoixNature extends Component {

  state = {
    hovered: false,

    showConsoleLog: true,

  }


  capitalize = (s) => {
    if (typeof s !== 'string') {
      return ''
    } else {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }


  manageID = (id) => {
    if (id === 100) {
      this.props.changeValues("showSocietyDescriptionTable", id)
    } else if (id === 101) {
      this.props.changeValues("showKnowledgeTable", id)
    } else {
      this.props.handleChangesNature(id)
    }
  }


  render() {

    const duration = 300;

    const defaultStyle = {
      transition: `all ${duration}ms ease-in-out`,
      opacity: 0,
    }

    let infoPlusHeight = document.getElementById(`infoPlus_${this.props.indexKey}`) === null ?
      0 : document.getElementById(`infoPlus_${this.props.indexKey}`).offsetHeight


    const transitionStyles2 = {
      entering: { height: infoPlusHeight, opacity: 1 },
      entered: { height: infoPlusHeight, opacity: 1 },
      exiting: { height: 0, opacity: 0 }, // visibility: 'hidden', opacity: 0
      exited: { height: 0, opacity: 0 },
    }

    this.props.showConsoleLog && console.log("ComponentChoixNature -> element :", this.props.element)
    this.props.showConsoleLog && console.log("ComponentChoixNature -> element.id :", this.props.element.id)
    this.props.showConsoleLog && console.log("ComponentChoixNature -> highlightBoolean :", this.props.highlightBoolean)
    this.props.showConsoleLog && console.log("ComponentChoixNature -> hideBoolean :", this.props.hideBoolean)

    return (

      <div
        className={"d-flex flex-column m-0 w-100 ".concat(window.innerWidth > 576 ? 'p-2 pl-3 pr-3 ' : 'mb-4')}
        style={{ position: 'relative', zIndex: 40 }}
      >

        {(this.props.element.nature === "étude" || this.props.element.nature === "dataviz") &&
          <div className="d-flex justify-content-end">
            <div
              className="w-40 mt-4 mr-1 pl-2 pr-2 text-center rotateConstruction textConstruction "
              style={{ position: 'absolute', zIndex: 42 }}
            >
              En construction
            </div>
          </div>
        }

        <button
          className="d-flex m-0 p-0 whiteButton noBorder w-100 "
          onMouseEnter={() => this.setState({ hovered: true })}
          onMouseLeave={() => this.setState({ hovered: false })}
          onClick={() => {
            this.props.showConsoleLog && console.log("ComponentChoixNature -> id :", this.props.element.id)
            this.manageID(this.props.element.id)
            // this.props.handleChangesNature(this.props.element.id)
          }}
          style={{ position: 'relative', zIndex: 30 }} // height: '100%', 
          disabled={(this.props.element.nature === "étude" 
            || this.props.element.nature === "dataviz" 
            || (this.props.formulaireType !== undefined && (this.props.formulaireType === 'new quote' || this.props.formulaireType === 'change title')))}
        >

          <div
            className="d-flex rounded p-2 m-0 w-100 "
            style={{
              border: 'solid',
              borderWidth: "2px",
              borderColor: this.props.q_typeID !== 0 ? "#e55c5c" : " #e5e8e8 ",
              backgroundColor: (this.props.element.id === this.props.q_typeID || window.innerWidth < 576) ? "#fff5f5" : "#ffffff",
              height: '100%',
            }}
          >
            <div className={"d-flex flex-column rounded m-0 w-100 ".concat(window.innerWidth > 576 ? 'p-2 ' : 'p-0 ')}>
              <div
                className="choixTitre text-center "
                style={{
                  color: (!this.props.highlightBoolean && this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" :
                    (this.props.element.nature === "étude" || this.props.element.nature === "dataviz") ? "#b3b6b7" : ""
                }}
              >
                {this.props.element.nature}
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className="separateurNature"
                  style={{ borderColor: this.props.q_typeID !== 0 ? "#e55c5c" : " #e5e8e8 " }}
                >
                </div>
              </div>
              <div className="d-flex flex-row w-100 ">
                <img
                  className="commentateurImage rounded-circle img-fluid "
                  src={this.props.url_image + this.props.element.t_photo}
                  title={this.props.element.nature}
                  alt=""
                  style={{ filter: this.props.q_typeID !== 0 ? 'none' : 'grayscale(1)' }}
                />
                <div
                  className="choixContenu text-justify align-middle p-3 w-100 "
                  style={{
                    height: '90px',
                    color: (!this.props.highlightBoolean && this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" :
                      (this.props.element.nature === "étude" || this.props.element.nature === "dataviz") ? "#b3b6b7" : ""
                  }}>
                  {this.props.element.nature === "fait" ?
                    <div>
                      <span>{"Information qui nous apprend quelque chose sur "}</span>
                      <span style={{ textDecoration: "underline" }}>{"le monde tel qu'il est"}</span>
                      <span>{". Se basant sur la méthode scientifique expérimentale, elle doit être argumentée par des études."}</span>
                    </div>
                    :
                    (this.props.element.nature === "évènement" ?
                      <div>
                        <span>{"Fait décrivant "}</span>
                        <span style={{ textDecoration: "underline" }}>{"une action"}</span>
                        <span>{" qui s'est déroulée, se déroule ou se déroulera, à une date ou sur une période précise."}</span>
                      </div>
                      :
                      <span>{this.props.element.definition}</span>
                    )
                  }
                </div>
              </div>
            </div>
          </div>

        </button>

        {this.props.showConsoleLog && console.log("ComponentChoixNature -> showMoreInfoNature : ", this.props.showMoreInfoNature)}
        {this.props.showConsoleLog && console.log("ComponentChoixNature -> hovered : ", this.state.hovered)}
        {this.props.showConsoleLog && console.log("ComponentChoixNature -> cond : ", (this.props.showMoreInfoNature !== undefined && this.props.showMoreInfoNature) || this.state.hovered || window.innerWidth < 576)}
        {this.props.showConsoleLog && console.log("ComponentChoixNature -> element : ", this.props.element)}
        {this.props.showConsoleLog && console.log("ComponentChoixNature -> originTable : ", this.props.originTable)}

        {
          <div
            className="d-flex p-2  "
            style={{ position: 'relative', zIndex: 20 }}
          >
            <div
              id={`infoPlus_${this.props.indexKey}`}
              className="d-flex flex-column bottomRadius argumentSourceBox p-2 "
              style={{ position: 'relative', top: -8, bottom: 0, width: '96%', color: (this.props.element.nature === "étude" || this.props.element.nature === "dataviz") ? "#b3b6b7" : "" }}
            >
              <div className="choixContenu" style={{ color: (this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" : "" }}>
                {"Catégorie : " + this.props.element.categorie}
              </div>
              {false && <div className="choixContenu" style={{ color: (this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" : "" }}>
                {"Définition : " + this.props.element.critere}
              </div>}
              {this.props.element.introduction.length > 0 &&
                <div className="choixContenu" style={{ color: (this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" : "" }}>
                  {"Introduction : " + this.props.element.introduction}
                </div>
              }
              {false && <div className="choixContenu" style={{ color: (this.props.hideBoolean && !this.state.hovered) ? "#d7d7d7" : "" }}>
                {"Exemple : " + this.props.element.exemple}
              </div>}
            </div>
          </div>
        }

      </div >

    )
  }
}


export default ComponentChoixNature