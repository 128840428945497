import React, { Component } from 'react';

import ComponentFormulaireQuoteManager from './ComponentFormulaireQuoteManager';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import '../full.css';
import "react-datepicker/dist/react-datepicker.css";


class ModalFormulaireQuoteManager extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    show: false,
    showConsoleLog: true,
  }

  componentDidMount() {
  }

  componentDidUpdate = (prevProps) => {
  }


  render() {

    return (

      <div className="d-flex flex-column col-lg-12 m-0 p-0 " >

        { // Création d'un débat
          this.props.formulaireType === "new quote" &&
          <div className="d-flex align-items-center justify-content-start w-100 m-0 p-0">

            <Button
              onClick={() => { this.setState({ show: true }) }}
              className="d-flex justify-content-center align-items-center w-100 m-0 p-1 jeContribueBox"
            >
              <div className="ml-3 mr-3 mt-1 mb-1 ">
                {"Ajouter un débat sur SPOT".toUpperCase()}
              </div>
            </Button>

          </div>
        }

        { // Modifier le titre
          this.props.formulaireType === "change title" &&
          this.props.contributionActivated &&
          <div className="d-flex align-items-center justify-content-start w-100 m-0 p-0">

            <Button
              className="buttonStepper validerBox w-100"
              onClick={() => { this.setState({ show: true }) }}
            >
              {"Modifier le titre"}
            </Button>

          </div>
        }


        <Modal
          show={this.state.show}
          onHide={() => { this.setState({ show: false }) }}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby=""
          className=""
        >

          <Modal.Header className="d-flex justify-content-center whiteButtonShadow " closeButton> {/* sticky-top */}
            <Modal.Title className="d-flex flex-column justify-content-center w-100 ">

              <div
                className="col-12 col-lg-9 m-0 p-0 titreFormulaire "
                style={{ fontSize: window.innerWidth > 576 ? '25px' : '19px' }}
              >
                {
                  this.props.formulaireType === 'change title' ? "Modifier le titre du débat" :
                    (this.props.formulaireType === 'new quote' ? "Ajouter une information sur SPOT" : "")
                }
              </div>

            </Modal.Title>
          </Modal.Header>


          <Modal.Body>

            <div className={'d-flex justify-content-center m-0 p-0 rounded '}>
              <div className='d-flex col-12 col-lg-11 m-0 p-0 borderRadius' >
                <div className='d-flex flex-column w-100 m-0 p-0 ' >

                  {// Sélection de la nature de l'info et du titre

                    <div className="d-flex borderRadius ">

                      <ComponentFormulaireQuoteManager
                        formulaireType={this.props.formulaireType}
                        listquoteSimilar={this.props.listquoteSimilar}

                        departmentsList={this.props.departmentsList}

                        getAttributeValueInArrayOfObjectFromOtherAttribute={this.props.getAttributeValueInArrayOfObjectFromOtherAttribute}
                        miseEnFormeIntroSansCitation={this.props.miseEnFormeIntroSansCitation}

                        natureInformationTable={this.props.natureInformationTable}
                        natureInformationTable_description_probleme={this.props.natureInformationTable_description_probleme}
                        natureInformationTable_fait_opinion={this.props.natureInformationTable_fait_opinion}
                        natureInformationTable_faits={this.props.natureInformationTable_faits}

                        ListSimilarQuoteAPIs={this.props.ListSimilarQuoteAPIs}
                        addQuote={this.props.addQuote}

                        url_image={this.props.url_image}
                        showConsoleLog={this.props.showConsoleLog}
                        spot_api={this.props.spot_api}
                        API_key={this.props.API_key}

                        show={this.state.show}
                        quote={this.props.quote}

                        capitalizeFirstLetter={this.props.capitalizeFirstLetter}

                        quoteModalities={[]}

                      />

                    </div>
                  }

                </div>
              </div>

            </div>

          </Modal.Body>
        </Modal>

      </div>

    )
  }
}

export default ModalFormulaireQuoteManager