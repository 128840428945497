import React, { useState } from 'react';

// Elements Bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { InfoIcon } from '@primer/octicons-react'

import '../full.css';

function ModalWindowHelp({ pageName, helpType, helpType2, url_image, listThemes, mainThemesList, sousThemesList, cnature, inButton,
  closePopupInformationThematique, closePopupInformationNature, natureInformationTable }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    if (closePopupInformationThematique !== undefined) {
      closePopupInformationThematique()
    }
    if (closePopupInformationNature !== undefined) {
      closePopupInformationNature()
    }

    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
  }


  const getThemesIDlist = (themesList) => {

    let returnListID = []

    if (themesList !== undefined) {
      themesList.forEach((element) => {
        returnListID.push(element.idtheme)
      })
    }

    return returnListID
  }


  const tableComplétudePourComprendre = [
    {
      sousPartie: "Pour comprendre",
      complétude: 82,
      neutralité: 90,
      display: true,
      mainTitle: true,
    },
    {
      sousPartie: "Le sujet",
      complétude: 75,
      neutralité: 87,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Quoi",
      complétude: 60,
      neutralité: 45,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Qui",
      complétude: 55,
      neutralité: 30,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Changements",
      complétude: 90,
      neutralité: 75,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Contexte",
      complétude: 87,
      neutralité: 60,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Définitions",
      complétude: 92,
      neutralité: 90,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Qui pense quoi ?",
      complétude: 70,
      neutralité: 75,
      display: true,
      mainTitle: true,
    },
    {
      sousPartie: "Les commentateurs",
      complétude: 65,
      neutralité: 65,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Les institutions",
      complétude: 90,
      neutralité: 90,
      display: true,
      mainTitle: false,
    },
    {
      sousPartie: "Les autres pays",
      complétude: 0,
      neutralité: 0,
      display: true,
      mainTitle: false,
    },

  ]


  const tableQQOQCC = [
    {
      question: "Quoi ?",
      contenu: "De quoi s’agit-il ?",
      appliedFait: true,
      appliedEvenement: true,
      appliedEtude: true,
      appliedPropAct: true,
      appliedSondage: true,
      appliedOpinion: true,
      appliedProjFait: true,
      appliedProjOpinion: true,
    },
    {
      question: "Qui ?",
      contenu: "Quelles sont les populations concernées ?",
      appliedFait: true,
      appliedEvenement: true,
      appliedEtude: true,
      appliedPropAct: true,
      appliedSondage: true,
      appliedOpinion: true,
      appliedProjFait: true,
      appliedProjOpinion: true,
    },
    {
      question: "Où ?",
      contenu: "Quels espaces sont concernés par cette information ?",
      appliedFait: true,
      appliedEvenement: true,
      appliedEtude: true,
      appliedPropAct: true,
      appliedSondage: true,
      appliedOpinion: true,
      appliedProjFait: true,
      appliedProjOpinion: true,
    },
    {
      question: "Quels changements ?",
      contenu: "Quels sont les changements induits ?",
      appliedFait: false,
      appliedEvenement: false,
      appliedEtude: false,
      appliedPropAct: true,
      appliedSondage: false,
      appliedOpinion: false,
      appliedProjFait: false,
      appliedProjOpinion: false,
    },
    {
      question: "Contexte",
      contenu: "Quels sont les éléments de contexte utiles pour comprendre l’information ?",
      appliedFait: true,
      appliedEvenement: true,
      appliedEtude: true,
      appliedPropAct: true,
      appliedSondage: true,
      appliedOpinion: true,
      appliedProjFait: true,
      appliedProjOpinion: true,
    },
    {
      question: "Définitions",
      contenu: "Définitions utiles pour comprendre le débat",
      appliedFait: true,
      appliedEvenement: true,
      appliedEtude: true,
      appliedPropAct: true,
      appliedSondage: true,
      appliedOpinion: true,
      appliedProjFait: true,
      appliedProjOpinion: true,
    },
  ]



  return (

    <div className="">

      {
        (pageName === 'SideMenu') &&
        <button
          className="d-flex align-items-end textMenuIntro petiteMargeDessus zoomImage_35pct whiteButton"
          onClick={handleShow}
        >
          <InfoIcon size={15} />
        </button>
      }
      {
        pageName === 'Citation_2' && (inButton === undefined || !inButton) &&
        <button
          className="d-flex align-items-center zoomImage_25pct whiteButton"
          style={{
            color: helpType2 === 'white' ? "white" : '#566573',
            backgroundColor: helpType2 === 'white' ? '#e55c5c' : '#ffffff'
          }}
          onClick={handleShow}>
          <InfoIcon size={15} />
        </button>
      }
      {
        pageName === 'Citation_2' && (inButton !== undefined && inButton) &&
        <div
          className="d-flex align-items-center zoomImage_25pct whiteButton"
          style={{
            color: helpType2 === 'white' ? "white" : '#566573',
            backgroundColor: helpType2 === 'white' ? '#e55c5c' : '#ffffff'
          }}
          onClick={handleShow}>
          <InfoIcon size={15} />
        </div>
      }
      {
        pageName === 'Home' && helpType !== 'NatureInformation' && helpType !== 'Complétude' &&
        <button
          className="d-flex align-items-center informationButton m-0 p-0 zoomImage_25pct whiteButton"
          style={{ color: 'grey' }}
          onClick={handleShow}><InfoIcon size={16}
          />
        </button>
      }
      {
        pageName === 'Home' && helpType === 'NatureInformation' &&
        <button className="d-flex align-items-center m-0 p-0 zoomImage_25pct whiteButton" style={{ color: '#afb4b8' }} onClick={handleShow}><InfoIcon size={16} /></button>
      }
      {
        pageName === 'PageCitation' &&
        <button className="d-flex align-items-center m-0 p-0 zoomImage_25pct " style={{ color: '#ffffff', backgroundColor: "#1fe0", border: 'none' }} onClick={handleShow}><InfoIcon size={16} /></button>
      }
      {
        pageName === 'Home' && helpType === 'Complétude' &&
        <button className="d-flex align-items-center m-0 p-0 zoomImage_25pct whiteButton"
          style={{
            color: '#ffffff',
            backgroundColor: '#333',
          }}
          onClick={handleShow}
        >
          <InfoIcon size={16} className="m-0 p-0" />
        </button>
      }
      {
        pageName === 'compenentDebate' &&
        <button className="d-flex align-items-center m-0 p-0 zoomImage_40pct whiteButton" style={{ color: 'grey' }} onClick={handleShow}><InfoIcon size={14} /></button>
      }



      <Modal size="xl" show={show} onHide={handleClose}>

        {
          helpType === 'NatureInformation' &&
          <Modal.Header closeButton>
            <Modal.Title>La nature des informations</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === 'Thématiques' &&
          <Modal.Header closeButton>
            <Modal.Title>Les thématiques</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === 'Complétude' &&
          <Modal.Header closeButton>
            <Modal.Title>La complétude</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === 'NiveauLecture' &&
          <Modal.Header closeButton>
            <Modal.Title>Niveau de lecture</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === 'UnitéGéographique' &&
          <Modal.Header closeButton>
            <Modal.Title>Les échelles de débats</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === "QQOQCC" &&
          <Modal.Header closeButton>
            <Modal.Title>Le sujet</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === "Commentateurs" &&
          <Modal.Header closeButton>
            <Modal.Title>Les commentateurs</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === "Institutions" &&
          <Modal.Header closeButton>
            <Modal.Title>Les institutions</Modal.Title>
          </Modal.Header>
        }
        {
          helpType === "AutresPays" &&
          <Modal.Header closeButton>
            <Modal.Title>Les autres pays</Modal.Title>
          </Modal.Header>
        }

        <Modal.Body>

          {
            (helpType === 'UnitéGéographique' || helpType === "Institutions") &&
            <div>

              {
                helpType === 'UnitéGéographique' &&
                <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                  <h4 className="text-center p-2" >Un débat portant sur la France entière ? Un débat pour une ville ou même un quartier ? </h4>
                  <p className="text-center p-2" >SPOT permet de s'informer sur tout type de débat.</p>
                </div>
              }

              {
                helpType === 'Institutions' &&
                <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                  <h4 className="text-center p-2" >A quoi sert la partie "Les institutions" ?</h4>
                  <p className="text-center p-2" >Cette partie regroupe les avis des représentants de la République. Les représentants dépendent de l'échelle géographique du débat.</p>
                </div>
              }

              <div className="d-flex justify-content-center mt-4 " >
                <div className="col-lg-9" >
                  <Table striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                    <thead>
                      <tr>
                        <th colSpan="2" className="align-middle ">Par quelle institution le débat peut-il être traité ?</th>
                        <th className="align-middle ">Echelle géographique </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><img className="" src={url_image + "Carte_communes_de-France_Métropolitaine.png"} alt="Carte communes françaises" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="align-middle ">
                          <p>Mairie</p>
                          <p>Conseil de quartier</p>
                        </td>
                        <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Débat local</td>
                      </tr>
                      <tr>
                        <td><img className="" src={url_image + "Carte_France-departements-dom-tom.jpg"} alt="Carte départements français" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="align-middle ">Conseil Départemental</td>
                        <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Débat départemental</td>
                      </tr>
                      <tr>
                        <td><img className="" src={url_image + "Carte_France-nouvelles-regions.jpg"} alt="Carte régions françaises" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="align-middle ">Conseil Régional</td>
                        <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Débat régional</td>
                      </tr>
                      <tr>
                        <td><img className="" src={url_image + "Carte_france-aires-urbaines-urbanisation-fondfonce-echelle-lambert93-vierge_transp.png"} alt="Carte France" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="align-middle ">
                          <p>Assemblée Nationale</p>
                          <p>Sénat</p>
                        </td>
                        <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Débat national</td>
                      </tr>
                      <tr>
                        <td><img className="" src={url_image + "Carte_Union_Européenne.jpg"} alt="Carte Union Européenne" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="align-middle ">Parlement Européen</td>
                        <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Débat européen</td>
                      </tr>
                      <tr>
                        <td colSpan="2"><img className="" src={url_image + "Carte_Monde.jpg"} alt="Carte Monde" style={{ height: '180px', 'objectFit': 'contain' }} /></td>
                        <td className="text-center align-middle" style={{ 'backgroundColor': ' ' }}>Débat mondial</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }


          {
            helpType === 'Thématiques' &&
            <div>
              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Quels sont les grands thèmes abordés dans chaque débat ?</h4>
                <p className="text-center p-2" >Chaque information est associée à un nombre de thématiques, compris entre 1 et 3.</p>
              </div>

              <div className="d-flex justify-content-center mt-4 " >
                <div className="col-lg-11" >
                  <Table striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                    <thead>
                      <tr>
                        <th colSpan="2" className="align-middle " style={{ width: '20%' }}>Thématique</th>
                        <th className="align-middle ">Définition</th>
                        <th className="align-middle ">Exemples</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mainThemesList !== undefined &&
                        mainThemesList.map((element, index) => (
                          <tr key={`help_listThemes_${index}`}>
                            <td className="align-middle "><img className="" src={url_image + element.t_photo} title={element.t_title} alt={element.t_photo} style={{ height: '40px', 'objectFit': 'contain' }} /></td>
                            <td className="align-middle ">{element.t_title}</td>
                            <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Déf {index}</td>
                            <td className="align-middle " style={{ 'backgroundColor': ' ' }}>Exemple {index}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="mt-4 pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Est-ce suffisamment précis ?</h4>
                <p className="text-center p-2" >Bien sûr que non ! C'est pourquoi chaque grand thème est découpé en plusieurs sous-thèmes.</p>
              </div>

              <div className="d-flex justify-content-center mt-4 " >
                <div className="col-lg-11" >
                  <Table striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                    <thead>
                      <tr>
                        <th className="align-middle "></th>
                        <th className="align-middle " colSpan={mainThemesList === undefined ? 1 : mainThemesList.length}>Thèmes</th>
                      </tr>
                      <tr>
                        <th className="align-middle ">Sous-thème</th>
                        {mainThemesList !== undefined &&
                          mainThemesList.map((element, index) => (
                            <th key={`help_listThemes1_${index}`} className="align-middle ">
                              <img
                                className=""
                                src={url_image + element.t_photo}
                                alt={element.t_photo}
                                title={element.t_title}
                                style={{ height: '40px', 'objectFit': 'contain' }}
                              />
                            </th>
                          ))
                        }
                      </tr>
                    </thead>

                    <tbody>
                      {mainThemesList !== undefined &&
                        sousThemesList.map((sousTheme, index) => (
                          <tr key={`help_sousThemes_${index}`}>

                            <td className="align-middle ">{sousTheme.t_title}</td>
                            {
                              mainThemesList.map((mainTheme, indexMainTheme) => (
                                getThemesIDlist(sousTheme.parentsIDlist).includes(mainTheme.idtheme) ?
                                  <td key={`help_cross_${indexMainTheme}`}>x</td>
                                  :
                                  <td key={`help_noCross_${indexMainTheme}`}></td>
                              ))
                            }

                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="text-right mt-0 pt-0 ">
                <p className="mr-5 mt-0 pt-0 textBlack2"><small style={{ color: 'grey' }}>* Tableau non-exhaustif</small></p>
              </div>


            </div>
          }




          {
            helpType === 'NatureInformation' &&
            <div>
              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Pourquoi et comment différencier les informations ?</h4>
                <p className="text-center p-2" >Selon s'il s'agit d'un fait ou d'une opinion, une information n'a pas besoin des mêmes niveaux de justification.</p>
              </div>

              <div className="d-flex justify-content-center mt-4 " >
                <div className="col-lg-11" >
                  <Table striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                    <thead>
                      <tr>
                        <th colSpan="2" className="align-middle " style={{}}>Nature</th>
                        <th className="align-middle ">Définition</th>
                        <th className="align-middle ">Critères différenciants</th>
                        <th className="align-middle ">Introduction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        natureInformationTable.map((element, index) => (
                          <tr
                            key={`help_natureInformationTable_${index}`}
                            style={{
                              'color': (cnature === undefined ? '' : (cnature.toLowerCase() === element.nature.toLowerCase()) ? 'black' : 'grey'),
                              'backgroundColor': (cnature !== undefined && cnature.toLowerCase() === element.nature.toLowerCase()) ? ' #fadbd8 ' : '',
                              'border': (cnature !== undefined && cnature.toLowerCase() === element.nature.toLowerCase()) ? "solid" : '',
                              'borderWidth': (cnature !== undefined && cnature.toLowerCase() === element.nature.toLowerCase()) ? '5px' : '',
                              'borderColor': (cnature !== undefined && cnature.toLowerCase() === element.nature.toLowerCase()) ? '#e74c3c' : '',
                            }}
                          >
                            <td className="align-middle ">
                              <img className="" src={url_image + element.t_photo} alt={element.t_photo} title={element.nature} style={{ height: index < 6 ? '70px' : '50px', 'objectFit': 'contain' }} />
                            </td>
                            <td className="align-middle ">{element.nature}</td>
                            <td className="text-left align-middle ">{element.definition}</td>
                            <td className="text-left align-middle ">{element.critere}</td>
                            <td className="align-middle ">{element.introduction}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>

            </div>
          }


          {
            helpType === 'Complétude' &&
            <div>

              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Comment garantir la complétude et la neutralité des débats ?</h4>
                <p className="text-center p-2" >Pour chaque partie de la page de débat, des indicateurs permettent d'identifier en un coup d'oeil les niveaux de complétude et de neutralité</p>
              </div>

              <Table className="mt-5" striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                <thead>
                  <tr>
                    <th className="align-middle " style={{}}>Partie</th>
                    <th className="align-middle ">Complétude</th>
                    <th className="align-middle ">Neutralité</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableComplétudePourComprendre.map((element, index) => (
                      <tr key={`help_tableCompletudePourComprendre_${index}`}>
                        <td className="align-middle " style={{ 'backgroundColor': element.mainTitle ? " #f5b7b1 " : "" }}>{element.sousPartie}</td>
                        <td className="align-middle "><ProgressBar variant={element.complétude < 50 ? "danger" : (element.complétude < 75 ? "warning" : "success")} now={element.complétude} label={`${element.complétude}%`} /></td>
                        <td className="align-middle "><ProgressBar variant={element.neutralité < 50 ? "danger" : (element.neutralité < 75 ? "warning" : "success")} now={element.neutralité} label={`${element.neutralité}%`} /></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>

              <Carousel className="mt-5">
                <Carousel.Item style={{ 'height': "250px" }} >
                  <div style={{ 'height': "250px", 'backgroundColor': '#373940' }} className="d-block w-100"></div>
                  <Carousel.Caption>
                    <h3>La complétude</h3>
                    <p>Définition à compléter</p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item style={{ 'height': "250px" }}>
                  <div style={{ 'height': "250px", 'backgroundColor': '#282c34' }} className="d-block w-100"></div>
                  <Carousel.Caption>
                    <h3>La neutralité</h3>
                    <p>Définition à compléter</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>

            </div>
          }



          {
            helpType === 'NiveauLecture' &&
            <div>
              <div className="pt-2 pb-3 rounded addShadow fontStyleRoboto" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Comment garantir la complétude et la neutralité des débats ?</h4>
                <p className="text-center p-2" >SPOT permet d'aborder chaque débat avec différents niveaux de compléxité.</p>
                <p className="text-center m-0 mt-2" >
                  Vous souhaitez vous familiariser avec un débat ? sélectionner le niveau "facile".
                </p>
                <p className="text-center m-0 " >
                  Vous souhaitez approfondir un sujet que vous connaissez ? Sélectionnez le niveau "difficile".
                </p>
              </div>

              <h6 className="p-2 mt-4" >
                <li className="ml-3 textBlack2">Les niveaux de lecture de la partie "Pour comprendre"</li>
              </h6>
              <div className="d-flex justify-content-center mt-3 ">
                <Carousel className="border rounded" interval={1500} pause="hover" slide={false} style={{ 'width': "750px" }}>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "250px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Pour comprendre - facile.png"} alt="Niveau de lecture facile" style={{ 'height': "250px" }} />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "250px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Pour comprendre - moyen.png"} alt="Niveau de lecture moyen" style={{ 'height': "250px" }} />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "250px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Pour comprendre - difficile.png"} alt="Niveau de lecture difficile" style={{ 'height': "250px" }} />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>


              <h6 className="p-2 mt-4" >
                <li className="ml-3 textBlack2" style={{}}>Les niveaux de lecture de la partie "Qui pense quoi ?"</li>
              </h6>
              <div className="d-flex justify-content-center mt-3 ">
                <Carousel className="border rounded" interval={1500} pause="hover" slide={false} style={{ 'width': "750px" }}>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "350px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Qui pense quoi - facile.png"} alt="Niveau de lecture facile" style={{ 'height': "350px" }} />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "350px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Qui pense quoi - moyen.png"} alt="Niveau de lecture moyen" style={{ 'height': "350px" }} />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="mt-3 mb-3 " style={{ 'height': "350px" }} >
                    <div className="d-flex justify-content-center ">
                      <img className="" src={url_image + "Niveau de lecture - Qui pense quoi - difficile.png"} alt="Niveau de lecture difficile" style={{ 'height': "350px" }} />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>

              <h6 className="p-2 mt-4" >
                <li className="ml-3 textBlack2" style={{ color: 'grey' }}>Bientôt... d'autres parties seront concernées par les niveaux de lecture !</li>
              </h6>

            </div>
          }




          {
            helpType === "QQOQCC" &&
            <div>
              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Quelles sont les informations à rassembler pour comprendre une information ?</h4>
                <p className="text-center p-2" >Cette partie décrit l'ensemble des questions nécessaires à la compréhension d'une information</p>
              </div>

              <div className="d-flex justify-content-center mt-4 " >
                <div className="col-lg-12" >
                  <Table striped bordered hover size="sm" style={{ 'textAlign': 'center', 'verticalAlign': 'middle' }}>
                    <thead>
                      <tr>
                        <th rowSpan="3" className="align-middle " style={{}}>Question</th>
                        <th rowSpan="3" className="align-middle ">Contenu</th>
                        <th colSpan="8" className="align-middle " style={{}}>S'applique à quel type d'information ?</th>
                      </tr>
                      <tr>
                        {
                          natureInformationTable.map((element, index) => (
                            <td key={`help_natureInformationTable1_${index}`} className="align-middle " style={{ 'font-size': '12px' }}>{element.nature}</td>
                          ))
                        }
                      </tr>
                      <tr>

                        {
                          natureInformationTable.map((element, index) => (
                            <th key={`help_natureInformationTable2_${index}`} className="align-middle ">
                              <img className="" src={url_image + element.t_photo} alt={element.t_photo} title={element.nature} style={{ height: index < 6 ? '70px' : '50px', 'objectFit': 'contain' }} />
                            </th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tableQQOQCC.map((element, index) => (
                          <tr
                            key={`help_tableQQOQCC_${index}`}
                            style={{
                              'color': (cnature === undefined ? '' : (cnature.toLowerCase() === element.nature.toLowerCase()) ? 'black' : 'grey'),
                              'backgroundColor': (cnature !== undefined && cnature.toLowerCase() === element.nature.toLowerCase()) ? ' #fadbd8 ' : '',
                              'border': (helpType2 !== undefined && element.question.toLowerCase() === helpType2.toLowerCase()) ? "solid" : '',
                              'borderWidth': (helpType2 !== undefined && element.question.toLowerCase() === helpType2.toLowerCase()) ? '5px' : '',
                              'borderColor': (helpType2 !== undefined && element.question.toLowerCase() === helpType2.toLowerCase()) ? '#e74c3c' : '',
                            }}
                          >
                            <td className="align-middle ">{element.question}</td>
                            <td className="text-left align-middle ">{element.contenu}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedFait ? " #a9cce3 " : " #eaeded " }}>{element.appliedFait ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedEvenement ? " #a9cce3 " : " #eaeded " }}>{element.appliedEvenement ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedEtude ? " #a9cce3 " : " #eaeded " }}>{element.appliedEtude ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedPropAct ? " #a9cce3 " : " #eaeded " }}>{element.appliedPropAct ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedSondage ? " #a9cce3 " : " #eaeded " }}>{element.appliedSondage ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedOpinion ? " #a9cce3 " : " #eaeded " }}>{element.appliedOpinion ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedProjFait ? " #a9cce3 " : " #eaeded " }}>{element.appliedProjFait ? "oui" : "non"}</td>
                            <td className="text-center align-middle " style={{ 'backgroundColor': element.appliedProjOpinion ? " #a9cce3 " : " #eaeded " }}>{element.appliedProjOpinion ? "oui" : "non"}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }


          {
            helpType === "Commentateurs" &&
            <div>
              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Qu'appelle-t-on "les commentateurs" ?</h4>
                <p className="text-center p-2" >Les commentateurs sont les personnes s'étant publiquement exprimées : dans les médias, sur les réseaux sociaux, dans un livre, un tract etc.</p>
              </div>
            </div>
          }


          {
            helpType === "AutresPays" &&
            <div>
              <div className="pt-2 pb-1 rounded addShadow" style={{ 'backgroundColor': ' #fdedec  ' }}>
                <h4 className="text-center p-2" >Et les autres pays ?</h4>
                <p className="text-center p-2" >
                  <p>Cette partie permet de voir les décisions qui sont appliquées dans les autres pays d'Europe ou du monde.</p>
                  <strong>Ces informations ont une vertue explicative, mais elles ne servent pas d'argument pour avancer qu'une décision est mieux qu'une autre !</strong>
                </p>
              </div>
            </div>
          }

          {
            helpType !== 'Thématiques' && helpType !== 'UnitéGéographique' && helpType !== 'NatureInformation' &&
            helpType !== 'Complétude' && helpType !== 'NiveauLecture' && helpType !== "QQOQCC"
            && helpType !== "Commentateurs" && helpType !== "Institutions" && helpType !== "AutresPays" &&
            <Carousel>
              <Carousel.Item style={{ 'height': "550px" }} >
                <div style={{ 'height': "550px", 'backgroundColor': '#373940' }} className="d-block w-100"></div>


                <Carousel.Caption>
                  <h3>Aide 1</h3>
                  <p>A construire</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ 'height': "550px" }}>
                <div style={{ 'height': "550px", 'backgroundColor': '#282c34' }} className="d-block w-100"></div>
                <Carousel.Caption>
                  <h3>Aide 2</h3>
                  <p>A construire</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item style={{ 'height': "550px" }}>
                <div style={{ 'height': "550px", 'backgroundColor': '#20232a' }} className="d-block w-100"></div>
                <Carousel.Caption>
                  <h3>Aide 3</h3>
                  <p>A construire</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          }




        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            J'ai compris
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalWindowHelp
